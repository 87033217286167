import PercentNetRevenueRowValue from './PercentNetRevenueRowValue.vue';
export default {
  name: "DataAndEvolutionValue",
  props: {
    value: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    align: {
      type: String,
      default: 'left'
    },
    fields: {
      type: Array
    },
    type: {
      type: String,
      // 'cumlative', 'normal'
      default: 'normal'
    },
    minMax: {
      type: Object
    }
  },
  components: {
    PercentNetRevenueRowValue
  },
  expose: ['toggleAggregate', 'toggleDetails'],
  data() {
    return {
      thStyles: [],
      hideDetails: true,
      hideDetailEvents: []
    };
  },
  watch: {
    fields: {
      handler: function (val) {
        this.thStyles = val.map(field => field.thStyle);
      },
      immediate: true
    },
    value: {
      handler: function (val) {
        this.hideDetailEvents = new Array(val?.details?.length).fill(true) || [];
      },
      immediate: true
    }
  },
  computed: {
    hideEvents() {
      return index => this.hideDetailEvents[index];
    },
    hideDetailsExpr() {
      // Only `Net Revenue` has a default open details
      return this.value.pl_aggregate !== 'Net Revenue' && this.hideDetails;
    }
  },
  methods: {
    toggleAggregate() {
      this.hideDetails = !this.hideDetails;
    },
    toggleDetails(index) {
      this.hideDetailEvents[index] = !this.hideDetailEvents[index];
    },
    dataTransform(value) {
      return {
        period_evolution: {
          period1: value.pc_net_revenue,
          period2: value.pc_net_revenue_previous
        },
        evolution_pp: value.pc_net_revenue_evol,
        priority: value.priority
      };
    }
  }
};