import { processDate } from "@/utils";
import { HugValue, ValueElement } from "@/components/tableElements";
export default {
  name: "DateValue",
  emits: ["click"],
  props: {
    value: {
      type: [String, null, Object]
    },
    format: {
      type: String
    },
    id: {
      type: String,
      default: "date-value"
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    align: {
      type: String,
      default: "center"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedText() {
      return processDate(this.value, this.format);
    }
  },
  components: {
    HugValue,
    ValueElement
  }
};