import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d92b26cc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "category-id"
};
const _hoisted_2 = {
  class: "category"
};
const _hoisted_3 = {
  class: "category-name"
};
const _hoisted_4 = {
  class: "dragger"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['category-item', {
      active: $props.active,
      hoverable: $props.hoverable,
      dragging: $props.dragging
    }]),
    onClick: _cache[0] || (_cache[0] = e => {
      if (!$props.active) $options.handleCategoryClick(e);
    })
  }, [_createElementVNode("div", _hoisted_1, _toDisplayString($props.categoryId), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, [_withDirectives(_createVNode(_component_app_icon, {
    icon: "ai",
    size: 10,
    color: $options.getIconColor
  }, null, 8 /* PROPS */, ["color"]), [[_vShow, $props.icons]]), _createElementVNode("div", _hoisted_3, _toDisplayString($props.category), 1 /* TEXT */), _createElementVNode("div", {
    class: _normalizeClass([{
      active: $props.active
    }, 'd-flex justify-content-center align-items-center category-icon'])
  }, [_withDirectives(_createVNode(_component_app_icon, {
    icon: `${$props.active ? 'cross' : 'plus'}`,
    size: 12,
    onClick: $options.handleCategoryClick,
    color: $options.getIconColor
  }, null, 8 /* PROPS */, ["icon", "onClick", "color"]), [[_vShow, $props.icons]])], 2 /* CLASS */)]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_app_icon, {
    icon: "dragger"
  })])], 2 /* CLASS */);
}