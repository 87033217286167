import AppIcon from '@/components/AppIcon';
import AppTextarea from "@/components/AppTextarea";
import AppPopover from "@/components/AppPopover";
import ContentDisplayRulesMixin from "@/mixins/ContentDisplayRulesMixin.vue";
import ValidateMixin from '@/mixins/ValidateMixin.vue';
import ApplyKeywordsIcon from "@/components/icons/ApplyKeywordsIcon.vue";
import { computeLength } from '@/utils';
export default {
  name: 'EditTextModalContent',
  emits: ['update:modelValue', 'hide_comparism', 'apply-keywords'],
  mixins: [ContentDisplayRulesMixin, ValidateMixin],
  components: {
    AppIcon,
    AppTextarea,
    AppPopover,
    ApplyKeywordsIcon
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: "omny"
    },
    show_translation: {
      type: Boolean,
      default: false
    },
    show_comparism: {
      type: Boolean,
      default: false
    },
    content_type: {
      type: String,
      default: ""
    },
    original: {
      type: String,
      default: ''
    },
    allowHtmlInputAndSpecialCharacters: {
      type: Boolean,
      default: false
    },
    character_range: {
      type: Object,
      default: {}
    },
    translation: {
      type: String,
      default: ''
    },
    comparism: {
      type: String,
      default: ''
    },
    apply_keywords_loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      copied: false,
      validationMap: {
        product_name: this.mValidateTitle,
        generic_keyword: this.mValidateKeyword,
        product_description: this.mValidateDescription,
        bullet_point_1: this.mValidateBulletPoints,
        bullet_point_2: this.mValidateBulletPoints,
        bullet_point_3: this.mValidateBulletPoints,
        bullet_point_4: this.mValidateBulletPoints,
        bullet_point_5: this.mValidateBulletPoints
      }
    };
  },
  computed: {
    editText: {
      get() {
        return this.modelValue;
      },
      set(val) {
        let newValue = this.allowHtmlInputAndSpecialCharacters ? val : this.removeHtmlTagsAndSpecialCharacters(val);
        this.$emit('update:modelValue', newValue);
      }
    },
    characterCountInfo() {
      return this.getCharacterCountInfo();
    },
    validation() {
      return this.getValidation();
    }
  },
  methods: {
    getCharacterCountInfo() {
      const valueLength = this.type == 'omny' ? computeLength(this.content_type, this.editText) : computeLength(this.content_type, this.original);
      const isProductType = this.content_type === 'product_name' || this.content_type === 'generic_keyword';
      const maxCharacters = isProductType ? ` / ${this.character_range.max}` : '';
      const plural = valueLength > 2 ? 's' : '';
      return `${valueLength}${maxCharacters} character${plural}`;
    },
    getValidation() {
      const validateMethod = this.validationMap[this.content_type];
      if (validateMethod) {
        const validateText = this.type == 'omny' ? this.modelValue : this.original;
        //console.log("Validate text: ", validateText);
        return validateMethod(validateText);
      }
      return {
        status: 'error',
        text: 'Invalid type'
      };
    },
    onCopy() {
      if (!this.copied) {
        const copyText = this.type == 'omny' ? this.editText : this.original;
        navigator.clipboard.writeText(copyText).then(this.setCopied);
      }
    },
    setCopied() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 5000);
    },
    handleClickOnComparism() {
      if (this.type == "amazon") {
        return;
      }
      this.$emit('hide_comparism');
    },
    onApplyKeywords() {
      this.$emit('apply-keywords');
    }
  }
};