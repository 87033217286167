import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-489c173b"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      getWrapperComputedStyle: $options.getWrapperComputedStyle
    })
  }, [_createElementVNode("img", {
    loading: "lazy",
    style: _normalizeStyle({
      width: `${this.width}px`,
      height: `${this.height}px`
    }),
    src: $props.flag,
    alt: "[]"
  }, null, 12 /* STYLE, PROPS */, _hoisted_1)], 4 /* STYLE */);
}