import CompoundTable from "@/components/CompoundTable.vue";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import LoadingElement from "@/components/shared/LoadingElement.vue";
import { OverlapBarValue, HugValue, DottedValue, DateValue, CurrencyValue, PercentageValue, BoxedValue, BarWithValue, ValueElement, NumberValue, WrapWithArrow } from "@/components/tableElements";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import AppIcon from "@/components/AppIcon.vue";
import { EVENTS, KPI_DIRECTION_TYPE, CVR_HEATMAP_COLORS, CPC_DECILE_COLORS, BEST_POSITION_HEATMAP_COLORS, PERIOD_COLUMN_TITLES, DEFAULT_DATE_FORMATS } from "@/utils/constants";
import { generateColorBasedOnState, getColorsBasedOnRange, getColorForDecile, getDescriptionBasedOnKpiKey } from "@/utils/transformers";
import { mapState, mapGetters } from "vuex";
import { SELECTOR, PERIOD_FIELD, TACOS_FIELD, SPONSORED_ASIN_FIELD, SPEND_FIELD, SPONSORED_SALES_FIELD, CLICKS_FIELD, CPC_FIELD, BAR_ROAS_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, PPC_V2_PERIOD_FIELD } from "@/utils/table_fields";
import FlexRow from "@/components/FlexRow.vue";
import FilterDivider from "@/components/new-filters/FilterDivider.vue";
import AppIconGroup from "@/components/AppIconGroup";
import DownloadMixin from "@/mixins/DownloadMixin.vue";
import { capitalize } from "vue";
export default {
  name: "PerformancePeriodTable",
  components: {
    CompoundTable,
    DottedValue,
    DateValue,
    CurrencyValue,
    PercentageValue,
    BoxedValue,
    ValueElement,
    NumberValue,
    BarWithValue,
    WrapWithPopover,
    WrapWithArrow,
    LoadingElement,
    OverlapBarValue,
    HugValue,
    AppIcon,
    FlexRow,
    FilterDivider,
    AppIconGroup
  },
  emits: ["refresh", "setDateRange"],
  mixins: [LiteTableMixin, DownloadMixin],
  props: {
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      KPI_DIRECTION_TYPE,
      showShadow: false,
      CVR_HEATMAP_COLORS,
      CPC_DECILE_COLORS,
      BEST_POSITION_HEATMAP_COLORS,
      fieldsSources: {
        DEFAULT_FIELDS: [SELECTOR, PERIOD_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, SPONSORED_ASIN_FIELD],
        TARGETING_FIELDS: [SELECTOR, PERIOD_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD]
      },
      csvSources: period => ({
        DEFAULT_FIELDS: {
          [PERIOD_COLUMN_TITLES[period]]: PPC_V2_PERIOD_FIELD.key,
          [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
          [SPEND_FIELD.label]: SPEND_FIELD.key,
          [TACOS_FIELD.label]: TACOS_FIELD.key,
          [CLICKS_FIELD.label]: CLICKS_FIELD.key,
          [CPC_FIELD.label]: CPC_FIELD.key,
          [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
          [CVR_FIELD.label]: CVR_FIELD.key,
          [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key,
          "Sponsored Asin": SPONSORED_ASIN_FIELD.key
        },
        TARGETING_FIELDS: {
          [PERIOD_COLUMN_TITLES[period]]: PPC_V2_PERIOD_FIELD.key,
          [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
          [SPEND_FIELD.label]: SPEND_FIELD.key,
          [CLICKS_FIELD.label]: CLICKS_FIELD.key,
          [CPC_FIELD.label]: CPC_FIELD.key,
          [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
          [CVR_FIELD.label]: CVR_FIELD.key,
          [BAR_ROAS_FIELD.label]: BAR_ROAS_FIELD.key
        }
      })
    };
  },
  computed: {
    ...mapState({
      periodTable: state => state.ppc_v2.periodTable,
      period: state => state.ppc_v2.period,
      tab: state => state.ppc_v2.currentTabValue
    }),
    ...mapGetters(['ppcV2SecondaryFiltersEnabled', 'ppcV2SecondaryFields']),
    items() {
      return this.periodTable[this.tab].items;
    },
    maxCharCount() {
      return this.periodTable[this.tab].maxCharCount;
    },
    minMax() {
      return this.periodTable[this.tab].minMax;
    },
    periodTitle() {
      return this.periodTable[this.tab].title[this.period];
    },
    getPeriodColumnTitle() {
      return PERIOD_COLUMN_TITLES[this.period];
    },
    in_currency() {
      return this.$store.getters.currencyConfigInCurrencyGet();
    },
    getDateValueFormat() {
      // Convert the current period to UPPER CASE
      const period = this.period.toUpperCase();
      return DEFAULT_DATE_FORMATS[period];
    },
    fields() {
      return this.fieldsSources[this.ppcV2SecondaryFields()];
    },
    csvFileName() {
      return `PPC_V2_Performance_Evolution_${capitalize(this.period)}.csv`;
    }
  },
  methods: {
    generateColorBasedOnState,
    getColorsBasedOnRange,
    getColorForDecile,
    getDescriptionBasedOnKpiKey,
    getMinForColumn(column) {
      return this.minMax[column]?.min;
    },
    getMaxForColumn(column) {
      return this.minMax[column]?.max;
    },
    handleDateClick(period) {
      window.$bus.trigger(EVENTS.OPEN_PRODUCT_TABLE_COLLAPSE);
      this.$emit("setDateRange", period);
    },
    handleDateReset() {
      this.$emit("setDateRange", null);
    },
    activateDateValueStyles(row) {
      return row.item.selected ? {
        color: "#312783",
        fontWeight: "600"
      } : {};
    },
    handleRefresh() {
      this.$emit('refresh');
    },
    handleRefreshDatePicker() {
      this.$emit('refresh:datePicker');
    },
    async downloadPerformancePeriodCSV() {
      const csv_headers = this.csvSources(this.period)[this.ppcV2SecondaryFields()];
      const csvFileName = this.csvFileName;
      const str = await this.generateCsvString(csv_headers, this.prepareCsvRowsForDownload(this.items, csv_headers), 1, '-');
      await this.downloadCsv(str, csvFileName);
    }
  }
};