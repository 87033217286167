import { mapGetters, mapActions } from "vuex";
import { isObjectEmpty } from "@/utils";
export default {
  name: "AccountsMixin",
  computed: {
    ...mapGetters(["accountByProviderGet", "workspaceNameById"])
  },
  methods: {
    ...mapActions(["accountList", "workspaceList", "accountProfileList"]),
    async getOmnyAccountSellers() {
      await this.workspaceList({});
      await this.accountList({});
      const sellerCentralAccounts = this.accountByProviderGet("Amazon Seller Central");
      const vendorCentralAccounts = this.accountByProviderGet("Amazon Vendor Central");
      return [...sellerCentralAccounts, ...vendorCentralAccounts].map(account => {
        return {
          id: account.account_id,
          account_id: account.account_id,
          region: account.region,
          account_provider: account.account_provider,
          name: account.account_name || this.workspaceNameById(account.workspace_id)
        };
      });
    },
    async getOmnySellerCentralAccounts() {
      await this.workspaceList({});
      await this.accountList({});
      const sellerCentralAccounts = this.accountByProviderGet("Amazon Seller Central");
      return sellerCentralAccounts.map(account => {
        return {
          id: account.account_id,
          account_id: account.account_id,
          region: account.region,
          name: account.account_name || this.workspaceNameById(account.workspace_id)
        };
      });
    },
    async getOmnyAccountProfileIds(seller_ids = []) {
      await this.accountProfileList({
        seller_id: seller_ids.map(seller => seller.id)
      });
    },
    throwIfError(result) {
      if (result?.status == "error" || isObjectEmpty(result) || !result) {
        throw result;
      }
    },
    announceNoAccounts() {
      window.$bus.trigger("showInfo", {
        message: "No accounts available on the platform at the moment.",
        visible: true,
        delay: 50
      });
    }
  }
};