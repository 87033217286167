import TableBarElement from '@/components/TableBarElement.vue';
import SkeletonItem from '@/components/shared/SkeletonItem.vue';
export default {
  name: 'TotalGrapher',
  components: {
    TableBarElement,
    SkeletonItem
  },
  mounted() {
    //console.log("Data Widths: ", this.dataWidths)
  },
  computed: {
    getTotalColWidths() {
      //console.log("Computed: ", this.dataWidths)
      return this.dataWidths;
    }
  },
  props: {
    graphHeaders: {
      type: Array,
      required: true
    },
    graphData: {
      type: Object,
      required: true
    },
    showTotalItem: {
      type: Boolean,
      default: false
    },
    dataWidths: {
      type: Array,
      default: () => []
    },
    totalLoading: {
      type: Boolean,
      default: false
    },
    notAllowed: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getTotal(field) {
      // Check if the field is `keyword_bid` and the value is <= 0.65, return null
      const value = this.graphData?.[field];
      //console.log(field, value)
      if (this.notAllowed.includes(field)) {
        return null;
      }
      return value;
    },
    getStyle(width) {
      if (!width) {
        return {};
      }
      return {
        width: `${width}%`
      };
    }
  }
};