import OmnyConfirmContinueModal from '@/layouts/OmnyConfirmContinueModal.vue';
export default {
  name: 'PpcStrategiesConfirmUpdateModal',
  components: {
    OmnyConfirmContinueModal
  },
  emits: ['update:modelValue', 'cancel', 'update'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
};