import { fakeDataGenerator } from '@/components/shared/helpers.js';
import ProductSkuAsinItem from '@/components/shared/ProductSkuAsinItem.vue';
import EmptySpace from '@/components/shared/EmptySpace.vue';
import FilterSectionHeader from './FilterSectionHeader.vue';
import SellerFilterItem from './SellerFilterItem.vue';
import CoutryFilterItem from './CountryFilterItem.vue';
import CenterWrapper from '@/components/shared/CenterWrapper.vue';
export default {
  name: 'FilterItemSubSection',
  emits: ['select'],
  props: {
    values: {
      type: Object,
      required: true
    },
    header: {
      type: String,
      default: "",
      required: false
    },
    showIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    filterTitle: {
      type: String,
      default: "",
      required: false
    },
    enableSelectAll: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    fakeDataGenerator(rows) {
      return fakeDataGenerator(rows);
    },
    innerHandleSelect(e, item, only = false) {
      e.stopPropagation();

      // Check if `e.target` contains the id `only`

      if (e.target.id === 'only') {
        // Emit the event with the only flag
        this.$emit('select', {
          item,
          only
        });
      } else {
        // Emit the event without the only flag
        this.$emit('select', {
          item,
          only: false
        });
      }
    },
    getTimeZone(item) {
      if (item.timezone) {
        return item.timezone.replaceAll('_', ' ');
      }
      return '';
    },
    handleSelectAll() {
      this.$emit('select', {
        item: this.values,
        only: false
      });
    }
  },
  components: {
    ProductSkuAsinItem,
    EmptySpace,
    FilterSectionHeader,
    SellerFilterItem,
    CoutryFilterItem,
    CenterWrapper
  }
};