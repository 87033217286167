import { periodsSwitches } from "../components/shared/chartHelpers";
import { getDatePickerUIObjectFromDateRange, DATE_PICKER_COMPARE_TITLES, getDatePickerRangeCompare, DATE_PICKER_RANGE_TITLES } from "@/utils/datepicker";
export default {
  state: {
    datePickerPeriod: getDatePickerUIObjectFromDateRange({
      ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, DATE_PICKER_RANGE_TITLES.LAST_7_DAYS),
      rangeTitle: DATE_PICKER_RANGE_TITLES.LAST_7_DAYS,
      compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
    }),
    globalPeriod: periodsSwitches.DEFAULT[0]
  },
  mutations: {
    setDatePickerPeriod(state, period) {
      state.datePickerPeriod = period;
    },
    setGlobalPeriod(state, period) {
      state.globalPeriod = period;
    }
  },
  getters: {
    datePickerGetPeriod: state => state.datePickerPeriod,
    globalPeriodGetPeriod: state => state.globalPeriod
  },
  actions: {
    datePickerSetPeriod({
      commit
    }, period) {
      commit("setDatePickerPeriod", period);
    },
    globalPeriodSetPeriod({
      commit
    }, period) {
      commit("setGlobalPeriod", period);
    }
  }
};