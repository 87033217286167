import { request } from './index';
const member = {
  state: {
    list: []
  },
  mutations: {
    memberListSet(state, payload) {
      state.list = payload;
    }
  },
  actions: {
    async memberList({
      commit
    }, payload) {
      const data = await request('/workspace/members', 'POST', payload);
      if (data) {
        commit('memberListSet', data.members);
      }
      return data;
    },
    async memberRemove(_, payload) {
      const data = await request('/workspace/members/delete', 'POST', payload);
      if (data) {
        window.$bus.trigger('refreshMemberList', this.refreshMemberList);
      }
      return data;
    },
    async memberCreate(_, payload) {
      const data = await request('/workspace/members/invite', 'POST', payload);
      if (data) {
        window.$bus.trigger('refreshMemberList', this.refreshMemberList);
      }
      return data;
    },
    async memberCheck(_, payload) {
      const data = await request('/workspaces/invitation/check', 'POST', payload);
      return data;
    }
  }
};
export default member;