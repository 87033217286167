import FlexRow from '../FlexRow.vue';
import HugValue from './HugValue.vue';
import BarSlotWithValue from './BarSlotWithValue.vue';
import SignedBar from './bars/SignedBar.vue';
export default {
  name: "SignedBarWithValue",
  props: {
    value: {
      type: [Number, null],
      required: false,
      default: 3301
    },
    color: {
      type: String,
      required: true,
      default: "#312783"
    },
    positiveColor: {
      type: String,
      required: true,
      default: "#312783"
    },
    negativeColor: {
      type: String,
      required: true
    },
    align: {
      type: String,
      required: false,
      default: "right"
    },
    gap: {
      type: Number,
      required: false,
      default: 8
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    tight: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    negativeMin: {
      type: [Number, null],
      default: null
    }
  },
  components: {
    SignedBar,
    HugValue,
    FlexRow,
    BarSlotWithValue
  },
  mounted() {
    console.table(this.$props);
  },
  computed: {
    validValue() {
      return this.value !== null && this.value !== undefined;
    }
  }
};