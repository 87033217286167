import moment from "moment";
import { mapState } from "vuex";
import DatePickerCalendarMonthLayout from "./DatePickerCalendarMonthLayout.vue";
import { getWeeksForMonthYear } from "@/utils";
import { DateValue } from "@/components/tableElements";
import DatePickerCalendarMixin from "../mixins/DatePickerCalendarMixin.vue";
import { generateWeeksBetweenDates, getWeekFromDate, generateMonthsBetweenYears, getWeeksMapFromYearRange } from "../utils/datepicker";
export default {
  name: "DatePickerCalendarWeek",
  emits: ["custom-date", "hover-date", "switch-to-custom", "submit"],
  expose: ["setupCalenderDetails"],
  components: {
    DatePickerCalendarMonthLayout,
    DateValue
  },
  props: {
    range: {
      type: Object,
      default: () => {}
    },
    highlightToday: {
      type: Boolean,
      default: true
    },
    weekFilled: {
      type: Array,
      default: () => []
    },
    weekFilledRanges: {
      type: Array,
      default: () => []
    },
    selectedWeeks: {
      type: Object,
      default: () => ({
        start: null,
        end: null
      })
    },
    monthList: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    /*
      We generate `weeks: Array[Array['string']]` for each `month` `year` combo.
    */
    const today = moment();
    this.weeksMap = getWeeksMapFromYearRange(today.year(), today.clone().add(1, 'year').year());
  },
  data() {
    return {
      rangeCustom: {
        start: null,
        end: null
      },
      weekStack: [],
      weeksFilledRangeStore: [],
      currentlyHoveredWeek: null,
      state: {
        start: null,
        end: null
      },
      weeksMap: new Map()
    };
  },
  mixins: [DatePickerCalendarMixin],
  computed: {
    ...mapState({
      currentWeek: state => state.ppc_settings.currentWeek
    }),
    checkIfWeekIsBehindStack() {
      return (disabled, week) => {
        if (disabled) return disabled;
        if (this.weekStack.length > 0) {
          week = week.map(date => moment(date));
          const firstDayOfSelection = this.weekStack[0][0];
          if (week[0].isBefore(firstDayOfSelection, 'isoWeek')) return true;
        }
        return false;
      };
    },
    checkIfWeekIsAheadStack() {
      return (disabled, week) => {
        if (disabled) return false;
        if (this.weekStack.length === 0) return false;
        week = week.map(date => moment(date));
        const firstSelection = this.weekStack[0];
        const lastDayOfSelection = firstSelection[firstSelection.length - 1];
        const hoveredWeek = this.currentlyHoveredWeek.map(date => moment(date));
        return week[0].isAfter(lastDayOfSelection, 'isoWeek') && week[0].isBefore(hoveredWeek[0], 'isoWeek');
      };
    },
    checkIfWeekIsBetweenStartAndEnd() {
      return (disabled, week) => {
        if (disabled) return false;
        week = week.map(d => moment(d));
        return this.isDateInRange(week[0], {
          start: this.state.start,
          end: this.state.end
        });
      };
    },
    dateIsToday() {
      return date => date === moment().format("YYYY-MM-DD");
    }
  },
  methods: {
    setupCalenderDetails(data) {
      let start = data.start;
      start = getWeekFromDate(start);
      this.state.start = data.start;
      this.state.end = data.end;
      //console.log('weekFilledRanges', this.weekFilledRanges);

      this.weeksFilledRangeStore = this.weekFilledRanges.filter(({
        start_date,
        end_date
      }) => {
        return !(moment(start_date).isSameOrAfter(this.state.start) && moment(end_date).isSameOrBefore(this.state.end));
      });
    },
    isDateInRange(date, range) {
      if (!range.start || !range.end) return false;
      return date.isBetween(range.start, range.end, "day", "[]");
    },
    isDateSelected(date) {
      return this.isDateInRange(date, this.range);
    },
    getDates(month, year) {
      let weeks = this.weeksMap.get(`${month}-${year}`);
      if (!weeks) return [];
      weeks = weeks.map(week => {
        // Date is Array<String>
        // Check if the first day in the list is part of disabled
        const firstDay = week[0];
        const disabled = this.isWeekDateDisabled(firstDay);
        return {
          week,
          disabled
        };
      });
      return weeks;
    },
    getElementByWeek(week) {
      const weekRef = this.$refs[week[0]];
      if (weekRef) {
        return weekRef[0];
      }
    },
    handleWeekClick(week) {
      if (this.weekStack.length === 0) {
        this.state.start = null;
        this.state.end = null;
        // Remove `selected` and `start-range`
        const element = this.getElementByWeek(week);
        if (element) {
          this.weekStack = [...this.weekStack, week.map(d => moment(d))];
          this.state.start = moment(week[0]);
          // Get the previous week
          this.state.end = moment(week[0]);
        }
      } else {
        /*
          Before validating a selections
            i.e., when there is `start` and `end`,
             we need to make sure that between `start` and `end`, there aren't weeks with `disabled`
             we use `generateWeeksBetweenDates` to get all the elements;
            map over to create a `true` or `false` array.
             If there is a single disabled, then we do not validate the selection
        */
        const end = moment(week[0]);
        this.weekStack = [];
        const weeks = generateWeeksBetweenDates(this.state.start, end);
        const disabledWeeks = weeks.map(week => this.getElementByWeek(week)).map(el => el.classList.contains('disabled'));
        if (disabledWeeks.includes(true)) {
          window.$bus.trigger("showInfo", {
            visible: true,
            message: `Disabled dates cannot exists between ${this.state.start.format("DD MMM YYYY")} and ${end.format("DD MMM YYYY")}`
          });
          return;
        }
        this.state.end = end;
        this.$emit('submit', weeks.map(week => week[0]));
      }
    },
    getPreviousWeek(week) {
      // Array<String>
      let _date = moment(week[0]);
      let _prevWeek = _date.subtract(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD");
      let _prevWeekEl = this.$refs[_prevWeek][0];
      return {
        prevWeek: _prevWeek,
        prevWeekEl: _prevWeekEl
      };
    },
    handleCurrentlyHovered(week) {
      this.currentlyHoveredWeek = week;
    }
  }
};