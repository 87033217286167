//import { request } from "@/store/index";

const pdf_render = {
  state: {
    pdfRenderDimension: {
      width: 0,
      height: 0,
      unit: "mm"
    },
    padded: true
  },
  actions: {
    /* 
      - Reset Sort
        This actions will take a state object to look through the global state.
          if no state object is provided, it ignores and returns immediately.
         Signature:
          resetSort({rootState},{moduleName})
    sessionStorage.getItem("useFaker");
        Expects:
          module:
            - sort: {
                field: 'name',
                direction: 'asc',
             }
    */
    resetSort({
      rootState
    }, {
      moduleName
    }) {
      if (!moduleName) return;
      const module = rootState[moduleName];
      if (!module) return;
      if (!module.sort) return;
      module.sort.field = "";
      module.sort.direction = "desc";
    },
    setPadded({
      state
    }, payload) {
      state.padded = payload;
    }
  },
  mutations: {
    pdfRendererDimensionSet(state, payload) {
      state.pdfRenderDimension = payload;
    }
  },
  getters: {
    pdfRendererOrientationGet: state => {
      return () => {
        return state.pdfRenderDimension.orientation;
      };
    },
    pdfRendererDimensionGet: state => {
      return () => {
        const unit = state.pdfRenderDimension.unit;
        return {
          width: `${state.pdfRenderDimension.width}${unit}`,
          height: `${state.pdfRenderDimension.height}${unit}`
        };
      };
    }
  }
};
export default pdf_render;