import AppIcon from '@/components/AppIcon.vue';
import { ref } from "vue";
export default {
  name: 'AppDropdown',
  emits: ['update:modelValue'],
  components: {
    AppIcon
  },
  setup() {
    const arrowIconColor = ref('#4D4D4D');
    return {
      arrowIconColor
    };
  },
  mounted() {
    this.arrowIconColor = this.arrow_icon_color ?? "#4D4D4D";
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    noCaret: {
      type: Boolean,
      default: true
    },
    arrow_icon_color: {
      type: String,
      default: ""
    }
  },
  computed: {
    text() {
      if (this.modelValue) {
        const temp = this.options.find(item => {
          if (typeof item == 'object') {
            return item?.value == this.modelValue;
          } else {
            return item == this.modelValue;
          }
        });
        if (temp) {
          return temp?.text ?? temp;
        }
        return this.modelValue;
      }
      return this.placeholder;
    }
  },
  methods: {
    onClick(val) {
      this.$emit('update:modelValue', val);
    }
  }
};