import { CurrencyValue, HugValue, NumberValue, PercentageValue } from '@/components/tableElements';
export default {
  name: "ProductTableTotal",
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    total: {
      type: Object,
      default: () => ({})
    },
    currencyConfig: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    //console.log("fields", this.fields);
  },
  components: {
    CurrencyValue,
    HugValue,
    NumberValue,
    PercentageValue
  },
  methods: {
    isTotalColumn(field) {
      return ['seller_name', 'sales_channel', 'campaign_group_name', 'campaign_name', 'targeting', 'product_name'].includes(field.key);
    }
  }
};