import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f2c7ef58"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "member-status-body"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1,
  class: "d-flex align-item-center justify-content-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_connection_page_title = _resolveComponent("connection-page-title");
  const _component_b_th = _resolveComponent("b-th");
  const _component_b_tr = _resolveComponent("b-tr");
  const _component_b_thead = _resolveComponent("b-thead");
  const _component_b_td = _resolveComponent("b-td");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_button = _resolveComponent("app-button");
  const _component_b_tbody = _resolveComponent("b-tbody");
  const _component_b_table_simple = _resolveComponent("b-table-simple");
  const _component_app_modal = _resolveComponent("app-modal");
  const _component_create_member_side_bar = _resolveComponent("create-member-side-bar");
  const _component_app_card_connections = _resolveComponent("app-card-connections");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_connection_page_title, {
    class: "",
    visibleButton: $options.canAddMember,
    action: "Add Member",
    searchPlaceHolder: $data.searchPlaceHolder,
    onAction: _cache[0] || (_cache[0] = $event => $data.createMemberSideBar = $options.canAddMember)
  }, null, 8 /* PROPS */, ["visibleButton", "searchPlaceHolder"]), _createVNode(_component_app_card_connections, {
    class: "no-left-right-margin",
    loading: $data.dataLoading,
    "min-height": 300,
    scrollable: false,
    hasBorder: false
  }, {
    default: _withCtx(() => [!$data.dataLoading && $options.paginatedMembers.length > 0 ? (_openBlock(), _createBlock(_component_b_table_simple, {
      key: 0,
      striped: "",
      responsive: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_b_thead, {
        class: "account-table-thead-background"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_tr, null, {
          default: _withCtx(() => [_createVNode(_component_b_th, {
            class: "account-table-thead-background-text"
          }, {
            default: _withCtx(() => [_createTextVNode("Members")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            class: "account-table-thead-background-text"
          }, {
            default: _withCtx(() => [_createTextVNode("Email")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            class: "account-table-thead-background-text"
          }, {
            default: _withCtx(() => [_createTextVNode("Status")]),
            _: 1 /* STABLE */
          }), _createCommentVNode(" <b-th>Role</b-th> "), _createVNode(_component_b_th, {
            style: {
              "width": "0"
            }
          })]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      }), _createVNode(_component_b_tbody, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.paginatedMembers, member => {
          return _openBlock(), _createBlock(_component_b_tr, {
            key: member.id
          }, {
            default: _withCtx(() => [_createVNode(_component_b_td, {
              class: "align-middle"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString($options.memberFullName(member)), 1 /* TEXT */)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, {
              class: "text-nowrap align-middle"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(member.email), 1 /* TEXT */)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createCommentVNode(" <b-td class=\"align-middle\">{{ memberRole(member) }}</b-td> "), _createVNode(_component_b_td, {
              class: "text-nowrap align-middle"
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_badge, {
                style: _normalizeStyle({
                  background: `${$options.memberStatusIconColor(member)} !important`
                })
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString($options.memberStatusText(member)), 1 /* TEXT */)]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["style"]), !member.enabled ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_app_button, {
                loading: member.email == $data.invitedMemberEmail ? $data.inviteSent : null,
                variant: "link",
                onClick: $event => $options.onResendInvitation(member)
              }, {
                default: _withCtx(() => [_createVNode(_component_app_icon, {
                  icon: "stroked-repeat"
                }), _createTextVNode(" Resend link ")]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "onClick"])])) : _createCommentVNode("v-if", true)])]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, null, {
              default: _withCtx(() => [$options.canDeleteMember(member) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_app_button, {
                loading: member.id == $data.removeId ? $data.removeLoading : null,
                variant: "link",
                onClick: $event => $options.onRemoveConfirm(member.id)
              }, {
                default: _withCtx(() => [_createVNode(_component_app_icon, {
                  icon: "remove"
                })]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "onClick"])])) : _createCommentVNode("v-if", true)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */);
        }), 128 /* KEYED_FRAGMENT */))]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    })) : _createCommentVNode("v-if", true), !$data.dataLoading && _ctx.members.length == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, " You have not any members yet ")) : _createCommentVNode("v-if", true), _createVNode(_component_app_modal, {
      modelValue: $data.confirmRemoveModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.confirmRemoveModal = $event),
      title: "Confirm Member Unsubscribing",
      "cancel-title": "No",
      "ok-title": "Yes",
      onOk: $options.onRemove
    }, {
      default: _withCtx(() => [_createTextVNode(" Are you sure you want to unsubscribe " + _toDisplayString($options.memberNameById($data.removeId)) + " for " + _toDisplayString($options.workspaceName) + " workspace? ", 1 /* TEXT */)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "onOk"]), _createVNode(_component_create_member_side_bar, {
      modelValue: $data.createMemberSideBar,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.createMemberSideBar = $event),
      workspace: $options.workspace,
      loading: $data.loading,
      inviteSent: $data.inviteSent,
      onSendMemberInvitation: $options.onSendMemberInvitations
    }, null, 8 /* PROPS */, ["modelValue", "workspace", "loading", "inviteSent", "onSendMemberInvitation"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"])]);
}