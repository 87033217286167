import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-18bff245"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "text"
};
const _hoisted_2 = {
  class: "actions"
};
const _hoisted_3 = {
  class: "body",
  style: {}
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FlexRow = _resolveComponent("FlexRow");
  const _component_FlexColumn = _resolveComponent("FlexColumn");
  return _openBlock(), _createBlock(_component_FlexColumn, {
    id: $props.id,
    classes: `section ${$props.selected ? 'selected' : ''}`
  }, {
    default: _withCtx(() => [_createVNode(_component_FlexRow, {
      gap: 8,
      classes: "header",
      styles: {
        justifyContent: 'space-between',
        padding: '12px 16px'
      }
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "title", {}, undefined, true)]), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "actions", {}, undefined, true)])]),
      _: 3 /* FORWARDED */
    }), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "body", {}, undefined, true)])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["id", "classes"]);
}