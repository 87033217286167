import HugValue from "@/components/tableElements/HugValue.vue";
import LoadingElement from './shared/LoadingElement.vue';
export default {
  name: 'TableSkeletonV2',
  props: {
    field: {
      type: Array
    },
    rows: {
      type: Number,
      default: 14
    },
    showFooter: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LoadingElement,
    HugValue
  },
  data(vm) {
    return {
      items: new Array(vm.rows).fill({})
    };
  }
};