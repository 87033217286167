import FlagValue from "@/components/tableElements/FlagValue.vue";
import HugValue from "@/components/tableElements/HugValue.vue";
import FlexColumn from "@/components/FlexColumn.vue";
import DotElement from "@/components/tableElements/DotElement.vue";
import { COLORS } from "@/utils/constants";
export default {
  name: "CampaignValue",
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "100%"
    },
    align: {
      type: String,
      default: "left"
    }
  },
  data() {
    return {
      campaignStates: {
        PAUSED: COLORS.GRAY,
        ENABLED: COLORS.DARK_GREEN,
        ARCHIVED: COLORS.YELLOW
      }
    };
  },
  components: {
    HugValue,
    FlexColumn,
    FlagValue,
    DotElement
  }
};