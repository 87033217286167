import { mapMutations, mapActions } from 'vuex';
import AppCardCustom from '@/components/AppCardCustom';
import AppSlider from '@/components/AppSlider';
export default {
  name: 'PpcBooster',
  components: {
    AppCardCustom,
    AppSlider
  },
  props: {
    boost: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      levels: [{
        value: 0,
        label: 'OFF',
        tooltip: 'OFF'
      }, {
        value: 2,
        label: '2x',
        tooltip: '2x'
      }, {
        value: 3,
        label: '3x',
        tooltip: '3x'
      }, {
        value: 4,
        label: '4x',
        tooltip: '4x'
      }, {
        value: 5,
        label: '5x',
        tooltip: '5x'
      }, {
        value: 6,
        label: '6x',
        tooltip: '6x'
      }]
    };
  },
  computed: {
    localBoost: {
      get() {
        return this.boost;
      },
      set(val) {
        this.saveBoost(val);
      }
    }
  },
  methods: {
    ...mapMutations(['ppcBoostSet']),
    ...mapActions(['ppcBoostSave']),
    saveBoost(val) {
      this.ppcBoostSet(val);
      this.ppcBoostSave({
        boost: val
      });
    }
  }
};