import LoginCard from '@/components/LoginCard.vue';
import AppAlert from '@/components/AppAlert';
export default {
  name: 'TheWrapperPublic',
  components: {
    LoginCard,
    AppAlert
  },
  props: {
    title: {
      type: String,
      default: null
    },
    scrollable: {
      type: Boolean,
      default: true
    }
  }
};