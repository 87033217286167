import HelperMixin from "@/mixins/HelperMixin";
import { CurrencyValue } from "@/components/tableElements";
import PercentageValue from "@/components/tableElements/PercentageValue";
import PpcBudgetAllocationCircle from "@/components/PpcBudgetAllocationCircle";
import PpcBudgetAllocationWeeklyBudgetInput from "@/components/PpcBudgetAllocationWeeklyBudgetInput";
export default {
  name: "PpcBudgetAllocationWeeklyBudgetCampaign",
  emits: ["update-campaign"],
  mixins: [HelperMixin],
  components: {
    PpcBudgetAllocationCircle,
    PpcBudgetAllocationWeeklyBudgetInput,
    PercentageValue,
    CurrencyValue
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: ""
    },
    currency: {
      type: String
    }
  },
  computed: {
    localModel: {
      get() {
        return this.item.budgetCurrent;
      },
      set(value) {
        this.$emit("update-campaign", {
          ...this.item,
          budgetCurrent: value
        });
      }
    },
    getPercent() {
      return this.item.roas / this.item.maxRoas;
    }
  }
};