import TheSidebarMenu from '@/components/TheSidebarMenu';
import TopAccount from '@/components/TopAccount';
import AppIcon from '@/components/AppIcon';
import { EVENTS } from '@/utils/constants';
export default {
  name: 'TheSidebar',
  props: {
    isCollapsed: {
      type: Boolean,
      default: false
    },
    isHovering: {
      type: Boolean,
      default: false
    },
    handleCollapseBtnClickedToClose: {
      type: Function,
      default: () => {}
    }
  },
  emits: ['handleCollapse', 'handleHovering'],
  data() {
    return {
      openItemText: null,
      collapseBtnClickedToClose: false,
      showTopAccountListView: false
    };
  },
  components: {
    TheSidebarMenu,
    TopAccount,
    AppIcon
  },
  computed: {
    showSmallLogo() {
      return this.isCollapsed && !this.isHovering;
    }
  },
  created() {
    window.$bus.on(EVENTS.CLOSE_SIDEBAR, this.closeSidebar);
  },
  methods: {
    handleCollapseClick() {
      // Check if the sidebar is collapsed
      if (!this.isCollapsed) {
        this.collapseBtnClickedToClose = true;
      } else {
        this.collapseBtnClickedToClose = false;
      }
      this.$emit('handleCollapse', !this.isCollapsed);
    },
    handleMouseEnter(event) {
      const sidebar = this.$refs.sidebar;
      const collapseBtn = this.$refs.collapseBtn;

      // If the sidebar is collapsed and the collapse button is clicked, then open the sidebar
      // if the mouse is over the collapse button, then do nothing
      // if the mouse is over the sidebar, then close the sidebar
      if (this.collapseBtnClickedToClose) {
        if (event.target === collapseBtn || collapseBtn.contains(event.target) || sidebar.contains(event.target)) {
          return;
        } else {
          this.$emit('handleHovering', true);
        }
      }
    },
    handleMouseLeave(event) {
      const sidebar = this.$refs.sidebar;
      const collapseBtn = this.$refs.collapseBtn;

      // Only close the sidebar if it was collapsed and the collapse button was clicked
      if (this.collapseBtnClickedToClose) {
        if (!sidebar.contains(event.relatedTarget) && !collapseBtn.contains(event.relatedTarget)) {
          this.$emit('handleHovering', false);
        }
      }
    },
    handleListViewToggled(show) {
      this.showTopAccountListView = show;
    },
    async onMenuClick(item) {
      this.openItemText = this.openItemText === item.text ? null : item.text;
    },
    closeSidebar() {
      this.handleCollapseClick();
    },
    openSidebar() {
      this.handleCollapseClick();
    }
  }
};