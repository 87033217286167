import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1cae961e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_cross_hatched_bar = _resolveComponent("cross-hatched-bar");
  const _component_variable_styled_bar = _resolveComponent("variable-styled-bar");
  const _component_layer_bars = _resolveComponent("layer-bars");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    class: "layered-bar-with-value",
    styles: $options.computedStyle,
    loading: $props.loading
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true), $options.validValue ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_layer_bars, null, {
      default: _withCtx(() => [_createVNode(_component_cross_hatched_bar, {
        value: $props.value1,
        color: $props.color1,
        min: $props.min1,
        max: $props.max1,
        height: $props.height
      }, null, 8 /* PROPS */, ["value", "color", "min", "max", "height"]), _createVNode(_component_variable_styled_bar, {
        value: $props.value2,
        color: $props.color2,
        min: $props.min2,
        max: $props.max2,
        height: $props.height
      }, null, 8 /* PROPS */, ["value", "color", "min", "max", "height"])]),
      _: 1 /* STABLE */
    })])) : _createCommentVNode("v-if", true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["styles", "loading"]);
}