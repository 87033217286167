import { mapState, mapGetters } from "vuex";
import AppButtonRadioGroup from "@/components/AppButtonRadioGroup.vue";
import DashboardFilters from "@/components/DashboardFilters.vue";
import DashboardV2Mixin from "@/mixins/DashboardV2Mixin.vue";
import NavigationTabs from "@/components/NavigationTabs.vue";
import FlexColumn from "@/components/FlexColumn.vue";
import DateValue from "@/components/tableElements/DateValue.vue";
import PerformanceCards from "@/components/kpi-cards/dashboard_v2/PerformanceCards.vue";
import { EVENTS, APP_PERIOD_DROPDOWN_ELEMENTS } from "@/utils/constants";
import PerformancePeriodTable from "@/components/dashboard_v2/PerformancePeriodTable.vue";
import ListingPeriodTable from "@/components/dashboard_v2/ListingPeriodTable.vue";
import PerformanceProductTable from "@/components/dashboard_v2/PerformanceProductTable.vue";
import ProfitPeriodTable from "@/components/dashboard_v2/ProfitPeriodTable.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import moment from "moment";
import ListingCards from "@/components/kpi-cards/dashboard_v2/ListingCards.vue";
import AdvertisingCards from "@/components/kpi-cards/dashboard_v2/AdvertisingCards.vue";
import AdvertisingPeriodTable from "@/components/dashboard_v2/AdvertisingPeriodTable.vue";
import { DATE_RANGES_FROM_SELECTOR, DATE_PICKER_COMPARE_TITLES, getDatePickerRangeCompare, getDatePickerUIObjectFromDateRange, momentWeekType } from "@/utils/datepicker";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
export default {
  name: "DashboardV2Page",
  components: {
    AppButtonRadioGroup,
    DashboardFilters,
    NavigationTabs,
    FlexColumn,
    DateValue,
    PerformanceCards,
    ListingCards,
    AdvertisingCards,
    PerformancePeriodTable,
    ListingPeriodTable,
    PerformanceProductTable,
    AdvertisingPeriodTable,
    ProfitPeriodTable,
    AppDropdown
  },
  mixins: [DashboardV2Mixin, LiteTableMixin],
  async mounted() {
    // Setup Filters
    const sellers = await this.getOmnyAccountSellers();
    if (sellers.length === 0) {
      this.announceNoAccounts();
      this.periodTableLoading = false;
      this.productTableLoading = false;
      this.kpisLoading = false;
      return;
    }
    await this.initializeFilters({
      sellers
    }, "dashboard_v2", {
      seller: "sellers",
      marketplace: "marketplaces",
      product: "products"
    }, "dashboard/v2");
    this.$store.dispatch("dashboardV2SetProductTableGroupByElements");

    // Set the default date picker date
    this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
      ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
      rangeTitle: this.periodGroupBy,
      compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
    });
    this.storeDatePickerDate(this.defaultDatePickerDate);
    await this.refreshMediator(["KPIS", "PERIOD_TABLE", "PRODUCT_TABLE"]);
    window.$bus.on(EVENTS.REFRESH_DASHBOARD_V2, async () => await this.refreshMediator(["KPIS", "PERIOD_TABLE", "PRODUCT_TABLE"]));
    this.setAllLoading(false);
  },
  data() {
    return {
      today: moment(),
      kpisLoading: true,
      periodTableLoading: true,
      productTableLoading: true,
      defaultDatePickerDate: null,
      mediatorRefreshers: {
        KPIS: this.refreshKpis,
        PERIOD_TABLE: this.refreshPeriodTable,
        PRODUCT_TABLE: this.refreshProductTable
      },
      DATE_RANGES_FROM_SELECTOR
    };
  },
  computed: {
    ...mapState({
      kpis: state => state.dashboard_v2.kpis.data,
      currentPeriod: state => state.dashboard_v2.currentPeriod,
      periodOptions: state => state.dashboard_v2.periodOptions,
      periodSelectorOptions: state => state.dashboard_v2.periodSelectorOptions,
      tabs: state => state.dashboard_v2.tabs,
      productTable: state => state.dashboard_v2.product_table,
      productTableGroupBy: state => state.dashboard_v2.product_table.group_by_text,
      currentTabValue: state => state.dashboard_v2.currentTabValue,
      floatingTableState: state => state.dashboard_v2.product_table.state
    }),
    ...mapGetters(["dashboardV2GetPeriod", "dashboardV2GetPeriodCategory", "dashboardV2GetTab", "getSystemWeekType", "isUserInBetaAccessGroup"]),
    period: {
      set(value) {
        this.$store.dispatch("dashboardV2SetPeriod", value);
        this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
          ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
          rangeTitle: this.periodGroupBy,
          compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
        });
        this.storeDatePickerDate(this.defaultDatePickerDate);
        this.refreshMediator(["KPIS", "PERIOD_TABLE", "PRODUCT_TABLE"]);
      },
      get() {
        return this.dashboardV2GetPeriod();
      }
    },
    periodCategory: {
      set(value) {
        this.$store.dispatch("dashboardV2SetPeriodCategory", value);
      },
      get() {
        return this.dashboardV2GetPeriodCategory();
      }
    },
    tab: {
      async set(value) {
        //console.log()
        this.$store.dispatch("dashboardV2SetTab", {
          tabIndex: value,
          tabValue: this.tabs[value].value
        });
      },
      get() {
        return this.dashboardV2GetTab();
      }
    },
    periodGroupBy() {
      return this.getPeriodGroupFromCurrentPeriod(this.currentPeriod, this.period);
    },
    getCurrentPeriodDate() {
      if (this.period === APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value) {
        return momentWeekType[this.getSystemWeekType]();
      }
      return this.currentPeriod[this.period].date;
    }
  },
  methods: {
    setAllLoading(value) {
      this.kpisLoading = value;
    },
    async refreshKpis() {
      this.kpisLoading = true;
      await this.$store.dispatch("dashboardV2FetchKpis");
      this.kpisLoading = false;
    },
    async refreshPeriodTable() {
      this.periodTableLoading = true;
      await this.$store.dispatch("dashboardV2FetchPeriodTableData");
      this.periodTableLoading = false;
    },
    async refreshMediator(refresheeList) {
      let promises = refresheeList.map(refreshee => this.mediatorRefreshers[refreshee]());
      await Promise.all(promises);
    },
    async refreshProductTable() {
      this.productTableLoading = true;
      const groupByFetch = this.productTable[this.currentTabValue].data[this.productTableGroupBy]?.fetch;
      if (groupByFetch) await this.$store.dispatch(groupByFetch);
      this.productTableLoading = false;
    },
    storeDatePickerDate(date) {
      this.$store.dispatch("dashboardV2SetProductTablePeriod", date);
      window.$bus.trigger(EVENTS.RESET_DATE_PICKER, date);
      window.$bus.trigger(EVENTS.SETUP_DATE_PICKER);
    },
    async handleDateChange(date) {
      if (!date) {
        date = this.defaultDatePickerDate;
      }
      this.storeDatePickerDate(date);
      await this.refreshMediator(["KPIS", "PRODUCT_TABLE"]);
    },
    handleSetPeriodTable(date) {
      this.handleSetLiteTablePeriod(date, this.period, this.handleDateChange);
    },
    handleProductTableSort({
      sort,
      direction
    }) {
      this.$store.dispatch("dashboardV2SetProductTableSort", {
        sort,
        direction
      });
      this.refreshMediator(["PRODUCT_TABLE"]);
    }
  }
};