import AppTd from "@/components/AppTd";
import SeoProductCell from "@/components/seo/SeoProductCell.vue";
import SeoImagesCountCell from "@/components/seo/SeoImagesCountCell.vue";
import SeoBulletPointCell from "@/components/seo/SeoBulletPointCell.vue";
import SeoSearchTermsCell from "@/components/seo/SeoSearchTermsCell.vue";
import SeoDescriptionCell from "@/components/seo/SeoDescriptionCell.vue";
import SeoImageCell from "@/components/seo/SeoImageCell.vue";
import SeoAmazonScoreCell from "@/components/seo/SeoAmazonScoreCell.vue";
import SeoOmnyScoreCell from "@/components/seo/SeoOmnyScoreCell.vue";
import SeoPushStatusCell from "@/components/seo/SeoPushStatusCell.vue";
import SeoPencilEditCell from "@/components/seo/SeoPencilEditCell.vue";
import SeoCheckBoxCell from "@/components/seo/SeoCheckBoxCell.vue";
export default {
  name: 'SeoRow',
  components: {
    AppTd,
    SeoProductCell,
    SeoImagesCountCell,
    SeoBulletPointCell,
    SeoSearchTermsCell,
    SeoDescriptionCell,
    SeoImageCell,
    SeoAmazonScoreCell,
    SeoOmnyScoreCell,
    SeoPushStatusCell,
    SeoPencilEditCell,
    SeoCheckBoxCell
  },
  emits: ['selectItem', 'onEdit', 'onPaste', 'expandImageList', 'onEditSingleItem', 'onConfirmPushSingleContent', 'isCopied', 'onCopyingHover', 'onCopyingHoverLeave'],
  props: {
    tableItems: Array,
    isImageCellCollapsed: Boolean,
    pushModal: Boolean,
    isCopying: Boolean
  }
};