import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-25be22d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "connection-page-tabs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_connection_tabs_renderer = _resolveComponent("connection-tabs-renderer");
  const _component_AppPagination = _resolveComponent("AppPagination");
  const _component_omny_ui_layout = _resolveComponent("omny-ui-layout");
  return _openBlock(), _createBlock(_component_omny_ui_layout, {
    contentPadding: "0 0px",
    showPagination: $data.showPagination
  }, {
    pagination: _withCtx(() => [_createVNode(_component_AppPagination, {
      page: $data.page,
      "onUpdate:page": _cache[1] || (_cache[1] = $event => $data.page = $event),
      perPage: $data.perPage,
      "onUpdate:perPage": [_cache[2] || (_cache[2] = $event => $data.perPage = $event), $options.updatePerPage],
      total: $data.totalPages
    }, null, 8 /* PROPS */, ["page", "perPage", "total", "onUpdate:perPage"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_connection_tabs_renderer, {
      modelValue: $data.currentTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.currentTab = $event),
      tabs: $data.tabs
    }, null, 8 /* PROPS */, ["modelValue", "tabs"]), (_openBlock(), _createBlock(_resolveDynamicComponent($options.currentComponent), {
      key: $data.currentTab,
      page: $data.page,
      perPage: $data.perPage,
      onUpdateTotal: $options.handleTotalUpdate
    }, null, 40 /* PROPS, NEED_HYDRATION */, ["page", "perPage", "onUpdateTotal"]))])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["showPagination"]);
}