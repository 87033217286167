import HugValue from "./HugValue.vue";
import DotElement from "./DotElement.vue";
export default {
  name: "DottedValue",
  components: {
    HugValue,
    DotElement
  },
  props: {
    color: {
      type: String,
      required: true,
      default: "#312783"
    },
    align: {
      type: String,
      required: false,
      default: "center"
    },
    id: {
      type: String,
      required: false,
      default: "dotted-value"
    },
    value: {
      type: [Number, String, null],
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: [String, Number],
      required: false,
      default: 8
    },
    gap: {
      type: Number,
      default: 4
    },
    styles: {
      type: Object
    }
  },
  computed: {
    getComputedStyle() {
      return {
        ...this.styles,
        gap: `${this.gap}px`
      };
    },
    computedAlignment() {
      if (this.value === null) return "right";
      return this.align;
    },
    showDot() {
      return this.value !== null && this.value !== undefined;
    }
  }
};