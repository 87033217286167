import CompositeColorfulBar from "@/components/tableElements/CompositeColorfulBar.vue";
import { processDate } from "@/utils";
import { capitalize } from "vue";
import FloatingCompoundTable from "@/components/FloatingCompoundTable.vue";
import { EVENTS, TREND_DIRECTION_TYPE, KPI_DIRECTION_TYPE, DAYS_INVENTORY_HEATMAP_COLORS, ORGANIC_CVR_HEATMAP_COLORS, CVR_HEATMAP_COLORS, BUYBOX_HEATMAP_COLORS, KEYWORD_BID_HEATMAP_COLORS, CPC_DECILE_COLORS, COLORS, TARGETING_TYPE_SPLIT, TARGETING_TYPE_TYPES, ROAS_DECILE_COLORS, TARGETING_TYPE_SPLIT_KEYMAP, ANALYTICS_CSV_PAGE_SIZE, AD_PRODUCT_KEYMAP, HANDLE_ADD_FILTER_MODES } from "@/utils/constants";
import { generateColorBasedOnState, getColorForDecile, getColorsBasedOnRange, getDescriptionBasedOnKpiKey, getCampaignsStateColor } from "@/utils/transformers";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import ProductTableGroupBy from "@/components/ppc_v2/ProductTableGroupBy.vue";
import { mapState, mapGetters } from "vuex";
import { SPONSORED_ASIN_FIELD, SPONSORED_SALES_FIELD, UNITS_SOLD_FIELD, TACOS_FIELD, CPC_FIELD, CVR_FIELD, ROAS_FIELD, SPEND_FIELD, CLICKS_FIELD, CAMPAIGN_BUDGET_FIELD, TIME_IN_BUDGET_FIELD, TARGETING_TYPE_FIELD, MARKETPLACE_FIELD, SELLER_FIELD, PRODUCT_FIELD, CAMPAIGN_GROUP_FIELD, CAMPAIGN_NAME_FIELD, TARGETING_FIELD, KEYWORD_BID_FIELD, BIG_ROAS_FIELD, PPC_SALES_FIELD, BAR_ROAS_FIELD, AD_FORMAT_FIELD } from "@/utils/table_fields";
import FlexRow from "@/components/FlexRow.vue";
import { NumberValue, BoxedValue, BarWithValue, PercentageValue, RatingValue, DottedValue, OverlapBarValue, RateChangeValue, CurrencyValue, ValueElement, HugValue, MarketplaceValue, ProductValue, CampaignValue, TargetingValue, ProductGroupValue } from "@/components/tableElements";
import WrapWithArrow from "@/components/tableElements/WrapWithArrow.vue";
import FilterDivider from "@/components/new-filters/FilterDivider.vue";
import AppIconGroup from "@/components/AppIconGroup";
import ProductTableTotal from "@/components/ppc_v2/ProductTableTotal.vue";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import BarSlotWithValue from "@/components/tableElements/BarSlotWithValue.vue";
import LayerBars from "@/components/tableElements/LayerBars.vue";
import VariableStyledBar from "@/components/tableElements/VariableStyledBar.vue";
import TargetingTypeValue from "@/components/tableElements/TargetingTypeValue.vue";
import StrokedSafeIcon from "@/components/icons/StrokedSafeIcon.vue";
import ToggleNumberIcon from "@/components/icons/ToggleNumberIcon.vue";
import ToggleNumberCheckedIcon from "@/components/icons/ToggleNumberCheckedIcon.vue";
import CoinsStackedIcon from "@/components/icons/CoinsStackedIcon.vue";
import DownloadMixin from "@/mixins/DownloadMixin.vue";
import { PPC_EXTRA_FILTERS } from "../../utils/constants";
export default {
  name: "PerformanceProductTable",
  components: {
    FloatingCompoundTable,
    WrapWithPopover,
    ProductTableGroupBy,
    FlexRow,
    NumberValue,
    BoxedValue,
    RatingValue,
    BarWithValue,
    PercentageValue,
    DottedValue,
    OverlapBarValue,
    WrapWithArrow,
    RateChangeValue,
    CurrencyValue,
    ValueElement,
    HugValue,
    ProductTableTotal,
    MarketplaceValue,
    ProductValue,
    FilterDivider,
    AppIconGroup,
    ProductGroupValue,
    CampaignValue,
    TargetingValue,
    BarSlotWithValue,
    LayerBars,
    VariableStyledBar,
    TargetingTypeValue,
    StrokedSafeIcon,
    ToggleNumberIcon,
    ToggleNumberCheckedIcon,
    CoinsStackedIcon,
    CompositeColorfulBar
  },
  emits: ["refresh", "refreshOtherKpis", "changeDate"],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [LiteTableMixin, DownloadMixin],
  data() {
    return {
      noSortColumns: [BIG_ROAS_FIELD.key],
      EVENTS,
      TREND_DIRECTION_TYPE,
      KPI_DIRECTION_TYPE,
      DAYS_INVENTORY_HEATMAP_COLORS,
      ORGANIC_CVR_HEATMAP_COLORS,
      CVR_HEATMAP_COLORS,
      BUYBOX_HEATMAP_COLORS,
      CPC_DECILE_COLORS,
      KEYWORD_BID_HEATMAP_COLORS,
      TARGETING_TYPE_SPLIT,
      TARGETING_TYPE_TYPES,
      COLORS,
      BIG_ROAS_FIELD,
      ROAS_DECILE_COLORS,
      AD_PRODUCT_KEYMAP,
      fieldsSources: {
        strategic: {
          seller: {
            DEFAULT_FIELDS: [SELLER_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, PPC_SALES_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, TIME_IN_BUDGET_FIELD, SPONSORED_ASIN_FIELD],
            TARGETING_FIELDS: [SELLER_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD]
          },
          marketplace: {
            DEFAULT_FIELDS: [MARKETPLACE_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, PPC_SALES_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, TIME_IN_BUDGET_FIELD, SPONSORED_ASIN_FIELD],
            TARGETING_FIELDS: [MARKETPLACE_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, SPONSORED_ASIN_FIELD]
          },
          product: {
            DEFAULT_FIELDS: [PRODUCT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, PPC_SALES_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [PRODUCT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD]
          },
          campaign_group: {
            DEFAULT_FIELDS: [CAMPAIGN_GROUP_FIELD, {
              ...CAMPAIGN_BUDGET_FIELD,
              key: "campaign_group_budget"
            }, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [CAMPAIGN_GROUP_FIELD, {
              ...CAMPAIGN_BUDGET_FIELD,
              key: "campaign_group_budget"
            }, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, PPC_SALES_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, TIME_IN_BUDGET_FIELD]
          },
          campaign: {
            DEFAULT_FIELDS: [CAMPAIGN_NAME_FIELD, CAMPAIGN_BUDGET_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [CAMPAIGN_NAME_FIELD, CAMPAIGN_BUDGET_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, BAR_ROAS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, TIME_IN_BUDGET_FIELD]
          },
          targeting: {
            DEFAULT_FIELDS: [TARGETING_FIELD, TARGETING_TYPE_FIELD, KEYWORD_BID_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [TARGETING_FIELD, TARGETING_TYPE_FIELD, KEYWORD_BID_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD]
          },
          ad_format: {
            DEFAULT_FIELDS: [AD_FORMAT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [AD_FORMAT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD]
          }
        },
        performance: {
          seller: {
            DEFAULT_FIELDS: [SELLER_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD, SPONSORED_ASIN_FIELD],
            TARGETING_FIELDS: [SELLER_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD]
          },
          marketplace: {
            DEFAULT_FIELDS: [MARKETPLACE_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD, SPONSORED_ASIN_FIELD],
            TARGETING_FIELDS: [MARKETPLACE_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD, SPONSORED_ASIN_FIELD]
          },
          product: {
            DEFAULT_FIELDS: [PRODUCT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [PRODUCT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD]
          },
          campaign_group: {
            DEFAULT_FIELDS: [CAMPAIGN_GROUP_FIELD, {
              ...CAMPAIGN_BUDGET_FIELD,
              key: "campaign_group_budget"
            }, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [CAMPAIGN_GROUP_FIELD, {
              ...CAMPAIGN_BUDGET_FIELD,
              key: "campaign_group_budget"
            }, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD]
          },
          campaign: {
            DEFAULT_FIELDS: [CAMPAIGN_NAME_FIELD, CAMPAIGN_BUDGET_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [CAMPAIGN_NAME_FIELD, CAMPAIGN_BUDGET_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD]
          },
          targeting: {
            DEFAULT_FIELDS: [TARGETING_FIELD, KEYWORD_BID_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [TARGETING_FIELD, KEYWORD_BID_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD]
          },
          ad_format: {
            DEFAULT_FIELDS: [AD_FORMAT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD],
            TARGETING_FIELDS: [AD_FORMAT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, BAR_ROAS_FIELD, TIME_IN_BUDGET_FIELD]
          }
        }
      },
      csvSources: {
        strategic: {
          seller: {
            DEFAULT_FIELDS: {
              [SELLER_FIELD.label]: SELLER_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
              [TACOS_FIELD.label]: TACOS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key,
              [SPONSORED_ASIN_FIELD.label]: SPONSORED_ASIN_FIELD.key
            },
            TARGETING_FIELDS: {
              [SELLER_FIELD.label]: SELLER_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key
            }
          },
          marketplace: {
            DEFAULT_FIELDS: {
              [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
              [TACOS_FIELD.label]: TACOS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key,
              [SPONSORED_ASIN_FIELD.label]: SPONSORED_ASIN_FIELD.key
            },
            TARGETING_FIELDS: {
              [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key
            }
          },
          product: {
            DEFAULT_FIELDS: {
              [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
              Sku: "sku",
              Asin: "asin",
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
              [TACOS_FIELD.label]: TACOS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key,
              [SPONSORED_ASIN_FIELD.label]: SPONSORED_ASIN_FIELD.key
            },
            TARGETING_FIELDS: {
              [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
              Sku: "sku",
              Asin: "asin",
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key
            }
          },
          campaign_group: {
            DEFAULT_FIELDS: {
              [CAMPAIGN_GROUP_FIELD.label]: CAMPAIGN_GROUP_FIELD.key,
              [CAMPAIGN_BUDGET_FIELD.label]: 'campaign_group_budget',
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            },
            TARGETING_FIELDS: {
              [CAMPAIGN_BUDGET_FIELD.label]: 'campaign_group_budget',
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            }
          },
          campaign: {
            DEFAULT_FIELDS: {
              [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
              [CAMPAIGN_NAME_FIELD.label]: CAMPAIGN_NAME_FIELD.key,
              "Ad Product": "ad_product",
              [CAMPAIGN_BUDGET_FIELD.label]: CAMPAIGN_BUDGET_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [PPC_SALES_FIELD.label]: PPC_SALES_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            },
            TARGETING_FIELDS: {
              [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
              [CAMPAIGN_NAME_FIELD.label]: CAMPAIGN_NAME_FIELD.key,
              "Ad Product": "ad_product",
              [CAMPAIGN_BUDGET_FIELD.label]: CAMPAIGN_BUDGET_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key
            }
          },
          targeting: {
            DEFAULT_FIELDS: {
              [TARGETING_FIELD.label]: TARGETING_FIELD.key,
              [TARGETING_TYPE_FIELD.label]: TARGETING_TYPE_FIELD.key,
              [KEYWORD_BID_FIELD.label]: KEYWORD_BID_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            },
            TARGETING_FIELDS: {
              [TARGETING_FIELD.label]: TARGETING_FIELD.key,
              [TARGETING_TYPE_FIELD.label]: TARGETING_TYPE_FIELD.key,
              [KEYWORD_BID_FIELD.label]: KEYWORD_BID_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            }
          }
        },
        performance: {
          seller: {
            DEFAULT_FIELDS: {
              [SELLER_FIELD.label]: SELLER_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [TACOS_FIELD.label]: TACOS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key,
              [SPONSORED_ASIN_FIELD.label]: SPONSORED_ASIN_FIELD.key
            },
            TARGETING_FIELDS: {
              [SELLER_FIELD.label]: SELLER_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            }
          },
          marketplace: {
            DEFAULT_FIELDS: {
              [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [TACOS_FIELD.label]: TACOS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key,
              [SPONSORED_ASIN_FIELD.label]: SPONSORED_ASIN_FIELD.key
            },
            TARGETING_FIELDS: {
              [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [TACOS_FIELD.label]: TACOS_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key,
              [SPONSORED_ASIN_FIELD.label]: SPONSORED_ASIN_FIELD.key
            }
          },
          product: {
            DEFAULT_FIELDS: {
              [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
              Sku: "sku",
              Asin: "asin",
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            },
            TARGETING_FIELDS: {
              [PRODUCT_FIELD.label]: PRODUCT_FIELD.key,
              Sku: "sku",
              Asin: "asin",
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            }
          },
          campaign_group: {
            DEFAULT_FIELDS: {
              [CAMPAIGN_GROUP_FIELD.label]: CAMPAIGN_GROUP_FIELD.key,
              [CAMPAIGN_BUDGET_FIELD.label]: 'campaign_group_budget',
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            },
            TARGETING_FIELDS: {
              [CAMPAIGN_GROUP_FIELD.label]: CAMPAIGN_GROUP_FIELD.key,
              [CAMPAIGN_BUDGET_FIELD.label]: 'campaign_group_budget',
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            }
          },
          campaign: {
            DEFAULT_FIELDS: {
              [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
              [CAMPAIGN_NAME_FIELD.label]: CAMPAIGN_NAME_FIELD.key,
              "Ad Product": "ad_product",
              [CAMPAIGN_BUDGET_FIELD.label]: CAMPAIGN_BUDGET_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            },
            TARGETING_FIELDS: {
              [MARKETPLACE_FIELD.label]: MARKETPLACE_FIELD.key,
              [CAMPAIGN_NAME_FIELD.label]: CAMPAIGN_NAME_FIELD.key,
              "Ad Product": "ad_product",
              [CAMPAIGN_BUDGET_FIELD.label]: CAMPAIGN_BUDGET_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            }
          },
          targeting: {
            DEFAULT_FIELDS: {
              [TARGETING_FIELD.label]: TARGETING_FIELD.key,
              [KEYWORD_BID_FIELD.label]: KEYWORD_BID_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            },
            TARGETING_FIELDS: {
              [TARGETING_FIELD.label]: TARGETING_FIELD.key,
              [KEYWORD_BID_FIELD.label]: KEYWORD_BID_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            }
          },
          ad_format: {
            DEFAULT_FIELDS: {
              [AD_FORMAT_FIELD.label]: AD_FORMAT_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            },
            TARGETING_FIELDS: {
              [AD_FORMAT_FIELD.label]: AD_FORMAT_FIELD.key,
              [SPONSORED_SALES_FIELD.label]: SPONSORED_SALES_FIELD.key,
              [SPEND_FIELD.label]: SPEND_FIELD.key,
              [CLICKS_FIELD.label]: CLICKS_FIELD.key,
              [CPC_FIELD.label]: CPC_FIELD.key,
              [UNITS_SOLD_FIELD.label]: UNITS_SOLD_FIELD.key,
              [CVR_FIELD.label]: CVR_FIELD.key,
              [ROAS_FIELD.label]: ROAS_FIELD.key,
              [TIME_IN_BUDGET_FIELD.label]: TIME_IN_BUDGET_FIELD.key
            }
          }
        }
      },
      CLICK_TO_FILTER_OPTIONS: {
        seller: this.sellersClickToFilter,
        marketplace: this.marketplacesClickToFilter,
        product: this.productsClickToFilter,
        ad_format: this.adFormatsClickToFilter,
        campaign_group: this.campaignGroupsClickToFilter,
        campaign: this.campaignsClickToFilter,
        targeting: this.targetingsClickToFilter
      },
      CLICK_TO_CLEAR_FILTER_OPTIONS: {
        seller: this.sellersClickToClearFilter,
        marketplace: this.marketplacesClickToClearFilter,
        product: this.productsClickToClearFilter,
        ad_format: this.adFormatsClickToClearFilter,
        campaign_group: this.campaignGroupsClickToClearFilter,
        campaign: this.campaignsClickToClearFilter,
        targeting: this.targetingsClickToClearFilter
      }
    };
  },
  computed: {
    ...mapState({
      groupBy: state => state.ppc_v2.product_table.group_by_text,
      productTable: state => state.ppc_v2.product_table,
      currentTabValue: state => state.ppc_v2.currentTabValue,
      periodCategory: state => state.ppc_v2.periodCategory,
      currencyConfig: state => state.ppc_v2.currencyConfig,
      sellers: state => state.ppc_v2.sellers,
      marketplaces: state => state.ppc_v2.marketplaces,
      products: state => state.ppc_v2.products,
      ad_formats: state => state.ppc_v2.ad_formats,
      campaigns: state => state.ppc_v2.campaigns,
      targetings: state => state.ppc_v2.targetings,
      keywords: state => state.ppc_v2.keywords
    }),
    ...mapGetters(['ppcV2SecondaryFiltersEnabled', 'ppcV2SecondaryFields']),
    items() {
      return this.productTable[this.currentTabValue].data[this.groupBy]?.items;
    },
    total() {
      return this.productTable[this.currentTabValue].data[this.groupBy]?.total;
    },
    minMax() {
      return this.productTable[this.currentTabValue].data[this.groupBy]?.minMax;
    },
    sort() {
      return this.productTable[this.currentTabValue].data[this.groupBy]?.sort;
    },
    maxCharCount() {
      return this.productTable[this.currentTabValue].data[this.groupBy]?.maxCharCount;
    },
    fields() {
      return this.fieldsSources[this.currentTabValue]?.[this.groupBy]?.[this.ppcV2SecondaryFields()];
    },
    datePickerDate() {
      return this.productTable[this.currentTabValue].period;
    },
    page: {
      set(val) {
        this.$store.dispatch("ppcV2SetProductTablePage", val);
        this.$emit("refresh");
      },
      get() {
        return this.$store.getters.ppcV2GetProductTablePage();
      }
    },
    perPage: {
      set(val) {
        this.$store.dispatch("ppcV2SetProductTablePerPage", val);
        this.$emit("refresh");
      },
      get() {
        return this.$store.getters.ppcV2GetProductTablePerPage();
      }
    },
    totalPages: {
      set(val) {
        this.$store.dispatch("ppcV2SetProductTableTotalPages", val);
      },
      get() {
        return this.$store.getters.ppcV2GetProductTableTotalPages();
      }
    },
    groupByFetcher() {
      return this.productTable[this.currentTabValue].data[this.groupBy].fetch;
    },
    csvFileName() {
      const dateRange = `${processDate(this.datePickerDate?.range.start, 'DDMMM')}-${processDate(this.datePickerDate?.range.end, 'DDMMMYYYY')}`;
      return `PPC_V2_${capitalize(this.currentTabValue)}_${capitalize(this.groupBy)}_${dateRange}.csv`;
    }
  },
  methods: {
    generateColorBasedOnState,
    getColorForDecile,
    getColorsBasedOnRange,
    getDescriptionBasedOnKpiKey,
    getCampaignsStateColor,
    getHeaderWrapWithPopoverStyle(col) {
      if (['seller_name', 'sales_channel', 'spend', 'sponsored_sales', 'sponsored_asin', 'revenue_excl_VAT', 'product_name', 'page_views', 'return_rate'].includes(col)) {
        return {
          width: '100%'
        };
      } else {
        return {};
      }
    },
    handleDatePickerSubmit(date) {
      this.$emit("changeDate", date);
      window.$bus.trigger(EVENTS.FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE);
    },
    handleTableState(state) {
      this.$store.dispatch("ppcV2SetProductTableState", state);
    },
    handleGroupByRefresh() {
      this.$emit('refresh');
    },
    handleClicked() {
      window.$bus.trigger(EVENTS.FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE);
    },
    handleHeadClick(fieldKey) {
      const directionFlip = {
        "asc": "desc",
        "desc": "asc"
      };
      if (this.loading) return;
      const direction = directionFlip[this.sort[fieldKey]] ?? "desc";
      this.handleSort({
        sort: fieldKey,
        direction
      });
    },
    async handleBreakdownReset() {
      const clickToClearFilter = this.CLICK_TO_CLEAR_FILTER_OPTIONS[this.groupBy];
      await clickToClearFilter();
    },
    async handleBreakdownSelect(item) {
      const clickToFilter = this.CLICK_TO_FILTER_OPTIONS[this.groupBy];
      await clickToFilter(item);
    },
    async sellersClickToFilter(item) {
      this.sellers.selectOnly({
        id: item.seller_id
      }, 'id');
      this.sellers.apply();
      this.$emit('refreshOtherKpis');
    },
    async sellersClickToClearFilter() {
      // Set the `seller` filter
      this.sellers.clear();
      this.sellers.apply();
      await window.$bus.trigger(EVENTS.REFRESH_SELLERS_SUB_FILTERS);
      this.$emit('refreshOtherKpis');
    },
    async marketplacesClickToFilter(item) {
      this.marketplaces.selectOnly({
        sales_channel: item.sales_channel
      }, 'sales_channel');

      // Set the current group by to `product`
      this.marketplaces.apply();
      await window.$bus.trigger(EVENTS.REFRESH_MARKETPLACES_SUB_FILTERS);
      this.$emit('refreshOtherKpis');
    },
    async marketplacesClickToClearFilter() {
      // Set the `marketplace` filter
      this.marketplaces.clear();
      this.marketplaces.apply();
      await window.$bus.trigger(EVENTS.REFRESH_MARKETPLACES_SUB_FILTERS);
      await this.$emit('refreshOtherKpis');
    },
    async productsClickToFilter(item) {
      await this.productsSearchAndSelect([item.sku]);
    },
    async productsClickToClearFilter() {
      await this.productsReset();
    },
    async adFormatsClickToFilter(item) {
      this.ad_formats.selectOnly({
        ad_format: item.ad_product
      }, "ad_format");
      this.ad_formats.apply();
      window.$bus.trigger(EVENTS.ADD_PPC_FILTER, {
        value: PPC_EXTRA_FILTERS.AD_FORMAT,
        mode: HANDLE_ADD_FILTER_MODES.SILENT
      });
      this.$emit('refreshOtherKpis');
    },
    async adFormatsClickToClearFilter() {
      this.ad_formats.clear();
      this.ad_formats.apply();
      this.$emit('refreshOtherKpis');
    },
    async productsReset() {
      await this.clearAndRefresh({
        entity: this.products,
        filters: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        },
        postActions: [() => this.$emit('refreshOtherKpis')]
      });
    },
    async productsSearchAndSelect(keyword) {
      this.products.clear();
      this.products.apply();
      let res = await this.products.asyncSearchAndSelect({
        keyword,
        args: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        }
      });
      if (!res) {
        return;
      }
      this.products.apply();
      this.$emit('refreshOtherKpis');
    },
    async campaignsSearchAndSelect(keyword) {
      await this.searchAndSelect({
        entity: this.campaigns,
        keyword,
        filters: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        },
        postActions: [() => this.$emit('refreshOtherKpis')],
        eventTrigger: {
          name: EVENTS.ADD_PPC_FILTER,
          payload: {
            value: PPC_EXTRA_FILTERS.CAMPAIGN,
            mode: HANDLE_ADD_FILTER_MODES.SILENT
          }
        }
      });
    },
    async campaignsReset() {
      await this.clearAndRefresh({
        entity: this.campaigns,
        filters: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        },
        postActions: [() => this.$emit('refreshOtherKpis')]
      });
    },
    async campaignGroupsClickToFilter(item) {
      // Set the `campaign_group` filter
      const campaign_id_list = item?.campaign_id_list ?? [];
      await this.campaignsSearchAndSelect(campaign_id_list);
    },
    async campaignGroupsClickToClearFilter() {
      await this.campaignsReset();
    },
    async campaignsClickToFilter(item) {
      await this.campaignsSearchAndSelect([item.campaign_id]);
    },
    async campaignsClickToClearFilter() {
      await this.campaignsReset();
    },
    async targetingsClickToFilter(item) {
      await this.searchAndSelect({
        entity: this.targetings,
        keyword: item.keyword_id_list,
        filters: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        },
        postActions: [() => this.$emit('refreshOtherKpis')],
        eventTrigger: {
          name: EVENTS.ADD_PPC_FILTER,
          payload: {
            value: PPC_EXTRA_FILTERS.TARGETING,
            mode: HANDLE_ADD_FILTER_MODES.SILENT
          }
        }
      });
    },
    async targetingsClickToClearFilter() {
      await this.clearAndRefresh({
        entity: this.targetings,
        filters: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        },
        postActions: [() => this.$emit('refreshOtherKpis')]
      });
    },
    async handleRowClick(item) {
      const ppcProductTable = this.$refs.ppcProductTable;
      if (ppcProductTable) {
        ppcProductTable.handleNormalize();
      }
      this.handleBreakdownRowClicked(item, this.handleBreakdownReset, this.handleBreakdownSelect, this.items);
    },
    handleShowComposites(_, index) {
      const spendComposite = this.$refs[`spendComposite_${index}`];
      const sponsoredSalesComposite = this.$refs[`sponsoredSalesComposite_${index}`];
      if (spendComposite && sponsoredSalesComposite) {
        sponsoredSalesComposite.setShowCurrencies();
        spendComposite.setShowCurrencies();
      }
    },
    handleHideComposites(_, index) {
      const spendComposite = this.$refs[`spendComposite_${index}`];
      const sponsoredSalesComposite = this.$refs[`sponsoredSalesComposite_${index}`];
      if (spendComposite && sponsoredSalesComposite) {
        sponsoredSalesComposite.setHideCurrencies();
        spendComposite.setHideCurrencies();
      }
    },
    findTargetingTypeValue(key, arr = []) {
      return arr.find(item => item.key === key)?.value ?? null;
    },
    findTargetingTypeMinMax(key, arr = []) {
      return arr.find(item => item.key === key)?.minMax ?? {
        min: 0,
        max: 0
      };
    },
    async downloadPPCV2CSV() {
      const csv_headers = this.csvSources[this.currentTabValue][this.groupBy][this.ppcV2SecondaryFields()];
      const csvFileName = this.csvFileName;
      const download = async page => await this.downloader(page, ANALYTICS_CSV_PAGE_SIZE, csv_headers, this.groupByFetcher);
      const str = await this.generateCsvStringWithPagination(csv_headers, Math.ceil(this.totalPages / ANALYTICS_CSV_PAGE_SIZE), download, '-');
      await this.downloadCsv(str, csvFileName);
    }
  }
};