export default {
  name: "AppTabs",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    tabs: {
      type: Array,
      default: () => []
    },
    enabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lineStyle: ""
    };
  },
  watch: {
    modelValue(val) {
      this.calcLineStyle(val);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcLineStyle(this.modelValue);
    });
  },
  methods: {
    calcLineStyle(val) {
      const ref = this.$refs[`ref-${val}`]?.[0];
      if (ref) {
        const left = ref.getBoundingClientRect().left - ref.parentNode.getBoundingClientRect().left;
        const width = ref.offsetWidth;
        this.lineStyle = `width: ${width}px; left: ${left}px;`;
      }
    },
    onTabChange(tab) {
      if (tab.canSwitchTab()) {
        this.$emit("update:modelValue", tab.value);
      }
    }
  }
};