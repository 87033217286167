import { TextValue } from "@/components/tableElements";
import FlexRow from "@/components/FlexRow.vue";
import FlexColumn from "@/components/FlexColumn.vue";
import { WrapWithSectionItem } from "../sections";
import WrapWithCopy from "@/components/WrapWithCopy.vue";
export default {
  name: "ProductFilterItem",
  components: {
    FlexRow,
    FlexColumn,
    TextValue,
    WrapWithSectionItem,
    WrapWithCopy
  },
  emits: ["select", "selectOnly", "deselect"],
  props: {
    id: {
      type: String
    },
    sku: {
      type: String
    },
    asin: {
      type: String
    },
    product_name: {
      type: String
    },
    image: {
      type: String
    },
    width: {
      type: Number,
      default: 100
    },
    selected: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    }
  }
};