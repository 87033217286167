import AccountsMixin from "./AccountsMixin.vue";
import { EVENTS } from "@/utils";
import InitializeFiltersMixin from "./InitializeFiltersMixin.vue";
export default {
  name: "PPCManagementV2Mixin",
  mixins: [AccountsMixin, InitializeFiltersMixin],
  mounted() {
    window.$bus.on(EVENTS.REFRESH_SELLERS_SUB_FILTERS, async () => {
      const sellers = this.$store.state.ppc_v2.sellers.filterValues;
      const sales_channel = await this.initializeMarketplacesFilters({
        sellers
      }, "ppc_v2", "marketplaces", "ppc/v2");
      await this.fetchSubFilters({
        sellers,
        sales_channel
      }, "ppc_v2", {
        product: "products",
        match_type: "match_types",
        targeting: "targetings",
        campaign: "campaigns",
        portfolio: "portfolios",
        ad_format: "ad_formats"
      }, "ppc/v2");
    });
    window.$bus.on(EVENTS.REFRESH_MARKETPLACES_SUB_FILTERS, async () => {
      const sellers = this.$store.state.ppc_v2.sellers.filterValues;
      const sales_channel = this.$store.state.ppc_v2.marketplaces.filterValues;
      await this.fetchSubFilters({
        sellers,
        sales_channel
      }, "ppc_v2", {
        product: "products",
        match_type: "match_types",
        targeting: "targetings",
        campaign: "campaigns",
        portfolio: "portfolios",
        ad_format: "ad_formats"
      }, "ppc/v2");
    });
  }
};