export default {
  state: {
    config: {
      week_type: "sunday"
    }
  },
  mutations: {
    weekTypeConfigSet(state, payload) {
      state.config.week_type = payload;
    }
  },
  getters: {
    dateConfigGet(state) {
      return () => state.config;
    },
    dateConfigQueryGet(state) {
      return () => ({
        week_type: state.config.week_type
      });
    },
    getSystemWeekType(state) {
      return state.config.week_type;
    }
  },
  actions: {
    setWeekTypeConfig({
      commit
    }, payload) {
      if (payload && payload !== "") commit("weekTypeConfigSet", payload);
    }
  }
};