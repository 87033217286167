import AppIcon from "@/components/AppIcon.vue";
export default {
  name: "CategoryItem",
  components: {
    AppIcon
  },
  emits: ["selectCategory"],
  props: {
    category: {
      type: String,
      default: ""
    },
    categoryId: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    hoverable: {
      type: Boolean,
      default: true
    },
    dragging: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getIconColor() {
      return this.active ? "#312783" : "#595959";
    }
  },
  methods: {
    handleCategoryClick() {
      this.$emit("selectCategory", {
        category: this.category,
        categoryId: this.categoryId
      });
    }
  }
};