import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-01f6948a"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id"];
const _hoisted_2 = {
  class: "profile-id"
};
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1,
  class: "d-flex align-item-center justify-content-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_connection_page_title = _resolveComponent("connection-page-title");
  const _component_b_th = _resolveComponent("b-th");
  const _component_b_tr = _resolveComponent("b-tr");
  const _component_b_thead = _resolveComponent("b-thead");
  const _component_b_td = _resolveComponent("b-td");
  const _component_b_tooltip = _resolveComponent("b-tooltip");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_button = _resolveComponent("app-button");
  const _component_b_tbody = _resolveComponent("b-tbody");
  const _component_b_table_simple = _resolveComponent("b-table-simple");
  const _component_app_modal = _resolveComponent("app-modal");
  const _component_create_account_side_bar = _resolveComponent("create-account-side-bar");
  const _component_app_card_connections = _resolveComponent("app-card-connections");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_connection_page_title, {
    visibleButton: $options.canAddAccount,
    action: "Add Account",
    searchPlaceHolder: $data.searchPlaceHolder,
    onAction: _cache[0] || (_cache[0] = $event => $data.createAccountSideBar = $options.canAddAccount)
  }, null, 8 /* PROPS */, ["visibleButton", "searchPlaceHolder"]), _createVNode(_component_app_card_connections, {
    loading: $data.dataLoading,
    class: "no-left-right-margin",
    "min-height": 300,
    scrollable: true,
    hasBorder: false
  }, {
    default: _withCtx(() => [!$data.dataLoading && $options.accounts.length > 0 ? (_openBlock(), _createBlock(_component_b_table_simple, {
      key: 0,
      striped: "",
      responsive: ""
    }, {
      default: _withCtx(() => [_createVNode(_component_b_thead, {
        class: "account-table-thead-background"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_tr, null, {
          default: _withCtx(() => [_createVNode(_component_b_th, {
            class: "account-table-thead-background-text"
          }, {
            default: _withCtx(() => [_createTextVNode("Account")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            class: "account-table-thead-background-text"
          }, {
            default: _withCtx(() => [_createTextVNode("Seller ID")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            class: "account-table-thead-background-text"
          }, {
            default: _withCtx(() => [_createTextVNode("Countries")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            class: "account-table-thead-background-text"
          }, {
            default: _withCtx(() => [_createTextVNode("Credentials")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            style: {
              "width": "0"
            }
          })]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      }), _createVNode(_component_b_tbody, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.accounts, account => {
          return _openBlock(), _createBlock(_component_b_tr, {
            key: account.account_id
          }, {
            default: _withCtx(() => [_createVNode(_component_b_td, {
              class: "align-middle"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(account.account_name), 1 /* TEXT */)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createCommentVNode(" b-td class=\"align-middle\">{{\n              workspaceName(account.workspace_id)\n            }}</b-td"), _createVNode(_component_b_td, {
              class: "align-middle"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(account.account_id), 1 /* TEXT */)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, {
              class: "align-middle col-md-4"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(account.profiles, profile => {
                return _openBlock(), _createElementBlock("span", {
                  class: "country",
                  key: profile.profileId,
                  id: profile.profileId
                }, [_createVNode(_component_b_tooltip, {
                  target: profile.profileId,
                  triggers: "hover"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString(profile.profileId), 1 /* TEXT */)]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"]), _createElementVNode("img", {
                  class: "flag",
                  src: profile.flag,
                  alt: profile.country
                }, null, 8 /* PROPS */, _hoisted_3), _createTextVNode(" " + _toDisplayString(profile.country), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_1);
              }), 128 /* KEYED_FRAGMENT */))]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, {
              class: "align-middle"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(account.credentials), 1 /* TEXT */)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, null, {
              default: _withCtx(() => [!_ctx.isOmnyWorkspace ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_app_button, {
                loading: account.account_id == $data.removeId ? $data.removeLoading : null,
                variant: "link",
                onClick: $event => $options.onRemoveConfirm(account.profiles)
              }, {
                default: _withCtx(() => [_createVNode(_component_app_icon, {
                  icon: "remove"
                })]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "onClick"])])) : _createCommentVNode("v-if", true)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */);
        }), 128 /* KEYED_FRAGMENT */))]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    })) : _createCommentVNode("v-if", true), !$data.dataLoading && $options.accounts.length == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_5, " You have not any accounts yet ")) : _createCommentVNode("v-if", true), _createVNode(_component_app_modal, {
      modelValue: $data.confirmRemoveModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.confirmRemoveModal = $event),
      title: "Confirm Account Removing",
      "cancel-title": "No",
      "ok-title": "Yes",
      onOk: $options.onRemove
    }, {
      default: _withCtx(() => [_createTextVNode(" Are you sure you want to remove your account? ")]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "onOk"]), _createVNode(_component_create_account_side_bar, {
      modelValue: $data.createAccountSideBar,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.createAccountSideBar = $event),
      provider: $data.provider,
      regions: $data.regions,
      accounts: $data.seller_vendorAccountsList,
      countries: $data.vendorCountries,
      hasAccounts: true,
      hasCountries: $options.hasCountries
    }, null, 8 /* PROPS */, ["modelValue", "provider", "regions", "accounts", "countries", "hasCountries"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"])]);
}