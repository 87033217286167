import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "date-picker-filter"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_date_picker_selector = _resolveComponent("date-picker-selector");
  const _component_filter_item_selector = _resolveComponent("filter-item-selector");
  const _component_dropdown_body = _resolveComponent("dropdown-body");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_dropdown, {
    ref: "dropdown",
    "no-caret": "",
    offset: "8",
    onHidden: $options.handleHidden,
    menuClass: "datepicker-menu"
  }, {
    "button-content": _withCtx(() => [_createVNode(_component_filter_item_selector, {
      "no-caret": $props.noCaret
    }, {
      title: _withCtx(() => [_createVNode(_component_date_picker_selector, {
        loading: $props.loading,
        title: $props.date.title,
        start: $props.date.start,
        end: $props.date.end,
        pillStyle: $props.pillStyle
      }, {
        body: _withCtx(() => [_renderSlot(_ctx.$slots, "date-picker-body")]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["loading", "title", "start", "end", "pillStyle"])]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["no-caret"])]),
    default: _withCtx(() => [_createVNode(_component_dropdown_body, {
      class: "filter-dropdown-body"
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "body")]),
      _: 3 /* FORWARDED */
    })]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["onHidden"])]);
}