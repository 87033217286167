import AccountCreateCard from '@/components/AccountCreateCard.vue';
import AppAlert from '@/components/AppAlert';
export default {
  name: 'TheWrapperAccountCreation',
  components: {
    AccountCreateCard,
    AppAlert
  },
  props: {
    title: {
      type: String,
      default: null
    },
    scrollable: {
      type: Boolean,
      default: true
    }
  }
};