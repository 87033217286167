import HugValue from "@/components/tableElements/HugValue.vue";
import { TARGETING_TYPE_SPLIT } from "@/utils/constants";
export default {
  name: "TargetingTypeValue",
  components: {
    HugValue
  },
  props: {
    value: {
      type: String
    },
    align: {
      type: String,
      default: "left"
    },
    loading: {
      type: Boolean
    }
  },
  computed: {
    targetingTypeObject() {
      return TARGETING_TYPE_SPLIT.find(({
        key
      }) => key === this.value);
    },
    targetingTypeStyle() {
      const {
        bg,
        tableColor
      } = this.targetingTypeObject ?? {
        bg: 'transparent',
        tableColor: '#ccc'
      };
      return {
        ['--targeting-type-color']: tableColor,
        ['--targeting-type-bg']: bg
      };
    }
  }
};