export default {
  state: {
    currentTab: 0,
    currentTabValue: "content optimisation",
    tabs: [{
      text: "Content Optimisation",
      value: "content optimisation",
      activeBg: "#F3EEFF",
      activeColor: "#423994"
    }]
  },
  mutation: {
    seoSetCurrentTab(state, payload) {
      state.currentTab = payload;
      state.currentTabValue = state.tabs[payload].value;
    }
  },
  getters: {
    seoGetTab(state) {
      return () => state.currentTab;
    },
    seoGetTabValue(state) {
      return () => state.tabs[state.currentTab].value;
    }
  },
  actions: {
    seoSetTab({
      commit
    }, tab) {
      commit('seoSetCurrentTab', tab);
    }
  }
};