import { mapState, mapGetters, mapActions } from "vuex";
import { SellerFilter, MarketplaceFilter } from "@/components/new-filters";
import FlexRow from "@/components/FlexRow.vue";
import { EVENTS } from "@/utils/constants";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
export default {
  name: "PpcSettingsFilters",
  components: {
    SellerFilter,
    MarketplaceFilter,
    FlexRow
  },
  computed: {
    ...mapState({
      sellers_filter: state => state.ppc_settings.sellers_filter,
      marketplaces_filter: state => state.ppc_settings.marketplaces_filter,
      currencies_filter: state => state.ppc_settings.currencies_filter
    })
  },
  methods: {
    async applySellers() {
      if (this.sellers_filter.isModified) {
        this.sellers_filter.apply();
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS_MARKETPLACES_FILTER);
        await window.$bus.trigger(EVENTS.REFRESH_PPC_WEEKS_RANGE);
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS);
      }
    },
    async applyMarketplaces() {
      if (this.marketplaces_filter.isModified) {
        this.marketplaces_filter.apply();
        await window.$bus.trigger(EVENTS.REFRESH_PPC_WEEKS_RANGE);
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS);
      }
    }
  }
};