import ProductScoreBad from '@/components/icons/ProductScoreBad.vue';
import ProductScoreGood from '@/components/icons/ProductScoreGood.vue';
import ProductScoreOk from '@/components/icons/ProductScoreOk.vue';
import WrapWithPopover from '@/components/shared/WrapWithPopover.vue';
import HugValue from "@/components/tableElements/HugValue.vue";
export default {
  name: 'WrapWithStatusIcon',
  components: {
    WrapWithPopover,
    ProductScoreBad,
    ProductScoreGood,
    ProductScoreOk,
    HugValue
  },
  data() {
    return {
      SEO_STATUS_ICONS_MAP: {
        success: 'ProductScoreGood',
        warning: 'ProductScoreOk',
        error: 'ProductScoreBad'
      }
    };
  },
  props: {
    validationStatus: {
      type: Object,
      default: () => ({
        status: 'warning',
        text: 'Warning'
      })
    },
    hugStyles: {
      type: Object
    }
  },
  computed: {
    statusObject() {
      return SEO_STATUS_ICONS_MAP[this.type];
    },
    statusIcon() {
      return this.statusObject.icon;
    },
    statusColor() {
      return this.statusObject.color;
    }
  }
};