import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5d9d9257"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "product-group-value"
};
const _hoisted_2 = {
  class: "inner"
};
const _hoisted_3 = {
  class: "image-wrapper"
};
const _hoisted_4 = {
  class: "name"
};
const _hoisted_5 = {
  class: "dot-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_campaign_id_count = _resolveComponent("campaign-id-count");
  const _component_b_img = _resolveComponent("b-img");
  const _component_campaigns_count = _resolveComponent("campaigns-count");
  const _component_flex_column = _resolveComponent("flex-column");
  const _component_flag_value = _resolveComponent("flag-value");
  const _component_dot_element = _resolveComponent("dot-element");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    loading: $props.loading,
    align: $props.align,
    styles: {
      width: $props.width
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_campaign_id_count, {
      count: $props.value.campaignIdCount
    }, null, 8 /* PROPS */, ["count"]), _createVNode(_component_flex_column, {
      classes: "product",
      gap: 4,
      styles: {
        padding: 0,
        overflow: 'hidden'
      }
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_b_img, {
        src: $props.value?.image ?? '/assets/images/amazon-logo-transparent.svg',
        class: "custom-img",
        height: "16",
        width: "16"
      }, null, 8 /* PROPS */, ["src"])]), _createElementVNode("div", _hoisted_4, _toDisplayString($props.value.name), 1 /* TEXT */)]), _createVNode(_component_campaigns_count, {
        count: $props.value.campaignsCount
      }, null, 8 /* PROPS */, ["count"])]),
      _: 1 /* STABLE */
    }), _createVNode(_component_flag_value, {
      flag: $props.value.flag
    }, null, 8 /* PROPS */, ["flag"]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_dot_element, {
      class: "",
      color: $props.value.color
    }, null, 8 /* PROPS */, ["color"])])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading", "align", "styles"]);
}