import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_table_graph = _resolveComponent("table-graph");
  const _component_error_wrapper = _resolveComponent("error-wrapper");
  return _openBlock(), _createBlock(_component_error_wrapper, {
    showError: $data.showError,
    onRetry: $options.retry
  }, {
    default: _withCtx(() => [_createVNode(_component_table_graph, {
      onSortTable: $options.sortTable,
      graphId: $data.graphId,
      graphTitle: $options.graphTitle,
      graphHeaders: $data.graphHeaders,
      tableLoading: $data.graphLoading,
      positions: _ctx.graph,
      totalPositions: $data.totalPositions,
      minMax: $data.minMax,
      totalLoading: $data.totalLoading,
      description: $data.graphDescription,
      sortBy: $data.sortBy,
      sortDirection: $data.sortDirection,
      onPage: $options.handlePage,
      dataWidths: $data.totalWidths,
      displayTotal: true,
      totalCount: $data.totalPositionsCount,
      currentPage: $data.currentPage,
      pageSize: $data.pageSize,
      pageRenderedLoading: $data.pageRenderedLoading
    }, null, 8 /* PROPS */, ["onSortTable", "graphId", "graphTitle", "graphHeaders", "tableLoading", "positions", "totalPositions", "minMax", "totalLoading", "description", "sortBy", "sortDirection", "onPage", "dataWidths", "totalCount", "currentPage", "pageSize", "pageRenderedLoading"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["showError", "onRetry"]);
}