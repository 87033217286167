import { intersectKeys, explodeInnerArrays } from '@/utils/transformers';
import { ANALYTICS_TABLE_CELL_HEIGHT, EVENTS } from '@/utils/constants';
import { mapGetters } from "vuex";
import { DATE_PICKER_COMPARE_TITLES, DATE_PICKER_RANGE_TITLES, getDatePickerUIObjectFromDateRange, DATE_RANGES_FROM_SELECTOR, getPeriodGroupFromCurrentPeriod } from "@/utils/datepicker";
import moment from "moment";
export default {
  name: "LiteTableMixin",
  data() {
    return {
      evolCategory: {
        prev_period: "",
        same_period_ly: "last_year_"
      },
      evolutionType: "percentage",
      evolutionTypeKpi: {
        percentage: "",
        currency: "_currency"
      }
    };
  },
  computed: {
    ...mapGetters(["getSystemWeekType"]),
    in_currency() {
      return this.$store.getters?.currencyConfigInCurrencyGet() ?? '';
    },
    cssProps() {
      return ANALYTICS_TABLE_CELL_HEIGHT[this.groupBy] ?? {
        '--lite-table-td-height': '32px'
      };
    }
  },
  methods: {
    getPeriodGroupFromCurrentPeriod(currentPeriod, period) {
      return getPeriodGroupFromCurrentPeriod(currentPeriod, period, this.getSystemWeekType);
    },
    handleRowClass(item, items) {
      const lastItem = items[items.length - 1];
      return item.selected === undefined ? "" : item.selected ? `lite-table-selected-row ${item === lastItem ? "last" : ""}` : "lite-table-unselected-row";
    },
    handleRowClicked(item, reset, selected, key, items) {
      let elementItems = 'items' in this ? this.items : items;
      if (this.loading) return;
      if (item.selected) {
        elementItems.forEach(item => {
          item.selected = undefined;
        });
        if (reset) reset();
      } else {
        elementItems.forEach(item => {
          item.selected = false;
        });
        item.selected = true;
        selected(!key ? item : item[key]);
      }
    },
    async handleBreakdownRowClicked(item, reset, selected, items) {
      let elementItems = 'items' in this ? this.items : items;
      if (this.loading) return;
      if (item.selected) {
        elementItems.forEach(item => {
          item.selected = undefined;
        });
        if (reset) await reset();
      } else {
        elementItems.forEach(item => {
          item.selected = false;
        });
        item.selected = true;
        await selected(item);
      }
    },
    handleSort({
      sort,
      direction
    }) {
      // Check if there is a `noSortColumns` columns exits
      if ("noSortColumns" in this.$data && this.$data.noSortColumns.includes(sort)) {
        window.$bus.trigger('showWarning', {
          message: 'Cannot sort by this field',
          visible: true,
          delay: 1
        });
        return;
      }
      this.$emit('handleSort', {
        sort,
        direction
      });
    },
    activateDateValueStyles(row) {
      return row.item.selected ? {
        color: "#312783",
        fontWeight: "600"
      } : {};
    },
    prepareCsvRowsForDownload(rows, csv_headers) {
      return explodeInnerArrays(rows).map(row => intersectKeys(row, csv_headers, 2));
    },
    async downloader(page = 1, page_size, csv_headers, fetcher) {
      const response = await this.$store.dispatch(fetcher, {
        pager: {
          page,
          page_size
        },
        store: false
      });
      if (response) {
        const {
          report
        } = response;
        let {
          result
        } = report;
        return this.prepareCsvRowsForDownload(result, csv_headers);
      }
      return [];
    },
    handleSetLiteTablePeriod(date, period, callback) {
      if (!date) {
        callback(date);
        return;
      }
      const {
        range,
        compare
      } = DATE_RANGES_FROM_SELECTOR[period](date, this.getSystemWeekType);
      const datePickerDate = getDatePickerUIObjectFromDateRange({
        range,
        compare,
        rangeTitle: DATE_PICKER_RANGE_TITLES.CUSTOM,
        compareTitle: DATE_PICKER_COMPARE_TITLES.CUSTOM
      });
      callback(datePickerDate);
      window.$bus.trigger(EVENTS.FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE);
    },
    getEvolutionsForField(fieldObject, options = {
      hasCurrencyEvolution: false
    }) {
      const {
        key,
        label
      } = fieldObject;
      if (!("key" in fieldObject) && !("label" in fieldObject)) return {};
      const fields = {
        [`${label} Previous Period Evolution`]: `${key}_evol`,
        [`${label} Same Period Last Year Evolution`]: `last_year_${key}_evol`
      };
      if (options.hasCurrencyEvolution) {
        fields[`${label} Previous Period Currency Evolution`] = `${key}_evol_currency`;
        fields[`${label} Same Period Last Year Evolution`] = `last_year_${key}_evol_currency`;
      }
      return fields;
    },
    toggleEvolutionType(e, type) {
      e.stopPropagation();
      this.evolutionType = type;
    },
    async searchAndSelect({
      entity,
      // Filter Instance
      keyword,
      // Array
      filters = {},
      postActions = [],
      eventTrigger = null
    }) {
      // Clear and apply filters on the selected entity
      entity.clear();
      entity.apply();

      // Perform search with the given keyword and filters
      const res = await entity.asyncSearchAndSelect({
        keyword,
        args: filters
      });

      // Exit if search result is empty
      if (!res) {
        window.$bus.trigger('showDanger', {
          message: `Could not search for the ${keyword}`,
          dismissSecs: 3,
          visible: true
        });
        throw Error(`Could not search for the ${keyword}`);
      }

      // Re-apply the filters to the entity if needed
      entity.apply();

      // Trigger any additional actions specified in postActions
      postActions.forEach(action => action());

      // Optionally trigger a custom event if specified
      if (eventTrigger) {
        window.$bus.trigger(eventTrigger.name, eventTrigger.payload);
      }
    },
    async clearAndRefresh({
      entity,
      filters = {},
      postActions = []
    }) {
      // Clear the entity after performing an async search with the specified filters
      await entity.clearAfterAsyncSearch({
        args: filters
      });

      // Trigger any additional post-actions specified
      postActions.forEach(action => action());
    }
  }
};