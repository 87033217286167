import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = {
  key: 1,
  class: "d-flex w-100 justify-content-between"
};
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = {
  class: "w-100"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_performance_product_table_group_by = _resolveComponent("performance-product-table-group-by");
  const _component_app_icon_group = _resolveComponent("app-icon-group");
  const _component_wrap_with_arrow = _resolveComponent("wrap-with-arrow");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_performance_product_table_total = _resolveComponent("performance-product-table-total");
  const _component_value_element = _resolveComponent("value-element");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_evolution_value = _resolveComponent("evolution-value");
  const _component_rate_change_value = _resolveComponent("rate-change-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_slot_with_evolution_value = _resolveComponent("slot-with-evolution-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_rating_value = _resolveComponent("rating-value");
  const _component_dotted_value = _resolveComponent("dotted-value");
  const _component_overlap_bar_value = _resolveComponent("overlap-bar-value");
  const _component_marketplace_value = _resolveComponent("marketplace-value");
  const _component_product_value = _resolveComponent("product-value");
  const _component_parent_product_value = _resolveComponent("parent-product-value");
  const _component_signed_bar_with_value = _resolveComponent("signed-bar-with-value");
  const _component_wrap_with_alert_icon = _resolveComponent("wrap-with-alert-icon");
  const _component_block_bar_with_value = _resolveComponent("block-bar-with-value");
  const _component_box_and_bar_with_value = _resolveComponent("box-and-bar-with-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  const _component_floating_compound_table = _resolveComponent("floating-compound-table");
  return _openBlock(), _createBlock(_component_floating_compound_table, {
    ref: "performanceFloatingCompoundTable",
    datePickerDate: $options.datePickerDate,
    loading: $props.loading,
    onChangeDate: $options.handleDatePickerSubmit,
    onTableState: $options.handleTableState,
    page: $options.page,
    "onUpdate:page": _cache[4] || (_cache[4] = $event => $options.page = $event),
    totalPages: $options.totalPages,
    "onUpdate:totalPages": _cache[5] || (_cache[5] = $event => $options.totalPages = $event),
    perPage: $options.perPage,
    "onUpdate:perPage": _cache[6] || (_cache[6] = $event => $options.perPage = $event),
    style: {
      ['--compound-table-right-gap']: '16px'
    },
    fields: $options.fields
  }, {
    header: _withCtx(() => [_createTextVNode("Breakdown by")]),
    "header-sub": _withCtx(() => [_createVNode(_component_performance_product_table_group_by, {
      onRefresh: $options.handleGroupByRefresh,
      onClicked: $options.handleClicked,
      tabBusy: $props.loading
    }, null, 8 /* PROPS */, ["onRefresh", "onClicked", "tabBusy"])]),
    "prepend-actions": _withCtx(() => [_createVNode(_component_app_icon_group, {
      icons: [{
        icon: 'download-csv',
        size: 20,
        help: 'Download CSV',
        click: $options.downloadDashboardV2CSV
      }]
    }, null, 8 /* PROPS */, ["icons"])]),
    table: _withCtx(({
      fullSize,
      visible
    }) => [_createVNode(_component_b_table_lite, {
      class: _normalizeClass(['lite-table', 'performance-product-table', {
        ['full-size']: fullSize
      }, {
        ['intermediate']: !fullSize && visible
      }, _ctx.groupBy]),
      style: _normalizeStyle(_ctx.cssProps),
      items: $options.filteredItems,
      fields: $options.fields,
      responsive: "",
      fixed: "",
      "sticky-header": "",
      bordered: "",
      "no-border-collapse": "",
      tbodyTrClass: item => $options.getTbodyTrClass(item),
      onHeadClicked: $options.handleHeadClick,
      onRowClicked: $options.handleRowClick
    }, {
      "head()": _withCtx(col => [_createVNode(_component_flex_row, {
        styles: {
          justifyContent: col.field.justify,
          ...col.field.thStyle
        },
        id: `performance-product-table-${col?.column}-${_ctx.groupBy}`
      }, {
        default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
          triggers: "hover",
          target: `performance-product-table-${col?.column}-${_ctx.groupBy}`,
          style: {
            width: '100%'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", {
            innerHTML: $options.getDescriptionBasedOnKpiKey(col?.column).title
          }, null, 8 /* PROPS */, _hoisted_1)]),
          content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
          default: _withCtx(() => [['asc', 'desc'].includes(this.sort[col?.column]) ? (_openBlock(), _createBlock(_component_wrap_with_arrow, {
            key: 0,
            direction: this.sort[col?.column]
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              class: "title",
              innerHTML: col?.label
            }, null, 8 /* PROPS */, _hoisted_2)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["direction"])) : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "title",
            innerHTML: col?.label
          }, null, 8 /* PROPS */, _hoisted_3))]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles", "id"])]),
      "head(revenue_excl_VAT)": _withCtx(col => [_createVNode(_component_flex_row, {
        styles: {
          justifyContent: col.field.justify,
          ...col.field.thStyle
        },
        id: `performance-product-table-revenue_excl_VAT`,
        onClick: $event => $options.handleHeadClick(col.field.key)
      }, {
        default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
          triggers: "hover",
          target: `performance-product-table-revenue_excl_VAT`,
          style: {
            width: '100%'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", {
            innerHTML: $options.getDescriptionBasedOnKpiKey(col?.column).title
          }, null, 8 /* PROPS */, _hoisted_4)]),
          content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
          default: _withCtx(() => [['asc', 'desc'].includes(this.sort[col?.column]) ? (_openBlock(), _createBlock(_component_wrap_with_arrow, {
            key: 0,
            direction: this.sort[col?.column]
          }, {
            "more-head-actions": _withCtx(() => [_ctx.evolutionType === 'percentage' ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = $event => _ctx.toggleEvolutionType($event, 'currency')),
              class: "evolution-type"
            }, "€")) : _createCommentVNode("v-if", true), _ctx.evolutionType === 'currency' ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = $event => _ctx.toggleEvolutionType($event, 'percentage')),
              class: "evolution-type"
            }, "%")) : _createCommentVNode("v-if", true)]),
            default: _withCtx(() => [_createElementVNode("div", {
              class: "title",
              innerHTML: col?.label
            }, null, 8 /* PROPS */, _hoisted_5)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["direction"])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", {
            class: "title",
            innerHTML: col?.label
          }, null, 8 /* PROPS */, _hoisted_7), _createElementVNode("div", null, [_ctx.evolutionType === 'percentage' ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            onClick: _cache[2] || (_cache[2] = $event => _ctx.toggleEvolutionType($event, 'currency')),
            class: "evolution-type"
          }, "€")) : _createCommentVNode("v-if", true), _ctx.evolutionType === 'currency' ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            onClick: _cache[3] || (_cache[3] = $event => _ctx.toggleEvolutionType($event, 'percentage')),
            class: "evolution-type"
          }, "%")) : _createCommentVNode("v-if", true)])]))]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles", "onClick"])]),
      "custom-foot": _withCtx(() => [_createElementVNode("tr", null, [_createVNode(_component_performance_product_table_total, {
        fields: $options.fields,
        total: $options.total,
        currencyConfig: _ctx.currencyConfig,
        loading: $props.loading,
        evolutionType: _ctx.evolutionType
      }, null, 8 /* PROPS */, ["fields", "total", "currencyConfig", "loading", "evolutionType"])])]),
      "bottom-row": _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.fields, ({
        stickyColumn
      }, index) => {
        return _openBlock(), _createElementBlock("td", {
          key: index,
          class: _normalizeClass(['bottom-row', {
            'b-table-sticky-column': stickyColumn
          }])
        }, null, 2 /* CLASS */);
      }), 128 /* KEYED_FRAGMENT */))]),
      "cell(seller_name)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading,
        align: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_value_element, {
          value: row.item?.seller_name
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(revenue_excl_VAT)": _withCtx(row => [_createVNode(_component_rate_change_value, {
        value1: row.item?.revenue_and_lost_revenue_excl_VAT,
        onClick: $event => $options.handleRowClick(row.item),
        value2: row.item?.revenue_excl_VAT,
        min1: 0,
        min2: 0,
        max1: $options.minMax?.revenue_and_lost_revenue_excl_VAT?.max,
        max2: $options.minMax?.revenue_and_lost_revenue_excl_VAT?.max,
        loading: $props.loading,
        rateChange: _ctx.periodCategory === 'prev_period' ? row.item?.revenue_excl_VAT_evol : row.item?.last_year_revenue_excl_VAT_evol,
        color1: "#FFB3B8",
        color2: "#8D85E5",
        evolutionType: _ctx.evolutionType
      }, {
        lost: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.lost_revenue_excl_VAT,
          limit: 10_000,
          loading: $props.loading,
          styles: {
            justifyContent: 'flex-end',
            width: '100%'
          }
        }, null, 8 /* PROPS */, ["value", "loading"])]),
        gained: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.revenue_excl_VAT,
          limit: 10_000,
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "loading"])]),
        evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
          value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[_ctx.evolutionType]}`]
        }, {
          default: _withCtx(({
            color
          }) => [_ctx.evolutionType === 'currency' ? (_openBlock(), _createBlock(_component_currency_value, {
            key: 0,
            "show-sign": "",
            value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[_ctx.evolutionType]}`],
            color: color,
            charCount: $options.maxCharCount.currency?.[`${$data.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[_ctx.evolutionType]}`]
          }, null, 8 /* PROPS */, ["value", "color", "charCount"])) : _createCommentVNode("v-if", true), _ctx.evolutionType === 'percentage' ? (_openBlock(), _createBlock(_component_percentage_value, {
            key: 1,
            "show-sign": "",
            value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[_ctx.evolutionType]}`],
            color: color
          }, null, 8 /* PROPS */, ["value", "color"])) : _createCommentVNode("v-if", true)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.revenue_excl_VAT,
          limit: 10_000,
          loading: $props.loading,
          charCount: $options.maxCharCount?.currency?.revenue_excl_VAT
        }, null, 8 /* PROPS */, ["value", "loading", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value1", "onClick", "value2", "max1", "max2", "loading", "rateChange", "evolutionType"])]),
      "cell(share_total_revenue)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading,
        align: "right"
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.share_total_revenue
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(total_quantity)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 16
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}total_quantity_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              "show-sign": "",
              value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}total_quantity_evol`],
              color: color,
              charCount: $options.maxCharCount.percentage?.[`${$data.evolCategory[_ctx.periodCategory]}total_quantity_evol`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_number_value, {
            value: row.item?.total_quantity,
            loading: $props.loading,
            tight: ""
          }, null, 8 /* PROPS */, ["value", "loading"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(days_inventory)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.days_inventory,
        min: $options.minMax?.days_inventory?.min,
        color: "#CC9F6C",
        max: $options.minMax?.days_inventory?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          value: row.item?.days_inventory,
          styles: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(share_asin_sold)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.share_asin_sold,
        min: $options.minMax?.share_asin_sold?.min,
        color: "#93999F",
        max: $options.minMax?.share_asin_sold?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.share_asin_sold,
          charCount: $options.maxCharCount?.percentage?.share_asin_sold
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(organic_CVR)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getColorsBasedOnRange(row.item?.organic_CVR, $data.ORGANIC_CVR_HEATMAP_COLORS),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.organic_CVR,
          precision: 1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(rating)": _withCtx(row => [_createVNode(_component_rating_value, {
        value: row.item?.rating,
        color: "#FC7E47",
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          value: row.item?.rating,
          precision: 1,
          styles: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "loading"])]),
      "cell(traffic_by_asin_buy_box_percentage)": _withCtx(row => [_createVNode(_component_dotted_value, {
        align: 'center',
        color: $options.getColorsBasedOnRange(row.item?.traffic_by_asin_buy_box_percentage, $data.BUYBOX_HEATMAP_COLORS),
        value: row.item?.traffic_by_asin_buy_box_percentage,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.traffic_by_asin_buy_box_percentage
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["color", "value", "loading"])]),
      "cell(share_sponsored_sales)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.share_sponsored_sales,
        min: $options.minMax?.share_sponsored_sales?.min,
        color: "#4C8EFF",
        max: $options.minMax?.share_sponsored_sales?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.share_sponsored_sales,
          charCount: $options.maxCharCount?.percentage?.share_sponsored_sales
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(TACOS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
        value: row.item?.TACOS,
        background: "#EBD4F7",
        borderColor: "#CD93EB",
        min: $options.minMax?.TACOS?.min,
        max: $options.minMax?.TACOS?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.TACOS
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(sales_channel)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading,
        align: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_marketplace_value, {
          marketplace: row.item?.sales_channel,
          flag: row.item?.flag
        }, null, 8 /* PROPS */, ["marketplace", "flag"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(return_rate)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.return_rate,
        color: "#8D85E5",
        min: $options.minMax?.return_rate?.min,
        max: $options.minMax?.return_rate?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.return_rate,
          style: {
            textAlign: 'right'
          },
          precision: 1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(product_name)": _withCtx(row => [_ctx.groupBy === 'parent_asin' ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createCommentVNode(" Product Element is Child "), row.item?.is_child_asin ? _withDirectives((_openBlock(), _createBlock(_component_product_value, {
        key: 0,
        value: {
          name: row.item?.product_name,
          image: row.item?.main_image_link,
          asin: row.item?.asin,
          sku: row.item?.sku,
          link: row.item?.amazon_link
        },
        loading: $props.loading,
        sponsored: row.item?.sku === 'SPONSORED_BRANDS',
        styles: {
          padding: '4px 8px 4px 60px'
        },
        isChild: true,
        isToggled: $data.standardized_parents[row.item?.standardized_parent_asin]
      }, null, 8 /* PROPS */, ["value", "loading", "sponsored", "isToggled"])), [[_vShow, $data.standardized_parents[row.item?.standardized_parent_asin]]]) : !row.item?.standardized_parent_asin && row.item?.asin_count === 1 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createCommentVNode(" Product element is neither a parent nor a child (pseudo parent with no children) "), _createVNode(_component_product_value, {
        value: {
          name: row.item?.product_name,
          image: row.item?.main_image_link,
          asin: row.item?.asin_list[0],
          sku: row.item?.sku,
          link: row.item?.amazon_link
        },
        loading: $props.loading,
        sponsored: row.item?.sku === 'SPONSORED_BRANDS',
        styles: {
          padding: '4px 8px 4px 24px'
        }
      }, null, 8 /* PROPS */, ["value", "loading", "sponsored"])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */)) : (_openBlock(), _createBlock(_component_parent_product_value, {
        key: 2,
        value: {
          name: row.item?.product_name,
          image: row.item?.main_image_link
        },
        loading: $props.loading,
        hasChildren: row.item?.asin_count > 0,
        state: $data.standardized_parents[row.item?.standardized_parent_asin],
        count: row.item?.asin_count,
        onToggleChildren: $event => $options.handleParentAsinToggle(row.item)
      }, null, 8 /* PROPS */, ["value", "loading", "hasChildren", "state", "count", "onToggleChildren"]))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_product_value, {
        key: 1,
        value: {
          name: row.item?.product_name,
          image: row.item?.main_image_link,
          asin: row.item?.asin,
          sku: row.item?.sku,
          link: row.item?.amazon_link
        },
        loading: $props.loading,
        sponsored: row.item?.sku === 'SPONSORED_BRANDS'
      }, null, 8 /* PROPS */, ["value", "loading", "sponsored"]))]),
      "row-details": _withCtx(row => []),
      "cell(page_views)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 4
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}page_views_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              "show-sign": "",
              value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}page_views_evol`],
              color: color,
              charCount: $options.maxCharCount.percentage?.[`${$data.evolCategory[_ctx.periodCategory]}page_views_evol`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_bar_with_value, {
            tight: "",
            align: "left",
            value: row.item?.page_views,
            min: $options.minMax?.page_views?.min,
            color: "#8D85E5",
            max: $options.minMax?.page_views?.max,
            loading: $props.loading
          }, {
            default: _withCtx(() => [_createVNode(_component_number_value, {
              value: row.item?.page_views,
              charCount: $options.maxCharCount?.number?.page_views,
              tight: ""
            }, null, 8 /* PROPS */, ["value", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(ratings_total)": _withCtx(row => [_createVNode(_component_number_value, {
        value: row.item?.ratings_total,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(lost_revenue_excl_VAT)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading,
        align: "right"
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.lost_revenue_excl_VAT,
          color: $data.COLORS.RED700
        }, null, 8 /* PROPS */, ["value", "color"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(spend)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 4
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}spend_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}spend_evol`],
              color: color,
              charCount: $options.maxCharCount.percentage?.[`${$data.evolCategory[_ctx.periodCategory]}spend_evol`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_bar_with_value, {
            tight: "",
            align: "left",
            value: row.item?.spend,
            color: "#5A52AE",
            min: $options.minMax?.spend?.min,
            max: $options.minMax?.spend?.max,
            loading: $props.loading,
            width: $options.maxCharCount?.currency?.spend
          }, {
            default: _withCtx(() => [_createVNode(_component_currency_value, {
              value: row.item?.spend,
              charCount: $options.maxCharCount?.currency?.spend
            }, null, 8 /* PROPS */, ["value", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "width"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(sponsored_sales)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 4
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}sponsored_sales_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              "show-sign": "",
              value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}sponsored_sales_evol`],
              color: color,
              charCount: $options.maxCharCount.percentage?.[`${$data.evolCategory[_ctx.periodCategory]}sponsored_sales_evol`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_bar_with_value, {
            tight: "",
            align: "left",
            value: row.item?.sponsored_sales,
            color: "#8D85E5",
            min: $options.minMax?.sponsored_sales?.min,
            max: $options.minMax?.sponsored_sales?.max,
            loading: $props.loading,
            width: $options.maxCharCount?.currency?.sponsored_sales
          }, {
            default: _withCtx(() => [_createVNode(_component_currency_value, {
              value: row.item?.sponsored_sales,
              charCount: $options.maxCharCount?.currency?.sponsored_sales
            }, null, 8 /* PROPS */, ["value", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "width"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(CVR)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getColorsBasedOnRange(row.item?.CVR, $data.CVR_HEATMAP_COLORS),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.CVR,
          limit: 0.1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(ROAS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
        value: row.item?.ROAS,
        background: "#E6E9FE",
        borderColor: "#D8DCFD",
        min: $options.minMax?.ROAS?.min,
        max: $options.minMax?.ROAS?.max,
        loading: $props.loading,
        align: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          styles: {
            padding: 0
          },
          value: row.item?.ROAS,
          precision: 1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(units_sold_clicks_14_d)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 16
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            value: row.item?.units_sold_clicks_14_d,
            loading: $props.loading,
            tight: ""
          }, null, 8 /* PROPS */, ["value", "loading"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(clicks)": _withCtx(row => [_createVNode(_component_number_value, {
        value: row.item?.clicks,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(CPC)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getColorForDecile(row.item?.CPC, $options.minMax?.CPC?.min, $options.minMax?.CPC?.max, $data.CPC_DECILE_COLORS),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.CPC,
          precision: 2,
          config: _ctx.currencyConfig
        }, null, 8 /* PROPS */, ["value", "config"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(sponsored_asin)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.sponsored_asin,
        color: "#4C8EFF",
        min: $options.minMax?.sponsored_asin?.min,
        max: $options.minMax?.sponsored_asin?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          value: row.item?.sponsored_asin,
          charCount: $options.maxCharCount.number?.sponsored_asin,
          style: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(pl_net_revenue)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 8
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${$data.evolCategory[this.periodCategory]}pl_net_revenue_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: row.item?.[`${$data.evolCategory[this.periodCategory]}pl_net_revenue_evol`],
              color: color,
              "show-sign": ""
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_bar_with_value, {
            tight: "",
            align: "left",
            value: row.item?.pl_net_revenue,
            min: $options.minMax?.pl_net_revenue?.min,
            max: $options.minMax?.pl_net_revenue?.max,
            color: "#22863D"
          }, {
            default: _withCtx(() => [_createVNode(_component_currency_value, {
              value: row.item?.pl_net_revenue,
              charCount: $options.maxCharCount?.currency?.pl_net_revenue
            }, null, 8 /* PROPS */, ["value", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(pl_contributive_margin)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 8
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}pl_contributive_margin_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}pl_contributive_margin_evol`],
              color: color,
              "show-sign": "",
              charCount: $options.maxCharCount?.percentage?.[`${$data.evolCategory[_ctx.periodCategory]}pl_contributive_margin_evol`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_signed_bar_with_value, {
            tight: "",
            align: "left",
            value: row.item?.pl_contributive_margin,
            min: $options.minMax?.pl_contributive_margin?.min,
            max: $options.minMax?.pl_contributive_margin?.max,
            positiveColor: "#5A52AE",
            negativeMin: $options.minMax?.pl_contributive_margin?.negativeMin
          }, {
            default: _withCtx(() => [_createVNode(_component_currency_value, {
              value: row.item?.pl_contributive_margin,
              charCount: $options.maxCharCount?.currency?.pl_contributive_margin
            }, null, 8 /* PROPS */, ["value", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "negativeMin"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(pl_amazon_commission_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getProfitPercentageDecileColor(row, 'pl_amazon_commission_pc'),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.pl_amazon_commission_pc
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(pl_fba_direct_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getProfitPercentageDecileColor(row, 'pl_fba_direct_pc'),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.pl_fba_direct_pc
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(pl_fba_indirect_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getProfitPercentageDecileColor(row, 'pl_fba_indirect_pc'),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.pl_fba_indirect_pc
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(pl_refund_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getProfitPercentageDecileColor(row, 'pl_refund_pc'),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.pl_refund_pc
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(pl_coop_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getProfitPercentageDecileColor(row, 'pl_coop_pc'),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.pl_coop_pc
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(pl_cogs_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getProfitPercentageDecileColor(row, 'pl_cogs_pc'),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.pl_cogs_pc
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(pl_other_costs_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getProfitPercentageDecileColor(row, 'pl_other_costs_pc'),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.pl_other_costs_pc
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(pl_net_percentage_revenue)": _withCtx(row => [_createVNode(_component_block_bar_with_value, {
        value: row.item?.pl_net_percentage_revenue * 0.7,
        min: $options.minMax?.pl_net_percentage_revenue?.min,
        max: $options.minMax?.pl_net_percentage_revenue?.max,
        color: $options.getColorForDecile(row.item?.pl_net_percentage_revenue, this.minMax?.pl_net_percentage_revenue?.min, this.minMax?.pl_net_percentage_revenue?.max, $data.PROFIT_REVENUE_PERCENTAGE_DECILE_COLORS, 5, 4),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_hug_value, {
          styles: {
            padding: '0 8px',
            alignSelf: 'stretch',
            justifyContent: 'flex-end',
            gap: '4px',
            position: 'relative'
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_8, [row.item?.pl_net_percentage_revenue < 0 ? (_openBlock(), _createBlock(_component_wrap_with_alert_icon, {
            key: 0
          }, {
            default: _withCtx(() => [_createVNode(_component_percentage_value, {
              value: row.item?.pl_net_percentage_revenue,
              styles: {
                justifyContent: 'flex-start'
              },
              charCount: $options.maxCharCount.percentage?.pl_net_percentage_revenue,
              color: $data.COLORS.RED700
            }, null, 8 /* PROPS */, ["value", "charCount", "color"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)) : (_openBlock(), _createBlock(_component_percentage_value, {
            key: 1,
            value: row.item?.pl_net_percentage_revenue,
            styles: {
              justifyContent: 'flex-start'
            },
            charCount: $options.maxCharCount.percentage?.pl_net_percentage_revenue
          }, null, 8 /* PROPS */, ["value", "charCount"]))]), _createVNode(_component_evolution_value, {
            value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}pl_net_percentage_revenue_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}pl_net_percentage_revenue_evol`],
              color: color,
              "show-sign": "",
              pp: ""
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "color", "loading"])]),
      "cell(pl_advertising_costs)": _withCtx(row => [_createVNode(_component_box_and_bar_with_value, {
        loading: $props.loading,
        bgColor: $options.getProfitPercentageDecileColor(row, 'pl_advertising_costs_pc'),
        barColor: "#F1959C",
        value: row.item?.pl_advertising_costs,
        min: $options.minMax?.pl_advertising_costs?.min,
        max: $options.minMax?.pl_advertising_costs?.max
      }, {
        "box-value": _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.pl_advertising_costs_pc
        }, null, 8 /* PROPS */, ["value"])]),
        "bar-value": _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.pl_advertising_costs,
          color: "#F2616D"
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "bgColor", "value", "min", "max"])]),
      "cell(avg_unit_price)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
          gap: 16
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              "show-sign": "",
              value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`],
              color: color,
              charCount: $options.maxCharCount.percentage?.[`${$data.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.avg_unit_price,
            precision: 2,
            align: "right"
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "style", "items", "fields", "tbodyTrClass", "onHeadClicked", "onRowClicked"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["datePickerDate", "loading", "onChangeDate", "onTableState", "page", "totalPages", "perPage", "fields"]);
}