export default {
  name: 'AppCardCustom',
  props: {
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className() {
      const result = ['app-card-custom'];
      if (this.noPadding) {
        result.push('no-padding');
      }
      return result.join(' ');
    }
  }
};