export default {
  name: "TheTopbar",
  computed: {
    breadcrumbItems() {
      return this.$store.state.breadcrumb.breadcrumbs;
    }
  },
  watch: {
    $route(newRoute) {
      this.resetAll();
      this.emitBreadcrumbsUpdate(newRoute);
    }
  },
  async created() {
    this.emitBreadcrumbsUpdate(this.$route);
  },
  unmounted() {
    this.resetAll();
  },
  methods: {
    resetAll() {
      this.activeSEOPageTopSection = false;
      this.activePPCPageTopSection = false;
      this.activeProfilePageTopSection = false;
      this.activateProductDataTopSection = false;
    },
    emitBreadcrumbsUpdate(route) {
      let breadcrumbs = this.calculateBreadcrumbs(route);
      this.$store.dispatch("setBreadcrumbs", breadcrumbs);
    },
    calculateBreadcrumbs(route) {
      let breadcrumbs = [];
      route.matched.forEach(record => {
        if (record.meta && record.meta.breadcrumb) {
          if (route.name !== "Dashboard") {
            breadcrumbs.push({
              text: record.meta.menuName,
              to: "/"
            });
          }
          breadcrumbs.push({
            text: record.meta.breadcrumb,
            to: record.path
          });
        }
      });
      breadcrumbs = breadcrumbs.filter(crumb => crumb.text !== undefined);
      return breadcrumbs;
    }
  }
};