import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-396f36da"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "targeting-value"
};
const _hoisted_2 = {
  key: 0,
  class: "image"
};
const _hoisted_3 = {
  class: "main"
};
const _hoisted_4 = {
  class: "targeting-wrapper"
};
const _hoisted_5 = {
  class: "targeting"
};
const _hoisted_6 = {
  class: "type"
};
const _hoisted_7 = {
  class: "link-wrapper"
};
const _hoisted_8 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_img = _resolveComponent("b-img");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_stroked_link_icon = _resolveComponent("stroked-link-icon");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    loading: $props.loading,
    align: $props.align
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [$props.value.type === 'TARGETING_EXPRESSION' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_img, {
      src: $props.value.image ?? '/assets/images/amazon-logo-transparent.svg',
      alt: "Targeting",
      width: "32",
      height: "32"
    }, null, 8 /* PROPS */, ["src"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString($props.value.targeting), 1 /* TEXT */), _createElementVNode("div", _hoisted_6, [_createVNode(_component_AppIcon, {
      icon: `CAMPAIGN-${$props.value?.type ?? ''}`,
      color: "#736BC9"
    }, null, 8 /* PROPS */, ["icon"]), _createElementVNode("div", null, _toDisplayString($props.value?.type), 1 /* TEXT */)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("a", {
      href: $props.value.link,
      target: "_blank",
      class: "link"
    }, [_createVNode(_component_stroked_link_icon, {
      width: "16",
      height: "16",
      color: "#999999"
    })], 8 /* PROPS */, _hoisted_8)])])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading", "align"]);
}