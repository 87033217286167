import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-404e2904"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "p-2"
};
const _hoisted_2 = {
  class: "drop-downn-items"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_b_dropdown, {
    class: "custom-dropdown",
    disabled: $props.disabled,
    variant: "",
    style: {
      "width": "224px"
    },
    ref: "dropdown",
    show: $data.isDropdownOpen,
    "auto-close": "outside"
  }, {
    "button-content": _withCtx(() => [_createTextVNode(_toDisplayString($data.computedValue?.text || $props.placeholder) + " ", 1 /* TEXT */), _createVNode(_component_b_icon, {
      icon: "caret-down-fill"
    })]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_form_input, {
      modelValue: $options.sanitizedSearchQuery,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.sanitizedSearchQuery = $event),
      placeholder: "Search...",
      size: "sm",
      onFocus: $options.keepDropdownOpen,
      onBlur: $options.handleInputBlur,
      class: _normalizeClass({
        'is-invalid': $data.searchQuery && $data.searchQuery.trim().length !== 5
      }),
      title: $data.searchQuery && $data.searchQuery.trim().length !== 5 ? 'Must be exactly 5 characters' : ''
    }, null, 8 /* PROPS */, ["modelValue", "onFocus", "onBlur", "class", "title"])]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredOptions, (option, index) => {
      return _openBlock(), _createBlock(_component_b_dropdown_item, {
        key: index,
        onClick: $event => $options.selectOption(option),
        class: "dropdown-item"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(option.text), 1 /* TEXT */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]);
    }), 128 /* KEYED_FRAGMENT */)), $options.filteredOptions.length === 0 ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
      key: 0,
      disabled: ""
    }, {
      default: _withCtx(() => [_createTextVNode(" No vendor code found ")]),
      _: 1 /* STABLE */
    })) : _createCommentVNode("v-if", true), $data.searchQuery ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
      key: 1,
      onClick: $options.addOption,
      class: "dropdown-item text-primary",
      disabled: $data.searchQuery.trim().length !== 5
    }, {
      default: _withCtx(() => [_createTextVNode(" Add \"" + _toDisplayString($data.searchQuery) + "\" ", 1 /* TEXT */)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onClick", "disabled"])) : _createCommentVNode("v-if", true)])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled", "show"])]);
}