import DatePickerSelector from '@/components/DatePickerSelector.vue';
import DropdownBody from '@/components/shared/DropdownBody.vue';
import DatePicker from '@/components/DatePicker.vue';
import { EVENTS, processDate } from '@/utils';
import { getDatePickerDayId } from "@/utils/datepicker";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: 'DatePickerPopup',
  components: {
    DatePickerSelector,
    DropdownBody,
    DatePicker
  },
  props: {
    datePickerDate: {
      type: Object
    },
    pillStyle: {
      type: Object,
      default: () => ({
        backgroundColor: '#D0E2FF',
        color: '#1755AB'
      })
    }
  },
  beforeUnmount() {
    window.$bus.off(EVENTS.RESET_DATE_PICKER);
    window.$bus.off(EVENTS.CALENDAR_AUTO_SCROLL);
  },
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    if (this.datePickerDate?.selector?.start !== null) {
      this.loading = false;
    }
  },
  watch: {
    datePickerDate: {
      handler(_, oldValue) {
        if (oldValue?.selector?.start !== null) this.loading = false;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["getSystemWeekType"])
  },
  methods: {
    handleToggle(e) {
      if (this.loading) {
        // We put this check here to prevent the dropdown from opening 
        // when the date picker is loading
        return;
      }
      this.handleHidden();
      this.$refs.dropdown.toggle();
    },
    handleDatePickerCanel() {
      this.$emit('cancel');
      this.$nextTick(() => {
        this.$refs.datePicker.reset(this.datePickerDate);
        this.$refs.dropdown.close();
      });
    },
    handleSubmit(date) {
      const format = 'YYYY-MM-DD';
      date.range.start = processDate(date.range.start, format);
      date.range.end = processDate(date.range.end, format);
      date.compare.start = processDate(date.compare.start, format);
      date.compare.end = processDate(date.compare.end, format);
      this.$emit('submit', date);
      this.$refs.dropdown.close();
      // Reset the date picker to the current range
      // This is to ensure that the right range is selected when the user 
      // opens the date picker again

      this.$refs.datePicker.reset(date);
    },
    handleHidden() {
      // Reset the date picker to the current range
      this.$refs.datePicker.reset(this.datePickerDate);
    },
    handleShown() {
      //console.log("Range start", this.datePickerDate.range.start);
      this.$refs.datePicker.scrollTo(getDatePickerDayId(this.datePickerDate.range.start, 'month-date'), "auto");
    }
  }
};