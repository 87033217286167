export default {
  name: 'DatePickerSidebar',
  emits: ['update:range', 'update:compare', 'rangeItemClick', 'compareItemClick', 'scrollToSelectedDate'],
  props: {
    range: {
      type: Number,
      default: 0
    },
    rangeList: {
      type: Array,
      default: () => []
    },
    compare: {
      type: Number,
      default: 0
    },
    compareList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onCurrentRangeChange(id) {
      this.$emit('update:range', id);
      this.$emit('rangeItemClick');
      this.$emit('scrollToSelectedDate', id);
    },
    onCurrentCompareChange(id) {
      this.$emit('update:compare', id);
      this.$emit('compareItemClick');
    }
  }
};