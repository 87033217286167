import DoubleChevronsDownIcon from '@/components/icons/DoubleChevronsDownIcon.vue';
import { APP_PERIOD_DROPDOWN_ELEMENTS } from '@/utils/constants';
export default {
  name: "WrapTableWithShowMore",
  components: {
    DoubleChevronsDownIcon
  },
  props: {
    modelValue: {
      type: Number
    },
    period: {
      type: String
    }
  },
  emits: ['update:modelValue'],
  computed: {
    showButton() {
      return [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value, APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value].includes(this.period);
    }
  },
  methods: {
    handleClick(_) {
      this.$emit('update:modelValue');
    }
  }
};