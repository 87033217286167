import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_number_value = _resolveComponent("number-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_PercentageValue = _resolveComponent("PercentageValue");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_kpi_card = _resolveComponent("kpi-card");
  return _openBlock(), _createBlock(_component_kpi_card, {
    name: "Spend",
    subtitle: "clicks",
    loading: $props.loading
  }, {
    "kpi-subtitle-amount": _withCtx(() => [_createVNode(_component_number_value, {
      value: $props.subtitleAmount,
      styles: {
        padding: '0'
      }
    }, null, 8 /* PROPS */, ["value"])]),
    "subtitle-percentage": _withCtx(() => [_createVNode(_component_percentage_value, {
      value: $props.subtitlePercentage,
      styles: {
        padding: '0'
      },
      "show-sign": ""
    }, null, 8 /* PROPS */, ["value"])]),
    amount: _withCtx(() => [_createVNode(_component_currency_value, {
      value: $props.amount
    }, null, 8 /* PROPS */, ["value"])]),
    "change-rate": _withCtx(() => [_createVNode(_component_b_badge, {
      style: _normalizeStyle({
        background: `${$data.COLORS.GRAY_BLUE} !important`
      })
    }, {
      default: _withCtx(() => [_createVNode(_component_PercentageValue, {
        value: $props.percentage,
        color: $data.COLORS.DARK_GRAY,
        styles: {
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '16px'
        },
        showSign: ""
      }, null, 8 /* PROPS */, ["value", "color"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["style"])]),
    "info-popover-title": _withCtx(() => [_createTextVNode(_toDisplayString($data.title), 1 /* TEXT */)]),
    "info-popover-content": _withCtx(() => [_createTextVNode(_toDisplayString($data.detail), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"]);
}