import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-06bfac08"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_card_title = _resolveComponent("b-card-title");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_overlay = _resolveComponent("b-overlay");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createElementBlock(_Fragment, null, [!$props.pushModal ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_overlay, {
    show: $props.loading,
    variant: "white"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card, {
      style: _normalizeStyle({
        ...$options.styles,
        overflow: $props.scrollable ? 'auto' : 'hidden',
        border: $props.hasBorder ? '1px solid #20000000;' : 'none'
      }),
      class: "custom-card"
    }, {
      default: _withCtx(() => [$props.title ? (_openBlock(), _createBlock(_component_b_card_title, {
        key: 0,
        class: "card-title"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.title), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["style"])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["show"])])) : _createCommentVNode("v-if", true), $props.pushModal ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_modal, {
    centered: "",
    scrollable: "",
    "hide-footer": "",
    "hide-header": "",
    id: "seo-push-modal",
    modelValue: $options.modal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.modal = $event)
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue"])])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
}