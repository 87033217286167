import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_product_score = _resolveComponent("product-score");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_popover = _resolveComponent("app-popover");
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    align: "center",
    class: "border-table-row stick-to-right",
    stickyColumn: "",
    style: _normalizeStyle({
      right: $props.pushModal ? '111px' : '147px'
    })
  }, {
    default: _withCtx(() => [_createVNode(_component_product_score, {
      size: 20,
      score: $props.item.statusAmzScore.score,
      color: $props.item.statusAmzScore.status == 'success' ? '#22863d' : $props.item.statusAmzScore.status == 'warning' ? '#d7aa0f' : '#e7747a',
      id: `${$props.item.content_id}-score-amazon`
    }, null, 8 /* PROPS */, ["score", "color", "id"]), _createVNode(_component_app_popover, {
      target: `${$props.item.content_id}-score-amazon`,
      "auto-width": "",
      placement: "left",
      "custom-class": "seo-popover"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.statusResultAmzScore, result => {
        return _openBlock(), _createElementBlock("div", {
          class: "status-item",
          key: result.text
        }, [_createVNode(_component_app_icon, {
          size: "16",
          icon: result.status == 'success' ? 'check-v2' : result.status == 'warning' ? 'product-score-warning' : 'product-score-bad',
          color: result.status == 'success' ? '#22863d' : result.status == 'warning' ? '#d7aa0f' : '#ffffff'
        }, null, 8 /* PROPS */, ["icon", "color"]), _createElementVNode("span", null, _toDisplayString(result.text), 1 /* TEXT */)]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["target"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style"]);
}