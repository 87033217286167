import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 16 16`,
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M6 12.332H10M4.66667 9.9987H11.3333M3.33333 1.33203H12.6667C13.403 1.33203 14 1.99531 14 2.81351V13.1839C14 14.0021 \n                13.403 14.6654 12.6667 14.6654H3.33333C2.59695 14.6654 2 14.0021 2 13.1839V2.81351C2 1.99531 2.59695 1.33203 3.33333 \n                1.33203ZM7.9984 4.13999C7.53189 3.62056 6.75394 3.48084 6.16943 3.95648C5.58492 4.43212 5.50263 5.22736 5.96165 5.7899C6.42067 \n                6.35244 7.9984 7.66536 7.9984 7.66536C7.9984 7.66536 9.57614 6.35244 10.0352 5.7899C10.4942 5.22736 10.4219 4.42711 9.82738 \n                3.95648C9.23282 3.48584 8.46492 3.62056 7.9984 4.13999Z",
    stroke: $props.color,
    "stroke-width": "1.33333",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}