import FlexRow from '@/components/FlexRow.vue';
import StrokedArrowDownIcon from '@/components/icons/StrokedArrowDownIcon.vue';
import StrokedArrowUpIcon from '@/components/icons/StrokedArrowUpIcon.vue';
import PercentageValue from '@/components/tableElements/PercentageValue.vue';
import LayeredBarWithValue from '@/components/tableElements/LayeredBarWithValue.vue';
import CurrencyValue from '@/components/tableElements/CurrencyValue.vue';
import HugValue from '@/components/tableElements/HugValue.vue';
import RateChangeModal from '@/components/tableElements/RateChangeModal.vue';
import { COLORS } from "@/utils/constants";
import EvolutionValue from "@/components/tableElements/EvolutionValue.vue";
import SlotWithEvolutionValue from "@/components/tableElements/SlotWithEvolutionValue.vue";
export default {
  name: 'RateChangeValue',
  components: {
    FlexRow,
    StrokedArrowDownIcon,
    StrokedArrowUpIcon,
    PercentageValue,
    LayeredBarWithValue,
    CurrencyValue,
    HugValue,
    RateChangeModal,
    EvolutionValue,
    SlotWithEvolutionValue
  },
  emits: ['click'],
  props: {
    value1: {
      type: [Number, null],
      required: false,
      default: 0
    },
    value2: {
      type: [Number, null],
      required: false,
      default: 0
    },
    min1: {
      type: Number,
      required: false,
      default: 0
    },
    max1: {
      type: Number,
      required: false,
      default: 0
    },
    min2: {
      type: Number,
      required: false,
      default: 0
    },
    max2: {
      type: Number,
      required: false,
      default: 0
    },
    color1: {
      type: String,
      required: true,
      default: '#312783'
    },
    color2: {
      type: String,
      required: true,
      default: '#312783'
    },
    rateChange: {
      type: [Number, null],
      required: false,
      default: 0
    },
    rateChangeCharCount: {
      type: Number
    },
    change: {
      type: String,
      required: false,
      default: 'positive'
      /**
      * Possible values:
          * - positive:
              *    - we consider positive values as `GREEN`
              *   - we consider negative values as `RED`
          * - negative
              *  - we consider positive values as `RED`
              * - we consider negative values as `GREEN`
       */
    },
    type: {
      type: String,
      default: 'percentage' // value can be percentage or currency
    },
    loading: {
      type: Boolean,
      default: false
    },
    noModal: {
      type: Boolean,
      default: false
    },
    evolutionType: {
      type: String,
      default: "percentage"
    }
  },
  computed: {
    rateChangeColor() {
      const changes = {
        positive: () => {
          return this.rateChange > 0 ? COLORS.DARK_GREEN : COLORS.DARK_RED;
        },
        negative: () => {
          return this.rateChange < 0 ? COLORS.DARK_RED : COLORS.DARK_GREEN;
        }
      };
      return changes[this.change]();
    },
    getVisibility: {
      set(val) {
        this.visible = val;
      },
      get() {
        return this.visible;
      }
    }
  },
  methods: {}
};