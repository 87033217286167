import AccountsMixin from "@/mixins/AccountsMixin.vue";
import { EVENTS } from "@/utils";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
export default {
  name: "PPCManagementMixin",
  mixins: [AccountsMixin, InitializeFiltersMixin],
  mounted() {
    window.$bus.on(EVENTS.REFRESH_SELLERS_SUB_FILTERS, async () => {
      const sellers = this.$store.state.ppc.sellers.filterValues;
      const sales_channel = await this.initializeMarketplacesFilters({
        sellers
      }, "ppc", "marketplaces", "ppc");
      await this.fetchSubFilters({
        sellers,
        sales_channel
      }, "ppc", {
        product: "products",
        match_type: "match_types",
        targeting: "targetings",
        campaign: "campaigns"
      }, "ppc");
    });
    window.$bus.on(EVENTS.REFRESH_MARKETPLACES_SUB_FILTERS, async () => {
      const sellers = this.$store.state.ppc.sellers.filterValues;
      const sales_channel = this.$store.state.ppc.marketplaces.filterValues;
      await this.fetchSubFilters({
        sellers,
        sales_channel
      }, "ppc", {
        product: "products",
        match_type: "match_types",
        targeting: "targetings",
        campaign: "campaigns"
      }, "ppc");
    });
    window.$bus.on(EVENTS.REFRESH_SETTINGS_SELLERS_SUB_FILTERS, () => {
      const sellers = this.$store.state.ppcSettings.sellers.filterValues;
      this.initializeMarketplacesFilters({
        sellers
      }, "ppcSettings", "marketplaces", "ppc");
    });
  }
};