export default {
  name: "FlexColumn",
  props: {
    gap: {
      type: Number,
      default: 0
    },
    classes: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      default: ""
    }
  },
  computed: {
    getComputedStyle() {
      return {
        display: "flex",
        flexDirection: "column",
        gap: this.gap + "px",
        height: "100%",
        ...this.styles
      };
    }
  }
};