import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_td = _resolveComponent("b-td");
  return _openBlock(), _createBlock(_component_b_td, {
    class: "custom-td",
    align: $props.align,
    valign: $props.valign,
    "sticky-column": $props.stickyColumn
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["align", "valign", "sticky-column"]);
}