import { mapState } from "vuex";
import { CurrencyValue } from "@/components/tableElements";
import PercentageValue from "@/components/tableElements/PercentageValue";
export default {
  name: "PpcBidSimulator",
  components: {
    CurrencyValue,
    PercentageValue
  },
  props: {
    targeting: {
      type: Object,
      default: {}
    },
    currency: {
      type: String
    },
    focusTargeting: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState({
      bid_simulator: state => state.ppc_settings.bid_simulator
    })
  },
  data() {
    return {
      targeting_types: ["B.Non-Branded KW", "A.Competitors Product", "C.Branded", "D.Discovery"],
      key_mapping: {
        "B.Non-Branded KW": "non_branded_keyword_targeting",
        "A.Competitors Product": "competitor_product_targeting",
        "C.Branded": "branded_keyword_product_targeting",
        "D.Discovery": "discovery_targeting"
      },
      row_styles: {
        "B.Non-Branded KW": {
          marginTop: 0,
          colorClass: "blue",
          focusClass: "focus-blue"
        },
        "A.Competitors Product": {
          colorClass: "purple",
          focusClass: "focus-purple"
        },
        "C.Branded": {
          colorClass: "red",
          focusClass: "focus-red"
        },
        "D.Discovery": {
          marginTop: "44px",
          colorClass: "gray",
          focusClass: "focus-gray"
        }
      },
      precision: 2
    };
  },
  methods: {
    getTargeting(key) {
      let result = this.bid_simulator[key];
      return !!result ? result[0].targeting : 0;
    },
    getCvr(key) {
      let result = this.bid_simulator[key];
      return !!result ? result[0].cvr : 0;
    },
    getProductMargin(key) {
      let result = this.bid_simulator[key];
      return !!result ? result[0].total_weighted_margin_ccy : 0;
    },
    avgMargin(key) {
      let product_margin = this.getProductMargin(key);
      let cvr = this.getCvr(key);
      return product_margin * cvr;
    },
    computedMaxBid(key) {
      let result = this.targeting[this.key_mapping[key]] / 100 * this.avgMargin(key);
      return result;
    },
    getRowStyle(key) {
      return this.row_styles[key];
    }
  }
};