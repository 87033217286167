import { mapMutations, mapGetters, mapActions } from "vuex";
import AppIcon from "@/components/AppIcon";
import AppTabs from "@/components/AppTabs";
import AppButton from "@/components/AppButton";
import { EVENTS, isObjectEmpty } from "@/utils";
import DemandForecastingSettingsCoverage from "@/components/DemandForecastingSettingsCoverage";
import DemandForecastingSettingsForecasting from "@/components/DemandForecastingSettingsForecasting";
export default {
  name: "DemandForecastingSettings",
  emits: ["close"],
  components: {
    AppIcon,
    AppTabs,
    AppButton,
    DemandForecastingSettingsCoverage,
    DemandForecastingSettingsForecasting
  },
  props: {
    account_id: {
      type: String,
      default: ""
    }
  },
  async mounted() {
    await this.refreshDemandForecastingSettings();
  },
  data() {
    return {
      tabs: [{
        value: "coverage",
        text: "Target coverage",
        canSwitchTab: this.canSwitchTab
      }, {
        value: "forecasting",
        text: "Forecasting",
        canSwitchTab: this.canSwitchTab
      }],
      tab: "coverage",
      settings: {}
    };
  },
  computed: {
    ...mapGetters(["demandForecastingSettingsForAccountGet"]),
    settingsInitialized() {
      return !isObjectEmpty(this.settings);
    }
  },
  methods: {
    ...mapActions(["demandForecastingSettingsSave"]),
    ...mapMutations(["demandForecastingSettingsUpdate"]),
    onClose() {
      this.$emit("close");
    },
    canSwitchTab() {
      return true;
    },
    async onUpdateForecastingSettings(data) {
      let payload = {
        ...data,
        ...{
          account_id: this.account_id
        }
      };
      this.settings = payload;
      await this.demandForecastingSettingsUpdate(payload);
    },
    async onSave() {
      if (!isObjectEmpty(this.settings)) {
        let payload = {
          ...this.settings,
          ...{
            account_id: this.account_id
          }
        };
        await this.demandForecastingSettingsSave(payload);
        await window.$bus.trigger(EVENTS.REFRESH_DEMAND_FORECASTING_SETTINGS);
        await window.$bus.trigger(EVENTS.REFRESH_DEMAND_FORECASTING_PAGE);
        this.$emit("close");
      }
    },
    async refreshDemandForecastingSettings() {
      await window.$bus.trigger(EVENTS.REFRESH_DEMAND_FORECASTING_SETTINGS);
      this.settings = this.demandForecastingSettingsForAccountGet(this.account_id);
    }
  }
};