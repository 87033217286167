import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kpis_controllers_section = _resolveComponent("kpis-controllers-section");
  const _component_product_performance_page = _resolveComponent("product-performance-page");
  const _component_product_advertising_page = _resolveComponent("product-advertising-page");
  const _component_product_inventory_coverage_page = _resolveComponent("product-inventory-coverage-page");
  const _component_product_pricing_margin_page = _resolveComponent("product-pricing-margin-page");
  const _component_LoadingTable = _resolveComponent("LoadingTable");
  const _component_kpis_renderer = _resolveComponent("kpis-renderer");
  return _openBlock(), _createBlock(_component_kpis_renderer, null, {
    controllerSection: _withCtx(() => [_createVNode(_component_kpis_controllers_section, {
      filters: $options.getUpdatedTabFilters,
      showFilterSectionLine: false,
      showTabSection: false,
      onChangeTab: _cache[0] || (_cache[0] = value => $options.handleTabChange(value)),
      onChangeView: _cache[1] || (_cache[1] = value => $options.handleViewChange(value)),
      pages: $options.getUpdatedPages,
      pdfPages: _ctx.getPdfExportPages,
      pdfPeriods: _ctx.getPdfExportPeriods,
      tabPeriods: _ctx.getTabPeriods,
      graphControllers: _ctx.getGraphControllers,
      currentTab: _ctx.currentTab,
      currentView: _ctx.currentView,
      currentPeriod: $data.currentPeriod,
      showDatePicker: $options.showDatePicker
    }, null, 8 /* PROPS */, ["filters", "pages", "pdfPages", "pdfPeriods", "tabPeriods", "graphControllers", "currentTab", "currentView", "currentPeriod", "showDatePicker"])]),
    kpisSection: _withCtx(() => [_createVNode(_component_LoadingTable, {
      loading: $data.pageLoading
    }, {
      default: _withCtx(() => [$data.showProductPerformancePage ? (_openBlock(), _createBlock(_component_product_performance_page, {
        key: 0,
        period: this.currentPeriod
      }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showProductAdvertisingPage ? (_openBlock(), _createBlock(_component_product_advertising_page, {
        key: 1,
        period: this.currentPeriod
      }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showProductInventoryCoveragePage ? (_openBlock(), _createBlock(_component_product_inventory_coverage_page, {
        key: 2,
        period: this.currentPeriod
      }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showProductPricingAndMarginPage ? (_openBlock(), _createBlock(_component_product_pricing_margin_page, {
        key: 3,
        period: this.currentPeriod
      }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading"])]),
    _: 1 /* STABLE */
  });
}