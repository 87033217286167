import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createBlock(_component_FlexRow, {
    gap: $props.gap
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.icons, ({
      icon,
      click,
      size,
      disabled,
      help,
      placement
    }) => {
      return _openBlock(), _createElementBlock("div", {
        key: icon,
        class: _normalizeClass(["icon-wrapper", [{
          disabled
        }]])
      }, [help ? (_openBlock(), _createBlock(_component_wrap_with_popover, {
        key: 0,
        placement: placement ?? 'left',
        width: "fit-content"
      }, {
        title: _withCtx(() => [_createTextVNode(_toDisplayString(help), 1 /* TEXT */)]),
        default: _withCtx(() => [_createVNode(_component_AppIcon, {
          icon: icon,
          size: size ?? 16,
          color: $props.color,
          onClick: $event => $options.handleClick($event, click)
        }, null, 8 /* PROPS */, ["icon", "size", "color", "onClick"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["placement"])) : (_openBlock(), _createBlock(_component_AppIcon, {
        key: 1,
        icon: icon,
        size: size ?? 16,
        color: $props.color,
        onClick: $event => $options.handleClick($event, click)
      }, null, 8 /* PROPS */, ["icon", "size", "color", "onClick"]))], 2 /* CLASS */);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["gap"]);
}