import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-37aaac8a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "controllers"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.controllers, (controller, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "controller"
    }, [_createVNode(_component_b_button, {
      active: this.activate(controller.name),
      variant: "outline-secondary",
      size: "sm",
      class: "graph-controller",
      onClick: $event => $options.handleClick(controller)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(controller.name), 1 /* TEXT */)]),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["active", "onClick"])]);
  }), 128 /* KEYED_FRAGMENT */))]);
}