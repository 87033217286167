import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3d9a5342"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "search"
};
const _hoisted_2 = ["placeholder", "value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", {
    class: "form-control",
    placeholder: $props.placeholder,
    type: "search",
    pattern: ".*\\S.*",
    minlength: "3",
    onInput: _cache[0] || (_cache[0] = $event => $options.onInput($event)),
    value: $props.value
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2)]);
}