import AppIcon from "@/components/AppIcon";
export default {
  name: "AiAssistanceCard",
  components: {
    AppIcon
  },
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
};