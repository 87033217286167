import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-276c4f97"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "date-picker-wrapper"
};
const _hoisted_3 = {
  class: "pill"
};
const _hoisted_4 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_date_picker = _resolveComponent("date-picker");
  const _component_dropdown_body = _resolveComponent("dropdown-body");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_dropdown, {
    ref: "dropdown",
    "no-caret": "",
    toggleClass: "clicker",
    onShown: $options.handleDatePickerShown,
    onHidden: $options.handleDatePickerHidden
  }, {
    "button-content": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("span", _hoisted_3, _toDisplayString($data.title), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString($data.startDate) + " - " + _toDisplayString($data.endDate) + " ", 1 /* TEXT */), _createVNode(_component_font_awesome_icon, {
      class: "calendar",
      icon: "fa-calendar"
    })])])]),
    default: _withCtx(() => [_createVNode(_component_dropdown_body, null, {
      default: _withCtx(() => [_createVNode(_component_date_picker, {
        ref: "datePicker",
        onCancel: $options.handleDatePickerCanel,
        onSubmit: $options.handleSubmit
      }, null, 8 /* PROPS */, ["onCancel", "onSubmit"])]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onShown", "onHidden"])]);
}