import GroupBy from "@/components/GroupBy.vue";
import LoadingElement from "@/components/shared/LoadingElement.vue";
import { mapState } from "vuex";
export default {
  name: "PerformanceProductTableGroupBy",
  components: {
    GroupBy,
    LoadingElement
  },
  emits: ["refresh", "clicked"],
  props: {
    tabBusy: {
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      groupByElements: state => state.dashboard_v2.product_table.group_by_elements
    }),
    groupByIndex: {
      get() {
        return this.$store.getters.dashboardV2GetProductTableGroupBy();
      },
      set(val) {
        this.$store.dispatch("dashboardV2SetProductTableGroupBy", val);
        this.$emit("refresh");
      }
    }
  },
  methods: {
    handleClicked() {
      this.$emit('clicked');
    }
  }
};