export default {
  name: "AppPopover",
  emits: ["popup-shown", "popup-hidden"],
  props: {
    target: {
      type: String,
      required: true
    },
    click: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: "left"
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    container: {
      type: String,
      default: "body"
    },
    popup: {
      type: Boolean,
      default: false
    },
    grayArrow: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customClass() {
      const result = [`data-${this.target}`];
      if (this.popup) {
        result.push("app-popup");
      } else {
        result.push("app-popover");
      }
      if (this.grayArrow) {
        result.push("gray-arrow");
      }
      if (this.noArrow) {
        result.push("no-arrow");
      }
      if (this.autoWidth) {
        result.push("auto-width");
      }
      if (this.autoHeight) {
        result.push("auto-height");
      }
      return result.join(" ");
    }
  },
  methods: {
    onShown() {
      this.$emit("popup-shown", this.target);
    },
    onHidden() {
      this.$emit("popup-hidden", this.target);
    }
  }
};