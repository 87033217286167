import moment from "moment";
import { APP_PERIOD_DROPDOWN_ELEMENTS } from "@/utils/constants";
export const WEEK_SWITCH = {
  monday: "isoWeek",
  sunday: "week"
};

/**
 * @param {number} startYear
 * @param {number} endYear
 *
 * @returns {Array<{month: number, monthName: string, year: number}>}
 *
 * This method is used to generate an array of months between two years.
 */
const generateMonthsBetweenYears = (startYear, endYear, startMonth = 0, endMonth = 11) => {
  const months = [];
  let date = moment({
    year: startYear,
    month: startMonth,
    day: 1
  }, true);
  const endDate = moment({
    year: endYear,
    month: endMonth,
    day: 31
  }, true);
  while (date.isSameOrBefore(endDate)) {
    months.push({
      month: date.month(),
      monthName: date.format("MMMM"),
      year: date.year(),
      date: date.clone()
    });
    date.add(1, "month");
  }
  return months;
};
const getNumberOfMonthsAbsolute = momentDate => {
  var months = Number(momentDate.format("MM"));
  var years = Number(momentDate.format("YYYY"));
  return months + years * 12;
};
const generateDaysForMonthYear = (month, year, weekType = "monday") => {
  // Moment.js is required for date manipulation
  // Make sure to include it before using this function

  const days = [];
  month = moment([year, month, 1]);

  // First day of the month
  let firstDay = month.clone().startOf("month").startOf(WEEK_SWITCH[weekType]);
  // Get the day of the week for the first day of the month

  let endDay = month.clone().endOf("month").endOf(WEEK_SWITCH[weekType]);

  // Generate the days for the month between the first and last day
  while (firstDay.isSameOrBefore(endDay)) {
    days.push(firstDay.format("YYYY-MM-DD"));
    firstDay.add(1, "days");
  }
  return days;
};
const getWeekFromDate = date => {
  // Return the dates in the week of the specified date
  // Moment.js is required for date manipulation

  const startOfWeek = moment(date).startOf("isoWeek");
  const week = [];
  for (var i = 0; i < 7; i++) {
    week.push(startOfWeek.clone().add(i, "days").format("YYYY-MM-DD"));
  }
  return week;
};
const getWeeksForMonthYear = (month, year) => {
  // Return the weeks in the month of the specified date
  // Moment.js is required for date manipulation

  const weeks = [];
  const firstDayOfMonth = moment([year, month, 1]);
  const lastDayOfMonth = firstDayOfMonth.clone().endOf("month");
  let currentWeek = getWeekFromDate(firstDayOfMonth);
  while (moment(currentWeek[0]).isBefore(lastDayOfMonth)) {
    weeks.push(currentWeek);
    currentWeek = getWeekFromDate(moment(currentWeek[6]).add(1, "days"));
  }
  return weeks;
};
const getWeekEnd = (date, type = "isoWeek") => {
  // Return the end of the week for the specified date
  // Moment.js is required for date manipulation

  return moment(date).endOf(type);
};
const generateYearsBetweenRange = (startYear, endYear) => {
  const years = [];

  // Use moment
  let date = moment({
    year: startYear
  });
  const endDate = moment({
    year: endYear
  });
  while (date.isSameOrBefore(endDate)) {
    years.push({
      year: date.year(),
      date: date.clone()
    });
    date.add(1, "year");
  }
  return years;
};
const generateQuartersFromYear = months => {
  if (months.length !== 12) {
    throw new Error("Invalid number of months provided." + `Expected 12, but got ${months.length}`);
  }
  const quarters = [];
  for (let i = 0; i < 4; i++) {
    const startMonthIndex = i * 3;
    const endMonthIndex = startMonthIndex + 2;
    quarters.push({
      quarter: i + 1,
      months: months.slice(startMonthIndex, endMonthIndex + 1)
    });
  }
  return quarters;
};
export const generateWeeksBetweenDates = (start, end) => {
  start = moment(start);
  end = moment(end);
  const diff = end.diff(start, "weeks");
  let out = [];
  for (let i = 0; i < diff; i++) {
    out.push(getWeekFromDate(start.clone().add(i, "weeks")));
  }
  out.push(getWeekFromDate(end));
  return out;
};
export const DATE_RANGES_FROM_SELECTOR = {
  weekly: (date, weekType) => {
    date = moment(date);

    // If current week, then chop off at today
    let range = {
      start: null,
      end: null
    };
    let compare = {
      start: null,
      end: null
    };
    range.start = date.clone();
    range.end = moment(range.start).endOf(WEEK_SWITCH[weekType]);
    if (moment().week() === date.week()) range.end = moment().subtract(1, "days");
    compare.start = moment(range.start).subtract(1, "weeks");
    compare.end = moment(range.end).subtract(1, "weeks");
    return {
      range,
      compare
    };
  },
  daily: date => {
    date = moment(date);
    let range = {
      start: null,
      end: null
    };
    let compare = {
      start: null,
      end: null
    };
    range.start = date.clone();
    range.end = date.clone();
    compare.start = date.clone().subtract(1, "days");
    compare.end = date.clone().subtract(1, "days");
    return {
      range,
      compare
    };
  },
  monthly: date => {
    date = moment(date);
    let range = {
      start: null,
      end: null
    };
    let compare = {
      start: null,
      end: null
    };
    range.start = date.clone();
    compare.start = range.start.clone().subtract(1, "months").startOf("month");
    range.end = moment(range.start).endOf("month");
    compare.end = moment(compare.start).endOf("month");
    if (moment().isSame(date, "month") && moment().isSame(date, "year")) {
      range.end = moment().subtract(1, "days");
      compare.end = moment(range.end).subtract(1, "months");
    }
    return {
      range,
      compare
    };
  },
  yearly: date => {
    date = moment(date);
    let range = {
      start: null,
      end: null
    };
    let compare = {
      start: null,
      end: null
    };
    range.start = date.startOf("year").clone();
    range.end = moment.min(moment().subtract(1, "days"), date.endOf("year").clone());
    compare.start = range.start.clone().subtract(1, "years");
    compare.end = range.end.clone().subtract(1, "years");
    return {
      range,
      compare
    };
  }
};
export const DATE_PICKER_RANGE_TITLES = {
  YESTERDAY: "Yesterday",
  LAST_7_DAYS: "Last 7 days",
  LAST_28_DAYS: "Last 28 days",
  LAST_WEEK: "Last week",
  LAST_MONTH: "Last month",
  LAST_90_DAYS: "Last 90 days",
  LAST_365_DAYS: "Last 365 days",
  LAST_12_WEEKS: "Last 12 weeks",
  LAST_12_MONTHS: "Last 12 months",
  LAST_YEAR: "Last year",
  CURRENT_WEEK: "Current week",
  CURRENT_MONTH: "Current month",
  CURRENT_YEAR: "Current year",
  CUSTOM: "Custom"
};
export const DATE_PICKER_COMPARE_TITLES = {
  PRECEDING_PERIOD: "Preceding Period",
  SAME_PERIOD_LY: "Same Period Last Year",
  CUSTOM: "Custom"
};
export const INTERVAL_IN_DAYS = (date, interval) => moment(date).subtract(interval, "days");
export const INTERVAL_IN_MONTHS = (date, interval) => moment(date).subtract(interval, "months");
export const INTERVAL_IN_WEEKS = (date, interval) => moment(date).subtract(interval, "weeks");
export const INTERVAL_IN_YEARS = (date, interval) => moment(date).subtract(interval, "years");
export const CUSTOM_COMPARE_FUNCTION = {
  [DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD]: ({
    start,
    end
  }) => {
    // Know the difference between the start and the end
    const difference = end.diff(start, "weeks");
    return {
      range: {
        start,
        end
      },
      compare: {
        start: INTERVAL_IN_DAYS(moment(start), difference * 7 + 7),
        end: INTERVAL_IN_DAYS(moment(end), difference * 7 + 7)
      }
    };
  },
  [DATE_PICKER_COMPARE_TITLES.SAME_PERIOD_LY]: ({
    start,
    end
  }) => {
    return {
      range: {
        start,
        end
      },
      compare: {
        start: INTERVAL_IN_YEARS(start, 1),
        end: INTERVAL_IN_YEARS(end, 1)
      }
    };
  }
};
export const getDatePickerRangeCompare = (compare, range, weekType) => {
  if (!DATE_PICKER_RANGE_COMPARE_SELECTOR[compare] || !DATE_PICKER_RANGE_COMPARE_SELECTOR[compare][range]) {
    throw new Error('Invalid compare title or range title');
  }
  return DATE_PICKER_RANGE_COMPARE_SELECTOR[compare][range](weekType);
};
export const DATE_PICKER_RANGE_COMPARE_SELECTOR = {
  [DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD]: {
    [DATE_PICKER_RANGE_TITLES.YESTERDAY]: () => ({
      range: {
        start: moment().subtract(1, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_WEEKS(moment().subtract(1, "days"), 1),
        end: INTERVAL_IN_WEEKS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_7_DAYS]: () => ({
      range: {
        start: moment().subtract(7, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_DAYS(moment().subtract(7, "days"), 7),
        end: INTERVAL_IN_DAYS(moment().subtract(1, "days"), 7)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_28_DAYS]: () => ({
      range: {
        start: moment().subtract(28, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_DAYS(moment().subtract(28, "days"), 28),
        end: INTERVAL_IN_DAYS(moment().subtract(1, "days"), 28)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_WEEK]: weekType => ({
      range: {
        start: momentWeekType[weekType]().subtract(7, "days"),
        end: momentWeekType[weekType]().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_WEEKS(momentWeekType[weekType]().subtract(7, "days"), 1),
        end: INTERVAL_IN_WEEKS(momentWeekType[weekType]().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_MONTH]: () => ({
      range: {
        start: moment().subtract(1, "months").startOf("month"),
        end: moment().subtract(1, "months").endOf("month")
      },
      compare: {
        start: moment().subtract(2, "months").startOf("month"),
        end: moment().subtract(2, "months").endOf("month")
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_90_DAYS]: () => ({
      range: {
        start: moment().subtract(90, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_DAYS(moment().subtract(90, "days"), 90),
        end: INTERVAL_IN_DAYS(moment().subtract(1, "days"), 90)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_365_DAYS]: () => ({
      range: {
        start: moment().subtract(365, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_DAYS(moment().subtract(365, "days"), 365),
        end: INTERVAL_IN_DAYS(moment().subtract(1, "days"), 365)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_12_MONTHS]: () => ({
      range: {
        start: moment().subtract(12, "months").date(1),
        end: moment().subtract(1, "months").endOf("month")
      },
      compare: {
        start: INTERVAL_IN_MONTHS(moment().subtract(12, "months").date(1), 12),
        end: INTERVAL_IN_MONTHS(moment().subtract(1, "months").endOf("month"), 12)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_12_WEEKS]: weekType => ({
      range: {
        start: INTERVAL_IN_WEEKS(momentWeekType[weekType]().subtract(7, "days"), 11),
        end: momentWeekType[weekType]().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_WEEKS(momentWeekType[weekType]().subtract(7, "days"), 23),
        end: INTERVAL_IN_WEEKS(momentWeekType[weekType]().subtract(1, "days"), 12)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.CURRENT_WEEK]: weekType => ({
      range: {
        start: momentWeekType[weekType](),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_WEEKS(momentWeekType[weekType](), 1),
        end: INTERVAL_IN_WEEKS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.CURRENT_MONTH]: () => ({
      range: {
        start: moment().startOf("month"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_MONTHS(moment().date(1), 1),
        end: INTERVAL_IN_MONTHS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.CURRENT_YEAR]: () => ({
      range: {
        start: moment().startOf("year"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: moment().startOf("year").subtract(1, "years"),
        end: moment().subtract(1, "days").subtract(1, "years")
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_YEAR]: () => ({
      range: {
        start: moment().startOf("year").subtract(1, "years"),
        end: moment().endOf("year").subtract(1, "years")
      },
      compare: {
        start: moment().startOf("year").subtract(2, "years"),
        end: moment().endOf("year").subtract(2, "years")
      }
    }),
    [DATE_PICKER_RANGE_TITLES.CUSTOM]: () => ({
      range: {
        start: null,
        end: null
      },
      compare: {
        start: null,
        end: null
      }
    })
  },
  [DATE_PICKER_COMPARE_TITLES.SAME_PERIOD_LY]: {
    [DATE_PICKER_RANGE_TITLES.YESTERDAY]: () => ({
      range: {
        start: moment().subtract(1, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().subtract(1, "days"), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_7_DAYS]: () => ({
      range: {
        start: moment().subtract(7, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().subtract(7, "days"), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_28_DAYS]: () => ({
      range: {
        start: moment().subtract(28, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().subtract(28, "days"), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_WEEK]: () => ({
      range: {
        start: moment().startOf("isoWeek").subtract(7, "days"),
        end: moment().startOf("isoWeek").subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().startOf("isoWeek").subtract(7, "days"), 1),
        end: INTERVAL_IN_YEARS(moment().startOf("isoWeek").subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_MONTH]: () => ({
      range: {
        start: moment().subtract(1, "months").startOf("month"),
        end: moment().subtract(1, "months").endOf("month")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().subtract(1, "months").startOf("month"), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "months").endOf("month"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_90_DAYS]: () => ({
      range: {
        start: moment().subtract(90, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().subtract(90, "days"), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_365_DAYS]: () => ({
      range: {
        start: moment().subtract(365, "days"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().subtract(365, "days"), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_12_MONTHS]: () => ({
      range: {
        start: moment().subtract(12, "months").date(1),
        end: moment().subtract(1, "months").endOf("month")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().subtract(12, "months").date(1), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "months").endOf("month"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.LAST_12_WEEKS]: () => ({
      range: {
        start: moment().subtract(12, "weeks").startOf("isoWeek"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().subtract(12, "weeks").startOf("isoWeek"), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.CURRENT_WEEK]: () => ({
      range: {
        start: moment().startOf("isoWeek"),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().startOf("isoWeek"), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.CURRENT_MONTH]: () => ({
      range: {
        start: moment().date(1),
        end: moment().subtract(1, "days")
      },
      compare: {
        start: INTERVAL_IN_YEARS(moment().date(1), 1),
        end: INTERVAL_IN_YEARS(moment().subtract(1, "days"), 1)
      }
    }),
    [DATE_PICKER_RANGE_TITLES.CUSTOM]: () => ({
      range: {
        start: null,
        end: null
      },
      compare: {
        start: null,
        end: null
      }
    })
  },
  [DATE_PICKER_COMPARE_TITLES.CUSTOM]: {
    [DATE_PICKER_RANGE_TITLES.CUSTOM]: () => ({
      range: {
        start: null,
        end: null
      },
      compare: {
        start: null,
        end: null
      }
    })
  }
};
export const getDatePickerUIObjectFromDateRange = ({
  range,
  compare,
  rangeTitle,
  compareTitle
}) => {
  return {
    rangeTitle,
    compareTitle,
    range: {
      start: moment(range.start).format("YYYY-MM-DD"),
      end: moment(range.end).format("YYYY-MM-DD")
    },
    compare: {
      start: moment(compare.start).format("YYYY-MM-DD"),
      end: moment(compare.end).format("YYYY-MM-DD")
    },
    selector: {
      title: rangeTitle,
      start: {
        date: range.start,
        format: "MMM DD"
      },
      end: {
        date: range.end,
        format: "MMM DD YYYY"
      }
    }
  };
};
export const DATE_PICKER_MODES = {
  READ_ONLY_MODE: "READ_ONLY_MODE",
  CUSTOM_MODE: "CUSTOM_MODE"
};
export const momentWeekType = {
  monday: (date = moment()) => date.startOf("isoWeek"),
  sunday: (date = moment()) => date.startOf("week")
};
export const getDatePickerDayId = (date, prefix) => {
  return `${prefix ? prefix : ''}-${date}`;
};
export const disableStrategyWeeks = (date, weeksFilled, currentWeek) => {
  //console.log("weeksFilled", weeksFilled);
  // TODO: Optimize the check to use `start_date` and `end_date` for filled check

  date = moment(date);
  let result = date.clone().isBefore(currentWeek, 'isoWeek') || date.clone().isBefore(currentWeek, 'month') || weeksFilled.some(dateRange => {
    const week = moment(dateRange.week);
    return date.isBetween(week, week.clone().add(6, "days"), null, "[]");
  });
  return result;
};
export const weekGetterFromMap = (weeksMap, disabler = () => {}) => {
  let weeks = weeksMap.get(`${month}-${year}`);
  weeks = weeks.map(week => {
    // Date is Array<String>
    // Check if the first day in the list is part of disabled
    const firstDay = week[0];
    const disabled = disabler(firstDay);
    return {
      week,
      disabled
    };
  });
  return weeks;
};
export const getWeeksMapFromYearRange = (year_start = moment().year(), year_end = moment().year() + 1, today = moment()) => {
  const months = generateMonthsBetweenYears(year_start, year_end, today.month()); // [{month, year}];

  let weekDatesMap = new Map();
  for (const {
    month,
    year
  } of months) {
    const key = `${month}-${year}`;
    let weeks = getWeeksForMonthYear(month, year);

    // Check if the `firstDay` of the first week is in the same month
    const firstDay = weeks[0][0];
    if (!moment(firstDay).isSame(moment([year, month]), 'month')) {
      // Remove the first week since it was already added to the previous month;
      weeks = weeks.slice(1);
    }
    weekDatesMap.set(key, weeks);
  }
  return weekDatesMap;
};
export const getPeriodGroupFromCurrentPeriod = (currentPeriod, period, weekType = "sunday") => {
  const GOLDEN_CASE_$_FIRST_DAY_OF_THE_YEAR_AND_OF_THE_MONTH_AND_OF_THE_WEEK = {
    [APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value]: DATE_PICKER_RANGE_TITLES.LAST_MONTH,
    [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY.value]: DATE_PICKER_RANGE_TITLES.YESTERDAY,
    [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value]: DATE_PICKER_RANGE_TITLES.LAST_WEEK,
    [APP_PERIOD_DROPDOWN_ELEMENTS.YEARLY.value]: DATE_PICKER_RANGE_TITLES.LAST_YEAR
  };
  const FIRST_DAY_OF_THE_WEEK_AND_MONTH_SELECTORS = {
    [APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value]: DATE_PICKER_RANGE_TITLES.LAST_MONTH,
    [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY.value]: DATE_PICKER_RANGE_TITLES.YESTERDAY,
    [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value]: DATE_PICKER_RANGE_TITLES.LAST_WEEK,
    [APP_PERIOD_DROPDOWN_ELEMENTS.YEARLY.value]: DATE_PICKER_RANGE_TITLES.CURRENT_YEAR
  };
  const FIRST_DAY_OF_THE_WEEK_AND_NOT_MONTH_SELECTORS = {
    [APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value]: DATE_PICKER_RANGE_TITLES.CURRENT_MONTH,
    [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY.value]: DATE_PICKER_RANGE_TITLES.YESTERDAY,
    [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value]: DATE_PICKER_RANGE_TITLES.LAST_WEEK,
    [APP_PERIOD_DROPDOWN_ELEMENTS.YEARLY.value]: DATE_PICKER_RANGE_TITLES.CURRENT_YEAR
  };
  const FIRST_DAY_OF_THE_MONTH_AND_NOT_WEEK_SELECTORS = {
    [APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value]: DATE_PICKER_RANGE_TITLES.LAST_MONTH,
    [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY.value]: DATE_PICKER_RANGE_TITLES.YESTERDAY,
    [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value]: DATE_PICKER_RANGE_TITLES.CURRENT_WEEK,
    [APP_PERIOD_DROPDOWN_ELEMENTS.YEARLY.value]: DATE_PICKER_RANGE_TITLES.LAST_YEAR
  };
  const currentDate = moment();
  console.log("Current Date", currentDate);
  const isoWeekDay = weekType === "monday" ? 1 : 7;
  const isFirstDayOfWeek = currentDate.isoWeekday() === isoWeekDay;
  const isFirstDayOfMonth = currentDate.date() === 1;
  const isFirstDayOfYear = currentDate.dayOfYear() === 1;
  let selector = currentPeriod[period].groupBy;
  if (isFirstDayOfYear && isFirstDayOfMonth && isFirstDayOfWeek) {
    selector = GOLDEN_CASE_$_FIRST_DAY_OF_THE_YEAR_AND_OF_THE_MONTH_AND_OF_THE_WEEK[period];
  } else if (isFirstDayOfWeek && isFirstDayOfMonth) {
    selector = FIRST_DAY_OF_THE_WEEK_AND_MONTH_SELECTORS[period];
  } else if (isFirstDayOfWeek) {
    selector = FIRST_DAY_OF_THE_WEEK_AND_NOT_MONTH_SELECTORS[period];
  } else if (isFirstDayOfMonth) {
    selector = FIRST_DAY_OF_THE_MONTH_AND_NOT_WEEK_SELECTORS[period];
  }
  return selector;
};
export { generateMonthsBetweenYears, generateDaysForMonthYear, getWeekFromDate, getWeeksForMonthYear, getWeekEnd, generateYearsBetweenRange, generateQuartersFromYear, getNumberOfMonthsAbsolute };