import HugValue from "./HugValue.vue";
export default {
  name: "BoxedValue",
  components: {
    HugValue
  },
  props: {
    bgColor: {
      type: String,
      required: false,
      default: "transparent"
    },
    align: {
      type: String,
      required: false,
      default: "right"
    },
    padding: {
      type: String,
      required: false,
      default: "4px 8px"
    },
    id: {
      type: String,
      required: false,
      default: "boxed-value"
    },
    loading: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object
    }
  },
  computed: {
    computedStyle() {
      const justify = {
        center: "center",
        left: "flex-start",
        right: "flex-end"
      }[this.align];
      return {
        height: "100%",
        width: "100%",
        padding: this.padding,
        backgroundColor: this.loading ? 'transparent' : this.bgColor,
        justifyContent: justify,
        ...this.styles
      };
    }
  }
};