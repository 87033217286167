import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SearchElement = _resolveComponent("SearchElement");
  const _component_MatchTypeFilterItem = _resolveComponent("MatchTypeFilterItem");
  const _component_SelectedSection = _resolveComponent("SelectedSection");
  const _component_ListSection = _resolveComponent("ListSection");
  const _component_WrapWithDropdownBody = _resolveComponent("WrapWithDropdownBody");
  return _openBlock(), _createBlock(_component_WrapWithDropdownBody, {
    onHidden: _cache[7] || (_cache[7] = $event => _ctx.$emit('hidden'))
  }, {
    searcher: _withCtx(() => [_createVNode(_component_SearchElement, {
      placeholder: "Search match types...",
      onSearch: _cache[0] || (_cache[0] = val => _ctx.$emit('search', val)),
      value: $data.searchValue
    }, null, 8 /* PROPS */, ["value"])]),
    selected: _withCtx(() => [_createVNode(_component_SelectedSection, {
      title: "Match Type",
      count: $props.selected.length,
      isModified: $props.isModified,
      onClear: _cache[2] || (_cache[2] = $event => {
        _ctx.$emit('clear');
      }),
      onApply: _cache[3] || (_cache[3] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _cache[4] || (_cache[4] = $event => _ctx.$emit('closeDropdown'))
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selected, ({
        name,
        id
      }) => {
        return _openBlock(), _createBlock(_component_MatchTypeFilterItem, {
          key: id,
          name: name,
          id: id,
          type: name,
          selected: "",
          onDeselect: _cache[1] || (_cache[1] = val => _ctx.$emit('deselect', val))
        }, null, 8 /* PROPS */, ["name", "id", "type"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count", "isModified"])]),
    list: _withCtx(() => [_createVNode(_component_ListSection, {
      title: "Match Type",
      count: $props.list.length
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, ({
        name,
        id
      }) => {
        return _openBlock(), _createBlock(_component_MatchTypeFilterItem, {
          key: id,
          name: name,
          id: id,
          type: name,
          onSelect: _cache[5] || (_cache[5] = val => _ctx.$emit('select', val)),
          onSelectOnly: _cache[6] || (_cache[6] = val => _ctx.$emit('selectOnly', val))
        }, null, 8 /* PROPS */, ["name", "id", "type"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count"])]),
    _: 1 /* STABLE */
  });
}