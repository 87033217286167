import { mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import AppForm from "@/components/AppForm.vue";
import AppInput from "@/components/AppInput.vue";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon";
import TheWrapperPublic from "@/components/TheWrapperPublic.vue";
export default {
  name: "RegoveryPage",
  mixins: [ValidateMixin],
  components: {
    AppForm,
    AppInput,
    AppButton,
    AppIcon,
    TheWrapperPublic
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      form: {
        email: ""
      },
      loading: false,
      showSuccessMessage: false
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token
    })
  },
  async mounted() {
    var is_authenticated = await this.authStatus(false);
    this.showSuccessMessage = false;
    if (is_authenticated) {
      this.$router.replace("/");
    }
  },
  methods: {
    ...mapActions(["authRecovery", "authStatus"]),
    async onSubmit() {
      const valid = await this.mValidate();
      if (valid) {
        this.loading = true;
        const result = await this.authRecovery(this.form);
        if (result) {
          this.showSuccessMessage = true;
        } else {
          window.$bus.trigger("showDanger", {
            visible: true,
            message: "User not found."
          });
        }
        this.loading = false;
      }
    },
    loginPage() {
      this.$router.replace("/login");
    }
  }
};