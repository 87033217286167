import AppIcon from "@/components/AppIcon";
export default {
  name: "AiGenerationTabs",
  components: {
    AppIcon
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    tabs: {
      type: Array,
      default: () => []
    },
    enabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onTabChange(tab) {
      if (tab.canSwitchTab()) {
        this.$emit("update:modelValue", tab.value);
      }
    }
  }
};