import { CPC_DECILE_COLORS, CVR_HEATMAP_COLORS, DAYS_INVENTORY_HEATMAP_COLORS, BUYBOX_HEATMAP_COLORS, BEST_POSITION_HEATMAP_COLORS, KEYWORD_BID_HEATMAP_COLORS } from "@/utils/constants";
export const XXLARGE_COLUMN_WIDTH = "308px";
export const XLARGE_COLUMN_WIDTH = "250px";
export const LARGE_COLUMN_WIDTH = "200px";
export const MEDIUM_COLUMN_WIDTH = "115px";
export const SMALL_COLUMN_WIDTH = "82px";
export const TINY_COLUMN_WIDTH = "62px";
export const VERY_TINY_COLUMN_WIDTH = "32px";
export const SUPER_TINY_COLUMN_WIDTH = "16px";
export const SPEND_FIELD = {
  key: "spend",
  label: "Spend",
  threshold: {
    bad: 0.3,
    good: 0.7
  },
  thStyle: {
    minWidth: LARGE_COLUMN_WIDTH
  }
};
export const BIG_SPEND_FIELD = {
  key: "big_spend",
  label: "Spend",
  class: "right",
  justify: "flex-end",
  threshold: {
    bad: 0.3,
    good: 0.7
  },
  thStyle: {
    width: XLARGE_COLUMN_WIDTH
  }
};
export const TACOS_FIELD = {
  key: "TACOS",
  label: "TACOS",
  class: "right",
  justify: "flex-end",
  threshold: {
    bad: 0.5,
    good: 1.0
  },
  thStyle: {
    minWidth: SMALL_COLUMN_WIDTH
  }
};
export const RATING_FIELD = {
  key: "rating",
  label: "Rating",
  class: "right",
  justify: "flex-end",
  threshold: {
    bad: 0.5,
    good: 1.0
  },
  thStyle: {
    width: SMALL_COLUMN_WIDTH
  }
};
export const RATING_TOTAL_FIELD = {
  key: "ratings_total",
  label: "Reviews",
  class: "right",
  justify: "flex-end",
  threshold: {
    bad: 0.5,
    good: 1.0
  },
  thStyle: {
    width: SMALL_COLUMN_WIDTH
  }
};
export const CVR_FIELD = {
  key: "CVR",
  label: "CVR",
  class: "right",
  justify: "flex-end",
  heatmap_ranges: CVR_HEATMAP_COLORS,
  thStyle: {
    minWidth: VERY_TINY_COLUMN_WIDTH
  }
};
export const DAYS_INVENTORY_FIELD = {
  key: "days_inventory",
  label: "Days of inventory",
  class: "left",
  justify: "flex-start",
  heatmap_ranges: DAYS_INVENTORY_HEATMAP_COLORS,
  thStyle: {
    minWidth: MEDIUM_COLUMN_WIDTH
  }
};
export const ORGANIC_CVR_FIELD = {
  key: "organic_CVR",
  label: "CVR",
  class: "right",
  justify: "flex-end",
  heatmap_ranges: CVR_HEATMAP_COLORS,
  thStyle: {
    minWidth: VERY_TINY_COLUMN_WIDTH
  }
};
export const CPC_FIELD = {
  key: "CPC",
  label: "CPC",
  class: "right",
  justify: "flex-end",
  thStyle: {
    minWidth: VERY_TINY_COLUMN_WIDTH
  },
  heatmap_ranges: CPC_DECILE_COLORS
};
export const SPONSORED_ASIN_FIELD = {
  key: "sponsored_asin",
  label: "Spons. ASIN",
  thStyle: {
    width: SMALL_COLUMN_WIDTH
  }
};
export const PAGE_VIEWS_FIELD = {
  key: "page_views",
  label: "Page views",
  thStyle: {
    width: XLARGE_COLUMN_WIDTH
  }
};
export const RETURN_RATE_FIELD = {
  key: "return_rate",
  label: "Return rate",
  thStyle: {
    minWidth: MEDIUM_COLUMN_WIDTH
  }
};
export const PPC_SALES_FIELD = {
  key: "share_sponsored_sales",
  label: "% of PPC sales",
  thStyle: {
    width: MEDIUM_COLUMN_WIDTH
  }
};
export const UNITS_SOLD_FIELD = {
  key: "units_sold_clicks_14_d",
  label: "Units Sold",
  class: "right",
  justify: "flex-end",
  threshold: {
    bad: 0.3,
    good: 0.5
  },
  thStyle: {
    width: SMALL_COLUMN_WIDTH
  }
};
export const CLICKS_FIELD = {
  key: "clicks",
  label: "Clicks",
  class: "right",
  justify: "flex-end",
  threshold: {
    bad: 0.3,
    good: 0.5
  },
  thStyle: {
    minWidth: TINY_COLUMN_WIDTH
  }
};
export const SPONSORED_SALES_FIELD = {
  key: "sponsored_sales",
  label: "Sponsored Sales",
  threshold: {
    bad: 0.3,
    good: 0.5
  },
  thStyle: {
    minWidth: LARGE_COLUMN_WIDTH
  }
};
export const BIG_SPONSORED_SALES_FIELD = {
  key: "big_sponsored_sales",
  label: "Sponsored Sales",
  class: "right",
  justify: "flex-end",
  threshold: {
    bad: 0.3,
    good: 0.5
  },
  thStyle: {
    minWidth: XLARGE_COLUMN_WIDTH
  }
};
export const REVENUE_EXCL_VAT_FIELD = {
  key: "revenue_excl_VAT",
  label: "Revenue excl. VAT",
  threshold: {
    bad: 0.3,
    good: 0.5
  },
  thStyle: {
    minWidth: XLARGE_COLUMN_WIDTH
  }
};
export const BEST_POSITION_FIELD = {
  key: "best_position",
  label: "Best pos<sup>n</sup>",
  class: "center",
  justify: "center",
  thStyle: {
    minWidth: TINY_COLUMN_WIDTH
  },
  heatmap_ranges: BEST_POSITION_HEATMAP_COLORS
};
export const BUYBOX_FIELD = {
  key: "traffic_by_asin_buy_box_percentage",
  label: "% of buybox",
  class: "center",
  justify: "center",
  thStyle: {
    minWidth: TINY_COLUMN_WIDTH
  },
  heatmap_ranges: BUYBOX_HEATMAP_COLORS
};
export const CAMPAIGN_BUDGET_FIELD = {
  key: "campaign_budget",
  label: "Daily Budget",
  justify: "flex-end",
  thStyle: {
    minWidth: TINY_COLUMN_WIDTH
  }
};
export const ROAS_FIELD = {
  key: "ROAS",
  label: "ROAS",
  class: 'center',
  justify: "center",
  threshold: {
    bad: 0.5,
    good: 1.0
  },
  thStyle: {
    minWidth: TINY_COLUMN_WIDTH
  }
};
export const TOTAL_ROAS_FIELD = {
  key: "total_ROAS",
  label: "Total ROAS",
  class: ["roas-highlighted", "total-roas"],
  threshold: {
    bad: 0.5,
    good: 1.0
  },
  thStyle: {
    width: SMALL_COLUMN_WIDTH,
    ['--total-roas-width']: TINY_COLUMN_WIDTH
  }
};
export const BAR_ROAS_FIELD = {
  key: "ROAS",
  label: "ROAS",
  class: 'left',
  justify: "flex-start",
  threshold: {
    bad: 0.5,
    good: 1.0
  },
  thStyle: {
    minWidth: TINY_COLUMN_WIDTH
  }
};
export const SELECTOR = {
  key: "selector",
  label: "",
  class: "center",
  thStyle: {
    width: SUPER_TINY_COLUMN_WIDTH
  },
  stickyColumn: true
};
export const PERIOD_FIELD = {
  key: "period_agg",
  label: "Week",
  stickyColumn: true,
  thStyle: {
    width: MEDIUM_COLUMN_WIDTH
  }
};
export const PPC_V2_PERIOD_FIELD = {
  key: "period_agg",
  label: "Week",
  stickyColumn: true,
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const SKU_SOLD_FIELD = {
  key: "share_sku_sold",
  label: "% of SKUs sold",
  class: "left",
  justify: "flex-start",
  thStyle: {
    minWidth: MEDIUM_COLUMN_WIDTH
  },
  heatmap_ranges: CPC_DECILE_COLORS
};
export const ASIN_SOLD_FIELD = {
  key: "share_asin_sold",
  label: "% of ASIN sold",
  class: "left",
  justify: "flex-start",
  thStyle: {
    minWidth: MEDIUM_COLUMN_WIDTH
  },
  heatmap_ranges: CPC_DECILE_COLORS
};
export const REVENUE_SHARE_FIELD = {
  key: "share_total_revenue",
  label: "Rev. share",
  class: "right",
  justify: "flex-end",
  thStyle: {
    minWidth: SMALL_COLUMN_WIDTH
  }
};
export const TOTAL_QUANTITY_FIELD = {
  key: "total_quantity",
  label: "Units Sold",
  class: "right",
  justify: "flex-end",
  thStyle: {
    width: MEDIUM_COLUMN_WIDTH
  }
};
export const LISTED_ASIN_FIELD = {
  key: "listed_asin_count",
  label: "# active vs listed ASIN",
  thStyle: {
    minWidth: LARGE_COLUMN_WIDTH
  }
};
export const CANNIBALIZATION_FIELD = {
  key: "cannibalization_pc",
  label: "Cannibal<sup>n</sup>",
  class: "center",
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const TIME_IN_BUDGET_FIELD = {
  key: "percent_time_in_budget",
  label: "Time in budget",
  class: "right",
  justify: "flex-end",
  thStyle: {
    minWidth: MEDIUM_COLUMN_WIDTH
  }
};
export const BIG_ROAS_FIELD = {
  key: "targeting_type_ROAS",
  label: "ROAS",
  justify: "center",
  threshold: {
    bad: 0.5,
    good: 1.0
  },
  class: ["center", "roas-highlighted", "big-roas"],
  thStyle: {
    width: XLARGE_COLUMN_WIDTH
  }
};
export const BIG_CPC_FIELD = {
  key: "targeting_type_CPC",
  label: "CPC",
  justify: "center",
  threshold: {
    bad: 0.5,
    good: 1.0
  },
  class: ["center", "cpc-highlighted"],
  thStyle: {
    width: XLARGE_COLUMN_WIDTH
  }
};
export const TARGETING_TYPE_FIELD = {
  key: "targeting_type",
  label: "",
  class: "center",
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const MARKETPLACE_FIELD = {
  key: "sales_channel",
  label: "Marketplace",
  justify: "flex-start",
  thStyle: {
    minWidth: LARGE_COLUMN_WIDTH
  },
  stickyColumn: true
};
export const SELLER_FIELD = {
  key: "seller_name",
  label: "Seller Name",
  justify: "flex-start",
  thStyle: {
    minWidth: XLARGE_COLUMN_WIDTH
  },
  stickyColumn: true
};
export const PRODUCT_FIELD = {
  key: "product_name",
  label: "Product",
  justify: "flex-start",
  thStyle: {
    minWidth: XXLARGE_COLUMN_WIDTH
  },
  stickyColumn: true
};
export const CAMPAIGN_GROUP_FIELD = {
  key: "campaign_group_name",
  label: "Campaign group",
  justify: "flex-start",
  thStyle: {
    minWidth: XXLARGE_COLUMN_WIDTH
  },
  stickyColumn: true
};
export const CAMPAIGN_NAME_FIELD = {
  key: "campaign_name",
  label: "Campaign",
  justify: "flex-start",
  thStyle: {
    minWidth: XXLARGE_COLUMN_WIDTH
  },
  stickyColumn: true
};
export const TARGETING_FIELD = {
  key: "targeting",
  label: "Targeting",
  justify: "flex-start",
  thStyle: {
    minWidth: XXLARGE_COLUMN_WIDTH
  },
  stickyColumn: true
};
export const KEYWORD_BID_FIELD = {
  key: "keyword_bid",
  label: "Last Bid",
  justify: "flex-end",
  heatmap_ranges: KEYWORD_BID_HEATMAP_COLORS,
  thStyle: {
    minWidth: SMALL_COLUMN_WIDTH
  }
};
export const LOST_REVENUE_EXCL_VAT_FIELD = {
  key: "lost_revenue_excl_VAT",
  label: "Lost revenue",
  class: 'right',
  justify: 'flex-end',
  thStyle: {
    minWidth: SMALL_COLUMN_WIDTH
  }
};
export const PROFIT_REVENUE_FIELD = {
  key: "pl_net_revenue",
  label: "Revenue",
  thStyle: {
    width: XLARGE_COLUMN_WIDTH
  }
};
export const PROFIT_CONTRIBUTIVE_MARGIN_FIELD = {
  key: "pl_contributive_margin",
  label: "Net Margin",
  thStyle: {
    width: XLARGE_COLUMN_WIDTH
  }
};
export const PROFIT_PERCENTAGE_OF_REVENUE_FIELD = {
  key: "pl_net_percentage_revenue",
  label: "% of rev.",
  thStyle: {
    width: MEDIUM_COLUMN_WIDTH
  }
};
export const PROFIT_COMMISSION_FIELD = {
  key: "pl_amazon_commission_pc",
  label: "Commis...",
  class: 'right',
  justify: 'flex-end',
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const PROFIT_FBA_DIRECT_FIELD = {
  key: "pl_fba_direct_pc",
  label: "FBA direct",
  class: 'right',
  justify: 'flex-end',
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const PROFIT_FBA_INDIRECT_FIELD = {
  key: "pl_fba_indirect_pc",
  label: "FBA indir.",
  class: 'right',
  justify: 'flex-end',
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const PROFIT_REFUND_FIELD = {
  key: "pl_refund_pc",
  label: "Ref. & G...",
  class: 'right',
  justify: 'flex-end',
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const PROFIT_COOP_FIELD = {
  key: "pl_coop_pc",
  label: "Co-op",
  class: 'right',
  justify: 'flex-end',
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const PROFIT_COGS_FIELD = {
  key: "pl_cogs_pc",
  label: "COGS",
  class: 'right',
  justify: 'flex-end',
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const PROFIT_OTHER_COSTS_FIELD = {
  key: "pl_other_costs_pc",
  label: "Other",
  class: 'right',
  justify: 'flex-end',
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};
export const PROFIT_ADVERTISING_COSTS_FIELD = {
  key: "pl_advertising_costs",
  label: "Advertising costs",
  thStyle: {
    width: LARGE_COLUMN_WIDTH
  }
};
export const AVERAGE_UNIT_PRICE_FIELD = {
  key: "avg_unit_price",
  label: "Avg unit price",
  thStyle: {
    width: MEDIUM_COLUMN_WIDTH
  },
  class: 'right',
  justify: 'flex-end'
};
export const AD_FORMAT_FIELD = {
  key: "ad_product",
  label: "Ad Format",
  thStyle: {
    width: LARGE_COLUMN_WIDTH
  },
  stickyColumn: true
};
export const SHARE_OF_VOICE_FIELD = {
  key: "share_of_voice",
  label: "Br. SoV",
  thStyle: {
    width: TINY_COLUMN_WIDTH
  }
};