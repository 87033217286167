import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-07c7ef06"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "campaigns-count"
};
const _hoisted_2 = {
  class: "count"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_flex_row, {
    gap: 4
  }, {
    default: _withCtx(() => [_createVNode(_component_app_icon, {
      icon: "campaigns-count",
      color: "#595959",
      size: 12
    }), _createElementVNode("div", _hoisted_2, _toDisplayString($props.count), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  })]);
}