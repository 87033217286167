import { ValueElement } from ".";
import FlexRow from "../FlexRow.vue";
import FlagValue from "./FlagValue.vue";
export default {
  name: "MarketplaceValue",
  components: {
    ValueElement,
    FlexRow,
    FlagValue
  },
  props: {
    marketplace: {
      type: String,
      required: true,
      default: ""
    },
    flag: {
      type: String,
      required: true
    },
    gap: {
      type: Number,
      default: 4
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formattedValue() {
      return this.marketplace?.toLowerCase();
    }
  }
};