import AppIcon from '@/components/AppIcon.vue';
import FlexRow from '../components/FlexRow.vue';
export default {
  name: 'OmnyModalHeader',
  components: {
    AppIcon,
    FlexRow
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    icon: {
      type: [String, null]
    },
    color: {
      type: String,
      default: '#4D4D4D'
    }
  },
  emits: ['update:modelValue'],
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
};