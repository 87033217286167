import { mapActions } from "vuex";
import AppVersion from "@/components/AppVersion";
import AppSwitch from "@/components/AppSwitch";
import AppIcon from "@/components/AppIcon";
import AppDropdown from "@/components/AppDropdown";
import AppDropdownItem from "@/components/AppDropdownItem.vue";
import FlexRow from "@/components/FlexRow.vue";
import { isObjectEmpty } from "@/utils";
export default {
  name: "AiGenerationProductData",
  emits: ["changeTab", "selectedProduct", "userInputAIGeneration"],
  components: {
    AppVersion,
    AppSwitch,
    AppIcon,
    AppDropdown,
    AppDropdownItem,
    FlexRow
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    all_versions_list: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      use_existing: false,
      selected_product: {},
      selectedVersion: null,
      user_input: "",
      custom_product: {
        product_name: "",
        brand: this.product.brand,
        title: "Custom version"
      },
      input_data_options: [{
        value: "Local version",
        text: "Local Version",
        color: "#C39A0E",
        icon: "draft"
      }, {
        value: "Amazon version",
        text: "Amazon Version",
        color: "#E94E1B",
        icon: "amazon"
      }, {
        value: "additional",
        text: "Use only additional",
        color: "#4D4D4D",
        icon: ""
      }],
      input_data_type: {},
      user_input_import: false,
      user_input_import_done: false,
      user_input_import_loading: false,
      import_link: ""
    };
  },
  computed: {
    versionList() {
      //console.log('[ai_generated version list]');
      let transformedVersionList = this.transformVersions(this.all_versions_list);
      return transformedVersionList;
    },
    checkProductnameReset() {
      return this.custom_product.product_name && this.custom_product.product_name != this.all_versions_list[0].product_name;
    },
    userInput: {
      get() {
        return this.user_input;
      },
      set(val) {
        this.user_input = val;
        this.$emit("userInputAIGeneration", val);
      }
    },
    checkInputData() {
      return isObjectEmpty(this.input_data_type);
    }
  },
  methods: {
    ...mapActions(["contentScrapProductData"]),
    onNext() {
      this.$emit("changeTab", "product_data");
    },
    onPrevious() {
      this.$emit("changeTab", "market_definition");
    },
    async onSelectProduct(product) {
      this.selected_product = product;
      this.selectedVersion = product;
      if (!this.use_existing) {
        this.selected_product = this.custom_product;
        this.selectedVersion = null;
      }
      //console.log('[ai_generated_selected product]');
      //console.log(this.selected_product);

      this.$emit("selectedProduct", this.selected_product);
    },
    transformVersions(versions) {
      return versions.map(version => {
        const {
          version_name,
          id,
          ...properties
        } = version;
        return {
          ...properties,
          title: version_name ?? "Local version",
          id: id ?? version.content_id
        };
      });
    },
    onAmazonButtonClick() {
      this.custom_product.product_name = this.all_versions_list[0].product_name;
    },
    onSwitchChange() {
      this.selected_product = this.all_versions_list[0];
      this.selectedVersion = this.versionList[0];
      if (!this.use_existing) {
        this.selected_product = this.custom_product;
        this.selectedVersion = null;
      }
      this.$emit("selectedProduct", this.selected_product);
    },
    handleCustomDataChange() {
      if (!this.selected_product || this.selected_product?.title == "Custom version") {
        this.selected_product = this.custom_product;
        this.$emit("selectedProduct", this.selected_product);
      }
    },
    async onFetchButtonClick() {
      if (this.user_input_import_done || !this.import_link) {
        return;
      }
      this.user_input_import_loading = true;
      const payload = {
        url: this.import_link
      };
      this.userInput = await this.contentScrapProductData(payload);
      this.user_input_import_loading = false;
      this.user_input_import_done = true;
    },
    onSelectInputData(input_data_type) {
      this.input_data_type = input_data_type;
      this.selected_product = this.versionList.find(item => item.title === this.input_data_type.value);
      if (this.input_data_type.value == 'additional') {
        this.selected_product = this.custom_product;
      }
      this.$emit("selectedProduct", this.selected_product);
    }
  }
};