import { truncateString } from "@/utils";
import FilterItem from "./FilterItem.vue";
import CampaignFilterBody from "./bodies/CampaignFilterBody.vue";
export default {
  name: "CampaignFilter",
  components: {
    FilterItem,
    CampaignFilterBody
  },
  emits: ["apply", "cleared"],
  expose: ["openDropdown"],
  props: {
    campaigns: {
      type: Object,
      required: true
    },
    sellers: {
      type: Object,
      required: true
    },
    marketplaces: {
      type: Object,
      required: true
    }
  },
  computed: {
    getCampaignTitle() {
      let joinedContents = this.campaigns.selected.map(el => el?.name).join(", ");
      joinedContents = joinedContents.length > 0 ? joinedContents : 'Campaigns';
      return truncateString(joinedContents, 16);
    }
  },
  methods: {
    handleCampaignSearch({
      value
    }) {
      // If value is empty, clear the searching state
      if (value.length === 0) {
        this.campaigns.searching = false;
      }
      this.campaigns.asyncSearch({
        keyword: value,
        args: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        }
      });
    },
    handleClear() {
      this.campaigns.clear();
      this.$emit('apply');
    },
    handleSelect({
      id
    }) {
      this.campaigns.select({
        id
      }, 'id');
    },
    handleSelectOnly({
      id
    }) {
      this.campaigns.selectOnly({
        id
      }, 'id');
    },
    handleDeselect({
      id
    }) {
      this.campaigns.deselect({
        id
      }, 'id');
      this.$emit('apply');
    },
    handleClearAll() {
      this.campaigns.clear();
      this.$emit('cleared');
    },
    openDropdown() {
      this.$nextTick(() => {
        const innerFilterItem = this.$refs.campaignFilterItem;
        if (innerFilterItem) {
          innerFilterItem.openDropdown();
        }
      });
    }
  }
};