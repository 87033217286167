import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import auth from "@/store/auth";
import workspace from "@/store/workspace";
import member from "@/store/member";
import account from "@/store/account";
import content from "@/store/content";
import dashboard from "@/store/dashboard";
import period from "@/store/period";
import ppc from "@/store/ppc";
import demand_forecasting from "@/store/demand_forecasting";
import product_data from "@/store/product_data";
import cogs_data from "@/store/cogs_data";
import pdf_render from "@/store/pdf_render";
import tasks from "@/store/tasks";
import ppc_settings from "@/store/ppc_settings";
import profitAndLoss from "@/store/profit-and-loss";
import breadcrumb from "@/store/breadcrumb";
import dashboard_v2 from "@/store/dashboard_v2";
import alerts from "@/store/alerts";
import currency_config from "@/store/currency_config";
import ppc_v2 from "@/store/ppc_v2";
import seo from "./seo";
import date_config from "@/store/date_config";
import rate_limiter from "@/store/rate_limiter";
import { ABORT_MESSAGES } from "../utils/constants";
import { CREDENTIALS_ERROR } from "../utils/constants";
const store = createStore(Vuex.Store, {
  modules: {
    auth,
    workspace,
    member,
    account,
    content,
    dashboard,
    period,
    ppc,
    tasks,
    breadcrumb,
    ppc_settings,
    profitAndLoss,
    demand_forecasting,
    product_data,
    cogs_data,
    pdf_render,
    dashboard_v2,
    alerts,
    currency_config,
    ppc_v2,
    seo,
    date_config
  }
});
export default store;

//export const useFaker = false;
//export const API_URL = useFaker ? '/fakers' : window.location.origin;

window.rate_limiter ??= rate_limiter;
export const request = (uri, method = "GET", data = {}, showSuccessPopup = true, showErrorPopup = true, mode = "cors", signal = null, allow_manual_redirection = true, header_type = "application/json") => {
  const document_cookie = document.cookie ? document.cookie.split("; ") : "";
  const csrf_token_string = document_cookie ? document_cookie.find(row => row.startsWith("csrf_token=")) : "";
  const csrf_token = csrf_token_string ? csrf_token_string.split("=")[1] : "";
  if (!signal) {
    console.warn("Abort Signal not provided");
  } else {
    console.info("Abort Signal: ", signal);
  }
  const params = {
    method,
    credentials: "include",
    mode: mode,
    headers: {
      Accept: "text/html",
      "Content-Type": header_type,
      "X-CSRF-Token": csrf_token
    },
    signal
  };
  data instanceof FormData ? (delete params.headers["Content-Type"], params.body = data) : method !== "GET" ? params.body = JSON.stringify(data) : undefined;
  const {
    token
  } = store.state.auth;
  if (token) {
    params.headers.Authorization = `Bearer ${token}`;
  }
  //const promise = fetch(`${API_URL}${uri}`, params)
  let useFaker = sessionStorage.getItem("useFaker");
  const API_URL = useFaker === "true" ? "/fakers" : window.location.origin;
  const url_to_fetch = `${API_URL}${uri}`;
  let retries = 3;
  const promise = fetchWrapper(url_to_fetch, params).then(responseHandler(allow_manual_redirection, showSuccessPopup, showErrorPopup, url_to_fetch, params, retries)).catch(errorHandler(showErrorPopup));
  return promise;
};
const fetchWrapper = (url, params) => {
  let useFaker = sessionStorage.getItem("useFaker");
  if (useFaker === "true") {
    url += ".json";
    params.method = "GET";
    params.body = null;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        fetch(url, params).then(resolve).catch(reject);
      }, 1000);
    });
  } else {
    return window.rate_limiter.acquireToken(() => fetch(url, params), url);
  }
};
const responseHandler = (allow_manual_redirection, showSuccessPopup, showErrorPopup, url_to_fetch, params, retries) => async response => {
  if (response.redirected && allow_manual_redirection) {
    // show the redirected response
    //https://stackoverflow.com/questions/75184430/how-to-redirect-the-user-to-another-page-after-login-using-javascript-fetch-api
    //const response_url = new URL(response.url);
    //sessionStorage.setItem(response_url.pathname, true);
    window.location.replace(response.url); // or, location.replace(res.url);
    return;
  }
  if (response.status === 429 && retries >= 1) {
    console.log("retry");
    console.log(response.status);
    console.log(response);
    const promise = fetchWrapper(url_to_fetch, params).then(responseHandler(allow_manual_redirection, showSuccessPopup, showErrorPopup, url_to_fetch, params, retries - 1)).catch(errorHandler(showErrorPopup));
    return promise;
  }
  let data = await response.text();
  if (response.headers.get("content-type").startsWith("application/json")) {
    data = JSON.parse(data);
  } else {
    data = {};
  }
  if (!response.ok) {
    // Need to know server response structure to process
    console.log("Error at server. Need to handle");
    let err = new Error("HTTP status code: " + response?.status);
    err.response = data;
    throw err;
  }
  if (response.status == 201) {
    // This is an asynchronous task, call the tasks api until the response is available
    let task_data = {
      task_id: data.task_id
    };
    setTimeout(function () {
      request("/tasks/get", "POST", task_data, false, false);
    }, 5000);
  }
  if (showSuccessPopup && data.status) {
    window.$bus.trigger("showInfo", {
      visible: true,
      message: data.status
    });
  }
  return data;
};
const errorHandler = showErrorPopup => err => {
  // Need to know server response structure to process
  //let message = reason.response.status ? reason.response.status : "Error at server";

  console.log("Error at server. Need to handle", err);
  if (err.response?.status == "CredentialsError") {
    window.$bus.trigger("showErrorLogin", CREDENTIALS_ERROR.WRONG_CREDENTIALS_ERROR);
  }
  if (err.response?.status == "ServerCredentialsError") {
    window.$bus.trigger("showErrorLogin", CREDENTIALS_ERROR.WRONG_CREDENTIALS_ERROR_SERVER);
  }

  // Check if it's an abort error
  if (err.name == "AbortError") {
    //console.log("Request aborted");
    return ABORT_MESSAGES.IN_PROGRESS;
  }
  if (showErrorPopup) {
    window.$bus.trigger("showDanger", {
      visible: true,
      message: "Error at server"
    });
  }
  return err.response;
};