import FilterSectionTitle from "./FilterSectionTitle.vue";
import WrapWithSection from "./WrapWithSection.vue";
export default {
  name: "ListSection",
  components: {
    WrapWithSection,
    FilterSectionTitle
  },
  emits: ["selectAll"],
  props: {
    title: {
      type: String,
      default: ""
    },
    count: {
      type: Number,
      default: 0
    },
    searching: {
      type: Boolean,
      default: false
    },
    heightFromTop: {
      type: String,
      default: "0px"
    }
  }
};