import { getFormattedDecimal } from "@/utils";
import HugValue from "./HugValue.vue";
import ValueElement from "./ValueElement.vue";
import TableElementsMixin from '@/mixins/TableElementsMixin.vue';
export default {
  name: "NumberValue",
  mixins: [TableElementsMixin],
  components: {
    HugValue,
    ValueElement
  },
  props: {
    value: {
      type: [Number, null],
      required: false,
      default: null
    },
    align: {
      type: String,
      required: false,
      default: "right"
    },
    styles: {
      type: Object,
      required: false,
      default: () => ({})
    },
    precision: {
      type: Number,
      required: false,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "auto"
    },
    showSign: {
      type: Boolean,
      default: false
    },
    charCount: {
      type: Number
    },
    tight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedStyle() {
      const width = this.charCount ? `${this.charCount}ch` : 'auto';
      return {
        ...this.styles,
        color: this.color,
        width
      };
    },
    formattedValue() {
      if (!this.isCorrectValue) {
        return null;
      }
      return getFormattedDecimal(this.value, this.precision, this.showSign);
    }
  }
};