import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-aae6ab3a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "empty-space"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    style: _normalizeStyle({
      width: $props.size + 'px',
      height: $props.size + 'px'
    }),
    src: "/assets/images/empty-space.svg",
    alt: "empty-space"
  }, null, 4 /* STYLE */), _createElementVNode("div", null, _toDisplayString($props.text), 1 /* TEXT */)]);
}