import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-75833a3c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pager"
};
const _hoisted_2 = {
  class: "buttons"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, _toDisplayString($options.pageStart) + " — " + _toDisplayString($options.pageEnd) + " / " + _toDisplayString($props.totalCount), 1 /* TEXT */), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: _normalizeClass({
      active: $options.activateLeft
    }),
    onClick: _cache[0] || (_cache[0] = (...args) => $options.prevPage && $options.prevPage(...args))
  }, [_createVNode(_component_font_awesome_icon, {
    icon: "fa-chevron-left"
  })], 2 /* CLASS */), _createElementVNode("div", {
    class: _normalizeClass({
      active: $options.activateRight
    }),
    onClick: _cache[1] || (_cache[1] = (...args) => $options.nextPage && $options.nextPage(...args))
  }, [_createVNode(_component_font_awesome_icon, {
    icon: "fa-chevron-right"
  })], 2 /* CLASS */)])]);
}