import { truncateString } from '@/utils';
export default {
  name: 'ProductSkuAsinItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false,
      required: false
    },
    truncate: {
      type: Boolean,
      default: false,
      required: false
    },
    truncateLength: {
      type: Number,
      default: 50,
      required: false
    },
    showImage: {
      type: Boolean,
      default: true,
      required: false
    },
    handleSelect: {
      type: Function,
      required: false,
      default: () => {
        return () => {};
      }
    },
    customStyle: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    breakName: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    updatedItem() {
      if (this.truncate) {
        return truncateString(this.item.product_name, this.truncateLength);
      }
      return this.item.product_name;
    }
  },
  methods: {
    truncateString,
    generateTitle() {
      return `Product: ${this.item.product_name}\nAsin: ${this.item.asin}\nSku:  ${this.item.sku}`;
    },
    handleClick(e) {
      e.preventDefault();
      e.stopPropagation();
      if (this.handleSelect) this.handleSelect(e, this.item);
    }
  }
};