import { mapGetters } from "vuex";
export default {
  name: 'DatePickerDays',
  data() {
    return {
      dayNames: {
        monday: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        sunday: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
      }
    };
  },
  computed: {
    ...mapGetters(["getSystemWeekType"]),
    getDays() {
      return this.dayNames[this.getSystemWeekType];
    }
  }
};