import SearchElement from "../SearchElement.vue";
import WrapWithDropdownBody from "../WrapWithDropdownBody.vue";
import { SelectedSection } from "../sections";
import AdFormatFilterItem from "../items/AdFormatFilterItem.vue";
import ListSection from "../sections/ListSection.vue";
export default {
  name: "AdFormatFilterBody",
  components: {
    WrapWithDropdownBody,
    SearchElement,
    SelectedSection,
    AdFormatFilterItem,
    ListSection
  },
  emits: ["clear", "select", "selectOnly", "deselect", "apply", "search", "hidden", "closeDropdown"],
  data() {
    return {
      searchValue: ""
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    selected: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    isModified: {
      type: Boolean,
      default: false
    },
    searching: {
      type: Boolean,
      default: false
    }
  }
};