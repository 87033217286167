import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-74d48156"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "datepicker"
};
const _hoisted_2 = {
  class: "datepicker-sidebar"
};
const _hoisted_3 = {
  class: "datepicker-right-pane"
};
const _hoisted_4 = {
  class: "datepicker-content"
};
const _hoisted_5 = {
  class: "actions-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_button = _resolveComponent("app-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "sidebar", {}, undefined, true)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "body", {}, undefined, true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_app_button, {
    variant: "text",
    class: "custom-button",
    "no-min-width": "",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('cancel'))
  }, {
    default: _withCtx(() => [_createTextVNode("Cancel")]),
    _: 1 /* STABLE */
  }), _createVNode(_component_app_button, {
    variant: "primary",
    disabled: $props.disableOK,
    class: "custom-button",
    "no-min-width": "",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('submit'))
  }, {
    default: _withCtx(() => [_createTextVNode("OK")]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled"])])])]);
}