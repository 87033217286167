import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_select = _resolveComponent("b-form-select");
  const _component_b_form_group = _resolveComponent("b-form-group");
  return _openBlock(), _createBlock(_component_b_form_group, {
    label: $props.label,
    description: $props.description,
    state: $options.inputState,
    "invalid-feedback": _ctx.mValidateMessage('value'),
    "label-for": $data.id,
    class: _normalizeClass(['app-select', {
      v2: $props.v2
    }])
  }, {
    default: _withCtx(() => [_createVNode(_component_b_form_select, {
      modelValue: $options.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.value = $event),
      options: $options._options,
      required: $props.required,
      disabled: $props.readonly,
      state: $options.inputState,
      id: $data.id,
      style: {
        "min-width": "200px"
      },
      onKeydown: _withKeys($options.onSubmit, ["enter"])
    }, null, 8 /* PROPS */, ["modelValue", "options", "required", "disabled", "state", "id", "onKeydown"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["label", "description", "state", "invalid-feedback", "label-for", "class"]);
}