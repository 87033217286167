import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6d2a575d"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1,
  class: "sort",
  style: {
    "margin-right": "8px"
  }
};
const _hoisted_4 = {
  class: "label-text",
  style: {
    "flex": "1",
    "white-space": "nowrap"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_b_th = _resolveComponent("b-th");
  const _component_b_tr = _resolveComponent("b-tr");
  const _component_b_thead = _resolveComponent("b-thead");
  return $props.fields.length ? (_openBlock(), _createBlock(_component_b_thead, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tr, {
      class: "fixed-header"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fields, (field, index) => {
        return _openBlock(), _createBlock(_component_b_th, {
          key: index
        }, {
          default: _withCtx(() => [_createElementVNode("div", {
            class: _normalizeClass(['th', field.custom, field.align, field.valign, {
              sortable: field.sortable
            }, {
              active: field.sortable && $props.sortBy == field.key
            }]),
            onClick: $event => $options.onSort(field),
            style: {
              "display": "flex",
              "align-items": "center"
            }
          }, [field.isSelectAll ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_b_form_checkbox, {
            modelValue: $data.selectall,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.selectall = $event),
            onChange: $options.onSelectAll
          }, null, 8 /* PROPS */, ["modelValue", "onChange"])])) : _createCommentVNode("v-if", true), field.sortable ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_app_icon, {
            icon: $props.sortDesc && $props.sortBy == field.key ? 'arrow-down' : 'arrow-up'
          }, null, 8 /* PROPS */, ["icon"])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, field.key, {}, () => [_createTextVNode(_toDisplayString(field.label), 1 /* TEXT */)], true)])], 10 /* CLASS, PROPS */, _hoisted_1)]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 3 /* FORWARDED */
    })]),
    _: 3 /* FORWARDED */
  })) : _createCommentVNode("v-if", true);
}