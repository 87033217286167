import { MarketplaceValue } from "@/components/tableElements";
import { WrapWithSectionItem } from "../sections";
export default {
  name: "MarketplaceFilterItem",
  emits: ["select", "selectOnly", "deselect"],
  props: {
    name: {
      type: String
    },
    icon: {
      type: String
    },
    selected: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    MarketplaceValue,
    WrapWithSectionItem
  }
};