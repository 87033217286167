export default {
  name: 'DoubleChevronsDownIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 17
    },
    color: {
      type: String,
      default: '#736BC9'
    }
  }
};