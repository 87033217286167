import AppTd from "@/components/AppTd";
import WrapWithStatusIcon from "./WrapWithStatusIcon.vue";
export default {
  name: 'SeoImagesCountCell',
  props: {
    item: Object
  },
  components: {
    AppTd,
    WrapWithStatusIcon
  }
};