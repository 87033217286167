export default {
  name: "ErrorOccured",
  emits: ['retry'],
  data() {
    return {
      loading: false
    };
  },
  props: {
    text: {
      type: String,
      default: 'Sorry, an error occurred processing your request. Please try again.'
    },
    size: {
      type: String,
      default: '200'
    },
    wrapperHeight: {
      type: String,
      default: '400px'
    }
  },
  methods: {
    handleRetry() {
      this.loading = true;
      this.$emit('retry');
    }
  }
};