import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9f449760"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "counter"
};
const _hoisted_2 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($options.formattedCount), 1 /* TEXT */)], 512 /* NEED_PATCH */)), [[_vShow, $props.count > 0]]);
}