import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-505dc534"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon_group = _resolveComponent("app-icon-group");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_table_skeleton_v2 = _resolveComponent("table-skeleton-v2");
  const _component_flex_column = _resolveComponent("flex-column");
  return _openBlock(), _createBlock(_component_flex_column, {
    gap: $props.gap,
    class: "table-with-title"
  }, {
    default: _withCtx(() => [_createVNode(_component_flex_row, {
      style: {
        width: 'auto',
        height: 'auto',
        justifyContent: 'unset'
      },
      gap: 12
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString($props.title), 1 /* TEXT */), _createVNode(_component_app_icon_group, {
        icons: [{
          icon: 'download-csv',
          size: 20,
          help: 'Download CSV',
          click: $options.handleClick,
          placement: 'right'
        }]
      }, null, 8 /* PROPS */, ["icons"])]),
      _: 1 /* STABLE */
    }), $props.loading ? (_openBlock(), _createBlock(_component_table_skeleton_v2, {
      key: 0,
      fields: $props.fields
    }, null, 8 /* PROPS */, ["fields"])) : _renderSlot(_ctx.$slots, "default", {
      key: 1
    }, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["gap"]);
}