import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
export default {
  name: "PpcBidSettingInput",
  mixins: [ValidateMixin],
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    refName: {
      type: String,
      required: true
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      validate: [{
        variable: "value",
        rule: "bid"
      }]
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue.toString();
      },
      set(val) {
        //console.log(val);
        this.$emit("update:modelValue", val);
        this.$emit("change", val);
      }
    }
  },
  methods: {
    async onBlur() {
      const inputField = this.$refs["bid_setting_input"];
      if (!inputField) return;
      const isValid = await this.mValidate();
      this.$emit("update:isValid", isValid);
      if (isValid) {
        this.blur();
        this.$emit("onBlur", this.refName, this.isValid);
      } else {
        this.focus();
      }
    },
    focus() {
      this.$refs["bid_setting_input"].focus();
    },
    blur() {
      this.$refs["bid_setting_input"].blur();
    },
    async onInput(event) {
      let input = event.target.value;
      input = input.replace(/[^0-9-]/g, '');
      this.value = input;
      const isValid = await this.mValidate();
      this.$emit("update:isValid", isValid);
      const cursorPosition = event.target.selectionStart;
      this.$nextTick(() => {
        event.target.setSelectionRange(cursorPosition, cursorPosition);
      });
    }
  }
};