import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "w-100 h-100 position-relative"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_block_styled_bar = _resolveComponent("block-styled-bar");
  const _component_loading_element = _resolveComponent("loading-element");
  return _openBlock(), _createBlock(_component_loading_element, {
    class: "h-100",
    loading: $props.loading
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_block_styled_bar, {
      class: "position-absolute",
      value: $props.value,
      min: $props.min,
      max: $props.max,
      color: $props.color
    }, null, 8 /* PROPS */, ["value", "min", "max", "color"]), _renderSlot(_ctx.$slots, "default")])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading"]);
}