import { createApp } from 'vue';
import BootstrapVueNext from 'bootstrap-vue-next';
import store from './store';
import router from './router';
import $bus from './store/events.js';
import feature from '@/features';
import App from './App.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAnglesLeft, faTimes, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircleXmark, faDownLong, faUpLong, faClipboard, faSearch, faDownload, faCheckCircle, faExclamationCircle, faExclamationTriangle, faCalendar, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './scss/app.scss';
import OmnyUILayout from './layouts/OmnyUILayout.vue';
import TheTopbar from './components/TheTopbar.vue';
import RightPaneLayout from './layouts/RightPaneLayout.vue';
import OmnyDeleteModal from './layouts/OmnyDeleteModal.vue';
import OmnyModalHeader from './layouts/OmnyModalHeader.vue';
import DropdownBody from './components/shared/DropdownBody.vue';
import { vElementVisibility } from '@vueuse/components';
library.add(faAnglesLeft, faTimes, faChevronDown, faChevronUp, faCircleXmark, faDownLong, faUpLong, faClipboard, faSearch, faChevronLeft, faChevronRight, faDownload, faCheckCircle, faExclamationCircle, faExclamationTriangle, faCalendar, faAngleDown);
(function () {
  'use strict';

  const app = createApp(App);
  window.$bus = $bus;

  //Give a reference to the store to the router
  router.$store = store;
  feature.$store = store;
  router.$feature = feature;

  // Declare a global abort controller
  window.abort_controller = new AbortController();

  //session storage for developement testing, do not use to store data
  sessionStorage.setItem("useFaker", false);
  app.component('font-awesome-icon', FontAwesomeIcon);
  // eslint-disable-next-line vue/multi-word-component-names
  app.component('feature', feature);
  app.component('omny-ui-layout', OmnyUILayout);
  app.component('the-topbar', TheTopbar);
  app.component('right-pane-layout', RightPaneLayout);
  app.component('omny-delete-modal', OmnyDeleteModal);
  app.component('omny-modal-header', OmnyModalHeader);
  app.component('dropdown-body', DropdownBody);
  app.directive('v-element-visibility', vElementVisibility);
  app.use(BootstrapVueNext);
  app.use(store);
  app.use(router);
  app.mount('#app');
})();