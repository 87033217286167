import FlexRow from "@/components/FlexRow.vue";
import { WrapWithSectionItem } from "../sections";
import RegionValue from "./RegionValue.vue";
import { TextValue } from "@/components/tableElements";
import FlexColumn from "../../FlexColumn.vue";
import WrapWithCopy from "@/components/WrapWithCopy.vue";
export default {
  name: "SellerFilterItem",
  components: {
    WrapWithSectionItem,
    TextValue,
    FlexRow,
    FlexColumn,
    RegionValue,
    WrapWithCopy
  },
  emits: ["select", "selectOnly", "deselect"],
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    region: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    }
  }
};