import { mapActions } from 'vuex';
import AppCardCustom from '@/components/AppCardCustom';
import AppInput from '@/components/AppInput';
import AppIcon from '@/components/AppIcon';
import AppLoader from '@/components/AppLoader';
export default {
  name: 'PpcProducts',
  components: {
    AppCardCustom,
    AppInput,
    AppIcon,
    AppLoader
  },
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      search: '',
      isSecondStatus: false,
      isOmnyAiAbove: false,
      isOmnyAiUnder: false,
      isInactiveAbove: false,
      loadingAsin: null
    };
  },
  computed: {
    filteredProducts() {
      let items = this.products;
      if (this.search.trim() != '') {
        items = this.products.filter(item => {
          return item.name.toLowerCase().includes(this.search.trim().toLowerCase()) || item.asin.toLowerCase().includes(this.search.trim().toLowerCase()) || item.sku.toLowerCase().includes(this.search.trim().toLowerCase());
        });
      }
      return {
        total: items,
        active: items.filter(item => item.active),
        boosted: items.filter(item => !item.active && item.boosted),
        inactive: items.filter(item => !item.active && !item.boosted)
      };
    },
    percents() {
      let percents = {
        active: 0,
        boosted: 0,
        inactive: 0
      };
      if (this.filteredProducts.total.length > 0) {
        percents = {
          active: this.filteredProducts.active.length * 100 / this.filteredProducts.total.length,
          boosted: this.filteredProducts.boosted.length * 100 / this.filteredProducts.total.length,
          inactive: this.filteredProducts.inactive.length * 100 / this.filteredProducts.total.length
        };
      }
      return percents;
    }
  },
  mounted() {
    this.onScroll();
  },
  methods: {
    ...mapActions(['ppcProductsBoost']),
    onScroll() {
      const omnyAI = this.$refs.omnyAI;
      const scrollDiv = this.$refs.scroll;
      this.isSecondStatus = false;
      this.isOmnyAiUnder = false;
      this.isOmnyAiAbove = false;
      if (omnyAI && scrollDiv) {
        const omnyAIRect = omnyAI.getBoundingClientRect();
        const scrollDivRect = scrollDiv.getBoundingClientRect();
        const isOmnyAIIsAboveVisible = omnyAIRect.bottom < scrollDivRect.top;
        if (isOmnyAIIsAboveVisible) {
          this.isSecondStatus = true;
          this.isOmnyAiAbove = true;
        } else {
          const isOmnyAIIsUnderVisible = omnyAIRect.top > scrollDivRect.bottom;
          if (isOmnyAIIsUnderVisible) {
            this.isOmnyAiUnder = true;
          }
        }
        this.isInactiveAbove = false;
        const inactive = this.$refs.inactive;
        if (inactive) {
          const inactiveRect = inactive.getBoundingClientRect();
          const isInactiveAboveVisible = inactiveRect.top <= scrollDivRect.top;
          if (isInactiveAboveVisible) {
            this.isInactiveAbove = true;
          }
        }
      }
    },
    onInactiveScroll() {
      const inactive = this.$refs.inactive;
      const scrollDiv = this.$refs.scroll;
      if (inactive && scrollDiv) {
        const inactiveRect = inactive.getBoundingClientRect();
        const scrollDivRect = scrollDiv.getBoundingClientRect();
        scrollDiv.scrollTo({
          top: inactiveRect.top - scrollDivRect.top + scrollDiv.scrollTop,
          left: 0,
          behavior: 'smooth'
        });
      }
    },
    onBoostedScroll() {
      const omnyAI = this.$refs.omnyAI;
      const scrollDiv = this.$refs.scroll;
      if (omnyAI && scrollDiv) {
        const omnyAIRect = omnyAI.getBoundingClientRect();
        const scrollDivRect = scrollDiv.getBoundingClientRect();
        scrollDiv.scrollTo({
          top: omnyAIRect.top - scrollDivRect.top + scrollDiv.scrollTop - 20,
          left: 0,
          behavior: 'smooth'
        });
      }
    },
    async onBoosted(item) {
      this.loadingAsin = item.asin;
      await this.ppcProductsBoost({
        asin: item.asin
      });
      this.loadingAsin = null;
    }
  }
};