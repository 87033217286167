import AppIcon from '@/components/AppIcon.vue';
import LoadingElement from '@/components/shared/LoadingElement.vue';
export default {
  name: 'AppRating',
  components: {
    AppIcon,
    LoadingElement
  },
  props: {
    rating: {
      type: Number,
      default: 0
    },
    maxRating: {
      type: Number,
      default: 5
    },
    maxStars: {
      type: Number,
      default: 5
    },
    count: {
      type: Number,
      default: null
    },
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: 'orange'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getIconName() {
      return index => {
        const step = Math.round(100 / this.maxStars);
        const percent = this.rating * 100 / this.maxRating;
        if (percent <= (index - 1) * step) {
          return 'star';
        }
        if (percent >= index * step) {
          return 'star-fill';
        }
        return 'star-half';
      };
    }
  }
};