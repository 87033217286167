// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg width=%272%27 height=%2734%27 viewBox=%270 0 2 34%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M0.900002 34V31.1667H0.299995V34H0.900002ZM0.900002 25.5V19.8333H0.299995V25.5H0.900002ZM0.900002 14.1667V8.5H0.299995V14.1667H0.900002ZM0.900002 2.83334V0H0.299995V2.83334H0.900002ZM1.2 34V31.1667H0V34H1.2ZM1.2 25.5V19.8333H0V25.5H1.2ZM1.2 14.1667V8.5H0V14.1667H1.2ZM1.2 2.83334V0H0V2.83334H1.2Z%27 fill=%27%23BFBFBF%27/%3E%3C/svg%3E%0A", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".evolution[data-v-25fe4ca4] {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  height: 100%;\n  width: 100%;\n}\n.evolution div[data-v-25fe4ca4] {\n  overflow: hidden;\n}\n.evolution .text[data-v-25fe4ca4] {\n  overflow: hidden;\n  display: -webkit-box;\n  min-width: -moz-max-content;\n  min-width: max-content;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 1;\n  color: var(--pp-color, initial);\n}\n.evolution .evolution-value[data-v-25fe4ca4] {\n  gap: 4px !important;\n}\n.evolution .evolution-value.negative .bar[data-v-25fe4ca4] {\n  border-left: 1px solid #DB333B !important;\n}\n.evolution .evolution-value.positive .bar[data-v-25fe4ca4] {\n  border-right: 1px solid #22863D !important;\n}\n.col[data-v-25fe4ca4] {\n  border-right: 1px solid var(--border-color);\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  font-family: Figtree;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 140%;\n  color: #4D4D4D;\n}\n.col[data-v-25fe4ca4]:not(.name) {\n  font-variant-numeric: lining-nums tabular-nums;\n}\n.col.level-2[data-v-25fe4ca4], .col.level-3[data-v-25fe4ca4] {\n  color: #595959;\n}\n.col.level-2 > div[data-v-25fe4ca4] {\n  padding-left: 16px !important;\n}\n.col.level-3 > div[data-v-25fe4ca4] {\n  padding-left: 32px !important;\n}\n.col.name.type-cumlative[data-v-25fe4ca4] {\n  font-weight: 600;\n}\n.col.evolution-column[data-v-25fe4ca4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-repeat: no-repeat;\n  --container-width: calc(180px - (5ch + 4px));\n  overflow: hidden;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
