import CenterWrapper from '../shared/CenterWrapper.vue';
export default {
  name: "AnnoncementIcon",
  components: {
    CenterWrapper
  },
  props: {
    color: {
      type: String,
      default: "#5A52AE"
    },
    width: {
      type: String,
      default: "24"
    },
    height: {
      type: String,
      default: "24"
    }
  }
};