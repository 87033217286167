import FlexRow from "@/components/FlexRow.vue";
import RevenueExclVatCard from "@/components/kpi-cards/dashboard_v2/RevenueExclVatCard.vue";
import DaysOfInventoryCard from "@/components/kpi-cards/dashboard_v2/DaysOfInventoryCard.vue";
import OrganicCvrCard from "@/components/kpi-cards/dashboard_v2/OrganicCvrCard.vue";
import TacosCard from "@/components/kpi-cards/dashboard_v2/TacosCard.vue";
import { APP_PERIOD_DROPDOWN_ELEMENTS } from "@/utils/constants";
import { mapState } from "vuex";
export default {
  name: "PerformanceCards",
  components: {
    FlexRow,
    RevenueExclVatCard,
    DaysOfInventoryCard,
    OrganicCvrCard,
    TacosCard
  },
  computed: {
    ...mapState({
      kpis: state => state.dashboard_v2.kpis.data.performance,
      periodCategory: state => state.dashboard_v2.periodCategory,
      period: state => state.dashboard_v2.period
    }),
    previousPeriodKpis() {
      return this.kpis[this.periodCategory];
    },
    getSources() {
      return {
        [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value]: this.previousPeriodKpis[0],
        [APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value]: this.previousPeriodKpis[4],
        [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY.value]: this.previousPeriodKpis[5],
        [APP_PERIOD_DROPDOWN_ELEMENTS.YEARLY.value]: this.previousPeriodKpis[6]
      };
    },
    getSplitSubtitle() {
      return this.getSources[this.period]?.split_subtitle;
    },
    getCardName() {
      return this.getSources[this.period]?.name;
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};