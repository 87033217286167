import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2865a5fa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "day-list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getDays, day => {
    return _openBlock(), _createElementBlock("div", {
      key: day,
      class: "day-item"
    }, _toDisplayString(day), 1 /* TEXT */);
  }), 128 /* KEYED_FRAGMENT */))]);
}