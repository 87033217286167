import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_group_by = _resolveComponent("group-by");
  return _openBlock(), _createBlock(_component_group_by, {
    ref: "groupBy",
    class: "aggregate",
    modelValue: $options.groupBy,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.groupBy = $event),
    tabs: $data.groupByElements,
    onGroupBySelected: $options.groupBySelected,
    loading: $props.loading
  }, null, 8 /* PROPS */, ["modelValue", "tabs", "onGroupBySelected", "loading"]);
}