import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createBlock(_component_b_dropdown, {
    class: "dropdown app-dropdown",
    "no-caret": "",
    offset: "8",
    onShown: _cache[0] || (_cache[0] = $event => $setup.arrowIconColor = $props.arrow_icon_color ?? '#5A52AE'),
    onHidden: _cache[1] || (_cache[1] = $event => $setup.arrowIconColor = $props.arrow_icon_color ?? '#4D4D4D')
  }, {
    "button-content": _withCtx(() => [_ctx.$slots['button-content'] ? _renderSlot(_ctx.$slots, "button-content", {
      key: 0
    }) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [$props.icon ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 0,
      icon: $props.icon,
      size: 14,
      color: "#616161",
      class: "icon"
    }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString($options.text), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */)), $props.noCaret ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 2,
      icon: "simple-arrow-down",
      color: $setup.arrowIconColor,
      size: 16,
      class: "icon"
    }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)]),
    default: _withCtx(() => [_ctx.$slots['body'] ? _renderSlot(_ctx.$slots, "body", {
      key: 0
    }) : (_openBlock(true), _createElementBlock(_Fragment, {
      key: 1
    }, _renderList($props.options, item => {
      return _openBlock(), _createBlock(_component_b_dropdown_item, {
        key: item?.value ?? item,
        active: (item?.value ?? item) == $props.modelValue,
        onClick: $event => $options.onClick(item?.value ?? item)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item?.text ?? item), 1 /* TEXT */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["active", "onClick"]);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 3 /* FORWARDED */
  });
}