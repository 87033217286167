import { TextValue } from "../../tableElements";
import { WrapWithSectionItem } from "../sections";
export default {
  name: "PortfolioFilterItem",
  emits: ["select", "selectOnly", "deselect"],
  props: {
    portfolio_name: String,
    portfolio_id_list: Array,
    portfolio_id: String,
    selected: Boolean
  },
  components: {
    WrapWithSectionItem,
    TextValue
  }
};