import { mapState, mapActions } from "vuex";
import { EVENTS } from "@/utils";
import AppButton from "@/components/AppButton.vue";
import AppIcon from "@/components/AppIcon";
import TasksMixin from "@/mixins/TasksMixin.vue";
import FileUploadErrorMessageMixin from "@/mixins/FileUploadErrorMessageMixin.vue";
export default {
  name: "UploadProductDataSidebar",
  mixins: [TasksMixin, FileUploadErrorMessageMixin],
  emits: ["is-sidebar-collapsed", "close-upload-file-modal"],
  components: {
    AppIcon,
    AppButton
  },
  props: {
    account_id: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState({})
  },
  data() {
    return {
      menu: null,
      selectedFile: null,
      loading: false,
      upload_finished: false,
      fileName: "",
      isDragging: false,
      errorMessage: ""
    };
  },
  methods: {
    ...mapActions(["uploadProductDataCSV"]),
    onToggleMenu(menu) {
      //console.log("close the menu when the download is finished");
      this.menu = this.menu == menu ? null : menu;
      this.$emit("is-sidebar-collapsed", this.menu == null);
    },
    triggerNativeFileInput() {
      //console.log("[trigger file input]");
      this.$refs.nativeFileInput.click();
    },
    dragEnter() {
      this.isDragging = true;
      //console.log("Drag enter detected"); // Debugging
    },
    dragLeave() {
      this.isDragging = false;
      //console.log("Drag leave detected"); // Debugging
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.validateFile(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      //console.log("[handle drop]");
      //console.log(file);
      //console.log("[handle drop event]", event);
      //console.log("[event dataTransfer]", event.dataTransfer);
      this.validateFile(file);
      this.isDragging = false;
    },
    handleDragOver(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    validateFile(file) {
      const validTypes = ["text/csv"];
      //console.log("validate file");
      //console.log("[dragged file to validate]");
      //console.log(file);
      if (file) {
        if (!validTypes.includes(file.type)) {
          this.errorMessage = "The submitted file is not supported.";
          this.selectedFile = null;
        } else {
          this.errorMessage = null;
          this.selectedFile = file;
          this.fileName = this.selectedFile.name;
          this.loading = true;
          //console.log("[selected file:]", this.selectedFile);
          this.uploadProductDataCSVFile();
        }
      } else {
        this.errorMessage = "No files were dropped.";
      }
    },
    async uploadProductDataCSVFile() {
      //console.log("[uploadProductDataCSVFile clicked for new implementation]");

      const chunkSize = 1024 * 1024 * 5; // 5MB
      //const chunkSize = 350; //this is for testing to see if doesnt break the line
      const reader = new FileReader();
      const file = this.selectedFile;
      const totalChunks = Math.ceil(file.size / chunkSize);
      let payload = {
        fileName: file.name,
        fileType: file.type,
        totalChunks: totalChunks
      };
      this.loading = true;
      reader.onload = async event => {
        const fileContent = event.target.result;
        const lines = fileContent.split("\n");
        let lineIndex = 0;
        let chunkIndex = 0;
        let chunk = "";
        let line = "";
        let currentSize = 0;
        while (lineIndex < lines.length && this.loading) {
          line = lines[lineIndex];
          chunk += line + "\n";
          currentSize += line.length + 1;
          lineIndex++;
          if (currentSize > chunkSize) {
            payload.chunk = chunk;
            payload.chunkIndex = chunkIndex;
            let response = await this.uploadProductDataCSV(payload);

            //console.log("[file process update in the sidebar>>Initial chunk]");
            this.checkFileUploadTaskStatusPeriodically(response.task_id);
            chunk = lines[0] + "\n";
            currentSize = 0;
            chunkIndex++;
          }
        }
        if (chunk.length > 0 && currentSize > 1 && this.loading) {
          payload.chunk = chunk;
          payload.chunkIndex = chunkIndex;
          let response = await this.uploadProductDataCSV(payload);
          //console.log("[file process update in the sidebar>>Final chunk]");
          this.checkFileUploadTaskStatusPeriodically(response?.task_id);
        }
        this.upload_finished = true;
        reader.abort();
      };
      reader.readAsText(this.selectedFile);
    },
    handleIdle() {
      //console.log("[no file upload task is idle]");
      this.loading = false;
    },
    handleRunning(data) {
      //console.log("[file upload task is still running]");
      this.loading = true;
      this.checkFileUploadTaskStatusPeriodically(data?.task_id);
    },
    handleFailed(data) {
      this.loading = false;
      this.selectedFile = null;
      if (this.asyncUpdateFileUpload) {
        clearTimeout(this.asyncUpdateFileUpload);
        this.asyncUpdateFileUpload = null;
      }
      let parsedFileUploadResponseError = this.parseFileUploadErrorResult(data.task_result);
      let errorSummary = `<div class="stacked-vertical">File Upload failed.Error Summary:</div><br>`;
      Object.entries(parsedFileUploadResponseError.errorCount).forEach(([errorType, count]) => {
        errorSummary += `<div>${errorType}: ${count} occurrence(s)</div>`;
      });
      const jsonData = JSON.stringify(parsedFileUploadResponseError, null, 2);
      const blob = new Blob([jsonData], {
        type: "application/json"
      });
      const url = URL.createObjectURL(blob);
      const downloadLink = `<div style="margin-top: 1rem;"><a href="${url}" download="file_upload_errors.json" class="btn btn-link">Click here to download error details as JSON</a></div>`;
      errorSummary += downloadLink;
      window.$bus.trigger("showDanger", {
        visible: true,
        message: `<div class="stacked-vertical">${errorSummary}</div>`,
        delay: 60000,
        isHtml: true
      });
      //throw new Error("Upload failed");
    },
    async handleFinished(data) {
      this.selectedFile = null;
      if (this.asyncUpdateFileUpload) {
        clearTimeout(this.asyncUpdateFileUpload);
        this.asyncUpdateFileUpload = null;
      }
      if (this.upload_finished) {
        this.loading = false;
        window.$bus.trigger("showSuccess", {
          visible: true,
          message: "File Upload is finished",
          delay: 5 // Show alert less than 6 secs
        });
        await window.$bus.trigger(EVENTS.REFRESH_PRODUCT_DATA_PAGE);
        this.$emit("close-upload-file-modal");
      }
    },
    handleDownloadCSVClick() {
      this.$emit("handleDownloadCSVClick");
    }
  }
};