import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_skeleton_item = _resolveComponent("skeleton-item");
  const _component_b_th = _resolveComponent("b-th");
  const _component_b_table_simple = _resolveComponent("b-table-simple");
  return _openBlock(), _createBlock(_component_b_table_simple, {
    style: _normalizeStyle($props.customStyle),
    class: "custom-table",
    responsive: ""
  }, {
    default: _withCtx(() => [_withDirectives(_createElementVNode("thead", null, [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getHeaders, (_, index) => {
      return _openBlock(), _createBlock(_component_b_th, {
        key: index
      }, {
        default: _withCtx(() => [_createVNode(_component_skeleton_item, {
          color: "#ccc"
        })]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */);
    }), 128 /* KEYED_FRAGMENT */))])], 512 /* NEED_PATCH */), [[_vShow, !$props.noHeader]]), _createElementVNode("tbody", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getContent, (_, index) => {
      return _openBlock(), _createElementBlock("tr", {
        key: index
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getHeaders, (_, index) => {
        return _openBlock(), _createElementBlock("td", {
          key: index
        }, [_createVNode(_component_skeleton_item)]);
      }), 128 /* KEYED_FRAGMENT */))]);
    }), 128 /* KEYED_FRAGMENT */))])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style"]);
}