import AppIcon from "@/components/AppIcon.vue";
import AppModal from "@/components/AppModal.vue";
import Popper from "vue3-popper";
export default {
  name: "SeoImageRow",
  components: {
    AppIcon,
    AppModal,
    Popper
  },
  props: {
    imagesList: {
      type: Array,
      default: () => []
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
    pushModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hoverImage: {}
    };
  },
  computed: {
    renderedImageList() {
      if (this.imagesList.length <= 0) {
        return [];
      }
      return this.isCollapsed ? [this.imagesList[0]] : this.imagesList;
    },
    imageListSize() {
      let size = 9;
      this.imagesList.forEach(image => {
        size = image.link == "" ? size - 1 : size;
      });
      return size;
    }
  },
  mounted() {
    this.imagesList.forEach(image => {
      this.hoverImage[image.link] = false;
    });
  },
  methods: {
    expandColumn() {
      this.$emit("expandImageList");
    },
    onClickImage(refName) {
      if (!refName) {
        return;
      }
      this.hoverImage[refName] = true;
    },
    onLeaveImage(refName) {
      this.hoverImage[refName] = false;
    },
    imagePopupText(image) {
      return image.link === "" ? "image is missing" : image.hover_img_size == "image_large" ? "image with good size (>1000px)" : "image with too low size (<1000px)";
    }
  }
};