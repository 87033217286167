import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a2fa398e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "product-push-status"
};
const _hoisted_2 = ["id"];
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "add-sku-btn"
};
const _hoisted_6 = {
  key: 2,
  class: "product-asin-sku-only product-sku-asin-text"
};
const _hoisted_7 = {
  class: "status-item"
};
const _hoisted_8 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_popover = _resolveComponent("app-popover");
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    align: "center",
    class: "border-table-row stick-to-right",
    stickyColumn: "",
    style: _normalizeStyle({
      backgroundColor: `${$props.item.statusPush.color} !important`,
      padding: '12px 8px !important',
      right: $props.pushModal ? '0px' : '36px'
    })
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
      class: "product-asin-sku-only status-icon",
      id: `${$props.item.content_id}-push-status`
    }, [$props.item.statusPush.icon === 'seller-central-sync-status' ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 0,
      class: "tick-icon",
      icon: "tick",
      color: '#2172E1'
    })) : _createCommentVNode("v-if", true), $props.item.statusPush.icon === 'amazon-sync-status' ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 1,
      class: "tick-icon",
      icon: "tick",
      color: '#22863D'
    })) : _createCommentVNode("v-if", true), $props.item.sku ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 2,
      size: "30",
      icon: $props.item.statusPush.icon,
      color: $props.item.statusPush.color
    }, null, 8 /* PROPS */, ["icon", "color"])) : _createCommentVNode("v-if", true), !$props.item.sku ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 3,
      icon: "no-sku"
    })) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_2), $options.isPossibleToPush($props.item) && !$props.pushModal ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", {
      class: "push-btn",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('onConfirmPushSingleContent', $props.item))
    }, [_createVNode(_component_app_icon, {
      icon: "push-button"
    })])])) : _createCommentVNode("v-if", true), !$props.item.sku ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_app_icon, {
      icon: "tool"
    })])])) : _createCommentVNode("v-if", true), !$options.isPossibleToPush($props.item) ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString($props.item.last_push_date), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)]), $props.item.sku ? (_openBlock(), _createBlock(_component_app_popover, {
      key: 0,
      target: `${$props.item.content_id}-push-status`,
      "auto-width": "",
      placement: "left",
      "custom-class": $options.getPushStatusClass($props.item)
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
        innerHTML: $props.item.statusPush.text
      }, null, 8 /* PROPS */, _hoisted_8)])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["target", "custom-class"])) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style"]);
}