export default {
  name: "TextValue",
  props: {
    value: {
      type: [String, null],
      required: true
    },
    size: {
      type: [Number, String],
      default: 13
    },
    color: {
      type: String,
      default: "#4d4d4d"
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    main: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getComputedStyle() {
      const main = this.main ? {
        fontWeight: 500,
        fontSize: "14px",
        color: "#4d4d4d",
        lineHeight: "19.6px"
      } : {};
      return {
        fontSize: `${this.size}px`,
        color: this.color,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        height: "100%",
        ...this.styles,
        ...main
      };
    }
  }
};