import { request } from "./index";
const auth = {
  state: {
    token: null,
    is_authenticated: null,
    me: {},
    isOmnyAdmin: false,
    isOmnyUser: false,
    isClientAdmin: false,
    isClientUser: false
  },
  getters: {
    getAuthState: () => allow_manual_redirection => {
      return auth.actions.authStatus({}, allow_manual_redirection);
    },
    getAuthUser: () => async allow_manual_redirection => {
      const data = await auth.actions.authSettings({}, allow_manual_redirection);
      var result = {
        isOmnyAdmin: auth.state.isOmnyAdmin,
        isOmnyUser: auth.state.isOmnyUser,
        isClientAdmin: auth.state.isClientAdmin,
        isClientUser: auth.state.isClientUser
      };
      if (Object.keys(data).length > 0) {
        result = {
          ...result,
          ...{
            isOmnyAdmin: data.roles.includes("Omny Admin"),
            isOmnyUser: data.roles.includes("Omny User"),
            isClientAdmin: data.roles.includes("Client Admin"),
            isClientUser: data.roles.includes("Client User")
          }
        };
      }
      return result;
    },
    isUserInBetaAccessGroup: () => () => {
      return auth.state.isOmnyAdmin || auth.state.isOmnyUser;
    }
  },
  mutations: {
    authStatusReset(state, payload) {
      state = payload;
    },
    authTokenSet(state, payload) {
      state.token = payload;
    },
    authMeSet(state, payload) {
      state.me = payload;
      state.isOmnyAdmin = state.me.roles.includes("Omny Admin");
      state.isOmnyUser = state.me.roles.includes("Omny User");
      state.isClientAdmin = state.me.roles.includes("Client Admin");
      state.isClientUser = state.me.roles.includes("Client User");
    }
  },
  actions: {
    async authStatus({
      commit
    }, allow_manual_redirection) {
      const data = await request("/authenticated", "GET", {}, false, false, "cors", null, allow_manual_redirection);
      // { page: "home", status: "Welcome to Omny"}
      // <! DOCTYPE >
      if (data && data.page === "home" && data.status === "Welcome to Omny") {
        return true;
      }
      return false;
    },
    async authSettings({
      commit
    }, allow_manual_redirection) {
      const data = await request("/user/settings", "GET", {}, false, false, "cors", null, allow_manual_redirection);
      return data;
    },
    async authMe({
      commit
    }) {
      const data = await request("/user/settings");
      if (data) {
        commit("authMeSet", data);
      }
      return data;
    },
    async authLogin({
      commit
    }, payload) {
      const data = await request("/login", "POST", payload, false, false);
      if (data) {
        //commit("authMeSet", data);

        if (data.token) {
          commit("authTokenSet", data.token);
        }
      }
      return data;
    },
    async authLogout({
      commit
    }, payload) {
      const data = await request("/logout", "POST", payload, false, false);
      if (data) {
        let payload = {
          token: null,
          is_authenticated: null,
          me: {},
          isOmnyAdmin: false,
          isOmnyUser: false,
          isClientAdmin: false,
          isClientUser: false
        };
        commit("authStatusReset", payload);
      }
      return data;
    },
    async authCreate(_, payload) {
      const data = await request("/user/create", "POST", payload);
      return data;
    },
    async authRegister(_, payload) {
      const data = await request("/user/edit", "POST", payload);
      return data;
    },
    async authRecovery(_, payload) {
      const data = await request("/user/password-recovery/request", "POST", payload, true, false);
      return data;
    },
    async authUpdate({
      commit
    }, payload) {
      const data = await request("/user/edit", "POST", payload);
      if (data) {
        commit("authMeSet", payload);
      }
      return data;
    },
    async authPassword(_, payload) {
      const data = await request("/user/p/edit", "POST", payload);
      return data;
    },
    async resetPassword(_, payload) {
      const data = await request("/user/password-reset", "POST", payload);
      return data;
    }
  }
};
export default auth;