import { truncateString } from "../../utils";
import FilterItem from "./FilterItem.vue";
import MarketplaceFilterBody from "./bodies/MarketplaceFilterBody.vue";
import { MarketplaceValue } from "@/components/tableElements";
export default {
  name: "MarketplaceFilter",
  components: {
    FilterItem,
    MarketplaceFilterBody,
    MarketplaceValue
  },
  emits: ["apply", "cleared"],
  props: {
    marketplaces: {
      type: Object,
      required: true
    },
    onlyMode: {
      type: Boolean,
      default: false
    },
    select_key: {
      type: String,
      default: "sales_channel"
    },
    search_key: {
      type: Array,
      default: ["sales_channel"]
    }
  },
  computed: {
    getSelectedMarketplaces() {
      const joinedMarketplaces = this.marketplaces.selected?.map(marketplace => {
        const code = marketplace.code.toLowerCase();
        if (code === "gb") return "uk";
        return code;
      }).join(", ");
      return truncateString(joinedMarketplaces, 20);
    },
    getMarketplace() {
      return this.marketplaces.selected?.[0];
    }
  },
  methods: {
    onSelect($event) {
      if (this.onlyMode) {
        this.marketplaces.selectOnly($event, this.select_key);
      } else {
        this.marketplaces.select($event, this.select_key);
      }
    },
    onSelectOnly($event) {
      this.marketplaces.selectOnly($event, this.select_key);
    },
    onDeSelect($event) {
      this.marketplaces.deselect($event, this.select_key);
      if (this.marketplaces.filterState.selected.length === 0) {
        this.$emit("cleared");
      }
    },
    onClear() {
      this.marketplaces.clear();
      this.$emit("cleared");
    },
    onApply() {
      this.$emit("apply");
    },
    onClose() {
      this.$refs?.marketplaceFilterItem?.closeDropdown;
    },
    onSearch($event) {
      this.marketplaces.listSearch($event.value, [...this.search_key, "marketplace_id"]);
    }
  }
};