import { mapActions } from "vuex";
import AppCheckbox from "@/components/AppCheckbox";
import AppIcon from "@/components/AppIcon";
import AppPopover from "@/components/AppPopover";
import CogsDataRow from "@/components/CogsDataRow";
import { EVENTS, isObjectEmpty } from "@/utils";
export default {
  name: "CogsDataContent",
  emits: ["select", "sort", "update", "refresh-page-on-update"],
  components: {
    AppCheckbox,
    AppIcon,
    AppPopover,
    CogsDataRow
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    sortField: {
      type: String,
      default: "product_name"
    },
    sortDirection: {
      type: String,
      default: "asc"
    },
    accountProvider: {
      type: String,
      default: "Amazon Seller Central"
    }
  },
  computed: {},
  data() {
    return {
      toggleAll: false,
      dataLoading: false,
      focusedCell: null
    };
  },
  methods: {
    ...mapActions(["dfUpdateProductCogsData"]),
    onToggleAll() {
      if (this.toggleAll) {
        this.$emit("select", this.items.filter(item => item.orderable).map(item => item.asin));
      } else {
        this.$emit("select", []);
      }
    },
    async onUpdateVendor(item, updatedVendorFields) {
      let editedVendorFields = [];
      if (updatedVendorFields.vendor_sku && !item.sku) {
        editedVendorFields = ['sku', 'vendor_sku'];
        item.sku = updatedVendorFields.vendor_sku;
      }
      item.sku = item.sku || (item.vendor_sku ? String(item.vendor_sku).trim() : "");
      let item_to_update = {
        asin: item.asin,
        sku: item.sku,
        seller_id: item.seller_id,
        currency: item.currency,
        vendor_sku: updatedVendorFields.vendor_sku,
        vendor_code: updatedVendorFields.vendor_code,
        editedFields: editedVendorFields,
        marketplace_id: item.marketplace_id_list?.[0] || ""
      };

      //editedVendorFields.length > 0 && (item_to_update.editedFields = editedVendorFields);

      let data = {
        seller_id: item.seller_id,
        product_data_list: [item_to_update]
      };

      //console.log("[update product cogs data for vendor]")
      //console.log(data);
      await this.updateProductCogsData(data);
    },
    async onUpdate(item, editedField) {
      let editedVendorFields = [];
      if (editedField === 'vendor_sku' && !item.sku) {
        editedVendorFields = ['sku', 'vendor_sku'];
        item.sku = item.vendor_sku;
      }
      item.sku = item.sku || item.vendor_sku;
      let item_to_update = {
        asin: item.asin,
        sku: item.sku,
        seller_id: item.seller_id,
        editedFields: editedVendorFields,
        vendor_code: item.vendor_code ?? "",
        [editedField]: item[editedField],
        ...(editedField !== "currency" && {
          currency: item.currency
        })
      };

      //editedVendorFields.length > 0 && (item_to_update.editedFields = editedVendorFields);

      let data = {
        seller_id: item.seller_id,
        product_data_list: [item_to_update]
      };
      console.log("[update product cogs data]");
      console.log(data);
      await this.updateProductCogsData(data);
    },
    async updateProductCogsData(payload) {
      try {
        this.dataLoading = true;
        let data = await this.$store.dispatch("dfUpdateProductCogsData", payload);
        if (data.count) {
          window.$bus.trigger("showSuccess", {
            visible: true,
            message: data.status
          });
        } else {
          let parseCogsDataUpdateErrorResponse = this.parseCogsDataUpdateErrorResult(data.status);
          let errorSummary = "Cogs data update failed.Error Summary:<br>";
          Object.entries(parseCogsDataUpdateErrorResponse.errorCount).forEach(([errorType, count]) => {
            errorSummary += `${errorType}: ${count} occurrence(s)<br>`;
          });
          window.$bus.trigger("showDanger", {
            visible: true,
            message: errorSummary,
            delay: 6000,
            isHtml: true
          });
        }
      } catch (error) {} finally {
        await window.$bus.trigger(EVENTS.REFRESH_COGS_DATA_PAGE);
        this.dataLoading = false;
      }
    },
    onSelect(item) {
      //this logic is for selecting all checkboxes,this is the old default code
      /* let selected = this.selected.slice();
        if (selected.includes(asin)) {
          selected = selected.filter((item) => item != asin);
        } else {
          selected.push(asin);
        }
        //console.log("[onSelect called from ProductData Content]");
        this.$emit("select", selected); */
      //let selected = this.selected;
      //this code ensures only one check box is selected at a time.
      if (this.selected.length === 1 && this.selected[0] === item.asin) {
        return;
      }
      //console.log("[product data content selected item]");
      //console.log(item);
      this.$emit("select", item);
      this.$emit("save-product-data", item);
    },
    async onExpirationDateRequired(item) {
      let item_to_update = {
        asin: item.asin,
        sku: item.sku,
        seller_id: item.seller_id,
        expiration_date_required: item.expiration_date_required
      };

      //console.log("[product data content expiration date]");
      //console.log(item_to_update);

      let data = {
        seller_id: item.seller_id,
        product_data_list: [item_to_update]
      };
      await this.updateProductData(data);
    },
    onSort(field) {
      const newDirection = field == this.sortField ? this.sortDirection == "asc" ? "desc" : "asc" : "asc";
      const newField = field;
      this.$emit("sort", [newField, newDirection]);
    },
    setFocusedCell(refName) {
      this.focusedCell = refName;
    },
    parseCogsDataUpdateErrorResult(responseData) {
      let data = JSON.parse(responseData);
      const errorCount = {};
      const errors = data.map(({
        attribute,
        error: errorType
      }) => {
        errorCount[errorType] = (errorCount[errorType] || 0) + 1;
        return {
          attribute,
          error: errorType
        };
      });
      //console.log("[file upload parsing error]", errors);
      //console.log("[Error count summary]", errorCount);
      return {
        errors,
        errorCount
      }; // Return both errors and the error count summary
    }
  }
};