import TableBarElement from '@/components/TableBarElement.vue';
import ProdcutSkuAsinItem from '@/components/shared/ProductSkuAsinItem.vue';
import { truncateString } from '@/utils';
import InfiniteBarLoader from '../InfiniteBarLoader.vue';
export default {
  name: 'TableGrapher',
  emits: ['sortTable'],
  props: {
    graphData: {
      type: Array,
      default: () => []
    },
    graphMinMax: {
      type: Object,
      default: () => {}
    },
    graphHeaderDescriptions: {
      type: Array,
      default: () => []
    },
    fetchingData: {
      type: Boolean,
      default: false
    },
    graphHeaders: {
      type: Array,
      default: () => []
    },
    defaultSortBy: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    noProductImage: {
      type: Boolean,
      default: false
    },
    productFullText: {
      type: Boolean,
      default: false
    },
    setStriped: {
      type: Boolean,
      default: false
    },
    breakProductName: {
      type: Boolean,
      default: false
    },
    truncateProductName: {
      type: Boolean,
      default: false
    },
    graphId: {
      type: String,
      default: ''
    },
    currentViewPeriodOptions: {
      type: Object,
      default: () => {
        return {
          options: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }
        };
      }
    },
    sortBy: {
      type: String,
      default: ''
    },
    sortDirection: {
      type: String,
      default: 'desc'
    },
    pageRenderedLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TableBarElement,
    ProdcutSkuAsinItem,
    InfiniteBarLoader
  },
  computed: {
    switchStriped() {
      return this.setStriped;
    }
  },
  methods: {
    getData(field, index, truncate = false, truncateLength = 65) {
      const data = this.graphData[index][field];
      if (data === undefined) return null;
      if (truncate) {
        return truncateString(data, truncateLength);
      }
      return data;
    },
    getMax(field) {
      return this.graphMinMax?.[field]?.max;
    },
    getMin(field) {
      return this.graphMinMax?.[field]?.min;
    },
    formatPeriod(period) {
      if (!period) return '';
      const [year, month, day] = period.split('-');
      const date = new Date(year, month - 1, day);
      const options = this.currentViewPeriodOptions;
      const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date);
      return formattedDate;
    },
    truncateString,
    getHeaderDescription(key) {
      const graphHeaderDescriptions = this.graphHeaderDescriptions.find(item => item.key === key);
      return graphHeaderDescriptions.detail || '';
    },
    getTruncateLength(field) {
      // Based on the selected field, find the shortest length to truncate
      const positionsLengths = this.graphData.map(position => {
        return position[field].length;
      });
      return Math.min(...positionsLengths);
    },
    getCaseColor(field, index) {
      return '#7cb342' + this.toHexNormalized(this.positiveNormalized(field, index));
    },
    getBoolCaseColor(field, index) {
      const data = this.getData(field, index);
      if (data === null) return '#FFFFFF';
      return data ? '#c8e6c9' : '#f4511e';
    },
    getConditionalCaseColor(field, index, upperBound, lowerBound) {
      const data = this.getData(field, index);
      if (data < upperBound && data > lowerBound) {
        return '#fb8500';
      }
      if (data < lowerBound) {
        return '#f4511e';
      }
      return '#FFFFFF';
    },
    positiveNormalized(field, index) {
      const absAmount = Math.abs(parseFloat(this.getData(field, index)));
      const max = this.getMax(field);
      const normalizedAmount = max !== 0 ? absAmount / Math.abs(max) : 0;
      return (normalizedAmount * 100).toFixed(2);
    },
    toHexNormalized(number) {
      // Takes a float and return normalizes it betwen 00 and FF
      const hex = Math.round(parseFloat(number) * 2.55).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    },
    getSalesChannelFlag(code, key) {
      const flag = this.countries.filter(country => {
        return country[key] === code;
      });
      return flag[0]?.icon || '';
    },
    getSortableProperty(header) {
      return Object.keys(header).filter(key => key === 'notSortable').length === 0;
    },
    processHeader(header) {
      return header.name.replaceAll(' ', '&nbsp;');
    },
    getBuyBoxPosition(position) {
      if (position === null) return '-';
      return position ? 'true' : 'false';
    },
    getProductNameTruncateLength(truncateLength) {
      if (this.truncateProductName) {
        return 53;
      }
      return truncateLength;
    }
  }
};