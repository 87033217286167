import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c65a1e20"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "custom-control custom-checkbox"
};
const _hoisted_2 = ["id", "checked"];
const _hoisted_3 = ["for"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    align: "center",
    stickyColumn: "true"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("input", {
      type: "checkbox",
      class: "custom-control-input",
      id: 'checkbox' + _ctx.index,
      checked: _ctx.$emit('isSelectedItem', $props.item),
      onChange: _cache[0] || (_cache[0] = $event => _ctx.$emit('selectItem', {
        $event,
        item: $props.item
      }))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2), _createElementVNode("label", {
      class: "custom-control-label",
      for: 'checkbox' + _ctx.index
    }, null, 8 /* PROPS */, _hoisted_3)])]),
    _: 1 /* STABLE */
  });
}