import Icons from "@/components/icons";
export default {
  name: "NavigationTabs",
  components: {
    ...Icons
  },
  emits: ["update:modelValue"],
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => [{
        text: "Tab 1",
        activeColor: "red",
        value: "tab1"
      }]
    },
    modelValue: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentTab: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    },
    cssProps() {
      return {
        "--active-bg": this.tabs[this.currentTab].activeBg,
        "--active-color": this.tabs[this.currentTab].activeColor,
        "--hover-bg-color": `${this.tabs[this.currentTab].activeBg}19`
      };
    }
  }
};