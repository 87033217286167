import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "custom-dropdown-wrapper"
};
const _hoisted_2 = {
  class: "d-flex align-items-center btn-content"
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_filter_counter = _resolveComponent("filter-counter");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_CustomInput = _resolveComponent("CustomInput");
  const _component_selected_items = _resolveComponent("selected-items");
  const _component_filter_item_sub_section = _resolveComponent("filter-item-sub-section");
  const _component_dropdown_body = _resolveComponent("dropdown-body");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  const _component_b_form_group = _resolveComponent("b-form-group");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_b_form_group, {
    state: $options.selectedState,
    "invalid-feedback": $props.validateErrorMessage,
    "label-for": $data.id
  }, {
    default: _withCtx(() => [_createVNode(_component_b_dropdown, {
      size: "sm",
      "no-caret": "",
      variant: "outline-secondary",
      text: $props.filter?.titleName,
      class: _normalizeClass(["custom-dropdown filter-item", {
        activate: $props.filter?.selected?.length > 0
      }]),
      onHidden: $options.handleHidden
    }, {
      "button-content": _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        id: "filter-name",
        innerHTML: $props.filter?.titleName
      }, null, 8 /* PROPS */, _hoisted_3), $options.selectedCount > 0 ? (_openBlock(), _createBlock(_component_filter_counter, {
        key: 0,
        count: $options.selectedCount
      }, null, 8 /* PROPS */, ["count"])) : _createCommentVNode("v-if", true), _createVNode(_component_font_awesome_icon, {
        icon: "fa-angle-down",
        class: "icon"
      })])]),
      default: _withCtx(() => [_createVNode(_component_dropdown_body, null, {
        default: _withCtx(() => [_createCommentVNode(" For now, we use CustomInput for the search bar, but this will be replaced by a newer and better component "), _withDirectives(_createVNode(_component_CustomInput, {
          placeholder: $props.filter?.searchPlaceholder || '',
          onSearch: $options.innerHandleSearch,
          loading: $props.loading,
          searchValue: $data.searchValue,
          disabled: $data.searchValue.length === 0 && $props.filter.values.length === 0,
          onClear: $options.clear
        }, null, 8 /* PROPS */, ["placeholder", "onSearch", "loading", "searchValue", "disabled", "onClear"]), [[_vShow, $props.filter.showSearch]]), $props.selected.length > 0 ? (_openBlock(), _createBlock(_component_selected_items, {
          key: 0,
          onRefresh: $options.handleRefresh,
          activateRefresh: $data.filterClicked,
          onDeselect: $options.handleDeselect,
          selected: $props.selected,
          showIcon: $props.filter.showIcon,
          onClear: $options.handleClear,
          filterTitle: $props.filterTitle
        }, null, 8 /* PROPS */, ["onRefresh", "activateRefresh", "onDeselect", "selected", "showIcon", "onClear", "filterTitle"])) : _createCommentVNode("v-if", true), _createVNode(_component_filter_item_sub_section, {
          values: $props.filter.values,
          header: $props.filter.titleName,
          onRefresh: _cache[0] || (_cache[0] = $event => _ctx.$emit('triggerRefresh')),
          onSelect: $options.handleSelect,
          showIcon: $props.filter?.showIcon || false,
          enableSelectAll: $data.searchValue.length >= 3,
          filterTitle: $props.filterTitle
        }, null, 8 /* PROPS */, ["values", "header", "onSelect", "showIcon", "enableSelectAll", "filterTitle"])]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["text", "onHidden", "class"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["state", "invalid-feedback", "label-for"])]);
}