import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4b0a529f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper",
  style: {
    margin: '0.2rem 0.3rem'
  }
};
const _hoisted_2 = {
  class: "left-icon"
};
const _hoisted_3 = ["placeholder", "value"];
const _hoisted_4 = {
  class: "right-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_spinner = _resolveComponent("b-spinner");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_font_awesome_icon, {
    icon: "fa-search"
  })]), _createElementVNode("input", {
    placeholder: $props.placeholder,
    type: "search",
    minlength: "3",
    value: $props.searchValue,
    onInput: _cache[0] || (_cache[0] = $event => $options.onInput($event)),
    pattern: ".*\\S.*"
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3), _createElementVNode("div", _hoisted_4, [$props.loading ? (_openBlock(), _createBlock(_component_b_spinner, {
    key: 0,
    small: ""
  })) : !$props.loading && $props.searchValue.length > 0 ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
    key: 1,
    style: {
      cursor: 'pointer'
    },
    icon: "fa-circle-xmark",
    onClick: _cache[1] || (_cache[1] = $event => this.$emit('clear'))
  })) : _createCommentVNode("v-if", true)])]);
}