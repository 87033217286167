import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1900044e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "custom-input-description"
};
const _hoisted_2 = {
  key: 0,
  class: "append"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_b_input_group_prepend = _resolveComponent("b-input-group-prepend");
  const _component_b_form_input = _resolveComponent("b-form-input");
  const _component_app_button = _resolveComponent("app-button");
  const _component_b_input_group_append = _resolveComponent("b-input-group-append");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_b_form_group = _resolveComponent("b-form-group");
  return _openBlock(), _createBlock(_component_b_form_group, {
    label: $props.label,
    state: $options.inputState,
    "invalid-feedback": _ctx.mValidateMessage('value'),
    "label-for": $data.id,
    class: _normalizeClass(['form-group', 'description-class', 'label-style', {
      v2: $props.v2,
      appendSimple: $props.appendSimple
    }, $props.version])
  }, {
    description: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString($props.description), 1 /* TEXT */)]),
    default: _withCtx(() => [_createVNode(_component_b_input_group, {
      prepend: $props.prepend,
      append: $props.append,
      class: _normalizeClass({
        'password-group': this.type == 'password',
        'error-border': $props.hasError
      })
    }, {
      default: _withCtx(() => [$props.prependIcon ? (_openBlock(), _createBlock(_component_b_input_group_prepend, {
        key: 0,
        "is-text": "",
        class: _normalizeClass($props.prependSimple ? 'simple' : null)
      }, {
        default: _withCtx(() => [_createVNode(_component_app_icon, {
          icon: $props.prependIcon,
          size: 20,
          color: "version == 'ba-search' ? '#A5AAB0' : '#757575'"
        }, null, 8 /* PROPS */, ["icon"])]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true), _createVNode(_component_b_form_input, _mergeProps({
        modelValue: $setup.v$.value.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.v$.value.$model = $event),
        class: [{
          readonly: $props.readonly,
          icon: $props.icon,
          'custom-input-style': true
        }, $props.version],
        type: $options.computedType,
        placeholder: $props.placeholder,
        required: $props.required,
        readonly: $props.readonly,
        state: $options.inputState,
        id: $data.id,
        debounce: $props.debounce,
        onKeydown: _withKeys($options.onSubmit, ["enter"])
      }, _ctx.$attrs), null, 16 /* FULL_PROPS */, ["modelValue", "class", "type", "placeholder", "required", "readonly", "state", "id", "debounce", "onKeydown"]), this.type == 'password' ? (_openBlock(), _createBlock(_component_b_input_group_append, {
        key: 1
      }, {
        default: _withCtx(() => [_createVNode(_component_app_button, {
          variant: "outline-light",
          onClick: $options.onPasswordShowToggle,
          "no-min-width": "",
          class: _normalizeClass({
            'password-toggle-border': true,
            'error-border': $options.showErrorBorder
          })
        }, {
          default: _withCtx(() => [_createVNode(_component_app_icon, {
            icon: $data.isPasswordShown ? 'eye-slash' : 'eye'
          }, null, 8 /* PROPS */, ["icon"])]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick", "class"])]),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["prepend", "append", "class"]), $props.icon ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_app_icon, {
      icon: $props.icon
    }, null, 8 /* PROPS */, ["icon"])])) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["label", "state", "invalid-feedback", "label-for", "class"]);
}