import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-729cfea8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "match-type-value",
  gap: 8
};
const _hoisted_2 = {
  class: "icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_target_icon = _resolveComponent("target-icon");
  const _component_brackets_plus_icon = _resolveComponent("brackets-plus-icon");
  const _component_brackets_check_icon = _resolveComponent("brackets-check-icon");
  const _component_zoom_out_icon = _resolveComponent("zoom-out-icon");
  const _component_message_square_icon = _resolveComponent("message-square-icon");
  const _component_equals_icon = _resolveComponent("equals-icon");
  const _component_text_value = _resolveComponent("text-value");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.type == 'EXACT' ? (_openBlock(), _createBlock(_component_target_icon, {
    key: 0,
    width: 20,
    height: 20
  })) : $props.type == 'TARGETING_EXPRESSION' ? (_openBlock(), _createBlock(_component_brackets_plus_icon, {
    key: 1,
    width: 20,
    height: 20
  })) : $props.type == 'TARGETING_EXPRESSION_DEFINED' ? (_openBlock(), _createBlock(_component_brackets_check_icon, {
    key: 2,
    width: 20,
    height: 20
  })) : $props.type == 'BROAD' ? (_openBlock(), _createBlock(_component_zoom_out_icon, {
    key: 3,
    width: 20,
    height: 20
  })) : $props.type == 'PHRASE' ? (_openBlock(), _createBlock(_component_message_square_icon, {
    key: 4,
    width: 20,
    height: 20
  })) : (_openBlock(), _createBlock(_component_equals_icon, {
    key: 5,
    width: 20,
    height: 20
  }))]), _createVNode(_component_text_value, {
    class: "name",
    value: $props.name,
    main: ""
  }, null, 8 /* PROPS */, ["value"])]);
}