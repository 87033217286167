import { FLOATING_COMPOUND_TABLE_STATES, APP_PERIOD_DROPDOWN_ELEMENTS, GROUP_BY_TAB_ELEMENTS } from "@/utils/constants";
import moment from "moment";
import { request } from "@/store";
import Filter from "./filter";
import { computeDetailsForKpis, computeCurrencyMaxCharCount, computeNumberMaxCharCount, computePercentageMaxCharCount } from "@/utils/transformers";
import { computeMinMax, transformWithCountries } from "@/utils";
import { DATE_PICKER_RANGE_TITLES } from "@/utils/datepicker";
const DEFAULT_EMPTY_ROW_SIZE = 20;
export default {
  state: {
    sellers: new Filter([], [], "id"),
    marketplaces: new Filter([], [], "sales_channel"),
    product_groups: new Filter([], [], "campaign_group_name"),
    products: new Filter([], [], "products"),
    campaigns: new Filter([], [], "campaign_id", undefined, undefined, undefined, false),
    portfolios: new Filter([], [], "portfolio_name", undefined, undefined, undefined, false),
    ad_formats: new Filter([], [], "ad_format", undefined, undefined, undefined, false),
    targetings: new Filter([], [], "targeting", undefined, undefined, undefined, false),
    match_types: new Filter([], [], "match_type", undefined, undefined, undefined, false),
    currentTab: 0,
    currentTabValue: "performance",
    tabs: [{
      text: "Performance",
      value: "performance",
      activeBg: "#E8EBF3",
      activeColor: "#484C51",
      icon: "StrokedPercentIcon"
    }, {
      text: "Strategic",
      value: "strategic",
      activeBg: "#F3EEFF",
      activeColor: "#423994",
      icon: "StrokedStarIcon"
    }, {
      text: "Settings",
      value: "settings",
      activeBg: "#F4F4F4",
      activeColor: "#4D4D4D",
      icon: "SettingsIcon"
    }],
    betatabs: [{
      text: "Performance",
      value: "performance",
      activeBg: "#E8EBF3",
      activeColor: "#484C51",
      icon: "StrokedPercentIcon"
    }, {
      text: "Strategic",
      value: "strategic",
      activeBg: "#F3EEFF",
      activeColor: "#423994",
      icon: "StrokedStarIcon"
    }],
    kpis: {
      CONSIDERED_KPIS: [{
        key: "sponsored_sales",
        name: "sponsored sales",
        subtitle: {
          name: "units sold",
          key: "units_sold_clicks_14_d"
        }
      }, {
        key: "spend",
        name: "spend",
        subtitle: {
          name: "clicks",
          key: "clicks"
        }
      }, {
        key: "TACOS",
        name: "TACOS",
        subtitle: {
          name: "ROAS",
          key: "ROAS"
        }
      }, {
        key: "CVR",
        name: "CVR",
        subtitle: {
          name: "CPC",
          key: "CPC"
        }
      }],
      data: []
    },
    period: APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value,
    periodSelectorOptions: [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY, APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY, APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY, APP_PERIOD_DROPDOWN_ELEMENTS.YEARLY],
    currentPeriod: {
      [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value]: {
        title: "Week-to-date vs Same period Last Week",
        format: "MMM D, YYYY",
        date: moment().startOf("isoWeek"),
        groupBy: DATE_PICKER_RANGE_TITLES.CURRENT_WEEK,
        groupByCompare: {
          title: "Preceding Period",
          subtitle: "(match to date)"
        }
      },
      [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY.value]: {
        title: "Yesterday vs Same day Last Week",
        format: "MMM DD, YYYY",
        date: moment().subtract(1, "days"),
        groupBy: DATE_PICKER_RANGE_TITLES.YESTERDAY,
        groupByCompare: {
          title: "Preceding Period",
          subtitle: "(match to date)"
        }
      },
      [APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value]: {
        title: "Month-to-date vs Same period Last Month",
        format: "MMM YYYY",
        date: moment().startOf("month"),
        groupBy: DATE_PICKER_RANGE_TITLES.CURRENT_MONTH,
        groupByCompare: {
          title: "Preceding Period",
          subtitle: "(match to date)"
        }
      },
      [APP_PERIOD_DROPDOWN_ELEMENTS.YEARLY.value]: {
        title: "Current year vs previous year",
        format: "YYYY",
        date: moment().startOf("year"),
        groupBy: DATE_PICKER_RANGE_TITLES.CURRENT_YEAR,
        groupByCompare: {
          title: 'Preceding Period',
          subtitle: '(match to date)'
        }
      }
    },
    periodTable: {
      strategic: {
        items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
        maxCharCount: {},
        minMax: {},
        title: {
          [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY.value]: "Last 14 days",
          [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value]: "Last 12 weeks",
          [APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value]: "Last 12 months"
        }
      },
      performance: {
        items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
        maxCharCount: {},
        minMax: {},
        title: {
          [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY.value]: "Last 14 days",
          [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value]: "Last 12 weeks",
          [APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value]: "Last 12 months"
        }
      }
    },
    product_table: {
      state: FLOATING_COMPOUND_TABLE_STATES.CLOSED,
      group_by: 0,
      group_by_text: "",
      group_by_elements: [],
      strategic: {
        period: null,
        data: {
          seller: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedBySellers"
          },
          marketplace: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByMarketplaces"
          },
          product: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByProducts"
          },
          campaign_group: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByCampaignGroups"
          },
          campaign: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByCampaigns"
          },
          targeting: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByTargetings"
          },
          ad_format: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByAdFormats"
          }
        }
      },
      performance: {
        period: null,
        data: {
          seller: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedBySellers"
          },
          marketplace: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByMarketplaces"
          },
          product: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByProducts"
          },
          campaign_group: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByCampaignGroups"
          },
          campaign: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByCampaigns"
          },
          targeting: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByTargetings"
          },
          ad_format: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              sponsored_sales: "desc",
              spend: "desc"
            },
            fetch: "ppcV2FetchReportGroupedByAdFormats"
          }
        }
      }
    }
  },
  mutations: {
    ppcSettingsSellersSet(state, payload) {
      state.sellers = payload;
    },
    ppcSettingsMarketplacesSet(state, payload) {
      state.marketplaces = payload;
    },
    ppcV2SetTab(state, payload) {
      state.currentTab = payload;
      state.currentTabValue = state.tabs[payload].value;
    },
    ppcV2SetPeriod(state, payload) {
      state.period = payload;
    },
    ppcV2SetPeriodTableItems(state, payload) {
      state.periodTable["strategic"].items = payload;
      state.periodTable["performance"].items = payload;
    },
    ppcV2SetPeriodTableMaxCharCount(state, payload) {
      state.periodTable["strategic"].maxCharCount = payload;
      state.periodTable["performance"].maxCharCount = payload;
    },
    ppcV2SetPeriodTableMinMax(state, payload) {
      state.periodTable["strategic"].minMax = payload;
      state.periodTable["performance"].minMax = payload;
    },
    ppcV2SetKpisData(state, payload) {
      state.kpis.data = payload;
    },
    ppcV2SetProductTableGroupByElements(state, payload) {
      state.product_table.group_by_elements = payload;
    },
    ppcV2SetProductTableState(state, payload) {
      state.product_table.state = payload;
    },
    ppcV2SetProductTablePeriod(state, payload) {
      state.product_table["strategic"].period = payload;
      state.product_table["performance"].period = payload;
    },
    ppcV2SetProductTableItems(state, payload) {
      state.product_table["strategic"].data[state.product_table.group_by_text].items = payload;
      state.product_table["performance"].data[state.product_table.group_by_text].items = payload;
    },
    ppcV2SetProductTableGroupBy(state, payload) {
      state.product_table.group_by = payload;
      state.product_table.group_by_text = state.product_table.group_by_elements[payload].key;
    },
    ppcV2SetProductTableSort(state, {
      sort,
      direction
    }) {
      state.product_table["strategic"].data[state.product_table.group_by_text].sort = {
        [sort]: direction
      };
      state.product_table["performance"].data[state.product_table.group_by_text].sort = {
        [sort]: direction
      };
    },
    ppcV2SetProductTableMaxCharCount(state, payload) {
      state.product_table["strategic"].data[state.product_table.group_by_text].maxCharCount = payload;
      state.product_table["performance"].data[state.product_table.group_by_text].maxCharCount = payload;
    },
    ppcV2SetProductTableMinMax(state, payload) {
      state.product_table["strategic"].data[state.product_table.group_by_text].minMax = payload;
      state.product_table["performance"].data[state.product_table.group_by_text].minMax = payload;
    },
    ppcV2SetProductTableTotal(state, payload) {
      state.product_table["strategic"].data[state.product_table.group_by_text].total = payload;
      state.product_table["performance"].data[state.product_table.group_by_text].total = payload;
    },
    ppcV2SetProductTableTotalPages(state, payload) {
      state.product_table["strategic"].data[state.product_table.group_by_text].totalPages = payload;
      state.product_table["performance"].data[state.product_table.group_by_text].totalPages = payload;
    },
    ppcV2SetProductTablePage(state, payload) {
      state.product_table["strategic"].data[state.product_table.group_by_text].page = payload;
      state.product_table["performance"].data[state.product_table.group_by_text].page = payload;
    },
    ppcV2SetProductTablePerPage(state, payload) {
      state.product_table["strategic"].data[state.product_table.group_by_text].perPage = payload;
      state.product_table["performance"].data[state.product_table.group_by_text].perPage = payload;
    }
  },
  getters: {
    ppcV2GetPeriod(state) {
      return () => state.period;
    },
    ppcV2GetFilters(state) {
      return () => {
        return {
          seller_id: state.sellers.filterValues,
          sales_channel: state.marketplaces.filterValues,
          campaigns_list: state.campaigns.selectedValues,
          keyword_id_list: state.targetings.selectedValues.flat(),
          match_type_list: state.match_types.selectedValues,
          sku_list: state.products.selected.map(product => product.sku).filter(Boolean),
          asin_list: state.products.selected.map(product => product.asin).filter(Boolean),
          ad_format_list: state.ad_formats.selectedValues,
          portfolio_id_list: state.portfolios.selected.flatMap(({
            portfolio_id_list
          }) => portfolio_id_list)
        };
      };
    },
    ppcV2SecondaryFiltersEnabled(_, getters) {
      return () => {
        const {
          campaigns_list,
          keyword_id_list,
          match_type_list,
          ad_format_list,
          portfolio_id_list
        } = getters.ppcV2GetFilters();
        return [campaigns_list, match_type_list, keyword_id_list, ad_format_list, portfolio_id_list].reduce((acc, curr) => acc || curr.length > 0, false);
      };
    },
    ppcV2GetAccountState(state) {
      return () => {
        const res = {
          sellers: state.sellers.defaultList,
          marketplaces: state.marketplaces.defaultList
        };
        return res;
      };
    },
    ppcV2GetProductTableGroupBy(state) {
      return () => state.product_table.group_by;
    },
    ppcV2GetProductTableSort(state) {
      return () => {
        let obj = {
          sort_by: [],
          sort_direction: []
        };
        for (const [key, value] of Object.entries(state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.sort)) {
          obj["sort_by"].push(key);
          obj["sort_direction"].push(value);
        }
        return obj;
      };
    },
    ppcV2GetProductTablePage(state) {
      return () => state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.page;
    },
    ppcV2GetProductTablePerPage(state) {
      return () => state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.perPage;
    },
    ppcV2GetProductTableTotalPages(state) {
      return () => state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.totalPages;
    },
    ppcV2GetProductTablePager(state) {
      return () => ({
        page: state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.page,
        page_size: state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.perPage
      });
    },
    ppcV2SecondaryFields(_, getters) {
      return () => getters.ppcV2SecondaryFiltersEnabled() ? 'TARGETING_FIELDS' : 'DEFAULT_FIELDS';
    }
  },
  actions: {
    ppcV2SetTab({
      commit
    }, tab) {
      commit("ppcV2SetTab", tab);
    },
    ppcV2SetPeriod({
      commit
    }, period) {
      commit("ppcV2SetPeriod", period);
    },
    async ppcV2FetchKpis({
      commit,
      getters,
      state
    }) {
      try {
        const params = {
          period: state.period,
          ...getters.ppcV2GetFilters(),
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        let response = await request("/ppc/v2/current_period/kpis", "POST", params, false, true, "cors");
        if (response) {
          let data = response.data;
          data = data.kpis.pop();
          commit("ppcV2SetKpisData", computeDetailsForKpis(data, state.kpis.CONSIDERED_KPIS));
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async ppcV2FetchPeriodTableData({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        const {
          store = true
        } = options;
        const params = {
          period: state.period,
          ...getters.ppcV2GetFilters(),
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/ppc/v2/global/evolution", "POST", params, false, true, "cors");
        if (!response) {
          return [];
        }
        let data = response.data.graph;

        // Compute and store the minMax object
        if (data && store) {
          commit("ppcV2SetPeriodTableItems", data);
          commit("ppcV2SetPeriodTableMaxCharCount", {
            currency: computeCurrencyMaxCharCount(data, full, currency, precision, showCurrencySign, false, limit),
            number: computeNumberMaxCharCount(data),
            percentage: computePercentageMaxCharCount(data)
          });
          commit("ppcV2SetPeriodTableMinMax", computeMinMax(data, ["period_agg"]));
        }
        return response;
      } catch (err) {
        //console.log(err);
      }
    },
    ppcV2SetProductTableGroupByElements({
      commit,
      getters
    }) {
      const {
        sellers,
        marketplaces
      } = getters.ppcV2GetAccountState();
      const {
        PRODUCT,
        CAMPAIGN_GROUP,
        CAMPAIGN,
        TARGETING,
        SELLER,
        MARKETPLACE,
        AD_FORMAT
      } = GROUP_BY_TAB_ELEMENTS;
      const defaultElements = [PRODUCT, AD_FORMAT, CAMPAIGN_GROUP, CAMPAIGN, TARGETING];
      if (sellers.length > 1 && marketplaces.length > 1) {
        commit("ppcV2SetProductTableGroupByElements", [SELLER, MARKETPLACE, ...defaultElements]);
        return;
      } else if (marketplaces.length > 1) {
        commit("ppcV2SetProductTableGroupByElements", [MARKETPLACE, ...defaultElements]);
        return;
      }
      commit("ppcV2SetProductTableGroupByElements", defaultElements);
    },
    ppcV2SetSecondaryFiltersProductTableGroupByElements({
      commit
    }) {
      const {
        CAMPAIGN_GROUP,
        CAMPAIGN,
        TARGETING,
        AD_FORMAT
      } = GROUP_BY_TAB_ELEMENTS;
      commit("ppcV2SetProductTableGroupByElements", [AD_FORMAT, CAMPAIGN_GROUP, CAMPAIGN, TARGETING]);
    },
    ppcV2SetProductTableState({
      commit
    }, payload) {
      commit("ppcV2SetProductTableState", payload);
    },
    ppcV2SetProductTablePeriod({
      commit
    }, period) {
      commit("ppcV2SetProductTablePeriod", period);
    },
    ppcV2SetProductTableGroupBy({
      commit
    }, groupBy) {
      commit("ppcV2SetProductTableGroupBy", groupBy);
    },
    async ppcV2FetchReportGroupedBySellers({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.ppcV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...pager,
          ...getters.ppcV2GetFilters(),
          ...getters.ppcV2GetProductTableSort(),
          ...getters.currencyConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/ppc/v2/report/sellers", "POST", params, false, true, "cors");
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          const {
            result
          } = report;
          if (store) {
            commit("ppcV2SetProductTableTotalPages", total?.[0]?.row_count ?? 0);
            commit("ppcV2SetProductTableItems", result);
            commit("ppcV2SetProductTableMaxCharCount", {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit("ppcV2SetProductTableTotal", total?.pop() ?? {});
            commit("ppcV2SetProductTableMinMax", computeMinMax(result));
          }
        }
        return response;
      } catch (error) {
        //console.log(error);
      }
    },
    async ppcV2FetchReportGroupedByMarketplaces({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.ppcV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.ppcV2GetFilters(),
          ...getters.ppcV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/ppc/v2/report/marketplace", "POST", params, false, true, "cors");
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            result
          } = report;
          result = await transformWithCountries(result);
          if (store) {
            commit("ppcV2SetProductTableTotalPages", total?.[0]?.row_count ?? 0);
            commit("ppcV2SetProductTableItems", result);
            commit("ppcV2SetProductTableMaxCharCount", {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit("ppcV2SetProductTableTotal", total?.pop() ?? {});
            commit("ppcV2SetProductTableMinMax", computeMinMax(result));
          }
        }
        return response;
      } catch (error) {
        //console.log(error);
      }
    },
    async ppcV2FetchReportGroupedByProducts({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.ppcV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.ppcV2GetFilters(),
          ...getters.ppcV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/ppc/v2/report/product", "POST", params, false, true, "cors");
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          const {
            result
          } = report;
          if (store) {
            commit("ppcV2SetProductTableTotalPages", total?.[0]?.row_count ?? 0);
            commit("ppcV2SetProductTableItems", result);
            commit("ppcV2SetProductTableMaxCharCount", {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit("ppcV2SetProductTableTotal", total?.pop() ?? {});
            commit("ppcV2SetProductTableMinMax", computeMinMax(result));
          }
        }
        return response;
      } catch (error) {
        //console.log(error);
      }
    },
    async ppcV2FetchReportGroupedByCampaignGroups({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.ppcV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.ppcV2GetFilters(),
          ...getters.ppcV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/ppc/v2/report/product_group", "POST", params, false, true, "cors");
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            result
          } = report;
          result = await transformWithCountries(result);
          if (store) {
            commit("ppcV2SetProductTableTotalPages", total?.[0]?.row_count ?? 0);
            commit("ppcV2SetProductTableItems", result);
            commit("ppcV2SetProductTableMaxCharCount", {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit("ppcV2SetProductTableTotal", total?.pop() ?? {});
            commit("ppcV2SetProductTableMinMax", computeMinMax(result));
          }
        }
        return response;
      } catch (error) {
        //console.log(error);
      }
    },
    async ppcV2FetchReportGroupedByCampaigns({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.ppcV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.ppcV2GetFilters(),
          ...getters.ppcV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/ppc/v2/report/campaign", "POST", params, false, true, "cors");
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            result
          } = report;
          result = await transformWithCountries(result);
          if (store) {
            commit("ppcV2SetProductTableTotalPages", total?.[0]?.row_count ?? 0);
            commit("ppcV2SetProductTableItems", result);
            commit("ppcV2SetProductTableMaxCharCount", {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit("ppcV2SetProductTableTotal", total?.pop() ?? {});
            commit("ppcV2SetProductTableMinMax", computeMinMax(result));
          }
        }
        return response;
      } catch (error) {
        //console.log(error);
      }
    },
    async ppcV2FetchReportGroupedByTargetings({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.ppcV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.ppcV2GetFilters(),
          ...getters.ppcV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/ppc/v2/report/targeting", "POST", params, false, true, "cors");
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          const {
            result
          } = report;
          if (store) {
            commit("ppcV2SetProductTableTotalPages", total?.[0]?.row_count ?? 0);
            commit("ppcV2SetProductTableItems", result);
            commit("ppcV2SetProductTableMaxCharCount", {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit("ppcV2SetProductTableTotal", total?.pop() ?? {});
            commit("ppcV2SetProductTableMinMax", computeMinMax(result));
          }
        }
        return response;
      } catch (error) {
        //console.log(error);
      }
    },
    async ppcV2FetchReportGroupedByAdFormats({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.ppcV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.ppcV2GetFilters(),
          ...getters.ppcV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/ppc/v2/report/ad_format", "POST", params, false, true, "cors");
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          const {
            result
          } = report;
          if (store) {
            commit("ppcV2SetProductTableTotalPages", total?.[0]?.row_count ?? 0);
            commit("ppcV2SetProductTableItems", result);
            commit("ppcV2SetProductTableMaxCharCount", {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit("ppcV2SetProductTableTotal", total?.pop() ?? {});
            commit("ppcV2SetProductTableMinMax", computeMinMax(result));
          }
        }
        return response;
      } catch (error) {
        //console.log(error);
      }
    },
    ppcV2SetProductTablePage({
      commit
    }, page) {
      commit("ppcV2SetProductTablePage", page);
    },
    ppcV2SetProductTablePerPage({
      commit
    }, perPage) {
      commit("ppcV2SetProductTablePerPage", perPage);
    },
    ppcV2SetProductTableSort({
      commit
    }, payload) {
      commit("ppcV2SetProductTableSort", payload);
    },
    ppcV2SetProductTableTotalPages({
      commit
    }, payload) {
      commit("ppcV2SetProductTableTotalPages", payload);
    }
  }
};