import AppIcon from "./AppIcon.vue";
import { copyToClipboard } from "@/components/shared/helpers";
import FlexRow from "./FlexRow.vue";
export default {
  name: "WrapWithCopy",
  components: {
    AppIcon,
    FlexRow
  },
  props: {
    size: {
      type: Number,
      default: 12
    }
  },
  data() {
    return {
      copyIcon: "copy-v2",
      isClicked: false
    };
  },
  methods: {
    handleClick(e) {
      e.stopPropagation();
      copyToClipboard(this.$refs?.copiee.$el.innerText);
      this.$emit("copied");
      this.isClicked = true;
      this.copyIcon = "is-copied";
      setTimeout(() => {
        this.isClicked = false;
      }, 1000);
    },
    onHover() {
      if (!this.isClicked) {
        this.copyIcon = "copy-hovered";
      }
    },
    onLeave() {
      if (!this.isClicked) {
        this.copyIcon = "copy-v2";
      }
    }
  }
};