import { truncateString } from "@/utils";
import FilterItem from "./FilterItem.vue";
import PortfolioFilterBody from "./bodies/PortfolioFilterBody.vue";
export default {
  name: "PortfolioFilter",
  components: {
    FilterItem,
    PortfolioFilterBody
  },
  emits: ["apply", "cleared"],
  expose: ["openDropdown"],
  props: {
    portfolios: {
      type: Object,
      required: true
    },
    sellers: {
      type: Object,
      required: true
    },
    marketplaces: {
      type: Object,
      required: true
    }
  },
  computed: {
    getPortfolioTitle() {
      let joinedContents = this.portfolios.selected.map(el => el?.portfolio_name).join(", ");
      joinedContents = joinedContents.length > 0 ? joinedContents : 'Portfolios';
      return truncateString(joinedContents, 16);
    }
  },
  methods: {
    handlePortfolioSearch({
      value
    }) {
      // If value is empty, clear the searching state
      if (value.length === 0) {
        this.portfolios.searching = false;
      }
      this.portfolios.asyncSearch({
        keyword: value,
        args: {
          sellers: this.sellers.filterValues,
          sales_channel: this.marketplaces.filterValues
        }
      });
    },
    handleClear() {
      this.portfolios.clear();
      this.$emit('apply');
    },
    handleSelect({
      portfolio_id
    }) {
      this.portfolios.select({
        portfolio_id
      }, 'portfolio_id');
    },
    handleSelectOnly({
      portfolio_id
    }) {
      //console.log("Only", portfolio_id)
      this.portfolios.selectOnly({
        portfolio_id
      }, 'portfolio_id');
    },
    handleDeselect({
      portfolio_id
    }) {
      this.portfolios.deselect({
        portfolio_id
      }, 'portfolio_id');
      this.$emit('apply');
    },
    handleClearAll() {
      this.portfolios.clear();
      this.$emit('cleared');
    },
    openDropdown() {
      this.$nextTick(() => {
        const innerFilterItem = this.$refs.portfolioFilterItem;
        if (innerFilterItem) {
          innerFilterItem.openDropdown();
        }
      });
    }
  }
};