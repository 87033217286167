import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_kpi_card = _resolveComponent("kpi-card");
  return _openBlock(), _createBlock(_component_kpi_card, {
    name: "Days of Inventory",
    subtitle: "revenue lost",
    loading: $props.loading
  }, {
    "kpi-subtitle-amount": _withCtx(() => [_createVNode(_component_currency_value, {
      value: $props.subtitleAmount,
      styles: {
        padding: '0'
      }
    }, null, 8 /* PROPS */, ["value"])]),
    "subtitle-percentage": _withCtx(() => [_createVNode(_component_percentage_value, {
      value: $props.subtitlePercentage,
      showSign: "",
      styles: {
        padding: 0
      }
    }, null, 8 /* PROPS */, ["value"])]),
    amount: _withCtx(() => [_createVNode(_component_number_value, {
      value: $props.amount,
      precision: 0,
      styles: {
        padding: 0
      }
    }, null, 8 /* PROPS */, ["value"])]),
    "change-rate": _withCtx(() => [_createVNode(_component_b_badge, {
      style: _normalizeStyle({
        background: `${$data.COLORS.GRAY_BLUE} !important`,
        color: '#54595E !important'
      })
    }, {
      default: _withCtx(() => [_createVNode(_component_flex_row, null, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          value: $props.days,
          styles: {
            padding: 0
          },
          "show-sign": ""
        }, null, 8 /* PROPS */, ["value"]), $props.amount !== null && $props.amount !== undefined ? (_openBlock(), _createElementBlock("span", _hoisted_1, " days")) : _createCommentVNode("v-if", true)]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["style"])]),
    "info-popover-title": _withCtx(() => [_createTextVNode(_toDisplayString($data.title), 1 /* TEXT */)]),
    "info-popover-content": _withCtx(() => [_createTextVNode(_toDisplayString($data.detail), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"]);
}