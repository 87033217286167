import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height", "viewBox"];
const _hoisted_2 = ["fill"];
const _hoisted_3 = ["fill"];
const _hoisted_4 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 ${$props.width} ${$props.height}`,
    fill: "none"
  }, [_createElementVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M3.36994 11.6662C3.36994 10.8071 4.06638 10.1107 4.92549 10.1107C5.7846 \n    10.1107 6.48105 10.8071 6.48105 11.6662C6.48105 12.5253 5.7846 13.2218 4.92549 13.2218C4.06638 13.2218 \n    3.36994 12.5253 3.36994 11.6662ZM4.92549 11.1477C4.63912 11.1477 4.40697 11.3799 4.40697 11.6662C4.40697 \n    11.9526 4.63912 12.1848 4.92549 12.1848C5.21186 12.1848 5.44401 11.9526 5.44401 11.6662C5.44401 11.3799 5.21186 11.1477 4.92549 11.1477Z",
    fill: $props.color
  }, null, 8 /* PROPS */, _hoisted_2), _createElementVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M9.59216 11.6662C9.59216 10.8071 10.2886 10.1107 11.1477 \n  10.1107C12.0068 10.1107 12.7033 10.8071 12.7033 11.6662C12.7033 12.5253 12.0068 13.2218 11.1477 13.2218C10.2886 \n  13.2218 9.59216 12.5253 9.59216 11.6662ZM11.1477 11.1477C10.8613 11.1477 10.6292 11.3799 10.6292 11.6662C10.6292 \n  11.9526 10.8613 12.1848 11.1477 12.1848C11.4341 12.1848 11.6662 11.9526 11.6662 11.6662C11.6662 11.3799 11.4341 \n  11.1477 11.1477 11.1477Z",
    fill: $props.color
  }, null, 8 /* PROPS */, _hoisted_3), _createElementVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M0.777344 1.29586C0.777344 1.00949 1.00949 0.777344 1.29586 0.777344H2.46331C3.24329 0.778525 3.90179 1.35714 4.00329 \n            2.13046V2.13046L4.16683 3.36994H12.7033C12.8586 3.36994 13.0058 3.4396 13.1043 3.55977C13.2028 3.67993 13.2422 3.83792 \n            13.2117 3.99026L12.3407 8.33984L12.3406 8.34055V8.34055C12.1938 9.07743 11.5414 9.60395 10.7906 9.59216H5.31634C4.53159 \n            9.5976 3.86552 9.01754 3.76328 8.23925M3.76328 8.23925L2.97513 2.26591C2.94134 \n  2.00821 2.72194 1.81493 2.46207 1.81438H1.29586C1.00949 1.81438 0.777344 1.58223 0.777344 1.29586M4.30366 4.40697L4.79141 8.10359C4.82546 \n  8.36336 5.048 8.55736 5.30998 8.55514L5.31438 8.5551L10.7951 8.55512L10.8051 8.55522C11.0562 8.56003 11.2746 8.38417 11.3236 8.13781L11.3237 \n  8.13702L12.0706 4.40697H4.30366Z",
    fill: $props.color
  }, null, 8 /* PROPS */, _hoisted_4)], 8 /* PROPS */, _hoisted_1);
}