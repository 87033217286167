import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_table_with_chart = _resolveComponent("table-with-chart");
  const _component_error_wrapper = _resolveComponent("error-wrapper");
  return _openBlock(), _createBlock(_component_error_wrapper, {
    showError: $data.showError,
    onRetry: $options.retry
  }, {
    default: _withCtx(() => [_createVNode(_component_table_with_chart, {
      graphId: $data.graphId,
      evolutionTitle: $options.chartTitle,
      graphTitle: $options.graphTitle,
      graphHeaders: $data.graphHeaders,
      graphPositions: $data.graphPositions,
      chartPositions: $data.chartPositions,
      minMax: $data.minMax,
      setStriped: true,
      description: $data.graphDescription,
      sortBy: $data.sortBy,
      sortDirection: $data.sortDirection,
      pageRenderedLoading: $data.pageRenderedLoading,
      tableLoading: $data.graphLoading
    }, null, 8 /* PROPS */, ["graphId", "evolutionTitle", "graphTitle", "graphHeaders", "graphPositions", "chartPositions", "minMax", "description", "sortBy", "sortDirection", "pageRenderedLoading", "tableLoading"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["showError", "onRetry"]);
}