import { mapState, mapGetters, mapActions } from "vuex";
import ConnectionPageTitle from "@/components/ConnectionPageTitle";
import AppCardConnections from "@/components/AppCardConnections";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import AppModal from "@/components/AppModal";
import CreateWorkspaceSideBar from "@/components/CreateWorkspaceSideBar";
import AppPagination from "@/components/AppPagination.vue";
import { EVENTS } from "@/utils";
export default {
  name: "AllWorkspacesPage",
  components: {
    ConnectionPageTitle,
    AppCardConnections,
    AppButton,
    AppIcon,
    AppModal,
    CreateWorkspaceSideBar,
    AppPagination
  },
  data() {
    return {
      dataLoading: true,
      currentLoading: false,
      removeLoading: false,
      searchPlaceHolder: "Search",
      page: 1,
      perPage: 20,
      confirmRemoveModal: false,
      removeId: null,
      currentId: null,
      createWorkspaceSideBar: false
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      workspaces: state => state.workspace.list,
      current_workspace: state => state.workspace.current_workspace,
      isOmnyAdmin: state => state.auth.isOmnyAdmin
    }),
    ...mapGetters(["accountByWorkspaceIdGet", "accountAdvertisingBySellerIdGet"]),
    // isLoggedIn() {
    //   return !! this.token
    // },

    formatDate() {
      return date => {
        // Date formatting
        return date;
      };
    },
    totalPages() {
      return this.$store.getters.workspaceGetTotalPages();
    },
    paginatedWorkspaces() {
      const startIndex = (this.page - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.workspaces.slice(startIndex, endIndex);
    }
  },
  async mounted() {
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      await this.workspaceList();
      await this.workspaceCurrent();
      this.dataLoading = false;
      window.$bus.on("refreshWorkspaceList", this.refreshWorkspaceList);
    }
  },
  created() {
    this.checkAutoOpenModal();
  },
  methods: {
    ...mapActions(["workspaceList", "workspaceCurrent", "workspaceChange", "workspaceRemove", "contentDelete", "accountRemove", "authStatus"]),
    async onCurrentChange(workspace_id) {
      this.currentId = workspace_id;
      this.currentLoading = true;
      await this.workspaceChange({
        workspace_id: this.currentId
      });
      window.$bus.trigger(EVENTS.REFRESH_APPLICATION);
      this.currentLoading = false;
      this.currentId = null;
      //this.$router.push(`/`);
    },
    isCurrent(workspace) {
      return workspace.workspace_id == this.current_workspace.workspace_id;
    },
    isOmnyWorkspace(workspace) {
      return workspace.workspace_name == "All Accounts";
    },
    onMembers(workspace_id) {
      this.$router.push(`/manage-accounts/${workspace_id}/members`);
    },
    onRemoveConfirm(workspace_id) {
      this.removeId = workspace_id;
      this.confirmRemoveModal = true;
    },
    async onRemove() {
      this.confirmRemoveModal = false;
      this.removeLoading = true;

      //delete all content and accounts related to a workspace

      const seller_list = this.accountByWorkspaceIdGet(this.removeId);
      if (seller_list.length > 0) {
        let payload = {
          content_list: [],
          seller_list: seller_list,
          workspace_id: this.removeId
        };
        const result_delete = await this.contentDelete(payload);
        await this.accountRemove(payload);
      }
      await this.workspaceRemove({
        workspace_id: this.removeId
      });
      this.removeLoading = false;
      this.removeId = null;
    },
    async refreshWorkspaceList() {
      await this.workspaceList();
      await this.workspaceCurrent();
    },
    updatePerPage(perPage) {
      this.page = 1;
      this.perPage = perPage;
    },
    checkAutoOpenModal() {
      if (this.$route.query.openModal === "addAccountFromTop" && this.isOmnyAdmin) {
        this.createWorkspaceSideBar = true;
      }
    }
  }
};