import FlexColumn from "@/components/FlexColumn.vue";
import LoadingElement from "@/components/shared/LoadingElement.vue";
export default {
  name: 'RevenueExclVATSubtitleCard',
  props: {
    title: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },
  components: {
    FlexColumn,
    LoadingElement
  }
};