import { HugValue, TextValue } from "../../tableElements";
export default {
  name: "RegionValue",
  components: {
    HugValue,
    TextValue
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedValue() {
      return this.value?.toUpperCase();
    }
  }
};