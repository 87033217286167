import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_annotation_alert_icon = _resolveComponent("annotation-alert-icon");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    align: "left"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {
      color: $props.color
    }), _createVNode(_component_annotation_alert_icon, {
      color: $props.color
    }, null, 8 /* PROPS */, ["color"])]),
    _: 3 /* FORWARDED */
  });
}