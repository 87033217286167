export default {
  name: "FilterDivider",
  props: {
    width: {
      type: Number,
      default: 1
    },
    height: {
      type: Number,
      default: 28
    },
    color: {
      type: String,
      default: "#E3E3E3"
    }
  }
};