import AppRating from "@/components/AppRating";
import TextValue from "@/components/tableElements/TextValue.vue";
import HugValue from "@/components/tableElements/HugValue.vue";
import FlexRow from "@/components/FlexRow.vue";
export default {
  name: "CompetitorsModalTable",
  emits: ["back", "cancel", "select"],
  components: {
    AppRating,
    TextValue,
    FlexRow,
    HugValue
  },
  props: {
    competitors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableFields: [{
        key: "select",
        custom: "select-checkbox",
        isSelectAll: true,
        thClass: "fixed-header-th"
      }, {
        key: "title",
        label: "Product",
        custom: "product-wrapper",
        thClass: "fixed-header-th"
      }, {
        key: "asin",
        label: "ASIN",
        custom: "asin-wrapper",
        thClass: "fixed-header-th"
      }, {
        key: "rating",
        label: "Rating",
        custom: "rating-wrapper",
        thClass: "fixed-header-th"
      }],
      selectLimit: 10,
      loading: false,
      styles: {
        standardWrap: {
          padding: '8px 12px'
        }
      },
      selectall: false
    };
  },
  created() {
    this.selectAll(true);
  },
  computed: {
    selectedCompetitors() {
      return this.competitors.filter(item => item.isSelected);
    }
  },
  methods: {
    onRowSelect(item) {
      if (this.selectedCompetitors.length < this.selectLimit) {
        item.isSelected ??= false;
        item.isSelected = !item.isSelected;
        this.$emit("select", this.selectedCompetitors);
      }
    },
    // onBack() {
    //   this.$emit("back");
    // },

    // onCancel() {
    //   this.$emit("cancel");
    // },

    // onSubmit() {
    //   if (this.selectedCompetitors.length) {
    //     this.$emit("select", this.selectedCompetitors);
    //     this.$emit("cancel");
    //   }
    // },
    selectAll(val) {
      this.competitors.forEach(item => {
        item.isSelected ??= false;
        item.isSelected = val;
      });
      this.$emit("select", this.selectedCompetitors);
    }
  }
};