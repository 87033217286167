export default {
  name: "FileUploadErrorMessageMixin",
  computed: {},
  data() {},
  methods: {
    parseFileUploadErrorResult(responseData) {
      let data = JSON.parse(responseData);
      const errorCount = {};
      const errors = data.map(({
        attribute,
        error: errorType
      }) => {
        errorCount[errorType] = (errorCount[errorType] || 0) + 1;
        return {
          attribute,
          error: errorType
        };
      });
      //console.log("[file upload parsing error]", errors);
      //console.log("[Error count summary]", errorCount);
      return {
        errors,
        errorCount
      }; // Return both errors and the error count summary
    }
  }
};