import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "d-flex flex-row align-items-center gap-2"
};
const _hoisted_2 = {
  class: "d-flex flex-row align-items-center"
};
const _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_loading_element = _resolveComponent("loading-element");
  return _openBlock(), _createBlock(_component_loading_element, {
    loading: $props.loading
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from({
      length: $props.maxStars
    }, (_, index) => index + 1), item => {
      return _openBlock(), _createBlock(_component_app_icon, {
        key: item,
        size: $props.size,
        icon: $options.getIconName(item),
        color: $props.color
      }, null, 8 /* PROPS */, ["size", "icon", "color"]);
    }), 128 /* KEYED_FRAGMENT */))]), $props.count != null ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.count), 1 /* TEXT */)) : _createCommentVNode("v-if", true)])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"]);
}