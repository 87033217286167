import { getAggregateTableData, AggregateTable, aggregateTableHeaders } from "@/components/profit-and-loss";
import ProfitAndLossMixin from "@/mixins/ProfitAndLossMixin.vue";
import ProfitAndLossFilters from "@/components/ProfitAndLossFilters";
import { EVENTS, flattenTree } from "../utils";
import AggregateTableGroupBy from '@/components/profit-and-loss/AggregateTableGroupBy.vue';
export default {
  name: 'ProfitAndLossPage',
  mixins: [ProfitAndLossMixin],
  async mounted() {
    window.$bus.on(EVENTS.PROFIT_AND_LOSS_DATE_PICKER_SUBMIT, async state => await this.handleDatePickerDateChange(state));

    // Setup Filters
    const sellers = await this.getOmnyAccountSellers();
    if (sellers.length === 0) {
      this.announceNoAccounts();
      return;
    }
    // We are enabling only {Sellers, Marketplaces, Products} filters
    await this.initializeFilters({
      sellers
    }, "profitAndLoss", {
      seller: "sellers",
      marketplace: "marketplaces",
      product: "products"
    }, "profit_and_loss");
    const datepicker = this.$refs.filters.datePicker;
    if (!datepicker) {
      throw new Error("Datepicker not found");
    }
    const datePickerDate = datepicker.state;
    //console.log('Datepicker Date', datePickerDate)
    // Set default period for Aggregate Table
    this.$store.dispatch('profitAndLossSetAggregatePeriod', datePickerDate);
    await this.setupAggregateTable();
    window.$bus.on(EVENTS.REFRESH_PROFIT_AND_LOSS, () => {
      this.refreshAggregateTable().then(() => {});
    });
    this.aggregateTable.loading = false;
    this.datePickerLoading = false;
  },
  unmounted() {
    window.$bus.off(EVENTS.REFRESH_PROFIT_AND_LOSS, this.refreshAggregateTable);
  },
  components: {
    AggregateTable,
    ProfitAndLossFilters,
    AggregateTableGroupBy
  },
  data() {
    return {
      datePickerLoading: true,
      aggregateTable: {
        loading: true,
        aggregate: {
          items: [],
          minMax: {},
          colGroups: aggregateTableHeaders
        }
      }
    };
  },
  computed: {
    profitAndLossFilters() {
      let filters = {
        seller_id: this.$store.getters["profitAndLossGetSellers"](),
        sales_channel: this.$store.getters["profitAndLossGetMarketplaces"](),
        sku_list: this.$store.getters["profitAndLossGetSkus"]() ?? [],
        asin_list: this.$store.getters["profitAndLossGetAsins"]() ?? []
      };
      return filters;
    },
    getAggregateTableItems() {
      if (this.aggregateTable.loading) {
        return new Array(12).fill({});
      }
      return this.aggregateTable[this.getAggregateTableGroupBy.key].items;
    },
    getColGroups() {
      const colGroups = this.aggregateTable[this.getAggregateTableGroupBy.key].colGroups;

      /*
      ColGroups looks like this:
          [
              {key, label, fields: {montly: [], quarterly: [], yearly: []}}
          ]
           We will use the current period to determine which fields to use
      */

      const {
        key
      } = this.aggregatePeriod ?? '';
      return colGroups?.map(group => {
        return {
          ...group,
          fields: group.fields[key]
        };
      });
    },
    getAggregateTableGroupBy() {
      return this.$store.getters.profitAndLossGetAggregateGroupBy();
    },
    aggregatePeriod() {
      return this.$store.getters.profitAndLossGetAggregatePeriod();
    }
  },
  methods: {
    async setupAggregateTable() {
      const data = await this.fetchAggregateData();
      if (!data) return;
      const flattened = data.map(item => flattenTree(item, 'pc_net_revenue_evol')).flat().filter(Boolean);
      const absFlattened = flattened.filter(v => !!v).map(v => Math.abs(v));
      const minMax = {
        evolution_pp: {
          min: Math.min(...absFlattened),
          max: Math.max(...absFlattened)
        }
      };
      const groupBy = this.getAggregateTableGroupBy.key;
      this.aggregateTable[groupBy].items = data;
      this.aggregateTable[groupBy].minMax = minMax;
    },
    async fetchAggregateData() {
      //console.log("profitAndLossFilters", this.profitAndLossFilters);
      const params = {
        ...this.profitAndLossFilters,
        period: this.aggregatePeriod,
        ...this.$store.getters.currencyConfigQueryGet()
      };
      //console.log("Params", params)
      return await getAggregateTableData({
        params
      });
    },
    async refreshAggregateTable() {
      this.aggregateTable.loading = true;
      await this.setupAggregateTable();
      this.aggregateTable.loading = false;
    },
    async handleDatePickerDateChange(state) {
      this.$store.dispatch('profitAndLossSetAggregatePeriod', state);
      await this.refreshAggregateTable();
    }
  }
};