import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_search_element = _resolveComponent("search-element");
  const _component_product_filter_item = _resolveComponent("product-filter-item");
  const _component_selected_section = _resolveComponent("selected-section");
  const _component_list_section = _resolveComponent("list-section");
  const _component_WrapWithDropdownBody = _resolveComponent("WrapWithDropdownBody");
  return _openBlock(), _createBlock(_component_WrapWithDropdownBody, {
    onHidden: _cache[5] || (_cache[5] = $event => _ctx.$emit('hidden'))
  }, {
    searcher: _withCtx(() => [_createVNode(_component_search_element, {
      placeholder: "Search products...",
      onSearch: _cache[0] || (_cache[0] = val => {
        _ctx.$emit('search', val);
      })
    })]),
    selected: _withCtx(() => [_createVNode(_component_selected_section, {
      title: "Products",
      count: $props.selected.length,
      onClear: _cache[1] || (_cache[1] = $event => _ctx.$emit('clear')),
      onApply: _cache[2] || (_cache[2] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _cache[3] || (_cache[3] = $event => _ctx.$emit('closeDropdown')),
      isModified: $props.isModified
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selected, item => {
        return _openBlock(), _createBlock(_component_product_filter_item, {
          key: item.id,
          product_name: item.product_name,
          asin: item.asin,
          sku: item.sku,
          image: item.main_image_link,
          id: item.id,
          selected: true,
          item: item,
          onDeselect: item => _ctx.$emit('deselect', item),
          width: 400
        }, null, 8 /* PROPS */, ["product_name", "asin", "sku", "image", "id", "item", "onDeselect"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count", "isModified"])]),
    list: _withCtx(() => [_createVNode(_component_list_section, {
      title: "Products",
      count: $props.list.length,
      searching: $props.searching,
      onSelectAll: _cache[4] || (_cache[4] = $event => _ctx.$emit('selectAll'))
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, item => {
        return _openBlock(), _createBlock(_component_product_filter_item, {
          key: item.id,
          product_name: item.product_name,
          asin: item.asin,
          sku: item.sku,
          id: item.id,
          image: item.main_image_link,
          item: item,
          onSelect: item => _ctx.$emit('select', item),
          onSelectOnly: item => _ctx.$emit('selectOnly', item),
          width: 400
        }, null, 8 /* PROPS */, ["product_name", "asin", "sku", "id", "image", "item", "onSelect", "onSelectOnly"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count", "searching"])]),
    _: 1 /* STABLE */
  });
}