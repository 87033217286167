import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_page_views_card = _resolveComponent("page-views-card");
  const _component_listing_organic_cvr_card = _resolveComponent("listing-organic-cvr-card");
  const _component_listing_buy_box_card = _resolveComponent("listing-buy-box-card");
  const _component_listing_active_asin_card = _resolveComponent("listing-active-asin-card");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: 24,
    styles: {
      alignSelf: 'stretch'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_page_views_card, {
      amount: $options.previousPeriodKpis[0]?.amount,
      percentage: $options.previousPeriodKpis[0]?.percentage,
      loading: $props.loading
    }, null, 8 /* PROPS */, ["amount", "percentage", "loading"]), _createVNode(_component_listing_organic_cvr_card, {
      amount: $options.previousPeriodKpis[1]?.amount,
      percentage: $options.previousPeriodKpis[1]?.percentage,
      loading: $props.loading
    }, null, 8 /* PROPS */, ["amount", "percentage", "loading"]), _createVNode(_component_listing_buy_box_card, {
      amount: $options.previousPeriodKpis[2]?.amount,
      percentage: $options.previousPeriodKpis[2]?.percentage,
      loading: $props.loading
    }, null, 8 /* PROPS */, ["amount", "percentage", "loading"]), _createVNode(_component_listing_active_asin_card, {
      amount: $options.previousPeriodKpis[3]?.amount,
      percentage: $options.previousPeriodKpis[3]?.percentage,
      loading: $props.loading
    }, null, 8 /* PROPS */, ["amount", "percentage", "loading"])]),
    _: 1 /* STABLE */
  });
}