import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_marketplace_value = _resolveComponent("marketplace-value");
  const _component_marketplace_filter_body = _resolveComponent("marketplace-filter-body");
  const _component_filter_item = _resolveComponent("filter-item");
  return _openBlock(), _createBlock(_component_filter_item, {
    id: "marketplaces",
    ref: "marketplaceFilterItem",
    icon: "MarketplaceIcon",
    title: "All Marketplaces",
    selectedCount: $props.marketplaces.filterState.selectedCount,
    onClearAll: $options.onClear
  }, _createSlots({
    body: _withCtx(() => [_createVNode(_component_marketplace_filter_body, {
      title: "Marketplace",
      selected: $props.marketplaces.filterState.selected,
      isModified: $props.marketplaces.isModified,
      searching: $props.marketplaces.searching,
      list: $props.marketplaces.filterState.list,
      onClear: $options.onClear,
      onSelect: $options.onSelect,
      onSelectOnly: $options.onSelectOnly,
      onDeselect: $options.onDeSelect,
      onApply: $options.onApply,
      onSearch: $options.onSearch,
      onHidden: $options.onApply,
      onCloseDropdown: $options.onClose
    }, null, 8 /* PROPS */, ["selected", "isModified", "searching", "list", "onClear", "onSelect", "onSelectOnly", "onDeselect", "onApply", "onSearch", "onHidden", "onCloseDropdown"])]),
    _: 2 /* DYNAMIC */
  }, [$options.getMarketplace ? {
    name: "title",
    fn: _withCtx(() => [_createVNode(_component_marketplace_value, {
      gap: 8,
      marketplace: $options.getSelectedMarketplaces,
      flag: $options.getMarketplace.icon
    }, null, 8 /* PROPS */, ["marketplace", "flag"])]),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["selectedCount", "onClearAll"]);
}