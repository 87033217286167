import { mapActions } from "vuex";
import AppIcon from "@/components/AppIcon";
import EditPageContentPreview from "@/components/EditPageContentPreview";
import EditPageContentEdit from "@/components/EditPageContentEdit";
import { OnClickOutside } from '@vueuse/components';
export default {
  name: "EditpageContent",
  emits: ["update:modelValue"],
  components: {
    AppIcon,
    EditPageContentPreview,
    EditPageContentEdit,
    OnClickOutside
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    amazon: {
      type: Object,
      default: () => {}
    },
    ai_translated_content: {
      type: Object,
      default: () => {}
    },
    ai_generated_content: {
      type: Object,
      default: () => {}
    },
    ai_generated_content_translation: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    active_menu: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    show_ai_generated_content: {
      type: Boolean,
      default: false
    },
    show_ai_translated_content: {
      type: Boolean,
      default: false
    },
    keyword_sidebar_open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      charactersMap: {
        product_name: {
          min: 100,
          max: 200
        },
        generic_keyword: {
          min: 100,
          max: 250
        },
        product_description: {
          min: 1000,
          max: 2000
        },
        bullet_points: {
          min: 50,
          max: 300
        }
      },
      show_amazon_version: false
    };
  },
  computed: {
    ...mapActions(['contentApplySuggestedKeywords']),
    amazonContent() {
      return {
        product_name: this.amazon?.product_name ?? "",
        generic_keyword: this.amazon?.generic_keyword ?? "",
        product_description: this.amazon?.product_description ?? "",
        bullet_points: [this.amazon.bullet_point_1, this.amazon.bullet_point_2, this.amazon.bullet_point_3, this.amazon.bullet_point_4, this.amazon.bullet_point_5]
      }[this.type] ?? "";
    },
    aiContent() {
      return this.show_ai_translated_content && this.translateContent || this.show_ai_generated_content && this.aiGenerateContent;
    },
    aiContentTranslate() {
      return this.show_ai_generated_content && this.aiGenerateContentTranslate;
    },
    aiContentTitle() {
      return this.show_ai_translated_content && "AI Translated Content" || this.show_ai_generated_content && "AI proposition";
    },
    async translateContent() {
      const keywords = await this.contentApplySuggestedKeywords({
        seller_id: this.modelValue.seller_id,
        asin: this.modelValue.asin,
        marketplace_id: this.modelValue.marketplace_id,
        product_name: this.modelValue.product_name,
        product_description: this.modelValue.product_description,
        bullet_points: [this.modelValue.bullet_point_1, this.modelValue.bullet_point_2, this.modelValue.bullet_point_3, this.modelValue.bullet_point_4, this.modelValue.bullet_point_5],
        brand: this.modelValue.brand
      });
      let content = {
        product_name: this.ai_translated_content?.product_name ?? "",
        generic_keyword: this.amazon?.generic_keyword ?? keywords,
        product_description: this.ai_translated_content?.product_description ?? "",
        bullet_points: [this.ai_translated_content?.bullet_point_1 ?? "", this.ai_translated_content?.bullet_point_2 ?? "", this.ai_translated_content?.bullet_point_3 ?? "", this.ai_translated_content?.bullet_point_4 ?? "", this.ai_translated_content?.bullet_point_5 ?? ""].filter(Boolean)
      }[this.type];
      return content == false ? "" : content;
    },
    aiGenerateContent() {
      let content = {
        product_name: [this.ai_generated_content?.product_name_1 ?? "", this.ai_generated_content?.product_name_2 ?? "", this.ai_generated_content?.product_name_3 ?? ""],
        generic_keyword: this.ai_generated_content?.generic_keyword ?? "",
        product_description: this.ai_generated_content?.product_description ?? "",
        bullet_points: [this.ai_generated_content?.bullet_point_1 ?? "", this.ai_generated_content?.bullet_point_2 ?? "", this.ai_generated_content?.bullet_point_3 ?? "", this.ai_generated_content?.bullet_point_4 ?? "", this.ai_generated_content?.bullet_point_5 ?? "", this.ai_generated_content?.bullet_point_6 ?? "", this.ai_generated_content?.bullet_point_7 ?? "", this.ai_generated_content?.bullet_point_8 ?? "", this.ai_generated_content?.bullet_point_9 ?? ""].filter(Boolean)
      }[this.type];
      return content == false ? "" : content;
    },
    aiGenerateContentTranslate() {
      let content = {
        product_name: [this.ai_generated_content_translation?.product_name_1 ?? "", this.ai_generated_content_translation?.product_name_2 ?? "", this.ai_generated_content_translation?.product_name_3 ?? ""],
        generic_keyword: this.ai_generated_content_translation?.generic_keyword ?? "",
        product_description: this.ai_generated_content_translation?.product_description ?? "",
        bullet_points: [this.ai_generated_content_translation?.bullet_point_1 ?? "", this.ai_generated_content_translation?.bullet_point_2 ?? "", this.ai_generated_content_translation?.bullet_point_3 ?? "", this.ai_generated_content_translation?.bullet_point_4 ?? "", this.ai_generated_content_translation?.bullet_point_5 ?? "", this.ai_generated_content_translation?.bullet_point_6 ?? "", this.ai_generated_content_translation?.bullet_point_7 ?? "", this.ai_generated_content_translation?.bullet_point_8 ?? "", this.ai_generated_content_translation?.bullet_point_9 ?? ""].filter(Boolean)
      }[this.type];
      return content == false ? "" : content;
    },
    omnyContent: {
      get() {
        return {
          product_name: this.modelValue?.product_name ?? "",
          generic_keyword: this.modelValue?.generic_keyword ?? "",
          product_description: this.modelValue?.product_description ?? "",
          bullet_points: [this.modelValue.bullet_point_1, this.modelValue.bullet_point_2, this.modelValue.bullet_point_3, this.modelValue.bullet_point_4, this.modelValue.bullet_point_5]
        }[this.type] ?? "";
      },
      set(val) {
        const newValue = {
          ...this.modelValue
        };
        const updateBulletPointsAttribute = (newVal, val) => {
          for (const index in val) {
            newVal[`bullet_point_${index * 1 + 1}`] = val[index];
          }
          return newVal;
        };
        const updateAttribute = (newVal, val) => {
          newVal[this.type] = val;
          return newVal;
        };
        let result = this.type == "bullet_points" ? updateBulletPointsAttribute(newValue, val) : updateAttribute(newValue, val);
        this.$emit("update:modelValue", result);
      }
    },
    characterRangePerContentType() {
      return this.charactersMap[this.type] || {
        min: 150,
        max: 500
      };
    }
  },
  methods: {
    onAdd(val) {
      this.omnyContent = val;
    }
  }
};