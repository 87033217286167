import { truncateString } from '../../utils';
import AppIcon from '../AppIcon.vue';
export default {
  name: 'EventTypeValue',
  components: {
    AppIcon
  },
  props: {
    value: {
      type: String
    }
  }
};