import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7bf0c274"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sections-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FlexColumn = _resolveComponent("FlexColumn");
  return _openBlock(), _createBlock(_component_FlexColumn, {
    classes: "wrap-with-dropdown-body",
    styles: _ctx.$attrs.style
  }, {
    default: _withCtx(() => [$props.showSearcher ? _renderSlot(_ctx.$slots, "searcher", {
      key: 0
    }, undefined, true) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "selected", {}, undefined, true), _renderSlot(_ctx.$slots, "list", {}, undefined, true)])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["styles"]);
}