import FlexRow from "@/components/FlexRow.vue";
import PageViewsCard from "@/components/kpi-cards/dashboard_v2/PageViewsCard.vue";
import ListingOrganicCvrCard from "@/components/kpi-cards/dashboard_v2/ListingOrganicCvrCard.vue";
import ListingBuyBoxCard from "@/components/kpi-cards/dashboard_v2/ListingBuyBoxCard.vue";
import ListingActiveAsinCard from "@/components/kpi-cards/dashboard_v2/ListingActiveAsinCard.vue";
import { mapState } from "vuex";
export default {
  name: "ListingCards",
  components: {
    FlexRow,
    PageViewsCard,
    ListingOrganicCvrCard,
    ListingBuyBoxCard,
    ListingActiveAsinCard
  },
  computed: {
    ...mapState({
      kpis: state => state.dashboard_v2.kpis.data.listing,
      periodCategory: state => state.dashboard_v2.periodCategory
    }),
    previousPeriodKpis() {
      return this.kpis[this.periodCategory];
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};