import VariableStyledBar from '@/components/tableElements/VariableStyledBar.vue';
export default {
  name: "CrossHatchedBar",
  components: {
    VariableStyledBar
  },
  props: {
    value: {
      type: [Number, null],
      required: false,
      default: 0
    },
    color: {
      type: String,
      required: true,
      default: "#312783"
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 0
    },
    height: {
      type: Number,
      required: false,
      default: 4
    }
  },
  computed: {
    getComputedStyle() {
      const encodedColor = this.color.replace('#', '%23');
      return {
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='13' height='${this.height}' viewBox='0 0 13 ${this.height}' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 -3L1 8' stroke='${encodedColor}' stroke-width='0.7' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,
        backgroundColor: 'transparent !important',
        border: `1px solid ${this.color}`,
        height: `${this.height}px`
      };
    }
  }
};