import { sortData, getCountriesMapping } from "@/utils";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import AppIcon from "@/components/AppIcon";
import AppCircleProgress from "@/components/AppCircleProgress";
import AppButton from "@/components/AppButton";
import EditPageTranslateCountrySelect from "@/components/EditPageTranslateCountrySelect";
import ContentMixin from "@/mixins/ContentMixin.vue";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import { isObjectEmpty } from "@/utils";
export default {
  name: "EditPageTranslate",
  mixins: [ContentMixin, ValidateMixin, AccountsMixin],
  emits: ["close", "translate", "asyncUpdate", "update:is_translation_button_active"],
  components: {
    AppIcon,
    AppCircleProgress,
    AppButton,
    EditPageTranslateCountrySelect
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    loading_translation_spinner: {
      type: Boolean,
      default: false
    },
    is_translation_button_active: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    const accounts = await this.getOmnyAccountSellers();
    let seller_list = [];
    accounts.forEach(account => {
      seller_list.push(account.account_id);
    });
    const payload = {
      seller_list: seller_list,
      asin_list: [this.product.asin]
    };
    const data = await this.contentGetExistingMarketPlaceList(payload);
    //console.log("[data from the existing marketplace]");
    //console.log(data);
    if (data && data.data.length > 0) {
      let localCountriesMapping = await getCountriesMapping();
      let filteredCountries = localCountriesMapping.filter(mapping => data.data.some(item => item.marketplace_id === mapping.marketplace_id && this.product.marketplace_id != mapping.marketplace_id)).map(mapping => {
        const foundItem = data.data.find(item => item.marketplace_id === mapping.marketplace_id);
        const rulesAmzScore = this.statusValidate(foundItem, "amazon");
        return {
          ...mapping,
          score: foundItem ? foundItem.cvr_score : "",
          success_rules: foundItem ? this.successRules(rulesAmzScore) : "",
          all_rules: foundItem ? this.allRules(rulesAmzScore) : ""
        };
      });
      this.list = sortData(filteredCountries);
      //console.log('[setting list for existing market place]');
      //console.log(this.list);
    }
    this.loading_existing_listing = false;
  },
  async created() {},
  data() {
    return {
      list: [],
      // list: [
      //   { id: 'fr', title: 'amazon.fr', score: 6.5, country: 'France' },
      //   { id: 'it', title: 'amazon.it', score: 6.5, country: 'Italy' },
      //   { id: 'pl', title: 'amazon.pl', score: 6.5, country: 'Poland' },
      //   { id: 'uk', title: 'amazon.uk', score: 6.5, country: 'UK' },
      //   { id: 'us', title: 'amazon.us', score: 8, country: 'US' },
      //   { id: 'es', title: 'amazon.es', score: 6.5, country: 'Spain' },
      // ],
      selected: null,
      selected_item: null,
      loading_existing_listing: true,
      translationTaskIdentifier: "",
      hide: true,
      other_selected: false
    };
  },
  computed: {
    ...mapState({
      current_workspace: state => state.workspace.current_workspace
    }),
    ...mapGetters(["getItemFromExistingMarketPlaceList", "accountByProviderGet"])
  },
  methods: {
    ...mapActions(["contentGetExistingMarketPlaceList"]),
    onClose() {
      this.$emit("close");
    },
    onTranslate() {
      //console.log("[on translate selected item]");
      //console.log(this.selected_item);
      this.$emit("translate", this.selected_item);
    },
    onSelect(id) {
      this.other_selected = false;
      this.selected = id;
      this.selected_item = this.getItemFromExistingMarketPlaceList({
        marketplace_id: id
      });
      //keep this code,this will help to debug the async task translation problem easily

      this.translationTaskIdentifier = this.current_workspace.workspace_id + "_" + "content_translation" + "_" + this.selected_item.content_id;
      //console.log("[selected item to check async translatation status]");
      //console.log(this.translationTaskIdentifier);
      this.$emit("asyncUpdate", this.translationTaskIdentifier);
      //this.$emit("asyncUpdate", this.selected_item);
    },
    onSelectOther() {
      this.selected = null;
      this.selected_item = null;
      this.$emit("update:is_translation_button_active", false);
      this.other_selected = true;
    },
    onOtherSelectedItem(item) {
      if (!isObjectEmpty(item)) {
        this.selected_item = item;
        this.translationTaskIdentifier = this.current_workspace.workspace_id + "_" + "content_translation" + "_" + this.selected_item.asin + "_" + this.selected_item.marketplace_id;
        //console.log("[selected item to check async translatation status]");
        //console.log(this.translationTaskIdentifier);
        this.$emit("asyncUpdate", this.translationTaskIdentifier);
      }
    },
    successRules(array) {
      var success_rules = 0;
      array.forEach(rule => {
        success_rules += rule.score * rule.coefficient;
      });
      return success_rules;
    },
    allRules(array) {
      var all_rules = 0;
      array.forEach(rule => {
        all_rules += 10 * rule.coefficient;
      });
      return all_rules;
    }
  }
};