import SkeletonItem from '@/components/shared/SkeletonItem.vue';
export default {
  name: 'LoadingElement',
  components: {
    SkeletonItem
  },
  emits: ['click'],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number
    },
    height: {
      type: Number,
      default: 20
    },
    color: {
      type: String
    }
  }
};