import TableGraph from '@/components/TableGraph.vue';
import DashboardMixin from '@/mixins/DashboardMixin';
import { computeMinMax, EVENTS, computeTableWidths } from '@/utils';
import { getGraphHeaderByTabView, getGraphTitles } from '@/components/shared/chartHelpers';
import { getGraphDefaultSortBy } from '@/components/shared/chartHelpers';
import { mapState } from 'vuex';
import ErrorWrapper from '@/components/ErrorWrapper.vue';
export default {
  name: 'ProductPricingMarginPage',
  mixins: [DashboardMixin],
  props: {
    modelValue: {
      type: Boolean
    },
    period: {
      type: Object
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      sellerIds: [],
      positions: [],
      totalPositions: [],
      totalPositionsCount: 0,
      totalWidths: [],
      totalLoading: false,
      showTotal: false,
      currentPage: 1,
      pageSize: 200,
      minMax: {},
      graphHeaders: [],
      graphDescription: [],
      sortBy: "",
      sortDirection: "desc",
      tab: "Product",
      view: "Pricing and Margin",
      currentViewPeriodOptions: {},
      pageRenderedLoading: false,
      showError: false,
      graphId: 'product_pricing_margin'
    };
  },
  async mounted() {
    this.$store.dispatch('dashboardSetCurrentTab', 'Product');
    this.$store.dispatch('dashboardSetCurrentView', 'Pricing and Margin');
    this.graphDescription = this.headerDescription(this.tab, this.view);

    // Update Graph Headers
    this.graphHeaders = getGraphHeaderByTabView(this.tab, this.view, this.period);

    // Set the default sortBy
    this.sortBy = getGraphDefaultSortBy(this.tab, this.view);
    //console.log("Pricing Margin: ", this.sortBy)

    await this.updateComponent({
      period: this.period
    });
    window.$bus.on(EVENTS.PRODUCT_PRICING_MARGIN, () => {
      this.updateComponent({
        period: this.period
      }).catch(err => {
        //console.log("Failed to update component", err)
      });
    });
    window.$bus.on(EVENTS.SIDEBAR_CHANGED, () => {
      this.totalWidths = computeTableWidths(this.graphId);
      //console.log("Sidebar changed: ", this.totalWidths)
    });
  },
  computed: {
    ...mapState({
      graph: state => state.dashboard.pageReports['product_pricing_margin'].graph
    }),
    graphTitle() {
      return getGraphTitles()[this.tab][this.view] || "";
    },
    filters() {
      return {
        seller_id: this.$store.getters['dashboardGetSellers'](),
        sales_channel: this.$store.getters['dashboardGetSalesChannels'](),
        ...this.$store.getters['dashboardGetProducts']()
      };
    },
    graphLoading: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  methods: {
    retry() {
      this.showError = false;
      this.updateComponent({
        period: this.period
      }).catch(err => {
        //console.log("Failed to update component", err)
      });
    },
    async innerFetch({
      period
    }) {
      return await this.fetchKpis({
        path: '/product/pricing_and_margin',
        filters: this.filters,
        period,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
        page: this.currentPage,
        pageSize: this.pageSize,
        wrapWithCountries: true
      });
    },
    async updateComponent({
      period
    }) {
      this.graphLoading = true;
      const {
        graph,
        total,
        totalCount,
        error
      } = await this.setupComponent({
        period
      });
      if (error) {
        this.showError = true;
        this.graphLoading = false;
        return;
      }

      // Compute minMax
      this.minMax = computeMinMax(graph);
      this.setGraph({
        tabView: 'product_pricing_margin',
        graph
      });
      this.totalPositions = total[0];
      this.totalPositionsCount = totalCount;
      this.graphLoading = false;
    },
    async setupComponent({
      period
    }) {
      await this.getOmnyAccountSellers();
      return await this.innerFetch({
        period
      });
    },
    async sortTable(sortBy) {
      this.pageRenderedLoading = true;
      if (this.sortBy !== sortBy) this.sortDirection = 'desc';else {
        this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      }
      this.sortBy = sortBy;
      const {
        graph
      } = await this.setupComponent({
        period: this.period
      });

      // Compute minMax
      this.minMax = computeMinMax(graph);
      this.setGraph({
        tabView: 'product_pricing_margin',
        graph
      });
      this.pageRenderedLoading = false;
    },
    handlePage(page) {
      this.pageRenderedLoading = true;
      this.currentPage = page;
      this.innerFetch({
        period: this.period
      }).then(({
        graph
      }) => {
        this.setGraph({
          tabView: 'product_pricing_margin',
          graph
        });
        this.pageRenderedLoading = false;
      }).catch(() => {
        this.pageRenderedLoading = false;
      });
    }
  },
  components: {
    TableGraph,
    ErrorWrapper
  }
};