import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_loading_element = _resolveComponent("loading-element");
  return _openBlock(), _createBlock(_component_loading_element, {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('click')),
    loading: $props.loading,
    size: $props.size
  }, {
    default: _withCtx(() => [_createVNode(_component_flex_row, {
      gap: 12,
      class: "date-picker-selector"
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        style: _normalizeStyle($options.getPillStyle)
      }, _toDisplayString($props.title), 5 /* TEXT, STYLE */), _createVNode(_component_flex_row, {
        class: "date-side",
        gap: 8
      }, {
        default: _withCtx(() => [!('body' in _ctx.$slots) ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle($options.getDateStyle)
        }, _toDisplayString($options.getFormattedDates), 5 /* TEXT, STYLE */)) : _renderSlot(_ctx.$slots, "body", {
          key: 1
        }), _createVNode(_component_AppIcon, {
          icon: "calendar",
          size: 16,
          color: "#595959"
        })]),
        _: 3 /* FORWARDED */
      })]),
      _: 3 /* FORWARDED */
    })]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading", "size"]);
}