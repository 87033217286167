import FlexColumn from "@/components/FlexColumn.vue";
import RightStripIcon from "@/components/RightStripIcon.vue";
import AppButton from "@/components/AppButton.vue";
export default {
  name: "OmnyUILayout",
  props: {
    contentPadding: {
      type: String,
      default: "24px"
    },
    rightStripPadding: {
      type: String,
      default: "8px"
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    activateSettings: {
      type: Boolean,
      default: false
    },
    settingsInfo: {
      type: String,
      default: ""
    },
    settingsInfoDownload: {
      type: String,
      default: ""
    },
    settingsInfoUpload: {
      type: String,
      default: ""
    },
    showPagination: {
      type: Boolean,
      default: false
    },
    rightStripFeatureName: {
      type: String,
      default: "default"
    },
    hideSettingsFooterButtons: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "modal-shown", "close-file-upload-modal"],
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  methods: {
    handleDownloadCSVClick() {
      this.$emit("handleDownloadCSVClick");
    },
    onCloseFileUpload() {
      this.$emit("close-file-upload-modal");
    }
  },
  components: {
    FlexColumn,
    RightStripIcon,
    AppButton
  }
};