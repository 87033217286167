export default {
  name: 'AppLink',
  props: {
    to: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    }
  }
};