import AppInputRangeItem from '@/components/AppInputRangeItem';
export default {
  name: 'AppInputRange',
  components: {
    AppInputRangeItem
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validateRule: {
      type: String,
      default: 'none'
    }
  },
  emits: ['update:modelValue', 'submit'],
  computed: {
    items: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  methods: {
    onRemove(index) {
      const temp = [...this.items];
      temp.splice(index, 1);
      this.items = temp;
    },
    onAdd(index) {
      const temp = [...this.items];
      temp.splice(index + 1, 0, '');
      this.items = temp;
    },
    onInput(value, index) {
      this.items[index] = value;
    },
    onSubmit() {
      this.$emit('submit');
    }
  }
};