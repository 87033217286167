import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-346dd7b4"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = {
  class: "btns"
};
const _hoisted_3 = {
  key: 0,
  class: "ruler"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
    class: "header",
    style: _normalizeStyle($props.headerStyle)
  }, [_createElementVNode("div", {
    innerHTML: $props.header,
    class: "header-text"
  }, null, 8 /* PROPS */, _hoisted_1), _createElementVNode("div", _hoisted_2, [$props.showButton ? (_openBlock(), _createBlock(_component_b_button, {
    key: 0,
    disabled: $props.disable,
    variant: "outline-light",
    class: "tiny-btn outlined",
    onClick: _cache[0] || (_cache[0] = e => {
      e.stopPropagation();
      _ctx.$emit('click', e);
    })
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.buttonText), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["disabled"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "extra-buttons", {}, undefined, true)])], 4 /* STYLE */), !$props.noRuler ? (_openBlock(), _createElementBlock("hr", _hoisted_3)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
}