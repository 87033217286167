import { mapMutations, mapState, mapGetters } from "vuex";
import { EVENTS } from "@/utils";
import AppDropdown from "@/components/AppDropdown";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import FlexRow from "@/components/FlexRow.vue";
import { SellerFilter, MarketplaceFilter, ProductFilter } from "@/components/new-filters";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import NavigationTabs from "@/components/NavigationTabs";
export default {
  name: "SeoTopSection",
  mixins: [AccountsMixin],
  emits: ["push-submit-modifed-contents", "push-modal"],
  components: {
    AppDropdown,
    AppButton,
    AppIcon,
    FlexRow,
    SellerFilter,
    MarketplaceFilter,
    ProductFilter,
    NavigationTabs
  },
  data() {
    return {
      item: "omny",
      options: [{
        value: "omny",
        text: "Omny"
      }],
      sellers: [],
      push_button_text: {
        default: "Push Updates on Amazon",
        push_mode: this.pushButtonName
      },
      marketplaces_filter_key: ["marketplace_id", "name", "code", "country_code", "id"],
      sellers_filter_key: ["account_id", "name", "region", "id"]
    };
  },
  computed: {
    ...mapState({
      sellers_filter: state => state.content.sellers_filter,
      marketplaces_filter: state => state.content.marketplaces_filter,
      products_filter: state => state.content.products_filter,
      tabs: state => state.seo.tabs,
      selected_contents: state => state.content.selected_contents,
      current_mode: state => state.content.current_mode
    }),
    ...mapGetters(["seoGetTab"]),
    tab: {
      async set(value) {
        this.$store.dispatch("seoSetTab", value);
      },
      get() {
        return this.seoGetTab();
      }
    },
    pushButton() {
      return this.push_button[this.current_mode];
    }
  },
  methods: {
    ...mapMutations(["contentsKeyWordListSet", "contentSelectedContentsSet", "contentPushModalStatusSet", "contentsKeyWordListSet"]),
    async applySellers() {
      if (this.sellers_filter.isModified) {
        this.sellers_filter.apply();
        this.contentsKeyWordListSet([]);
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_COUNTRIES);
      }
    },
    async clearSellers() {
      if (this.sellers_filter.isModified) {
        this.sellers_filter.clear();
        this.contentsKeyWordListSet([]);
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_COUNTRIES);
      }
    },
    async applyMarketplaces() {
      if (this.marketplaces_filter.isModified) {
        this.marketplaces_filter.apply();
        this.contentsKeyWordListSet([]);
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_AND_SUGGESTIONS);
      }
    },
    async clearMarketplaces() {
      if (this.marketplaces_filter.isModified) {
        this.marketplaces_filter.clear();
        this.contentsKeyWordListSet([]);
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_AND_SUGGESTIONS);
      }
    },
    async applyProducts() {
      if (this.products_filter.isModified) {
        this.products_filter.apply();
        this.contentsKeyWordListSet([]);
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
      }
    },
    async clearProducts() {
      if (this.products_filter.isModified) {
        this.products_filter.clear();
        this.contentsKeyWordListSet([]);
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
      }
    },
    async searchProducts($event) {
      this.products_filter.searching = true;
      if (Array.isArray($event) && $event.length === 0) {
        this.products_filter.searching = false;
      }
      this.contentsKeyWordListSet($event);
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_SUGGESTIONS);
    },
    pushUpdates() {
      this.current_mode == "push_mode" ? this.$emit("push-submit-modifed-contents") : this.current_mode == "default" ? this.$emit("push-modal") : "";
    }
  }
};