import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import PpcStrategiesDatesModal from "@/components/PpcStrategiesDatesModal";
import FlexColumn from "@/components/FlexColumn";
import FlexRow from "@/components/FlexRow";
import AppIcon from "@/components/AppIcon";
import { EVENTS } from "@/utils/constants";
import PpcStrategiesModalAllocation from "./PpcStrategiesModalAllocation.vue";
import WrapWithInfo from "./WrapWithInfo.vue";
import { DateValue } from "./tableElements";
import { getWeekEnd, getCurrencySymbol, getFormattedPercentage, makeArrayUnique } from "@/utils";
import { amz_sp_api_marketplace_ids_country_domains_flipped, country_codes_with_flags_and_marketplace_ids } from "@/utils/countries";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import PpcStrategiesConfirmUpdateModal from "@/components/PpcStrategiesConfirmUpdateModal.vue";
export default {
  name: "PpcStrategiesModal",
  mixins: [AccountsMixin],
  emits: ["update:modelValue", "modal-close", "confirm-update"],
  components: {
    PpcStrategiesDatesModal,
    FlexColumn,
    FlexRow,
    AppIcon,
    PpcStrategiesModalAllocation,
    WrapWithInfo,
    DateValue,
    PpcStrategiesConfirmUpdateModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    startWeekStrategy: {
      type: Object,
      default: null
    },
    currency: {
      type: String,
      default: "USD"
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    await this.initializePpcStrategiesModal();
  },
  data() {
    return {
      loading: false,
      startWeek: null,
      endWeek: null,
      allocationMethod: "fixed",
      type: "fixed",
      valueAmount: 1000,
      valuePercent: 10,
      isDateModalVisible: false,
      originalState: {},
      confirmUpdateModal: false,
      unWatchUpdateWatcher: null,
      updateConfirmed: null,
      isValid: true,
      previous_strategy: {}
    };
  },
  computed: {
    ...mapState({
      weeksRange: state => state.ppc_settings.weeksRange,
      sellers_filter: state => state.ppc_v2.sellers,
      marketplaces_filter: state => state.ppc_v2.marketplaces,
      current_workspace: state => state.workspace.current_workspace,
      currentWeek: state => state.ppc_settings.currentWeek
    }),
    ppcSettingsFilters() {
      return {
        seller_id: this.sellers_filter.selectedValues.slice(-1).pop(),
        sales_channel: this.marketplaces_filter.selectedValues.slice(-1).pop()
      };
    },
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    ppcSettings() {
      return this.$store.state.ppcSettings;
    },
    computedWeeks() {
      const result = (this.endWeek - this.startWeek) / (1000 * 60 * 60 * 24 * 7);
      return Math.ceil(result);
    },
    ...mapGetters(["accountProfileIdGet"])
  },
  methods: {
    ...mapActions(["ppcStrategiesSave", "ppcBudgetDelete"]),
    getWeekEnd,
    getCurrencySymbol,
    initializePpcStrategiesModal() {
      this.startWeek = this.startWeekStrategy.start_date ? moment(this.startWeekStrategy.start_date) : this.startWeekStrategy.moment.clone();
      this.endWeek = this.startWeekStrategy.end_date ? moment(this.startWeekStrategy.end_date) : this.startWeekStrategy.moment.clone().endOf("isoWeek");
      this.type = this.startWeekStrategy?.strategy?.type || "fixed";
      this.allocationMethod = this.startWeekStrategy?.strategy?.type || "fixed";
      this.valueAmount = this.startWeekStrategy?.strategy?.value || 1000;
      this.valuePercent = this.startWeekStrategy?.strategy?.value || 10;
      this.previous_strategy = {
        type: this.type,
        start_date: moment(this.startWeek).format(`DD MM, 'YY`),
        end_date: moment(this.endWeek).format(`DD MM, 'YY`)
      };
    },
    onTypeChange(type) {
      this.type = type;
    },
    getInputValidationRuleForCurrency() {
      let known_currencies = ["EUR", "USD", "GBP", "JPY", "CAD", "AUD"];
      return known_currencies.includes(this.currency) ? this.currency + "_strategies_rule" : "USD_strategies_rule";
    },
    onAllocationMethodChange(method) {
      this.allocationMethod = method;
    },
    onDateModalVisible() {
      this.isDateModalVisible = true;
    },
    onWeeksSelected(result) {
      this.startWeek = result.startWeek.clone();
      this.endWeek = getWeekEnd(result.endWeek.clone());
      this.isDateModalVisible = false;
    },
    async onSubmit() {
      let filter_settings = {};
      let payload = [];
      try {
        this.throwIfError(this.isValid);
        this.loading = true;
        filter_settings = this.ppcSettingsFilters;
        const profileId = this.accountProfileIdGet(filter_settings);
        this.throwIfError(profileId);
        let seller_id = filter_settings.seller_id;
        let seller_name = this.sellers_filter.selected[0].name;
        let marketplace_id = amz_sp_api_marketplace_ids_country_domains_flipped[filter_settings.sales_channel.toLowerCase()];
        let country_code = country_codes_with_flags_and_marketplace_ids.find(el => el.marketplace_id === marketplace_id)?.country_code ?? "";
        let nextWeek = this.startWeek.clone();
        let start_date = nextWeek.format("YYYY-MM-DD");
        let end_date = this.endWeek.clone().format("YYYY-MM-DD");
        let strategy_id = seller_id + "_" + profileId + "_" + marketplace_id + "_" + start_date;
        const submit_type = this.isEdit ? "UPDATE" : "CREATE";

        // {
        //   "seller_id": "FakeSeller",
        //     "profile_id": "FakeProfileId",
        //       "marketplace_id": "FakeMarketPlaceId",
        //         "strategy_id": "FakeSeller_FakeProfileId_FakeMarketPlaceId_2024-01-22",
        //           "week": "2024-01-22",
        //             "strategy": { "type": "fixed", "value": 1000 },
        // }

        while (nextWeek.isSameOrBefore(this.endWeek)) {
          payload.push({
            seller_id: seller_id,
            seller_name,
            marketplace_id: marketplace_id,
            country_code,
            profile_id: profileId,
            strategy_id: strategy_id,
            week_id: seller_id + "_" + profileId + "_" + marketplace_id + "_" + nextWeek.format("YYYY-MM-DD"),
            start_date: start_date,
            start_date_formatted: moment(start_date).format("DD MMM, 'YY"),
            end_date: end_date,
            end_date_formatted: moment(end_date).format("DD MMM, 'YY"),
            week: nextWeek.format("YYYY-MM-DD"),
            strategy: {
              type: this.allocationMethod,
              value: this.allocationMethod == "fixed" ? parseFloat(this.valueAmount) : parseFloat(this.valuePercent) / 100.0,
              valueFormatted: this.allocationMethod == "fixed" ? `${this.getCurrencySymbol(this.currency)}${this.valueAmount}` : `${getFormattedPercentage(this.valuePercent / 100)}`
            }
          });
          nextWeek = nextWeek.clone().add(1, "week");
        }

        /*
           Algo definition:
          x---x---x---x---x---x
          Here, we need to calculate the diff between the originalState.endWeek and this.endWeek
           Note: before we continue, we will prompt the user to get their consent.
             if `this.endWeek` is `before` the `originalState.endWeek`
              |> We compute the `number of weeks` need to and delete;
              |> then we shortened the period for the strategy
                |-> Then we delete the rest of the periods using the first period
                    since the delete logic does the find of the range for us
                
              
             otherwise we do nothing
        */

        if (this.endWeek.isBefore(this.originalState.endWeek)) {
          let deleteStartWeek = this.endWeek.clone();
          let payload = [];
          while (deleteStartWeek.isSameOrBefore(this.originalState.endWeek)) {
            payload.push(this.weeksRange.find(({
              week
            }) => moment(week).isSame(deleteStartWeek, "isoWeek")));
            deleteStartWeek = deleteStartWeek.clone().add(1, "week");
          }
          const value = await this.confirmUserUpdate(); // Wait for the user's response;
          this.unWatchUpdateWatcher(); // Clean up the watcher
          if (value === "cancel") {
            return;
          }
          await window.$bus.trigger(EVENTS.DELETE_PPC_STRATEGY, payload[0]);
        }
        const existing_strategies = this.getLatestStrategies({
          seller_name,
          marketplace_id,
          country_code,
          start_date,
          end_date
        });
        await this.ppcStrategiesSave({
          data: payload,
          workspace_id: this.current_workspace.workspace_id,
          existing_strategies,
          submit_type,
          previous_strategy: this.previous_strategy
        });
        this.$emit("modal-close");
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS_BUDGET_STRATEGY);
        this.loading = false;
      } catch (error) {
        //console.log("error", error);
        window.$bus.trigger("showDanger", {
          message: "Error occured during Budget strategy creation",
          dismissSecs: 60,
          visible: true
        });
        this.$emit("modal-close");
        await window.$bus.trigger(EVENTS.REFRESH_PPC_SETTINGS_BUDGET_STRATEGY);
        this.loading = false;
      }
    },
    async confirmUserUpdate() {
      return new Promise(resolve => {
        this.confirmUpdateModal = true; // Show confirmation dialog/UI

        this.unWatchUpdateWatcher = this.$watch("updateConfirmed", newVal => {
          if (["cancel", "confirm"].includes(newVal)) {
            resolve(newVal);
          }
        }, {
          immediate: true
        });
      });
    },
    handleShown() {
      this.$refs.calendar.setupCalenderDetails();
      this.originalState = {
        startWeek: this.startWeek,
        endWeek: this.endWeek
      };
      this.$nextTick(() => {
        this.$refs.calendar.scrollTo(this.startWeek);
      });
    },
    closeDropdown() {
      this.$refs.calendarDropdown.close();
    },
    handleDateSubmit(val) {
      this.onWeeksSelected(val);
    },
    handleUpdateConfirmed() {
      this.updateConfirmed = "confirm";
    },
    handleUpdateRejected() {
      this.updateConfirmed = "cancel";
    },
    getLatestStrategies({
      seller_name,
      marketplace_id,
      country_code,
      start_date,
      end_date
    }) {
      const weeksWithStrategy = this.weeksRange.filter(el => moment(el.moment).isSameOrAfter(this.currentWeek, "isoweek") && "strategy" in el && !moment(start_date).isSame(moment(el.start_date)) && !moment(end_date).isSame(moment(el.end_date))).map(el => ({
        seller_name,
        marketplace_id,
        country_code,
        start_date_formatted: moment(el.start_date).format("DD MMM, 'YY"),
        end_date_formatted: moment(el.end_date).format("DD MMM, 'YY"),
        strategy_id: el.strategy_id,
        strategy: {
          ...el.strategy,
          valueFormatted: el.strategy.type == "fixed" ? `${this.getCurrencySymbol(this.currency)}${el.strategy.value}` : `${getFormattedPercentage(el.strategy.value)}`
        }
      })).slice(0, 10);

      //console.log("WeeskWithStrategy", makeArrayUnique(weeksWithStrategy));

      return makeArrayUnique(weeksWithStrategy);
    },
    handleValidValue(valid) {
      this.isValid = valid;
    }
  }
};