import { CurrencyValue, HugValue, NumberValue, PercentageValue } from '../tableElements';
export default {
  name: 'DataAndEvolutionRowValue',
  components: {
    HugValue,
    CurrencyValue,
    PercentageValue,
    NumberValue
  },
  props: {
    value: {
      type: Object
    },
    name: {
      type: String
    },
    loading: {
      type: Boolean
    },
    thStyles: {
      type: Array
    },
    level: {
      type: Number,
      default: 1
    },
    type: {
      type: String,
      // 'cumlative', 'normal', 'quantity'
      default: 'normal'
    },
    fields: {
      type: Array
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle'],
  methods: {
    getEvolutionColor(value) {
      const RED = '#DB333B';
      const GREEN = '#22863D';
      if ([0, null].includes(value)) {
        return 'auto';
      }
      if (this.type === 'cumlative' || this.isQuantity) {
        return value > 0 ? GREEN : RED;
      }
      return 'auto';
    },
    handleNameClick() {
      if (this.type !== 'cumlative') {
        this.$emit('toggle');
      }
    }
  },
  computed: {
    upperLevels() {
      return [1, 2].includes(this.level);
    },
    isQuantity() {
      return this.name === 'Quantity';
    }
  }
};