import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
export default {
  name: "AppSelect",
  mixins: [ValidateMixin],
  emits: ["update:modelValue", "submit", "change"],
  props: {
    modelValue: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    validateRule: {
      type: String,
      default: "none"
    },
    error: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    v2: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      id: "input-" + Math.floor(Math.random() * 900000) + 100000,
      validate: [{
        variable: "value",
        rule: this.validateRule
      }]
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
        this.$emit("change", val);
      }
    },
    inputState() {
      return !this.mValidateMessage("value") ? null : false;
    },
    _options() {
      const options = [...this.options];
      if (this.placeholder) {
        options.unshift({
          value: "",
          text: this.placeholder
        });
      }
      return options;
    }
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    }
  }
};