import WorkspaceInformation from "@/components/WorkspaceInformation";
export default {
  name: "WorkspaceDetailPage",
  emits: ["update-total"],
  components: {
    WorkspaceInformation
  },
  mounted() {
    this.$emit('update-total', {
      totalPages: 1,
      showPagination: false
    });
  }
};