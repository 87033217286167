import { TREND_DIRECTION_TYPE } from "@/utils/constants";
import HugValue from "./HugValue.vue";
import TrendElement from "./TrendElement.vue";
export default {
  name: "TrendWithValue",
  components: {
    HugValue,
    TrendElement
  },
  props: {
    color: {
      type: String,
      required: true,
      default: "#312783"
    },
    direction: {
      type: String,
      required: true,
      default: TREND_DIRECTION_TYPE.OFF
    },
    align: {
      type: String,
      required: false,
      default: "right"
    },
    id: {
      type: String,
      required: false,
      default: "trend-with-value"
    },
    value: {
      type: [Number, null],
      required: false,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedStyle() {
      return {
        justifyContent: this.align === "left" ? "flex-start" : "flex-end",
        gap: "8px"
      };
    }
  }
};