import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_seller_filter = _resolveComponent("seller-filter");
  const _component_marketplace_filter = _resolveComponent("marketplace-filter");
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createBlock(_component_FlexRow, {
    gap: 16
  }, {
    default: _withCtx(() => [_createVNode(_component_seller_filter, {
      sellers: _ctx.sellers_filter,
      onlyMode: true,
      onApply: $options.applySellers
    }, null, 8 /* PROPS */, ["sellers", "onApply"]), _createVNode(_component_marketplace_filter, {
      marketplaces: _ctx.marketplaces_filter,
      onlyMode: true,
      onApply: $options.applyMarketplaces
    }, null, 8 /* PROPS */, ["marketplaces", "onApply"])]),
    _: 1 /* STABLE */
  });
}