import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_percent_net_revenue_row_value = _resolveComponent("percent-net-revenue-row-value");
  const _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createBlock(_component_b_row, {
    class: _normalizeClass(['aggregate', 'percent-net-revenue', $props.type, {
      active: !$options.hideDetailsExpr
    }, {
      ['net-revenue']: $props.value.pl_aggregate === 'Net Revenue'
    }])
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "aggregate-row"
    }, {
      default: _withCtx(() => [_createVNode(_component_percent_net_revenue_row_value, {
        onToggle: $options.toggleAggregate,
        value: $options.dataTransform($props.value),
        align: "left",
        fields: $props.fields,
        thStyles: $data.thStyles,
        loading: $props.loading,
        open: !$options.hideDetailsExpr,
        type: $props.type,
        minMax: $props.minMax
      }, null, 8 /* PROPS */, ["onToggle", "value", "fields", "thStyles", "loading", "open", "type", "minMax"])]),
      _: 1 /* STABLE */
    }), $props.value.details?.length > 0 && !$options.hideDetailsExpr ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList($props.value.details, (detail, index) => {
      return _openBlock(), _createBlock(_component_b_row, {
        class: _normalizeClass(['details', {
          active: !$options.hideEvents(index)
        }]),
        key: index
      }, {
        default: _withCtx(() => [_createVNode(_component_b_row, {
          class: "detail-row"
        }, {
          default: _withCtx(() => [_createVNode(_component_percent_net_revenue_row_value, {
            onToggle: $event => $options.toggleDetails(index),
            value: $options.dataTransform(detail),
            fields: $props.fields,
            loading: $props.loading,
            level: 2,
            thStyles: $data.thStyles,
            open: !$options.hideEvents,
            minMax: $props.minMax
          }, null, 8 /* PROPS */, ["onToggle", "value", "fields", "loading", "thStyles", "open", "minMax"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */), detail.events?.length > 0 && !$options.hideEvents(index) ? (_openBlock(true), _createElementBlock(_Fragment, {
          key: 0
        }, _renderList(detail.events, (event, e_index) => {
          return _openBlock(), _createBlock(_component_b_row, {
            class: "events",
            key: e_index
          }, {
            default: _withCtx(() => [_createVNode(_component_percent_net_revenue_row_value, {
              value: $options.dataTransform(event),
              fields: $props.fields,
              loading: $props.loading,
              level: 3,
              thStyles: $data.thStyles,
              minMax: $props.minMax
            }, null, 8 /* PROPS */, ["value", "fields", "loading", "thStyles", "minMax"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */);
        }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]);
    }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}