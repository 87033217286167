export default {
  name: "FlexRow",
  props: {
    gap: {
      type: Number,
      default: 0
    },
    classes: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      default: ""
    },
    align: {
      type: String,
      default: "center"
    }
  },
  computed: {
    getComputedStyle() {
      const alignment = {
        left: "flex-start",
        right: "flex-end",
        center: "center"
      };
      return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: alignment[this.align],
        gap: this.gap + "px",
        height: "100%",
        ...this.styles
      };
    }
  }
};