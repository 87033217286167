import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 12 13`,
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M9.2854 10.5C9.9164 10.5 10.4284 9.9885 10.4284 9.357V7.0715L10.9999 6.5L10.4284 5.9285V3.643C10.4284 \n          3.0115 9.9169 2.5 9.2854 2.5M2.7145 2.5C2.083 2.5 1.5715 3.0115 1.5715 3.643V5.9285L1 6.5L1.5715 7.0715V9.357C1.5715 \n          9.9885 2.083 10.5 2.7145 10.5M3.75 6.5L4.96716 7.71716C5.06616 7.81616 5.11566 7.86566 5.17275 7.88421C5.22296 \n          7.90053 5.27704 7.90053 5.32725 7.88421C5.38434 7.86566 5.43384 7.81616 5.53284 7.71716L8.25 5",
    stroke: $props.color,
    "stroke-width": "1.2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}