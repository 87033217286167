import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createSlots as _createSlots, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loading_element = _resolveComponent("loading-element");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  return _openBlock(), _createBlock(_component_b_table_lite, {
    class: "lite-table lite-table-hoverable",
    fields: _ctx.fields,
    bordered: "",
    responsive: "",
    fixed: "",
    items: $data.items
  }, _createSlots({
    "thead-top": _withCtx(() => [_ctx.$slots['thead-top'] ? _renderSlot(_ctx.$slots, "thead-top", {
      key: 0
    }) : _createCommentVNode("v-if", true)]),
    "head()": _withCtx(col => [_createElementVNode("div", {
      class: "title",
      innerHTML: col?.label
    }, null, 8 /* PROPS */, _hoisted_1)]),
    "cell()": _withCtx(col => [_createVNode(_component_hug_value, null, {
      default: _withCtx(() => [_createVNode(_component_loading_element, {
        loading: "",
        style: _normalizeStyle({
          ...col.field.thStyle
        }),
        height: 26
      }, null, 8 /* PROPS */, ["style"])]),
      _: 2 /* DYNAMIC */
    }, 1024 /* DYNAMIC_SLOTS */)]),
    _: 2 /* DYNAMIC */
  }, [$props.showFooter ? {
    name: "custom-foot",
    fn: _withCtx(() => [_createElementVNode("tr", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, index) => {
      return _openBlock(), _createElementBlock("tr", {
        key: index
      }, [_createVNode(_component_hug_value, null, {
        default: _withCtx(() => [_createVNode(_component_loading_element, {
          loading: "",
          style: _normalizeStyle({
            ...field.thStyle
          }),
          height: 30
        }, null, 8 /* PROPS */, ["style"])]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)]);
    }), 128 /* KEYED_FRAGMENT */))])]),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["fields", "items"]);
}