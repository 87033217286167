import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_WrapWithOnlyButton = _resolveComponent("WrapWithOnlyButton");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = (...args) => $options.handleClick && $options.handleClick(...args)),
    onMouseenter: _cache[2] || (_cache[2] = $event => $data.showOnlyButton = true),
    onMouseleave: _cache[3] || (_cache[3] = $event => $data.showOnlyButton = false),
    class: _normalizeClass(`section-item ${$props.selected ? 'selected' : ''}`)
  }, [_createVNode(_component_WrapWithOnlyButton, {
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('selectOnly', {
      id: _ctx.id
    })),
    showButton: $options.computeShowButton
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["showButton"]), _createVNode(_component_FlexRow, null, {
    default: _withCtx(() => [$props.selected ? (_openBlock(), _createBlock(_component_AppIcon, {
      key: 0,
      icon: "close-table",
      size: 10,
      color: "#616161"
    })) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  })], 34 /* CLASS, NEED_HYDRATION */);
}