import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3c43d98d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "top"
};
const _hoisted_2 = {
  class: "navigation-tabs"
};
const _hoisted_3 = {
  class: "top-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_navigation_tabs = _resolveComponent("navigation-tabs");
  const _component_seller_filter = _resolveComponent("seller-filter");
  const _component_marketplace_filter = _resolveComponent("marketplace-filter");
  const _component_product_filter = _resolveComponent("product-filter");
  const _component_app_button = _resolveComponent("app-button");
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_navigation_tabs, {
    tabs: _ctx.tabs,
    modelValue: $options.tab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.tab = $event)
  }, null, 8 /* PROPS */, ["tabs", "modelValue"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_seller_filter, {
    sellers: _ctx.sellers_filter,
    select_key: _ctx.sellers_filter.key,
    search_key: $data.sellers_filter_key,
    onApply: $options.applySellers,
    onCleared: $options.clearSellers
  }, null, 8 /* PROPS */, ["sellers", "select_key", "search_key", "onApply", "onCleared"]), _createVNode(_component_marketplace_filter, {
    marketplaces: _ctx.marketplaces_filter,
    select_key: _ctx.marketplaces_filter.key,
    search_key: $data.marketplaces_filter_key,
    onApply: $options.applyMarketplaces,
    onCleared: $options.clearMarketplaces
  }, null, 8 /* PROPS */, ["marketplaces", "select_key", "search_key", "onApply", "onCleared"]), _createVNode(_component_product_filter, {
    products: _ctx.products_filter,
    marketplaces: _ctx.marketplaces_filter,
    sellers: _ctx.sellers_filter,
    onApply: $options.applyProducts,
    onSearch: $options.searchProducts,
    onCleared: $options.clearProducts
  }, null, 8 /* PROPS */, ["products", "marketplaces", "sellers", "onApply", "onSearch", "onCleared"]), _createVNode(_component_app_button, {
    onClick: $options.pushUpdates,
    version: "small",
    v2: "",
    class: "push-button"
  }, {
    default: _withCtx(() => [_createTextVNode(" Push " + _toDisplayString(_ctx.current_mode == "push_mode" ? _ctx.selected_contents.length ? "selected items" : "all" : "") + " updates to Amazon", 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"]), _createVNode(_component_app_button, {
    variant: "text",
    version: "small",
    v2: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_app_icon, {
      icon: "info-circle"
    })]),
    _: 1 /* STABLE */
  })])]);
}