import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_date_picker_selector = _resolveComponent("date-picker-selector");
  const _component_date_picker = _resolveComponent("date-picker");
  const _component_dropdown_body = _resolveComponent("dropdown-body");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createBlock(_component_b_dropdown, {
    ref: "dropdown",
    "no-caret": "",
    dropstart: "",
    toggleClass: "dropdown-selector",
    class: "date-picker-popup",
    menuClass: "datepicker-menu",
    onHidden: $options.handleHidden,
    onShown: $options.handleShown,
    offset: "8"
  }, {
    "button-content": _withCtx(() => [_createVNode(_component_date_picker_selector, {
      onClick: $options.handleToggle,
      loading: $data.loading,
      title: $props.datePickerDate?.selector.title,
      start: $props.datePickerDate?.selector?.start,
      end: $props.datePickerDate?.selector?.end,
      pillStyle: $props.pillStyle,
      dateStyle: {
        color: '#595959',
        fontWeight: 500,
        lineHeight: '12px',
        fontSize: '14px',
        textAlign: 'right'
      }
    }, null, 8 /* PROPS */, ["onClick", "loading", "title", "start", "end", "pillStyle"])]),
    default: _withCtx(() => [_createVNode(_component_dropdown_body, null, {
      default: _withCtx(() => [_createVNode(_component_date_picker, {
        ref: "datePicker",
        onCancel: $options.handleDatePickerCanel,
        onSubmit: $options.handleSubmit,
        date: $props.datePickerDate,
        weekType: _ctx.getSystemWeekType
      }, null, 8 /* PROPS */, ["onCancel", "onSubmit", "date", "weekType"])]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onHidden", "onShown"]);
}