import KpisControllersSection from '@/components/KpisControllersSection';
import KpisRenderer from '@/components/KpisRenderer';
import { periodsSwitches } from '@/components/shared/chartHelpers';
import { ProductPerformancePage, ProductAdvertisingPage, ProductPricingMarginPage, ProductInventoryCoveragePage } from '@/pages/subpages';
import DashboardMixin from '@/mixins/DashboardMixin';
import DatePickerMixin from '@/mixins/DatePickerMixin';
import { EVENTS } from '@/utils/constants';
import LoadingTable from '../components/LoadingTable.vue';
export default {
  name: 'ProductAnalyticsPage',
  async created() {
    this.$store.dispatch('dashboardSetCurrentTab', 'Product');
    this.$store.dispatch('dashboardSetCurrentView', 'Performance');
    this.defaultDatePickerDate = this.getDateFromRangeCompare();
    this.currentPeriod = this.defaultDatePickerDate;
    const sellers = await this.getOmnyAccountSellers();
    this.omnyAccountSellersCount = sellers.length;
    this.omnyAccountHasMoreThanOneSeller = sellers.length > 1;
    await this.initializeFilters({
      sellers
    });
    this.pageLoading = false;
    this.showProductPerformancePage = true;
  },
  components: {
    KpisRenderer,
    KpisControllersSection,
    ProductPerformancePage,
    ProductAdvertisingPage,
    ProductPricingMarginPage,
    ProductInventoryCoveragePage,
    LoadingTable
  },
  mixins: [DashboardMixin, DatePickerMixin],
  data() {
    return {
      countries: [],
      sellers: [],
      omnyAccountSellersCount: 0,
      omnyAccountHasMoreThanOneSeller: false,
      currentViewMinMax: {},
      tableLoading: true,
      errorOccured: false,
      totalLoading: true,
      searchLoading: false,
      totalWidths: [],
      noDataFound: false,
      currentPeriod: periodsSwitches.DAY_INTERVAL[0],
      defaultDatePickerDate: null,
      INVENTORY_COVERAGE_PERIOD: periodsSwitches.INVENTORY_COVERAGE,
      showProductPerformancePage: true,
      showProductInventoryCoveragePage: false,
      showProductPricingAndMarginPage: false,
      showProductAdvertisingPage: false,
      tabViewEnabler: {
        Product: {
          Performance: () => {
            this.resetAllTabs();
            this.showProductPerformancePage = true;
          },
          Advertising: () => {
            this.resetAllTabs();
            this.showProductAdvertisingPage = true;
          },
          'Inventory Coverage': () => {
            this.resetAllTabs();
            this.showProductInventoryCoveragePage = true;
          },
          'Pricing and Margin': () => {
            this.resetAllTabs();
            this.showProductPricingAndMarginPage = true;
          }
        }
      },
      refreshTriggers: {
        Product: {
          Performance: EVENTS.PRODUCT_PERFORMANCE,
          Advertising: EVENTS.PRODUCT_ADVERTISING,
          'Inventory Coverage': EVENTS.PRODUCT_INVENTORY_COVERAGE,
          'Pricing and Margin': EVENTS.PRODUCT_PRICING_MARGIN
        }
      },
      pageLoading: true
    };
  },
  computed: {
    getUpdatedTabFilters() {
      return this.getTabFilters({
        omnyAccountHasMoreThanOneSeller: this.omnyAccountHasMoreThanOneSeller,
        refreshTrigger: this.refreshTriggers[this.currentTab][this.currentView]
      });
    },
    getUpdatedPages() {
      return this.pages({
        omnyAccountHasMoreThanOneSeller: this.omnyAccountHasMoreThanOneSeller
      });
    },
    showDatePicker() {
      return !['Inventory Coverage', 'Pricing and Margin'].includes(this.currentView);
    }
  },
  methods: {
    handleTabChange(tab) {
      if (!this.currentTab) return;
      if (tab.name === this.currentTab) return;
      this.getUpdatedPages.forEach(page => {
        if (page.name === tab.name) {
          page.active = true;
          const view = this.graphControllers[page.name].views[0];
          this.handleViewChange(view);
          this.tabViewEnabler[tab.name][view.name]();
        } else {
          page.active = false;
        }
      });
    },
    handleViewChange(view) {
      // If we are on the same tab, and the same view, we don't do anything
      if (view.name === this.currentView) return;

      // Switch all graphControllers to false
      this.getGraphControllers.forEach(controller => {
        if (controller.name === view.name) {
          controller.active = true;
          this.tabViewEnabler[this.currentTab][view.name]();
        } else {
          controller.active = false;
        }
      });
    },
    resetAllTabs() {
      this.showProductPerformancePage = false;
      this.showProductAdvertisingPage = false;
      this.showProductInventoryCoveragePage = false;
      this.showProductPricingAndMarginPage = false;
    }
  }
};