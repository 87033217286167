import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["width", "height", "viewBox"];
const _hoisted_2 = {
  "clip-path": "url(#clip0_2370_75390)"
};
const _hoisted_3 = ["stroke"];
const _hoisted_4 = {
  id: "clip0_2370_75390"
};
const _hoisted_5 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_center_wrapper = _resolveComponent("center-wrapper");
  return _openBlock(), _createBlock(_component_center_wrapper, null, {
    default: _withCtx(() => [(_openBlock(), _createElementBlock("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: $props.width,
      height: $props.height,
      viewBox: `0 0 ${$props.width} ${$props.height}`,
      fill: "none"
    }, [_createElementVNode("g", _hoisted_2, [_createElementVNode("path", {
      d: "M5.9987 5.9987H6.00536M9.9987 9.9987H10.0054M10.6654 5.33203L5.33203 10.6654M6.33203 5.9987C6.33203 6.18279 6.18279 6.33203 5.9987 6.33203C5.8146 6.33203 5.66536 6.18279 5.66536 5.9987C5.66536 5.8146 5.8146 5.66536 5.9987 5.66536C6.18279 5.66536 6.33203 5.8146 6.33203 5.9987ZM10.332 9.9987C10.332 10.1828 10.1828 10.332 9.9987 10.332C9.8146 10.332 9.66536 10.1828 9.66536 9.9987C9.66536 9.8146 9.8146 9.66536 9.9987 9.66536C10.1828 9.66536 10.332 9.8146 10.332 9.9987ZM14.6654 7.9987C14.6654 11.6806 11.6806 14.6654 7.9987 14.6654C4.3168 14.6654 1.33203 11.6806 1.33203 7.9987C1.33203 4.3168 4.3168 1.33203 7.9987 1.33203C11.6806 1.33203 14.6654 4.3168 14.6654 7.9987Z",
      stroke: $props.color,
      "stroke-width": "1.4",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8 /* PROPS */, _hoisted_3)]), _createElementVNode("defs", null, [_createElementVNode("clipPath", _hoisted_4, [_createElementVNode("rect", {
      width: "16",
      height: "16",
      fill: $props.color
    }, null, 8 /* PROPS */, _hoisted_5)])])], 8 /* PROPS */, _hoisted_1))]),
    _: 1 /* STABLE */
  });
}