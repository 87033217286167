import FlexRow from "../../FlexRow.vue";
import FilterSectionTitle from "./FilterSectionTitle.vue";
import WrapWithSection from "./WrapWithSection.vue";
export default {
  name: "SelectedSection",
  components: {
    FlexRow,
    WrapWithSection,
    FilterSectionTitle
  },
  emits: ["clear", "apply", 'closeDropdown'],
  props: {
    title: {
      type: String,
      default: ""
    },
    count: {
      type: Number,
      default: 0
    },
    isModified: {
      type: Boolean,
      default: false
    },
    searching: {
      type: Boolean,
      default: false
    },
    heightFromTop: {
      type: String,
      default: "0px"
    }
  },
  computed: {
    formattedTitle() {
      return `${this.title} selected`.toUpperCase();
    }
  }
};