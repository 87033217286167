import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-14f30cae"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "standard"
};
const _hoisted_2 = {
  class: "td"
};
const _hoisted_3 = {
  class: "standard"
};
const _hoisted_4 = {
  class: "td"
};
const _hoisted_5 = {
  class: "product"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "info"
};
const _hoisted_8 = {
  class: "name"
};
const _hoisted_9 = {
  class: "asin-sku"
};
const _hoisted_10 = {
  class: "standard"
};
const _hoisted_11 = {
  class: "td center"
};
const _hoisted_12 = {
  class: "standard"
};
const _hoisted_13 = {
  class: "standard pastel-yellow"
};
const _hoisted_14 = {
  class: "td right"
};
const _hoisted_15 = {
  class: "standard"
};
const _hoisted_16 = {
  class: "standard purple"
};
const _hoisted_17 = {
  class: "td right"
};
const _hoisted_18 = {
  class: "standard gray"
};
const _hoisted_19 = {
  class: "td right"
};
const _hoisted_20 = {
  class: "standard"
};
const _hoisted_21 = {
  class: "td center"
};
const _hoisted_22 = {
  class: "td right"
};
const _hoisted_23 = {
  key: 1,
  class: "raw-box"
};
const _hoisted_24 = {
  class: "raw-top"
};
const _hoisted_25 = {
  class: "raw-text"
};
const _hoisted_26 = {
  key: 0,
  class: "raw-days"
};
const _hoisted_27 = {
  class: "standard fake-height"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_checkbox = _resolveComponent("app-checkbox");
  const _component_app_switch = _resolveComponent("app-switch");
  const _component_demand_forecasting_row_velocity = _resolveComponent("demand-forecasting-row-velocity");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_demand_forecasting_row_inventory = _resolveComponent("demand-forecasting-row-inventory");
  const _component_demand_forecasting_row_manual = _resolveComponent("demand-forecasting-row-manual");
  return _openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(['app-row', {
      selected: $props.selected,
      'slow-mover': $options.isSlowMover,
      'muted': !$props.item.orderable
    }]),
    onClick: _cache[2] || (_cache[2] = (...args) => $options.onSelect && $options.onSelect(...args))
  }, [_createElementVNode("td", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_app_checkbox, {
    modelValue: $options.realSelected,
    disabled: !$props.item.orderable
  }, null, 8 /* PROPS */, ["modelValue", "disabled"])])]), _createElementVNode("td", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("img", {
    src: $props.item.main_image_link,
    alt: ""
  }, null, 8 /* PROPS */, _hoisted_6), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString($props.item.product_name), 1 /* TEXT */), _createElementVNode("div", _hoisted_9, _toDisplayString($props.item.asin) + " - " + _toDisplayString($props.item.sku), 1 /* TEXT */)])])])]), _createElementVNode("td", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_app_switch, {
    modelValue: $props.item.orderable,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $props.item.orderable = $event),
    onChange: $options.onOrderable
  }, null, 8 /* PROPS */, ["modelValue", "onChange"])])]), _createElementVNode("td", _hoisted_12, [_createVNode(_component_demand_forecasting_row_velocity, {
    values: $options.velocity,
    "slow-mover": $options.isSlowMover
  }, null, 8 /* PROPS */, ["values", "slow-mover"])]), _createElementVNode("td", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(Math.round($props.item.weighted_30d_velocity)), 1 /* TEXT */)]), _createElementVNode("td", _hoisted_15, [_createElementVNode("div", {
    class: _normalizeClass(["td right", {
      'boost-green': ($options.additionalBoost ?? 0) > 0,
      'boost-red': ($options.additionalBoost ?? 0) < 0
    }])
  }, [($options.additionalBoost ?? 0) >= 50 || ($options.additionalBoost ?? 0) <= -50 ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "info",
    color: ($options.additionalBoost ?? 0) >= 50 ? '#1A662E' : '#AD1F25'
  }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString($options.additionalBoost && $props.settings.boost_active ? `${$options.additionalBoost}%` : '-'), 1 /* TEXT */)], 2 /* CLASS */)]), _createElementVNode("td", _hoisted_16, [_createElementVNode("div", _hoisted_17, _toDisplayString($options.weighted30DSalesForecast), 1 /* TEXT */)]), _createElementVNode("td", _hoisted_18, [_createElementVNode("div", _hoisted_19, _toDisplayString($props.item.available_inventory), 1 /* TEXT */), _createVNode(_component_demand_forecasting_row_inventory, {
    item: $props.item
  }, null, 8 /* PROPS */, ["item"])]), _createElementVNode("td", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("div", {
    class: _normalizeClass(['days-left', {
      'yellow': $options.isSlowMover,
      'green': !$options.isSlowMover && $options.daysLeft >= $options.totalCoverage,
      'red': !$options.isSlowMover && $options.daysLeft < $options.totalCoverage
    }])
  }, _toDisplayString($options.daysLeft), 3 /* TEXT, CLASS */)])]), _createElementVNode("td", {
    class: _normalizeClass(["standard", !$options.isSlowMover ? $options.rawQuantityToSend == 0 ? 'green' : 'purple' : ''])
  }, [_createElementVNode("div", _hoisted_22, [$options.rawQuantityToSend == 0 ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "like"
  })) : (_openBlock(), _createElementBlock("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [_createVNode(_component_app_icon, {
    icon: "ai",
    size: 14
  }), _createElementVNode("div", _hoisted_25, _toDisplayString($options.rawQuantityToSend), 1 /* TEXT */)]), !$options.isSlowMover && $options.rawQuantityToSendDays > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString($options.rawQuantityToSendDays) + "D", 1 /* TEXT */)) : _createCommentVNode("v-if", true)]))])], 2 /* CLASS */), _createElementVNode("td", _hoisted_27, [_createVNode(_component_demand_forecasting_row_manual, {
    modelValue: $props.item.orderable_units,
    "onUpdate:modelValue": [_cache[1] || (_cache[1] = $event => $props.item.orderable_units = $event), $options.onOrderableUnits],
    item: $props.item,
    weighted30DSalesForecast: $options.weighted30DSalesForecast,
    coverageDays: $options.daysLeft,
    totalCoverage: $options.totalCoverage,
    slowMover: $options.isSlowMover,
    onClear_manual_override: $options.onClearManualOverride
  }, null, 8 /* PROPS */, ["modelValue", "item", "weighted30DSalesForecast", "coverageDays", "totalCoverage", "slowMover", "onUpdate:modelValue", "onClear_manual_override"])]), _createCommentVNode(" <td class=\"standard\">\n      <div class=\"td center\">\n        <div class=\"settings-button\" @click.stop=\"onSettings\">\n          <app-icon icon=\"settings\" :size=\"14\" color=\"#616161\" />\n        </div>\n      </div>\n    </td> ")], 2 /* CLASS */);
}