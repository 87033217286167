import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6d7e12d6"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["contenteditable", "innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['category-item', $options.getClassPerType, {
      custom: $props.custom,
      active: $props.active,
      edit: $props.edit
    }]),
    onClick: _cache[0] || (_cache[0] = e => {
      if (!$props.active) $options.handleCategoryClick(e);
    })
  }, [$props.score > 0 && !$props.edit ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['category-id', $options.getClassPerType])
  }, _toDisplayString($props.score), 3 /* TEXT, CLASS */)) : _createCommentVNode("v-if", true), $props.category || $props.edit ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(['category', $options.getClassPerType, {
      noId: $props.score < 0,
      active: $props.active,
      edit: $props.edit
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(['category-name', {
      edit: $props.edit,
      active: $props.active
    }]),
    contenteditable: $props.edit,
    innerHTML: $props.category,
    ref: "category_name"
  }, null, 10 /* CLASS, PROPS */, _hoisted_1), _createElementVNode("div", {
    class: _normalizeClass([{
      custom: $props.custom
    }, 'd-flex justify-content-center align-items-center category-icon'])
  }, [!$props.active && !$props.edit ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "plus",
    size: 12,
    color: this.type == 'product_benefits' ? '#312783' : '#B75B15'
  }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true), $props.active && $props.edit ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 1,
    icon: "cross",
    size: "12",
    color: "#A5AAB0",
    onClick: $options.unsetCategoryEdit,
    class: "icon-active"
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true), $props.active && $props.edit ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 2,
    icon: "check2",
    size: "12",
    color: "#22863D",
    onClick: $options.editCategory,
    class: "icon-active"
  }, null, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)], 2 /* CLASS */)) : _createCommentVNode("v-if", true), $props.active && !$props.edit ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    class: _normalizeClass([{
      custom: $props.custom
    }, 'category-icon active', $options.getClassPerType])
  }, [$props.active && !$props.edit ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "edit-pencil",
    size: "12",
    onClick: $options.setCategoryEdit,
    color: $options.getIconColor,
    class: "icon-active"
  }, null, 8 /* PROPS */, ["onClick", "color"])) : _createCommentVNode("v-if", true), $props.active && !$props.edit ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 1,
    icon: "trash",
    size: "12",
    onClick: $options.handleCategoryClick,
    color: $props.type == 'product_usecases' ? '#EB4750' : '#F07A80',
    class: "icon-active"
  }, null, 8 /* PROPS */, ["onClick", "color"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createCommentVNode("div class=\"dragger\">\n      <app-icon icon=\"dragger\" />\n    </div")], 2 /* CLASS */);
}