import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b69eabac"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['multi-mode-selector-item', {
      active: $props.active
    }, {
      disabled: $props.disabled
    }, {
      previous: $props.previous
    }]),
    onClick: _cache[0] || (_cache[0] = () => {
      if (!$props.disabled) _ctx.$emit('click', _ctx.$event);
    })
  }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 2 /* CLASS */);
}