import { mapGetters, mapActions } from "vuex";
import CompetitorsModalTable from "@/components/CompetitorsModalTable";
import FlexRow from "@/components/FlexRow.vue";
import FlexColumn from "@/components/FlexColumn.vue";
import AppChoiceMaker from "@/components/AppChoiceMaker.vue";
import AiCategorySectionTitle from "@/components/AiCategorySectionTitle.vue";
export default {
  name: "AiGenerationMarketDefinition",
  emits: ["changeTab", "selectedCompetitors"],
  components: {
    CompetitorsModalTable,
    FlexRow,
    FlexColumn,
    AppChoiceMaker,
    AiCategorySectionTitle
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selected_strategy: "",
      competitors: [],
      selected_competitors: [],
      loading_category: false,
      isDisabled: true
    };
  },
  // computed: {
  //   ...mapGetters(["getCountryFlag"]),
  // },
  methods: {
    ...mapActions(["categoryDefinition"]),
    onNext() {
      this.$emit("changeTab", "product_data");
    },
    async onSelectStrategy() {
      const payload = {
        category_id: this.product.category_id,
        marketplace_id: this.product.marketplace_id
      };
      this.loading_category = true;
      this.competitors = await this.categoryDefinition(payload);
      if (this.competitors) {
        this.loading_category = false;
      }
    },
    async onSelectCompetitors(competitors) {
      this.selected_competitors = competitors;
      this.$emit("selectedCompetitors", this.selected_competitors);
      //console.log(this.selected_competitors);
    }
  },
  mounted() {
    this.selected_strategy = "category";
    this.onSelectStrategy();
  }
};