import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_dot_element = _resolveComponent("dot-element");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    id: $props.id,
    styles: $options.getComputedStyle,
    loading: $props.loading,
    align: $options.computedAlignment
  }, {
    default: _withCtx(() => [$options.showDot ? (_openBlock(), _createBlock(_component_dot_element, {
      key: 0,
      size: $props.size,
      color: $props.color
    }, null, 8 /* PROPS */, ["size", "color"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["id", "styles", "loading", "align"]);
}