import KpiCard from "@/components/KpiCard.vue";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import { PercentageValue, NumberValue, periodTableHeaders, periodTableHeadersForSecondaryFilters, CurrencyValue } from "@/components/ppc";
import PeriodTable from "@/components/ppc/PeriodTable.vue";
import PPCManagementMixin from "../mixins/PPCManagementMixin.vue";
import CurrentPeriod from "@/components/ppc/CurrentPeriod.vue";
import ProductTable from "@/components/ppc/ProductTable.vue";
import FlexColumn from "@/components/FlexColumn.vue";
import { EVENTS } from "@/utils/constants";
import PPCTopSection from "@/components/PPCTopSection.vue";
import PPCMoreFilters from "@/components/ppc/PPCMoreFilters.vue";
import PpcSettingsFilters from "@/components/ppc/PpcSettingsFilters.vue";
import PpcSettings from "@/components/ppc/PpcSettings.vue";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import { generateColorBasedOnState, generateThresholds } from "@/utils/transformers";
import { DATE_RANGES_FROM_SELECTOR, DATE_PICKER_COMPARE_TITLES, getDatePickerRangeCompare, DATE_PICKER_RANGE_TITLES, getDatePickerUIObjectFromDateRange } from "@/utils/datepicker";
export default {
  name: "PPCManagementPage",
  components: {
    KpiCard,
    PercentageValue,
    NumberValue,
    PeriodTable,
    CurrencyValue,
    CurrentPeriod,
    ProductTable,
    FlexColumn,
    PPCTopSection,
    PPCMoreFilters,
    PpcSettingsFilters,
    PpcSettings
  },
  mixins: [PPCManagementMixin, LiteTableMixin],
  beforeUnmount() {
    // Reset the `padded` to true to add padding in `TheWrapperPrivate.innerPage` padding
    this.$store.dispatch("setPadded", true);
    window.$bus.trigger(EVENTS.OPEN_SIDEBAR);
    window.$bus.off(EVENTS.REFRESH_PPC_MANAGEMENT, async () => await this.refreshMediator(["KPIS", "PERIOD_TABLE", "PRODUCT_TABLE"]));
    window.$bus.off(EVENTS.PPC_HANDLE_COLLAPSED, this.handleCollapsed);
    window.$bus.off(EVENTS.SET_MORE_FILTERS, this.setMoreFilters);
  },
  async mounted() {
    // Add events
    window.$bus.on(EVENTS.PPC_HANDLE_COLLAPSED, this.handleCollapsed);
    window.$bus.trigger(EVENTS.CLOSE_SIDEBAR);
    window.$bus.on(EVENTS.SET_MORE_FILTERS, this.setMoreFilters);

    // Setup Filters
    const sellers = await this.getOmnyAccountSellers();
    await this.initializeFilters({
      sellers
    }, "ppc", {
      seller: "sellers",
      marketplace: "marketplaces",
      product: "products",
      match_type: "match_types",
      campaign: "campaigns",
      targeting: "targetings"
    }, "ppc");
    await this.getOmnyAccountProfileIds(sellers);
    this.$store.dispatch("ppcSetProductTableGroupByElements");

    // Get default date picker

    this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
      ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
      rangeTitle: this.periodGroupBy,
      compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
    });

    // Set the default date picker date
    this.storeDatePickerDate(this.defaultDatePickerDate);

    // Setup all the data using Promise.all
    await this.refreshMediator(["KPIS", "PERIOD_TABLE"]);

    // Set all the loading to false
    this.setAllLoading(false);
    window.$bus.on(EVENTS.REFRESH_PPC_MANAGEMENT, async () => await this.refreshMediator(["KPIS", "PERIOD_TABLE", "PRODUCT_TABLE"]));
  },
  data() {
    return {
      periodTableLoading: true,
      productTableLoading: true,
      kpisLoading: true,
      defaultDatePickerDate: {},
      collasped: false,
      openModal: false,
      activateSettings: true,
      hideSettingsFooterButtons: true,
      rightStripFeatureName: "ppcsettingsfeature",
      currentPeriodTable: {
        weekly: moment().startOf("isoWeek"),
        monthly: moment().startOf("month"),
        daily: moment().subtract(1, "days")
      },
      moreFilters: false,
      mediatorRefreshers: {
        KPIS: this.refreshKpis,
        PERIOD_TABLE: this.refreshPeriodTable,
        PRODUCT_TABLE: this.refreshProductTable
      },
      DATE_RANGES_FROM_SELECTOR
    };
  },
  computed: {
    ...mapState({
      periodTable: state => state.ppc.periodTable,
      kpis: state => state.ppc.kpis,
      productTable: state => state.ppc.productTable,
      periodTableGroupBy: state => state.ppc.periodTable.periodText,
      sellers: state => state.ppc.sellers,
      marketplaces: state => state.ppc.marketplaces,
      campaigns: state => state.ppc.campaigns,
      match_types: state => state.ppc.match_types,
      targetings: state => state.ppc.targetings,
      currencyConfig: state => state.ppc.currencyConfig
    }),
    ...mapGetters(["getSystemWeekType"]),
    currentPeriod() {
      // Get PeriodTable period from the store
      //console.log("PeriodTable", this.periodTableGroupBy);
      const {
        groupBy,
        title,
        dateFormat,
        currentPeriodDate
      } = this.periodTable[this.periodTableGroupBy];
      const convertName = {
        "Current week": "Week-to-date vs Same period Last Week",
        "Current month": "Month-to-date vs Same period Last Month",
        Yesterday: "Yesterday vs Same day Last Week"
      };
      return {
        key: this.periodTableGroupBy,
        name: convertName[groupBy],
        period: currentPeriodDate,
        format: dateFormat,
        title
      };
    },
    getPeriodTableItems() {
      if (this.periodTableLoading) {
        return new Array(12).fill({});
      }
      return this.$store.getters.ppcGetPeriodTableItems();
    },
    getPeriodTableMinMax() {
      return this.$store.getters.ppcGetPeriodTableMinMax();
    },
    ppcFilters() {
      let filters = {
        seller_id: this.$store.getters["ppcGetSellers"](),
        sales_channel: this.$store.getters["ppcGetMarketplaces"](),
        campaigns_list: this.$store.getters["ppcGetCampaigns"](),
        targeting_list: this.$store.getters["ppcGetTargetings"](),
        keyword_id_list: this.$store.getters["ppcGetKeywords"](),
        match_type_list: this.$store.getters["ppcGetMatchTypes"](),
        sku_list: this.$store.getters["ppcGetSkus"](),
        asin_list: this.$store.getters["ppcGetAsins"]()
      };
      return filters;
    },
    ppcSettingsFilter() {
      return {
        seller_id: this.ppcFilters.seller_id[0],
        sales_channel: this.ppcFilters.sales_channel[0]
      };
    },
    getThresholds() {
      return (field, minMax, fields) => {
        return generateThresholds(minMax?.[field]?.max, fields.find(f => f.threshold)?.threshold);
      };
    },
    getHeatMapThresholds() {
      return (field, fields, excludes) => {
        if (excludes.includes(field)) {
          return fields?.find(f => f.key === field)?.heatmap_ranges ?? [];
        } else {
          return [];
        }
      };
    },
    getPeriodTableThresholds() {
      return field => {
        return this.getThresholds(field, this.getPeriodTableMinMax, [...this.getPeriodTableFields]);
      };
    },
    getPeriodTableHeatmapThresholds() {
      return field => {
        return this.getHeatMapThresholds(field, this.getPeriodTableFields, ["CVR", "CPC", "best_position"]);
      };
    },
    getProductTableHeatMapThresholds() {
      return field => {
        return this.getHeatMapThresholds(field, this.getProductTableFields, ["CVR", "CPC", "keyword_bid"]);
      };
    },
    getProductTableThresholds() {
      return field => {
        return this.getThresholds(field, this.getProductTableMinMax, [...this.getProductTableFields]);
      };
    },
    getProductTableMinMax() {
      return this.productTable.groupBys[this.productTableGroupByText]?.minMax;
    },
    getProductTableMaxCharCount() {
      return this.productTable.groupBys[this.productTableGroupByText]?.maxCharCount;
    },
    getProductTableItems() {
      if (this.productTableLoading) {
        return new Array(24).fill({});
      }
      return this.productTable.groupBys[this.productTableGroupByText]?.items;
    },
    getProductTableTotal() {
      const total = this.productTable.groupBys?.[this.productTableGroupByText]?.total;
      if (!total) {
        return {};
      }
      return total[0];
    },
    getProductTableSort() {
      return this.productTable.groupBys?.[this.productTableGroupByText]?.sort;
    },
    getProductTableFields() {
      return this.productTable.groupBys?.[this.productTableGroupByText]?.fields;
    },
    getDatePickerDate() {
      // Get datePickerTitle from the store
      return this.productTableCurrentPeriod;
    },
    productTableGroupByText() {
      return this.$store.getters.ppcGetProductTableGroupByText();
    },
    periodTableCurrentPeriod() {
      return this.$store.getters.ppcGetPeriodTablePeriod();
    },
    productTableCurrentPeriod() {
      return this.$store.getters.ppcGetProductTablePeriod();
    },
    accountState() {
      return this.$store.state.ppc.accountState;
    },
    productTableState() {
      return this.$store.getters.ppcGetProductTableState();
    },
    getPeriodTableFields() {
      return this.$store.getters.ppcGetPeriodTableFields();
    },
    periodGroupBy() {
      return this.getPeriodGroupFromCurrentPeriod(this.periodTable, this.periodTableGroupBy);
    }
  },
  methods: {
    generateColorBasedOnState,
    async refreshKpis() {
      this.kpisLoading = true;
      await this.$store.dispatch("ppcFetchKpis");
      this.kpisLoading = false;
    },
    async refreshPeriodTable() {
      // When secondary filters are active, we return `periodTableHeadersForSecondaryFilters`
      // Secondary filters are defined by either `campaign`, `targeting` or `match_type` selected
      // being more than zero.
      if ([this.campaigns.isEmpty, this.targetings.isEmpty, this.match_types.isEmpty].includes(false)) {
        this.$store.dispatch("ppcSetPeriodTableFields", periodTableHeadersForSecondaryFilters);
      } else {
        this.$store.dispatch("ppcSetPeriodTableFields", periodTableHeaders);
      }
      this.periodTableLoading = true;
      await this.$store.dispatch("ppcFetchPeriodTableData");
      this.periodTableLoading = false;
    },
    async refreshProductTable() {
      this.productTableLoading = true;
      const groupBy = this.productTableGroupByText;
      const groupByFetch = this.productTable.groupBys[groupBy]?.fetch;
      if (groupByFetch) await this.$store.dispatch(groupByFetch);
      this.productTableLoading = false;
    },
    async refreshMediator(refresheeList) {
      let promises = refresheeList.map(refreshee => this.mediatorRefreshers[refreshee]());
      await Promise.all(promises);
    },
    handleProductTableSort({
      sort,
      direction
    }) {
      this.$store.dispatch("ppcSetProductTableSort", {
        sort,
        direction
      });
      this.refreshMediator(["PRODUCT_TABLE"]);
    },
    handleSetDateRange(date) {
      if (!date) {
        this.handleDateChange(date);
        return;
      }
      const {
        range,
        compare
      } = this.DATE_RANGES_FROM_SELECTOR[this.periodTableGroupBy](date);
      const datePickerDate = getDatePickerUIObjectFromDateRange({
        range,
        compare,
        rangeTitle: DATE_PICKER_RANGE_TITLES.CUSTOM,
        compareTitle: DATE_PICKER_COMPARE_TITLES.CUSTOM
      });
      this.handleDateChange(datePickerDate);
    },
    storeDatePickerDate(date) {
      this.$store.dispatch("ppcSetProductTablePeriod", date);
      window.$bus.trigger(EVENTS.RESET_DATE_PICKER, date);
    },
    handleDateChange(date) {
      if (!date) {
        date = this.defaultDatePickerDate;
      }
      this.storeDatePickerDate(date);
      this.refreshMediator(["KPIS", "PRODUCT_TABLE"]);
    },
    handleAutoScroll() {
      this.$nextTick(() => {
        window.$bus.trigger(EVENTS.PPC_STRATEGY_AUTO_SCROLL);
      });
    },
    handleCollapsed(value) {
      this.collasped = value;
    },
    toggleMoreFilters() {
      this.moreFilters = !this.moreFilters;
    },
    setMoreFilters(value) {
      this.moreFilters = value;
    },
    setAllLoading(value) {
      this.periodTableLoading = value;
      this.productTableLoading = value;
      this.kpisLoading = value;
    },
    productTableSetCount(count) {
      this.$store.dispatch("ppcSetProductTableTotalPages", count);
    },
    async handleSideOpened() {
      this.openModal = true;
    },
    handleRefreshDatePicker() {
      this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
        ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, this.periodGroupBy, this.getSystemWeekType),
        rangeTitle: this.periodGroupBy,
        compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
      });
      this.storeDatePickerDate(this.defaultDatePickerDate);
    },
    getFeatureName() {
      return "ppcsettingsfeature";
    }
  }
};