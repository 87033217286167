import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6e2d158e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "total-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_b_td = _resolveComponent("b-td");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fields, field => {
    return _openBlock(), _createBlock(_component_b_td, {
      key: field.key,
      class: _normalizeClass([{
        ['b-table-sticky-column']: $options.isTotalColumn(field)
      }])
    }, {
      default: _withCtx(() => [_createVNode(_component_hug_value, {
        class: "total-element",
        styles: {
          justifyContent: field.justify,
          padding: '8px'
        },
        loading: $props.loading
      }, {
        default: _withCtx(() => [$options.isTotalColumn(field) ? (_openBlock(), _createElementBlock("span", _hoisted_1, "TOTAL")) : _createCommentVNode("v-if", true), field.key === 'sponsored_sales' ? (_openBlock(), _createBlock(_component_currency_value, {
          key: 1,
          value: $props.total.sponsored_sales,
          limit: 10_000,
          config: $props.currencyConfig
        }, null, 8 /* PROPS */, ["value", "config"])) : _createCommentVNode("v-if", true), field.key === 'spend' ? (_openBlock(), _createBlock(_component_currency_value, {
          key: 2,
          value: $props.total.spend,
          limit: 10_000,
          config: $props.currencyConfig
        }, null, 8 /* PROPS */, ["value", "config"])) : _createCommentVNode("v-if", true), field.key === 'clicks' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 3,
          value: $props.total.clicks,
          styles: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'ACOS' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 4,
          value: $props.total.ACOS
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'TACOS' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 5,
          value: $props.total.TACOS
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'units_sold_clicks_14_d' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 6,
          value: $props.total.units_sold_clicks_14_d,
          styles: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'ROAS' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 7,
          value: $props.total.ROAS,
          precision: 1,
          styles: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'CPC' ? (_openBlock(), _createBlock(_component_currency_value, {
          key: 8,
          value: $props.total.CPC,
          precision: 2,
          config: $props.currencyConfig,
          styles: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value", "config"])) : _createCommentVNode("v-if", true), field.key === 'CVR' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 9,
          value: $props.total.CVR,
          limit: 0.1
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'sponsored_asin' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 10,
          value: $props.total.sponsored_asin,
          styles: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'keyword_bid' ? (_openBlock(), _createBlock(_component_currency_value, {
          key: 11,
          value: $props.total.keyword_bid,
          precision: 2,
          config: $props.currencyConfig
        }, null, 8 /* PROPS */, ["value", "config"])) : _createCommentVNode("v-if", true)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles", "loading"])]),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]);
  }), 128 /* KEYED_FRAGMENT */);
}