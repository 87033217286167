import AppModal from '@/components/AppModal';
import ImageManager from '@/components/ImageManager';
export default {
  name: 'ImageManagerModal',
  emits: ['update:modelValue', 'update', 'submit'],
  components: {
    AppModal,
    ImageManager
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    imageModel: {
      get() {
        return this.image;
      },
      set(val) {
        this.$emit('update', val);
      }
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.image);
    }
  }
};