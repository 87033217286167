import FlexRow from "@/components/FlexRow.vue";
import AppIcon from "@/components/AppIcon.vue";
export default {
  name: "WrapWithArrow",
  components: {
    FlexRow,
    AppIcon
  },
  props: {
    direction: {
      type: String
    },
    id: {
      type: String,
      default: ""
    }
  },
  computed: {
    getArrowDirection() {
      //console.log("direction", this.direction);
      const direction = this.direction?.toLowerCase();
      const directions = {
        "desc": "down",
        "asc": "up",
        "down": "down",
        "up": "up"
      };
      return directions[direction];
    }
  }
};