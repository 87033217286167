import SearchElement from "../SearchElement.vue";
import WrapWithDropdownBody from "../WrapWithDropdownBody.vue";
import { SelectedSection } from "../sections";
import ListSection from "../sections/ListSection.vue";
import MarketplaceFilterItem from "../items/MarketplaceFilterItem.vue";
export default {
  name: "MarketplaceFilterBody",
  components: {
    WrapWithDropdownBody,
    SearchElement,
    SelectedSection,
    ListSection,
    MarketplaceFilterItem
  },
  emits: ["select", "selectOnly", "deselect", "clear", "search", "apply", "hidden", "closeDropdown"],
  props: {
    title: {
      type: String,
      default: ""
    },
    selected: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    isModified: {
      type: Boolean,
      default: false
    },
    searching: {
      type: Boolean,
      default: false
    }
  }
};