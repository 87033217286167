import WrapWithCopy from "@/components/WrapWithCopy.vue";
import AppPopover from "@/components/AppPopover";
import AppIcon from "@/components/AppIcon";
import Popper from "vue3-popper";
import AppTd from "@/components/AppTd";
import WrapWithStatusIcon from "@/components/seo/WrapWithStatusIcon.vue";
import ProductScore from "@/components/ProductScore";
export default {
  name: "SeoPushStatusCell",
  props: {
    item: Object,
    pushModal: Boolean
  },
  emits: ['onConfirmPushSingleContent'],
  components: {
    AppIcon,
    AppPopover,
    WrapWithCopy,
    Popper,
    AppTd,
    WrapWithStatusIcon,
    ProductScore
  },
  data() {
    return {
      evaluationStatusPossibleToPush: ["no_content_on_omny_and_sc_difference_from_amazon", "no_content_on_omny_and_sc_difference_from_amazon_but_some_amazon_attribute_empty", "content_on_omny_difference_from_amazon_and_amazon_equal_sc_without_push", "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_push", "content_on_omny_difference_from_amazon_and_amazon_difference_sc_without_push", "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_sync_error", "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_old_push_contribution_modified", "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_old_push_direct_modification", "content_on_omny_equal_sc_and_omny_difference_from_amazon_with_push", "content_on_omny_diffrence_from_sc_and_sc_difference_amazon_with_push"]
    };
  },
  computed: {
    isPossibleToPush() {
      return item => {
        const unpushableStatus = ["processing", "sync_finished", "accepted", "unknown"];
        return this.evaluationStatusPossibleToPush.includes(item.content_evaluation_status[0]) && !unpushableStatus.includes(item.statusPush.status) || item.statusPush.status == "draft";
      };
    }
  },
  methods: {
    getPushStatusClass(item) {
      return `seo-popover ${item.statusPush.status}`;
    }
  }
};