import { request } from "@/store";
import Filter from "@/store/filter";
var _ = require("lodash");
const product_data = {
  state: {
    list: [],
    download_list: [],
    total_rows: null,
    sellers_filter: new Filter([], [], "id"),
    products_filter: new Filter([], [], "id"),
    keyword_list: []
  },
  mutations: {
    productDataSellersFilterSet(state, payload) {
      state.sellers_filter = payload;
    },
    productDataProductsFilterSet(state, payload) {
      state.products_filter.initialize({
        list: payload,
        selected: [],
        name: "product_data_suggestions_filter",
        key: "content_id"
      });
    },
    productDataKeywordsListSet(state, payload) {
      state.keyword_list = payload;
    },
    productDataListSet(state, payload) {
      state.list = payload;
    },
    productDataListSetDownload(state, payload) {
      state.download_list = payload;
    },
    filteredProductDataListSet(state, payload) {
      state.list = state.list.map(item => {
        let product_data_items = payload.find(product_data => product_data.sku === item.sku);
        return {
          ..._.mergeWith({}, item, product_data_items, (o, s) => _.isNull(s) ? o : s),
          orderable: product_data_items?.orderable === false ? false : true
        };
      });
    },
    filteredProductDataListSetDownload(state, payload) {
      state.download_list = state.download_list.map(item => {
        let product_data_items = payload.find(product_data => product_data.sku === item.sku);
        return {
          ..._.mergeWith({}, item, product_data_items, (o, s) => _.isNull(s) ? o : s),
          orderable: product_data_items?.orderable === false ? false : true
        };
      });
    },
    productDataTotalRowSet(state, payload) {
      state.total_rows = payload;
    }
  },
  getters: {
    productDataSellersFilterValuesIdsGet(state) {
      return () => {
        return state.sellers_filter.filterValues;
      };
    },
    productDataGetProductSkus(state) {
      return () => {
        return state.list.map(product => product.sku);
      };
    },
    productDataGetProductAsins(state) {
      return () => {
        return state.list.map(product => product.asin);
      };
    },
    productDataGetProductIds(state) {
      return () => {
        return state.list.map(product => [product.seller_id, product.asin, product.sku]);
      };
    }
  },
  actions: {
    async getAllProductDataList({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_data_by_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("filteredProductDataListSet", result.data);
      }
      return result;
    },
    async getAllProductDataListToDownload({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_data_by_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        //console.log("[getAllProductDataListToDownload] result");
        //console.log(result.data);
        commit("filteredProductDataListSetDownload", result.data);
      }
      return result;
    },
    async getProductDataList({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_data_by_seller_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("productDataListSet", result.data);
        commit("productDataTotalRowSet", result.total_rows);
      }
      return result;
    },
    async getProductDataSuggestionList({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_data_by_seller_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("productDataProductsFilterSet", result.data);
      }
      return result;
    },
    async getProductDataListToDownload({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_data_by_seller_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("productDataListSetDownload", result.data);
        commit("productDataTotalRowSet", result.total_rows);
      }
      return result;
    },
    async dfUpdateProductData({
      state
    }, payload) {
      let result = await request("/inventory/update_product_data", "POST", payload);
      return result;
    },
    async dfproductDataList({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_data_by_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("filteredProductDataListSet", result.data);
      }
      return result;
    },
    // async uploadProductDataCSV({ state }, file) {
    //   let formData = new FormData();
    //   formData.append('file', file);
    //   let result = await request('/inventory/uploadcsv', 'POST', formData);
    //   return result;
    // },
    /*     async uploadProductDataCSV({ state }, file) {
          const chunkSize = 5 * 1024 * 1024; // 5MB
          const totalChunks = Math.ceil(file.size / chunkSize);
          
          for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);
            const formData = new FormData();
      
            formData.append('file', chunk, `${file.name}-part-${chunkIndex}`);
            formData.append('index', chunkIndex);
            formData.append('totalChunks', totalChunks);
      
            try {
              const response = await request('/inventory/uploadcsv', 'POST', formData);
              //console.log("[instant response from server when clicked upload]");
              const result = await response.json();
    
              if (!result.task_id) {
                throw new Error('Upload failed');
              }
              //console.log(`Chunk ${chunkIndex + 1} of ${totalChunks} uploaded successfully`, result);
         
            } catch (error) {
              console.error(`Error uploading chunk ${chunkIndex + 1}`, error);
              //return { error: "Couldnt process your CSV data"};
            }
          }
          return { success: "CSV data has been successfully processed"};
        }, */

    async uploadProductDataCSV({
      state
    }, payload) {
      //console.log("[Inside uploadProductDataCSV]");
      //console.log("[chunk index]");

      //console.log(payload.chunkIndex);

      const formData = new FormData();
      const blob = new Blob([payload.chunk], {
        type: 'text/plain'
      });
      formData.append('file', blob);
      formData.append('file_name', payload.fileName);
      formData.append('file_type', payload.fileType);
      formData.append('total_chunks', payload.totalChunks);
      formData.append('chunk_index', payload.chunkIndex);
      try {
        const response = await request('/inventory/uploadcsv', 'POST', formData, false);
        //console.log("[instant response from server when clicked upload]");
        //const result = await response.json();
        //console.log(response.task_status);
        //console.log(response.task_id);

        if (!response.task_id) {
          throw new Error('Upload failed');
        }
        //console.log(`Chunk ${payload.chunkIndex + 1} of ${payload.totalChunks} uploaded successfully`, response);
        return response;
      } catch (error) {
        console.error(`Error uploading chunk ${payload.chunkIndex + 1}`, error);
      }
      //return { status: response.status,task_id:response.task_id};
    }
  }
};
export default product_data;