import { mapState, mapGetters, mapActions } from "vuex";
import ConnectionPageTitle from "@/components/ConnectionPageTitle";
import TasksMixin from "@/mixins/TasksMixin.vue";
import AppCardConnections from "@/components/AppCardConnections";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import AppModal from "@/components/AppModal";
import CreateMemberSideBar from "@/components/CreateMemberSideBar";
import AppPagination from "@/components/AppPagination.vue";
export default {
  name: "MembersPage",
  emits: ["update-total"],
  mixins: [TasksMixin],
  components: {
    ConnectionPageTitle,
    AppCardConnections,
    AppButton,
    AppIcon,
    AppModal,
    CreateMemberSideBar,
    AppPagination
  },
  data() {
    return {
      dataLoading: true,
      removeLoading: false,
      searchPlaceHolder: "Search",
      loading: false,
      confirmRemoveModal: false,
      removeId: null,
      createMemberSideBar: false,
      inviteSent: false,
      memberCreateTaskIdentifier: "",
      invitedMemberEmail: ""
    };
  },
  props: {
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 20
    }
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      me: state => state.auth.me,
      members: state => state.member.list,
      current_workspace: state => state.workspace.current_workspace,
      isOmnyAdmin: state => state.auth.isOmnyAdmin,
      isClientAdmin: state => state.auth.isClientAdmin
    }),
    ...mapGetters(["workspaceById"]),
    // isLoggedIn() {
    //   return !! this.token
    // },
    memberRole() {
      return member => {
        const result = [];
        for (const role of member.roles) {
          switch (role) {
            case "omny_viewer_role":
              result.push("Member");
              break;
            case "omny_admin_role":
              result.push("Admin");
              break;
            default:
              result.push("Unknown");
              break;
          }
        }
        return result.join(", ");
      };
    },
    memberNameById() {
      return memberId => {
        for (const member of this.members) {
          if (member.id == memberId) {
            return this.memberFullName(member);
          }
        }
        return "";
      };
    },
    memberFullName() {
      return member => {
        return (member.firstName || " ") + " " + (member.lastName || " ");
      };
    },
    workspace() {
      return this.workspaceById(this.current_workspace.workspace_id);
    },
    workspaceName() {
      return this.workspace ? this.workspace?.workspace_name : "";
    },
    canAddMember() {
      return this.isOmnyAdmin || this.isClientAdmin;
    },
    memberStatusText() {
      return member => {
        return member.enabled ? "Active" : "Invitation sent";
      };
    },
    memberStatusIconColor() {
      return member => {
        return member.enabled ? "#22863D" : "#8F710A";
      };
    },
    totalPages() {
      return this.members.length;
    },
    paginatedMembers() {
      const startIndex = (this.page - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.members.slice(startIndex, endIndex);
    }
  },
  async mounted() {
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      await this.workspaceList();
      await this.memberList({
        workspace_id: this.current_workspace.workspace_id
      });
      window.$bus.on("refreshMemberList", this.refreshMemberList);
      this.memberCreateTaskIdentifier = this.workspace.workspace_id + "_" + "member_create";
      this.getAsyncUpdate(this.memberCreateTaskIdentifier);
      this.checkTaskStatusPeriodically(this.memberCreateTaskIdentifier);
      this.updateTotalData();
      this.dataLoading = false;
    }
  },
  methods: {
    ...mapActions(["memberCreate", "workspaceList", "memberList", "memberRemove", "authStatus"]),
    onRemoveConfirm(member_id) {
      this.removeId = member_id;
      this.confirmRemoveModal = true;
    },
    async onRemove() {
      this.confirmRemoveModal = false;
      this.removeLoading = true;
      await this.memberRemove({
        workspace_id: this.current_workspace.workspace_id,
        user_id: this.removeId,
        workspace_name: this.workspaceName
      });
      this.removeLoading = false;
      this.removeId = null;
    },
    async refreshMemberList() {
      await this.memberList({
        workspace_id: this.current_workspace.workspace_id
      });
    },
    canDeleteMember(member) {
      return (this.isOmnyAdmin || this.isClientAdmin) && !this.isMemberSameAsLoggedInUser(member);
    },
    isMemberSameAsLoggedInUser(member) {
      return this.me.email == member.email;
    },
    async onResendInvitation(member) {
      var members = [member.email];
      this.invitedMemberEmail = member.email;
      await this.sendInvitation(members);
      this.loading = true;
      this.inviteSent = true;
    },
    async sendInvitation(members) {
      var params = {
        members: members,
        workspace_id: this.workspace.workspace_id,
        workspace_name: this.workspace.workspace_name,
        task_type: "member_create"
      };
      const result = await this.memberCreate(params);
      if (result) {
        this.executeTaskStatusHandler(result);
        this.checkTaskStatusPeriodically(this.memberCreateTaskIdentifier);
      }
    },
    handleIdle() {
      this.loading = false;
      this.inviteSent = false;
    },
    handleFailed() {
      this.loading = false;
      this.inviteSent = false;
    },
    handleRunning() {
      this.loading = true;
      this.inviteSent = true;
      this.checkTaskStatusPeriodically(this.memberCreateTaskIdentifier);
    },
    handleFinished() {
      this.loading = false;
      this.inviteSent = false;
      this.invitedMemberEmail = null;
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
    },
    async onSendMemberInvitations(members) {
      await this.sendInvitation(members);
      this.loading = true;
      this.inviteSent = true;
    },
    updatePerPage(perPage) {
      this.page = 1;
      this.perPage = perPage;
    },
    updateTotalData() {
      this.$emit('update-total', {
        totalPages: this.members.length,
        showPagination: true
      });
    }
  }
};