import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_IntercomScript = _resolveComponent("IntercomScript");
  const _component_feature = _resolveComponent("feature");
  const _component_the_wrapper_private = _resolveComponent("the-wrapper-private");
  return $data.initializationFinished ? (_openBlock(), _createBlock(_component_the_wrapper_private, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view), _createVNode(_component_feature, {
      name: $options.getFeatureName()
    }, {
      default: _withCtx(() => [$data.is_authenticated ? (_openBlock(), _createBlock(_component_IntercomScript, {
        key: 0,
        appId: $data.intercomAppId,
        userName: $data.userName,
        userEmail: $data.userEmail,
        showPopup: !$data.is_authenticated
      }, null, 8 /* PROPS */, ["appId", "userName", "userEmail", "showPopup"])) : _createCommentVNode("v-if", true)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["name"])]),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true);
}