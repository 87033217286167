import FlexColumn from "@/components/FlexColumn.vue";
import DotElement from "@/components/tableElements/DotElement.vue";
import HugValue from "@/components/tableElements/HugValue.vue";
import FlagValue from "@/components/tableElements/FlagValue.vue";
import CampaignsCount from "@/components/tableElements/CampaignsCount.vue";
import CampaignIdCount from "@/components/tableElements/CampaignIdCount.vue";
export default {
  name: "ProductGroupValue",
  components: {
    FlexColumn,
    HugValue,
    FlagValue,
    CampaignsCount,
    CampaignIdCount,
    DotElement
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: '100%'
    },
    loading: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: "left"
    }
  }
};