import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["width", "height", "viewBox"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_center_wrapper = _resolveComponent("center-wrapper");
  return _openBlock(), _createBlock(_component_center_wrapper, null, {
    default: _withCtx(() => [(_openBlock(), _createElementBlock("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: $props.width,
      height: $props.height,
      viewBox: `0 0 ${$props.width} ${$props.height}`,
      fill: "none"
    }, [_createElementVNode("path", {
      d: "M4.00025 14.5L5.57489 20.7985C5.61918 20.9757 5.64132 21.0643 5.66751 21.1416C5.92341 21.8971 6.60376 22.4283 \n                7.39876 22.4933C7.48015 22.5 7.57144 22.5 7.75403 22.5C7.98268 22.5 8.09701 22.5 8.19332 22.4906C9.14524 22.3983 \n                9.89858 21.645 9.9909 20.693C10.0002 20.5967 10.0002 20.4824 10.0002 20.2538V5.99997M18.5002 14C20.4332 14 22.0002 \n                12.433 22.0002 10.5C22.0002 8.56698 20.4332 6.99997 18.5002 6.99997M10.2502 5.99997H6.50024C4.01496 5.99997 2.00024 \n                8.01469 2.00024 10.5C2.00025 12.9853 4.01496 15 6.50024 15H10.2502C12.0167 15 14.1775 15.9469 15.8445 16.8556C16.8171 \n                17.3858 17.3033 17.6508 17.6218 17.6118C17.9171 17.5757 18.1404 17.4431 18.3136 17.2011C18.5002 16.9401 18.5002 16.418 \n                18.5002 15.3737V5.62626C18.5002 4.58197 18.5002 4.05982 18.3136 3.79886C18.1404 3.55687 17.9171 3.42427 17.6218 \n                3.3881C17.3033 3.34909 16.8171 3.61417 15.8445 4.14433C14.1775 5.05309 12.0167 5.99997 10.2502 5.99997Z",
      stroke: $props.color,
      "stroke-width": "1.69",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1))]),
    _: 1 /* STABLE */
  });
}