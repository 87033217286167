const breadcrumb = {
  state: {
    breadcrumbs: [{
      text: 'Dashboard',
      to: '/'
    }]
  },
  mutations: {
    setBreadcrumbs(state, payload) {
      state.breadcrumbs = payload;
    }
  },
  actions: {
    setBreadcrumbs({
      commit
    }, payload) {
      commit('setBreadcrumbs', payload);
    }
  },
  getters: {
    getCurrentBreadCrumbs(state) {
      return () => {
        return state.breadcrumbs;
      };
    }
  }
};
export default breadcrumb;