import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-44872558"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "filter-subsection-wrapper"
};
const _hoisted_2 = {
  class: "scrollable"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  key: 2
};
const _hoisted_7 = ["onClick", "title"];
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
  key: 2,
  id: "name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_filter_section_header = _resolveComponent("filter-section-header");
  const _component_empty_space = _resolveComponent("empty-space");
  const _component_CenterWrapper = _resolveComponent("CenterWrapper");
  const _component_product_sku_asin_item = _resolveComponent("product-sku-asin-item");
  const _component_coutry_filter_item = _resolveComponent("coutry-filter-item");
  const _component_seller_filter_item = _resolveComponent("seller-filter-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_filter_section_header, {
    header: $props.header,
    showButton: $props.enableSelectAll,
    buttonText: "Select All",
    onClick: $options.handleSelectAll
  }, null, 8 /* PROPS */, ["header", "showButton", "onClick"]), _createElementVNode("div", _hoisted_2, [$props.values.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_CenterWrapper, null, {
    default: _withCtx(() => [_createVNode(_component_empty_space, {
      text: "No data",
      size: "120"
    })]),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true), $props.filterTitle === 'Product' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.values, (item, p_index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "custom-dropdown-item",
      key: p_index,
      id: "parent"
    }, [_createVNode(_component_product_sku_asin_item, {
      item: item,
      breakName: true,
      truncate: true,
      truncateLength: 100,
      handleSelect: e => {
        $options.innerHandleSelect(e, item);
      },
      showTitle: true,
      ref_for: true,
      ref: "parent"
    }, null, 8 /* PROPS */, ["item", "handleSelect"]), _createElementVNode("div", {
      id: "only",
      onClick: e => {
        e.stopPropagation();
        $options.innerHandleSelect(e, item, true);
      },
      class: "select-only product"
    }, " Only ", 8 /* PROPS */, _hoisted_5)]);
  }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.values, (item, a_index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "custom-dropdown-item",
      onClick: e => $options.innerHandleSelect(e, item),
      id: "parent",
      key: a_index,
      title: item.long_name ? item.long_name : item.name
    }, [_createElementVNode("div", {
      onClick: e => {
        e.stopPropagation();
        $options.innerHandleSelect(e, item, true);
      },
      id: "only",
      class: "select-only"
    }, " Only ", 8 /* PROPS */, _hoisted_8), $props.filterTitle === 'Country' ? (_openBlock(), _createBlock(_component_coutry_filter_item, {
      key: 0,
      item: item
    }, null, 8 /* PROPS */, ["item"])) : $props.filterTitle === 'Sellers' ? (_openBlock(), _createBlock(_component_seller_filter_item, {
      key: 1,
      item: item
    }, null, 8 /* PROPS */, ["item"])) : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(item.name), 1 /* TEXT */))], 8 /* PROPS */, _hoisted_7);
  }), 128 /* KEYED_FRAGMENT */))]))])]);
}