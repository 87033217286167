import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_image_manager = _resolveComponent("image-manager");
  const _component_app_modal = _resolveComponent("app-modal");
  return _openBlock(), _createBlock(_component_app_modal, {
    modelValue: $options.modal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.modal = $event),
    title: "Images",
    "ok-loading": $data.loading,
    "ok-title": "Ok",
    onOk: $options.onSubmit
  }, {
    default: _withCtx(() => [_createVNode(_component_image_manager, {
      modelValue: $options.imageModel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.imageModel = $event)
    }, null, 8 /* PROPS */, ["modelValue"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "ok-loading", "onOk"]);
}