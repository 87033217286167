import { request } from "./index";
import Filter from "@/store/filter";
import { sortData, hasKey, updatedField, getCountriesMapping } from "@/utils";
import { country_amz_sp_api_marketplace_ids, country_code_amz_sp_api_marketplace_ids, country_codes_flipped, country_codes } from "@/utils/countries";
import ContentDisplayRulesMixin from "@/mixins/ContentDisplayRulesMixin.vue";
const content = {
  state: {
    list: [],
    existing_all_market_place_list: [],
    item: {},
    modified_list: [],
    keyword_list: [],
    images: [],
    selected_contents: [],
    current_mode: "default",
    pushModal: false,
    sellers_filter: new Filter([], [], "id"),
    marketplaces_filter: new Filter([], [], "id"),
    products_filter: new Filter([], [], "id"),
    sellers: {
      list: [],
      selected: [],
      modified_list: [],
      modified_selected: []
    },
    country: {
      list: [],
      selected: [],
      modified_list: [],
      modified_selected: []
    },
    contentSuggestion: {
      list: [],
      selected: [],
      modified_list: [],
      modified_selected: []
    },
    total_count: 0,
    page: 1,
    perPage: 20,
    title_limit_exceeded: false,
    keyword_limit_exceeded: false
  },
  getters: {
    contentKeyWordsGet(state) {
      return () => {
        return state.keyword_list;
      };
    },
    getCountry() {
      return marketplace_id => {
        for (const key in country_amz_sp_api_marketplace_ids) {
          if (marketplace_id == country_amz_sp_api_marketplace_ids[key]) {
            return key;
          }
        }
        return null;
      };
    },
    getCountryCode() {
      return country => {
        if (hasKey(country_codes, country)) {
          ////console.log(`The key '${country_code}' exists.`);
          return country_codes[country];
        }
        return null;
      };
    },
    getMarketPlaceIdByCountryCode() {
      return country_code => {
        if (hasKey(country_code_amz_sp_api_marketplace_ids, country_code)) {
          ////console.log(`The key '${country_code}' exists.`);
          return country_code_amz_sp_api_marketplace_ids[country_code];
        }
        return null;
      };
    },
    getCountryFlag() {
      return (country_code, type = "shiny") => {
        if (country_code) {
          return `https://flagsapi.com/${country_code}/${type}/64.png`;
        }
        return null;
      };
    },
    getItemFromExistingMarketPlaceList(state) {
      return params => {
        const item = state.existing_all_market_place_list.find(el => el.marketplace_id == params.marketplace_id);
        return item ? item : null;
      };
    },
    contentItem(state) {
      return params => {
        const item = state.list.find(el => el.content_id == params.content_id);
        return item ? item : null;
      };
    },
    contentSellersListGet(state) {
      return () => {
        return state.sellers_filter.filterValues;
      };
    },
    modifiedContentSellersListGet(state) {
      return () => {
        return state.sellers.modified_list;
      };
    },
    modifiedContentSellersSelectedGet(state) {
      return () => {
        return state.sellers.modified_selected;
      };
    },
    modifiedContentCountriesListGet(state) {
      return () => {
        return state.country.modified_list;
      };
    },
    modifiedContentCountriesSelectedGet(state) {
      return () => {
        return state.marketplaces_filter.filterValues;
      };
    },
    modifiedContentSuggestionsListGet(state) {
      return () => {
        return state.contentSuggestion.modified_list;
      };
    },
    modifiedContentSuggestionsSelectedGet(state) {
      return () => {
        return state.contentSuggestion.modified_selected;
      };
    },
    contentCountriesListGet(state) {
      return () => {
        return state.country.list;
      };
    },
    contentCountriesSelectedGet(state) {
      return () => {
        return state.marketplaces_filter.filterValues;
      };
    },
    contentSuggestionsListGet(state) {
      return () => {
        return state.products_filter.filterValues;
      };
    },
    contentSuggestionsSelectedGet(state) {
      return () => {
        return state.products_filter.selectedItems;
      };
    },
    totalCountGet(state) {
      return () => {
        return state.total_count;
      };
    },
    totalModifiedCountGet(state) {
      return () => {
        return state.total_modified_count;
      };
    },
    modifiedContentUpdatedSellersGet: (_, getters) => () => {
      const sellers = getters.modifiedContentSellersSelectedGet().length ? getters.modifiedContentSellersSelectedGet() : getters.modifiedContentSellersListGet();
      return sellers.map(seller => seller.id);
    },
    contentUpdatedSellersGet: state => () => {
      return state.sellers_filter.filterValues;
    },
    contentFiltersSelectedGet(state, getters) {
      return () => {
        return {
          seller_list: getters.contentUpdatedSellersGet(),
          marketplace_list: getters.contentCountriesSelectedGet(),
          asin_list: getters.contentSuggestionsSelectedGet().map(item => item.asin).filter(element => !!element),
          sku_list: getters.contentSuggestionsSelectedGet().map(item => item.sku).filter(element => !!element),
          product_list: [],
          keyword_list: getters.contentKeyWordsGet().filter(element => !!element)
        };
      };
    },
    contentFiltersSuggestionsGet(state, getters) {
      return () => {
        return {
          seller_list: getters.contentUpdatedSellersGet(),
          marketplace_list: getters.contentCountriesSelectedGet(),
          asin_list: [],
          sku_list: [],
          product_list: [],
          keyword_list: getters.contentKeyWordsGet().filter(element => !!element)
        };
      };
    },
    modifiedContentsSelectedGet(state, getters) {
      return () => {
        let specific_content = state.modified_list.filter(item => getters.contentUpdatedSellersGet().some(seller => seller.seller_id == item.seller_id) && getters.contentCountriesSelectedGet().some(country_marketplace_id => country_marketplace_id == item.marketplace_id) && getters.contentSuggestionsSelectedGet().some(suggestion => suggestion.asin == item.asin) || getters.contentSuggestionsSelectedGet().some(suggestion => suggestion.sku == item.sku));
        return specific_content.length > 0 ? specific_content : state.modified_list;
      };
    }
    //To be deleted soon; this idea has been abandoned
    // modifiedContentFiltersSelectedGet(state, getters) {
    //   return () => {
    //     return {
    //       seller_list: getters.modifiedContentUpdatedSellersGet(),

    //       marketplace_list: getters
    //         .modifiedContentCountriesSelectedGet()
    //         .map((country) => country.code)
    //         .map((countryCode) =>
    //           getters.getMarketPlaceIdByCountryCode(countryCode)
    //         ),

    //       asin_list: getters
    //         .modifiedContentSuggestionsSelectedGet()
    //         .map((item) => item.asin),

    //       sku_list: getters
    //         .modifiedContentSuggestionsSelectedGet()
    //         .map((item) => item.sku),

    //       product_list: getters
    //         .modifiedContentSuggestionsSelectedGet()
    //         .map((item) => item.product_name),
    //     };
    //   };
    // },
  },
  mutations: {
    contentsListSet(state, payload) {
      state.list = payload;
    },
    contentSelectedContentsSet(state, payload) {
      state.selected_contents.push(payload);
    },
    contentCurrentModeSet(state, payload) {
      state.current_mode = payload;
    },
    contentPushModalStatusSet(state, payload) {
      state.pushModal = payload;
    },
    contentExistingMarketPlaceListSet(state, payload) {
      state.existing_all_market_place_list = payload;
    },
    contentModifiedSellersListSet(state, payload) {
      state.sellers.modified_list = payload;
    },
    contentModifiedCountriesListSet(state, payload) {
      state.country.modified_list = payload;
    },
    contentModifiedListSet(state, payload) {
      state.modified_list = payload;
    },
    contentPageSet(state, payload) {
      state.page = payload;
    },
    contentPerPageSet(state, payload) {
      state.perPage = payload;
    },
    contentModifiedSuggestionsListSet(state, payload) {
      state.contentSuggestion.modified_list = payload;
    },
    contentModifiedSellersSelectedSet(state, payload) {
      state.sellers.modified_selected = payload;
    },
    contentModifiedCountriesSelectedSet(state, payload) {
      state.country.modified_selected = payload;
    },
    contentModifiedContentsSelectedSet(state, payload) {
      state.contentSuggestion.modified_selected = payload;
    },
    contentFieldSet(state, payload) {
      state.list = state.list.map(item => {
        if (item.asin == payload.asin && item.content_id === payload.content_id) {
          if (payload.type == "image") {
            item.image_small = payload.value;
            item.image_medium = payload.value;
            item.image_large = payload.value;
          } else {
            item.omny_version[payload.type] = payload.value;
            item.modified_attributes ??= [];
            item.prev_push_status ??= item.push_status;
            const bullet_point_attributes = ["bullet_point_1", "bullet_point_2", "bullet_point_3", "bullet_point_4", "bullet_point_5"];
            let attribute_to_push = bullet_point_attributes.includes(payload.type) ? "bullet_point" : payload.type;
            if (ContentDisplayRulesMixin.methods.isOmnyVersionSameAsAmazonWithCaseSensitive(item, payload.type) || ContentDisplayRulesMixin.methods.isOmnyVersionEmptyString(item, payload.type)) {
              item.modified_attributes = item.modified_attributes.filter(attr => attr !== attribute_to_push);
              item.push_status = item.modified_attributes.length > 0 ? item.push_status : item.prev_push_status;
            } else {
              item = ContentDisplayRulesMixin.methods.updateModifiedAttributes(item, attribute_to_push);
            }
          }
        }
        return item;
      });
    },
    contentSmartListingFieldSet(state, payload) {
      state.list = state.list.map(item => {
        if (item.asin == payload.asin && item.content_id === payload.content_id) {
          item.smart_listing_data ??= {};
          item.smart_listing_data[payload.type] = payload.value;
        }
        return item;
      });
    },
    contentUpdateAfterPush(state, payload) {
      state.list = state.list.map(item => {
        let accumulated_item = item;
        if (item.asin == payload.asin && item.content_id === payload.content_id) {
          //destructing assignment
          accumulated_item = {
            ...item,
            ...payload
          };
        }
        return accumulated_item;
      });
    },
    imageListSet(state, payload) {
      state.images = payload;
    },
    contentSellersListSet(state, payload) {
      state.sellers.list = payload;
    },
    contentSellersSelectedSet(state, payload) {
      state.sellers.selected = payload;
    },
    contentCountriesListSet(state, payload) {
      state.country.list = sortData(payload);
    },
    contentCountriesSelectedSet(state, payload) {
      state.country.selected = sortData(payload);
    },
    contentSuggestionsListSet(state, payload) {
      state.contentSuggestion.list = sortData(payload, "product_name");
    },
    contentSuggestionsSelectedSet(state, payload) {
      state.contentSuggestion.selected = sortData(payload, "product_name");
    },
    contentTotalCountSet(state, payload) {
      let total_count = payload[0]?.total_count ?? 0;
      state.total_count = total_count;
    },
    totalModifiedCountSet(state, payload) {
      state.total_modified_count = payload.length;
    },
    contentsKeyWordListSet(state, payload) {
      state.keyword_list = payload;
    },
    contentsModifiedListSet(state, payload) {
      state.modified_list = payload;
    },
    setTitleLimitExceeded(state, value) {
      state.title_limit_exceeded = value;
    },
    setKeywordsLimitExceeded(state, value) {
      state.keyword_limit_exceeded = value;
    }
  },
  actions: {
    async contentModifiedList({
      commit
    }, payload) {
      commit("contentModifiedSellersListSet", []);
      commit("contentModifiedCountriesListSet", []);
      commit("contentModifiedSuggestionsListSet", []);
      commit("totalModifiedCountSet", []);
      let result = (await request("/content/getmodifiedcontentlist", "POST", payload)) ?? {
        data: [],
        suggesticontentListons: []
      };
      if (result) {
        commit("contentModifiedListSet", result.data);
        //   commit("contentModifiedCountriesListSet", data);
        //   commit("contentModifiedSuggestionsListSet", data);
        //   commit("totalModifiedCountSet", data);
      }
      return result;
    },
    async contentGetCountries({
      commit,
      getters
    }) {
      try {
        const payload = {
          seller_list: getters.contentUpdatedSellersGet()
        };
        if (!Array.isArray(payload.seller_list) || payload.seller_list.length === 0) {
          throw new Error("Seller ID must be a non-empty array");
        }
        const result = (await request("/content/getcountries", "POST", payload)) ?? {
          data: []
        };
        if (result.data) {
          let localCountriesMapping = await getCountriesMapping();
          let filteredCountries = localCountriesMapping.filter(localCountriesMapping => result.data.some(data => data.country_code === localCountriesMapping.code));
          commit("contentCountriesListSet", filteredCountries);
        }
        return result.data;
      } catch (error) {
        //console.log("Error: ", error);
        throw new Error(error);
      }
    },
    async contentGetStatus({
      commit
    }, payload) {
      try {
        //console.log("payload: ", payload);
        let result = (await request("/content/getstatus", "POST", payload)) ?? {
          data: []
        };
        //console.log("result: ", result);
        return result.data;
      } catch (error) {
        //console.log("Error: ", error);
        throw new Error(error);
      }
    },
    async contentGetByFilters({
      commit
    }, payload) {
      let result = (await request("/content/getcontentlist", "POST", payload)) ?? {
        data: [],
        suggestions: []
      };
      return result;
    },
    async contentGetByContentId({
      commit
    }, payload) {
      let result = (await request("/content/getcontentlistbyid", "POST", payload)) ?? {
        data: [],
        suggestions: []
      };
      return result;
    },
    async contentGetExistingMarketPlaceList({
      commit
    }, payload) {
      //console.log("[contentGetExistingMarketPlaceList]");
      let result = (await request("/content/getcontentlist", "POST", payload)) ?? {
        data: [],
        suggestions: []
      };
      if (result) {
        commit("contentExistingMarketPlaceListSet", result.data);
      }
      return result;
    },
    async contentGetFromRainforest(_, payload) {
      let result = await request("/content/getcontentfromrainforest", "POST", payload);
      return result;
    },
    async contentUpdate({
      state
    }, params) {
      const item = state.list.find(el => el.content_id == params.content_id);
      let bullet_point_to_push = [ContentDisplayRulesMixin.methods.contentToDisplay(item, "bullet_point_1"), ContentDisplayRulesMixin.methods.contentToDisplay(item, "bullet_point_2"), ContentDisplayRulesMixin.methods.contentToDisplay(item, "bullet_point_3"), ContentDisplayRulesMixin.methods.contentToDisplay(item, "bullet_point_4"), ContentDisplayRulesMixin.methods.contentToDisplay(item, "bullet_point_5")];
      item.omny_version.bullet_point = bullet_point_to_push;
      let payload = {
        seller_id: item.seller_id,
        marketplace_id: item.marketplace_id,
        sku: item.sku,
        asin: item.asin,
        cvr_score: item.cvr_score,
        vendor_code: item.vendor_code,
        vendor_sku: item.vendor_sku,
        ean: item.ean,
        product_type: item.product_type,
        image_small: item.image_small,
        image_medium: item.image_medium,
        image_large: item.image_large,
        content_id: item.content_id,
        modified_attributes: item.modified_attributes,
        push_status: item.push_status,
        prev_push_status: item.prev_push_status,
        last_push_to_amazon_time: item?.last_push_to_amazon_time,
        sync_errors: item.sync_errors,
        omny_version: item.omny_version
      };
      const data = await request("/content/update", "POST", payload);
      return data;
    },
    async contentPush(_, payload) {
      const data = await request("/content/push", "POST", payload);
      return data;
    },
    async contentDelete(_, payload) {
      const data = await request("/content/delete", "POST", payload);
      return data;
    },
    async contentGet(_, payload) {
      const data = await request("/content/get", "POST", payload);
      if (data) {
        return data;
      }
    },
    async contentGetTranslation(_, payload) {
      const data = await request("/content/asynctranslate", "POST", payload);
      if (data) {
        return data;
      }
    },
    async simpleTranslationGet(_, payload) {
      const data = await request("/content/simple_translate", "POST", payload);
      if (data) {
        return data?.translation;
      }
    },
    async contentGetSuggestedKeywords(_, payload) {
      const data = await request("/content/getsuggestedkeywords", "POST", payload);
      if (data) {
        return data;
      }
    },
    async contentApplySuggestedKeywords(_, payload) {
      const data = await request("/content/applysuggestedkeywords", "POST", payload);
      if (data) {
        return data;
      }
    },
    async contentScrapProductData(_, payload) {
      const data = await request("/content/scrapproductdata", "POST", payload);
      if (data) {
        return data;
      }
    },
    async productValidate(_, payload) {
      const data = await request(`/product/content/validate`, "POST", payload);
      return data;
    },
    async productSmartListing(_, payload) {
      const data = await request(`/product/content/smart-listing`, "POST", payload);
      return data;
    },
    async categoryDefinition(_, payload) {
      let result = (await request("/content/smart_listing/category_definition", "POST", payload)) ?? {
        data: []
      };
      return result.data;
    },
    async productDataFromUrl(_, payload) {
      let result = (await request("/content/smart_listing/product_data_from_url", "POST", payload)) ?? {
        data: []
      };
      return result.data;
    },
    async competitorsList(_, payload) {
      const data = await request(`/product/content/competitors`, "POST", payload);
      //console.log(data);
      return data;
    },
    async productBenefits(_, payload) {
      const data = await request(`/product/content/benefits`, "POST", payload);
      return data;
    },
    async keywordBank(_, payload) {
      const data = await request(`/product/content/keywords`, "POST", payload);
      return data;
    },
    async imageList({
      commit
    }) {
      const data = await request("/product/images");
      if (data) {
        commit("imageListSet", data);
      }
      return data;
    },
    async imageUpload(_, payload) {
      const data = await request(`/product/images`, "POST", payload);
      return data;
    },
    updateTitleLimitExceeded({
      commit
    }, value) {
      commit("setTitleLimitExceeded", value);
    },
    updateKeywordsLimitExceeded({
      commit
    }, value) {
      commit("setKeywordsLimitExceeded", value);
    }
  }
};
export default content;