import TableWithTitle from '@/components/TableWithTitle.vue';
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import { getDescriptionBasedOnKpiKey, getColorsBasedOnRange } from "@/utils/transformers";
import { SELECTOR, PERIOD_FIELD, RETURN_RATE_FIELD, ORGANIC_CVR_FIELD, RATING_FIELD, BUYBOX_FIELD, TOTAL_QUANTITY_FIELD, PAGE_VIEWS_FIELD, LISTED_ASIN_FIELD, RATING_TOTAL_FIELD, AVERAGE_UNIT_PRICE_FIELD } from "@/utils/table_fields";
import AppIcon from '@/components/AppIcon.vue';
import { mapState } from "vuex";
import { HugValue, DateValue, RateChangeValue, CurrencyValue, NumberValue, BoxedValue, BarWithValue, PercentageValue, RatingValue, DottedValue, OverlapBarValue } from "@/components/tableElements";
import LiteTableMixin from '@/mixins/LiteTableMixin.vue';
import { DEFAULT_DATE_FORMATS, DAYS_INVENTORY_HEATMAP_COLORS, ORGANIC_CVR_HEATMAP_COLORS, BUYBOX_HEATMAP_COLORS, PERIOD_COLUMN_TITLES } from '@/utils/constants';
import moment from 'moment';
import BarSlotWithValue from '@/components/tableElements/BarSlotWithValue.vue';
import LayerBars from '@/components/tableElements/LayerBars.vue';
import VariableStyledBar from '@/components/tableElements/VariableStyledBar.vue';
import FlexRow from "@/components/FlexRow";
import CompareValue from '@/components/tableElements/CompareValue.vue';
import DownloadMixin from '@/mixins/DownloadMixin.vue';
import { capitalize } from 'vue';
import SlotWithEvolutionValue from "@/components/tableElements/SlotWithEvolutionValue.vue";
import EvolutionValue from "@/components/tableElements/EvolutionValue.vue";
import WrapTableWithShowMore from '@/components/WrapTableWithShowMore.vue';
export default {
  name: "ListingPeriodTable",
  components: {
    TableWithTitle,
    WrapWithPopover,
    AppIcon,
    HugValue,
    DateValue,
    RateChangeValue,
    CurrencyValue,
    NumberValue,
    BoxedValue,
    BarWithValue,
    PercentageValue,
    RatingValue,
    DottedValue,
    OverlapBarValue,
    BarSlotWithValue,
    LayerBars,
    VariableStyledBar,
    FlexRow,
    CompareValue,
    SlotWithEvolutionValue,
    EvolutionValue,
    WrapTableWithShowMore
  },
  mixins: [LiteTableMixin, DownloadMixin],
  props: {
    loading: {
      typ: Boolean,
      required: true
    }
  },
  emits: ["setDateRange", "refresh"],
  data() {
    return {
      DAYS_INVENTORY_HEATMAP_COLORS,
      ORGANIC_CVR_HEATMAP_COLORS,
      BUYBOX_HEATMAP_COLORS,
      PERIOD_COLUMN_TITLES,
      fields: [SELECTOR, PERIOD_FIELD, LISTED_ASIN_FIELD, PAGE_VIEWS_FIELD, TOTAL_QUANTITY_FIELD, ORGANIC_CVR_FIELD, RATING_FIELD, RATING_TOTAL_FIELD, AVERAGE_UNIT_PRICE_FIELD, RETURN_RATE_FIELD, BUYBOX_FIELD],
      csvSources: period => ({
        [PERIOD_COLUMN_TITLES[period]]: PERIOD_FIELD.key,
        "# active ASIN": "active_asin_count",
        "# listed ASIN": "listed_asin_count",
        [PAGE_VIEWS_FIELD.label]: PAGE_VIEWS_FIELD.key,
        ...this.getEvolutionsForField(PAGE_VIEWS_FIELD),
        [TOTAL_QUANTITY_FIELD.label]: TOTAL_QUANTITY_FIELD.key,
        ...this.getEvolutionsForField(TOTAL_QUANTITY_FIELD),
        [ORGANIC_CVR_FIELD.label]: ORGANIC_CVR_FIELD.key,
        [RATING_FIELD.label]: RATING_FIELD.key,
        [RATING_TOTAL_FIELD.label]: RATING_TOTAL_FIELD.key,
        [AVERAGE_UNIT_PRICE_FIELD.label]: AVERAGE_UNIT_PRICE_FIELD.key,
        ...this.getEvolutionsForField(AVERAGE_UNIT_PRICE_FIELD),
        [RETURN_RATE_FIELD.label]: RETURN_RATE_FIELD.key,
        [BUYBOX_FIELD.label]: BUYBOX_FIELD.key
      })
    };
  },
  computed: {
    ...mapState({
      periodTable: state => state.dashboard_v2.periodTable,
      tab: state => state.dashboard_v2.currentTabValue,
      period: state => state.dashboard_v2.period,
      currentPeriod: state => state.dashboard_v2.currentPeriod,
      periodCategory: state => state.dashboard_v2.periodCategory
    }),
    items() {
      return this.periodTable[this.tab].items;
    },
    maxCharCount() {
      return this.periodTable[this.tab].maxCharCount;
    },
    minMax() {
      return this.periodTable[this.tab].minMax;
    },
    getDateValueFormat() {
      // Convert the current period to UPPER CASE
      const period = this.period.toUpperCase();
      return DEFAULT_DATE_FORMATS[period];
    },
    getPeriodColumnTitle() {
      return PERIOD_COLUMN_TITLES[this.period];
    },
    csvFileName() {
      return `Dashboard_Listing_Evolution_${capitalize(this.period)}.csv`;
    },
    period_pager: {
      get() {
        return this.periodTable.period_page;
      },
      set(_) {
        this.$store.dispatch('dashboardV2IncrementPeriodPage');
        this.$emit('refresh');
      }
    }
  },
  methods: {
    getDescriptionBasedOnKpiKey,
    getColorsBasedOnRange,
    handleDateClick(period) {
      period = moment(period);
      this.$emit("setDateRange", period);
    },
    handleDateReset() {
      this.$emit("setDateRange", null);
    },
    async downloadPerformancePeriodCSV() {
      const csv_headers = this.csvSources(this.period);
      const csvFileName = this.csvFileName;
      const str = await this.generateCsvString(csv_headers, this.prepareCsvRowsForDownload(this.items, csv_headers), 1, '-');
      await this.downloadCsv(str, csvFileName);
    }
  }
};