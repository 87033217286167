import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-23e3dc4e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "name-wrapper"
};
const _hoisted_2 = {
  class: "name"
};
const _hoisted_3 = {
  key: 0,
  class: "region-country"
};
const _hoisted_4 = {
  class: "region"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.item.name), 1 /* TEXT */)]), $props.item.region ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", null, _toDisplayString($props.item.region), 1 /* TEXT */)])])) : _createCommentVNode("v-if", true)]);
}