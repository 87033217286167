import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_campaign_filter = _resolveComponent("campaign-filter");
  const _component_match_type_filter = _resolveComponent("match-type-filter");
  const _component_targeting_filter = _resolveComponent("targeting-filter");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: 16
  }, {
    default: _withCtx(() => [_createVNode(_component_campaign_filter, {
      campaigns: _ctx.campaigns,
      storeRoot: "ppc_v2",
      onApply: $options.applyCampaigns
    }, null, 8 /* PROPS */, ["campaigns", "onApply"]), _createVNode(_component_match_type_filter, {
      match_types: _ctx.match_types,
      storeRoot: "ppc_v2",
      onApply: $options.applyMatchTypes
    }, null, 8 /* PROPS */, ["match_types", "onApply"]), _createVNode(_component_targeting_filter, {
      targetings: _ctx.targetings,
      storeRoot: "ppc_v2",
      onApply: $options.applyTargetings
    }, null, 8 /* PROPS */, ["targetings", "onApply"])]),
    _: 1 /* STABLE */
  });
}