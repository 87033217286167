import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6b198f80"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "badge"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    align: $props.align,
    style: _normalizeStyle($options.targetingTypeStyle),
    class: "targeting-type-value",
    loading: $props.loading
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString($options.targetingTypeObject?.short_name), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["align", "style", "loading"]);
}