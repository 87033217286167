import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3d719f17"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "campaign-value"
};
const _hoisted_2 = {
  class: "name"
};
const _hoisted_3 = {
  class: "campaign-type"
};
const _hoisted_4 = {
  class: "dot-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_flag_value = _resolveComponent("flag-value");
  const _component_flex_column = _resolveComponent("flex-column");
  const _component_dot_element = _resolveComponent("dot-element");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    loading: $props.loading,
    align: $props.align,
    styles: {
      width: '100%'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_flag_value, {
      flag: $props.value.flag
    }, null, 8 /* PROPS */, ["flag"]), _createVNode(_component_flex_column, {
      classes: "campaign",
      gap: 4,
      styles: {
        width: '100%',
        padding: 0,
        overflow: 'hidden'
      }
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString($props.value.name), 1 /* TEXT */), _createElementVNode("div", _hoisted_3, _toDisplayString($props.value.type), 1 /* TEXT */)]),
      _: 1 /* STABLE */
    }), _createElementVNode("div", _hoisted_4, [_createVNode(_component_dot_element, {
      color: $data.campaignStates[$props.value.state]
    }, null, 8 /* PROPS */, ["color"])])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading", "align"]);
}