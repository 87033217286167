import moment from 'moment';
import { getDatePickerDayId, disableStrategyWeeks } from '@/utils/datepicker';
export default {
  name: 'DatePickerCalendarMixin',
  data() {
    return {
      selectionStack: [],
      hoverStack: [],
      today: moment()
    };
  },
  methods: {
    getDatePickerDayId,
    getDayDateClass(date, month, year) {
      const result = ['date-item'];
      const today = moment();
      date = moment(date);
      month = moment([year, month, 1]);
      if (date.isBefore(month) && !date.isSame(month, 'month') || date.isAfter(month) && !date.isSame(month, 'month')) {
        // The conditional above means that 
        // if the date is before the month and not in the same month
        // or if the date is after the month and not in the same month
        // then the date is empty (meaning it's inactive)
        result.push('empty');
      } else {
        if (date.isSame(today, 'day') && this.highlightToday) {
          result.push('today');
        }
        if (this.readonly) {
          result.push('readonly');
        }
        if (date.isAfter(today)) {
          result.push('future');
        }
        if (this.range.start && this.range.end && date.isBetween(this.range.start.startOf('day'), this.range.end.endOf('day'), null, '[]')) {
          result.push('in-range');
        }
        if (this.range.start && date.isSame(this.range.start, 'day')) {
          result.push('in-range');
          result.push('start');
        }
        if (this.range.end && date.isSame(this.range.end, 'day')) {
          result.push('in-range');
          result.push('end');
        }
        if (this.compare.start && this.compare.end && date.isBetween(this.compare.start.startOf('day'), this.compare.end.endOf('day'), null, '[]')) {
          result.push('in-compare');
        }
        if (this.compare.start && date.isSame(this.compare.start, 'day')) {
          result.push('in-compare');
          result.push('start');
        }
        if (this.compare.end && date.isSame(this.compare.end, 'day')) {
          result.push('in-compare');
          result.push('end');
        }
        if ((this.isRangeCustom || this.isCompareCustom) && !this.readonly) {
          if (date.isBefore(today)) {
            if (!this.range.start) {
              result.push('hover-range');
            } else {
              if (!this.range.end) {
                if (date.isBetween(this.range.start.startOf('day'), today.endOf('day'), null, '(]')) {
                  result.push('hover-range');
                  const prevHoverDate = moment(this.hoverDate).subtract(1, 'day');
                  if (date.isBetween(this.range.start.startOf('day'), prevHoverDate.endOf('day'), null, '()')) {
                    result.push('hover-range-active');
                  }
                }
              } else {
                if (!this.compare.start) {
                  if (date.isBefore(this.range.start)) {
                    result.push('hover-compare');
                  }
                } else {
                  if (!this.compare.end) {
                    if (date.isBetween(this.compare.start.startOf('day'), this.range.start.endOf('day'), null, '[)')) {
                      result.push('hover-compare');
                      const prevHoverDate = moment(this.hoverDate).subtract(1, 'day');
                      if (date.isBetween(this.compare.start.startOf('day'), prevHoverDate.endOf('day'), null, '()')) {
                        result.push('hover-compare-active');
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return result.join(' ');
    },
    disableBeforeWeek(date, result, start) {
      return date.isBefore(start, 'isoWeek') ? [...result, 'disabled'] : result;
    },
    disableDaysNotInMonth(date, month, year, result) {
      return date.isSame(moment([year, month, 1]), 'month') ? result : [...result, 'disabled'];
    },
    setEmpty({
      start,
      end
    }) {
      return start === null || end === null;
    },
    onDayDateClick(date) {
      date = moment(date);
      if (date.isAfter(moment(), 'day')) {
        return;
      }
      this.$emit("enableCustomMode");
      this.$nextTick(() => {
        const {
          range,
          compare
        } = this.state;
        if (this.setEmpty({
          ...range
        })) {
          if (!range.start) {
            // First click on the calendar
            this.$emit("setDatePickerState", 'range', 'start', date);
            return;
          } else {
            if (date.isBefore(range.start, 'day')) {
              const oldDate = range.start;
              this.$emit("setDatePickerState", 'range', 'start', date);
              this.$emit("setDatePickerState", 'range', 'end', oldDate);
              return;
            }
            // Second click on the calendar
            this.$emit("setDatePickerState", 'range', 'end', date);
          }
        } else if (this.setEmpty({
          ...compare
        })) {
          if (!compare.start) {
            if (date.isSameOrAfter(range.start, 'day')) {
              return;
            }
            this.$emit("setDatePickerState", 'compare', 'start', date);
          } else {
            if (date.isSameOrAfter(range.start, 'day')) {
              return;
            }
            if (date.isBefore(compare.start, 'day')) {
              const oldDate = compare.start;
              this.$emit("setDatePickerState", 'compare', 'start', date);
              this.$emit("setDatePickerState", 'compare', 'end', oldDate);
              return;
            }
            this.$emit("setDatePickerState", 'compare', 'end', date);
          }
          return;
        }
      });
    },
    onDayDateHover(date = null) {
      if (!date) {
        this.$emit('hover-date', null);
        return;
      }
      const today = moment();
      date = moment(date);
      if (date.isBefore(today)) {
        let check = false;
        if (!this.range.start) {
          check = true;
        } else {
          if (!this.range.end) {
            if (date.isBetween(this.range.start.startOf('day'), today.endOf('day'), null, '(]')) {
              check = true;
            }
          } else {
            if (!this.compare.start) {
              if (date.isBefore(this.range.start)) {
                check = true;
              }
            } else {
              if (!this.compare.end) {
                if (date.isBetween(this.compare.start.startOf('day'), this.range.start.endOf('day'), null, '(]')) {
                  check = true;
                }
              }
            }
          }
        }
        if (check) {
          this.$emit('hover-date', date);
          return;
        }
      }
    },
    isWeekDateDisabled(date) {
      //console.log('date', date, this.weeksFilledRangeStore)
      return disableStrategyWeeks(date, this.weeksFilledRangeStore, this.currentWeek);
    }
  }
};