import AppButton from '@/components/AppButton';
import AppIcon from '@/components/AppIcon.vue';
export default {
  name: 'RightPaneLayout',
  components: {
    AppButton,
    AppIcon
  },
  emits: ['update:modelValue', 'cancel', 'submit'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    cancelTitle: {
      type: String,
      default: 'Cancel'
    },
    okTitle: {
      type: String,
      default: 'Ok'
    },
    isCreatable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
};