import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 15 16`,
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M11.4212 1.57812C14.4152 4.57214 14.4152 9.42641 11.4212 12.4204C8.55396 15.2877 3.9807 15.4091 0.968982 12.7847C0.837639 12.6702 0.771967 \n    12.613 0.742323 12.5348C0.717381 12.469 0.714589 12.3876 0.734959 12.3202C0.759169 12.2401 0.824779 12.1745 0.956 12.0433L2.43283 10.5665M11 6.99928C11 \n    9.7607 8.76145 11.9993 6.00003 11.9993C3.2386 11.9993 1.00003 9.7607 1.00003 6.99928C1.00003 4.23785 3.2386 1.99928 6.00003 1.99928C8.76145 1.99928 \n    11 4.23785 11 6.99928Z",
    stroke: $props.color,
    "stroke-width": "1.3",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}