export default {
  name: 'AppTextarea',
  emits: ['update:modelValue', 'submit'],
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 4
    },
    maxRows: {
      type: Number,
      default: 3
    },
    v2: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      id: 'area-' + Math.floor(Math.random() * 900000) + 100000
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
    }
  }
};