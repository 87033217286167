import { SellerFilter, MarketplaceFilter, ProductFilter } from "@/components/new-filters";
import { mapState } from 'vuex';
import FiltersFrame from "@/components/new-filters/FiltersFrame.vue";
import { EVENTS } from "@/utils";
export default {
  name: "DashboardFilters",
  components: {
    SellerFilter,
    MarketplaceFilter,
    ProductFilter,
    FiltersFrame
  },
  computed: {
    ...mapState({
      sellers: state => state.dashboard_v2.sellers,
      marketplaces: state => state.dashboard_v2.marketplaces,
      products: state => state.dashboard_v2.products
    })
  },
  methods: {
    async applySellers() {
      if (this.sellers.isModified) {
        this.sellers.apply();
        window.$bus.trigger('showSuccess', {
          message: '⏳Fetching sub filters for sellers...  ',
          visible: true,
          delay: 50
        });
        await window.$bus.trigger(EVENTS.REFRESH_DASHBOARD_V2_SELLERS_SUB_FILTERS);
        window.$bus.trigger(EVENTS.REFRESH_DASHBOARD_V2);
      }
    },
    async applyMarketplaces() {
      if (this.marketplaces.isModified) {
        this.marketplaces.apply();
        window.$bus.trigger('showSuccess', {
          message: '⏳Fetching sub filters for marketplace...  ',
          visible: true,
          delay: 50
        });
        await window.$bus.trigger(EVENTS.REFRESH_DASHBOARD_V2_MARKETPLACES_SUB_FILTERS);
        window.$bus.trigger(EVENTS.REFRESH_DASHBOARD_V2);
      }
    },
    applyProducts() {
      if (this.products.isModified) {
        this.products.apply();
        window.$bus.trigger(EVENTS.REFRESH_DASHBOARD_V2);
      }
    }
  }
};