import { mapGetters } from 'vuex';
import moment from 'moment';
import AppIcon from '@/components/AppIcon';
import AlertsStatus from '@/components/AlertsStatus';
export default {
  name: 'AlertsList',
  emits: ['select'],
  components: {
    AppIcon,
    AlertsStatus
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: [String, Number],
      default: null
    },
    selectedLevel: {
      type: String,
      default: null
    },
    expandedIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      openCategories: this.expandedIds
    };
  },
  computed: {
    ...mapGetters(['getCountryFlag']),
    filteredItems() {
      const result = this.items.reduce((acc, value) => {
        if (value.alerting_category == this.selectedLevel) {
          let category = acc.find(item => item.id === value.alerting_type);
          if (!category) {
            category = {
              id: value.alerting_type,
              name: value.alerting_type,
              items: []
            };
          }
          category.items.push(value);
          return [...acc.filter(item => item.id !== value.alerting_type), category];
        }
        return acc;
      }, []);
      result.sort((a, b) => b.items.length - a.items.length);
      return result;
    },
    createdAt() {
      return date => {
        return moment(date).format('D MMM');
      };
    }
  },
  methods: {
    onToggleOpen(id) {
      if (this.openCategories.includes(id)) {
        this.openCategories = this.openCategories.filter(item => item !== id);
      } else {
        this.openCategories.push(id);
      }
    },
    onToggleId(id) {
      this.$emit('select', id);
    }
  }
};