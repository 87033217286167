import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AdFormatFilterBody = _resolveComponent("AdFormatFilterBody");
  const _component_FilterItem = _resolveComponent("FilterItem");
  return _openBlock(), _createBlock(_component_FilterItem, {
    id: "ad_formats",
    ref: "adFormatFilterItem",
    icon: "LayoutIcon",
    title: $options.getAdFormatTitle,
    selectedCount: $props.ad_formats.filterState.selectedCount,
    onClearAll: $options.handleClearAll
  }, {
    body: _withCtx(() => [_createVNode(_component_AdFormatFilterBody, {
      title: "Ad Format",
      selected: $props.ad_formats.filterState.selected,
      isModified: $props.ad_formats.isModified,
      searching: $props.ad_formats.searching,
      list: $props.ad_formats.filterState.list,
      onClear: $options.handleClear,
      onSelect: $options.handleSelect,
      onSelectOnly: $options.handleSelectOnly,
      onSelectAll: _cache[0] || (_cache[0] = $event => $props.ad_formats.selectAll()),
      onDeselect: $options.handleDeselect,
      onApply: _cache[1] || (_cache[1] = $event => _ctx.$emit('apply')),
      onSearch: $options.handleAdFormatSearch,
      onHidden: _cache[2] || (_cache[2] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _ctx.$refs?.adFormatFilterItem?.closeDropdown
    }, null, 8 /* PROPS */, ["selected", "isModified", "searching", "list", "onClear", "onSelect", "onSelectOnly", "onDeselect", "onSearch", "onCloseDropdown"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "selectedCount", "onClearAll"]);
}