import { RateLimiter } from 'limiter';
//https://www.useanvil.com/blog/engineering/throttling-and-consuming-apis-with-429-rate-limits/

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
class TokenBucketRateLimiter {
  constructor({
    maxRequests,
    maxRequestWindowMS
  }) {
    this.maxRequests = maxRequests;
    this.maxRequestWindowMS = maxRequestWindowMS;
    this.reset();
  }
  reset() {
    console.log("*******reset*******");
    this.count = 0;
    this.resetTimeout = null;
  }
  scheduleReset() {
    // Only the first token in the set triggers the resetTimeout
    if (!this.resetTimeout) {
      console.log("*******scheduleReset*******");
      this.resetTimeout = setTimeout(() => this.reset(), this.maxRequestWindowMS);
    }
  }
  async acquireToken(fn, url) {
    this.scheduleReset();
    console.log("*******url *******");
    console.log(url);
    console.log("*******window.rate_limiter *******");
    console.log(window.rate_limiter);
    console.log("*******resetTimeout resetTimeout maxRequests *******");
    console.log(this.resetTimeout);
    console.log(this.count);
    console.log(this.maxRequests);
    console.log(this.maxRequestWindowMS);
    if (this.count >= this.maxRequests) {
      console.log("*******acquireToken*******");
      await sleep(this.maxRequestWindowMS);
      return this.acquireToken(fn);
    }
    this.count += 1;
    return fn();
  }
}
const rate_limiter = new TokenBucketRateLimiter({
  maxRequests: 5,
  maxRequestWindowMS: 1000
});
export default rate_limiter;