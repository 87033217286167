import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-17c5d635"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id"];
const _hoisted_2 = ["for"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-switch-container', {
      simple: $props.simple
    }, {
      between: $props.between
    }])
  }, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.value = $event),
    type: "checkbox",
    id: $data.id,
    onChange: _cache[1] || (_cache[1] = (...args) => $options.onChange && $options.onChange(...args))
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1), [[_vModelCheckbox, $options.value]]), _createElementVNode("label", {
    for: $data.id,
    class: "app-switch"
  }, [_createElementVNode("span", null, [!$props.modelValue ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "cross",
    size: 12,
    color: "#fff"
  })) : (_openBlock(), _createBlock(_component_app_icon, {
    key: 1,
    icon: "check2",
    size: 12,
    color: "#fff"
  }))])], 8 /* PROPS */, _hoisted_2)], 2 /* CLASS */);
}