import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-19c118dc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "product-value"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = {
  class: "image-wrapper"
};
const _hoisted_4 = {
  class: "image-item-wrapper"
};
const _hoisted_5 = {
  class: "image-overlay"
};
const _hoisted_6 = {
  key: 1,
  class: "image-item-wrapper"
};
const _hoisted_7 = {
  class: "image-overlay"
};
const _hoisted_8 = {
  key: 0,
  class: "name"
};
const _hoisted_9 = {
  class: "name"
};
const _hoisted_10 = {
  class: "sku-asin"
};
const _hoisted_11 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_annoncement_icon = _resolveComponent("annoncement-icon");
  const _component_b_img = _resolveComponent("b-img");
  const _component_stroked_rounded_link_icon = _resolveComponent("stroked-rounded-link-icon");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_wrap_with_copy = _resolveComponent("wrap-with-copy");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_flex_column = _resolveComponent("flex-column");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    loading: $props.loading,
    align: $props.align,
    styles: {
      width: '100%',
      ...$props.styles
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("a", {
      href: `https://${$props.value.link}`,
      target: "_blank",
      class: "link"
    }, [_createElementVNode("div", _hoisted_3, [$props.isChild ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [$props.sponsored ? (_openBlock(), _createBlock(_component_annoncement_icon, {
      key: 0
    })) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createElementVNode("div", _hoisted_4, [$props.isToggled ? (_openBlock(), _createBlock(_component_b_img, {
      key: 0,
      lazy: "",
      src: $props.value?.image ?? '/assets/images/amazon-logo-transparent.svg',
      class: "custom-img",
      height: "32",
      width: "32"
    }, null, 8 /* PROPS */, ["src"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_stroked_rounded_link_icon, {
      width: "16",
      height: "16",
      color: "#FFFFFF"
    })])], 64 /* STABLE_FRAGMENT */))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [$props.sponsored ? (_openBlock(), _createBlock(_component_annoncement_icon, {
      key: 0
    })) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_b_img, {
      lazy: "",
      src: $props.value?.image ?? '/assets/images/amazon-logo-transparent.svg',
      class: "custom-img",
      height: "32",
      width: "32"
    }, null, 8 /* PROPS */, ["src"])])), _createElementVNode("div", _hoisted_7, [_createVNode(_component_stroked_rounded_link_icon, {
      width: "16",
      height: "16",
      color: "#FFFFFF"
    })])], 64 /* STABLE_FRAGMENT */))])], 8 /* PROPS */, _hoisted_2), _createVNode(_component_flex_column, {
      classes: "product",
      gap: 2,
      styles: {
        width: '100%',
        padding: 0,
        overflow: 'hidden'
      }
    }, {
      default: _withCtx(() => [$props.sponsored ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Sponsored Brands Advertising Campaign")) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createVNode(_component_wrap_with_popover, {
        width: "495px",
        customClass: "product-value-popover"
      }, {
        title: _withCtx(() => [_createTextVNode(_toDisplayString($props.value.name), 1 /* TEXT */)]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_9, _toDisplayString($options.productName), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      }), _createElementVNode("div", _hoisted_10, [_createVNode(_component_flex_row, {
        gap: 4,
        styles: {
          width: 'fit-content',
          height: 'fit-content',
          alignItems: 'flex-start'
        }
      }, {
        default: _withCtx(() => [$options.sku ? (_openBlock(), _createBlock(_component_wrap_with_copy, {
          key: 0,
          class: "sku"
        }, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($options.sku), 1 /* TEXT */)]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), $options.sku ? (_openBlock(), _createElementBlock("div", _hoisted_11, "-")) : _createCommentVNode("v-if", true), _createVNode(_component_wrap_with_copy, null, {
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($options.asin), 1 /* TEXT */)]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      })])], 64 /* STABLE_FRAGMENT */))]),
      _: 1 /* STABLE */
    })])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading", "align", "styles"]);
}