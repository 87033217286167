import Filter from "./filter";
const profitAndLoss = {
  state: {
    sellers: new Filter([], [], "id"),
    marketplaces: new Filter([], [], "sales_channel"),
    products: new Filter([], [], "products"),
    aggregateTableGroupBy: {
      key: "aggregate",
      name: "Aggregate"
    },
    aggregateTablePeriod: {
      title: "Period",
      start: {
        date: null,
        format: 'YYYY-MM-DD'
      },
      end: {
        date: null,
        format: 'YYYY-MM-DD'
      },
      key: "monthly",
      range: {
        start: null,
        end: null
      },
      compare: {
        start: null,
        end: null
      }
    }
  },
  getters: {
    profitAndLossGetSellers(state) {
      return () => {
        return state.sellers.filterValues;
      };
    },
    profitAndLossGetMarketplaces(state) {
      return () => {
        return state.marketplaces.filterValues.map(v => v.toLowerCase());
      };
    },
    profitAndLossGetSkus(state) {
      return () => {
        return state.products.selected.map(product => product.sku);
      };
    },
    profitAndLossGetAsins(state) {
      return () => {
        return state.products.selected.map(product => product.asin);
      };
    },
    profitAndLossGetAggregateGroupBy(state) {
      return () => {
        return state.aggregateTableGroupBy;
      };
    },
    profitAndLossGetAggregatePeriod(state) {
      return () => {
        return state.aggregateTablePeriod;
      };
    }
  },
  actions: {
    profitAndLossSetAggregatePeriod({
      state
    }, period) {
      state.aggregateTablePeriod = period;
    }
  }
};
export default profitAndLoss;