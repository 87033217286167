import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "d-flex justify-content-between w-100"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    id: $props.id,
    styles: {
      width: '100%',
      lineHeight: '16.9px'
    },
    align: "left",
    gap: 8
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), _createElementVNode("div", _hoisted_1, [$props.direction ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 0,
      icon: `simple-arrow-${$options.getArrowDirection}`,
      color: "#757575",
      size: 12
    }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "more-head-actions")])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["id"]);
}