import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_card_title = _resolveComponent("b-card-title");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_overlay = _resolveComponent("b-overlay");
  return _openBlock(), _createBlock(_component_b_overlay, {
    show: $props.loading,
    variant: "white"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card, {
      style: _normalizeStyle({
        ...$options.styles,
        overflow: $props.scrollable ? 'auto' : 'hidden'
      }),
      class: _normalizeClass(["custom-card", {
        bordered: $props.hasBorder
      }])
    }, {
      default: _withCtx(() => [$props.title ? (_openBlock(), _createBlock(_component_b_card_title, {
        key: 0,
        class: "card-title"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.title), 1 /* TEXT */)]),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["style", "class"])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["show"]);
}