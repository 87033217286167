import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b5330d8c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "current"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1,
  class: "d-flex align-item-center justify-content-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_connection_page_title = _resolveComponent("connection-page-title");
  const _component_b_th = _resolveComponent("b-th");
  const _component_b_tr = _resolveComponent("b-tr");
  const _component_b_thead = _resolveComponent("b-thead");
  const _component_b_td = _resolveComponent("b-td");
  const _component_app_button = _resolveComponent("app-button");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_b_tbody = _resolveComponent("b-tbody");
  const _component_b_table_simple = _resolveComponent("b-table-simple");
  const _component_app_modal = _resolveComponent("app-modal");
  const _component_create_workspace_side_bar = _resolveComponent("create-workspace-side-bar");
  const _component_app_card_connections = _resolveComponent("app-card-connections");
  const _component_AppPagination = _resolveComponent("AppPagination");
  const _component_omny_ui_layout = _resolveComponent("omny-ui-layout");
  return _openBlock(), _createBlock(_component_omny_ui_layout, {
    contentPadding: "0 14px",
    showPagination: true
  }, {
    pagination: _withCtx(() => [_createVNode(_component_AppPagination, {
      page: $data.page,
      "onUpdate:page": _cache[3] || (_cache[3] = $event => $data.page = $event),
      perPage: $data.perPage,
      "onUpdate:perPage": [_cache[4] || (_cache[4] = $event => $data.perPage = $event), $options.updatePerPage],
      total: $options.totalPages
    }, null, 8 /* PROPS */, ["page", "perPage", "total", "onUpdate:perPage"])]),
    default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_connection_page_title, {
      class: "",
      visibleButton: _ctx.isOmnyAdmin,
      action: "Add Account",
      searchPlaceHolder: $data.searchPlaceHolder,
      onAction: _cache[0] || (_cache[0] = $event => $data.createWorkspaceSideBar = _ctx.isOmnyAdmin)
    }, null, 8 /* PROPS */, ["visibleButton", "searchPlaceHolder"]), _createVNode(_component_app_card_connections, {
      class: "no-left-right-margin",
      loading: $data.dataLoading,
      "min-height": 300,
      scrollable: true,
      hasBorder: false
    }, {
      default: _withCtx(() => [!$data.dataLoading && $options.paginatedWorkspaces.length > 0 ? (_openBlock(), _createBlock(_component_b_table_simple, {
        key: 0,
        striped: "",
        responsive: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_b_thead, {
          class: "account-table-thead-background"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_tr, null, {
            default: _withCtx(() => [_createVNode(_component_b_th, {
              class: "account-table-thead-background-text"
            }, {
              default: _withCtx(() => [_createTextVNode("Account Name")]),
              _: 1 /* STABLE */
            }), _createVNode(_component_b_th, {
              class: "account-table-thead-background-text"
            }, {
              default: _withCtx(() => [_createTextVNode("Creation Date")]),
              _: 1 /* STABLE */
            }), _createVNode(_component_b_th), _createVNode(_component_b_th), _createVNode(_component_b_th)]),
            _: 1 /* STABLE */
          })]),
          _: 1 /* STABLE */
        }), _createVNode(_component_b_tbody, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.paginatedWorkspaces, workspace => {
            return _openBlock(), _createBlock(_component_b_tr, {
              key: workspace.workspace_id
            }, {
              default: _withCtx(() => [_createVNode(_component_b_td, {
                class: "align-middle"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(workspace.workspace_name), 1 /* TEXT */)]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, {
                class: "align-middle"
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString($options.formatDate(workspace.workspace_creation_date)), 1 /* TEXT */)]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, {
                class: "text-center align-middle"
              }, {
                default: _withCtx(() => [$options.isCurrent(workspace) ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Current")) : (_openBlock(), _createBlock(_component_app_button, {
                  key: 1,
                  loading: $options.isCurrent(workspace) ? $data.currentLoading : null,
                  variant: "outline-primary",
                  onClick: $event => $options.onCurrentChange(workspace.workspace_id)
                }, {
                  default: _withCtx(() => [_createTextVNode(" Select ")]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "onClick"]))]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */), _createCommentVNode("b-td>\n              <app-button\n                variant=\"outline-primary\"\n                @click=\"onMembers(workspace.workspace_id)\"\n              >\n                Members\n              </app-button>\n            </b-td"), _createVNode(_component_b_td, null, {
                default: _withCtx(() => [_ctx.isOmnyAdmin && $options.isOmnyWorkspace(_ctx.current_workspace) && !$options.isOmnyWorkspace(workspace) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_app_button, {
                  loading: workspace.workspace_id == $data.removeId ? $data.removeLoading : null,
                  variant: "link",
                  onClick: $event => $options.onRemoveConfirm(workspace.workspace_id)
                }, {
                  default: _withCtx(() => [_createVNode(_component_app_icon, {
                    icon: "remove"
                  })]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "onClick"])])) : _createCommentVNode("v-if", true)]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */);
          }), 128 /* KEYED_FRAGMENT */))]),
          _: 1 /* STABLE */
        }), _createCommentVNode(" <b-tfoot>\n          <tr>\n            <td colspan=\"4\" class=\"text-left hide-bottom-border\">\n              <AppPagination v-model:page=\"page\" v-model:perPage=\"perPage\" :total=\"totalPages\" @update:perPage=\"updatePerPage\"/>\n            </td>\n          </tr>\n        </b-tfoot> ")]),
        _: 1 /* STABLE */
      })) : _createCommentVNode("v-if", true), !$data.dataLoading && _ctx.workspaces.length == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_3, " You have not any workspaces yet ")) : _createCommentVNode("v-if", true), _createVNode(_component_app_modal, {
        modelValue: $data.confirmRemoveModal,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.confirmRemoveModal = $event),
        title: "Confirm Workspace Removing",
        "cancel-title": "No",
        "ok-title": "Yes",
        onOk: $options.onRemove
      }, {
        default: _withCtx(() => [_createTextVNode(" Are you sure you want to remove this workspace? ")]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "onOk"]), _createVNode(_component_create_workspace_side_bar, {
        modelValue: $data.createWorkspaceSideBar,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.createWorkspaceSideBar = $event)
      }, null, 8 /* PROPS */, ["modelValue"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading"])])]),
    _: 1 /* STABLE */
  });
}