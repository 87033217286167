import { TextValue } from "../../tableElements";
import { WrapWithSectionItem } from "../sections";
export default {
  name: "CampaignFilterItem",
  emits: ["select", "selectOnly", "deselect"],
  props: {
    name: String,
    id: String,
    selected: Boolean
  },
  components: {
    WrapWithSectionItem,
    TextValue
  }
};