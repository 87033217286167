import { sortData } from '../utils';
import { periodsSwitches } from '../components/shared/chartHelpers';
const updatedField = (list, selected, key = 'id') => {
  const sellers = selected.length ? selected : list;
  return sellers.map(item => item[key]);
};
const dashboard = {
  state: {
    pageReports: {
      global_performance: {
        graph: []
      },
      global_advertising: {
        graph: []
      },
      country_performance: {
        graph: []
      },
      country_advertising: {
        graph: []
      },
      product_performance: {
        graph: []
      },
      product_advertising: {
        graph: []
      },
      product_inventory_coverage: {
        graph: [],
        chart: []
      },
      product_pricing_margin: {
        graph: []
      },
      advertising_targeting: {
        graph: []
      },
      advertising_campaigns: {
        graph: []
      },
      sellers_performance: {
        graph: []
      },
      sellers_advertising: {
        graph: []
      }
    },
    positionReportTotalCount: 0,
    positionTotal: [],
    positionMinMax: {},
    suggestions: {},
    sellers: {
      list: [],
      selected: []
    },
    productSuggestion: {
      // Products Suggestion state is the object used to the combination of `Product Name`, `Sku`, and `Asin`
      // Which is returned by the `suggestions` API.
      list: [],
      selected: []
    },
    country: {
      list: [],
      selected: []
    },
    campaigns: {
      list: [],
      selected: []
    },
    matchType: {
      list: [],
      selected: []
    },
    page: 1,
    pageSize: 200,
    sort: {
      field: '',
      direction: 'desc'
    },
    selectedPeriod: periodsSwitches.DEFAULT[0],
    currentTab: '',
    currentView: '',
    pdfPlotData: [],
    mainAbortController: new AbortController(),
    pdfDownloadAbortController: new AbortController(),
    pdfViewPeriods: {
      global_performance: null,
      global_advertising: null,
      country_performance: null,
      country_advertising: null,
      product_performance: null,
      product_advertising: null
    }
  },
  mutations: {
    dashboardSetTabViewReport(state, {
      graph,
      chart,
      tabView
    }) {
      if (graph) {
        if (tabView) state.pageReports[tabView].graph = graph;
      }
      if (chart) {
        if (tabView) state.pageReports[tabView].chart = chart;
      }
    },
    dashboardPositionsGraphReportSet(state, payload) {
      state.positionReport.graph = payload;
    },
    dashboardPositionsChartReportSet(state, payload) {
      state.positionReport.chart = payload;
    },
    dashboardPositionsTotalSet(state, payload) {
      state.positionTotal = payload;
    },
    dashboardSuggestionsSet(state, payload) {
      state.suggestions = payload;
    },
    dashboardSellersListSet(state, payload) {
      state.sellers.list = sortData(payload);
    },
    dashboardSellersSelectedSet(state, payload) {
      state.sellers.selected = sortData(payload);
    },
    dashboardProductSuggestionsListSet(state, payload) {
      state.productSuggestion.list = sortData(payload, '__id');
    },
    dashboardProductSuggestionsSelectedSet(state, payload) {
      state.productSuggestion.selected = sortData(payload, '__id');
    },
    dashboardCountriesListSet(state, payload) {
      state.country.list = sortData(payload, '__id');
    },
    dashboardCountriesSelectedSet(state, payload) {
      state.country.selected = sortData(payload, '__id');
    },
    dashboardPeriodSet(state, payload) {
      state.selectedPeriod = payload;
    },
    dashboardPositionsMinMaxSet(state, payload) {
      state.positionMinMax = payload;
    },
    dashboardCurrentTabSet(state, payload) {
      state.currentTab = payload;
    },
    dashboardCurrentViewSet(state, payload) {
      state.currentView = payload;
    },
    dashboardCampaignsListSet(state, payload) {
      state.campaigns.list = sortData(payload, '__id');
    },
    dashboardCampaignsSelectedSet(state, payload) {
      state.campaigns.selected = sortData(payload, '__id');
    },
    dashboardMatchTypeListSet(state, payload) {
      state.matchType.list = sortData(payload, '__id');
    },
    dashboardMatchTypeSelectedSet(state, payload) {
      state.matchType.selected = sortData(payload, '__id');
    },
    dashboardPageSet(state, payload) {
      state.page = payload;
    },
    dashboardPageSizeSet(state, payload) {
      state.pageSize = payload;
    },
    dashboardSortFieldSet(state, payload) {
      state.sort.field = payload;
    },
    dashboardSortDirectionSet(state, payload) {
      state.sort.direction = payload;
    },
    dashboardPositionsTotalCountSet(state, payload) {
      state.positionReportTotalCount = payload;
    },
    dashboardPdfPlotDataSet(state, payload) {
      state.pdfPlotData = payload;
    },
    dashboardAbortMainRequestSet(state, payload) {
      state.mainAbortController = payload;
    },
    dashboardAbortPdfDownloadRequestSet(state, payload) {
      state.pdfDownloadAbortController = payload;
    },
    dashboardPdfViewPeriodsSet(state, {
      tabView,
      period
    }) {
      state.pdfViewPeriods[tabView] = period;
    }
  },
  getters: {
    dashboardPositionsGraphReportGet(state) {
      return () => {
        return state.positionReport.graph;
      };
    },
    dashboardPositionsChartReportGet(state) {
      return () => {
        return state.positionReport.chart;
      };
    },
    dashboardPositionsTotalGet(state) {
      return () => {
        return state.positionTotal;
      };
    },
    dashboardPositionsMinMaxGet(state) {
      return () => {
        return state.positionMinMax;
      };
    },
    dashboardSuggestionsGet(state) {
      return () => {
        return state.suggestions;
      };
    },
    dashboardSellersListGet(state) {
      return () => {
        return state.sellers.list;
      };
    },
    dashboardSellersSelectedGet(state) {
      return () => {
        return state.sellers.selected;
      };
    },
    dashboardProductSuggestionsSelectedGet(state) {
      return () => {
        return state.productSuggestion.selected;
      };
    },
    dashboardProductSuggestionsListGet(state) {
      return () => {
        return state.productSuggestion.list;
      };
    },
    dashboardCountriesListGet(state) {
      return () => {
        return state.country.list;
      };
    },
    dashboardCountriesSelectedGet(state) {
      return () => {
        return state.country.selected;
      };
    },
    dashboardPeriodGet(state) {
      return () => {
        return state.selectedPeriod;
      };
    },
    dashboardCurrentTabGet(state) {
      return () => {
        return state.currentTab;
      };
    },
    dashboardCurrentViewGet(state) {
      return () => {
        return state.currentView;
      };
    },
    dashboardCampaignsListGet(state) {
      return () => {
        return state.campaigns.list;
      };
    },
    dashboardCampaignsSelectedGet(state) {
      return () => {
        return state.campaigns.selected;
      };
    },
    dashboardMatchTypeListGet(state) {
      return () => {
        return state.matchType.list;
      };
    },
    dashboardMatchTypeSelectedGet(state) {
      return () => {
        return state.matchType.selected;
      };
    },
    dashboardPageGet(state) {
      return () => {
        return state.page;
      };
    },
    dashboardPageSizeGet(state) {
      return () => {
        return state.pageSize;
      };
    },
    dashboardPdfPlotDataGet(state) {
      return () => {
        return state.pdfPlotData;
      };
    },
    dashboardMainAbortControllerGet(state) {
      return () => {
        return state.mainAbortController;
      };
    },
    dashboardPdfDownloadAbortControllerGet(state) {
      return () => {
        return state.pdfDownloadAbortController;
      };
    },
    dashboardPdfViewPeriodsGet(state) {
      return tabView => {
        return state.pdfViewPeriods[tabView] || null;
      };
    },
    dashboardGetTabViewPositions(state) {
      return ({
        tabView
      }) => {
        return state.pageReports[tabView];
      };
    },
    dashboardGetSellers(state) {
      return () => {
        return updatedField(state.sellers.list, state.sellers.selected);
      };
    },
    dashboardGetSalesChannels(state) {
      return () => {
        return updatedField(state.country.list, state.country.selected);
      };
    },
    dashboardGetProducts(state) {
      return () => {
        return {
          product_list: state.productSuggestion.selected.map(item => item.product_name),
          sku_list: state.productSuggestion.selected.map(item => item.sku),
          asin_list: state.productSuggestion.selected.map(item => item.asin)
        };
      };
    },
    dashboardGetMatchTypes(state) {
      return () => {
        return state.matchType.selected.map(item => item.id);
      };
    },
    dashboardGetCampaigns(state) {
      return () => {
        return state.campaigns.selected.map(item => item.id);
      };
    }
  },
  actions: {
    dashboardSetPositions({
      commit
    }, {
      graph,
      chart,
      tabView
    }) {
      commit('dashboardSetTabViewReport', {
        graph,
        chart,
        tabView
      });
    },
    dashboardSetSellers({
      commit
    }, {
      list,
      selected
    }) {
      commit('dashboardSellersListSet', list);
      commit('dashboardSellersSelectedSet', selected);
    },
    dashboardSetCountries({
      commit
    }, {
      list,
      selected
    }) {
      commit('dashboardCountriesListSet', list);
      commit('dashboardCountriesSelectedSet', selected);
    },
    dashboardSetProducts({
      commit
    }, {
      list,
      selected
    }) {
      commit('dashboardProductSuggestionsListSet', list);
      commit('dashboardProductSuggestionsSelectedSet', selected);
    },
    dashboardSetCampaigns({
      commit
    }, {
      list,
      selected
    }) {
      commit('dashboardCampaignsListSet', list);
      commit('dashboardCampaignsSelectedSet', selected);
    },
    dashboardSetMatchTypes({
      commit
    }, {
      list,
      selected
    }) {
      commit('dashboardMatchTypeListSet', list);
      commit('dashboardMatchTypeSelectedSet', selected);
    },
    dashboardAbortMainRequest({
      commit,
      getters
    }) {
      const controller = getters.dashboardMainAbortControllerGet();
      controller.abort();
      commit('dashboardAbortMainRequestSet', new AbortController());
    },
    dashboardAbortPdfDownloadRequest({
      commit,
      getters
    }) {
      const controller = getters.dashboardPdfDownloadAbortControllerGet();
      controller.abort();
      commit('dashboardAbortPdfDownloadRequestSet', new AbortController());
    },
    dashboardSetPeriodsForPdfView({
      commit
    }, {
      tabView,
      period
    }) {
      //console.log("Setting Periods: ", tabView, period)
      commit('dashboardPdfViewPeriodsSet', {
        tabView,
        period
      });
    },
    dashboardSetCurrentTab({
      commit
    }, payload) {
      commit('dashboardCurrentTabSet', payload);
    },
    dashboardSetCurrentView({
      commit
    }, payload) {
      commit('dashboardCurrentViewSet', payload);
    }
  }
};
export default dashboard;