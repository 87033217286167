import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_kpis_controllers_section = _resolveComponent("kpis-controllers-section");
  const _component_product_pricing_margin_page = _resolveComponent("product-pricing-margin-page");
  const _component_kpis_renderer = _resolveComponent("kpis-renderer");
  const _component_omny_ui_layout = _resolveComponent("omny-ui-layout");
  return _openBlock(), _createBlock(_component_omny_ui_layout, {
    contentPadding: "0 14px"
  }, {
    default: _withCtx(() => [_createVNode(_component_kpis_renderer, null, {
      controllerSection: _withCtx(() => [_createVNode(_component_kpis_controllers_section, {
        filters: $options.getUpdatedTabFilters,
        showFilterSectionLine: false,
        showTabSection: false,
        onChangeTab: $event => null,
        onChangeView: $event => null,
        onChangePeriod: $options.changePeriod,
        pages: $options.getUpdatedPages,
        pdfPages: _ctx.getPdfExportPages,
        pdfPeriods: _ctx.getPdfExportPeriods,
        tabPeriods: _ctx.getTabPeriods,
        graphControllers: [{
          name: 'Pricing and Margin',
          active: false,
          tab: 'Product',
          path: '/product/pricing_and_margin'
        }],
        currentTab: _ctx.currentTab,
        currentView: _ctx.currentView,
        currentPeriod: $data.currentPeriod,
        datePickerDate: _ctx.datePickerDate,
        showDatePicker: true
      }, null, 8 /* PROPS */, ["filters", "onChangePeriod", "pages", "pdfPages", "pdfPeriods", "tabPeriods", "currentTab", "currentView", "currentPeriod", "datePickerDate"])]),
      kpisSection: _withCtx(() => [_createVNode(_component_product_pricing_margin_page, {
        period: _ctx.datePickerDate,
        initialLoading: $data.homepageLoading,
        modelValue: $data.homepageLoading,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.homepageLoading = $event)
      }, null, 8 /* PROPS */, ["period", "initialLoading", "modelValue"])]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  });
}