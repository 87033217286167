import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8adb0b72"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "range-row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_input = _resolveComponent("app-input");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_button = _resolveComponent("app-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_app_input, {
    modelValue: $options.rangeItem,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.rangeItem = $event),
    validateRule: $props.validateRule,
    label: $props.label,
    type: $props.type,
    placeholder: $props.placeholder,
    required: $props.required,
    readonly: $props.readonly,
    style: {
      "flex": "1"
    },
    onSubmit: $options.onSubmit
  }, null, 8 /* PROPS */, ["modelValue", "validateRule", "label", "type", "placeholder", "required", "readonly", "onSubmit"]), _createElementVNode("div", {
    class: _normalizeClass([{
      'is-label': $props.label
    }, "range-buttons"])
  }, [_createVNode(_component_app_button, {
    variant: "link",
    "no-min-width": "",
    onClick: $options.onAdd
  }, {
    default: _withCtx(() => [_createVNode(_component_app_icon, {
      icon: "plus-square"
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"]), !$props.noRemove ? (_openBlock(), _createBlock(_component_app_button, {
    key: 0,
    variant: "link",
    "no-min-width": "",
    onClick: $options.onRemove
  }, {
    default: _withCtx(() => [_createVNode(_component_app_icon, {
      icon: "remove"
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true)], 2 /* CLASS */)]);
}