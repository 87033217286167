import AppIcon from '@/components/AppIcon';
import FlexColumn from '../FlexColumn.vue';
import ErrorOccured from "@/components/shared/ErrorOccured.vue";
export default {
  name: 'PPCSettingsSection',
  emits: ["retry-on-error"],
  props: {
    icon: {
      type: String
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true
    },
    errorOccured: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AppIcon,
    FlexColumn,
    ErrorOccured
  },
  methods: {
    retryOnError() {
      this.$emit("retry-on-error");
    }
  }
};