import { mapActions } from "vuex";
import ConnectionFailed from "@/components/ConnectionFailed";
export default {
  name: "ConnectionFailedPage",
  components: {
    ConnectionFailed
  },
  data() {
    return {
      provider: "",
      showAuthStatus: false
    };
  },
  async mounted() {
    var authorization_status = await this.accountAuthorizeRefreshCheck();
    if (!authorization_status || !authorization_status.status) {
      return this.$router.replace("/login"); // stop execution as we had an error
    }
    this.showAuthStatus = true;
  },
  methods: {
    ...mapActions(["accountAuthorizeRefreshCheck"]),
    onClose() {
      this.$router.push("/");
      this.$emit("close-success-window");
    }
  }
};