import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_b_button_group = _resolveComponent("b-button-group");
  return _openBlock(), _createBlock(_component_b_button_group, {
    style: _normalizeStyle({
      margin: 0,
      padding: '0.1rem 0.6rem',
      fontSize: '15px',
      ...$props.customStyle
    }),
    class: "custom-btn-group button-controller"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
      return _openBlock(), _createBlock(_component_b_button, {
        variant: "outline-secondary",
        key: index,
        active: this.activate(item.key),
        onClick: $event => _ctx.$emit('selectItem', item)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.name), 1 /* TEXT */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["active", "onClick"]);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style"]);
}