export default {
  name: 'MultiModeDatePickerUIText',
  props: {
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    },
    separator: {
      type: String,
      default: 'vs'
    }
  }
};