import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_sponsored_sales_card = _resolveComponent("sponsored-sales-card");
  const _component_spend_card = _resolveComponent("spend-card");
  const _component_tacos_card = _resolveComponent("tacos-card");
  const _component_cvr_card = _resolveComponent("cvr-card");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: 24,
    styles: {
      alignSelf: 'stretch'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_sponsored_sales_card, {
      loading: $props.loading,
      amount: $options.previousPeriodKpis[0]?.amount,
      subtitleAmount: $options.previousPeriodKpis[0]?.subtitleAmount,
      percentage: $options.previousPeriodKpis[0]?.percentage,
      subtitlePercentage: $options.previousPeriodKpis[0]?.subtitlePercentage
    }, null, 8 /* PROPS */, ["loading", "amount", "subtitleAmount", "percentage", "subtitlePercentage"]), _createVNode(_component_spend_card, {
      loading: $props.loading,
      amount: $options.previousPeriodKpis[1]?.amount,
      percentage: $options.previousPeriodKpis[1]?.percentage,
      subtitleAmount: $options.previousPeriodKpis[1]?.subtitleAmount,
      subtitlePercentage: $options.previousPeriodKpis[1]?.subtitlePercentage
    }, null, 8 /* PROPS */, ["loading", "amount", "percentage", "subtitleAmount", "subtitlePercentage"]), _createVNode(_component_tacos_card, {
      loading: $props.loading,
      amount: $options.previousPeriodKpis[2]?.amount,
      percentage: $options.previousPeriodKpis[2]?.percentage,
      percentageColor: $options.previousPeriodKpis[2]?.percentageColor,
      percentageBgColor: $options.previousPeriodKpis[2]?.percentageBgColor,
      subtitleAmount: $options.previousPeriodKpis[2]?.subtitleAmount,
      subtitlePercentage: $options.previousPeriodKpis[2]?.subtitlePercentage
    }, null, 8 /* PROPS */, ["loading", "amount", "percentage", "percentageColor", "percentageBgColor", "subtitleAmount", "subtitlePercentage"]), _createVNode(_component_cvr_card, {
      loading: $props.loading,
      amount: $options.previousPeriodKpis[3]?.amount,
      percentage: $options.previousPeriodKpis[3]?.percentage,
      percentageColor: $options.previousPeriodKpis[3]?.percentageColor,
      percentageBgColor: $options.previousPeriodKpis[3]?.percentageBgColor,
      subtitleAmount: $options.previousPeriodKpis[3]?.subtitleAmount,
      subtitlePercentage: $options.previousPeriodKpis[3]?.subtitlePercentage
    }, null, 8 /* PROPS */, ["loading", "amount", "percentage", "percentageColor", "percentageBgColor", "subtitleAmount", "subtitlePercentage"])]),
    _: 1 /* STABLE */
  });
}