import { mapGetters, mapMutations, mapActions } from 'vuex';
import TableSkeleton from '@/components/TableSkeleton.vue';
import SkeletonItem from '@/components/shared/SkeletonItem.vue';
import EmptySpace from '@/components/shared/EmptySpace.vue';
import { truncateString } from '@/utils';
import PagerItem from '@/components/shared/PagerItem.vue';
import TableGrapher from '@/components/shared/graphs/TableGrapher.vue';
import TotalGrapher from './shared/graphs/TotalGrapher.vue';
import CenterWrapper from '@/components/shared/CenterWrapper.vue';
export default {
  name: 'TableGraph',
  emits: ['retry', 'page', 'sortTable'],
  props: {
    graphTitle: {
      type: String,
      required: false
    },
    graphHeaders: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    defaultSortBy: {
      type: String,
      required: false,
      default: ''
    },
    tableLoading: {
      type: Boolean
    },
    totalLoading: {
      type: Boolean
    },
    errorOccurred: {
      type: Boolean
    },
    description: {
      type: Array,
      required: false
    },
    countries: {
      type: Array,
      required: false
    },
    customClass: {
      type: Boolean,
      required: false,
      default: false
    },
    customStyle: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    positions: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    totalPositions: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    minMax: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    totalCount: {
      type: Number,
      required: false,
      default: 0
    },
    pageSize: {
      type: Number,
      required: false,
      default: 0
    },
    currentPage: {
      type: Number,
      required: false,
      default: 0
    },
    noProductImage: {
      type: Boolean,
      required: false,
      default: false
    },
    productFullText: {
      type: Boolean,
      required: false,
      default: false
    },
    setStriped: {
      type: Boolean,
      required: false,
      default: false
    },
    displayTotal: {
      type: Boolean,
      required: false,
      default: false
    },
    dataWidths: {
      type: Array,
      required: false,
      default: () => []
    },
    breakProductName: {
      type: Boolean,
      required: false,
      default: false
    },
    truncateProductName: {
      type: Boolean,
      required: false,
      default: false
    },
    graphId: {
      type: String,
      required: false,
      default: ''
    },
    currentViewPeriodOptions: {
      type: Object
    },
    pageRenderedLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    sortBy: {
      type: String,
      default: ''
    },
    sortDirection: {
      type: String,
      default: 'desc'
    }
  },
  data() {
    return {
      prevScrollTop: 0,
      fetchingData: false
    };
  },
  computed: {
    ...mapGetters(['positionReportGet', 'dashboardPeriodGet', 'dashboardPositionsMinMaxGet', 'dashboardPositionsGraphReportGet', 'dashboardPositionsTotalGet', 'dashboardCurrentTabGet', 'dashboardCurrentViewGet', 'dashboardPageGet', 'dashboardSortFieldGet', 'dashboardSortDirectionGet', 'dashboardShowTotalGet']),
    currentPeriod() {
      return this.dashboardPeriodGet() || '';
    },
    currentTab() {
      return this.dashboardCurrentTabGet();
    },
    currentView() {
      return this.dashboardCurrentViewGet();
    },
    checkForProductPerformance() {
      return this.currentTab === 'Product';
    },
    showTotalItem() {
      return this.totalLoading;
    },
    showTotal() {
      return this.displayTotal;
    },
    dataPresent() {
      return this.positions.length > 0;
    },
    showPager() {
      return this.positions.length >= 200;
    },
    positionsTotal() {
      return this.totalPositions;
    },
    tableWidth() {
      return this.tableWrapper?.offsetWidth || 0;
    }
  },
  components: {
    TableSkeleton,
    EmptySpace,
    SkeletonItem,
    PagerItem,
    TableGrapher,
    TotalGrapher,
    CenterWrapper
  },
  methods: {
    ...mapMutations(['dashboardPageSet', 'dashboardPositionsGraphReportSet', 'dashboardPositionsMinMaxSet', 'dashboardSortDirectionSet', 'dashboardSortFieldSet']),
    ...mapActions(['dashboardGetPositionsReport']),
    getData(field, index, truncate = false, truncateLength = 65) {
      const data = this.positions[index][field];
      if (data === undefined) return null;
      if (truncate) {
        return truncateString(data, truncateLength);
      }
      return data;
    },
    getMax(field) {
      return this.positionsMinMax?.[field]?.max;
    },
    getMin(field) {
      return this.positionsMinMax?.[field]?.min;
    },
    formatPeriod(period) {
      const [year, month, day] = period.split('-');
      const date = new Date(year, month - 1, day);
      let options = {};
      const dashboardPeriod = this.currentPeriod;
      dashboardPeriod === 'Monthly' ? options = {
        month: 'long',
        year: 'numeric'
      } : options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      };
      const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(date);
      return formattedDate;
    },
    truncateString,
    getTotal(field) {
      // Check if the field is `keyword_bid` and the value is <= 0.65, return null
      const value = this.positionsTotal?.[field];
      if (field === 'keyword_bid') {
        return null;
      }
      return value;
    }
  }
};