import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CampaignFilterBody = _resolveComponent("CampaignFilterBody");
  const _component_FilterItem = _resolveComponent("FilterItem");
  return _openBlock(), _createBlock(_component_FilterItem, {
    id: "campaigns",
    ref: "campaignFilterItem",
    icon: "TrendingUpIcon",
    title: $options.getCampaignTitle,
    selectedCount: $props.campaigns.filterState.selectedCount,
    onClearAll: $options.handleClearAll
  }, {
    body: _withCtx(() => [_createVNode(_component_CampaignFilterBody, {
      title: "Campaign",
      selected: $props.campaigns.filterState.selected,
      isModified: $props.campaigns.isModified,
      searching: $props.campaigns.searching,
      list: $props.campaigns.filterState.list,
      onClear: $options.handleClear,
      onSelect: $options.handleSelect,
      onSelectOnly: $options.handleSelectOnly,
      onSelectAll: _cache[0] || (_cache[0] = $event => $props.campaigns.selectAll()),
      onDeselect: $options.handleDeselect,
      onApply: _cache[1] || (_cache[1] = $event => _ctx.$emit('apply')),
      onSearch: $options.handleCampaignSearch,
      onHidden: _cache[2] || (_cache[2] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _ctx.$refs?.campaignFilterItem?.closeDropdown
    }, null, 8 /* PROPS */, ["selected", "isModified", "searching", "list", "onClear", "onSelect", "onSelectOnly", "onDeselect", "onSearch", "onCloseDropdown"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "selectedCount", "onClearAll"]);
}