import { mapState, mapActions } from "vuex";
import ConnectionTabsRenderer from "@/components/shared/ConnectionTabsRenderer";
import AmazonSellerCentralPage from "@/pages/AmazonSellerCentralPage";
// import AmazonAdvertisingPage from "@/pages/AmazonAdvertisingPage";
import AppPagination from "@/components/AppPagination.vue";
export default {
  name: "ConnectionsPage",
  components: {
    ConnectionTabsRenderer,
    AmazonSellerCentralPage,
    // AmazonAdvertisingPage,
    AppPagination
  },
  data() {
    return {
      provider: "Amazon Seller Central",
      dataLoading: true,
      removeLoading: false,
      page: 1,
      perPage: 20,
      totalPages: 0,
      confirmRemoveModal: false,
      removeId: null,
      createAccountModal: false,
      currentTab: 'seller_central',
      provider: "Amazon Seller Central",
      tabs: [{
        value: "seller_central",
        text: "Amazon Seller Central",
        badge: 0,
        canSwitchTab: this.canSwitchTab
      }, {
        value: "vendor_central",
        text: "Amazon Vendor Central",
        badge: 0,
        canSwitchTab: this.canSwitchTab
      }
      /*{
        value: "advertising_central",
        text: "Amazon Advertising",
        badge: 0,
        canSwitchTab: this.canSwitchTab,
      },*/]
    };
  },
  watch: {
    currentTab(newTab) {
      this.updateProviderBasedOnTab(newTab);
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      isOmnyAdmin: state => state.auth.isOmnyAdmin
    }),
    currentComponent() {
      const componentMap = {
        'seller_central': AmazonSellerCentralPage,
        'vendor_central': AmazonSellerCentralPage
        // 'advertising_central': AmazonAdvertisingPage
      };
      return componentMap[this.currentTab] || null;
    }
  },
  async created() {},
  async mounted() {
    // Set `padded` to false to remove padding in `TheWrapperPrivate.innerPage` padding
    this.$store.dispatch("setPadded", false);
  },
  beforeUnmount() {
    // Reset the `padded` to true to add padding in `TheWrapperPrivate.innerPage` padding
    this.$store.dispatch("setPadded", true);
  },
  methods: {
    ...mapActions(["accountList", "accountRemove", "authStatus", "workspaceList"]),
    canSwitchTab() {
      return true;
    },
    updateProviderBasedOnTab(tabValue) {
      const tabToProviderMap = {
        'seller_central': "Amazon Seller Central",
        'vendor_central': "Amazon Vendor Central"
        // 'advertising_central': "Amazon Advertising"
      };
      this.provider = tabToProviderMap[tabValue] || "Default Provider";
    },
    handleTotalUpdate(totalData) {
      this.totalPages = totalData.totalPages;
    },
    updatePerPage(perPage) {
      this.page = 1;
      this.perPage = perPage;
    }
  }
};