import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["id", "innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon_group = _resolveComponent("app-icon-group");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_date_value = _resolveComponent("date-value");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_overlap_bar_value = _resolveComponent("overlap-bar-value");
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_value_element = _resolveComponent("value-element");
  const _component_dotted_value = _resolveComponent("dotted-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  const _component_compound_table = _resolveComponent("compound-table");
  return _openBlock(), _createBlock(_component_compound_table, {
    class: "ppc-performance-period-compound",
    loading: $props.loading,
    fields: $options.fields
  }, {
    header: _withCtx(() => [_createTextVNode(_toDisplayString($options.periodTitle), 1 /* TEXT */)]),
    "group-by-title": _withCtx(() => []),
    actions: _withCtx(() => [_createVNode(_component_app_icon_group, {
      icons: [{
        icon: 'download-csv',
        size: 20,
        help: 'Download CSV',
        click: $options.downloadPerformancePeriodCSV
      }]
    }, null, 8 /* PROPS */, ["icons"])]),
    table: _withCtx(() => [_createVNode(_component_b_table_lite, {
      class: _normalizeClass(['lite-table lite-table-hoverable performance-period-table', {
        ['show-shadow']: $data.showShadow
      }]),
      items: $options.items,
      fields: $options.fields,
      bordered: "",
      responsive: "",
      fixed: "",
      "sticky-header": true,
      tbodyTrClass: item => _ctx.handleRowClass(item, $options.items),
      onScroll: _cache[0] || (_cache[0] = $event => $data.showShadow = $event.target.scrollLeft > 0),
      onRowClicked: _cache[1] || (_cache[1] = item => _ctx.handleRowClicked(item, $options.handleDateReset, $options.handleDateClick, 'period_agg'))
    }, {
      "head()": _withCtx(col => [_createVNode(_component_flex_row, {
        styles: {
          justifyContent: col.field.justify,
          ...col.field?.thStyle
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
          target: `period-table-${col?.label}`,
          style: {
            width: '100%'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", {
            innerHTML: col?.label
          }, null, 8 /* PROPS */, _hoisted_1)]),
          content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
          default: _withCtx(() => [_createElementVNode("div", {
            id: `period-table-${col?.label}`,
            innerHTML: col?.column === 'period_agg' ? $options.getPeriodColumnTitle : col?.label,
            class: "title"
          }, null, 8 /* PROPS */, _hoisted_2)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles"])]),
      "cell(selector)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_AppIcon, {
          icon: `zoom-${row.item.selected ? 'out' : 'in'}`,
          color: row.item.selected ? null : '#ADADAD',
          size: 16
        }, null, 8 /* PROPS */, ["icon", "color"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(period_agg)": _withCtx(row => [_createVNode(_component_date_value, {
        value: row.item?.period_agg,
        format: $options.getDateValueFormat,
        styles: {
          justifyContent: 'flex-start',
          ...$options.activateDateValueStyles(row)
        },
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "format", "styles", "loading"])]),
      "cell(spend)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.spend,
        color: "#5A52AE",
        min: $options.minMax?.spend?.min,
        max: $options.minMax?.spend?.max,
        loading: $props.loading,
        width: $options.maxCharCount?.currency?.spend
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.spend,
          charCount: $options.maxCharCount?.currency?.spend
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "width"])]),
      "cell(sponsored_sales)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.sponsored_sales,
        color: "#8D85E5",
        min: $options.minMax?.sponsored_sales?.min,
        max: $options.minMax?.sponsored_sales?.max,
        loading: $props.loading,
        width: $options.maxCharCount?.currency?.sponsored_sales
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.sponsored_sales,
          charCount: $options.maxCharCount?.currency?.sponsored_sales
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "width"])]),
      "cell(TACOS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
        value: row.item?.TACOS,
        background: "#EBD4F7",
        borderColor: "#CD93EB",
        min: $options.minMax?.TACOS?.min,
        max: $options.minMax?.TACOS?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.TACOS
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(CVR)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getColorsBasedOnRange(row.item?.CVR, $data.CVR_HEATMAP_COLORS),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.CVR,
          limit: 0.1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(ROAS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
        value: row.item?.ROAS,
        background: "#E6E9FE",
        borderColor: "#D8DCFD",
        min: $options.minMax?.ROAS?.min,
        max: $options.minMax?.ROAS?.max,
        loading: $props.loading,
        align: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          styles: {
            padding: 0
          },
          value: row.item?.ROAS,
          precision: 1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(units_sold_clicks_14_d)": _withCtx(row => [_createVNode(_component_number_value, {
        value: row.item?.units_sold_clicks_14_d,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(clicks)": _withCtx(row => [_createVNode(_component_number_value, {
        value: row.item?.clicks,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(best_position)": _withCtx(row => [_createVNode(_component_dotted_value, {
        align: 'center',
        color: $options.getColorsBasedOnRange(row.item?.best_position, $data.BEST_POSITION_HEATMAP_COLORS),
        value: row.item?.best_position,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_value_element, {
          value: row.item?.best_position
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["color", "value", "loading"])]),
      "cell(CPC)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getColorForDecile(row.item?.CPC, $options.minMax?.CPC?.min, $options.minMax?.CPC?.max, $data.CPC_DECILE_COLORS),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.CPC,
          precision: 2,
          config: _ctx.currencyConfig
        }, null, 8 /* PROPS */, ["value", "config"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(sponsored_asin)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.sponsored_asin,
        color: "#4C8EFF",
        min: $options.minMax?.sponsored_asin?.min,
        max: $options.minMax?.sponsored_asin?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          value: row.item?.sponsored_asin,
          charCount: $options.maxCharCount.number?.sponsored_asin,
          style: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class", "items", "fields", "tbodyTrClass"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading", "fields"]);
}