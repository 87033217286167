import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-19f04fb7"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["width", "height", "viewBox"];
const _hoisted_2 = ["d", "stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [(_openBlock(), _createElementBlock("svg", {
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 ${$props.width} ${$props.height}`
  }, [_createElementVNode("path", {
    d: $options.arcPath,
    class: "arc",
    stroke: $props.color
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1))]);
}