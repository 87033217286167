import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 16 16`,
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M14.6693 4.66797L9.42352 9.91372C9.15951 10.1777 9.0275 10.3097 8.87528 10.3592C8.74139 10.4027 8.59716 \n            10.4027 8.46326 10.3592C8.31104 10.3097 8.17904 10.1777 7.91502 9.91372L6.09018 8.08888C5.82617 7.82487 \n            5.69417 7.69287 5.54195 7.64341C5.40805 7.5999 5.26382 7.5999 5.12993 7.64341C4.97771 7.69287 4.8457 \n            7.82487 4.58169 8.08888L1.33594 11.3346M14.6693 4.66797H10.0026M14.6693 4.66797V9.3346",
    stroke: $props.color,
    "stroke-width": "1.5",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}