import { mapState, mapMutations } from "vuex";
import AppCheckbox from "@/components/AppCheckbox";
import AppIcon from "@/components/AppIcon";
import AppPopover from "@/components/AppPopover";
import { EVENTS, isObjectEmpty } from "@/utils";
import LoadingTable from "@/components/LoadingTable";
import DemandForecastingRow from "@/components/DemandForecastingRow";
import DemandForecastingMixins from "@/mixins/DemandForecastingMixins.vue";
export default {
  name: "DemandForecastingContent",
  emits: ["select", "sort", "update"],
  mixins: [DemandForecastingMixins],
  components: {
    AppCheckbox,
    AppIcon,
    AppPopover,
    DemandForecastingRow,
    LoadingTable
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    settings: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Array,
      default: () => []
    },
    isSideBarCollapsed: {
      type: Boolean,
      default: true
    },
    sortField: {
      type: String,
      default: "product_name"
    },
    sortDirection: {
      type: String,
      default: "asc"
    }
  },
  data() {
    return {
      toggleAll: false,
      boost_types: {
        manual: "Manual",
        seasonal: "Seasonal",
        trend: "Trend"
      }
    };
  },
  computed: {
    ...mapState({
      df_manual_override_active: state => state.demand_forecasting.df_manual_override_active
    }),
    settingsInitialized() {
      return !isObjectEmpty(this.settings);
    },
    totalCoverage() {
      return Math.round(this.totalCoverageFromSettings(this.settings));
    },
    additionalBoostType() {
      return this.boost_types[this.settings.boost_type];
    },
    stickyPosition() {
      let style = this.isSideBarCollapsed ? {
        "z-index": "1"
      } : {
        "z-index": "0"
      };
      return style;
    }
  },
  methods: {
    ...mapMutations(["demandForecastingManualOverrideActiveSet", "demandForecastingManualOverrideActiveReset"]),
    onToggleAll() {
      if (this.toggleAll) {
        this.$emit("select", this.items.filter(item => item.orderable).map(item => item.sku));
      } else {
        this.$emit("select", []);
      }
    },
    onSelect(sku) {
      let selected = this.selected.slice();
      if (selected.includes(sku)) {
        selected = selected.filter(item => item != sku);
      } else {
        selected.push(sku);
      }
      this.$emit("select", selected);
    },
    onOrderable(item) {
      this.$emit("update", this.items.map(el => {
        if (el.sku == item.sku) {
          return item;
        }
        return el;
      }));
      let item_to_update = {
        asin: item.asin,
        sku: item.sku,
        seller_id: item.seller_id,
        orderable: item.orderable
      };
      let data = {
        seller_id: item.seller_id,
        product_data_list: [item_to_update]
      };
      this.$emit("save-product-data", data);
    },
    onOrderableUnits(item) {
      this.$emit("update", this.items.map(el => {
        if (el.sku == item.sku) {
          return item;
        }
        return el;
      }));
      this.demandForecastingManualOverrideActiveSet(item);
      let item_to_update = {
        asin: item.asin,
        sku: item.sku,
        seller_id: item.seller_id,
        orderable_units: item.orderable_units,
        manual_override: item.manual_override
      };
      let data = {
        seller_id: item.seller_id,
        product_data_list: [item_to_update]
      };
      this.$emit("save-product-data", data);
    },
    async onClearAllOverride() {
      let product_data_list = this.items.filter(item => item.manual_override).map(item => ({
        asin: item.asin,
        sku: item.sku,
        seller_id: item.seller_id,
        orderable_units: 0,
        manual_override: false
      }));
      this.demandForecastingManualOverrideActiveReset(false);
      let data = {
        seller_id: this.items[0].seller_id,
        product_data_list
      };
      this.$emit("save-product-data", data);
      await window.$bus.trigger(EVENTS.REFRESH_DEMAND_FORECASTING_SETTINGS);
      await window.$bus.trigger(EVENTS.REFRESH_DEMAND_FORECASTING_PAGE);
    },
    onSort(field) {
      const newDirection = field == this.sortField ? this.sortDirection == "asc" ? "desc" : "asc" : "asc";
      const newField = field;
      this.$emit("sort", [newField, newDirection]);
    }
  }
};