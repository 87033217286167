import { mapMutations, mapActions } from 'vuex';
import AppCardCustom from '@/components/AppCardCustom';
import AppSlider from '@/components/AppSlider';
import AppIcon from '@/components/AppIcon';
import PpcBidChart from '@/components/PpcBidChart';
export default {
  name: 'PpcBidOptimizer',
  components: {
    AppCardCustom,
    AppSlider,
    AppIcon,
    PpcBidChart
  },
  props: {
    bid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      levels: [{
        value: 0,
        label: 'OFF',
        tooltip: 'OFF'
      }, {
        value: 1,
        label: '1',
        tooltip: '1'
      }, {
        value: 2,
        label: '2',
        tooltip: '2'
      }, {
        value: 3,
        label: '3',
        tooltip: '3'
      }, {
        value: 4,
        label: '4',
        tooltip: '4'
      }, {
        value: 5,
        label: '5',
        tooltip: '5'
      }, {
        value: 6,
        label: '6',
        tooltip: '6'
      }, {
        value: 7,
        label: '7',
        tooltip: '7'
      }, {
        value: 8,
        label: '8',
        tooltip: '8'
      }, {
        value: 9,
        label: '9',
        tooltip: '9'
      }, {
        value: 10,
        label: '10',
        tooltip: '10'
      }],
      chartHeight: 240,
      chartWidth: 0
    };
  },
  computed: {
    localBid: {
      get() {
        return this.bid;
      },
      set(val) {
        this.saveBid(val);
      }
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapMutations(['ppcBidSet']),
    ...mapActions(['ppcBidSave']),
    onResize() {
      if (this.$refs.chartBox) {
        this.chartWidth = this.$refs.chartBox.offsetWidth;
      }
    },
    saveBid(val) {
      this.ppcBidSet(val);
      this.ppcBidSave({
        bid: val
      });
    }
  }
};