import AppIcon from "./AppIcon.vue";
export default {
  name: "AppTableHeadSeo",
  emits: ["update:sortBy", "update:sortDesc"],
  components: {
    AppIcon
  },
  data() {
    return {
      activeIcon: null
    };
  },
  computed: {
    variantList() {
      return ["MAIN", "PT01", "PT02", "PT03", "PT04", "PT05", "PT06", "PT07", "PT08"];
    }
  },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onSort(field) {
      if (field.sortable) {
        this.activeIcon = field.key;
        if (this.sortBy == field.key) {
          this.$emit("update:sortDesc", !this.sortDesc);
        } else {
          this.$emit("update:sortBy", field.key);
          this.$emit("update:sortDesc", true);
        }
      }
    },
    expandImageList() {
      this.$emit("expandImageList");
    },
    collapseImageList() {
      this.$emit("collapseImageList");
    }
  }
};