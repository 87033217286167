import { mapState, mapGetters } from "vuex";
import HelperMixin from "@/mixins/HelperMixin";
import AppPopover from "@/components/AppPopover";
import AppIcon from "@/components/AppIcon";
import PercentageValue from "@/components/tableElements/PercentageValue";
import { CurrencyValue } from "@/components/tableElements";
import PpcBudgetAllocationCircle from "@/components/PpcBudgetAllocationCircle";
import PpcBudgetAllocationWeeklyBudgetEdit from "@/components/PpcBudgetAllocationWeeklyBudgetEdit";
export default {
  name: "PpcBudgetAllocationItem",
  emits: ["item-update", "weekly-budget-edit"],
  mixins: [HelperMixin],
  components: {
    AppPopover,
    AppIcon,
    PpcBudgetAllocationCircle,
    PpcBudgetAllocationWeeklyBudgetEdit,
    PercentageValue,
    CurrencyValue
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: ""
    },
    weeklyBudgetTarget: {
      type: String,
      default: null
    },
    last: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String
    }
  },
  data() {
    return {
      blockIconOver: false,
      currency_precision: 0,
      isBoostEdit: false,
      localBoost: "1",
      weeklyBudgetShown: null,
      calculatedCapForMode: {
        acos: this.aCOSCap,
        roas: this.rOASCap
      },
      badgeTextMapping: {
        activated: "Newly activated",
        paused: "Paused",
        inactive: "Inactive",
        updated: ""
      }
    };
  },
  computed: {
    ...mapState({
      bid_settings: state => state.ppc_settings.bid_settings
    }),
    ...mapGetters(["getCountryFlag"]),
    boost: {
      get() {
        return this.localBoost;
      },
      set(value) {
        let newValue = value.replace(/[^0-9.]/g, "");
        const parts = newValue.split(".");
        if (parts.length > 2) {
          newValue = parts[0] + "." + parts.slice(1).join("");
        }
        this.localBoost = newValue;
      }
    },
    processedItem() {
      console.log(this.item);
      console.log(this.getCountryFlag(this.item.country_code));
      return {
        id: this.item.campaign_group_id,
        isBlocked: this.item.is_blocked && this.item.is_blocked !== "false",
        name: this.item.campaign_group_name,
        image: this.item.campaign_group_image_link,
        flag: this.getCountryFlag(this.item.country_code),
        result: this.item.result,
        countryCode: this.item.country_code,
        subDetails: this.item.asin_count,
        daysInventory: this.item.days_inventory * 1,
        adsNetProfit4W: this.item.sponsored_net_profit,
        revShare4W: this.item.revenue_4w_share,
        productsMargin: this.item.asin_group_margin,
        boost: this.item?.boost ?? 1,
        cap: this.calculatedCapForMode[this.mode](this.item),
        //45, // TO-DO Need to calculate it
        acos4W: this.item.asin_group_4w_acos,
        roas4W: this.item.asin_group_4w_roas,
        perfomance: {
          budgetAi: this.item.perf_allocated_budget_ai,
          budgetCurrent: this.item.perf_allocated_budget_current,
          budgetPotential: this.item.perf_potential_budget,
          isCustom: this.item.perf_allocated_budget_current !== this.item.perf_allocated_budget_ai,
          potential: this.item.perf_saturation,
          previous: this.item.perf_allocated_budget_current - this.item.perf_previous_week_spend,
          campaigns: this.item.campaign_details.filter(item => item.campaign_theme === "PERF").map(item => ({
            id: item.campaign_id,
            budgetAi: item?.allocated_budget_ai,
            budgetCurrent: item?.allocated_budget_current,
            budgetCalculated: item?.allocated_budget_current,
            evolution: item?.budget_evolution,
            potential: item?.perf_saturation,
            roas: item?.campaign_roas,
            maxRoas: this.item?.asin_group_4w_roas,
            maxAcos: this.item?.asin_group_4w_acos,
            name: item?.campaign_name,
            type: item?.campaign_type
          }))
        },
        discovery: {
          budgetAi: this.item?.discovery_allocated_budget_ai,
          budgetCurrent: this.item?.discovery_allocated_budget_current,
          budgetCalculated: this.item?.discovery_allocated_budget_ai,
          budgetPotential: this.item?.discovery_potential_budget,
          isCustom: this.item?.discovery_allocated_budget_current !== this.item?.discovery_allocated_budget_ai,
          potential: this.item?.discovery_saturation,
          previous: (this.item?.discovery_allocated_budget_current ?? this.item?.discovery_allocated_budget_ai) - this.item?.discovery_previous_week_spend,
          campaigns: this.item.campaign_details.filter(item => item.campaign_theme === "DISCOVERY").map(item => ({
            id: item.campaign_id,
            budgetAi: item.allocated_budget_ai,
            budgetCurrent: item.allocated_budget_current,
            evolution: item.budget_evolution,
            potential: item.perf_saturation,
            roas: item.campaign_roas,
            maxRoas: this.item.asin_group_4w_roas,
            maxAcos: this.item.asin_group_4w_acos,
            name: item.campaign_name,
            type: item.campaign_type
          }))
        }
      };
    },
    getRowClass() {
      const item = this.processedItem;
      const result = [];
      if (item.isBlocked) {
        result.push("ba-row-blocked");
      } else {
        switch (item.result) {
          case "activated":
            result.push("ba-row-activated");
            break;
          case "paused":
            result.push("ba-row-paused");
            break;
          case "inactive":
            result.push("ba-row-inactive");
            break;
          default:
            result.push("ba-row-regular");
            break;
        }
      }
      return result;
    },
    getBadgeText() {
      const item = this.processedItem;
      let result = "";
      console.log(item);
      if (item.isBlocked) {
        result = "Blocked";
      } else {
        result = this.badgeTextMapping[item.result] || "";
      }
      return result;
    },
    shouldDisplayBadge() {
      return !!this.getBadgeText;
    },
    getBlockIconColor() {
      return this.processedItem.isBlocked ? "#DB333B" : this.blockIconOver ? "#F07A80" : "#C9CFD4";
    },
    getCap4W() {
      return this.mode == "acos" ? this.processedItem.acos4W : this.processedItem.roas4W;
    },
    getCapColor() {
      const cap = this.processedItem.cap * 1;
      let result = "";
      if (this.mode == "acos") {
        const value = this.processedItem.acos4W * 1;
        if (value <= cap) {
          result = "ba-text-green";
        } else if (value > 2 * cap) {
          result = "ba-text-red";
        } else if (value > 1.25) {
          result = "ba-text-yellow";
        }
      }
      if (this.mode == "roas") {
        const value = this.processedItem.roas4W * 1;
        if (value >= cap) {
          result = "ba-text-green";
        } else if (value < 0.5 * cap) {
          result = "ba-text-red";
        } else if (value < 0.8 * cap) {
          result = "ba-text-yellow";
        }
      }
      return result;
    },
    getPotentialColor() {
      return value => {
        if (value >= 90) return "color-green";
        if (value >= 60) return "color-yellow";
        return "color-red";
      };
    }
  },
  methods: {
    onBlockIconOverToggle(value) {
      this.blockIconOver = value;
    },
    onBlockToggle() {
      const item = {
        ...this.item
      };
      item.is_blocked = this.processedItem.isBlocked ? "false" : "true";
      this.onItemUpdate(item);
    },
    onItemUpdate(newItem) {
      this.$emit("item-update", newItem);
    },
    onBoostEdit() {
      this.localBoost = this.convertBoost(this.processedItem.boost);
      this.isBoostEdit = true;
      this.$nextTick(() => {
        this.$refs.boost.focus();
      });
    },
    onBoostInput(event) {
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, "");
      const parts = value.split(".");
      if (parts.length > 2) {
        value = parts[0] + "." + parts.slice(1).join("");
      }
      this.boost = value;
    },
    onBoostBlur() {
      this.validateBoost();
      this.isBoostEdit = false;
    },
    onBoostKeydown(event) {
      this.isNumber(event);
      if (event.key === "Enter") {
        this.validateBoost();
        this.isBoostEdit = false;
      } else if (event.key === "Escape") {
        this.localBoost = this.processedItem.boost;
        this.isBoostEdit = false;
      }
    },
    isNumber(event) {
      const allowedKeys = ["Backspace", "Tab", "Enter", "ArrowLeft", "ArrowRight", "Delete", "Home", "End", "Escape"];
      if (allowedKeys.includes(event.key)) {
        return;
      }
      if (event.key === "." && !event.target.value.includes(".")) {
        return;
      }
      if (event.key >= "0" && event.key <= "9") {
        return;
      }
      event.preventDefault();
    },
    validateBoost() {
      let value = parseFloat(this.boost);
      if (isNaN(value) || value <= 0) {
        value = 1;
      }
      const item = {
        ...this.item
      };
      item.boost = value;
      this.onItemUpdate(item);
    },
    convertBoost(value) {
      let strValue = value.toString();
      if (strValue.includes(".")) {
        const parts = strValue.split(".");
        if (parts[1].length > 2) {
          return parseFloat(value).toFixed(2);
        } else if (parts[1].length <= 2) {
          return strValue;
        }
      } else {
        return strValue + ".0";
      }
    },
    onWeeklyEdit(type) {
      const fullType = type === "perf" ? "perfomance" : "discovery";
      if (this.processedItem[fullType].budgetAi !== "-" && this.processedItem[fullType].budgetCurrent !== "-") {
        const target = `weekly${type}${this.processedItem.id}`;
        const popups = document.querySelectorAll(".app-popup");
        popups.forEach(div => {
          const dataClasses = Array.from(div.classList).filter(cls => cls.startsWith("data-"));
          const secondParts = dataClasses.map(cls => cls.split("-")[1]);
          if (!secondParts.includes(target)) {
            document.getElementById(secondParts[0]).click();
          }
        });
        this.$emit("weekly-budget-edit", target);
      }
    },
    onWeeklyReset(type) {
      const item = {
        ...this.item
      };
      item[`${type}_allocated_budget_current`] = item[`${type}_allocated_budget_ai`];
      this.onItemUpdate(item);
    },
    onWeeklyEditPopupShown(target) {
      if (target.startsWith("weekly")) {
        this.weeklyBudgetShown = target;
      }
    },
    onWeeklyEditPopupHidden(target) {
      this.weeklyBudgetShown = null;
    },
    onWeeklyBudgetEditCancel() {
      document.getElementById(this.weeklyBudgetTarget).click();
      this.$emit("weekly-budget-edit", null);
      this.weeklyBudgetShown = null;
    },
    onWeeklyBudgetEditApply(result) {
      document.getElementById(this.weeklyBudgetTarget).click();
      this.$emit("weekly-budget-edit", null);
      this.weeklyBudgetShown = null;
      const item = {
        ...this.item
      };
      item[`${result.type}_allocated_budget_current`] = result.current;
      item.campaign_details = item.campaign_details.map(campaign => {
        const updatedCampaign = result.campaigns.find(item => item.id === campaign.campaign_id);
        if (updatedCampaign) {
          campaign.allocated_budget_current = updatedCampaign.budgetCurrent;
        }
        return campaign;
      });
      this.onItemUpdate(item);
    },
    aCOSCap(item) {
      return item?.asin_group_margin * Math.max(this.bid_settings?.non_branded_keyword_targeting ?? 0, this.bid_settings?.branded_keyword_product_targeting ?? 0, this.bid_settings?.competitor_product_targeting ?? 0);
    },
    rOASCap(item) {
      return 1 / this.aCOSCap(item);
    }
  }
};