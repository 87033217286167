import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    classes: 'text',
    gap: 12,
    styles: {
      justifyContent: 'flex-start'
    }
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.currentPeriod.name), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  });
}