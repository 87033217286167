import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = {
  class: "left h-100"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "header-sub"
};
const _hoisted_4 = {
  class: "right"
};
const _hoisted_5 = {
  class: "group-by"
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  class: "table-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_minus_icon = _resolveComponent("minus-icon");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_minimize_icon = _resolveComponent("minimize-icon");
  const _component_maximize_icon = _resolveComponent("maximize-icon");
  const _component_table_skeleton_v2 = _resolveComponent("table-skeleton-v2");
  return _openBlock(), _createElementBlock("div", _mergeProps({
    class: ['compound-table', $props.innerClass, {
      ['full-size']: $props.fullSize,
      ['show-shadow']: $props.expanded
    }]
  }, _ctx.$attrs), [_createElementVNode("div", {
    class: "top",
    onClick: _cache[4] || (_cache[4] = $event => _ctx.$emit('topbar-click', $event)),
    style: _normalizeStyle({
      backgroundColor: $props.topBgColor
    })
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_flex_row, {
    gap: $props.topLeftGap
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "header")]), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "header-sub")])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["gap"])]), _createElementVNode("div", _hoisted_4, [_ctx.$slots['right'] ? _renderSlot(_ctx.$slots, "right", {
    key: 0
  }) : (_openBlock(), _createBlock(_component_flex_row, {
    key: 1,
    gap: 16
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [!_ctx.$slots['group-by-title'] ? (_openBlock(), _createElementBlock("div", _hoisted_6, "GROUP BY")) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "group-by-title")]), _renderSlot(_ctx.$slots, "group-by")]),
    _: 3 /* FORWARDED */
  })), _renderSlot(_ctx.$slots, "actions")]), $props.hasToolbar ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "compound-table-toolbar",
    onClick: _cache[3] || (_cache[3] = $event => $event.stopPropagation())
  }, [_createVNode(_component_wrap_with_popover, {
    width: "fit-content"
  }, {
    title: _withCtx(() => [_createTextVNode("Minimize")]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["tool", [{
        active: $options.toolbarState.CLOSED
      }]]),
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('minimize', $event))
    }, [_createVNode(_component_minus_icon, {
      width: 16,
      height: 16
    })], 2 /* CLASS */)]),
    _: 1 /* STABLE */
  }), _createVNode(_component_wrap_with_popover, {
    width: "fit-content"
  }, {
    title: _withCtx(() => [_createTextVNode("Normal")]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["tool", [{
        active: $options.toolbarState.INTERMEDIATE
      }]]),
      onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('normalize', $event))
    }, [_createVNode(_component_minimize_icon, {
      width: 16,
      height: 16
    })], 2 /* CLASS */)]),
    _: 1 /* STABLE */
  }), _createVNode(_component_wrap_with_popover, {
    width: "fit-content"
  }, {
    title: _withCtx(() => [_createTextVNode("Expand")]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["tool", [{
        active: $options.toolbarState.FULL_SCREEN
      }]]),
      onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('maximize', $event))
    }, [_createVNode(_component_maximize_icon, {
      width: 16,
      height: 16
    })], 2 /* CLASS */)]),
    _: 1 /* STABLE */
  })])) : _createCommentVNode("v-if", true)], 4 /* STYLE */), _createElementVNode("div", _hoisted_7, [$props.loading ? (_openBlock(), _createBlock(_component_table_skeleton_v2, {
    key: 0,
    fields: $props.fields
  }, null, 8 /* PROPS */, ["fields"])) : _renderSlot(_ctx.$slots, "table", {
    key: 1
  })]), _renderSlot(_ctx.$slots, "pagination")], 16 /* FULL_PROPS */);
}