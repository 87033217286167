import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height", "viewBox"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 ${$props.width} ${$props.height}`,
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [_createElementVNode("path", {
    d: "M4 14.5H6M10 14.5H12M11.6667 4.83333V10.1667M2 4.63333L2 10.3667C2 11.1134 2 11.4868 2.14532 \n        11.772C2.27316 12.0229 2.47713 12.2268 2.72801 12.3547C3.01323 12.5 3.3866 12.5 4.13333 12.5L11.8667 \n        12.5C12.6134 12.5 12.9868 12.5 13.272 12.3547C13.5229 12.2268 13.7268 12.0229 13.8547 11.772C14 11.4868 \n        14 11.1134 14 10.3667V4.63333C14 3.8866 14 3.51323 13.8547 3.22801C13.7268 2.97713 13.5229 2.77316 \n        13.272 2.64533C12.9868 2.5 12.6134 2.5 11.8667 2.5L4.13333 2.5C3.3866 2.5 3.01323 2.5 2.72801 2.64532C2.47713 \n        2.77316 2.27316 2.97713 2.14532 3.22801C2 3.51323 2 3.8866 2 4.63333ZM7.66667 7.5C7.66667 8.42047 6.92047 9.16667 \n        6 9.16667C5.07953 9.16667 4.33333 8.42047 4.33333 7.5C4.33333 6.57953 5.07953 5.83333 6 5.83333C6.92047 5.83333 \n        7.66667 6.57953 7.66667 7.5Z",
    stroke: $props.color,
    "stroke-width": "1.33",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}