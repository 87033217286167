import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "add-filter-dropdown"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_seller_filter = _resolveComponent("seller-filter");
  const _component_marketplace_filter = _resolveComponent("marketplace-filter");
  const _component_product_filter = _resolveComponent("product-filter");
  const _component_filter_divider = _resolveComponent("filter-divider");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item");
  const _component_app_dropdown = _resolveComponent("app-dropdown");
  return _openBlock(), _createBlock(_component_flex_row, {
    class: "ppc-v2-filters",
    style: {
      justifyContent: 'flex-start'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_flex_row, {
      ref: "filtersWrapper",
      class: _normalizeClass(["filters-wrapper", [{
        'extra-filters-active': $options.processedFilters.length > 0
      }]]),
      gap: 16,
      style: {
        justifyContent: 'flex-start'
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_seller_filter, {
        sellers: _ctx.sellers,
        onApply: $options.applySellersForCurrentTab,
        onCleared: $options.handleSellerCleared,
        onlyMode: $options.onlyMode
      }, null, 8 /* PROPS */, ["sellers", "onApply", "onCleared", "onlyMode"]), _createVNode(_component_marketplace_filter, {
        marketplaces: _ctx.marketplaces,
        onApply: $options.applyMarketPlacesForCurrentTab,
        onCleared: $options.handleMarketplaceCleared,
        onlyMode: $options.onlyMode
      }, null, 8 /* PROPS */, ["marketplaces", "onApply", "onCleared", "onlyMode"]), _withDirectives(_createVNode(_component_product_filter, {
        products: _ctx.products,
        sellers: _ctx.sellers,
        marketplaces: _ctx.marketplaces,
        onApply: $options.applyProducts
      }, null, 8 /* PROPS */, ["products", "sellers", "marketplaces", "onApply"]), [[_vShow, $data.enableProductFilter]]), _createVNode(_component_filter_divider, {
        height: 24
      }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.processedFilters, (filter, index) => {
        return _openBlock(), _createBlock(_resolveDynamicComponent(filter.name), _mergeProps({
          ref_for: true,
          ref: filter.name,
          key: index
        }, filter.props, _toHandlers(filter.methods)), null, 16 /* FULL_PROPS */);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"]), _createElementVNode("div", _hoisted_1, [$options.filterDropdownOptions.length > 0 ? (_openBlock(), _createBlock(_component_app_dropdown, {
      key: 0,
      "no-caret": false
    }, {
      "button-content": _withCtx(() => [_createVNode(_component_flex_row, {
        gap: 8
      }, {
        default: _withCtx(() => [_createVNode(_component_app_icon, {
          icon: "plus",
          size: 16,
          color: "#616161"
        }), _createTextVNode(" Add Filter ")]),
        _: 1 /* STABLE */
      })]),
      body: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filterDropdownOptions, ({
        name,
        icon
      }) => {
        return _openBlock(), _createBlock(_component_b_dropdown_item, {
          onClick: $event => $options.handleAddFilter({
            value: name
          })
        }, {
          default: _withCtx(() => [_createVNode(_component_flex_row, {
            styles: {
              justifyContent: 'flex-start'
            },
            gap: 8
          }, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(icon), {
              key: `ppc-v2-filters-${name}`,
              width: 16,
              height: 16,
              color: "#616161"
            })), _createElementVNode("div", null, _toDisplayString(name), 1 /* TEXT */)]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]);
      }), 256 /* UNKEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    })) : _createCommentVNode("v-if", true)])]),
    _: 1 /* STABLE */
  });
}