import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 12 13`,
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M11 6.5H9M3 6.5H1M6 3.5V1.5M6 11.5V9.5M10 6.5C10 8.70914 8.20914 10.5 6 10.5C3.79086 10.5 2 8.70914 2 6.5C2 4.29086 \n          3.79086 2.5 6 2.5C8.20914 2.5 10 4.29086 10 6.5Z",
    stroke: $props.color,
    "stroke-width": "1.2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}