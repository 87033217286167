import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
const _hoisted_3 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: "0 0 16 17",
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M4.66536 9.16663L7.9987 12.5L11.332 9.16663",
    stroke: $props.color,
    "stroke-width": "1.3",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_2), _createElementVNode("path", {
    d: "M4.66536 4.5L7.9987 7.83333L11.332 4.5",
    stroke: $props.color,
    "stroke-width": "1.3",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null, 8 /* PROPS */, _hoisted_3)], 8 /* PROPS */, _hoisted_1);
}