import FlexRow from "@/components/FlexRow.vue";
export default {
  name: 'WrapWithOnlyButton',
  components: {
    FlexRow
  },
  emits: ['click'],
  props: {
    showButton: {
      type: Boolean,
      default: false
    }
  }
};