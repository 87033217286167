export default {
  name: "SearchElement",
  expose: ["clearSearch"],
  props: {
    placeholder: {
      type: String,
      default: "",
      required: false
    },
    value: {
      type: String,
      default: "",
      required: false
    }
  },
  methods: {
    onInput(event) {
      if (this.disabled || !event.target.checkValidity()) return;
      const value = event.target.value.split(",").filter(v => v.length > 0);
      this.$emit("search", {
        value
      });
    },
    clearSearch() {
      this.$emit("search", {
        value: []
      });
    }
  }
};