import AppIcon from '@/components/AppIcon';
export default {
  name: 'DatePickerPeriodPreview',
  components: {
    AppIcon
  },
  props: {
    nextEnabled: {
      type: Boolean,
      default: true
    },
    previousEnabled: {
      type: Boolean,
      default: true
    }
  }
};