import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_popover = _resolveComponent("app-popover");
  const _component_b_card_title = _resolveComponent("b-card-title");
  const _component_b_card = _resolveComponent("b-card");
  const _component_b_overlay = _resolveComponent("b-overlay");
  return _openBlock(), _createBlock(_component_b_overlay, {
    show: $props.loading,
    variant: "white",
    class: "overlay-fullsize"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_card, {
      "no-body": $props.noBody,
      style: _normalizeStyle([$options.styles, {
        overflow: $props.scrollable ? 'auto' : 'hidden'
      }]),
      class: _normalizeClass(["custom-card", {
        bordered: $props.hasBorder
      }])
    }, {
      default: _withCtx(() => [$props.title ? (_openBlock(), _createBlock(_component_b_card_title, {
        key: 0,
        class: _normalizeClass([_ctx.card - $props.title, {
          underline: $props.noBody
        }])
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.title) + " ", 1 /* TEXT */), $props.info ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createVNode(_component_app_icon, {
          icon: "info-circle",
          size: 16,
          color: "#999999",
          id: `info${$data.id}`
        }, null, 8 /* PROPS */, ["id"]), _createVNode(_component_app_popover, {
          target: `info${$data.id}`,
          placement: "bottom"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($props.info), 1 /* TEXT */)]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["target"])], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["no-body", "style", "class"])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["show"]);
}