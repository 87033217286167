import WrapWithStatusIcon from '@/components/seo/WrapWithStatusIcon';
import WrapWithCopy from '@/components/WrapWithCopy';
import AppTd from '@/components/AppTd';
import AppPopover from "@/components/AppPopover";
export default {
  name: 'SeoBulletPointCell',
  components: {
    WrapWithStatusIcon,
    WrapWithCopy,
    AppTd,
    AppPopover
  },
  emits: ['onEdit', 'onPaste', 'isCopied', 'onCopyingHover', 'onCopyingHoverLeave'],
  props: {
    bullet_point: Object,
    item: Object,
    isCopying: Boolean
  },
  methods: {
    onCopyingHover() {
      const refEl = this.$refs[this.bullet_point.id];
      if (!refEl) {
        return;
      }
      this.$emit('onCopyingHover', refEl);
    },
    onCopyingHoverLeave() {
      const refEl = this.$refs[this.bullet_point.id];
      if (!refEl) {
        return;
      }
      this.$emit('onCopyingHoverLeave', refEl);
    },
    handleCopied() {
      const refEl = this.$refs[this.bullet_point.id];
      if (!refEl) {
        return;
      }
      this.$emit('isCopied', refEl);
    }
  }
};