import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-40a86dee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tabs"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  key: 0,
  class: "badge"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, tab => {
    return _openBlock(), _createElementBlock("div", {
      key: tab.value,
      class: _normalizeClass(['tab', {
        active: tab.value == $props.modelValue
      }]),
      ref_for: true,
      ref: `ref-${tab.value}`,
      onClick: $event => $options.onTabChange(tab)
    }, [_createTextVNode(_toDisplayString(tab.text) + " ", 1 /* TEXT */), tab.badge ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(tab.badge), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 10 /* CLASS, PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */)), _createElementVNode("div", {
    class: "line",
    style: _normalizeStyle($data.lineStyle)
  }, null, 4 /* STYLE */)]);
}