import WrapWithCopy from "@/components/WrapWithCopy.vue";
import AppPopover from "@/components/AppPopover";
import AppIcon from "@/components/AppIcon";
import Popper from "vue3-popper";
import AppTd from "@/components/AppTd";
import WrapWithStatusIcon from "@/components/seo/WrapWithStatusIcon.vue";
export default {
  name: "SeoDescriptionCell",
  props: {
    item: Object,
    isCopying: Boolean
  },
  emits: ['onEdit', 'onPaste', 'isCopied', 'onCopyingHover', 'onCopyingHoverLeave'],
  components: {
    AppIcon,
    AppPopover,
    WrapWithCopy,
    Popper,
    AppTd,
    WrapWithStatusIcon
  },
  computed: {
    id() {
      return `${this.item.content_id}-product-description`;
    }
  },
  methods: {
    onCopyingHover() {
      const refEl = this.$refs[this.id];
      if (!refEl) {
        return;
      }
      this.$emit('onCopyingHover', refEl);
    },
    onCopyingHoverLeave() {
      const refEl = this.$refs[this.id];
      if (!refEl) {
        return;
      }
      this.$emit('onCopyingHoverLeave', refEl);
    },
    handleCopied() {
      const refEl = this.$refs[this.id];
      if (!refEl) {
        return;
      }
      this.$emit('isCopied', refEl);
    }
  }
};