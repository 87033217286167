import KpiCard from "@/components/KpiCard.vue";
import PercentageValue from "@/components/tableElements/PercentageValue.vue";
import CurrencyValue from "@/components/tableElements/CurrencyValue.vue";
import NumberValue from "@/components/tableElements/NumberValue.vue";
import { COLORS } from "@/utils/constants";
import FlexRow from "@/components/FlexRow.vue";
import { getDescriptionBasedOnKpiKey } from "@/utils/transformers";
const {
  title,
  detail
} = getDescriptionBasedOnKpiKey("days_inventory");
export default {
  name: "DaysOfInventoryCard",
  props: {
    amount: {
      type: [Number, null]
    },
    subtitleAmount: {
      type: [Number, null]
    },
    days: {
      type: [Number, null]
    },
    subtitlePercentage: {
      type: Number
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      COLORS,
      title,
      detail
    };
  },
  components: {
    KpiCard,
    PercentageValue,
    CurrencyValue,
    NumberValue,
    FlexRow
  }
};