import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: 2,
    styles: $options.getComputedStyle,
    class: "evolution-value"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {
      color: $options.evolutionColor
    }, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["styles"]);
}