import { mapActions, mapState, mapMutations } from "vuex";
import { EVENTS } from "@/utils";
import AppDropdown from "@/components/AppDropdown";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import FlexRow from "@/components/FlexRow.vue";
import SellerFilter from "@/components/new-filters/SellerFilter.vue";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
export default {
  name: "CogsDataTopSection",
  mixins: [AccountsMixin],
  emits: ["create-inventory-order"],
  components: {
    AppDropdown,
    AppButton,
    AppIcon,
    FlexRow,
    SellerFilter
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      item: "omny",
      options: [{
        value: "omny",
        text: "Omny"
      }],
      sellers: []
    };
  },
  computed: {
    ...mapState({
      sellers_filter: state => state.cogs_data.sellers_filter
    })
  },
  methods: {
    ...mapActions(["inventoryCreateOrderCsv"]),
    async applySellers() {
      if (this.sellers_filter.isModified) {
        this.sellers_filter.apply();
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_COGS_DATA_PAGE);
      }
    },
    async clearSellers() {
      if (this.sellers_filter.isModified) {
        this.sellers_filter.clear();
        this.sellers_filter.selectOnly(this.sellers_filter.filterItems.slice(-1).pop(), "id");
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_COGS_DATA_PAGE);
      }
    },
    async onCreateInventoryOrder() {
      window.$bus.trigger("showSuccess", {
        message: "Creating Order csv for download ...  ",
        visible: true,
        delay: 10
      });
      this.$emit("create-inventory-order");
    }
  }
};