import AppIcon from "@/components/AppIcon.vue";
import FlexRow from "@/components/FlexRow.vue";
import CircularIndicator from "./CircularIndicator.vue";
import Icons from "@/components/icons";
export default {
  name: "FilterItemSelector",
  components: {
    ...Icons,
    AppIcon,
    FlexRow,
    CircularIndicator
  },
  emits: ['clearAll', 'click'],
  props: {
    icon: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "#4D4D4D"
    },
    iconSize: {
      type: Number,
      default: 14
    },
    title: {
      type: String,
      default: ""
    },
    selectedCount: {
      type: Number,
      default: 0
    },
    noCaret: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noCount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hovered: false
    };
  },
  computed: {
    getSelectedCount() {
      if (this.selectedCount > 9) {
        return '9+';
      } else {
        return this.selectedCount;
      }
    }
  }
};