import { request } from "@/store/index";
const tasks = {
  actions: {
    async tasksGetAsyncUpdate(_, payload) {
      const data = await request("/tasks/getasync", "POST", payload);
      if (data) {
        return data;
      }
    },
    async tasksGetAsyncFileUploadUpdate(_, payload) {
      const data = await request("/tasks/get", "POST", payload);
      if (data) {
        return data;
      }
    }
  }
};
export default tasks;