import DatePickerSidebar from '@/components/DatePickerSidebar';
import DatePickerRange from '@/components/DatePickerRange';
import DatePickerCalendar from '@/components/DatePickerCalendar';
import { EVENTS, generateMonthsBetweenYears, processDate } from '@/utils';
import moment from 'moment';
import DatePickerLayout from '@/components/DatePickerLayout.vue';
import DatePickerCalendarMonth from '@/components/DatePickerCalendarMonth.vue';
import DatePickerSidebarSection from '@/components/DatePickerSidebarSection.vue';
import { getDatePickerRangeCompare, DATE_PICKER_RANGE_TITLES, DATE_PICKER_COMPARE_TITLES, getDatePickerUIObjectFromDateRange, DATE_PICKER_MODES, CUSTOM_COMPARE_FUNCTION, getDatePickerDayId } from '@/utils/datepicker';
import { toRaw, ref } from 'vue';
import { generateDaysForMonthYear } from '../utils/datepicker';
export default {
  name: 'DatePicker',
  components: {
    DatePickerSidebar,
    DatePickerRange,
    DatePickerCalendar,
    DatePickerCalendarMonth,
    DatePickerLayout,
    DatePickerSidebarSection
  },
  expose: ["setupDatePicker", "reset", "scrollTo"],
  emits: ['cancel', 'submit'],
  setup(props) {
    const getFilteredMonthList = () => {
      const today = moment();
      //console.log("Today", today.format("YYYY-MM-DD"));
      let months = generateMonthsBetweenYears(today.year() - 3, today.year() + 1);
      //console.log("Months", months);

      months = months.filter(({
        date
      }) => {
        return date.isSameOrBefore(today);
      });

      // We insert into `datesMap` using `MONTH_YEAR` as key and `generateDaysForMonthYear(month, year, this.weekType)` as value
      return months;
    };
    const filteredMonthList = ref(getFilteredMonthList());
    const filteredDatesMap = ref(new Map(filteredMonthList.value.map(({
      month,
      year
    }) => {
      return [`${month}_${year}`, generateDaysForMonthYear(month, year, props.weekType)];
    })));

    //console.log("filtered", filteredMonthList, filteredDatesMap)
    return {
      filteredMonthList,
      filteredDatesMap
    };
  },
  mounted() {
    //console.log("filtered", this.filteredMonthList);
    window.$bus.on(EVENTS.RESET_DATE_PICKER, this.reset);
    window.$bus.on(EVENTS.SETUP_DATE_PICKER, this.setupDatePicker);
    // this.datesMap = 
    // this.handleRangeClick()
  },
  unmounted() {
    window.$bus.off(EVENTS.SETUP_DATE_PICKER, this.setupDatePicker);
    window.$bus.off(EVENTS.RESET_DATE_PICKER, this.reset);
  },
  props: {
    date: {
      type: Object
    },
    weekType: {
      type: String
    }
  },
  data() {
    return {
      _range: DATE_PICKER_RANGE_TITLES.CURRENT_WEEK,
      _compare: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD,
      periodOptions: [{
        key: DATE_PICKER_RANGE_TITLES.YESTERDAY,
        name: DATE_PICKER_RANGE_TITLES.YESTERDAY
      }, {
        key: DATE_PICKER_RANGE_TITLES.LAST_7_DAYS,
        name: DATE_PICKER_RANGE_TITLES.LAST_7_DAYS
      }, {
        key: DATE_PICKER_RANGE_TITLES.LAST_28_DAYS,
        name: DATE_PICKER_RANGE_TITLES.LAST_28_DAYS
      }, {
        key: DATE_PICKER_RANGE_TITLES.LAST_WEEK,
        name: DATE_PICKER_RANGE_TITLES.LAST_WEEK
      }, {
        key: DATE_PICKER_RANGE_TITLES.CURRENT_WEEK,
        name: DATE_PICKER_RANGE_TITLES.CURRENT_WEEK
      }, {
        key: DATE_PICKER_RANGE_TITLES.LAST_MONTH,
        name: DATE_PICKER_RANGE_TITLES.LAST_MONTH
      }, {
        key: DATE_PICKER_RANGE_TITLES.CURRENT_MONTH,
        name: DATE_PICKER_RANGE_TITLES.CURRENT_MONTH
      }, {
        key: DATE_PICKER_RANGE_TITLES.CURRENT_YEAR,
        name: DATE_PICKER_RANGE_TITLES.CURRENT_YEAR
      }, {
        key: DATE_PICKER_RANGE_TITLES.LAST_90_DAYS,
        name: DATE_PICKER_RANGE_TITLES.LAST_90_DAYS
      }, {
        key: DATE_PICKER_RANGE_TITLES.LAST_365_DAYS,
        name: DATE_PICKER_RANGE_TITLES.LAST_365_DAYS
      }, {
        key: DATE_PICKER_RANGE_TITLES.LAST_12_MONTHS,
        name: DATE_PICKER_RANGE_TITLES.LAST_12_MONTHS
      }, {
        key: DATE_PICKER_RANGE_TITLES.LAST_12_WEEKS,
        name: DATE_PICKER_RANGE_TITLES.LAST_12_WEEKS
      }, {
        key: DATE_PICKER_RANGE_TITLES.LAST_YEAR,
        name: DATE_PICKER_RANGE_TITLES.LAST_YEAR
      }, {
        key: DATE_PICKER_RANGE_TITLES.CUSTOM,
        name: DATE_PICKER_RANGE_TITLES.CUSTOM
      }],
      compareOptions: [{
        key: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD,
        name: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
      }, {
        key: DATE_PICKER_COMPARE_TITLES.SAME_PERIOD_LY,
        name: DATE_PICKER_COMPARE_TITLES.SAME_PERIOD_LY
      }, {
        key: DATE_PICKER_COMPARE_TITLES.CUSTOM,
        name: DATE_PICKER_COMPARE_TITLES.CUSTOM
      }],
      _state: getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, DATE_PICKER_RANGE_TITLES.CURRENT_WEEK, this.weekType),
      _rangeSelector: "start",
      _compareRangeSelector: null,
      DATE_PICKER_MODE: DATE_PICKER_MODES.READ_ONLY_MODE
    };
  },
  computed: {
    disableOK() {
      return this.state.range.start === null || this.state.range.end === null || this.state.compare.start === null || this.state.compare.end === null;
    },
    currentDate() {
      return this.state;
    },
    state: {
      get() {
        return this._state;
      },
      set(val) {
        this._state = toRaw(val);
      }
    },
    range: {
      get() {
        return this._range;
      },
      set(val) {
        // We auto select the rangeSelector to "start", i.e., the first button in the group
        // And we turn off the compareRangeSelector

        this._rangeSelector = "start";
        this._compareRangeSelector = null;

        /*
            If we are transitioning from Range.Custom (DATE_PICKER_RANGE_TITLES.CUSTOM), 
            then we need to set the `Compare` to (DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD)
        */

        if (this._range === DATE_PICKER_RANGE_TITLES.CUSTOM) {
          this._compare = DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD;
        }
        this._range = val;
        if (val === DATE_PICKER_RANGE_TITLES.CUSTOM) {
          /* 
              We switch into custom mode.
              Custom Mode:
              -- 
              set state <--- defaultState
                  state.range <--- defaultState.range
                  state.compare <--- defaultState.compare
              activate `DatePickerRange.Range.start`
              await input from the `DatePickerCalendar`
          */
          this.DATE_PICKER_MODE = DATE_PICKER_MODES.CUSTOM_MODE;
          this.setDatePickerDefaultState();
          this.rangeSelector = "start";
        } else {
          const res = getDatePickerRangeCompare(this._compare, val, this.weekType);
          this.state.range.start = res.range.start;
          this.state.range.end = res.range.end;
          this.state.compare.start = res.compare.start;
          this.state.compare.end = res.compare.end;
          this.DATE_PICKER_MODE = DATE_PICKER_MODES.READ_ONLY_MODE;
          this.scrollTo(getDatePickerDayId(res.range.start.format("YYYY-MM-DD"), "month-date"));
        }
      }
    },
    compare: {
      get() {
        return this._compare;
      },
      set(val) {
        /*
            If we are transitioning from `Compare.Custom` to either `Compare.Preceding Period` or
            `Compare.Same Period Last Year` then we set the `Range` to `DATE_PICKER_RANGE_TITLES.CURRENT_WEEK`
        */

        if (this._compare === DATE_PICKER_COMPARE_TITLES.CUSTOM) {
          this._range = DATE_PICKER_RANGE_TITLES.CURRENT_WEEK;
        }
        this._compare = val;
        if (val === DATE_PICKER_COMPARE_TITLES.CUSTOM) {
          /* 
              We switch into custom mode.
              Custom Mode:
              -- 
              set state.compare <--- defaultState.compare
              activate `DatePickerRange.Range.start`
              await input from the `DatePickerCalendar`
          */
          this.setDatePickerCompareDefaultState();
          this._range = DATE_PICKER_RANGE_TITLES.CUSTOM;
          this.rangeSelector = "start";
        } else {
          if (this.range === DATE_PICKER_RANGE_TITLES.CUSTOM) {
            const autoComputeFunction = CUSTOM_COMPARE_FUNCTION[this._compare];
            const res = autoComputeFunction({
              ...this.state.range
            });
            this.state.compare.start = res.compare.start;
            this.state.compare.end = res.compare.end;
            return;
          }
          this.state = getDatePickerRangeCompare(val, this.range, this.weekType);
        }
      }
    },
    rangeOptions() {
      return [{
        text: this.rangeFormatted(this.state.range.start, 'start'),
        value: "start"
      }, {
        text: this.rangeFormatted(this.state.range.end, 'end'),
        value: "end"
      }];
    },
    rangeSelector: {
      get() {
        return this._rangeSelector;
      },
      set(val) {
        this._compareRangeSelector = null;
        this._rangeSelector = val;
      }
    },
    compareRangeOptions() {
      return [{
        text: this.compareFormatted(this.state.compare.start, 'start'),
        value: "start"
      }, {
        text: this.compareFormatted(this.state.compare.end, 'end'),
        value: "end"
      }];
    },
    compareRangeSelector: {
      get() {
        return this._compareRangeSelector;
      },
      set(val) {
        this._rangeSelector = null;
        this._compareRangeSelector = val;
      }
    }
  },
  methods: {
    setupDatePicker(rangeTitle, compareTitle, state) {
      if (rangeTitle && compareTitle) {
        this.range = rangeTitle;
        this.compare = compareTitle;
        this.state = state;
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    onOk() {
      this.$emit('submit', getDatePickerUIObjectFromDateRange({
        rangeTitle: this.range,
        compareTitle: this.compare,
        ...this.state
      }));
    },
    handleRangeClick() {
      this.$refs?.range?.focus('start');

      // Deactivate the compare range
      this.$refs?.compare?.deactivate();
    },
    handleCompareClick() {
      this.$refs?.compare?.focus('start');

      // Deactivate the range range
      this.$refs.range.deactivate();
    },
    rangeFormatted(date, type) {
      const output = processDate(date, 'DD MMM YYYY');
      if (!output) {
        if (this.range === DATE_PICKER_RANGE_TITLES.CUSTOM) {
          return `Select ${type} date`;
        }
        return '-';
      }
      return output;
    },
    compareFormatted(date, type) {
      const output = processDate(date, 'DD MMM YYYY');
      if (!output) {
        if (this.compare === DATE_PICKER_COMPARE_TITLES.CUSTOM) {
          return `Select ${type} date`;
        }
        return '-';
      }
      return output;
    },
    enableCustomMode() {
      /*
           Enabling CUSTOM Mode means setting 
              Range <-- DATE_PICKER_RANGE_TITLES.CUSTOM
              Compare <-- DATE_PICKER_COMPARE_TITLES.CUSTOM
      */

      if (this.DATE_PICKER_MODE !== DATE_PICKER_MODES.CUSTOM_MODE) {
        this.DATE_PICKER_MODE = DATE_PICKER_MODES.CUSTOM_MODE;
        this.range = DATE_PICKER_RANGE_TITLES.CUSTOM;
        this.setDatePickerDefaultState();

        //console.log("enableCustomMode", this.state)
      } else if (this.state.range.start !== null && this.state.range.end !== null && this.state.compare.start !== null && this.state.compare.end !== null) {
        //console.log("All 4 stores are full.")
        this.setDatePickerDefaultState();
        this.$nextTick(() => {});
      }
    },
    setDatePickerState(category, type, date) {
      if (!['range', 'compare'].includes(category) && !['start', 'end'].includes(type)) {
        console.warn("Use either `range` or `compare` to set datePickerState");
        console.warn("Use `start` or `end` to the type");
        return;
      }
      this.state[category][type] = date;

      /*
          If Compare is not in Custom mode, 
          then when Range.start and Range.end is filled we need to automatically compute
          Compare.start and Compare.end based on the selected Compare.
      */

      if (this.state.range.start !== null && this.state.range.end && this.DATE_PICKER_MODE === DATE_PICKER_MODES.CUSTOM_MODE && this.compare !== DATE_PICKER_COMPARE_TITLES.CUSTOM) {
        const autoComputeFunction = CUSTOM_COMPARE_FUNCTION[this.compare];
        const res = autoComputeFunction({
          ...this.state.range
        });
        this.state.compare.start = res.compare.start;
        this.state.compare.end = res.compare.end;
      }

      /* 
         Range.start -> RangeSelector.end
         Range.end -> CompareSelector.start
         Compare.start -> CompareSelector.end
      */
      if (category === 'range' && type === 'start') {
        this.rangeSelector = 'end';
      }
      if (category === 'range' && type === 'end') {
        this.compareRangeSelector = 'start';
      }
      if (category === 'compare' && type === 'start') {
        this.compareRangeSelector = 'end';
      }
    },
    setDatePickerRangeDefaultState() {
      this.setDatePickerState('range', 'start', null);
      this.setDatePickerState('range', 'end', null);
    },
    setDatePickerCompareDefaultState() {
      this.setDatePickerState('compare', 'start', null);
      this.setDatePickerState('compare', 'end', null);
    },
    setDatePickerDefaultState() {
      this.setDatePickerRangeDefaultState();
      this.setDatePickerCompareDefaultState();
    },
    reset(date) {
      this._range = date.rangeTitle;
      this._compare = date.compareTitle;
      this.state.range.start = moment(date.range.start);
      this.state.range.end = moment(date.range.end);
      this.state.compare.start = moment(date.compare.start);
      this.state.compare.end = moment(date.compare.end);
      this._compareRangeSelector = null;
      this._rangeSelector = "start";
    },
    scrollTo(id) {
      this.$refs.calendar.scrollTo(id);
    }
  }
};