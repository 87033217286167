import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f2cb2104"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content-box"
};
const _hoisted_2 = {
  class: "text-content"
};
const _hoisted_3 = ["id"];
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_wrap_with_status_icon = _resolveComponent("wrap-with-status-icon");
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, {
    class: "border-table-row images-count-cell"
  }, {
    default: _withCtx(() => [_createVNode(_component_wrap_with_status_icon, {
      validationStatus: $props.item.count_images_style
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", {
        id: `${$props.item.content_id}-count-images`,
        class: "ellipsis"
      }, [$props.item.count_images !== null && typeof $props.item.count_images !== 'undefined' ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.item.count_images), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("i", _hoisted_5, "0"))], 8 /* PROPS */, _hoisted_3)])])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["validationStatus"])]),
    _: 1 /* STABLE */
  });
}