import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_product_table_group_by = _resolveComponent("product-table-group-by");
  const _component_app_icon_group = _resolveComponent("app-icon-group");
  const _component_wrap_with_arrow = _resolveComponent("wrap-with-arrow");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_product_table_total = _resolveComponent("product-table-total");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_overlap_bar_value = _resolveComponent("overlap-bar-value");
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_value_element = _resolveComponent("value-element");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_marketplace_value = _resolveComponent("marketplace-value");
  const _component_product_value = _resolveComponent("product-value");
  const _component_product_group_value = _resolveComponent("product-group-value");
  const _component_campaign_value = _resolveComponent("campaign-value");
  const _component_targeting_value = _resolveComponent("targeting-value");
  const _component_variable_styled_bar = _resolveComponent("variable-styled-bar");
  const _component_layer_bars = _resolveComponent("layer-bars");
  const _component_bar_slot_with_value = _resolveComponent("bar-slot-with-value");
  const _component_targeting_type_value = _resolveComponent("targeting-type-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  const _component_floating_compound_table = _resolveComponent("floating-compound-table");
  return _openBlock(), _createBlock(_component_floating_compound_table, {
    style: {
      ['--compound-table-right-gap']: '16px'
    },
    datePickerDate: $options.datePickerDate,
    loading: $props.loading,
    onChangeDate: $options.handleDatePickerSubmit,
    onTableState: $options.handleTableState,
    page: $options.page,
    "onUpdate:page": _cache[0] || (_cache[0] = $event => $options.page = $event),
    totalPages: $options.totalPages,
    "onUpdate:totalPages": _cache[1] || (_cache[1] = $event => $options.totalPages = $event),
    perPage: $options.perPage,
    "onUpdate:perPage": _cache[2] || (_cache[2] = $event => $options.perPage = $event),
    fields: $options.fields,
    ref: "ppcProductTable"
  }, {
    header: _withCtx(() => [_createTextVNode("Breakdown by")]),
    "header-sub": _withCtx(() => [_createVNode(_component_product_table_group_by, {
      onRefresh: $options.handleGroupByRefresh,
      onClicked: $options.handleClicked,
      tabBusy: $props.loading
    }, null, 8 /* PROPS */, ["onRefresh", "onClicked", "tabBusy"])]),
    "prepend-actions": _withCtx(() => [_createVNode(_component_app_icon_group, {
      icons: [{
        icon: 'download-csv',
        size: 20,
        help: 'Download CSV',
        click: $options.downloadPPCV2CSV
      }]
    }, null, 8 /* PROPS */, ["icons"])]),
    table: _withCtx(({
      fullSize,
      visible
    }) => [_createVNode(_component_b_table_lite, {
      class: _normalizeClass(['lite-table', 'ppc-v2-product-table', {
        ['full-size']: fullSize
      }, {
        ['intermediate']: !fullSize && visible
      }]),
      items: $options.items,
      style: _normalizeStyle(_ctx.cssProps),
      fields: $options.fields,
      responsive: "",
      fixed: "",
      "sticky-header": "",
      bordered: "",
      "no-border-collapse": "",
      tbodyTrClass: item => `item-row ${_ctx.handleRowClass(item, $options.items)}`,
      onHeadClicked: $options.handleHeadClick,
      onRowClicked: $options.handleRowClick,
      onRowHovered: $options.handleShowComposites,
      onRowUnhovered: $options.handleHideComposites
    }, {
      "head()": _withCtx(col => [_createVNode(_component_flex_row, {
        styles: {
          justifyContent: col.field.justify,
          ...col.field?.thStyle
        },
        id: `advertising-product-table-${col?.column}-${_ctx.groupBy}`
      }, {
        default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
          triggers: "hover",
          target: `advertising-product-table-${col?.column}-${_ctx.groupBy}`,
          style: {
            width: '100%'
          }
        }, {
          title: _withCtx(() => [_createElementVNode("div", {
            innerHTML: $options.getDescriptionBasedOnKpiKey(col?.column).title
          }, null, 8 /* PROPS */, _hoisted_1)]),
          content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
          default: _withCtx(() => [['asc', 'desc'].includes(this.sort[col?.column]) ? (_openBlock(), _createBlock(_component_wrap_with_arrow, {
            key: 0,
            direction: this.sort[col?.column]
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              class: "title",
              innerHTML: col?.label
            }, null, 8 /* PROPS */, _hoisted_2)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["direction"])) : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "title",
            innerHTML: col?.label
          }, null, 8 /* PROPS */, _hoisted_3))]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles", "id"])]),
      "custom-foot": _withCtx(() => [_createElementVNode("tr", null, [_createVNode(_component_product_table_total, {
        fields: $options.fields,
        total: $options.total,
        currencyConfig: _ctx.currencyConfig,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["fields", "total", "currencyConfig", "loading"])])]),
      "bottom-row": _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.fields, ({
        stickyColumn
      }, index) => {
        return _openBlock(), _createElementBlock("td", {
          key: index,
          class: _normalizeClass(['bottom-row', {
            'b-table-sticky-column': stickyColumn
          }]),
          style: {}
        }, null, 2 /* CLASS */);
      }), 128 /* KEYED_FRAGMENT */))]),
      "cell(spend)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.spend,
        color: "#5A52AE",
        min: $options.minMax?.spend?.min,
        max: $options.minMax?.spend?.max,
        loading: $props.loading,
        maxCharCount: $options.maxCharCount?.spend
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.spend,
          limit: 10_000,
          config: _ctx.currencyConfig,
          charCount: $options.maxCharCount.currency?.spend
        }, null, 8 /* PROPS */, ["value", "config", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "maxCharCount"])]),
      "cell(sponsored_sales)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.sponsored_sales,
        color: "#8D85E5",
        min: $options.minMax?.sponsored_sales?.min,
        max: $options.minMax?.sponsored_sales?.max,
        loading: $props.loading,
        maxCharCount: $options.maxCharCount?.sponsored_sales
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.sponsored_sales,
          limit: 10_000,
          config: _ctx.currencyConfig,
          charCount: $options.maxCharCount.currency?.sponsored_sales
        }, null, 8 /* PROPS */, ["value", "config", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "maxCharCount"])]),
      "cell(TACOS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
        value: row.item?.TACOS,
        background: "#EBD4F7",
        borderColor: "#CD93EB",
        min: $options.minMax?.TACOS?.min,
        max: $options.minMax?.TACOS?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.TACOS
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(CVR)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getColorsBasedOnRange(row.item?.CVR, $data.CVR_HEATMAP_COLORS),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.CVR,
          limit: 0.1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(ROAS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
        value: row.item?.ROAS,
        background: "#E6E9FE",
        borderColor: "#D8DCFD",
        min: $options.minMax?.ROAS?.min,
        max: $options.minMax?.ROAS?.max,
        loading: $props.loading,
        align: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          styles: {
            padding: 0
          },
          value: row.item?.ROAS,
          precision: 1
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(units_sold_clicks_14_d)": _withCtx(row => [_createVNode(_component_number_value, {
        value: row.item?.units_sold_clicks_14_d,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(clicks)": _withCtx(row => [_createVNode(_component_number_value, {
        value: row.item?.clicks,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(CPC)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getColorForDecile(row.item?.CPC, $options.minMax?.CPC?.min, $options.minMax?.CPC?.max, $data.CPC_DECILE_COLORS),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.CPC,
          precision: 2,
          config: _ctx.currencyConfig
        }, null, 8 /* PROPS */, ["value", "config"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(sponsored_asin)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.sponsored_asin,
        color: "#4C8EFF",
        min: $options.minMax?.sponsored_asin?.min,
        max: $options.minMax?.sponsored_asin?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_number_value, {
          value: row.item?.sponsored_asin,
          align: "right",
          style: {
            padding: 0
          },
          charCount: $options.maxCharCount.number?.sponsored_asin
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(seller_name)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading,
        align: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_value_element, {
          value: row.item?.seller_name
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(sales_channel)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading,
        align: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_marketplace_value, {
          marketplace: row.item?.sales_channel,
          flag: row.item?.flag
        }, null, 8 /* PROPS */, ["marketplace", "flag"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(campaign_budget)": _withCtx(row => [_createVNode(_component_hug_value, {
        align: "right",
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.campaign_budget,
          config: _ctx.currencyConfig
        }, null, 8 /* PROPS */, ["value", "config"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(campaign_group_budget)": _withCtx(row => [_createVNode(_component_hug_value, {
        align: "right",
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.campaign_group_budget,
          config: _ctx.currencyConfig
        }, null, 8 /* PROPS */, ["value", "config"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      "cell(keyword_bid)": _withCtx(row => [_createVNode(_component_boxed_value, {
        bgColor: $options.getColorsBasedOnRange(row.item?.keyword_bid, $data.KEYWORD_BID_HEATMAP_COLORS),
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_currency_value, {
          value: row.item?.keyword_bid,
          precision: 2,
          config: _ctx.currencyConfig
        }, null, 8 /* PROPS */, ["value", "config"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
      "cell(product_name)": _withCtx(row => [_createVNode(_component_product_value, {
        value: {
          name: row.item?.product_name,
          image: row.item?.main_image_link,
          asin: row.item?.asin,
          sku: row.item?.sku
        },
        loading: $props.loading,
        sponsored: row.item?.sku === 'SPONSORED_BRANDS'
      }, null, 8 /* PROPS */, ["value", "loading", "sponsored"])]),
      "cell(campaign_group_name)": _withCtx(row => [_createVNode(_component_product_group_value, {
        value: {
          name: row.item?.campaign_group_name,
          image: row.item?.campaign_group_image,
          campaignsCount: row.item?.campaign_activated_products_count,
          campaignIdCount: row.item?.campaign_id_count,
          color: $options.getCampaignsStateColor(row.item?.advertising_state),
          flag: row.item?.flag
        },
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(campaign_name)": _withCtx(row => [_createVNode(_component_campaign_value, {
        value: {
          name: row.item?.campaign_name,
          type: row.item?.ad_product,
          state: row.item?.campaign_state,
          flag: row.item?.flag
        },
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(targeting)": _withCtx(row => [_createVNode(_component_targeting_value, {
        value: {
          campaignName: row.item?.campaign_name,
          targeting: row.item?.targeting,
          type: row.item?.match_type ?? '',
          image: row.item?.targeting_product_image,
          link: row.item?.targeting_product_link
        },
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(percent_time_in_budget)": _withCtx(row => [_createVNode(_component_bar_slot_with_value, {
        loading: $props.loading,
        value: row.item?.percent_time_in_budget,
        reverse: ""
      }, {
        bar: _withCtx(() => [_createVNode(_component_layer_bars, null, {
          default: _withCtx(() => [_createVNode(_component_variable_styled_bar, {
            value: $options.minMax?.percent_time_in_budget?.max,
            max: $options.minMax?.percent_time_in_budget?.max,
            min: $options.minMax?.percent_time_in_budget?.min,
            color: "#E8EBF3",
            height: "6"
          }, null, 8 /* PROPS */, ["value", "max", "min"]), _createVNode(_component_variable_styled_bar, {
            value: row.item?.percent_time_in_budget,
            max: $options.minMax?.percent_time_in_budget?.max,
            min: $options.minMax?.percent_time_in_budget?.min,
            color: "#5F799B",
            height: "6"
          }, null, 8 /* PROPS */, ["value", "max", "min"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */)]),
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.percent_time_in_budget,
          charCount: $options.maxCharCount.percentage?.percent_time_in_budget
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "value"])]),
      "cell(targeting_type)": _withCtx(row => [_createVNode(_component_targeting_type_value, {
        value: row.item?.targeting_type,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      "cell(share_sponsored_sales)": _withCtx(row => [_createVNode(_component_bar_with_value, {
        value: row.item?.share_sponsored_sales,
        min: $options.minMax?.share_sponsored_sales?.min,
        color: "#5A52AE",
        max: $options.minMax?.share_sponsored_sales?.max,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_createVNode(_component_percentage_value, {
          value: row.item?.share_sponsored_sales,
          charCount: $options.maxCharCount.percentage?.share_sponsored_sales
        }, null, 8 /* PROPS */, ["value", "charCount"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
      "cell(ad_product)": _withCtx(row => [_createVNode(_component_hug_value, {
        loading: $props.loading,
        align: "left"
      }, {
        default: _withCtx(() => [_createVNode(_component_value_element, {
          value: $data.AD_PRODUCT_KEYMAP[row.item?.ad_product]
        }, null, 8 /* PROPS */, ["value"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "items", "style", "fields", "tbodyTrClass", "onHeadClicked", "onRowClicked", "onRowHovered", "onRowUnhovered"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["datePickerDate", "loading", "onChangeDate", "onTableState", "page", "totalPages", "perPage", "fields"]);
}