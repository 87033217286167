import { mapState, mapActions } from 'vuex';
import TheWrapperPrivate from '@/components/TheWrapperPrivate.vue';
import IntercomScript from "@/components/shared/IntercomScript";
export default {
  name: 'AuthPage',
  components: {
    TheWrapperPrivate,
    IntercomScript
  },
  data() {
    return {
      isSpecialTemplate: false,
      initializationFinished: false,
      intercomAppId: process.env.VUE_APP_OMNY_APP_INTERCOM_APP_ID,
      is_authenticated: false,
      userName: "",
      userEmail: ""
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token
      //is_authenticated: state => state.auth.is_authenticated,
    })

    // isLoggedIn() {
    //   //return !! this.token
    //   //return this.authStatus();
    //   return this.authStatus();
    //   //return this.is_authenticated
    // },
  },
  async created() {
    //https://www.aleksandrhovhannisyan.com/blog/async-functions-that-return-booleans/
    this.is_authenticated = await this.authStatus(false);
    if (!this.is_authenticated) {
      this.$router.replace('/login');
    } else {
      const userInfo = await this.authMe();
      await this.workspaceList();
      await this.workspaceCurrent();
      await this.accountList({});
      this.initializationFinished = true;
      this.userName = userInfo.first_name + " " + userInfo.last_name;
      this.userEmail = userInfo.email;
    }
  },
  methods: {
    ...mapActions(['authMe', 'authStatus', "workspaceCurrent", "accountList", "workspaceList"]),
    getFeatureName() {
      return "intercommessagingfeature";
    }
  }
};