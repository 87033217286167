import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "compound-aggregate-table"
};
const _hoisted_2 = {
  class: "headers"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_aggregate_table_header = _resolveComponent("aggregate-table-header");
  const _component_data_and_evolution_value = _resolveComponent("data-and-evolution-value");
  const _component_percent_net_revenue_value = _resolveComponent("percent-net-revenue-value");
  const _component_b_container = _resolveComponent("b-container");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_flex_row, {
    class: "aggregate-table",
    styles: {
      alignItems: 'unset',
      justifyContent: 'unset'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_b_container, {
      class: "table"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_aggregate_table_header, {
        class: "data",
        colGroup: $props.colGroups[0],
        loading: $props.loading,
        period1: $options.period1,
        period2: $options.period2
      }, null, 8 /* PROPS */, ["colGroup", "loading", "period1", "period2"]), _createVNode(_component_aggregate_table_header, {
        class: "percent",
        colGroup: $props.colGroups[1],
        loading: $props.loading,
        period1: $options.period1,
        period2: $options.period2
      }, null, 8 /* PROPS */, ["colGroup", "loading", "period1", "period2"])]), _createVNode(_component_b_container, {
        class: "body"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(['aggregation']),
            key: index,
            ref_for: true,
            ref: `aggregation-${index}`
          }, [_createVNode(_component_data_and_evolution_value, {
            class: "data",
            ref_for: true,
            ref: `data-evolution-${index}`,
            onTogglePercentAggregate: $event => $options.handleTogglePercentAggregate(index),
            value: item,
            onTogglePercentDetails: detailIndex => $options.handleTogglePercentDetails(index, detailIndex),
            type: $options.getType(item.pl_aggregate),
            fields: $props.colGroups[0].fields,
            loading: $props.loading
          }, null, 8 /* PROPS */, ["onTogglePercentAggregate", "value", "onTogglePercentDetails", "type", "fields", "loading"]), _createVNode(_component_percent_net_revenue_value, {
            class: "percent",
            ref_for: true,
            ref: `percent-net-${index}`,
            value: item,
            type: $options.getType(item.pl_aggregate),
            fields: $props.colGroups[1].fields,
            loading: $props.loading,
            minMax: $props.minMax
          }, null, 8 /* PROPS */, ["value", "type", "fields", "loading", "minMax"])]);
        }), 128 /* KEYED_FRAGMENT */))]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  })]);
}