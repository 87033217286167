export default {
  name: 'HelperMixin',
  methods: {
    priceFormatter(amount, showSign = false) {
      if (amount === null || amount === undefined || amount === '-') {
        return '-';
      }
      const sign = showSign && amount > 0 ? '+' : '';
      return sign + amount.toLocaleString('fr-FR', {
        maximumFractionDigits: 2
      }) + ' €';
    },
    numberFormatter(amount, decimals = 0) {
      if (amount === null || amount === undefined || amount === '-') {
        return '-';
      }
      let num = Number(amount);
      if (isNaN(num)) {
        return amount;
      }
      const decimalPart = num.toString().split('.')[1];
      const decimalPlaces = decimalPart ? decimalPart.length : 0;
      if (decimalPlaces > decimals) {
        num = Number(num.toFixed(decimals));
      }
      return num;
    }
  }
};