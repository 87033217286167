import { mapState, mapActions } from "vuex";
import CustomSideBarAppModal from "@/components/CustomSideBarAppModal";
import AppModal from "@/components/AppModal";
import AppIcon from "@/components/AppIcon";
import AppButton from "@/components/AppButton";
import { country_codes, country_codes_flipped } from "@/utils/countries";
export default {
  name: "AdvertisingAccountSideBar",
  emits: ["refresh"],
  components: {
    CustomSideBarAppModal,
    AppIcon,
    AppModal,
    AppButton
  },
  props: {
    account: {
      type: Object,
      default: () => ({})
    },
    account_type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      confirmRemoveModal: false,
      removeLoading: false,
      removeId: null
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      isOmnyWorkspace: state => state.workspace.isOmnyWorkspace,
      current_workspace: state => state.workspace.current_workspace
    })
  },
  methods: {
    ...mapActions(["accountAuthorize", "accountRemove"]),
    onRemoveConfirm(account_id) {
      this.removeId = account_id;
      this.confirmRemoveModal = true;
    },
    async onRemove() {
      this.confirmRemoveModal = false;
      this.removeLoading = true;
      await this.accountRemove({
        seller_list: [{
          account_id: this.removeId
        }],
        workspace_id: this.current_workspace.workspace_id
      });
      this.$emit("refresh", {
        "account_id": this.account.account_id,
        "type": this.account_type
      });
      this.removeLoading = false;
      this.removeId = null;
    },
    async onConnect() {
      const data = {
        region: this.account.region,
        account_provider: "Amazon Advertising",
        workspace_id: this.account.workspace_id,
        amz_sp_api_seller_id: this.account.selling_partner_id,
        country: country_codes[this.account.country],
        account_type: this.account_type
      };
      this.loading = true;
      await this.accountAuthorize(data);
      this.loading = false;
    },
    flipCountry(country) {
      return country_codes_flipped[country];
    }
  }
};