import AppIcon from "@/components/AppIcon";
export default {
  name: "TheSidebarMenuItem",
  emits: ["click"],
  components: {
    AppIcon
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
    isHovering: {
      type: Boolean,
      default: false
    },
    openItemText: String
  },
  data() {
    return {
      //openItemText: null,
      //isOpen: false,
    };
  },
  computed: {
    isActive() {
      if (this.item.children && this.item.children.length > 0) {
        return this.hasActiveChildren || this.$route.path.indexOf(this.item.url) == 0;
      }
      if (!this.item.children) {
        if (this.item.text === "Dashboard") {
          return this.checkForHomeRoutes(this.$route.path);
        }
        return this.$route.path == this.item.url;
      }
      return false;
    },
    isChildActive() {
      return item => {
        return item.url && this.$route.path.indexOf(item.url) == 0;
      };
    },
    hasActiveChildren() {
      if (this.item.children && this.item.children.length > 0) {
        for (const child of this.item.children) {
          if (this.isChildActive(child)) {
            return true;
          }
        }
      }
      return false;
    },
    isOpen() {
      return this.item.text === this.openItemText;
    },
    showMenuIcon() {
      return this.isCollapsed && !this.isHovering;
    }
  },
  // mounted() {
  //   if (this.hasActiveChildren) {
  //     this.isOpen = true;
  //   }
  // },

  methods: {
    onClick(item) {
      if (item.children && item.children.length > 0) {
        this.$emit("click", item);
      }
      if (item.url) {
        this.$emit("click", item);
        this.$router.push(item.url);
      }
      if (item.action) {
        this.$emit("click", item);
      }
    },
    getFeatureName(item) {
      return item.featureName;
    },
    checkForHomeRoutes(url) {
      if (url === "/") {
        return true;
      }
      return false;
    }
  }
};