import SkeletonItem from '@/components/shared/SkeletonItem.vue';
export default {
  name: 'TableSkeleton',
  props: {
    customStyle: {
      type: Object,
      default: () => {
        return {
          width: '100%'
        };
      }
    },
    headers: {
      type: Array,
      required: true,
      default: () => ["", "", "", "", "", "", "", "", "", "", "", ""]
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    length: {
      type: Number,
      default: 12
    }
  },
  components: {
    SkeletonItem
  },
  data() {
    return {
      contents: [14].map(item => {
        let res = [];
        for (let i = 0; i < item; i++) {
          res.push(i);
        }
        return res;
      }).pop()
    };
  },
  computed: {
    getContent() {
      return [this.length].map(item => {
        let res = [];
        for (let i = 0; i < item; i++) {
          res.push(i);
        }
        return res;
      }).pop();
    },
    getHeaders() {
      if (this.headers.length > 0) {
        return this.headers;
      } else {
        return [this.length].map(item => {
          let res = [];
          for (let i = 0; i < item; i++) {
            res.push(i);
          }
          return res;
        }).pop();
      }
    }
  }
};