import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2f61664e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ui-text"
};
const _hoisted_2 = {
  class: "start"
};
const _hoisted_3 = {
  class: "separator"
};
const _hoisted_4 = {
  class: "end"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.start), 1 /* TEXT */), _createElementVNode("span", _hoisted_3, " " + _toDisplayString($props.separator) + " ", 1 /* TEXT */), _createElementVNode("div", _hoisted_4, _toDisplayString($props.end), 1 /* TEXT */)]);
}