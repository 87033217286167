import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-036c61a0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sidebar-inner"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  class: "sidebar-content"
};
const _hoisted_4 = {
  class: "sidebar-menu"
};
const _hoisted_5 = {
  class: "sidebar-menu-buttons"
};
const _hoisted_6 = {
  key: 0,
  class: "sidebar-backdrop"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_demand_forecasting_settings = _resolveComponent("demand-forecasting-settings");
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
    class: _normalizeClass(['sidebar', $data.menu])
  }, [_createElementVNode("div", _hoisted_1, [_ctx.demand_forecasting_settings_initialized ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$data.menu == 'settings' ? (_openBlock(), _createBlock(_component_demand_forecasting_settings, {
    key: 0,
    account_id: $props.account_id,
    onClose: _cache[0] || (_cache[0] = $event => $options.onToggleMenu($data.menu))
  }, null, 8 /* PROPS */, ["account_id"])) : _createCommentVNode("v-if", true)])])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
    class: _normalizeClass(['sidebar-menu-button', {
      active: $data.menu == 'settings'
    }]),
    onClick: _cache[1] || (_cache[1] = $event => $options.onToggleMenu('settings'))
  }, [_createVNode(_component_app_icon, {
    icon: "settings",
    size: 18,
    color: "#616161"
  })], 2 /* CLASS */)])])])], 2 /* CLASS */), $data.menu ? (_openBlock(), _createElementBlock("div", _hoisted_6)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
}