import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-aa4c86ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_WrapWithPopover = _resolveComponent("WrapWithPopover");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, _toDisplayString($props.title), 1 /* TEXT */), _createVNode(_component_WrapWithPopover, {
    width: "465px",
    padding: "16px"
  }, {
    content: _withCtx(() => [_renderSlot(_ctx.$slots, "details", {}, undefined, true)]),
    default: _withCtx(() => [_createVNode(_component_app_icon, {
      icon: "info",
      color: "#999999"
    })]),
    _: 3 /* FORWARDED */
  })]);
}