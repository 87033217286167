import _ from 'lodash';
import table_descriptions from '@/utils/table_descriptions';
export const sortData = (data, key = 'name') => {
  if (!data) return [];
  data.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
  return data;
};
export const fakeDataGenerator = rows => {
  return [rows].map(item => {
    let res = [];
    for (let i = 0; i < item; i++) {
      res.push(i);
    }
    return res;
  }).pop();
};
export const truncateString = (str, num) => {
  if (!str) return '';
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};
export const isSubset = (subset, superset) => {
  for (const element of subset) {
    if (!superset.has(element)) {
      return false;
    }
  }
};
export const headerDescription = (tab, view) => {
  return table_descriptions(tab, view);
};
export const getValue = (object, string, defaultValue = '') => {
  return _.get(object, string, defaultValue);
};
export const copyToClipboard = value => {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  window.$bus.trigger('showSuccess', {
    type: 'success',
    visible: true,
    message: 'Copied to clipboard',
    delay: 100
  });
};
export const getAdvertisingAccountsFromList = list => {
  const accounts = list?.filter(account => account.account_provider === 'Amazon Advertising');
  let advertisingAccounts = new Map();
  if (!accounts) return advertisingAccounts;
  accounts.forEach(account => {
    // Get the account list, if it exists, if not add an new list
    const key = account?.accountInfo?.id;
    const value = advertisingAccounts.get(key) ?? [];
    key && advertisingAccounts.set(key, [...value, account]);
  });
  return advertisingAccounts;
};
export const formatSellersFromAdvertisingAccounts = advertisingAccounts => {
  let accounts = {};
  for (const [key, value] of advertisingAccounts.entries()) {
    if (value.length > 0) accounts[key] = {
      name: value[0].accountInfo.name,
      id: key,
      timezone: value[0].timezone.split('/')[0].replaceAll('_', ' ')
    };
  }
  return accounts;
};
export const TABLE_HEADERS_CONFIG = {
  Weekly: {
    name: 'Week (starting date)',
    currentViewPeriodOptions: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  },
  Monthly: {
    name: 'Month',
    currentViewPeriodOptions: {
      month: 'long',
      year: 'numeric'
    }
  },
  Daily: {
    name: 'Day',
    currentViewPeriodOptions: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  }
};