export default {
  name: 'PagerItem',
  emits: ['page'],
  props: {
    currentPage: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 0
    },
    totalCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    pageStart() {
      return (this.currentPage - 1) * this.pageSize + 1;
    },
    pageEnd() {
      return Math.min(this.currentPage * this.pageSize, this.totalCount);
    },
    activateLeft() {
      return this.currentPage > 1;
    },
    activateRight() {
      return this.currentPage < this.totalCount / this.pageSize;
    }
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit('page', this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalCount / this.pageSize) {
        this.$emit('page', this.currentPage + 1);
      }
    }
  }
};