import FlexRow from '../FlexRow.vue';
import HugValue from './HugValue.vue';
export default {
  name: "BarSlotWithValue",
  props: {
    value: {
      type: [Number, null],
      required: false,
      default: 3301
    },
    align: {
      type: String,
      required: false,
      default: "right"
    },
    gap: {
      type: Number,
      required: false,
      default: 8
    },
    loading: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    tight: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HugValue,
    FlexRow
  },
  computed: {
    validValue() {
      return this.value !== null && this.value !== undefined;
    },
    computedStyle() {
      return {
        justifyContent: this.align === "left" ? "flex-start" : "flex-end",
        gap: `${this.gap}px`
      };
    }
  }
};