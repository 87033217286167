import { mapState, mapGetters, mapActions } from "vuex";
import ConnectionPageTitle from "@/components/ConnectionPageTitle";
import AppCardConnections from "@/components/AppCardConnections";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import AppModal from "@/components/AppModal";
import CustomSideBarAppModal from "@/components/CustomSideBarAppModal";
import CreateAccountSideBar from "@/components/CreateAccountSideBar";
import AdvertisingAccountSideBar from "@/components/AdvertisingAccountSideBar";
import { country_codes } from "@/utils/countries";
export default {
  name: "AmazonSellerCentralPage",
  emits: ["update-total"],
  components: {
    ConnectionPageTitle,
    AppCardConnections,
    AppButton,
    AppIcon,
    AppModal,
    CustomSideBarAppModal,
    CreateAccountSideBar,
    AdvertisingAccountSideBar
  },
  data() {
    return {
      dataLoading: true,
      removeLoading: false,
      searchPlaceHolder: "Search",
      accountsList: [],
      sideBarAccount: {},
      confirmRemoveModal: false,
      removeId: null,
      createAccountSideBar: false,
      advertisingAccountSideBar: false,
      regions: [{
        text: "Choose a region to connect",
        value: ""
      }, {
        text: "North America",
        value: "North America"
      }, {
        text: "Europe & Middle East",
        value: "Europe & Middle East"
      }, {
        text: "Far East",
        value: "Far East"
      }],
      countries: {
        "": [{
          text: "Choose a country",
          value: ""
        }],
        "North America": [{
          text: "Brazil",
          value: "Brazil"
        }, {
          text: "US",
          value: "US"
        }, {
          text: "Mexico",
          value: "Mexico"
        }, {
          text: "Canada",
          value: "Canada"
        }],
        "Europe & Middle East": [{
          text: "Spain",
          value: "Spain"
        }, {
          text: "Belgium",
          value: "Belgium"
        }, {
          text: "UK",
          value: "UK"
        }, {
          text: "France",
          value: "France"
        }, {
          text: "Netherlands",
          value: "Netherlands"
        }, {
          text: "Germany",
          value: "Germany"
        }, {
          text: "Italy",
          value: "Italy"
        }, {
          text: "Sweden",
          value: "Sweden"
        }, {
          text: "Egypt",
          value: "Egypt"
        }, {
          text: "Poland",
          value: "Poland"
        }, {
          text: "Turkey",
          value: "Turkey"
        }, {
          text: "Saudi Arabia",
          value: "Saudi Arabia"
        }, {
          text: "U.A.E",
          value: "U.A.E."
        }, {
          text: "India",
          value: "India"
        }],
        "Far East": [{
          text: "Singapore",
          value: "Singapore"
        }, {
          text: "Australia",
          value: "Australia"
        }, {
          text: "Japan",
          value: "Japan"
        }]
      },
      account_provider_mapping: {
        "/amz-seller-central": "Amazon Seller Central",
        "/amz-vendor-central": "Amazon Vendor Central"
      },
      accountTypes: {
        "Amazon Seller Central": "seller",
        "Amazon Vendor Central": "vendor"
      }
    };
  },
  props: {
    provider: {
      type: String,
      default: ""
    },
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 20
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      isOmnyAdmin: state => state.auth.isOmnyAdmin,
      isOmnyUser: state => state.auth.isOmnyUser,
      isClientAdmin: state => state.auth.isClientAdmin,
      isClientUser: state => state.auth.isClientUser,
      isOmnyWorkspace: state => state.workspace.isOmnyWorkspace,
      current_workspace: state => state.workspace.current_workspace
    }),
    ...mapGetters(["accountByProviderGet", "workspaceNameById", "accountAdvertisingBySellerIdGet"]),
    canAddAccount() {
      return (this.isOmnyAdmin || this.isClientAdmin || this.isOmnyUser || this.isClientUser) && !this.isOmnyWorkspace;
    },
    accounts() {
      let sellerAccounts = this.accountByProviderGet(this.provider);
      this.accountsList = [];
      const makeProfile = value => {
        const countryCode = value.countryCode.toUpperCase();
        return {
          country: countryCode,
          flag: `https://flagsapi.com/${countryCode === "UK" ? "GB" : countryCode}/flat/16.png`,
          profileId: value.profileId.toString()
        };
      };
      sellerAccounts.forEach(account => {
        let adsAccounts = this.accountAdvertisingBySellerIdGet(account.account_id);
        let profiles = [];
        adsAccounts.forEach(ads_account => {
          profiles.push(makeProfile(ads_account));
        });
        account.profiles = profiles;
        this.accountsList.push(account);
      });
      const startIndex = (this.page - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.accountsList.slice(startIndex, endIndex);
    }
  },
  async created() {
    //this.provider = this.account_provider_mapping[this.$route.matched[1].path];
    let currentBreadCrumbs = this.$store.getters.getCurrentBreadCrumbs();
    currentBreadCrumbs[currentBreadCrumbs.length - 1].text = this.provider;
    this.$store.dispatch("setBreadcrumbs", currentBreadCrumbs);
  },
  async mounted() {
    //this.provider = this.account_provider_mapping[this.$route.matched[1].path];
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      await this.refreshAccountList();
      await this.workspaceList();
      this.dataLoading = false;
      window.$bus.on("refreshAccountList", this.refreshAccountList);
      //console.log("Seller Central Page Mounted");
      this.updateTotalData();
    }
  },
  beforeUnmount() {
    window.$bus.off("refreshAccountList", this.refreshAccountList);
  },
  methods: {
    ...mapActions(["accountList", "accountAuthorize", "accountRemove", "authStatus", "workspaceList", "setBreadcrumbs"]),
    onRemoveConfirm(account_id) {
      this.removeId = account_id;
      this.confirmRemoveModal = true;
    },
    workspaceName(workspace_id) {
      let found_workspace = this.workspaceNameById(workspace_id);
      return found_workspace ? found_workspace : "";
    },
    async onRemove() {
      this.confirmRemoveModal = false;
      this.removeLoading = true;
      let seller_list = [];
      seller_list.push({
        account_id: this.removeId
      });
      let advertisingAccounts = this.accountAdvertisingBySellerIdGet(this.removeId);
      advertisingAccounts.forEach(account => {
        seller_list.push({
          account_id: account.account_id
        });
      });
      await this.accountRemove({
        seller_list: seller_list,
        workspace_id: this.current_workspace.workspace_id
      });
      await this.refreshAccountList();
      this.removeLoading = false;
      this.removeId = null;
    },
    async refreshAccountList() {
      let data = {};
      await this.accountList(data);
    },
    updateTotalData() {
      this.$emit('update-total', {
        totalPages: this.accountByProviderGet(this.provider).length
      });
    },
    async onConnect(account) {
      const data = {
        region: account.region,
        account_provider: "Amazon Advertising",
        workspace_id: account.workspace_id,
        amz_sp_api_seller_id: account.selling_partner_id,
        country: country_codes[account.country],
        account_type: this.accountTypes[this.provider]
      };
      this.loading = true;
      await this.accountAuthorize(data);
      this.loading = false;
    },
    openSideBar(account) {
      this.sideBarAccount = {
        "account": account,
        "type": this.accountTypes[this.provider]
      };
      this.advertisingAccountSideBar = true;
    },
    async refreshAdvertisingAccountSideBar(account_object) {
      //console.log('Refresh account called');
      await this.refreshAccountList();
      const refresh_account = this.accountsList.find(account => account.account_id === account_object.account_id);
      this.advertisingAccountSideBar = false;
      this.sideBarAccount = {
        "account": refresh_account,
        "type": account_object.type
      };
      this.advertisingAccountSideBar = true;
    }
  }
};