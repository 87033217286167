export default {
  name: 'ProductScoreOk',
  props: {
    width: {
      type: Number,
      default: 12
    },
    height: {
      type: Number,
      default: 12
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
};