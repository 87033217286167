import { vModelText as _vModelText, withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    ref: "bid_setting_input",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.v$.value.$model = $event),
    onBlur: _cache[1] || (_cache[1] = (...args) => $options.onBlur && $options.onBlur(...args)),
    onKeyup: _cache[2] || (_cache[2] = _withKeys((...args) => $options.onBlur && $options.onBlur(...args), ["enter"])),
    inputmode: "numeric",
    type: "text",
    onInput: _cache[3] || (_cache[3] = (...args) => $options.onInput && $options.onInput(...args)),
    onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"])),
    class: _normalizeClass({
      'isUnvalid': !$props.isValid
    })
  }, null, 34 /* CLASS, NEED_HYDRATION */)), [[_vModelText, $setup.v$.value.$model]]);
}