import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 20 20`,
    fill: "none"
  }, [_createElementVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M9.62988 1.95428C9.62963 1.9544 9.62938 1.95453 9.62913 1.95465L3.53419 \n        5.00213L9.99987 8.23497L16.4656 5.00208L10.3708 1.95465C10.3705 1.95453 10.3703 1.9544 10.37 1.95428C10.1369 1.8387 9.86306 \n        1.8387 9.62988 1.95428ZM17.5 6.34832L10.8332 9.68169L10.8332 17.8225L17.0356 14.7214L17.0375 14.7204C17.3207 14.5797 17.4998 \n        14.2908 17.5 13.9746V6.34832ZM9.16658 17.8267L9.16654 9.68169L2.49996 6.3484V13.9725C2.49776 14.2891 2.67507 14.5795 2.95759 \n        14.7222C2.95736 14.7221 2.95782 14.7223 2.95759 14.7222L9.16658 17.8267ZM2.21983 3.79591C1.37082 4.21825 0.833796 5.08461 0.833293 \n        6.03294L0.833293 13.9641C0.827891 14.9133 1.3605 15.7837 2.20831 16.2109L2.21061 16.2121L8.87704 19.5453C9.58125 19.8977 10.4103 \n        19.8977 11.1145 19.5453L17.7791 16.213C17.7794 16.2128 17.7797 16.2127 17.78 16.2126C18.629 15.7903 19.1661 14.9239 19.1666 \n        13.9755V6.03338C19.1661 5.08512 18.6292 4.21838 17.7803 3.79601C17.7799 3.79582 17.7795 3.79562 17.7791 3.79543L11.1143 0.463019L11.1125 \n        0.462101C10.4117 0.11387 9.58825 0.11387 8.88746 0.462101L8.88562 0.463021L2.22079 3.79543C2.22047 3.79559 2.22015 3.79575 2.21983 3.79591Z",
    fill: $props.color
  }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1);
}