import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b2cba94"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "range-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_input_range_item = _resolveComponent("app-input-range-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.items, (item, index) => {
    return _openBlock(), _createBlock(_component_app_input_range_item, {
      modelValue: item,
      key: index,
      label: $props.label && index == 0 ? $props.label : null,
      type: $props.type,
      placeholder: $props.placeholder,
      required: $props.required,
      readonly: $props.readonly,
      validateRule: $props.validateRule,
      "no-remove": $options.items.length <= 1,
      onRemove: $event => $options.onRemove(index),
      onAdd: $event => $options.onAdd(index),
      onSubmit: $options.onSubmit,
      "onUpdate:modelValue": $event => $options.onInput($event, index)
    }, null, 8 /* PROPS */, ["modelValue", "label", "type", "placeholder", "required", "readonly", "validateRule", "no-remove", "onRemove", "onAdd", "onSubmit", "onUpdate:modelValue"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}