import CenterWrapper from "../shared/CenterWrapper.vue";
export default {
  name: "StrokedLinkIcon",
  components: {
    CenterWrapper
  },
  props: {
    width: {
      type: String,
      default: "18"
    },
    height: {
      type: String,
      default: "18"
    },
    color: {
      type: String
    }
  }
};