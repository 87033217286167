import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_value_element = _resolveComponent("value-element");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    class: "percentage-value",
    style: _normalizeStyle({
      ...$props.styles
    })
  }, {
    default: _withCtx(() => [_createVNode(_component_value_element, {
      style: _normalizeStyle($options.getComputedStyle),
      value: $options.formattedValue
    }, null, 8 /* PROPS */, ["style", "value"]), _ctx.isCorrectValue && $props.showSymbol ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      style: _normalizeStyle({
        ...$options.getComputedStyle,
        width: 'auto'
      })
    }, _toDisplayString($options.symbol), 5 /* TEXT, STYLE */)) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style"]);
}