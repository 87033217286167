import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
  style: {
    marginLeft: '16px'
  }
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_product_table_group_by = _resolveComponent("product-table-group-by");
  const _component_date_picker_popup = _resolveComponent("date-picker-popup");
  const _component_app_icon_group = _resolveComponent("app-icon-group");
  const _component_wrap_with_arrow = _resolveComponent("wrap-with-arrow");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_product_table_total = _resolveComponent("product-table-total");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_overlap_bar_value = _resolveComponent("overlap-bar-value");
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_value_element = _resolveComponent("value-element");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_marketplace_value = _resolveComponent("marketplace-value");
  const _component_product_value = _resolveComponent("product-value");
  const _component_product_group_value = _resolveComponent("product-group-value");
  const _component_campaign_value = _resolveComponent("campaign-value");
  const _component_targeting_value = _resolveComponent("targeting-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  const _component_b_collapse = _resolveComponent("b-collapse");
  const _component_app_pagination = _resolveComponent("app-pagination");
  const _component_compound_table = _resolveComponent("compound-table");
  return _openBlock(), _createBlock(_component_compound_table, {
    onClick: $options.listenForTaps,
    class: _normalizeClass([{
      ['intermediate']: !$data.fullSize && $data.visible
    }]),
    innerClass: "product-compound",
    expanded: $data.visible,
    topBgColor: "#F4F9FF",
    fullSize: $data.fullSize
  }, _createSlots({
    header: _withCtx(() => [_createTextVNode("Breakdown by")]),
    "header-sub": _withCtx(() => [_createVNode(_component_product_table_group_by, {
      ref: "productTableGroupBy",
      onRefresh: $options.handleRefresh,
      onClicked: $options.handleClicked,
      tabBusy: $props.loading
    }, null, 8 /* PROPS */, ["onRefresh", "onClicked", "tabBusy"])]),
    "group-by-title": _withCtx(() => [_createTextVNode(_toDisplayString(null))]),
    "group-by": _withCtx(() => [_createVNode(_component_date_picker_popup, {
      datePickerDate: $props.datePickerDate,
      loading: $props.loading,
      onSubmit: $options.handleDatePickerSubmit
    }, null, 8 /* PROPS */, ["datePickerDate", "loading", "onSubmit"])]),
    actions: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_app_icon_group, {
      icons: [{
        icon: 'download-csv',
        size: 20,
        help: 'Download CSV',
        click: $options.downloadPPCCSV
      }, {
        icon: `${$data.fullSize ? 'midscreen' : 'fullscreen'}`,
        click: $options.toggleFullSize,
        size: 20
      }, {
        icon: `simple-arrow-${$data.visible ? 'down' : 'up'}`,
        size: 20,
        click: $options.toggleCollapse
      }],
      gap: 4,
      color: "#757575"
    }, null, 8 /* PROPS */, ["icons"])])]),
    table: _withCtx(() => [_createVNode(_component_b_collapse, {
      skipAnimation: true,
      modelValue: $data.visible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.visible = $event),
      id: "product-table-collapse",
      ref: "productTableCollapse"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_table_lite, {
        class: _normalizeClass(['lite-table', 'product-table', {
          ['full-size']: $data.fullSize
        }, {
          ['intermediate']: !$data.fullSize && $data.visible
        }, {
          ['bottom-row-visible']: $data.showBottomRow
        }]),
        items: $options.items,
        style: _normalizeStyle(_ctx.cssProps),
        fields: $options.fields,
        responsive: "",
        fixed: "",
        "sticky-header": true,
        bordered: "",
        onRowClicked: $options.handleRowClick,
        onHeadClicked: $options.handleHeadClick,
        tbodyTrClass: item => _ctx.handleRowClass(item, $options.items),
        ref: "productTable"
      }, _createSlots({
        "head()": _withCtx(col => [_createVNode(_component_flex_row, {
          styles: {
            justifyContent: col.field.justify,
            ...col.field.thStyle
          },
          id: `product-table-${col?.column}-${_ctx.groupBy}`
        }, {
          default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
            triggers: "hover",
            target: `product-table-${col?.column}-${_ctx.groupBy}`,
            style: {
              width: '100%'
            }
          }, {
            title: _withCtx(() => [_createElementVNode("div", {
              innerHTML: col?.label
            }, null, 8 /* PROPS */, _hoisted_2)]),
            content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
            default: _withCtx(() => [['asc', 'desc'].includes(this.sort[col?.column]) ? (_openBlock(), _createBlock(_component_wrap_with_arrow, {
              key: 0,
              direction: this.sort[col?.column]
            }, {
              default: _withCtx(() => [_createElementVNode("div", {
                class: "title",
                innerHTML: col?.label
              }, null, 8 /* PROPS */, _hoisted_3)]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["direction"])) : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "title",
              innerHTML: col?.label
            }, null, 8 /* PROPS */, _hoisted_4))]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles", "id"])]),
        "custom-foot": _withCtx(() => [_createElementVNode("tr", null, [_createVNode(_component_product_table_total, {
          fields: $options.fields,
          total: $options.total,
          currencyConfig: _ctx.currencyConfig,
          loading: $props.loading
        }, null, 8 /* PROPS */, ["fields", "total", "currencyConfig", "loading"])])]),
        "cell(spend)": _withCtx(row => [_createVNode(_component_bar_with_value, {
          value: row.item?.spend,
          color: "#5A52AE",
          min: $options.minMax?.spend?.min,
          max: $options.minMax?.spend?.max,
          loading: $props.loading,
          maxCharCount: $options.maxCharCount?.spend
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.spend,
            limit: 10_000,
            config: _ctx.currencyConfig,
            charCount: $options.maxCharCount?.spend
          }, null, 8 /* PROPS */, ["value", "config", "charCount"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "maxCharCount"])]),
        "cell(sponsored_sales)": _withCtx(row => [_createVNode(_component_bar_with_value, {
          value: row.item?.sponsored_sales,
          color: "#8D85E5",
          min: $options.minMax?.sponsored_sales?.min,
          max: $options.minMax?.sponsored_sales?.max,
          loading: $props.loading,
          maxCharCount: $options.maxCharCount?.sponsored_sales
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.sponsored_sales,
            limit: 10_000,
            config: _ctx.currencyConfig,
            charCount: $options.maxCharCount?.sponsored_sales
          }, null, 8 /* PROPS */, ["value", "config", "charCount"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "maxCharCount"])]),
        "cell(TACOS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
          value: row.item?.TACOS,
          background: "#EBD4F7",
          borderColor: "#CD93EB",
          min: $options.minMax?.TACOS?.min,
          max: $options.minMax?.TACOS?.max,
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.TACOS
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        "cell(CVR)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getColorsBasedOnRange(row.item?.CVR, $data.CVR_HEATMAP_COLORS),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.CVR,
            limit: 0.1
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(ROAS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
          value: row.item?.ROAS,
          background: "#E6E9FE",
          borderColor: "#D8DCFD",
          min: $options.minMax?.ROAS?.min,
          max: $options.minMax?.ROAS?.max,
          loading: $props.loading,
          align: "left"
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            styles: {
              padding: 0
            },
            value: row.item?.ROAS,
            precision: 1
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        "cell(units_sold_clicks_14_d)": _withCtx(row => [_createVNode(_component_number_value, {
          value: row.item?.units_sold_clicks_14_d,
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "loading"])]),
        "cell(clicks)": _withCtx(row => [_createVNode(_component_number_value, {
          value: row.item?.clicks,
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "loading"])]),
        "cell(CPC)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getColorForDecile(row.item?.CPC, $options.minMax?.CPC?.min, $options.minMax?.CPC?.max, $data.CPC_DECILE_COLORS),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.CPC,
            precision: 2,
            config: _ctx.currencyConfig
          }, null, 8 /* PROPS */, ["value", "config"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(sponsored_asin)": _withCtx(row => [_createVNode(_component_bar_with_value, {
          value: row.item?.sponsored_asin,
          color: "#4C8EFF",
          min: $options.minMax?.sponsored_asin?.min,
          max: $options.minMax?.sponsored_asin?.max,
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_value_element, {
            value: row.item?.sponsored_asin,
            style: _normalizeStyle({
              textAlign: 'right',
              width: `${$options.maxCharCount?.sponsored_asin}ch`
            })
          }, null, 8 /* PROPS */, ["value", "style"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        "cell(seller_name)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading,
          align: "left"
        }, {
          default: _withCtx(() => [_createVNode(_component_value_element, {
            value: row.item?.seller_name
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(sales_channel)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading,
          align: "left"
        }, {
          default: _withCtx(() => [_createVNode(_component_marketplace_value, {
            marketplace: row.item?.sales_channel,
            flag: row.item?.flag
          }, null, 8 /* PROPS */, ["marketplace", "flag"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(campaign_budget)": _withCtx(row => [_createVNode(_component_hug_value, {
          align: "right",
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.campaign_budget,
            config: _ctx.currencyConfig
          }, null, 8 /* PROPS */, ["value", "config"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(campaign_group_budget)": _withCtx(row => [_createVNode(_component_hug_value, {
          align: "right",
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.campaign_group_budget,
            config: _ctx.currencyConfig
          }, null, 8 /* PROPS */, ["value", "config"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(keyword_bid)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getColorsBasedOnRange(row.item?.keyword_bid, $data.KEYWORD_BID_HEATMAP_COLORS),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.keyword_bid,
            precision: 2,
            config: _ctx.currencyConfig
          }, null, 8 /* PROPS */, ["value", "config"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(product_name)": _withCtx(row => [_createVNode(_component_product_value, {
          value: {
            name: row.item?.product_name,
            image: row.item?.main_image_link,
            asin: row.item?.asin,
            sku: row.item?.sku
          },
          loading: $props.loading,
          sponsored: row.item?.sku === 'SPONSORED_BRANDS'
        }, null, 8 /* PROPS */, ["value", "loading", "sponsored"])]),
        "cell(campaign_group_name)": _withCtx(row => [_createVNode(_component_product_group_value, {
          value: {
            name: row.item?.campaign_group_name,
            image: row.item?.campaign_group_image,
            campaignsCount: row.item?.campaign_activated_products_count,
            campaignIdCount: row.item?.campaign_id_count,
            color: $options.getCampaignsStateColor(row.item?.advertising_state),
            flag: row.item?.flag
          },
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "loading"])]),
        "cell(campaign_name)": _withCtx(row => [_createVNode(_component_campaign_value, {
          value: {
            name: row.item?.campaign_name,
            type: row.item?.ad_product,
            state: row.item?.campaign_state,
            flag: row.item?.flag
          },
          width: "308px",
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "loading"])]),
        "cell(targeting)": _withCtx(row => [_createVNode(_component_targeting_value, {
          value: {
            campaignName: row.item?.campaign_name,
            targeting: row.item?.targeting,
            type: row.item?.match_type ?? '',
            image: row.item?.targeting_product_image,
            link: row.item?.targeting_product_link
          },
          width: "308px",
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "loading"])]),
        _: 2 /* DYNAMIC */
      }, [$data.showBottomRow ? {
        name: "bottom-row",
        fn: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.fields, (_, index) => {
          return _openBlock(), _createElementBlock("td", {
            key: index,
            class: "bottom-row",
            style: _normalizeStyle({
              height: $data.bottomRowHeight
            })
          }, null, 4 /* STYLE */);
        }), 128 /* KEYED_FRAGMENT */))]),
        key: "0"
      } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "items", "style", "fields", "onRowClicked", "onHeadClicked", "tbodyTrClass"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])]),
    _: 2 /* DYNAMIC */
  }, [$data.fullSize ? {
    name: "pagination",
    fn: _withCtx(() => [_createVNode(_component_app_pagination, {
      page: $options.page,
      "onUpdate:page": [_cache[1] || (_cache[1] = $event => $options.page = $event), _cache[3] || (_cache[3] = $event => _ctx.$emit('refresh'))],
      perPage: $options.perPage,
      "onUpdate:perPage": [_cache[2] || (_cache[2] = $event => $options.perPage = $event), _cache[4] || (_cache[4] = $event => {
        this.totalPages = 0;
        _ctx.$emit('refresh');
      })],
      total: $options.totalPages
    }, null, 8 /* PROPS */, ["page", "perPage", "total"])]),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "class", "expanded", "fullSize"]);
}