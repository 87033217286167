import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_search_element = _resolveComponent("search-element");
  const _component_portfolio_filter_item = _resolveComponent("portfolio-filter-item");
  const _component_selected_section = _resolveComponent("selected-section");
  const _component_ListSection = _resolveComponent("ListSection");
  const _component_wrap_with_dropdown_body = _resolveComponent("wrap-with-dropdown-body");
  return _openBlock(), _createBlock(_component_wrap_with_dropdown_body, {
    onHidden: _cache[8] || (_cache[8] = $event => _ctx.$emit('hidden'))
  }, {
    searcher: _withCtx(() => [_createVNode(_component_search_element, {
      placeholder: "Search portfolios...",
      onSearch: _cache[0] || (_cache[0] = val => _ctx.$emit('search', val)),
      value: $data.searchValue
    }, null, 8 /* PROPS */, ["value"])]),
    selected: _withCtx(() => [_createVNode(_component_selected_section, {
      title: "Portfolios",
      count: $props.selected.length,
      isModified: $props.isModified,
      heightFromTop: "118px",
      onClear: _cache[2] || (_cache[2] = $event => {
        _ctx.$emit('clear');
      }),
      onApply: _cache[3] || (_cache[3] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _cache[4] || (_cache[4] = $event => _ctx.$emit('closeDropdown'))
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selected, ({
        portfolio_name,
        portfolio_id,
        portfolio_id_list
      }) => {
        return _openBlock(), _createBlock(_component_portfolio_filter_item, {
          key: portfolio_name,
          portfolio_name: portfolio_name,
          portfolio_id_list: portfolio_id_list,
          portfolio_id: portfolio_id,
          selected: "",
          onDeselect: _cache[1] || (_cache[1] = val => _ctx.$emit('deselect', val))
        }, null, 8 /* PROPS */, ["portfolio_name", "portfolio_id_list", "portfolio_id"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count", "isModified"])]),
    list: _withCtx(() => [_createVNode(_component_ListSection, {
      title: "Portfolios",
      count: $props.list.length,
      searching: $props.searching,
      onSelectAll: _cache[7] || (_cache[7] = $event => _ctx.$emit('selectAll')),
      heightFromTop: "118px"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, ({
        portfolio_name,
        portfolio_id_list,
        portfolio_id
      }) => {
        return _openBlock(), _createBlock(_component_portfolio_filter_item, {
          key: portfolio_name,
          portfolio_name: portfolio_name,
          portfolio_id_list: portfolio_id_list,
          portfolio_id: portfolio_id,
          onSelect: _cache[5] || (_cache[5] = val => _ctx.$emit('select', val)),
          onSelectOnly: _cache[6] || (_cache[6] = val => _ctx.$emit('selectOnly', val))
        }, null, 8 /* PROPS */, ["portfolio_name", "portfolio_id_list", "portfolio_id"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count", "searching"])]),
    _: 1 /* STABLE */
  });
}