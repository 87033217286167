import AppRating from "@/components/AppRating.vue";
import HugValue from "@/components/tableElements/HugValue.vue";
export default {
  name: 'RatingValue',
  components: {
    AppRating,
    HugValue
  },
  props: {
    value: {
      type: [Number, null],
      required: false,
      default: 0
    },
    size: {
      type: [Number, null],
      required: false,
      default: 12
    },
    color: {
      type: [String, null],
      required: false,
      default: 'black'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};