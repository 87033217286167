export default {
  name: "AppButtonRadioGroup",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [Number, String],
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    selected: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
};