import Filter from './filter';
import { computeDetailsForKpis, computeCurrencyMaxCharCount, computeNumberMaxCharCount, computePercentageMaxCharCount } from "@/utils/transformers";
import { computeMinMax, transformWithCountries } from "@/utils";
import { request } from "@/store";
import moment from "moment";
import { FLOATING_COMPOUND_TABLE_STATES, GROUP_BY_TAB_ELEMENTS, APP_PERIOD_DROPDOWN_ELEMENTS } from "@/utils/constants";
import { DATE_PICKER_RANGE_TITLES } from '../utils/datepicker';
const DEFAULT_EMPTY_ROW_SIZE = 20;
const dashboard_v2 = {
  state: {
    sellers: new Filter([], []),
    marketplaces: new Filter([], [], "sales_channel"),
    products: new Filter([], [], "products"),
    kpis: {
      data: {
        performance: {
          prev_period: [],
          same_period_ly: []
        },
        listing: {
          prev_period: [],
          same_period_ly: []
        },
        advertising: {
          prev_period: [],
          same_period_ly: []
        },
        profit: {
          prev_period: [],
          same_period_ly: []
        }
      },
      CONSIDERED_KPIS: {
        performance: {
          // TODO: Refactor this into constants to reuse everywhere
          prev_period: [{
            key: "revenue_excl_VAT",
            name: "Revenue Excl. Tax (WTD)",
            subtitle: {
              name: "units sold",
              key: "total_quantity"
            },
            split_subtitle: [{
              name: 'D-1',
              key: 'revenue_yesterday'
            }, {
              name: 'MTD',
              key: 'revenue_mtd'
            }, {
              name: 'YTD',
              key: 'revenue_ytd'
            }]
          }, {
            key: "days_inventory_current_period",
            name: "Days of inventory",
            subtitle: {
              name: "revenue lost",
              key: "lost_revenue_excl_VAT"
            }
          }, {
            key: "organic_CVR",
            name: "Organic CVR",
            subtitle: {
              name: "buybox",
              key: "traffic_by_asin_buy_box_percentage"
            }
          }, {
            key: "TACOS",
            name: "TACOS",
            subtitle: {
              name: "share of PPC",
              key: "share_sponsored_sales"
            }
          }, {
            key: "revenue_mtd",
            name: "Revenue Excl. Tax (MTD)",
            split_subtitle: [{
              name: 'WTD',
              key: 'revenue_wtd'
            }, {
              name: 'D-1',
              key: 'revenue_yesterday'
            }, {
              name: 'YTD',
              key: 'revenue_ytd'
            }]
          }, {
            key: "revenue_yesterday",
            name: "Revenue Excl. Tax (D-1)",
            split_subtitle: [{
              name: 'WTD',
              key: 'revenue_wtd'
            }, {
              name: 'MTD',
              key: 'revenue_mtd'
            }, {
              name: 'YTD',
              key: 'revenue_ytd'
            }]
          }, {
            key: "revenue_ytd",
            name: "Revenue Excl. Tax (YTD)",
            split_subtitle: [{
              name: 'WTD',
              key: 'revenue_wtd'
            }, {
              name: 'MTD',
              key: 'revenue_mtd'
            }, {
              name: 'D-1',
              key: 'revenue_yesterday'
            }]
          }],
          same_period_ly: [{
            key: "last_year_revenue_excl_VAT",
            name: "Revenue Excl. Tax (WTD)",
            subtitle: {
              name: "units sold",
              key: "last_year_total_quantity"
            },
            split_subtitle: [{
              name: 'D-1',
              key: 'last_year_revenue_yesterday'
            }, {
              name: 'MTD',
              key: 'last_year_revenue_mtd'
            }, {
              name: 'YTD',
              key: 'last_year_revenue_ytd'
            }]
          }, {
            key: "last_year_days_inventory_current_period",
            name: "Days of inventory",
            subtitle: {
              name: "revenue lost",
              key: "last_year_lost_revenue_excl_VAT"
            }
          }, {
            key: "last_year_organic_CVR",
            name: "Organic CVR",
            subtitle: {
              name: "buybox",
              key: "last_year_traffic_by_asin_buy_box_percentage"
            }
          }, {
            key: "last_year_TACOS",
            name: "TACOS",
            subtitle: {
              name: "share of PPC",
              key: "last_year_share_sponsored_sales"
            }
          }, {
            key: "revenue_mtd",
            name: "Revenue Excl. Tax (MTD)",
            split_subtitle: [{
              name: 'WTD',
              key: 'last_year_revenue_wtd'
            }, {
              name: 'D-1',
              key: 'last_year_revenue_yesterday'
            }, {
              name: 'YTD',
              key: 'last_year_revenue_ytd'
            }]
          }, {
            key: "revenue_yesterday",
            name: "Revenue Excl. Tax (D-1)",
            split_subtitle: [{
              name: 'WTD',
              key: 'last_year_revenue_wtd'
            }, {
              name: 'MTD',
              key: 'last_year_revenue_mtd'
            }, {
              name: 'YTD',
              key: 'last_year_revenue_ytd'
            }]
          }]
        },
        listing: {
          // TODO: Refactor this into constants to reuse everywhere
          prev_period: [{
            key: "page_views",
            name: "Page Views"
          }, {
            key: "organic_CVR",
            name: "Organic Conversion"
          }, {
            key: "traffic_by_asin_buy_box_percentage",
            name: "% of buybox"
          }, {
            key: "active_asin_count",
            name: "Active Asin"
          }],
          same_period_ly: [{
            key: "last_year_page_views",
            name: "Page Views"
          }, {
            key: "last_year_organic_CVR",
            name: "Organic Conversion"
          }, {
            key: "last_year_traffic_by_asin_buy_box_percentage",
            name: "% of buybox"
          }, {
            key: "last_year_active_asin_count",
            name: "Active Asin"
          }]
        },
        advertising: {
          prev_period: [{
            key: "sponsored_sales",
            name: "sponsored sales",
            subtitle: {
              name: "units sold",
              key: "units_sold_clicks_14_d"
            }
          }, {
            key: "spend",
            name: "spend",
            subtitle: {
              name: "clicks",
              key: "clicks"
            }
          }, {
            key: "TACOS",
            name: "TACOS",
            subtitle: {
              name: "ROAS",
              key: "ROAS"
            }
          }, {
            key: "CVR",
            name: "CVR",
            subtitle: {
              name: "CPC",
              key: "CPC"
            }
          }],
          same_period_ly: [{
            key: "last_year_sponsored_sales",
            name: "sponsored sales",
            subtitle: {
              name: "units sold",
              key: "last_year_units_sold_clicks_14_d"
            }
          }, {
            key: "last_year_spend",
            name: "spend",
            subtitle: {
              name: "clicks",
              key: "last_year_clicks"
            }
          }, {
            key: "last_year_TACOS",
            name: "TACOS",
            subtitle: {
              name: "ROAS",
              key: "last_year_ROAS"
            }
          }, {
            key: "last_year_CVR",
            name: "CVR",
            subtitle: {
              name: "CPC",
              key: "last_year_CPC"
            }
          }]
        }
      }
    },
    period: APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value,
    periodSelectorOptions: [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY, APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY, APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY, APP_PERIOD_DROPDOWN_ELEMENTS.YEARLY],
    periodCategory: "prev_period",
    product_table: {
      state: FLOATING_COMPOUND_TABLE_STATES.CLOSED,
      group_by: 0,
      group_by_text: "",
      group_by_elements: [],
      performance: {
        period: null,
        data: {
          seller: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedSellers"
          },
          marketplace: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedMarketplace"
          },
          parent_asin: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc",
              revenue_excl_VAT_previous_period: "desc",
              days_inventory: "asc"
            },
            fetch: "dashboardV2FetchReportGroupedParentAsin"
          },
          asin: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedAsin"
          },
          product: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedProduct"
          }
        }
      },
      listing: {
        period: null,
        data: {
          seller: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedSellers"
          },
          marketplace: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedMarketplace"
          },
          parent_asin: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedParentAsin"
          },
          asin: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedAsin"
          },
          product: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedProduct"
          }
        }
      },
      advertising: {
        period: null,
        data: {
          seller: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedSellers"
          },
          marketplace: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedMarketplace"
          },
          parent_asin: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedParentAsin"
          },
          asin: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedAsin"
          },
          product: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              revenue_excl_VAT: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedProduct"
          }
        }
      },
      profit: {
        period: null,
        data: {
          seller: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              pl_net_revenue: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedSellers"
          },
          marketplace: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              pl_net_revenue: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedMarketplace"
          },
          parent_asin: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              pl_net_revenue: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedParentAsin"
          },
          asin: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            minMax: {},
            maxCharCount: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              pl_net_revenue: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedAsin"
          },
          product: {
            items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
            total: [],
            maxCharCount: {},
            minMax: {},
            page: 1,
            perPage: 20,
            totalPages: 0,
            sort: {
              pl_net_revenue: "desc"
            },
            fetch: "dashboardV2FetchReportGroupedProduct"
          }
        }
      }
    },
    currentTab: 0,
    currentTabValue: "performance",
    options: [{
      text: "Daily",
      value: "daily"
    }, {
      text: "Weekly",
      value: "weekly"
    }, {
      text: "Monthly",
      value: "monthly"
    }],
    tabs: [{
      text: "Performance",
      value: "performance",
      activeBg: "#F3EEFF",
      activeColor: "#423994",
      feature: 'default'
    }, {
      text: "Advertising",
      value: "advertising",
      activeBg: "#E3FCFC",
      activeColor: "#068481",
      feature: 'default'
    }, {
      text: "Listing",
      value: "listing",
      activeBg: "#D0E2FF",
      activeColor: "#1755AB",
      feature: 'default'
    }, {
      text: "Profit",
      value: "profit",
      activeBg: "#FFF0F7",
      activeColor: "#A71B58",
      feature: 'default'
    }],
    periodOptions: [{
      html: `Prev. period`,
      value: "prev_period"
    }, {
      html: `Same period LY`,
      value: "same_period_ly"
    }],
    currentPeriod: {
      [APP_PERIOD_DROPDOWN_ELEMENTS.WEEKLY.value]: {
        title: {
          prev_period: "Current week vs previous week",
          same_period_ly: "Current week vs same period last year"
        },
        format: "MMM D, YYYY",
        date: moment().startOf("isoWeek"),
        groupBy: DATE_PICKER_RANGE_TITLES.CURRENT_WEEK,
        groupByCompare: {
          title: 'Preceding Period',
          subtitle: '(match to date)'
        }
      },
      [APP_PERIOD_DROPDOWN_ELEMENTS.DAILY.value]: {
        title: {
          prev_period: "Yesterday",
          same_period_ly: "Yesterday vs same period last year"
        },
        format: "MMM DD, YYYY",
        date: moment().subtract(1, "days"),
        groupBy: DATE_PICKER_RANGE_TITLES.YESTERDAY,
        groupByCompare: {
          title: 'Preceding Period',
          subtitle: '(match to date)'
        }
      },
      [APP_PERIOD_DROPDOWN_ELEMENTS.MONTHLY.value]: {
        title: {
          prev_period: "Current month vs previous month",
          same_period_ly: "Current month vs same period last year"
        },
        format: "MMM YYYY",
        date: moment().startOf("month"),
        groupBy: DATE_PICKER_RANGE_TITLES.CURRENT_MONTH,
        groupByCompare: {
          title: 'Preceding Period',
          subtitle: '(match to date)'
        }
      },
      [APP_PERIOD_DROPDOWN_ELEMENTS.YEARLY.value]: {
        title: {
          prev_period: "Current year vs previous year",
          same_period_ly: "Current year vs last year"
        },
        format: "YYYY",
        date: moment().startOf("year"),
        groupBy: DATE_PICKER_RANGE_TITLES.CURRENT_YEAR,
        groupByCompare: {
          title: 'Preceding Period',
          subtitle: '(match to date)'
        }
      }
    },
    periodTable: {
      performance: {
        items: new Array(DEFAULT_EMPTY_ROW_SIZE).fill({}),
        maxCharCount: {},
        minMax: {}
      },
      advertising: {
        items: [],
        maxCharCount: {},
        minMax: {}
      },
      listing: {
        items: [],
        maxCharCount: {},
        minMax: {}
      },
      profit: {
        items: [],
        maxCharCount: {},
        minMax: {}
      },
      period_page: 1
    },
    currencyConfig: {
      full: false,
      currency: "EUR",
      precision: 0,
      showCurrencySign: true,
      digitSeparator: ".",
      limit: 10_000
    }
  },
  mutations: {
    dashboardV2SetKpisDataPrevPeriod(state, {
      payload,
      tab
    }) {
      state.kpis.data[tab].prev_period = payload;
    },
    dashboardV2SetKpisDataSamePeriodLY(state, {
      payload,
      tab
    }) {
      state.kpis.data[tab].same_period_ly = payload;
    },
    dashboardV2SetPeriod(state, payload) {
      state.period = payload;
    },
    dashboardV2SetPeriodCategory(state, payload) {
      state.periodCategory = payload;
    },
    dashboardV2SetCurrentTab(state, payload) {
      const {
        tabIndex,
        tabValue
      } = payload;
      state.currentTab = tabIndex;
      state.currentTabValue = tabValue;
    },
    dashboardV2SetPeriodTableItems(state, payload) {
      state.periodTable['performance'].items = payload;
      state.periodTable['listing'].items = payload;
      state.periodTable['advertising'].items = payload;
      state.periodTable['profit'].items = payload;
    },
    dashboardV2SetPeriodTableMinMax(state, payload) {
      state.periodTable['performance'].minMax = payload;
      state.periodTable['listing'].minMax = payload;
      state.periodTable['advertising'].minMax = payload;
      state.periodTable['profit'].minMax = payload;
    },
    dashboardV2SetProductTableMinMax(state, payload) {
      state.product_table['performance'].data[state.product_table.group_by_text].minMax = payload;
      state.product_table['listing'].data[state.product_table.group_by_text].minMax = payload;
      state.product_table['advertising'].data[state.product_table.group_by_text].minMax = payload;
      state.product_table['profit'].data[state.product_table.group_by_text].minMax = payload;
    },
    dashboardV2SetProductTableMarketplaceItems(state, payload) {
      state.product_table['performance'].data.marketplaces.items = payload;
      state.product_table['listing'].data.marketplaces.items = payload;
      state.product_table['advertising'].data.marketplaces.items = payload;
      state.product_table['profit'].data.marketplaces.items = payload;
    },
    dashboardV2SetProductTableProductItems(state, payload) {
      state.product_table['performance'].data.products.items = payload;
      state.product_table['listing'].data.products.items = payload;
      state.product_table['advertising'].data.products.items = payload;
      state.product_table['profit'].data.products.items = payload;
    },
    dashboardV2SetProductTableTotalPages(state, payload) {
      state.product_table['performance'].data[state.product_table.group_by_text].totalPages = payload;
      state.product_table['listing'].data[state.product_table.group_by_text].totalPages = payload;
      state.product_table['advertising'].data[state.product_table.group_by_text].totalPages = payload;
      state.product_table['profit'].data[state.product_table.group_by_text].totalPages = payload;
    },
    dashboardV2SetProductTablePage(state, payload) {
      state.product_table['performance'].data[state.product_table.group_by_text].page = payload;
      state.product_table['listing'].data[state.product_table.group_by_text].page = payload;
      state.product_table['advertising'].data[state.product_table.group_by_text].page = payload;
      state.product_table['profit'].data[state.product_table.group_by_text].page = payload;
    },
    dashboardV2SetProductTablePerPage(state, payload) {
      state.product_table['performance'].data[state.product_table.group_by_text].perPage = payload;
      state.product_table['listing'].data[state.product_table.group_by_text].perPage = payload;
      state.product_table['advertising'].data[state.product_table.group_by_text].perPage = payload;
      state.product_table['profit'].data[state.product_table.group_by_text].perPage = payload;
    },
    dashboardV2SetProductTableGroupBy(state, payload) {
      state.product_table.group_by = payload;
      state.product_table.group_by_text = state.product_table.group_by_elements[payload].key;
    },
    dashboardV2SetProductTablePeriod(state, payload) {
      state.product_table['performance'].period = payload;
      state.product_table['listing'].period = payload;
      state.product_table['advertising'].period = payload;
      state.product_table['profit'].period = payload;
    },
    dashboardV2SetProductTableItems(state, payload) {
      state.product_table['performance'].data[state.product_table.group_by_text].items = payload;
      state.product_table['listing'].data[state.product_table.group_by_text].items = payload;
      state.product_table['advertising'].data[state.product_table.group_by_text].items = payload;
      state.product_table['profit'].data[state.product_table.group_by_text].items = payload;
    },
    dashboardV2SetProductTableMaxCharCount(state, payload) {
      state.product_table['performance'].data[state.product_table.group_by_text].maxCharCount = payload;
      state.product_table['listing'].data[state.product_table.group_by_text].maxCharCount = payload;
      state.product_table['advertising'].data[state.product_table.group_by_text].maxCharCount = payload;
      state.product_table['profit'].data[state.product_table.group_by_text].maxCharCount = payload;
    },
    dashboardV2SetPeriodTableMaxCharCount(state, payload) {
      state.periodTable['performance'].maxCharCount = payload;
      state.periodTable['listing'].maxCharCount = payload;
      state.periodTable['advertising'].maxCharCount = payload;
      state.periodTable['profit'].maxCharCount = payload;
    },
    dashboardV2SetProductTableState(state, payload) {
      state.product_table.state = payload;
    },
    dashboardV2SetSellersTableTotal(state, payload) {
      state.product_table['performance'].data[state.product_table.group_by_text].total = payload;
      state.product_table['listing'].data[state.product_table.group_by_text].total = payload;
      state.product_table['advertising'].data[state.product_table.group_by_text].total = payload;
      state.product_table['profit'].data[state.product_table.group_by_text].total = payload;
    },
    dashboardV2SetProductTableGroupByElements(state, payload) {
      state.product_table.group_by_elements = payload;
    },
    dashboardV2SetProductTableSort(state, {
      sort,
      direction
    }) {
      state.product_table['performance'].data[state.product_table.group_by_text].sort = {
        [sort]: direction
      };
      state.product_table['listing'].data[state.product_table.group_by_text].sort = {
        [sort]: direction
      };
      state.product_table['advertising'].data[state.product_table.group_by_text].sort = {
        [sort]: direction
      };
      state.product_table['profit'].data[state.product_table.group_by_text].sort = {
        [sort]: direction
      };
    },
    dashboardV2IncrementPeriodPage(state) {
      state.periodTable.period_page += 1;
    }
  },
  getters: {
    dashboardV2GetFilters(state) {
      return () => {
        return {
          seller_id: state.sellers.filterValues,
          sales_channel: state.marketplaces.filterValues,
          sku_list: state.products.selected.map(product => product.sku).filter(Boolean),
          asin_list: state.products.selected.map(product => product.sku).filter(Boolean)
        };
      };
    },
    dashboardV2GetPeriod(state) {
      return () => state.period;
    },
    dashboardV2GetPeriodCategory(state) {
      return () => state.periodCategory;
    },
    dashboardV2GetTab(state) {
      return () => state.currentTab;
    },
    dashboardV2GetTabValue(state) {
      return () => state.currentTab;
    },
    dashboardV2GetProductTablePage(state) {
      return () => state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.page;
    },
    dashboardV2GetProductTablePerPage(state) {
      return () => state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.perPage;
    },
    dashboardV2GetProductTableTotalPages(state) {
      return () => state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.totalPages;
    },
    dashboardV2GetAccountState(state) {
      return () => {
        const res = {
          sellers: state.sellers.defaultList,
          marketplaces: state.marketplaces.defaultList
        };
        return res;
      };
    },
    dashboardV2GetProductTableSort(state) {
      return () => {
        let obj = {
          sort_by: [],
          sort_direction: []
        };
        for (const [key, value] of Object.entries(state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.sort)) {
          obj['sort_by'].push(key);
          obj['sort_direction'].push(value);
        }
        return obj;
      };
    },
    dashboardV2GetProductTablePager(state) {
      return () => ({
        page: state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.page,
        page_size: state.product_table[state.currentTabValue].data?.[state.product_table.group_by_text]?.perPage
      });
    },
    dashboardV2GetProductTableGroupBy(state) {
      return () => state.product_table.group_by;
    }
  },
  actions: {
    async dashboardV2FetchKpis({
      commit,
      getters,
      state
    }) {
      try {
        const params = {
          period: state.period,
          ...getters.dashboardV2GetFilters(),
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        let response = await request('/dashboard/v2/current_period', 'POST', params, false, true, 'cors');
        if (response) {
          let data = response.data;
          data = data.pop();

          // Performance
          commit('dashboardV2SetKpisDataPrevPeriod', {
            payload: computeDetailsForKpis(data, state.kpis.CONSIDERED_KPIS['performance'].prev_period),
            tab: 'performance'
          });
          commit('dashboardV2SetKpisDataSamePeriodLY', {
            payload: computeDetailsForKpis(data, state.kpis.CONSIDERED_KPIS['performance'].same_period_ly),
            tab: 'performance'
          });

          // Listing
          commit('dashboardV2SetKpisDataPrevPeriod', {
            payload: computeDetailsForKpis(data, state.kpis.CONSIDERED_KPIS['listing'].prev_period),
            tab: 'listing'
          });
          commit('dashboardV2SetKpisDataSamePeriodLY', {
            payload: computeDetailsForKpis(data, state.kpis.CONSIDERED_KPIS['listing'].same_period_ly),
            tab: 'listing'
          });
          commit('dashboardV2SetKpisDataPrevPeriod', {
            payload: computeDetailsForKpis(data, state.kpis.CONSIDERED_KPIS['advertising'].prev_period),
            tab: 'advertising'
          });
          commit('dashboardV2SetKpisDataSamePeriodLY', {
            payload: computeDetailsForKpis(data, state.kpis.CONSIDERED_KPIS['advertising'].same_period_ly),
            tab: 'advertising'
          });
          //console.log('state', state)
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async dashboardV2FetchPeriodTableData({
      commit,
      getters,
      state
    }) {
      try {
        const params = {
          period: state.period,
          ...getters.dashboardV2GetFilters(),
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet(),
          period_page: state.periodTable.period_page
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request('/dashboard/v2/evolution', 'POST', params, false, true, 'cors');
        let data = response.data;
        if (data) {
          commit("dashboardV2SetPeriodTableItems", data);
          commit('dashboardV2SetPeriodTableMaxCharCount', {
            currency: computeCurrencyMaxCharCount(data, full, currency, precision, showCurrencySign, false, limit),
            number: computeNumberMaxCharCount(data),
            percentage: computePercentageMaxCharCount(data)
          });
          console.log("computedMinMax", computeMinMax(data));
          commit("dashboardV2SetPeriodTableMinMax", computeMinMax(data));
        }
      } catch (err) {
        //console.log(err);
      }
    },
    async dashboardV2FetchReportGroupedSellers({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.dashboardV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.dashboardV2GetFilters(),
          ...getters.dashboardV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/dashboard/v2/report/seller", 'POST', params, false, true, 'cors');
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          const {
            count,
            result
          } = report;
          if (store) {
            commit('dashboardV2SetProductTableTotalPages', total?.[0]?.row_count ?? 0);
            commit('dashboardV2SetProductTableItems', result);
            commit('dashboardV2SetProductTableMaxCharCount', {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit('dashboardV2SetSellersTableTotal', total?.[0] ?? {});
            commit('dashboardV2SetProductTableMinMax', computeMinMax(result));
          }
        }
        return response;
      } catch (err) {
        //console.log(err);
      }
    },
    async dashboardV2FetchReportGroupedMarketplace({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.dashboardV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.dashboardV2GetFilters(),
          ...getters.dashboardV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/dashboard/v2/report/marketplace", 'POST', params, false, true, 'cors');
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            count,
            result
          } = report;
          result = await transformWithCountries(result);
          if (store) {
            commit('dashboardV2SetProductTableTotalPages', total?.[0]?.row_count ?? 0);
            commit('dashboardV2SetProductTableItems', result);
            commit('dashboardV2SetProductTableMaxCharCount', {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit('dashboardV2SetSellersTableTotal', total?.pop() ?? {});
            commit('dashboardV2SetProductTableMinMax', computeMinMax(result, ["sales_channel"]));
          }
        }
        return response;
      } catch (err) {
        //console.log(err);
      }
    },
    async dashboardV2FetchReportGroupedProduct({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.dashboardV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.dashboardV2GetFilters(),
          ...getters.dashboardV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/dashboard/v2/report/product", 'POST', params, false, true, 'cors');
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          const {
            count,
            result
          } = report;
          if (store) {
            commit('dashboardV2SetProductTableTotalPages', total?.[0]?.row_count ?? 0);
            commit('dashboardV2SetProductTableItems', result);
            commit('dashboardV2SetSellersTableTotal', total?.pop() ?? {});
            commit('dashboardV2SetProductTableMaxCharCount', {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit('dashboardV2SetProductTableMinMax', computeMinMax(result, ["campaign_group_name", "sales_channel", "campaign_activated_products_count", "advertising_state"]));
          }
        }
        return response;
      } catch (err) {
        //console.log(err);
      }
    },
    async dashboardV2FetchReportGroupedAsin({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.dashboardV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.dashboardV2GetFilters(),
          ...getters.dashboardV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/dashboard/v2/report/asin", 'POST', params, false, true, 'cors');
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          const {
            result
          } = report;
          if (store) {
            commit('dashboardV2SetProductTableTotalPages', total?.[0]?.row_count ?? 0);
            commit('dashboardV2SetProductTableItems', result);
            commit('dashboardV2SetSellersTableTotal', total?.pop() ?? {});
            commit('dashboardV2SetProductTableMaxCharCount', {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            commit('dashboardV2SetProductTableMinMax', computeMinMax(result, ["campaign_group_name", "sales_channel", "campaign_activated_products_count", "advertising_state"]));
          }
        }
        return response;
      } catch (err) {
        //console.log(err);
      }
    },
    async dashboardV2FetchReportGroupedParentAsin({
      commit,
      getters,
      state
    }, options = {}) {
      try {
        let {
          pager,
          store = true
        } = options;
        if (!pager) {
          pager = getters.dashboardV2GetProductTablePager();
        }
        const params = {
          period: state.product_table[state.currentTabValue].period,
          ...getters.dashboardV2GetFilters(),
          ...getters.dashboardV2GetProductTableSort(),
          ...pager,
          ...getters.currencyConfigQueryGet(),
          ...getters.dateConfigQueryGet()
        };
        const {
          full,
          currency,
          precision,
          showCurrencySign,
          limit
        } = getters.currencyConfigGet();
        let response = await request("/dashboard/v2/report/parent_asin", 'POST', params, false, true, 'cors');
        if (response) {
          response = response.data;
          const {
            report,
            total
          } = response;
          let {
            result
          } = report;
          let out = [];
          for (const item of result) {
            if ("child_asin" in item && Array.isArray(item.child_asin) && item.child_asin.length > 0) {
              const lastIndex = item.child_asin.length - 1;

              // Add the 'last_child' property to the last element of 'child_asin'
              item.child_asin[lastIndex] = {
                ...item.child_asin[lastIndex],
                last_child: true
              };

              // Push the modified item into 'out'
              out.push(item, ...item.child_asin);
            } else out.push(item);
          }
          result = out;
          if (store) {
            commit('dashboardV2SetProductTableTotalPages', total?.[0]?.row_count ?? 0);
            commit('dashboardV2SetProductTableItems', result);
            commit('dashboardV2SetSellersTableTotal', total?.pop() ?? {});
            commit('dashboardV2SetProductTableMaxCharCount', {
              currency: computeCurrencyMaxCharCount(result, full, currency, precision, showCurrencySign, false, limit),
              number: computeNumberMaxCharCount(result),
              percentage: computePercentageMaxCharCount(result)
            });
            //console.log('Parent Asin', result);
            commit('dashboardV2SetProductTableMinMax', computeMinMax(result, ["campaign_group_name", "sales_channel", "campaign_activated_products_count", "advertising_state"]));
          }
        }
        return response;
      } catch (err) {
        //console.log(err);
      }
    },
    dashboardV2SetPeriod({
      commit
    }, period) {
      commit('dashboardV2SetPeriod', period);
    },
    dashboardV2SetPeriodCategory({
      commit
    }, periodCategory) {
      commit('dashboardV2SetPeriodCategory', periodCategory);
    },
    dashboardV2SetTab({
      commit
    }, tab) {
      commit('dashboardV2SetCurrentTab', tab);
    },
    dashboardV2SetProductTableGroupBy({
      commit
    }, groupBy) {
      commit('dashboardV2SetProductTableGroupBy', groupBy);
    },
    dashboardV2SetProductTablePeriod({
      commit
    }, period) {
      commit('dashboardV2SetProductTablePeriod', period);
    },
    dashboardV2SetProductTableState({
      commit
    }, payload) {
      commit('dashboardV2SetProductTableState', payload);
    },
    dashboardV2SetProductTablePage({
      commit
    }, page) {
      commit('dashboardV2SetProductTablePage', page);
    },
    dashboardV2SetProductTablePerPage({
      commit
    }, perPage) {
      commit('dashboardV2SetProductTablePerPage', perPage);
    },
    dashboardV2SetProductTableTotalPages({
      commit
    }, totalPages) {
      commit('dashboardV2SetProductTableTotalPages', totalPages);
    },
    dashboardV2SetProductTableGroupByElements({
      commit,
      getters
    }) {
      const {
        sellers,
        marketplaces
      } = getters.dashboardV2GetAccountState();
      const {
        SELLER,
        MARKETPLACE,
        PRODUCT_SKU,
        PRODUCT_ASIN,
        PRODUCT_PARENT
      } = GROUP_BY_TAB_ELEMENTS;
      if (sellers.length > 1 && marketplaces.length > 1) {
        commit("dashboardV2SetProductTableGroupByElements", [SELLER, MARKETPLACE, PRODUCT_PARENT, PRODUCT_ASIN, PRODUCT_SKU]);
        return;
      } else if (marketplaces.length > 1) {
        commit("dashboardV2SetProductTableGroupByElements", [MARKETPLACE, PRODUCT_PARENT, PRODUCT_ASIN, PRODUCT_SKU]);
        return;
      }
      commit("dashboardV2SetProductTableGroupByElements", [PRODUCT_PARENT, PRODUCT_ASIN, PRODUCT_SKU]);
    },
    dashboardV2SetProductTableSort({
      commit
    }, payload) {
      commit('dashboardV2SetProductTableSort', payload);
    },
    dashboardV2IncrementPeriodPage({
      commit
    }) {
      commit('dashboardV2IncrementPeriodPage');
    }
  }
};
export default dashboard_v2;