import { SELECTOR, SPONSORED_ASIN_FIELD, SPONSORED_SALES_FIELD, UNITS_SOLD_FIELD, TACOS_FIELD, CPC_FIELD, CVR_FIELD, ROAS_FIELD, BEST_POSITION_FIELD, SPEND_FIELD, CLICKS_FIELD, CAMPAIGN_BUDGET_FIELD, PERIOD_FIELD, SELLER_FIELD, MARKETPLACE_FIELD, PRODUCT_FIELD, CAMPAIGN_GROUP_FIELD, CAMPAIGN_NAME_FIELD, TARGETING_FIELD, KEYWORD_BID_FIELD } from "@/utils/table_fields";
export const periodTableHeadersForSecondaryFilters = [SELECTOR, PERIOD_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, ROAS_FIELD, BEST_POSITION_FIELD];
export const periodTableHeaders = [SELECTOR, PERIOD_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, ROAS_FIELD, SPONSORED_ASIN_FIELD];
export const productTableHeaders = {
  seller: [SELLER_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, ROAS_FIELD, SPONSORED_ASIN_FIELD],
  marketplace: [MARKETPLACE_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, TACOS_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, ROAS_FIELD, SPONSORED_ASIN_FIELD],
  product: [PRODUCT_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, ROAS_FIELD],
  campaign_group: [CAMPAIGN_GROUP_FIELD, {
    ...CAMPAIGN_BUDGET_FIELD,
    key: "campaign_group_budget"
  }, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, ROAS_FIELD],
  campaign: [CAMPAIGN_NAME_FIELD, CAMPAIGN_BUDGET_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, ROAS_FIELD],
  targeting: [TARGETING_FIELD, KEYWORD_BID_FIELD, SPONSORED_SALES_FIELD, SPEND_FIELD, CLICKS_FIELD, CPC_FIELD, UNITS_SOLD_FIELD, CVR_FIELD, ROAS_FIELD]
};