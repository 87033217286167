import AppButton from '@/components/AppButton';
export default {
  name: 'OmnyConfirmContinueModal',
  components: {
    AppButton
  },
  emits: ['update:modelValue', 'cancel', 'continue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    continueVariant: {
      type: String
    }
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  }
};