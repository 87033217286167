import { mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import TasksMixin from "@/mixins/TasksMixin.vue";
import CustomSideBarAppModal from "@/components/CustomSideBarAppModal";
import AppForm from "@/components/AppForm";
import AppInput from "@/components/AppInput";
import AppInputRange from "@/components/AppInputRange";
import AppSelect from "@/components/AppSelect";
export default {
  name: "CreateWorkspaceSideBar",
  emits: ["update:modelValue"],
  mixins: [ValidateMixin, TasksMixin],
  components: {
    CustomSideBarAppModal,
    AppForm,
    AppInput,
    AppInputRange,
    AppSelect
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        workspace_name: "",
        members: [""],
        currency: "",
        unit_type: "",
        week_type: ""
      },
      loading: false,
      workspaceCreateTaskIdentifier: "",
      unit_types: [{
        text: "Units",
        value: ""
      }, {
        text: "Metric",
        value: "metric"
      }, {
        text: "Imperial",
        value: "imperial"
      }],
      currencies: [{
        text: "Currency",
        value: ""
      }, {
        text: "EUR €",
        value: "EUR"
      }, {
        text: "USD $",
        value: "USD"
      }, {
        text: "GBP £",
        value: "GBP"
      }, {
        text: "CAD $",
        value: "CAD"
      }, {
        text: "AED د.إ.",
        value: "AED"
      }, {
        text: "AUD $",
        value: "AUD"
      }, {
        text: "BRL R$",
        value: "BRL"
      }, {
        text: "EGP ج.م.",
        value: "EGP"
      }, {
        text: "INR Rs",
        value: "INR"
      }, {
        text: "JPY ¥",
        value: "JPY"
      }, {
        text: "MXN $",
        value: "MXN"
      }, {
        text: "PLN zł",
        value: "PLN"
      }, {
        text: "SAR ر.س.",
        value: "SAR"
      }, {
        text: "SEK kr",
        value: "SEK"
      }, {
        text: "SGD $",
        value: "SGD"
      }, {
        text: "TRY TL",
        value: "TRY"
      }],
      week_types: [{
        text: "Week Type",
        value: ""
      }, {
        text: "Sunday",
        value: "sunday"
      }, {
        text: "Monday",
        value: "monday"
      }]
    };
  },
  async mounted() {
    this.workspaceCreateTaskIdentifier = this.current_workspace.workspace_id + "_" + "workspace_create";
    this.getAsyncUpdate(this.workspaceCreateTaskIdentifier);
    this.checkTaskStatusPeriodically(this.workspaceCreateTaskIdentifier);
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    ...mapState({
      current_workspace: state => state.workspace.current_workspace
    })
  },
  methods: {
    ...mapActions(["workspaceCreate"]),
    async onSubmit() {
      const valid = await this.mValidate();
      if (valid) {
        this.loading = true;
        const result = await this.workspaceCreate({
          ...this.form,
          ...{
            task_type: "workspace_create",
            workspace_id: this.current_workspace.workspace_id
          }
        });
        if (result) {
          this.executeTaskStatusHandler(result);
          this.checkTaskStatusPeriodically(this.workspaceCreateTaskIdentifier);
        }
        this.loading = false;
        this.modal = false;
      }
    },
    handleIdle() {
      this.loading = false;
    },
    handleFailed() {
      this.loading = false;
    },
    handleRunning() {
      this.loading = true;
      this.checkTaskStatusPeriodically(this.workspaceCreateTaskIdentifier);
    },
    handleFinished() {
      this.loading = false;
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
    }
  }
};