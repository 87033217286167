export default {
  name: 'OverviewRowContainer',
  props: {
    customStyle: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: Array,
      default: () => []
    },
    disableFull: {
      type: Boolean,
      default: false
    },
    disableLeft: {
      type: Boolean,
      default: false
    },
    disableRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rowClasses() {
      const classes = ['custom-row', ...this.classes];
      if (this.$slots.full) {
        classes.push('full-slot');
      }
      if (this.$slots.left && this.$slots.right) {
        classes.push('both-slots');
      } else if (this.$slots.left) {
        classes.push('left-slot-only');
      } else if (this.$slots.right) {
        classes.push('right-slot-only');
      }
      return classes;
    }
  }
};