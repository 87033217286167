import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-36e9a4e2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "alerts-container"
};
const _hoisted_2 = {
  class: "alerts-content"
};
const _hoisted_3 = {
  class: "chart-wrapper"
};
const _hoisted_4 = {
  class: "chart-wrapper"
};
const _hoisted_5 = {
  key: 1,
  class: "alerts-content-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_tabs = _resolveComponent("app-tabs");
  const _component_alerts_chart = _resolveComponent("alerts-chart");
  const _component_app_card = _resolveComponent("app-card");
  const _component_alerts_list = _resolveComponent("alerts-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_app_tabs, {
    modelValue: $data.tab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.tab = $event),
    tabs: $options.readyTabs
  }, null, 8 /* PROPS */, ["modelValue", "tabs"]), _createElementVNode("div", _hoisted_2, [$data.tab == 'overview' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['alerts-content-wrapper', {
      column: $props.isColumn
    }])
  }, [_createVNode(_component_app_card, {
    class: "alerts-card",
    "no-body": "",
    title: "Alerts per seller",
    info: "Some text"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_alerts_chart, {
      type: "sellers",
      items: $options.alertsSellers,
      "selected-row-id": $props.selectedRowId,
      "selected-level": $props.selectedLevel,
      onSelect: $options.onToggleSelect
    }, null, 8 /* PROPS */, ["items", "selected-row-id", "selected-level", "onSelect"])])]),
    _: 1 /* STABLE */
  }), _createVNode(_component_app_card, {
    class: "alerts-card",
    "no-body": "",
    title: "Per alert type",
    info: "Some text"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_alerts_chart, {
      type: "categories",
      items: $options.alertsCategories,
      "selected-row-id": $props.selectedRowId,
      "selected-level": $props.selectedLevel,
      onSelect: $options.onToggleSelect
    }, null, 8 /* PROPS */, ["items", "selected-row-id", "selected-level", "onSelect"])])]),
    _: 1 /* STABLE */
  })], 2 /* CLASS */)) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_alerts_list, {
    items: $props.items,
    "selected-id": $props.selectedId,
    "selected-level": $data.tab,
    "expanded-ids": $data.expandedIds,
    onSelect: $options.onToggleId
  }, null, 8 /* PROPS */, ["items", "selected-id", "selected-level", "expanded-ids", "onSelect"])]))])]);
}