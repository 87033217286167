import KpisControllersSection from '@/components/KpisControllersSection';
import KpisRenderer from '@/components/KpisRenderer';
import PdfExportPopup from '@/components/PdfExportPopup';
import { periodsSwitches } from '@/components/shared/chartHelpers';
import { ProductInventoryCoveragePage } from '@/pages/subpages';
import DashboardMixin from '@/mixins/DashboardMixin';
import DatePickerMixin from '@/mixins/DatePickerMixin';
import LoadingTable from '@/components/LoadingTable';
import { mapState } from 'vuex';
export default {
  name: 'InventoryCoveragePage',
  async created() {
    this.$store.dispatch('dashboardSetCurrentTab', 'Product');
    this.$store.dispatch('dashboardSetCurrentView', 'Inventory Coverage');

    // this.$store.dispatch('datePickerSetPeriod', this.getDateFromRangeCompare())
    const sellers = await this.getOmnyAccountSellers();
    this.omnyAccountSellersCount = sellers.length;
    this.omnyAccountHasMoreThanOneSeller = sellers.length > 1;
    await this.initializeFilters({
      sellers
    });
    this.homepageLoading = false;
  },
  components: {
    KpisRenderer,
    KpisControllersSection,
    PdfExportPopup,
    ProductInventoryCoveragePage,
    LoadingTable
  },
  mixins: [DashboardMixin],
  data() {
    return {
      settingUp: false,
      countries: [],
      sellers: [],
      omnyAccountSellersCount: 0,
      omnyAccountHasMoreThanOneSeller: false,
      currentViewMinMax: {},
      tableLoading: true,
      period: 'Weekly',
      currentPeriod: periodsSwitches.DEFAULT[0],
      homepageLoading: true
    };
  },
  computed: {
    ...mapState({
      globalPeriod: state => state.period.globalPeriod
    }),
    getUpdatedTabFilters() {
      return this.getTabFilters({
        omnyAccountHasMoreThanOneSeller: this.omnyAccountHasMoreThanOneSeller,
        refreshTrigger: this.refreshTriggers[this.currentTab][this.currentView]
      });
    },
    getUpdatedPages() {
      return this.pages({
        omnyAccountHasMoreThanOneSeller: this.omnyAccountHasMoreThanOneSeller
      });
    }
  }
};