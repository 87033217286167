import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7fbfc640"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ai-assistance-card-top"
};
const _hoisted_2 = {
  class: "select"
};
const _hoisted_3 = {
  class: "ai-assistance-card-content"
};
const _hoisted_4 = {
  class: "ai-assistance-card-title"
};
const _hoisted_5 = {
  class: "ai-assistance-card-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ai-assistance-card', {
      selected: $props.selected
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_app_icon, {
    icon: $props.icon,
    size: "28",
    color: $props.selected ? '#8147FF' : '#757575'
  }, null, 8 /* PROPS */, ["icon", "color"]), _createElementVNode("div", _hoisted_2, [$props.selected ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "check2",
    size: "16",
    color: "#fff"
  })) : _createCommentVNode("v-if", true)])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString($props.title), 1 /* TEXT */), _createElementVNode("div", _hoisted_5, _toDisplayString($props.description), 1 /* TEXT */)])], 2 /* CLASS */);
}