import TableWithTitle from '@/components/TableWithTitle.vue';
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import { getDescriptionBasedOnKpiKey, getColorsBasedOnRange, getColorForDecile, getProfitPercentageDecileColor } from "@/utils/transformers";
import { SELECTOR, PERIOD_FIELD, PROFIT_REVENUE_FIELD, PROFIT_CONTRIBUTIVE_MARGIN_FIELD, PROFIT_COMMISSION_FIELD, PROFIT_FBA_DIRECT_FIELD, PROFIT_FBA_INDIRECT_FIELD, PROFIT_REFUND_FIELD, PROFIT_COOP_FIELD, PROFIT_COGS_FIELD, PROFIT_OTHER_COSTS_FIELD, PROFIT_ADVERTISING_COSTS_FIELD, PROFIT_PERCENTAGE_OF_REVENUE_FIELD } from "@/utils/table_fields";
import AppIcon from '@/components/AppIcon.vue';
import EvolutionValue from '@/components/tableElements/EvolutionValue.vue';
import { mapState } from "vuex";
import { HugValue, DateValue, RateChangeValue, CurrencyValue, NumberValue, BoxedValue, BarWithValue, PercentageValue, RatingValue, DottedValue, OverlapBarValue, SignedBarWithValue } from "@/components/tableElements";
import SlotWithEvolutionValue from "@/components/tableElements/SlotWithEvolutionValue.vue";
import LiteTableMixin from '@/mixins/LiteTableMixin.vue';
import { DEFAULT_DATE_FORMATS, BUYBOX_HEATMAP_COLORS, PERIOD_COLUMN_TITLES, PROFIT_DECILE_COLORS, PROFIT_REVENUE_PERCENTAGE_DECILE_COLORS, COLORS } from '@/utils/constants';
import moment from 'moment';
import BarSlotWithValue from '@/components/tableElements/BarSlotWithValue.vue';
import LayerBars from '@/components/tableElements/LayerBars.vue';
import VariableStyledBar from '@/components/tableElements/VariableStyledBar.vue';
import FlexRow from "@/components/FlexRow";
import CompareValue from '@/components/tableElements/CompareValue.vue';
import DownloadMixin from '@/mixins/DownloadMixin.vue';
import { capitalize } from 'vue';
import BlockBarWithValue from '@/components/tableElements/BlockBarWithValue.vue';
import BoxAndBarWithValue from '@/components/tableElements/BoxAndBarWithValue.vue';
import BlockStyledBar from '@/components/tableElements/BlockStyledBar.vue';
import WrapWithAlertIcon from "@/components/WrapWithAlertIcon.vue";
import WrapTableWithShowMore from '@/components/WrapTableWithShowMore.vue';
export default {
  name: "ProfitPeriodTable",
  components: {
    TableWithTitle,
    WrapWithPopover,
    AppIcon,
    HugValue,
    DateValue,
    RateChangeValue,
    CurrencyValue,
    NumberValue,
    BoxedValue,
    BarWithValue,
    PercentageValue,
    RatingValue,
    DottedValue,
    OverlapBarValue,
    BarSlotWithValue,
    LayerBars,
    VariableStyledBar,
    FlexRow,
    CompareValue,
    EvolutionValue,
    SlotWithEvolutionValue,
    BlockStyledBar,
    BlockBarWithValue,
    BoxAndBarWithValue,
    WrapWithAlertIcon,
    SignedBarWithValue,
    WrapTableWithShowMore
  },
  mixins: [LiteTableMixin, DownloadMixin],
  props: {
    loading: {
      typ: Boolean,
      required: true
    }
  },
  emits: ["setDateRange", "refresh"],
  data() {
    return {
      PROFIT_DECILE_COLORS,
      BUYBOX_HEATMAP_COLORS,
      PERIOD_COLUMN_TITLES,
      PROFIT_REVENUE_FIELD,
      PROFIT_REVENUE_PERCENTAGE_DECILE_COLORS,
      fields: [SELECTOR, PERIOD_FIELD, PROFIT_REVENUE_FIELD, PROFIT_CONTRIBUTIVE_MARGIN_FIELD, PROFIT_PERCENTAGE_OF_REVENUE_FIELD, PROFIT_COMMISSION_FIELD, PROFIT_FBA_DIRECT_FIELD, PROFIT_FBA_INDIRECT_FIELD, PROFIT_REFUND_FIELD, PROFIT_COOP_FIELD, PROFIT_COGS_FIELD, PROFIT_OTHER_COSTS_FIELD, PROFIT_ADVERTISING_COSTS_FIELD],
      csvSources: period => ({
        [PERIOD_COLUMN_TITLES[period]]: PERIOD_FIELD.key,
        [PROFIT_REVENUE_FIELD.label]: PROFIT_REVENUE_FIELD.key,
        ...this.getEvolutionsForField(PROFIT_REVENUE_FIELD),
        [PROFIT_CONTRIBUTIVE_MARGIN_FIELD.label]: PROFIT_CONTRIBUTIVE_MARGIN_FIELD.key,
        ...this.getEvolutionsForField(PROFIT_CONTRIBUTIVE_MARGIN_FIELD),
        [PROFIT_PERCENTAGE_OF_REVENUE_FIELD.label]: PROFIT_PERCENTAGE_OF_REVENUE_FIELD.key,
        ...this.getEvolutionsForField(PROFIT_PERCENTAGE_OF_REVENUE_FIELD),
        [PROFIT_COMMISSION_FIELD.label]: PROFIT_COMMISSION_FIELD.key,
        [PROFIT_FBA_DIRECT_FIELD.label]: PROFIT_FBA_DIRECT_FIELD.key,
        [PROFIT_FBA_INDIRECT_FIELD.label]: PROFIT_FBA_INDIRECT_FIELD.key,
        [PROFIT_REFUND_FIELD.label]: PROFIT_REFUND_FIELD.key,
        [PROFIT_COOP_FIELD.label]: PROFIT_COOP_FIELD.key,
        [PROFIT_COGS_FIELD.label]: PROFIT_COGS_FIELD.key,
        [PROFIT_OTHER_COSTS_FIELD.label]: PROFIT_OTHER_COSTS_FIELD.key,
        ['% of Advertising costs']: 'pl_advertising_costs_pc',
        [PROFIT_ADVERTISING_COSTS_FIELD.label]: PROFIT_ADVERTISING_COSTS_FIELD.key
      }),
      evolCategory: {
        prev_period: "",
        same_period_ly: "last_year_"
      },
      COLORS
    };
  },
  computed: {
    ...mapState({
      periodTable: state => state.dashboard_v2.periodTable,
      tab: state => state.dashboard_v2.currentTabValue,
      period: state => state.dashboard_v2.period,
      currentPeriod: state => state.dashboard_v2.currentPeriod,
      periodCategory: state => state.dashboard_v2.periodCategory
    }),
    items() {
      return this.periodTable[this.tab].items;
    },
    maxCharCount() {
      return this.periodTable[this.tab].maxCharCount;
    },
    minMax() {
      return this.periodTable[this.tab].minMax;
    },
    getDateValueFormat() {
      // Convert the current period to UPPER CASE
      const period = this.period.toUpperCase();
      return DEFAULT_DATE_FORMATS[period];
    },
    getPeriodColumnTitle() {
      return PERIOD_COLUMN_TITLES[this.period];
    },
    csvFileName() {
      return `Dashboard_Profit_Evolution_${capitalize(this.period)}.csv`;
    },
    period_pager: {
      get() {
        return this.periodTable.period_page;
      },
      set(_) {
        this.$store.dispatch('dashboardV2IncrementPeriodPage');
        this.$emit('refresh');
      }
    }
  },
  methods: {
    getDescriptionBasedOnKpiKey,
    getColorsBasedOnRange,
    getColorForDecile,
    getProfitPercentageDecileColor,
    handleDateClick(period) {
      period = moment(period);
      this.$emit("setDateRange", period);
    },
    handleDateReset() {
      this.$emit("setDateRange", null);
    },
    async downloadPerformancePeriodCSV() {
      const csv_headers = this.csvSources(this.period);
      const csvFileName = this.csvFileName;
      const str = await this.generateCsvString(csv_headers, this.prepareCsvRowsForDownload(this.items, csv_headers), 1, '-');
      await this.downloadCsv(str, csvFileName);
    }
  }
};