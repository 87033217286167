import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TextValue = _resolveComponent("TextValue");
  const _component_TargetingFilterBody = _resolveComponent("TargetingFilterBody");
  const _component_FilterItem = _resolveComponent("FilterItem");
  return _openBlock(), _createBlock(_component_FilterItem, {
    id: "targetings",
    ref: "targetingFilterItem",
    icon: "TargetIcon",
    title: $options.getTargetingTitle,
    selectedCount: $props.targetings.filterState.selectedCount,
    onClearAll: _cache[4] || (_cache[4] = $event => {
      $props.targetings.clear();
      _ctx.$emit('cleared');
    })
  }, _createSlots({
    body: _withCtx(() => [_createVNode(_component_TargetingFilterBody, {
      title: "Targeting",
      selected: $props.targetings.filterState.selected,
      isModified: $props.targetings.isModified,
      searching: $props.targetings.searching,
      list: $props.targetings.filterState.list,
      onClear: _cache[0] || (_cache[0] = $event => {
        $props.targetings.clear();
        _ctx.$emit('cleared');
      }),
      onSelect: $options.handleSelect,
      onSelectOnly: $options.handleSelectOnly,
      onSelectAll: _cache[1] || (_cache[1] = $event => $props.targetings.selectAll()),
      onDeselect: $options.handleDeselect,
      onApply: _cache[2] || (_cache[2] = $event => _ctx.$emit('apply')),
      onSearch: $options.handleSearch,
      onHidden: _cache[3] || (_cache[3] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _ctx.$refs?.targetingFilterItem?.closeDropdown
    }, null, 8 /* PROPS */, ["selected", "isModified", "searching", "list", "onSelect", "onSelectOnly", "onDeselect", "onSearch", "onCloseDropdown"])]),
    _: 2 /* DYNAMIC */
  }, [$options.getTargeting ? {
    name: "title",
    fn: _withCtx(() => [_createVNode(_component_TextValue, {
      value: $options.truncateString($options.getTargeting.targeting, 16),
      main: "",
      styles: {
        width: 'auto !important'
      }
    }, null, 8 /* PROPS */, ["value"])]),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "selectedCount"]);
}