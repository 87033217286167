import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1dfbfe82"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "upload-box"
};
const _hoisted_2 = {
  key: 0,
  class: "upload-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_button = _resolveComponent("app-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_app_button, {
    variant: "outline-primary",
    class: "upload"
  }, {
    default: _withCtx(() => [_createElementVNode("input", {
      ref: "inputRef",
      type: "file",
      onChange: _cache[0] || (_cache[0] = (...args) => $options.onChange && $options.onChange(...args))
    }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), _createElementVNode("span", null, _toDisplayString($props.label), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }), $data.file ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($data.file.name), 1 /* TEXT */)) : _createCommentVNode("v-if", true), $data.file ? (_openBlock(), _createBlock(_component_app_button, {
    key: 1,
    loading: $props.loading,
    variant: "primary",
    onClick: $options.onUpload
  }, {
    default: _withCtx(() => [_createTextVNode(" Upload ")]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading", "onClick"])) : _createCommentVNode("v-if", true)]);
}