import AppButton from '@/components/AppButton';
import AppIcon from '@/components/AppIcon';
export default {
  name: 'CustomSideBarAppModal',
  emits: ['update:modelValue', 'cancel', 'ok'],
  components: {
    AppButton,
    AppIcon
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    title: {
      type: String,
      default: null
    },
    cancelTitle: {
      type: String,
      default: 'Cancel'
    },
    noCancel: {
      type: Boolean,
      default: false
    },
    okTitle: {
      type: String,
      default: 'Ok'
    },
    okLoading: {
      type: Boolean,
      default: false
    },
    okDisabled: {
      type: Boolean,
      default: false
    },
    noOk: {
      type: Boolean,
      default: false
    },
    noFooter: {
      type: Boolean,
      default: false
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    noClose: {
      type: Boolean,
      default: false
    },
    nested: {
      type: Boolean,
      default: false
    },
    v2: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('update:modelValue', false);
      this.$emit('cancel');
    },
    onOk() {
      this.$emit('ok');
    }
  }
};