import GroupBy from "@/components/GroupBy.vue";
import { mapState } from "vuex";
export default {
  name: "PeriodTableGroupBy",
  components: {
    GroupBy
  },
  emits: ["refresh", "clicked"],
  computed: {
    ...mapState({
      groupByElements: state => state.ppc.periodTable.periodTableElements
    }),
    groupByIndex: {
      get() {
        return this.$store.getters.ppcGetPeriodTablePeriod();
      },
      set(val) {
        this.$store.dispatch("ppcSetPeriodTablePeriod", val);
        this.$emit("refresh:datePicker");
        this.$emit("refresh");
      }
    }
  }
};