import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bef97306"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrap-with-status-icon"
};
const _hoisted_2 = {
  class: "status-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_hug_value, {
    styles: $props.hugStyles
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["styles"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_wrap_with_popover, {
    placement: "right",
    width: "fit-content"
  }, {
    title: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent($data.SEO_STATUS_ICONS_MAP[$props.validationStatus.status]), {
      width: 16,
      height: 16
    })), _createTextVNode(" " + _toDisplayString($props.validationStatus.text), 1 /* TEXT */)]),
    default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent($data.SEO_STATUS_ICONS_MAP[$props.validationStatus.status]), {
      width: 16,
      height: 16
    }))]),
    _: 1 /* STABLE */
  })])]);
}