import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_textarea = _resolveComponent("b-form-textarea");
  return _openBlock(), _createBlock(_component_b_form_textarea, {
    modelValue: $options.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.value = $event),
    id: $data.id,
    placeholder: $props.placeholder,
    rows: $props.rows,
    "max-rows": $props.maxRows,
    class: _normalizeClass([{
      v2: $props.v2
    }, {
      noPadding: $props.noPadding
    }]),
    style: _normalizeStyle({
      ['--app-text-area-bg']: $props.background
    }),
    "no-resize": ""
  }, null, 8 /* PROPS */, ["modelValue", "id", "placeholder", "rows", "max-rows", "class", "style"]);
}