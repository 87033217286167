import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-43b800fa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "event-type-value"
};
const _hoisted_2 = {
  class: "icon"
};
const _hoisted_3 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_app_icon, {
    icon: "amazon",
    size: 12,
    color: "#999999"
  })]), _createElementVNode("div", _hoisted_3, _toDisplayString($props.value), 1 /* TEXT */)]);
}