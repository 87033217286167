import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-581f78e6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "d-flex flex-row align-items-center gap-12px",
  style: {
    "padding": "16px"
  }
};
const _hoisted_2 = {
  class: "menu-children"
};
const _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_feature = _resolveComponent("feature");
  return _openBlock(), _createBlock(_component_feature, {
    name: $options.getFeatureName($props.item)
  }, {
    default: _withCtx(() => [$options.showMenuIcon ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      onClick: _cache[0] || (_cache[0] = $event => $options.onClick($props.item)),
      class: _normalizeClass(["strip-menu-item menu-text-style", {
        active: $options.isActive
      }])
    }, [_createElementVNode("div", null, [$props.item.icon ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 0,
      icon: $props.item.icon,
      color: $props.item.color
    }, null, 8 /* PROPS */, ["icon", "color"])) : _createCommentVNode("v-if", true)])], 2 /* CLASS */)) : (_openBlock(), _createElementBlock("div", {
      key: 1,
      class: _normalizeClass([{
        open: $options.isOpen,
        active: $options.isActive
      }, "menu-item menu-text-style"])
    }, [_createElementVNode("div", {
      class: "d-flex flex-row align-items-center justify-content-between gap-2 menu-item-link",
      onClick: _cache[1] || (_cache[1] = $event => $options.onClick($props.item))
    }, [_createElementVNode("span", _hoisted_1, [$props.item.icon ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 0,
      icon: $props.item.icon,
      color: $props.item.color
    }, null, 8 /* PROPS */, ["icon", "color"])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
      class: _normalizeClass({
        active: $options.isActive
      })
    }, _toDisplayString($props.item.text), 3 /* TEXT, CLASS */)]), $props.item.children ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 0,
      icon: $props.item.text === $props.openItemText ? 'chevron-down-new-ui' : 'chevron-right-new-ui',
      style: {
        "padding-right": "16px"
      }
    }, null, 8 /* PROPS */, ["icon"])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.children, child => {
      return _openBlock(), _createElementBlock("div", {
        key: child.text
      }, [_createElementVNode("div", {
        class: _normalizeClass([{
          active: $options.isChildActive(child)
        }, "d-flex flex-row align-items-center menu-item-child-link"]),
        onClick: $event => $options.onClick(child)
      }, _toDisplayString(child.text), 11 /* TEXT, CLASS, PROPS */, _hoisted_3)]);
    }), 128 /* KEYED_FRAGMENT */))])], 2 /* CLASS */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["name"]);
}