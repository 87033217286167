import AppIcon from '@/components/AppIcon';
import AppPopover from '@/components/AppPopover';
export default {
  name: 'DemandForecastingRowVelocity',
  components: {
    AppIcon,
    AppPopover
  },
  props: {
    values: {
      type: Array,
      default: () => []
    },
    slowMover: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: null,
      show_velocity_value: false
    };
  },
  computed: {
    max() {
      return Math.max(...this.values);
    },
    avg() {
      if (!Array.isArray(this.values) || this.values.length === 0) {
        return 0;
      }
      const sum = this.values.reduce((acc, value) => acc + value * 1, 0);
      const average = sum / this.values.length;
      return average;
    }
  },
  created() {
    this.id = Math.floor(100000 + Math.random() * 900000).toString();
  },
  methods: {
    getPercent(value) {
      const maxPercent = this.slowMover ? 20 : 80;
      return this.max > 0 ? value * maxPercent / this.max : 0;
    },
    getTitle(index) {
      switch (index) {
        case 0:
          return '7 DAYS';
        case 1:
          return '14 DAYS';
        case 2:
          return '30 DAYS';
        case 3:
          return '90 DAYS';
        default:
          return '';
      }
    },
    formatWithSpace(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }
};