import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterSectionTitle = _resolveComponent("FilterSectionTitle");
  const _component_b_button = _resolveComponent("b-button");
  const _component_FlexRow = _resolveComponent("FlexRow");
  const _component_WrapWithSection = _resolveComponent("WrapWithSection");
  return $props.count > 0 ? (_openBlock(), _createBlock(_component_WrapWithSection, {
    key: 0,
    id: "selected-section",
    background: "#edf5ff",
    selected: "",
    heightFromTop: $props.heightFromTop
  }, {
    title: _withCtx(() => [_createVNode(_component_FilterSectionTitle, {
      title: $props.title,
      count: $props.count,
      searching: $props.searching,
      selected: ""
    }, null, 8 /* PROPS */, ["title", "count", "searching"])]),
    actions: _withCtx(() => [_createVNode(_component_FlexRow, {
      gap: 14,
      classes: "actions"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-primary",
        size: "sm",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('clear'))
      }, {
        default: _withCtx(() => [_createTextVNode(" Clear ")]),
        _: 1 /* STABLE */
      }), _createVNode(_component_b_button, {
        variant: "primary",
        size: "sm",
        onClick: _cache[1] || (_cache[1] = () => {
          _ctx.$emit('apply');
          _ctx.$emit('closeDropdown');
        }),
        disabled: !$props.isModified
      }, {
        default: _withCtx(() => [_createTextVNode(" Apply ")]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"])]),
      _: 1 /* STABLE */
    })]),
    body: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["heightFromTop"])) : _createCommentVNode("v-if", true);
}