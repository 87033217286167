import { mapState, mapMutations } from "vuex";
import AppCardSeo from "@/components/AppCardSeo";
import AppInput from "@/components/AppInput";
import AppPagination from "@/components/AppPagination";
import SeoTopSection from "@/components/SeoTopSection";
import { filterOut, EVENTS } from "@/utils";
import ContentOptimisationTableNew from "../components/seo/ContentOptimisationTableNew.vue";
import ContentOptimisationTable from "../components/seo/ContentOptimisationTable.vue";
export default {
  name: "SEOPage",
  components: {
    AppCardSeo,
    AppInput,
    AppPagination,
    SeoTopSection,
    ContentOptimisationTableNew,
    ContentOptimisationTable
  },
  data() {
    return {
      dataLoading: false,
      search: "",
      editSingleItem: false
    };
  },
  computed: {
    ...mapState({
      pushModal: state => state.content.pushModal,
      total_count: state => state.content.total_count,
      page: state => state.content.page,
      perPage: state => state.content.perPage
    })
  },
  methods: {
    ...mapMutations(["contentPageSet", "contentPerPageSet", "contentsKeyWordListSet"]),
    async changePage(newPage) {
      this.contentPageSet(newPage);
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    async changeElements(elementsPerPage) {
      this.contentPageSet(1);
      this.contentPerPageSet(elementsPerPage);
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    onResetPagingValues() {
      this.contentPageSet(1);
      this.contentPerPageSet(20);
    },
    onDataLoading($event) {
      this.dataLoading = $event;
    },
    onEditSingleItem($event) {
      this.editSingleItem = $event;
    },
    async onPushModalCancel($event) {
      await window.$bus.trigger(EVENTS.SEO_PUSH_MODAL_CANCEL);
    },
    async onPushModalSubmitModifiedContents($event) {
      await window.$bus.trigger(EVENTS.SEO_PUSH_MODAL_SUBMIT_MODIFIED_CONTENT);
    },
    async onPushModal($event) {
      await window.$bus.trigger(EVENTS.SEO_PUSH_MODAL_OPEN);
    },
    async searchItem($event) {
      const value = $event.split(",").filter(v => v.length > 0);
      this.contentsKeyWordListSet(value);
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    async downloadConentsCSV() {
      try {
        let headersMapping = {
          seller_id: "seller_id",
          sku: "sku",
          asin: "asin",
          image_small: "image_small",
          listing_status: "listing_status",
          count_images: "count_images",
          brand: "brand",
          category_id: "category_id",
          category_name: "category_name",
          product_type: "product_type",
          product_name: "product_name",
          generic_keyword: "generic_keyword",
          product_description: "product_description",
          bullet_point_1: "bullet_point_1",
          bullet_point_2: "bullet_point_2",
          bullet_point_3: "bullet_point_3",
          bullet_point_4: "bullet_point_4",
          bullet_point_5: "bullet_point_5",
          push_status: "push_status"
        };
        let csv_headers = this.extractHeaders(headersMapping);
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: "Error generating or downloading CSV" + error,
          visible: true,
          delay: 10
        });
      }
    }
  }
};