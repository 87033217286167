import FilterSectionHeader from '@/components/filters/FilterSectionHeader.vue';
export default {
  name: "PdfExportSelector",
  emits: ['pageSelect', 'periodSelect', 'selectAll', 'clearAll'],
  props: {
    pages: {
      type: Object,
      default: () => {},
      required: true
    },
    periods: {
      type: Object,
      default: () => {},
      required: true
    },
    selectedPages: {
      type: Object,
      default: () => {},
      required: false
    },
    selectedPeriod: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  components: {
    FilterSectionHeader
  },
  computed: {
    disableClear() {
      return Object.keys(this.selectedPages).length === 0;
    }
  }
};