import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loading_element = _resolveComponent("loading-element");
  return _openBlock(), _createElementBlock("div", {
    id: $props.id,
    style: _normalizeStyle($options.computedStyle),
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('click', $event))
  }, [_createVNode(_component_loading_element, {
    loading: $props.loading
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading"])], 12 /* STYLE, PROPS */, _hoisted_1);
}