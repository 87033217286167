import moment from 'moment';
import { processDate } from '../utils';
export default {
  name: 'DatePickerMixin',
  data() {
    return {
      range: 2,
      rangeList: [{
        id: 9,
        title: 'Custom',
        range: {
          start: null,
          end: null
        }
      }],
      compare: 1,
      compareList: [{
        id: 1,
        title: 'Preceding Period'
      }, {
        id: 3,
        title: 'Same Period Last Year'
      }, {
        id: 4,
        title: 'Custom'
      }],
      selectedDate: null,
      monthDate: null,
      hoverDate: null,
      customStartDate: null,
      rangeCustom: {
        start: null,
        end: null
      },
      compareCustom: {
        start: null,
        end: null
      }
    };
  },
  watch: {
    range(val) {
      this.selectedDate = null;
      this.rangeCustom = {
        start: null,
        end: null
      };
      this.compareCustom = {
        start: null,
        end: null
      };
      if (val == 9 && this.customStartDate) {
        this.rangeCustom.start = this.customStartDate;
        this.customStartDate = null;
      }
    },
    compare() {
      this.selectedDate = null;
      this.compareCustom = {
        start: null,
        end: null
      };
    },
    selectedDate() {
      this.monthDate = null;
    }
  },
  computed: {
    currentDate() {
      if (this.monthDate) {
        return this.monthDate;
      }
      if (this.selectedDate) {
        return this.selectedDate;
      }
      if (this.currentRange.range.end) {
        return this.currentRange.range.end;
      } else {
        if (this.currentRange.range.start) {
          return this.currentRange.range.start;
        }
      }
      return moment();
    },
    isRangeCustom() {
      return this.range == 9;
    },
    isCompareCustom() {
      return this.compare == 4;
    },
    currentRange() {
      const tempRange = this.rangeList.find(item => item.id == this.range);
      if (this.isRangeCustom) {
        tempRange.range = this.rangeCustom;
      }
      return tempRange;
    },
    currentCompare() {
      const tempCompare = this.compareList.find(item => item.id == this.compare);
      tempCompare.range = this.compareRange;
      //console.log("Current Compare", tempCompare)
      return tempCompare;
    },
    compareRange() {
      switch (this.range) {
        case 5:
          return {
            start: this.compareStartLastMonth,
            end: this.compareEndLastMonth
          };
        case 8:
          return {
            start: this.compareStartLast12Months,
            end: this.compareEndLast12Months
          };
        case 12:
          return {
            start: this.compareStartCurrentMonth,
            end: this.compareEndCurrentMonth
          };
        default:
          return {
            start: this.compareStart,
            end: this.compareEnd
          };
      }
    },
    compareStart() {
      const {
        start,
        end
      } = this.currentRange.range;
      if (this.compare === 4) {
        return this.compareCustom.start;
      }
      if (this.compare === 5) return moment(start).subtract(1, 'week').startOf('isoWeek');
      if (start && end) {
        const daysDiff = end.diff(start, 'days');
        return moment(this.compareEnd).subtract(daysDiff, 'days');
      }
      return null;
    },
    compareEnd() {
      const {
        start,
        end
      } = this.currentRange.range;
      if (start && end) {
        switch (this.compare) {
          case 1:
            {
              const variant1 = moment(end).subtract(7, 'days');
              const diff = end.diff(start, 'days') % 7;
              const variant2 = moment(start).subtract(7 - diff, 'days');
              return moment.min(variant1, variant2);
            }
          case 2:
            return moment(start).subtract(1, 'day');
          case 3:
            return moment(end).subtract(1, 'year');
          case 5:
            return moment.min(moment().subtract(1, 'days').subtract(1, 'weeks'), moment(end));
          case 6:
            /* Same Period LY (match to date) */
            /*
                 {01/06/2024, 24/06/2024}
             */
            return;
        }
      }
      if (this.compare === 4) {
        return this.compareCustom.end;
      }
      return null;
    },
    compareStartLastMonth() {
      if ([1, 2].includes(this.compare)) {
        return moment().subtract(2, 'months').date(1);
      }
      if (this.compare == 3) {
        return moment().subtract(1, 'year').subtract(1, 'month').date(1);
      }
      return null;
    },
    compareEndLastMonth() {
      if ([1, 2].includes(this.compare)) {
        return moment().subtract(2, 'months').endOf('month');
      }
      if (this.compare == 3) {
        return moment().subtract(1, 'year').subtract(1, 'month').endOf('month');
      }
      return null;
    },
    compareStartLast12Months() {
      if (this.compare == 1 || this.compare == 2) {
        return moment().subtract(2, 'years').date(1);
      }
      if (this.compare == 3) {
        return moment().subtract(2, 'years').date(1);
      }
      return null;
    },
    compareEndLast12Months() {
      if ([1, 2, 3].includes(this.compare)) {
        return moment().subtract(1, 'year').subtract(1, 'month').endOf('month');
      }
      return null;
    },
    compareStartCurrentMonth() {
      if ([1, 2, 5].includes(this.compare)) {
        return moment().subtract(1, 'days').startOf('month').subtract(1, 'months');
      }
      if (this.compare == 3) {
        return moment().subtract(1, 'year').subtract(1, 'month').date(1);
      }
      return null;
    },
    compareEndCurrentMonth() {
      if ([1, 2].includes(this.compare)) {
        return moment().subtract(1, 'month');
      }
      if (this.compare == 3) {
        return moment().subtract(1, 'year').subtract(1, 'month').endOf('month');
      }
      if (this.compare === 5) {
        return moment().subtract(1, 'day').subtract(1, 'month');
      }
      return null;
    }
  },
  methods: {
    setRange(range) {
      this.range = range;
    },
    setCompare(compare) {
      this.compare = compare;
    },
    setRangeWithTitle(range) {
      this.range = this.getRangeAndCompareIds({
        range
      }).rangeId;
    },
    setCompareWithTitle(compare) {
      this.compare = this.getRangeAndCompareIds({
        compare
      }).compareId;
    },
    getRangeAndCompareIds({
      range = 'Last 7 days',
      compare = {
        title: 'Preceding Period'
      }
    } = {}) {
      // Get the range object from the rangeList
      const rangeObj = this.rangeList.find(item => item.title === range);
      if (!rangeObj) {
        console.error(`Range ${range} not found`);
        return;
      }

      // Get the compare object from the compareList
      const compareObj = this.compareList.find(item => item.title === compare.title);
      if (!compareObj) {
        console.error(`Compare ${compare} not found`);
        return;
      }
      return {
        rangeId: rangeObj.id,
        compareId: compareObj.id
      };
    },
    getDateFromRangeCompare({
      range = 'Last 7 days',
      compare = {
        title: 'Preceding Period'
      }
    } = {}) {
      // Use `processDate` with format YYYY-MM-DD to format 
      const format = 'YYYY-MM-DD';

      // Make selections with `this.range` and `this.compare`
      const res = this.getRangeAndCompareIds({
        range,
        compare
      });
      if (!res) {
        return;
      }
      const {
        rangeId,
        compareId
      } = res;
      this.range = rangeId;
      this.compare = compareId;
      return {
        title: range,
        range: {
          start: processDate(this.currentRange.range.start, format),
          end: processDate(this.currentRange.range.end, format)
        },
        compare: {
          start: processDate(this.currentCompare.range.start, format),
          end: processDate(this.currentCompare.range.end, format)
        },
        compareTitle: {
          title: this.currentCompare.title
        }
      };
    },
    getDateData() {
      return {
        title: this.currentRange.title,
        range: {
          start: this.currentRange.range.start,
          end: this.currentRange.range.end
        },
        compare: {
          start: this.currentCompare.range.start,
          end: this.currentCompare.range.end
        },
        compareTitle: {
          title: this.currentCompare.title
        }
      };
    },
    defaultDate() {
      this.range = 2;
      this.compare = 1;
      return {
        ...this.getDateData(),
        compareTitle: {
          title: this.currentCompare.title,
          subtitle: this.currentCompare.subtitle
        }
      };
    },
    getDateFromCustom(range, compare, cast = true, format = 'YYYY-MM-DD') {
      // Create moment dates from start and end
      range.start = moment(range.start);
      range.end = moment(range.end);
      compare.start = moment(compare.start);
      compare.end = moment(compare.end);

      // We need to set the range to custom and compare to Preceding Period
      this.range = 9;
      this.compare = 4;

      // We need to set the start date to the custom start date
      this.rangeCustom.start = range.start;
      this.rangeCustom.end = range.end;
      this.customStartDate = range.start;
      this.compareCustom.start = compare.start;
      this.compareCustom.end = compare.end;

      // Here, we use whichever compare is selected
      const currentDate = this.getDateData();
      if (!cast) return currentDate;
      return {
        title: currentDate.title,
        range: {
          start: processDate(currentDate.range.start, format),
          end: processDate(currentDate.range.end, format)
        },
        compare: {
          start: processDate(currentDate.compare.start, format),
          end: processDate(currentDate.compare.end, format)
        },
        compareTitle: {
          title: currentDate.compareTitle.title,
          subtitle: currentDate.compareTitle.subtitle
        }
      };
    },
    createDatePickerDate(date) {
      /*
          This is a simple method that creates a `DatePicker` date.
          Here, a date picker date consists of these items:
           - title: The title of the date picker (eg. Last 12 weeks, Yesterday, ...)
          - range: Range contains {start_date, end_date}. These either `moment` dates or date strings
          - compare: Compare contains {start_date, end_date}. These either `moment` dates or date strings
          - selector: Selector contains {title, start, end}. These are used to display the date picker date
                      Selector is used particularly for the UI to display the date picker date
                      This is separated so that we could change the format of the display.
          - API_ELEMENTS: API_ELEMENTS contains {range, compare}. These are used to reset the date picker
                          This is separated so that we could change the format of the API call.        
       */
      return {
        ...date,
        selector: {
          title: date?.title,
          start: {
            date: date?.range.start,
            format: "MMM DD"
          },
          end: {
            date: date?.range.end,
            format: "MMM DD YYYY"
          }
        },
        API_ELEMENTS: {
          range: date?.title,
          compare: date?.compareTitle
        }
      };
    }
  }
};