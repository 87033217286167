import GroupBy from "@/components/GroupBy.vue";
export default {
  name: "AggregateTableGroupBy",
  components: {
    GroupBy
  },
  emits: ["setAggregateGroupBy"],
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      groupByIndex: 0,
      groupByElements: []
    };
  },
  mounted() {
    // TODO: Use assignment approach similar to {ppc, dashboard_v2}
    // Refer to their stores for how it's done.
    this.groupByElements = [{
      name: "Aggregate",
      key: "aggregate"
    }];
  },
  computed: {
    groupBy: {
      set(val) {
        this.groupByIndex = val;
        this.$emit("setAggregateGroupBy", this.groupByElements[val], true);
      },
      get() {
        return this.groupByIndex;
      }
    }
  },
  methods: {
    groupBySelected(el) {
      this.$emit("setAggregateGroupBy", el, true);
    }
  }
};