export default {
  name: "EmptySpace",
  props: {
    text: {
      type: String,
      default: 'Sorry, no data available'
    },
    size: {
      type: String,
      default: '200'
    },
    wrapperHeight: {
      type: String,
      default: '400px'
    }
  }
};