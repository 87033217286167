import { request } from './index';
const workspace = {
  state: {
    list: [],
    current_workspace: {},
    isOmnyWorkspace: null,
    totalPages: 0,
    scaleType: ""
  },
  getters: {
    workspaceById(state) {
      return id => {
        for (const workspace of state.list) {
          if (workspace.workspace_id == id) {
            return workspace;
          }
        }
        return null;
      };
    },
    workspaceNameById(state) {
      return id => {
        for (const workspace of state.list) {
          if (workspace.workspace_id == id) {
            return workspace.workspace_name;
          }
        }
        return '';
      };
    },
    getIsOmnyWorkspace: () => () => {
      return {
        isOmnyWorkspace: workspace.state.isOmnyWorkspace
      };
    },
    workspaceGetTotalPages(state) {
      return () => {
        return state.totalPages;
      };
    }
  },
  mutations: {
    workspaceListSet(state, payload) {
      state.list = payload;
    },
    workspaceCurrentSet(state, payload) {
      state.current_workspace = payload;
      state.isOmnyWorkspace = payload.workspace_name == "All Accounts";
    },
    setWorkspaceTotalPages(state, payload) {
      state.totalPages = payload;
    },
    setScaleType(state, payload) {
      state.scaleType = payload;
    }
  },
  actions: {
    async workspaceList({
      commit
    }) {
      const data = await request('/user/workspaces');
      if (data) {
        commit('workspaceListSet', data.workspaces || []);
        commit('setWorkspaceTotalPages', data.workspaces.length || 0);
      }
      return data;
    },
    async workspaceCurrent({
      commit,
      dispatch
    }) {
      const data = await request('/user/current/workspace', 'POST');
      if (data) {
        commit('workspaceCurrentSet', data);
        commit('setScaleType', data.workspace_unit_type);
        //console.log("workspaceCurrent", data);
        dispatch('currencyConfigSetCurrency', data.workspace_currency);
        dispatch('setWeekTypeConfig', data.workspace_week_type);
      }
      return data;
    },
    async workspaceChange(_, payload) {
      const data = await request('/user/change/workspace', 'POST', payload);
      if (data) {
        window.$bus.trigger('refreshWorkspaceList');
      }
      return data;
    },
    async workspaceRemove(_, payload) {
      const data = await request('/workspaces/delete', 'POST', payload);
      if (data) {
        window.$bus.trigger('refreshWorkspaceList');
      }
      return data;
    },
    async workspaceCreate(_, payload) {
      const data = await request('/workspaces/create', 'POST', payload);
      if (data) {
        window.$bus.trigger('refreshWorkspaceList');
      }
    },
    async workspaceUpdate(_, payload) {
      const data = await request('/workspaces/update', 'POST', payload);
      if (data) {
        window.$bus.trigger('refreshWorkspaceList');
      }
    },
    async setScaleTypeFromUser({
      commit
    }, payload) {
      //console.log("[payload to set scaletype]")
      commit('setScaleType', payload);
    }
  }
};
export default workspace;