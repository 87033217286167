import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FilterSectionTitle = _resolveComponent("FilterSectionTitle");
  const _component_b_button = _resolveComponent("b-button");
  const _component_WrapWithSection = _resolveComponent("WrapWithSection");
  return $props.count > 0 ? (_openBlock(), _createBlock(_component_WrapWithSection, {
    key: 0,
    id: "list-section",
    heightFromTop: $props.heightFromTop
  }, _createSlots({
    title: _withCtx(() => [_createVNode(_component_FilterSectionTitle, {
      title: $props.title,
      count: $props.count,
      searching: $props.searching
    }, null, 8 /* PROPS */, ["title", "count", "searching"])]),
    body: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 2 /* DYNAMIC */
  }, [$props.searching && $props.count > 0 ? {
    name: "actions",
    fn: _withCtx(() => [_createVNode(_component_b_button, {
      variant: "outline-primary",
      size: "sm",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('selectAll'))
    }, {
      default: _withCtx(() => [_createTextVNode(" Select All ")]),
      _: 1 /* STABLE */
    })]),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["heightFromTop"])) : _createCommentVNode("v-if", true);
}