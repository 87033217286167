import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_radio_group = _resolveComponent("b-form-radio-group");
  return _openBlock(), _createBlock(_component_b_form_radio_group, {
    class: "radio-group",
    buttons: "",
    modelValue: $options.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.selected = $event),
    options: $props.options
  }, null, 8 /* PROPS */, ["modelValue", "options"]);
}