import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      width: `${$props.width}px`,
      height: `${$props.height}px`,
      background: $props.color,
      borderRadius: '8px',
      border: `1px solid ${$props.color}`
    })
  }, null, 4 /* STYLE */);
}