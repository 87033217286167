import AppIcon from "@/components/AppIcon.vue";
import FlexRow from "@/components/FlexRow.vue";
export default {
  name: "CampaignsCount",
  components: {
    AppIcon,
    FlexRow
  },
  props: {
    count: {
      type: Number,
      default: 0
    }
  }
};