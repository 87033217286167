import FlexRow from '@/components/FlexRow.vue';
import AppIcon from '@/components/AppIcon.vue';
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
export default {
  name: "AppIconGroup",
  components: {
    FlexRow,
    AppIcon,
    WrapWithPopover
  },
  props: {
    icons: {
      type: Array,
      required: true,
      default: () => []
    },
    color: {
      type: String,
      required: false,
      default: "#000000"
    },
    gap: {
      type: Number,
      required: false,
      default: 8
    }
  },
  methods: {
    handleClick(e, click = () => {}) {
      e.stopPropagation();
      if (this.disabled) {
        return;
      }
      click(e);
    }
  }
};