import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4b36826a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "calendar"
};
const _hoisted_2 = {
  class: "days-wrapper"
};
const _hoisted_3 = {
  class: "calendar-grid"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_date_picker_monthes = _resolveComponent("date-picker-monthes");
  const _component_date_picker_days = _resolveComponent("date-picker-days");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_date_picker_monthes, {
    first: $props.monthList[0]?.date,
    last: $props.monthList[$props.monthList.length - 1]?.date,
    date: $options.monthDate,
    onMonthChange: $options.onMonthChange
  }, null, 8 /* PROPS */, ["first", "last", "date", "onMonthChange"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_date_picker_days)]), _createElementVNode("div", {
    class: "calendar-scroll",
    ref: "scroll",
    onWheel: _cache[0] || (_cache[0] = (...args) => $options.onWheel && $options.onWheel(...args))
  }, [_createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default", {
    range: $props.range,
    compare: $props.compare,
    monthList: $props.monthList,
    state: $props.state
  }, undefined, true)])], 544 /* NEED_HYDRATION, NEED_PATCH */)]);
}