import LoadingElement from "@/components/shared/LoadingElement.vue";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import FlexRow from "@/components/FlexRow.vue";
import AppIcon from "@/components/AppIcon.vue";
export default {
  name: "KpiCard",
  props: {
    name: {
      type: String
    },
    percentage: {
      type: Number
    },
    subtitle: {
      type: String
    },
    subtitleAmount: {
      type: Number
    },
    loading: {
      type: Boolean,
      default: false
    },
    kpiId: {
      type: String
    },
    trendDirection: {
      type: String,
      default: "up"
    },
    percentageColor: {
      type: String,
      default: "#312783"
    }
  },
  computed: {
    updatedName() {
      return this.name?.toUpperCase();
    }
  },
  components: {
    LoadingElement,
    WrapWithPopover,
    FlexRow,
    AppIcon
  }
};