import KpisControllersSection from '@/components/KpisControllersSection';
import KpisRenderer from '@/components/KpisRenderer';
import PdfExportPopup from '@/components/PdfExportPopup';
import { periodsSwitches } from '@/components/shared/chartHelpers';
import { ProductPricingMarginPage } from '@/pages/subpages';
import DashboardMixin from '@/mixins/DashboardMixin';
import DatePickerMixin from '@/mixins/DatePickerMixin';
import LoadingTable from '@/components/LoadingTable';
import { mapState } from 'vuex';
import { EVENTS } from "@/utils/constants";
import moment from 'moment';
import { getDatePickerUIObjectFromDateRange, DATE_PICKER_COMPARE_TITLES, getDatePickerRangeCompare, DATE_PICKER_RANGE_TITLES } from '@/utils/datepicker';
export default {
  name: 'PricingAndMarginPage',
  async created() {
    this.$store.dispatch('dashboardSetCurrentTab', 'Product');
    this.$store.dispatch('dashboardSetCurrentView', 'Pricing and Margin');
    const sellers = await this.getOmnyAccountSellers();
    this.omnyAccountSellersCount = sellers.length;
    this.omnyAccountHasMoreThanOneSeller = sellers.length > 1;
    await this.initializeFilters({
      sellers
    });

    // Set the default date picker date
    this.defaultDatePickerDate = getDatePickerUIObjectFromDateRange({
      ...getDatePickerRangeCompare(DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD, DATE_PICKER_RANGE_TITLES.LAST_7_DAYS),
      rangeTitle: DATE_PICKER_RANGE_TITLES.LAST_7_DAYS,
      compareTitle: DATE_PICKER_COMPARE_TITLES.PRECEDING_PERIOD
    });
    this.storeDatePickerDate(this.defaultDatePickerDate);
    this.homepageLoading = false;
  },
  components: {
    KpisRenderer,
    KpisControllersSection,
    PdfExportPopup,
    ProductPricingMarginPage,
    LoadingTable
  },
  mixins: [DashboardMixin],
  data() {
    return {
      settingUp: false,
      countries: [],
      sellers: [],
      omnyAccountSellersCount: 0,
      omnyAccountHasMoreThanOneSeller: false,
      currentViewMinMax: {},
      tableLoading: true,
      period: 'Weekly',
      currentPeriod: periodsSwitches.DEFAULT[0],
      homepageLoading: true,
      defaultDatePickerDate: null
    };
  },
  computed: {
    ...mapState({
      datePickerDate: state => state.period.datePickerPeriod
    }),
    getUpdatedTabFilters() {
      return this.getTabFilters({
        omnyAccountHasMoreThanOneSeller: this.omnyAccountHasMoreThanOneSeller,
        refreshTrigger: this.refreshTriggers[this.currentTab][this.currentView]
      });
    },
    getUpdatedPages() {
      return this.pages({
        omnyAccountHasMoreThanOneSeller: this.omnyAccountHasMoreThanOneSeller
      });
    }
  },
  methods: {
    storeDatePickerDate(date) {
      this.$store.dispatch("datePickerSetPeriod", date);
      window.$bus.trigger(EVENTS.RESET_DATE_PICKER, date);
    },
    changePeriod(date) {
      this.storeDatePickerDate(date);
      window.$bus.trigger(EVENTS.PRODUCT_PRICING_MARGIN);
    }
  }
};