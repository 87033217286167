import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_loading_element = _resolveComponent("loading-element");
  return _openBlock(), _createBlock(_component_loading_element, {
    class: "h-100",
    loading: $props.loading
  }, {
    default: _withCtx(() => [_createVNode(_component_flex_row, {
      styles: {
        width: '100%',
        justifyContent: 'flex-start'
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_boxed_value, {
        bgColor: $props.bgColor,
        loading: $props.loading,
        styles: {
          width: '86px'
        }
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "box-value")]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["bgColor", "loading"]), _createVNode(_component_bar_with_value, {
        style: {
          width: '100%'
        },
        reverse: "",
        align: "right",
        value: $props.value,
        min: $props.min,
        max: $props.max,
        color: "#F1959C"
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "bar-value")]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["value", "min", "max"])]),
      _: 3 /* FORWARDED */
    })]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading"]);
}