import FlexRow from '@/components/FlexRow.vue';
import AggregateTableHeader from '@/components/profit-and-loss/AggregateTableHeader.vue';
import DataAndEvolutionValue from '@/components/profit-and-loss/DataAndEvolutionValue.vue';
import PercentNetRevenueValue from '@/components/profit-and-loss/PercentNetRevenueValue.vue';
export default {
  name: 'ProfitAndLossTable',
  components: {
    FlexRow,
    DataAndEvolutionValue,
    AggregateTableHeader,
    PercentNetRevenueValue
  },
  props: {
    items: {
      type: Array
    },
    colGroups: {
      type: Array
    },
    fields: {
      type: Array
    },
    minMax: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  computed: {
    aggregatePeriod() {
      return this.$store.getters.profitAndLossGetAggregatePeriod();
    },
    period1() {
      return this.aggregatePeriod.range.start;
    },
    period2() {
      return this.aggregatePeriod.compare.start;
    }
  },
  methods: {
    handleTogglePercentAggregate(index) {
      //console.log(this.items)

      if (this.$refs[`percent-net-${index}`]) {
        this.$refs[`percent-net-${index}`][0].toggleAggregate();
        // Using JS, let's add `left, active, right` classes to the aggregation row
        // Let's use `refs` to fetch these elements
        // Then, let's add the classes to the elements

        const addOrRemoveClassFromClassList = (el, className) => {
          // If it exists, remove it
          if (el.classList.contains(className)) {
            el.classList.remove(className);
          } else {
            el.classList.add(className);
          }
        };
        const active = this.$refs[`aggregation-${index}`][0];
        if (active) {
          addOrRemoveClassFromClassList(active, 'active');
        }
        const left = this.$refs[`aggregation-${index - 1}`]?.[0];
        if (left) {
          addOrRemoveClassFromClassList(left, 'left');
        }
        const right = this.$refs?.[`aggregation-${index + 1}`]?.[0];
        if (right) {
          addOrRemoveClassFromClassList(right, 'right');
        }
      }
    },
    handleTogglePercentDetails(index, detailIndex) {
      if (this.$refs[`percent-net-${index}`]) {
        this.$refs[`percent-net-${index}`][0].toggleDetails(detailIndex);
      }
    },
    getType(name) {
      const typeMap = {
        'Net Revenue': 'cumlative',
        'Gross Margin': 'cumlative',
        'Quantity': 'cumlative',
        'Contributive Margin': 'cumlative'
      };
      return typeMap[name] ?? 'normal';
    }
  }
};