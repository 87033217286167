import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  class: "range-row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_button_radio_group = _resolveComponent("app-button-radio-group");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['range-container', {
      compare: $props.isCompare
    }]),
    ref: "range"
  }, [_createElementVNode("p", _hoisted_1, [_renderSlot(_ctx.$slots, "title"), _ctx.$slots['detail'] ? (_openBlock(), _createElementBlock("b", _hoisted_2, [_renderSlot(_ctx.$slots, "detail")])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_app_button_radio_group, {
    options: $props.options,
    modelValue: $options.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.selected = $event)
  }, null, 8 /* PROPS */, ["options", "modelValue"])])], 2 /* CLASS */);
}