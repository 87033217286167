import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TextValue = _resolveComponent("TextValue");
  const _component_WrapWithCopy = _resolveComponent("WrapWithCopy");
  const _component_FlexColumn = _resolveComponent("FlexColumn");
  const _component_RegionValue = _resolveComponent("RegionValue");
  const _component_FlexRow = _resolveComponent("FlexRow");
  const _component_WrapWithSectionItem = _resolveComponent("WrapWithSectionItem");
  return _openBlock(), _createBlock(_component_WrapWithSectionItem, {
    selected: $props.selected,
    onSelect: _cache[0] || (_cache[0] = $event => _ctx.$emit('select', $props.item)),
    onDeselect: _cache[1] || (_cache[1] = $event => _ctx.$emit('deselect', $props.item)),
    onSelectOnly: _cache[2] || (_cache[2] = $event => _ctx.$emit('selectOnly', $props.item))
  }, {
    default: _withCtx(() => [_createVNode(_component_FlexRow, {
      styles: {
        justifyContent: 'space-between',
        height: 'auto',
        width: '100%'
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_FlexColumn, null, {
        default: _withCtx(() => [_createVNode(_component_TextValue, {
          value: $props.name,
          styles: {
            lineHeight: '19.2px',
            fontWeight: 500
          },
          size: 14
        }, null, 8 /* PROPS */, ["value"]), _createVNode(_component_WrapWithCopy, null, {
          default: _withCtx(() => [_createVNode(_component_TextValue, {
            value: $props.id,
            styles: {
              lineHeight: '18.2px',
              fontWeight: 500
            },
            size: 13,
            color: "#999999"
          }, null, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      }), _createVNode(_component_RegionValue, {
        value: $props.region
      }, null, 8 /* PROPS */, ["value"])]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["selected"]);
}