import FlexRow from "@/components/FlexRow.vue";
export default {
  name: "CurrentPeriod",
  props: {
    currentPeriod: {
      type: Object,
      default: () => ({
        name: "Current Period",
        period: "Oct 2, 2023",
        format: "MMM D, YYYY"
      })
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FlexRow
  }
};