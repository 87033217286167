import AppCheckbox from '@/components/AppCheckbox';
import AppSwitch from '@/components/AppSwitch';
import AppIcon from '@/components/AppIcon';
import DemandForecastingRowVelocity from '@/components/DemandForecastingRowVelocity';
import DemandForecastingRowInventory from '@/components/DemandForecastingRowInventory';
import DemandForecastingRowManual from '@/components/DemandForecastingRowManual';
import DemandForecastingMixins from "@/mixins/DemandForecastingMixins.vue";
export default {
  name: 'DemandForecastingRow',
  emits: ['select', 'orderable', 'orderable-units'],
  mixins: [DemandForecastingMixins],
  components: {
    AppCheckbox,
    AppSwitch,
    AppIcon,
    DemandForecastingRowVelocity,
    DemandForecastingRowInventory,
    DemandForecastingRowManual
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {
    velocity() {
      return [this.item.daily_velocity_90D, this.item.daily_velocity_30D, this.item.daily_velocity_14D, this.item.daily_velocity_7D];
    },
    realSelected() {
      return this.item.orderable && this.selected;
    },
    isSlowMover() {
      return this.isSlowMoverRule(this.item, this.settings);
    },
    additionalBoost() {
      this.onSettings();
      return Math.round(this.additionalBoostRuleForItem[this.settings.boost_type](this.item, this.settings));
    },
    weighted30DSalesForecast() {
      return Math.round(this.weighted30DSalesForecastRule(this.item, this.settings));
    },
    daysLeft() {
      let result = Math.round(this.daysLeftRule(this.item, this.settings));
      return result;
    },
    totalCoverage() {
      let result = this.totalCoverageFromSettings(this.settings);
      return result;
    },
    rawQuantityToSend() {
      return Math.round(this.rawQuantityToSendRule(this.item, this.settings));
    },
    rawQuantityToSendDays() {
      return Math.round(this.rawQuantityToSendDaysRule(this.item, this.settings));
    }
  },
  methods: {
    onSelect() {
      if (this.item.orderable) {
        this.$emit('select', this.item.sku);
      }
    },
    onOrderable() {
      this.$emit('orderable', this.item);
    },
    async onClearManualOverride() {
      this.item.orderable_units = 0;
      this.item.manual_override = false;
      this.$emit('orderable-units', this.item);
    },
    onOrderableUnits($event) {
      //console.log('onOrderableUnits')
      this.item.manual_override = true;
      this.$emit('orderable-units', this.item);
    },
    onSettings() {
      // //console.log('Settings')
      // //console.log(this.settings)
      // //console.log(this.item.sku)
      // //console.log(this.item)
      // //console.log(this.settings.manual_boost_value.value)
    }
  }
};