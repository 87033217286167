import DatePickerLayout from '@/components/DatePickerLayout.vue';
import DatePickerSidebarSection from './DatePickerSidebarSection.vue';
import MultiModeDatePickerSelectedPeriod from './MultiModeDatePickerSelectedPeriod.vue';
import { DateValue } from '@/components/tableElements';
import DatePickerPeriodPreview from './DatePickerPeriodPreview.vue';
import { generateMonthsBetweenYears, generateYearsBetweenRange, generateQuartersFromYear } from '@/utils/datepicker.js';
import moment from 'moment';
import MultiModeDatePickerSelectorItem from './MultiModeDatePickerSelectorItem.vue';
import MultiModeDatePickerQuarterSelectorItem from './MultiModeDatePickerQuarterSelectorItem.vue';
import * as R from 'ramda';
import FlexRow from "@/components/FlexRow.vue";
export default {
  name: 'MultiModeDatePicker',
  components: {
    DatePickerLayout,
    DatePickerSidebarSection,
    MultiModeDatePickerSelectedPeriod,
    DatePickerPeriodPreview,
    DateValue,
    MultiModeDatePickerSelectorItem,
    MultiModeDatePickerQuarterSelectorItem,
    FlexRow
  },
  mounted() {
    this.currentDate = moment();
    this.generatedYears.monthly = this.monthlyGeneratedYears(this.currentDate);
    this.generatedYears.quarterly = this.quarterlyGeneratedYears(this.currentDate);
    this.generatedYears.yearly = this.yearlyGeneratedYears(this.currentDate);
  },
  emits: ['submit', 'cancel'],
  expose: ['state', 'reset'],
  data() {
    return {
      range: 'monthly',
      compare: 'preceding_period',
      currentDate: null,
      currentYear: moment(),
      previousState: {},
      formats: {
        monthly: 'MMMM YYYY',
        quarterly: 'Q - YYYY',
        yearly: 'YYYY'
      },
      bodyFormats: {
        monthly: 'MMM',
        quarterly: 'MMM',
        yearly: 'YYYY'
      },
      uiFormats: {
        monthly: 'MMM YYYY',
        quarterly: 'Q YYYY',
        yearly: 'YYYY'
      },
      previousPeriodFormats: {
        monthly: "MMM YYYY",
        quarterly: "Q - YYYY",
        yearly: "YYYY"
      },
      generatedYears: {
        monthly: [],
        quarterly: [],
        yearly: []
      },
      periodOptions: [{
        key: 'monthly',
        name: "Monthly"
      }, {
        key: 'quarterly',
        name: "Quarterly"
      }, {
        key: 'yearly',
        name: "Yearly"
      }],
      compareOptions: [{
        key: 'preceding_period',
        name: "Preceding Period"
      }, {
        key: 'same_period_last_year',
        name: "Same period last year"
      }]
    };
  },
  computed: {
    activeMonth() {
      return date => this.currentDate.isSame(date, 'year') && this.currentDate.isSame(date, 'month');
    },
    activeQuarter() {
      return date => this.currentDate.isSame(date, 'year') && this.currentDate.isSame(date, 'quarter');
    },
    activeYear() {
      return date => this.currentDate.isSame(date, 'year');
    },
    activePreviousMonth() {
      /*
          For every active month, there are current `2` cases for previous month:
              - preceding month ,which is month -1 of the current year
              - same period last year, which is month of the previous year
      */

      return date => {
        const precedingMonth = this.currentDate.clone().subtract(1, 'month');
        const samePeriodLastYear = this.currentDate.clone().subtract(1, 'year');
        return this.compare === 'preceding_period' ? date.isSame(precedingMonth, 'month') : date.isSame(samePeriodLastYear, 'month');
      };
    },
    activePreviousQuarter() {
      return date => {
        const precedingQuarter = this.currentDate.clone().subtract(1, 'quarter');
        const samePeriodLastYear = this.currentDate.clone().subtract(1, 'year');
        return this.compare === 'preceding_period' ? date.isSame(precedingQuarter, 'quarter') : date.isSame(samePeriodLastYear, 'quarter');
      };
    },
    activePreviousYear() {
      return date => {
        const precedingYear = this.currentDate.clone().subtract(1, 'year');
        return date.isSame(precedingYear, 'year');
      };
    },
    currentActive() {
      return date => {
        const actives = {
          monthly: this.activeMonth(date),
          quarterly: this.activeQuarter(date),
          yearly: this.activeYear(date)
        };
        return actives[this.range];
      };
    },
    state() {
      /* 
          We need to return an object like this:
          {
              range: {
                  start: moment,
                  end: moment
              },
              compare: {
                  start: moment,
                  end: moment
              }
          }
            Range: 
              - start: first day of the first current date month
              - end: last day of the last current date month
           Compare:
              - start: first day of the first current date month
              - end: last day of the last current date month
          Extra data
              - Here we can provide a structure that will be used to render the date picker
       */
      const ranges = {
        monthly: 'month',
        quarterly: 'quarter',
        yearly: 'year'
      };
      const period = ranges[this.range];
      if (!this.currentDate) return;
      const start = this.currentDate.clone().startOf(period);
      const end = this.currentDate.clone().endOf(period);
      const compareStart = this.compare === 'preceding_period' ? start.clone().subtract(1, period) : start.clone().subtract(1, 'year');
      const compareEnd = this.compare === 'preceding_period' ? end.clone().subtract(1, period) : end.clone().subtract(1, 'year');
      //console.log("Period Options: ", this.periodOptions.find(({ key }) => key === this.range).name)
      return {
        key: this.range,
        compareKey: this.compare,
        range: {
          start: start.format('YYYY-MM-DD'),
          end: end.format('YYYY-MM-DD')
        },
        compare: {
          start: compareStart.format('YYYY-MM-DD'),
          end: compareEnd.format('YYYY-MM-DD')
        },
        title: this.periodOptions.find(({
          key
        }) => key === this.range).name,
        start: {
          date: start.format('YYYY-MM-DD'),
          format: this.uiFormats[this.range]
        },
        end: {
          date: compareStart.format('YYYY-MM-DD'),
          format: this.uiFormats[this.range]
        }
      };
    },
    disableDate() {
      return date => {
        const _today = moment();
        // if year of the date is after current year, return true
        // if month of the date is after current month, return true.
        // if quarter of the date is afte current quarter, return true;
        // other wise, return false;

        if (date.isAfter(_today, "year")) return true;
        if (date.isAfter(_today, "month")) return true;
        return false;
      };
    },
    disableQuarter() {
      return quarter => {
        const _today = moment();
        // if quarter of the date is afte current quarter, return true;
        // other wise, return false;

        if (_today.isAfter(quarter?.months[0]?.date, "quarter")) return true;
        return false;
      };
    }
  },
  methods: {
    handlePrevious() {
      const res = this.currentDate.clone().subtract(1, 'year');
      this.generatedYears.yearly = this.yearlyGeneratedYears(res);
      this.currentDate = res;
      this.generatedYears.monthly = this.monthlyGeneratedYears(res);
      this.generatedYears.quarterly = this.quarterlyGeneratedYears(res);
    },
    handleNext() {
      const res = this.currentDate.clone().add(1, 'year');
      this.generatedYears.yearly = this.yearlyGeneratedYears(res);
      if (res.isSameOrBefore(this.currentYear, 'year')) {
        this.currentDate = res;
        this.generatedYears.monthly = this.monthlyGeneratedYears(res);
        this.generatedYears.quarterly = this.quarterlyGeneratedYears(res);
      }
    },
    handleDateChange(date) {
      this.currentDate = date;
      if (this.range === 'yearly') {
        this.generatedYears.monthly = this.monthlyGeneratedYears(this.currentDate);
        this.generatedYears.quarterly = this.quarterlyGeneratedYears(this.currentDate);
      }
    },
    handleSubmit() {
      this.$emit('submit', this.state);
    },
    handleCancel() {
      this.$emit('cancel');
    },
    monthlyGeneratedYears(year) {
      return R.splitEvery(3, generateMonthsBetweenYears(year.year(), year.year()));
    },
    quarterlyGeneratedYears(year) {
      return generateQuartersFromYear(generateMonthsBetweenYears(year.year(), year.year()));
    },
    yearlyGeneratedYears(year) {
      return R.splitEvery(3, generateYearsBetweenRange(year.clone().subtract(4, 'year').year(), year.clone().add(7, 'year').year()));
    },
    reset(date) {
      /*
           This is a method to reset the date picker to some state. (not only the initial state)
           Argument Requirements:
              - date: object
                  - range:
                      - start: string
                      - end: string
                   - compare:
                      - start: string
                      - end: string
                  - key: string [monthly, quarterly, yearly]
                  - compareKey: string [preceding_period, same_period_last_year]
           Since the `state` and other computed properties depend on `currentDate`, we need to update the `currentDate` to the `range.start` date
           We ignore this update when the date received is the same one as the current date
      */
      if (date.range.start !== this.currentDate.format('YYYY-MM-DD')) {
        this.currentDate = moment(date.range.start);
      }
      if (date.key !== this.range) {
        this.range = date.key;
      }
      if (date.compareKey !== this.compare) {
        this.compare = date.compareKey;
      }
    }
  }
};