import { HugValue, PercentageValue } from '../tableElements';
import ColorfulBigBar from '@/components/tableElements/bars/ColorfulBigBar.vue';
import FlexRow from '@/components/FlexRow.vue';
export default {
  name: 'DataAndEvolutionRowValue',
  components: {
    HugValue,
    FlexRow,
    PercentageValue,
    ColorfulBigBar
  },
  props: {
    value: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    thStyles: {
      type: Array
    },
    level: {
      type: Number,
      default: 1
    },
    fields: {
      type: Array
    },
    minMax: {
      type: Object
    }
  },
  computed: {
    upperLevels() {
      return [1, 2].includes(this.level);
    },
    showEvolution() {
      return this.value.priority >= 100;
    }
  }
};