import { getFormattedCurrency } from "@/utils";
import ValueElement from "./ValueElement.vue";
import FlexRow from "../FlexRow.vue";
import { mapState } from "vuex";
import TableElementsMixin from "@/mixins/TableElementsMixin.vue";
export default {
  name: "CurrencyValue",
  mixins: [TableElementsMixin],
  props: {
    value: {
      type: [Number, null],
      required: false
    },
    precision: {
      type: Number
    },
    limit: {
      type: Number
    },
    charCount: {
      type: Number
    },
    color: {
      type: String
    },
    showSign: {
      type: Boolean,
      default: false
    },
    showSymbol: {
      type: Boolean,
      default: true
    },
    zeroInitials: {
      /* Used when dealing with values < 1000 */
      type: Boolean,
      default: false
    },
    locale: {
      // Used to set the locale for a particular CurrencyValue
      type: String,
      default: undefined
    },
    allowPrecisionModification: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String
    },
    align: {
      type: String,
      default: "left"
    }
  },
  components: {
    ValueElement,
    FlexRow
  },
  computed: {
    ...mapState({
      config: state => state.currency_config.config
    }),
    formattedValue() {
      if (!this.isCorrectValue) {
        return null;
      }
      let config = this.config;
      if (this.precision) {
        config = {
          ...config,
          precision: this.precision
        };
      }
      if (this.limit) {
        config = {
          ...config,
          limit: this.limit
        };
      }
      if (!this.showSymbol) {
        config = {
          ...config,
          showCurrencySign: this.showSymbol
        };
      }
      if (this.locale) {
        config = {
          ...config,
          locale: this.locale
        };
      }
      let currency = this.currency ?? config.currency;
      return getFormattedCurrency(this.value, config.full, currency, config.precision, config.showCurrencySign, this.showSign, config.limit, config.locale, this.zeroInitials, this.allowPrecisionModification);
    },
    getComputedStyle() {
      const width = this.charCount ? `${this.charCount}ch` : "auto";
      return {
        textAlign: "right",
        width
      };
    }
  }
};