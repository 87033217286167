import AppPopover from '@/components/AppPopover';
import AppIcon from '@/components/AppIcon';
export default {
  name: 'DemandForecastingSettingsPopover',
  emits: ['popover'],
  components: {
    AppPopover,
    AppIcon
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    number: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 5
    },
    prefix: {
      type: String,
      default: 'id'
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    onEmit(n) {
      this.$emit('popover', this.prefix, n);
    },
    onClose() {
      this.onEmit(this.number);
    },
    onPrev() {
      if (this.number > 1) {
        this.onEmit(this.number * 1 - 1);
      }
    },
    onNext() {
      if (this.number < this.max) {
        this.onEmit(this.number * 1 + 1);
      }
    }
  }
};