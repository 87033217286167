import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3ebaef3b"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_CircularIndicator = _resolveComponent("CircularIndicator");
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createBlock(_component_FlexRow, {
    class: _normalizeClass(["filter-item-selector", [{
      hovered: $data.hovered,
      disabled: $props.disabled
    }]]),
    gap: 8,
    onMouseover: _cache[1] || (_cache[1] = $event => $data.hovered = true),
    onMouseleave: _cache[2] || (_cache[2] = $event => $data.hovered = false),
    onClick: _cache[3] || (_cache[3] = $event => _ctx.$emit('click'))
  }, {
    default: _withCtx(() => [!_ctx.$slots.title ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [(_openBlock(), _createBlock(_resolveDynamicComponent($props.icon), {
      width: $props.iconSize,
      height: $props.iconSize,
      color: $props.disabled ? '#999999' : $props.color
    }, null, 8 /* PROPS */, ["width", "height", "color"])), _createElementVNode("div", {
      class: "title",
      innerHTML: $props.title
    }, null, 8 /* PROPS */, _hoisted_1)], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "title", {}, undefined, true)]), !$props.noCount ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [$props.selectedCount > 0 && $data.hovered ? (_openBlock(), _createBlock(_component_CircularIndicator, {
      key: 0,
      onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('clearAll')),
      background: "#669EFF",
      hoverBackground: "#4C8EFF"
    }, {
      default: _withCtx(() => [_createVNode(_component_AppIcon, {
        icon: "cross",
        size: 12,
        color: "#FFFFFF"
      })]),
      _: 1 /* STABLE */
    })) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [$props.selectedCount > 0 ? (_openBlock(), _createBlock(_component_CircularIndicator, {
      key: 0,
      width: "auto"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($options.getSelectedCount), 1 /* TEXT */)]),
      _: 1 /* STABLE */
    })) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true), !$props.noCaret ? (_openBlock(), _createBlock(_component_AppIcon, {
      key: 2,
      icon: "simple-arrow-down",
      color: $props.color,
      size: 16
    }, null, 8 /* PROPS */, ["color"])) : _createCommentVNode("v-if", true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class"]);
}