export default {
  name: 'PpcBidChart',
  props: {
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 240
    },
    current: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      canvas: null,
      ctx: null
    };
  },
  watch: {
    current() {
      this.update();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.canvas = this.$refs.chart;
      this.ctx = this.canvas.getContext('2d');
      setTimeout(() => {
        this.update();
      }, 300);
    });
  },
  methods: {
    update() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.area();
      this.draw();
    },
    area() {
      this.text('% of full bid', 0, 0 - this.width / 2 + 10, '#757575', 'center', -90);
      for (let i = 1; i <= 10; i++) {
        const top = this.height - (this.height / 10 - 1) * i;
        this.line(55, top, this.width, top, '#E3E3E3', 1, true);
        this.text(`${i * 10}%`, 20, top + 3, '#999999', 'left');
      }
    },
    draw() {
      const delta = 44;
      const stepX = (this.width - delta) / (this.max - 1) - 2.4;
      const stepY = this.height / 10 - 1;
      const startX = stepX + delta + 15 - 3;
      const startY = this.height - stepY * 2;
      if (this.current > 0) {
        this.line(startX, startY, startX, this.height, this.gradient('#A8A0FF'), 3);
        this.dot(startX, startY);
      }
      if (this.current > 1) {
        const maxX = stepX * (this.current + 1) + delta + 15 - 3;
        const maxY = 10;
        const slope = (maxY - startY) / (maxX - startX);
        const interceptY = startY - slope * startX;
        const currentX = stepX * this.current + delta + 15 - 3;
        const currentY = slope * currentX + interceptY;
        const lineNumber = (this.current - 1) * 8;
        const lineStep = stepX / 8;
        for (let i = 1; i < lineNumber; i++) {
          const lineX = startX + lineStep * i;
          const lineY = slope * lineX + interceptY;
          this.line(lineX, lineY, lineX, this.height, '#E4DFFF');
        }
        this.line(startX, startY, currentX, currentY, '#A8A0FF', 2);
        this.line(currentX, currentY, maxX, maxY, '#A8A0FF', 1, true);
        this.line(currentX, currentY, currentX, this.height, this.gradient('#A8A0FF'), 3);
        this.dot(currentX, currentY, '#A8A0FF', true);
      }
    },
    dot(x, y, color = '#A8A0FF', isBorder) {
      this.ctx.beginPath();
      this.ctx.arc(x, y, 6, 0, 2 * Math.PI);
      this.ctx.fillStyle = color;
      this.ctx.fill();
      if (isBorder) {
        this.ctx.beginPath();
        this.ctx.arc(x, y, 4, 0, 2 * Math.PI);
        this.ctx.fillStyle = '#fff';
        this.ctx.fill();
      }
    },
    line(startX, startY, endX, endY, color = '#E3E3E3', size = 1, isDashed = false) {
      if (isDashed) {
        this.ctx.setLineDash([4, 2]);
      }
      this.ctx.beginPath();
      this.ctx.moveTo(startX, startY);
      this.ctx.lineTo(endX, endY);
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = size;
      this.ctx.stroke();
      this.ctx.setLineDash([]);
    },
    gradient(color) {
      const gradient = this.ctx.createLinearGradient(0, 0, 0, this.height);
      gradient.addColorStop(0, this.hexToRgba(color));
      gradient.addColorStop(1, this.hexToRgba(color, 0.01));
      return gradient;
    },
    text(text, x, y, color = '#757575', align = 'center', rotate) {
      if (rotate) {
        this.ctx.save();
        this.ctx.translate(this.width / 2, this.height / 2);
        this.ctx.rotate(rotate * Math.PI / 180);
      }
      this.ctx.font = '500 11px Figtree';
      this.ctx.fillStyle = color;
      this.ctx.textAlign = align;
      this.ctx.fillText(text, x, y);
      this.ctx.restore();
    },
    hexToRgba(hex, opacity = 1) {
      const bigint = parseInt(hex.replace(/^#/, ''), 16);
      const r = bigint >> 16 & 255;
      const g = bigint >> 8 & 255;
      const b = bigint & 255;
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
  }
};