import { WrapWithSectionItem } from "../sections";
import MatchTypeValue from "@/components/tableElements/MatchTypeValue.vue";
export default {
  name: "MatchTypeFilterItem",
  emits: ["select", "selectOnly", "deselect"],
  props: {
    name: String,
    id: String,
    type: String,
    selected: Boolean
  },
  components: {
    WrapWithSectionItem,
    MatchTypeValue
  }
};