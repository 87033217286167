import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "assignee-row"
};
const _hoisted_2 = {
  class: "assignee-row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createBlock(_component_b_dropdown, {
    offset: [-13, 10],
    "no-caret": "",
    class: _normalizeClass(['dropdown-assignee'])
  }, {
    "button-content": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
      class: "assignee-avatar",
      style: _normalizeStyle({
        color: $options.assignee.color,
        backgroundColor: $options.assignee.back
      })
    }, _toDisplayString($options.assignee.name[0]), 5 /* TEXT, STYLE */), _createTextVNode(" " + _toDisplayString($options.assignee.name), 1 /* TEXT */)])]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignees, item => {
      return _openBlock(), _createBlock(_component_b_dropdown_item, {
        key: item.id,
        active: item.id == $props.modelValue.id,
        onClick: $event => $options.onClick(item)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
          class: "assignee-avatar",
          style: _normalizeStyle({
            color: item.color,
            backgroundColor: item.back
          })
        }, _toDisplayString(item.name[0]), 5 /* TEXT, STYLE */), _createTextVNode(" " + _toDisplayString(item.name), 1 /* TEXT */)])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["active", "onClick"]);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  });
}