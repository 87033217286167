import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["multi-mode-selected-period", {
      previous: $props.previous
    }])
  }, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "title")]), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")])], 2 /* CLASS */);
}