export const fiscal_codes_list = [{
  text: "A_BOOKS_GEN",
  value: "A_BOOKS_GEN"
}, {
  text: "A_BOOK_ADULT",
  value: "A_BOOK_ADULT"
}, {
  text: "A_BOOK_ATLAS",
  value: "A_BOOK_ATLAS"
}, {
  text: "A_BOOK_AUDIOBOOK",
  value: "A_BOOK_AUDIOBOOK"
}, {
  text: "A_BOOK_GLOBE",
  value: "A_BOOK_GLOBE"
}, {
  text: "A_BOOK_MAGAZINE",
  value: "A_BOOK_MAGAZINE"
}, {
  text: "A_BOOK_MAP",
  value: "A_BOOK_MAP"
}, {
  text: "A_CLTH_BABY",
  value: "A_CLTH_BABY"
}, {
  text: "A_CLTH_CHILD",
  value: "A_CLTH_CHILD"
}, {
  text: "A_CLTH_PROTECTIVE",
  value: "A_CLTH_PROTECTIVE"
}, {
  text: "A_FOOD_GEN",
  value: "A_FOOD_GEN"
}, {
  text: "A_FOOD_ANIMALFOOD",
  value: "A_FOOD_ANIMALFOOD"
}, {
  text: "A_FOOD_ANIMALMED",
  value: "A_FOOD_ANIMALMED"
}, {
  text: "A_FOOD_ANIMALVITAMINS",
  value: "A_FOOD_ANIMALVITAMINS"
}, {
  text: "A_FOOD_CAKEDECOR",
  value: "A_FOOD_CAKEDECOR"
}, {
  text: "A_FOOD_CANFRUIT",
  value: "A_FOOD_CANFRUIT"
}, {
  text: "A_FOOD_CEREALBARS",
  value: "A_FOOD_CEREALBARS"
}, {
  text: "A_FOOD_CEREALCHOCBARS",
  value: "A_FOOD_CEREALCHOCBARS"
}, {
  text: "A_FOOD_CHOCEREAL",
  value: "A_FOOD_CHOCEREAL"
}, {
  text: "A_FOOD_CNDY",
  value: "A_FOOD_CNDY"
}, {
  text: "A_FOOD_COFFEE",
  value: "A_FOOD_COFFEE"
}, {
  text: "A_FOOD_DAIRY",
  value: "A_FOOD_DAIRY"
}, {
  text: "A_FOOD_DESSERT",
  value: "A_FOOD_DRIEDFRUIT"
}, {
  text: "A_FOOD_FLOUR",
  value: "A_FOOD_FLOUR"
}, {
  text: "A_FOOD_MEATCHICKEN",
  value: "A_FOOD_MEATCHICKEN"
}, {
  text: "A_FOOD_MISCBEVERAGE",
  value: "A_FOOD_MISCBEVERAGE"
}, {
  text: "A_FOOD_NAAN",
  value: "A_FOOD_NAAN"
}, {
  text: "A_FOOD_NCARBWTR",
  value: "A_FOOD_NCARBWTR"
}, {
  text: "A_FOOD_OIL",
  value: "A_FOOD_OIL"
}, {
  text: "A_FOOD_PASTANOODLE",
  value: "A_FOOD_PASTANOODLE"
}, {
  text: "A_FOOD_PASTRYCASE",
  value: "A_FOOD_PASTRYCASE"
}, {
  text: "A_FOOD_PETFOOD",
  value: "A_FOOD_PETFOOD"
}, {
  text: "A_FOOD_PLAINBISCUIT",
  value: "A_FOOD_PLAINBISCUIT"
}, {
  text: "A_FOOD_PLAINCRACKER",
  value: "A_FOOD_PLAINCRACKER"
}, {
  text: "A_FOOD_PLAINNUT",
  value: "A_FOOD_PLAINNUT"
}, {
  text: "A_FOOD_RICE",
  value: "A_FOOD_RICE"
}, {
  text: "A_FOOD_SEASONINGS",
  value: "A_FOOD_SEASONINGS"
}, {
  text: "A_FOOD_SNACK",
  value: "A_FOOD_SNACK"
}, {
  text: "A_FOOD_SODAJUICE",
  value: "A_FOOD_SODAJUICE"
}, {
  text: "A_FOOD_SODAJUICEUNSWEET",
  value: "A_FOOD_SODAJUICEUNSWEET"
}, {
  text: "A_FOOD_SPREAD",
  value: "A_FOOD_SPREAD"
}, {
  text: "A_FOOD_SWEETNER",
  value: "A_FOOD_SWEETNER"
}, {
  text: "A_FOOD_TEA",
  value: "A_FOOD_TEA"
}, {
  text: "A_FOOD_VEGETABLE",
  value: "A_FOOD_VEGETABLE"
}, {
  text: "A_HLTH_NUTRITIONBAR",
  value: "A_HLTH_NUTRITIONBAR"
}, {
  text: "A_HLTH_NUTRITIONDRINK",
  value: "A_HLTH_NUTRITIONDRINK"
}, {
  text: "A_HLTH_PILLCAPSULETABLET",
  value: "A_HLTH_PILLCAPSULETABLET"
}, {
  text: "A_HLTH_SMOKINGCESSATION",
  value: "A_HLTH_SMOKINGCESSATION"
}, {
  text: "A_HLTH_SMOKINGGUM",
  value: "A_HLTH_SMOKINGGUM"
}, {
  text: "A_HLTH_VITAMINS",
  value: "A_HLTH_VITAMINS"
}, {
  text: "A_HPC_CONTACTLENSES",
  value: "A_HPC_CONTACTLENSES"
}, {
  text: "A_HPC_CONTRACEPTIVE",
  value: "A_HPC_CONTRACEPTIVE"
}, {
  text: "A_HPC_DIETARYSUPPL",
  value: "A_HPC_DIETARYSUPPL"
}, {
  text: "A_HPC_INCONTINENCE",
  value: "A_HPC_INCONTINENCE"
}, {
  text: "A_HPC_MEDICINE",
  value: "A_HPC_MEDICINE"
}, {
  text: "A_HPC_PPECLOTHING",
  value: "A_HPC_PPECLOTHING"
}, {
  text: "A_HPC_PPEMASKS",
  value: "A_HPC_PPEMASKS"
}, {
  text: "A_HPC_PPESANITISER",
  value: "A_HPC_PPESANITISER"
}, {
  text: "A_HPC_SANITARYPRODUCTS",
  value: "A_HPC_SANITARYPRODUCTS"
}, {
  text: "A_HPC_SANITARYPRODUCTSWASHABLE",
  value: "A_HPC_SANITARYPRODUCTSWASHABLE"
}, {
  text: "A_HPC_THERMOMETER",
  value: "A_HPC_THERMOMETER"
}, {
  text: "A_HPC_WALKINGSTICK",
  value: "A_HPC_WALKINGSTICK"
}, {
  text: "A_HPC_WHEELCHAIR",
  value: "A_HPC_WHEELCHAIR"
}, {
  text: "A_OUTDOOR_FUEL",
  value: "A_OUTDOOR_FUEL"
}, {
  text: "A_OUTDOOR_FERTILIZER",
  value: "A_OUTDOOR_FERTILIZER"
}, {
  text: "A_OUTDOOR_LAWNCONTROL",
  value: "A_OUTDOOR_LAWNCONTROL"
}, {
  text: "A_OUTDOOR_PLANTFOOD",
  value: "A_OUTDOOR_PLANTFOOD"
}, {
  text: "A_OUTDOOR_PLANTS",
  value: "A_OUTDOOR_PLANTS"
}, {
  text: "A_OUTDOOR_SEEDS",
  value: "A_OUTDOOR_SEEDS"
}, {
  text: "A_BABY_BIBCLOTH",
  value: "A_BABY_BIBCLOTH"
}, {
  text: "A_BABY_CARSEAT",
  value: "A_BABY_CARSEAT"
}, {
  text: "A_BABY_NAPPIES",
  value: "A_BABY_NAPPIES"
}, {
  text: "A_GEN_NOTAX",
  value: "A_GEN_NOTAX"
}];