import WrapWithCopy from "@/components/WrapWithCopy.vue";
import AppPopover from "@/components/AppPopover";
import AppIcon from "@/components/AppIcon";
import Popper from "vue3-popper";
import AppTd from "@/components/AppTd";
import WrapWithStatusIcon from "@/components/seo/WrapWithStatusIcon.vue";
import ProductScore from "@/components/ProductScore";
import { mapState } from "vuex";
export default {
  name: "SeoPencilEditCell",
  props: {
    item: Object
  },
  emits: ['onEditSingleItem'],
  components: {
    AppIcon,
    AppPopover,
    WrapWithCopy,
    Popper,
    AppTd,
    WrapWithStatusIcon,
    ProductScore
  },
  computed: {
    ...mapState({
      isOmnyAdmin: state => state.auth.isOmnyAdmin,
      isOmnyUser: state => state.auth.isOmnyUser
    }),
    testCaseTooltip() {
      return item => {
        const testcases = {
          "No content on SC and on Omny": "no_content_on_sc_and_on_omny",
          "No content on Omny and SC = AMZ": "no_content_on_omny_and_sc_equal_amazon",
          "No content on Omny and SC <> AMZ": "no_content_on_omny_and_sc_difference_from_amazon",
          "No Content On Omny And SC <> Amazon But Some SC Attribute Empty": "no_content_on_omny_and_sc_difference_from_amazon_but_some_sc_attribute_empty",
          "No Content On Omny And SC <> Amazon But Some Amazon Attribute Empty": "no_content_on_omny_and_sc_difference_from_amazon_but_some_amazon_attribute_empty",
          "Content on Omny = AMZ but <> SC w/o push": "content_on_omny_equal_amazon_and_omny_difference_sc_without_push",
          "Content on Omny = AMZ but <> SC with push": "content_on_omny_equal_amazon_and_omny_difference_sc_with_push",
          "Omny = AMZ = SC w/o push": "content_on_omny_equal_amazon_and_sc_without_push",
          "Omny <> AMZ = SC w/o push": "content_on_omny_difference_from_amazon_and_amazon_equal_sc_without_push",
          "Omny <> AMZ = SC with push": "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_push",
          "Omny <> AMZ <> SC w/o push": "content_on_omny_difference_from_amazon_and_amazon_difference_sc_without_push",
          "Omny <> AMZ = SC with sync error": "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_sync_error",
          "Omny <> AMZ = SC with recent push": "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_recent_push",
          "Omny <> AMZ = SC with old push contribution modified": "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_old_push_contribution_modified",
          "Omny <> AMZ = SC with old push direct modified": "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_old_push_direct_modification",
          "Omny = SC <> AMZ with push": "content_on_omny_equal_sc_and_omny_difference_from_amazon_with_push",
          "Omny <> SC <> AMZ with push": "content_on_omny_diffrence_from_sc_and_sc_difference_amazon_with_push",
          "Omny = SC = AMZ with push": "content_on_omny_equal_amazon_and_sc_with_push",
          "test case unavailable": ""
        };
        const found = Object.keys(testcases).find(key => testcases[key] == item.content_evaluation_status?.[0]);
        return found ?? '';
      };
    }
  },
  methods: {
    getPushStatusClass(item) {
      return `seo-popover ${item.statusPush.status}`;
    }
  }
};