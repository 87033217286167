import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["fill"];
const _hoisted_3 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 15 20`,
    fill: "none"
  }, [_createElementVNode("path", {
    d: "M8.37501 15.625C8.37501 15.1417 7.98326 14.75 7.50001 14.75C7.01676 14.75 6.62501 15.1417 6.62501 15.625C6.62501 16.1082 \n            7.01676 16.5 7.50001 16.5C7.98326 16.5 8.37501 16.1082 8.37501 15.625Z",
    fill: $props.color
  }, null, 8 /* PROPS */, _hoisted_2), _createElementVNode("path", {
    d: "M5.96316 5.59376L7.10443 11.8163C7.21596 12.4244 7.27172 12.7284 7.36572 12.7868C7.44648 12.837 7.5487 12.837 7.62948 \n        12.7869C7.72351 12.7285 7.77943 12.4245 7.89127 11.8165L9.03572 5.59454C9.09745 5.25894 9.12831 5.09114 9.12326 4.95399C9.10233 \n        4.38571 8.70046 3.90317 8.14534 3.77978C8.01136 3.75 7.84074 3.75 7.49951 3.75C7.15844 3.75 6.9879 3.75 6.85396 3.77976C6.29901 \n        3.90308 5.89716 4.38534 5.87598 4.95343C5.87087 5.09054 5.90163 5.25828 5.96316 5.59376Z",
    fill: $props.color
  }, null, 8 /* PROPS */, _hoisted_3)], 8 /* PROPS */, _hoisted_1);
}