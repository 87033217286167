import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_info_icon = _resolveComponent("info-icon");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: $props.gap,
    align: $props.align,
    class: "wrap-with-info"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), _createVNode(_component_wrap_with_popover, {
      width: $props.width,
      customClass: $props.popoverClass
    }, {
      title: _withCtx(() => [$props.title ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.title), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]),
      content: _withCtx(() => [_ctx.$slots['body'] ? _renderSlot(_ctx.$slots, "body", {
        key: 0
      }) : _createCommentVNode("v-if", true), $props.info ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.info), 1 /* TEXT */)) : _createCommentVNode("v-if", true)]),
      default: _withCtx(() => [_createVNode(_component_info_icon, {
        color: "#999999"
      })]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["width", "customClass"])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["gap", "align"]);
}