import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-348085db"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "value"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    loading: $props.loading,
    styles: {
      ...$props.styles
    },
    align: "right"
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      style: _normalizeStyle($options.valueStyle)
    }, [_createElementVNode("div", {
      class: "bar",
      style: _normalizeStyle($options.computedStyle)
    }, null, 4 /* STYLE */), _createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 4 /* STYLE */)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading", "styles"]);
}