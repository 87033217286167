import BarWithValue from './BarWithValue.vue';
import BoxedValue from './BoxedValue.vue';
import NumberValue from './NumberValue.vue';
import PercentageValue from './PercentageValue.vue';
import TrendElement from './TrendElement.vue';
import TrendWithValue from './TrendWithValue.vue';
import ValueElement from './ValueElement.vue';
import HugValue from './HugValue.vue';
import CurrencyValue from './CurrencyValue.vue';
import DateValue from './DateValue.vue';
import DottedValue from './DottedValue.vue';
import DotElement from './DotElement.vue';
import WrapWithDetails from './WrapWithDetails.vue';
import MarketplaceValue from './MarketplaceValue.vue';
import CampaignsCount from './CampaignsCount.vue';
import ProductGroupValue from './ProductGroupValue.vue';
import CampaignValue from './CampaignValue.vue';
import TargetingValue from './TargetingValue.vue';
import WrapWithArrow from './WrapWithArrow.vue';
import TextValue from './TextValue.vue';
import OverlapBarValue from './OverlapBarValue.vue';
import ProductValue from './ProductValue.vue';
import FlagValue from './FlagValue.vue';
import RateChangeValue from "./RateChangeValue.vue";
import RatingValue from './RatingValue.vue';
import ParentProductValue from './ParentProductValue.vue';
import DialValue from './DialValue.vue';
import SignedBarWithValue from './SignedBarWithValue.vue';
export { BarWithValue, BoxedValue, NumberValue, PercentageValue, TrendElement, TrendWithValue, ValueElement, HugValue, CurrencyValue, DateValue, DottedValue, WrapWithDetails, MarketplaceValue, CampaignsCount, ProductGroupValue, DotElement, CampaignValue, TargetingValue, WrapWithArrow, TextValue, OverlapBarValue, ProductValue, FlagValue, RateChangeValue, RatingValue, ParentProductValue, DialValue, SignedBarWithValue };