import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_omny_confirm_continue_modal = _resolveComponent("omny-confirm-continue-modal");
  return _openBlock(), _createBlock(_component_omny_confirm_continue_modal, {
    modelValue: $options.modal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.modal = $event),
    continueVariant: "danger",
    onContinue: _cache[1] || (_cache[1] = $event => _ctx.$emit('delete'))
  }, {
    title: _withCtx(() => [_createTextVNode("Delete Strategy")]),
    continue: _withCtx(() => [_createTextVNode("Yes, delete")]),
    default: _withCtx(() => [_createTextVNode(" Are you sure you want to delete this strategy? ")]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]);
}