import ErrorOccured from '@/components/shared/ErrorOccured.vue';
import EmptySpace from '@/components/shared/EmptySpace.vue';
export default {
  name: 'KpisRenderer',
  emits: ['retry'],
  props: {
    error: {
      type: Boolean,
      default: false
    },
    noDataFound: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ErrorOccured,
    EmptySpace
  },
  methods: {
    handleRetry() {
      this.$emit('retry');
    }
  }
};