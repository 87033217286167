import AppCheckbox from "@/components/AppCheckbox";
import AppSwitch from "@/components/AppSwitch";
import AppIcon from "@/components/AppIcon";
import ProductDataMixin from "@/mixins/ProductDataMixin";
export default {
  name: "ProductDataContentRow",
  mixins: [ProductDataMixin],
  emits: ["select", "save-product-data", "update"],
  components: {
    AppCheckbox,
    AppSwitch,
    AppIcon
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    scaleType: {
      type: String,
      default: "metric"
    }
  },
  data() {
    return {
      manualOverride: 0,
      default_orderable: true,
      selectedCells: {},
      isInputActive: false,
      convertedInputValues: {},
      modifiedItemValue: ""
    };
  },
  computed: {
    realSelected() {
      return this.item.orderable && this.selected;
    },
    lengthValue() {
      return val => this.convertLengthValue(val, this.scaleType);
    },
    weightValue() {
      return val => this.convertWeightValue(val, this.scaleType);
    }
  },
  methods: {
    onSelect() {
      if (this.item.orderable) {
        //this.$emit('select', { item: this.item, value: value });
      }
    },
    onExpirationDateRequired() {
      this.$emit("expiration", this.item);
    },
    onBlur(key) {
      this.$refs[key].blur();
    },
    onUpdate(editedField) {
      this.selectedCells = {};
      this.isInputActive = false;
      if (this.modifiedItemValue == null || this.modifiedItemValue === "") {
        this.item[editedField] = this.item[editedField];
      } else {
        this.item[editedField] = editedField.includes('cm') ? this.reverseConvertLengthValue(this.modifiedItemValue, this.scaleType) : editedField.includes('g') ? this.reverseConvertWeightValue(this.modifiedItemValue, this.scaleType) : this.modifiedItemValue;
      }
      //this.item[editedField] = editedField.includes('cm') ? this.reverseConvertLengthValue(this.modifiedItemValue, this.scaleType) : (editedField.includes('g') ? this.reverseConvertWeightValue(this.modifiedItemValue, this.scaleType) : this.item[editedField]);
      this.$emit("update", this.item, editedField);
      this.modifiedItemValue = "";
    },
    onSettings() {
      //console.log("Settings");
    },
    focusInput(refName) {
      this.isInputActive = refName;
      this.convertedInputValues[refName] = refName.includes('cm') ? this.convertLengthValue(this.item[refName], this.scaleType) : refName.includes('kg') ? this.convertWeightValue(this.item[refName], this.scaleType) : 0;
      this.$nextTick(() => {
        const isFocused = this.selectedCells[refName];
        for (const key in this.selectedCells) {
          if (key !== refName) {
            delete this.selectedCells[key];
          }
        }
        this.$refs[refName].focus();
        if (isFocused) {
          delete this.selectedCells[refName];
        } else {
          this.selectedCells[refName] = true;
        }
        this.selectedCells = {
          ...this.selectedCells
        };
      });
    },
    focusNextCell(refName) {
      this.isInputActive = refName;
      this.focusInput(refName);
    },
    onUpdateLengthItem(event, refName) {
      //console.log("[onUpdateLengthItem]");
      this.modifiedItemValue = event.target.value;
    }
  }
};