import AppIcon from "@/components/AppIcon";
import AppButton from "@/components/AppButton";
import CustomAccountSearchElement from "@/components/shared/CustomAccountSearchElement";
export default {
  name: "ConnectionPageTitle",
  emits: ["action"],
  components: {
    AppIcon,
    AppButton,
    CustomAccountSearchElement
  },
  props: {
    title: {
      type: [String, Array],
      default: null
    },
    search: {
      type: [String, Array],
      default: null
    },
    searchPlaceHolder: {
      type: String,
      default: null
    },
    visibleButton: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: null
    },
    spinButton: {
      type: Boolean,
      default: false
    },
    activeButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClick() {
      this.$emit("action");
    }
  }
};