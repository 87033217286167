import FlexColumn from "@/components/FlexColumn.vue";
import FlexRow from "@/components/FlexRow.vue";
import AppIconGroup from "@/components/AppIconGroup.vue";
import TableSkeletonV2 from '@/components/TableSkeletonV2.vue';
export default {
  name: 'TableWithTitle',
  components: {
    FlexColumn,
    FlexRow,
    AppIconGroup,
    TableSkeletonV2
  },
  emits: ['download'],
  props: {
    title: {
      type: String,
      required: true
    },
    gap: {
      type: Number,
      default: 16
    },
    loading: {
      type: Boolean,
      default: true
    },
    fields: {
      type: Boolean
    }
  },
  methods: {
    handleClick() {
      this.$emit('download');
    }
  }
};