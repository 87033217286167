import AppButton from '@/components/AppButton';
export default {
  name: 'AppUpload',
  emits: ['update:modelValue', 'upload'],
  components: {
    AppButton
  },
  props: {
    label: {
      type: String,
      default: 'Choose file...'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: null
    };
  },
  methods: {
    onChange(event) {
      this.file = event.target.files[0];
    },
    onUpload() {
      this.$emit('upload', this.file);
    },
    clear() {
      this.file = null;
      this.$refs.inputRef.value = null;
    }
  }
};