import { mapState, mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import ValidateMixin from '@/mixins/ValidateMixin.vue';
import AppForm from '@/components/AppForm.vue';
import AppInput from '@/components/AppInput.vue';
import AppButton from '@/components/AppButton.vue';
import AppLink from '@/components/AppLink.vue';
import TheWrapperPublic from '@/components/TheWrapperPublic.vue';
export default {
  name: 'RegisterPage',
  mixins: [ValidateMixin],
  components: {
    AppForm,
    AppInput,
    AppButton,
    AppLink,
    TheWrapperPublic
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: ''
      },
      loading: false,
      showSuccessMessage: false
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token
    })

    // isLoggedIn() {
    //   return !! this.token
    // },
  },
  async mounted() {
    this.showSuccessMessage = false;
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      this.$router.replace('/');
    }
  },
  methods: {
    ...mapActions(['authRegister', 'authStatus']),
    async onSubmit() {
      const valid = await this.mValidate();
      if (valid) {
        this.loading = true;
        const result = await this.authRegister(this.form);
        if (result) {
          this.showSuccessMessage = true;
        }
        this.loading = false;
      }
    }
  }
};