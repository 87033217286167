import { normalizeToRange } from '@/utils';
import { BAR_CHART_MINIMUM_PERCENTAGE } from '@/utils/constants';
import HugValue from './HugValue.vue';
export default {
  name: "OverlapBarValue",
  props: {
    value: {
      type: [Number, null],
      required: false,
      default: 3301
    },
    color: {
      type: String,
      default: "#312783"
    },
    align: {
      type: String,
      default: "right"
    },
    gap: {
      type: Number,
      default: 2
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: "#F2F2F2"
    },
    borderColor: {
      type: String
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    HugValue
  },
  computed: {
    computedStyle() {
      const width = normalizeToRange(this.value, BAR_CHART_MINIMUM_PERCENTAGE * this.min, this.max);
      return {
        height: "18px",
        backgroundColor: this.background,
        borderLeft: `1px solid ${this.borderColor}`,
        borderRadius: '2px',
        width: `${width}%`
      };
    },
    valueStyle() {
      const justify = {
        right: 'flex-end',
        left: 'flex-start',
        center: 'center'
      };
      return {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: justify[this.align],
        width: "100%"
      };
    }
  }
};