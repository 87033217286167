import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4d6c6978"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["width", "height"];
const _hoisted_2 = ["stroke"];
const _hoisted_3 = ["stroke", "stroke-dasharray", "stroke-dashoffset"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    width: $props.size,
    height: $props.size,
    viewBox: "0 0 36 36",
    class: "circular-progress"
  }, [_createElementVNode("circle", {
    class: "background-circle",
    cx: "18",
    cy: "18",
    r: "14",
    "stroke-width": "4",
    fill: "none",
    stroke: $options.grayColor
  }, null, 8 /* PROPS */, _hoisted_2), _createElementVNode("circle", {
    class: "progress-circle",
    cx: "18",
    cy: "18",
    r: "14",
    "stroke-width": "4",
    fill: "none",
    stroke: $options.progressColor,
    "stroke-dasharray": $options.circumference,
    "stroke-dashoffset": $options.offset
  }, null, 8 /* PROPS */, _hoisted_3)], 8 /* PROPS */, _hoisted_1);
}