import AppIcon from "../../AppIcon.vue";
import FlexRow from "../../FlexRow.vue";
import WrapWithOnlyButton from "../items/WrapWithOnlyButton.vue";
export default {
  name: "WrapWithSectionItem",
  components: {
    FlexRow,
    AppIcon,
    WrapWithOnlyButton
  },
  emits: ["select", "selectOnly", "deselect", "hover"],
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOnlyButton: false
    };
  },
  computed: {
    computeShowButton() {
      return !this.selected && this.showOnlyButton;
    }
  },
  methods: {
    handleClick() {
      if (this.selected) {
        this.$emit("deselect");
      } else {
        this.$emit("select");
      }
    }
  }
};