import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_omny_modal_header = _resolveComponent("omny-modal-header");
  const _component_AppButton = _resolveComponent("AppButton");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createBlock(_component_b_modal, {
    modelValue: $options.modal,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $options.modal = $event),
    modalClass: "omny-continue-modal",
    noFade: ""
  }, {
    header: _withCtx(() => [_createVNode(_component_omny_modal_header, {
      modelValue: $options.modal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.modal = $event)
    }, {
      title: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue"])]),
    cancel: _withCtx(() => [_createVNode(_component_AppButton, {
      v2: "",
      variant: "outline-secondary",
      version: "btn-modal",
      onClick: _cache[1] || (_cache[1] = () => {
        $options.modal = false;
        _ctx.$emit('cancel');
      })
    }, {
      default: _withCtx(() => [_ctx.$slots['cancel'] ? _renderSlot(_ctx.$slots, "cancel", {
        key: 0
      }) : (_openBlock(), _createElementBlock("div", _hoisted_1, "Cancel"))]),
      _: 3 /* FORWARDED */
    })]),
    ok: _withCtx(() => [_createVNode(_component_AppButton, {
      v2: "",
      variant: $props.continueVariant,
      version: "btn-modal",
      onClick: _cache[2] || (_cache[2] = $event => {
        _ctx.$emit('continue');
        $options.modal = false;
      })
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "continue")]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["variant"])]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue"]);
}