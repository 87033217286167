import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_row = _resolveComponent("b-row");
  return _openBlock(), _createBlock(_component_b_row, {
    class: _normalizeClass([$options.rowClasses, "overview-row"]),
    style: _normalizeStyle({
      ...$props.customStyle
    })
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "full", {}, undefined, true), _createElementVNode("div", {
      class: _normalizeClass({
        'disable-element': $props.disableLeft
      })
    }, [_renderSlot(_ctx.$slots, "left", {}, undefined, true)], 2 /* CLASS */), _createElementVNode("div", {
      class: _normalizeClass({
        'disable-element': $props.disableRight
      })
    }, [_renderSlot(_ctx.$slots, "right", {}, undefined, true)], 2 /* CLASS */)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class", "style"]);
}