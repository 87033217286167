import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e4876bb2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "inner"
};
const _hoisted_2 = {
  class: "text-font"
};
const _hoisted_3 = {
  class: "list"
};
const _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['version', {
      open: $data.versionOpen
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    class: "text",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onVersionToggle && $options.onVersionToggle(...args))
  }, [_createElementVNode("div", _hoisted_2, _toDisplayString($options.displayedVersionText), 1 /* TEXT */), _createVNode(_component_app_icon, {
    icon: "chevron-down2"
  })]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.versions, item => {
    return _openBlock(), _createElementBlock("div", {
      key: item.title,
      class: _normalizeClass(['list-item', {
        active: $props.version == item.title
      }]),
      onClick: $event => $options.onVersionClick(item)
    }, _toDisplayString(item.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_4);
  }), 128 /* KEYED_FRAGMENT */))])])], 2 /* CLASS */);
}