import BlockStyledBar from '@/components/tableElements/BlockStyledBar.vue';
import EvolutionValue from '@/components/tableElements/EvolutionValue.vue';
import PercentageValue from '@/components/tableElements/PercentageValue.vue';
import HugValue from '@/components/tableElements/HugValue.vue';
import LoadingElement from "@/components/shared/LoadingElement.vue";
export default {
  name: 'BlockBarWithValue',
  components: {
    EvolutionValue,
    BlockStyledBar,
    PercentageValue,
    HugValue,
    LoadingElement
  },
  props: {
    value: {
      type: [Number, null]
    },
    evolution: {
      type: [Number, null]
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    color: {
      type: String
    },
    charCount: {
      type: Number
    },
    loading: {
      type: Boolean,
      default: true
    }
  }
};