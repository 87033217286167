export default {
  name: 'CustomInput',
  emits: ['search', 'clear'],
  props: {
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    onSearch: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    searchValue: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    onInput(event) {
      if (this.disabled || !event.target.checkValidity()) return;
      this.$emit('search', {
        value: event.target.value,
        refresh: true
      });
    }
  }
};