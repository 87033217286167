import { normalizeToRange } from '@/utils';
import { BAR_CHART_MINIMUM_PERCENTAGE } from '@/utils/constants';
export default {
  name: "VariableStyledBar",
  props: {
    value: {
      type: [Number, null],
      required: true,
      default: 0
    },
    color: {
      type: String,
      required: true,
      default: "#312783"
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 0
    },
    height: {
      type: Number,
      default: 4
    }
  },
  computed: {
    barWidth() {
      // Compute the value normalized to `100%`
      // Patch: 
      //      We use BAR_CHART_MINIMUM_PERCENTAGE * this.min instead of this.min to avoid the bar being too small 
      const width = normalizeToRange(this.value, BAR_CHART_MINIMUM_PERCENTAGE * this.min, this.max);
      return {
        width: `${width}%`,
        backgroundColor: this.color,
        border: `1px solid ${this.color}`
      };
    }
  }
};