import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6e67f962"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["min", "max", "step"];
const _hoisted_2 = {
  key: 0,
  class: "steps-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['slider', $props.variant]),
    style: _normalizeStyle($options.sliderStyle)
  }, [_createElementVNode("div", {
    class: "slider-line",
    style: _normalizeStyle($options.lineStyle)
  }, [$props.mixed ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "slider-segments",
    style: _normalizeStyle($options.lineStyle)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.mixedSegments, segment => {
    return _openBlock(), _createElementBlock("div", {
      key: segment.index,
      class: "slider-segment",
      style: _normalizeStyle($options.mixedSegmentStyle(segment))
    }, null, 4 /* STYLE */);
  }), 128 /* KEYED_FRAGMENT */))], 4 /* STYLE */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "slider-progress",
    style: _normalizeStyle($options.progressStyle)
  }, null, 4 /* STYLE */), _createElementVNode("div", {
    class: "slider-thumb",
    style: _normalizeStyle($options.thumbStyle)
  }, [$props.showTooltip ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "slider-tooltip",
    style: _normalizeStyle($options.tooltipStyle)
  }, _toDisplayString($options.sliderTooltip), 5 /* TEXT, STYLE */)) : _createCommentVNode("v-if", true)], 4 /* STYLE */)], 4 /* STYLE */), _createElementVNode("div", {
    class: "input-line",
    ref: "line",
    style: _normalizeStyle($options.inputStyle)
  }, [_withDirectives(_createElementVNode("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.sliderValue = $event),
    type: "range",
    class: "slider-input",
    min: $options.initValues.min,
    max: $options.initValues.max,
    step: $options.initValues.step,
    onChange: _cache[1] || (_cache[1] = (...args) => $options.onChange && $options.onChange(...args))
  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1), [[_vModelText, $options.sliderValue]])], 4 /* STYLE */), $props.showSteps ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", {
    class: "steps",
    style: _normalizeStyle($options.stepsStyle)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.steps, step => {
    return _openBlock(), _createElementBlock("div", {
      key: step,
      class: "step",
      style: _normalizeStyle($options.stepStyle(step))
    }, _toDisplayString(step), 5 /* TEXT, STYLE */);
  }), 128 /* KEYED_FRAGMENT */))], 4 /* STYLE */)])) : _createCommentVNode("v-if", true)], 6 /* CLASS, STYLE */);
}