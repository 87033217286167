import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4084c6c4"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "circle-progress",
    style: _normalizeStyle(`width: ${$props.size}px; height: ${$props.size}px;`)
  }, [_createElementVNode("canvas", {
    ref: "progress",
    width: $props.size,
    height: $props.size
  }, null, 8 /* PROPS */, _hoisted_1), _createElementVNode("div", {
    class: "circle-progress-score",
    style: _normalizeStyle(`font-size: ${$props.font}px;`)
  }, _toDisplayString($options.score), 5 /* TEXT, STYLE */)], 4 /* STYLE */);
}