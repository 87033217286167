import FlexRow from "@/components/FlexRow.vue";
export default {
  name: "CircularIndicator",
  components: {
    FlexRow
  },
  data() {
    return {
      hovered: false
    };
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    background: {
      type: String,
      default: '#2172E1'
    },
    hoverBackground: {
      type: String,
      default: '#1A5ABF'
    },
    color: {
      type: String,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '16px'
    }
  },
  computed: {
    getBackground() {
      return this.hovered ? this.hoverBackground : this.background;
    }
  }
};