import FlexRow from "@/components/FlexRow.vue";
import FlexColumn from "@/components/FlexColumn.vue";
export default {
  name: "AppChoiceMaker",
  components: {
    FlexRow,
    FlexColumn
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};