import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["width", "height", "viewBox"];
const _hoisted_2 = {
  opacity: "0.6"
};
const _hoisted_3 = ["stroke"];
const _hoisted_4 = ["stroke"];
const _hoisted_5 = ["fill", "stroke"];
const _hoisted_6 = ["fill", "stroke"];
const _hoisted_7 = ["fill", "stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", {
    width: $props.width,
    height: $props.height,
    viewBox: `0 0 ${$props.width} ${$props.height}`,
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [_createElementVNode("g", _hoisted_2, [_createElementVNode("path", {
    opacity: "0.5",
    d: "M12.375 16.4062H16.875",
    stroke: $props.color,
    "stroke-width": "2.25",
    "stroke-linecap": "round"
  }, null, 8 /* PROPS */, _hoisted_3), _createElementVNode("path", {
    d: "M1.125 16.4062H5.625",
    stroke: $props.color,
    "stroke-width": "2.25",
    "stroke-linecap": "round"
  }, null, 8 /* PROPS */, _hoisted_4), _createElementVNode("path", {
    d: "M1.16562 7.96974V8.05412H1.25H2.76082V12.7695V12.8539H2.8452H3.75403H3.83841V12.7695V6.01953V5.93516H3.75403H3.08187H3.01463L2.99962 \n            6.0007C2.88074 6.52006 2.70603 6.82187 2.4953 6.94525C2.2691 7.07436 1.85594 7.1727 1.24182 7.23254L1.16562 7.23996V7.31652V7.96974Z",
    fill: $props.color,
    stroke: $props.color,
    "stroke-width": "0.16875"
  }, null, 8 /* PROPS */, _hoisted_5), _createElementVNode("path", {
    d: "M6.0759 12.6751L6.0711 12.7641H6.16016H10.7776H10.862V12.6797V11.8811V11.7968H10.7776H7.21629C7.30611 11.525 7.48281 11.272 7.75155 \n                11.0382L7.75173 11.0381C7.92087 10.8901 8.15632 10.7321 8.46027 10.5647L8.46048 10.5645L9.10401 10.2075L9.10419 10.2074C9.67208 9.89055 \n                10.074 9.61649 10.3021 9.38515C10.6966 8.9873 10.8948 8.50564 10.8948 7.94482C10.8948 7.41274 10.7081 6.93019 10.3392 6.49968C9.96352 6.05762 \n                9.37316 5.84531 8.58865 5.84531C7.63984 5.84531 6.96025 6.1807 6.57513 6.86536L6.57504 6.86554C6.35943 7.25101 6.24973 7.74356 6.2402 \n                8.33804L6.23882 8.42377H6.32456H7.18416H7.26604L7.2685 8.34193C7.28085 7.9314 7.34564 7.61975 7.4567 7.40052C7.56078 7.19529 7.70499 \n                7.04397 7.88931 6.94317C8.07442 6.84194 8.30473 6.78911 8.58396 6.78911C8.95765 6.78911 9.24797 6.8991 9.46415 7.1124L9.46456 7.1128C9.67998 \n                7.32248 9.79133 7.60897 9.79133 7.9824C9.79133 8.29316 9.67324 8.56989 9.43051 8.81559L9.4303 8.81579C9.27576 8.97331 9.00058 9.16908 8.59802 9.40314C8.59798 \n                9.40316 8.59795 9.40318 8.59791 9.4032L7.69621 9.92449L7.69609 9.92456C7.0836 10.28 6.66118 10.6806 6.44135 11.1301L6.44114 11.1305C6.22869 11.5715 6.10769 12.087 6.0759 12.6751Z",
    fill: $props.color,
    stroke: $props.color,
    "stroke-width": "0.16875"
  }, null, 8 /* PROPS */, _hoisted_6), _createElementVNode("path", {
    d: "M12.789 12.1858L12.7894 12.1864C13.1625 12.6373 13.755 12.8539 14.5454 12.8539C15.3174 12.8539 15.926 12.6529 16.3588 12.2394C16.7896 \n                11.8277 17.0035 11.2947 17.0035 10.6478C17.0035 10.2424 16.9019 9.89442 16.6944 9.60882C16.5288 9.37509 16.31 9.20355 16.0405 9.09393C16.1822 9.01319 \n                16.3042 8.91421 16.4056 8.79664C16.6384 8.52767 16.7514 8.17977 16.7514 7.76088C16.7514 7.17593 16.5607 6.71871 16.1704 6.40313C15.7836 6.08732 15.2442 \n                5.93516 14.5637 5.93516C13.6948 5.93516 13.071 6.251 12.7167 6.89476C12.5125 7.25228 12.4126 7.68275 12.4126 8.18246V8.26684H12.497H13.3127H13.3937L13.397 \n                8.18592C13.412 7.81947 13.4765 7.54785 13.5829 7.36306L13.5829 7.36302C13.7741 7.03053 14.1206 6.85543 14.6508 6.85543C14.9419 6.85543 15.1941 6.93861 15.4107 \n                7.10392C15.6192 7.26309 15.7258 7.48879 15.7258 7.79295C15.7258 8.00189 15.6844 8.17303 15.6062 8.31023C15.5284 8.4468 15.4111 8.55482 15.2505 8.63372L15.2505 \n                8.63375C15.0671 8.72401 14.8275 8.77171 14.527 8.77171C14.4611 8.77171 14.4 8.77021 14.3436 8.76725C14.2876 8.7643 14.2174 8.75835 14.1328 \n                8.74928L14.0394 8.73928V8.83318V9.56179V9.65072L14.1282 9.64605C14.1849 9.64307 14.2399 9.64158 14.2933 9.64158V9.6417L14.2978 9.64147C14.3544 9.63849 \n                14.4095 9.637 14.4629 9.637C14.9459 9.637 15.3109 9.72576 15.5668 9.89356L15.5672 9.89379C15.8129 10.0531 15.9457 10.3365 15.9457 10.7716C15.9457 11.1152 15.8238 \n                11.3912 15.5801 11.6065C15.3362 11.822 15.0095 11.9336 14.5912 11.9336C14.0491 11.9336 13.6965 11.7673 13.5036 11.4557L13.5036 11.4557L13.5031 11.455C13.392 11.2801 \n                13.3139 10.9882 13.2776 10.5673L13.2709 10.4901H13.1935H12.332H12.2477V10.5745C12.2477 11.2027 12.4271 11.7415 12.789 12.1858Z",
    fill: $props.color,
    stroke: $props.color,
    "stroke-width": "0.16875"
  }, null, 8 /* PROPS */, _hoisted_7)])], 8 /* PROPS */, _hoisted_1);
}