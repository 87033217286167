const dataAndEvolutionFixed = [{
  key: "aggregate__evolution_numbers",
  label: "Evolution",
  thStyle: {
    width: "84x"
  },
  justify: "right",
  showSubtitle: true,
  type: 'currency',
  subtitle: "in "
}, {
  key: "aggregate__evolution_percent",
  label: "Evolution",
  thStyle: {
    width: "84px"
  },
  justify: "right",
  showSubtitle: true,
  subtitle: "in %"
}];
const evolution_pp = {
  key: "percent_net_revenue__evolution",
  label: "Evolution",
  thStyle: {
    width: "280px"
  },
  showSubtitle: true,
  subtitle: "in pp"
};
export const aggregateTableHeaders = [{
  key: "data_and_evolution",
  label: "",
  fields: {
    monthly: [{
      key: "aggregate_level",
      label: "",
      justify: "left",
      thStyle: {
        minWidth: "242px"
      },
      showSubtitle: false
    }, {
      key: "aggregate__current_month",
      label: "Current Month",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true,
      current: true
    }, {
      key: "aggregate__previous_month",
      label: "Previous Month",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true
    }, ...dataAndEvolutionFixed],
    quarterly: [{
      key: "aggregate_level",
      label: "",
      justify: "left",
      thStyle: {
        minWidth: "242px"
      },
      showSubtitle: false
    }, {
      key: "aggregate__current_quarter",
      label: "Current Quarter",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true,
      current: true
    }, {
      key: "aggregate__previous_quarter",
      label: "Previous Quarter",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true
    }, ...dataAndEvolutionFixed],
    yearly: [{
      key: "aggregate_level",
      label: "",
      justify: "left",
      thStyle: {
        minWidth: "242px"
      },
      showSubtitle: false
    }, {
      key: "aggregate__current_year",
      label: "Current Year",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true,
      current: true
    }, {
      key: "aggregate__previous_year",
      label: "Previous Year",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true
    }, ...dataAndEvolutionFixed]
  }
}, {
  key: "percent_net_revenue",
  label: "% of net revenue",
  fields: {
    monthly: [{
      key: "percent_net_revenue__current_month",
      label: "Current Month",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true,
      current: true
    }, {
      key: "percent_net_revenue__previous_month",
      label: "Previous Month",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true
    }, evolution_pp],
    quarterly: [{
      key: "percent_net_revenue__current_quarter",
      label: "Current Quarter",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true,
      current: true
    }, {
      key: "percent_net_revenue__previous_quarter",
      label: "Previous Quarter",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true
    }, evolution_pp],
    yearly: [{
      key: "percent_net_revenue__current_year",
      label: "Current Year",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true,
      current: true
    }, {
      key: "percent_net_revenue__previous_year",
      label: "Previous Year",
      thStyle: {
        width: "92px"
      },
      justify: "right",
      showSubtitle: true
    }, evolution_pp]
  }
}];