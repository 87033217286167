import { mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import AppModal from "@/components/AppModal";
import AppForm from "@/components/AppForm";
import AppInputRange from "@/components/AppInputRange";
import CustomSideBarAppModal from "@/components/CustomSideBarAppModal";
export default {
  name: "CreateMemberSideBar",
  emits: ["update:modelValue", "send-member-invitation"],
  mixins: [ValidateMixin],
  components: {
    AppModal,
    AppForm,
    AppInputRange,
    CustomSideBarAppModal
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    workspace: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    inviteSent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        members: [""],
        workspace_id: null
      }
    };
  },
  computed: {
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    }
  },
  mounted() {},
  methods: {
    ...mapActions(["memberCreate"]),
    async onSubmit() {
      if (this.inviteSent) {
        this.modal = false;
        /* This is a hack to reset the form
            The Modal inner working could be looked at to see if there is a better way
          */
        this.form.members = [""];
      } else {
        const valid = await this.mValidate();
        if (valid) {
          this.$emit("send-member-invitation", this.form.members);
        }
      }
    }
  }
};