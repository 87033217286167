import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_flag_value = _resolveComponent("flag-value");
  const _component_value_element = _resolveComponent("value-element");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    styles: $props.styles,
    classes: "marketplace-value",
    gap: $props.gap
  }, {
    default: _withCtx(() => [_createVNode(_component_flag_value, {
      flag: $props.flag
    }, null, 8 /* PROPS */, ["flag"]), _createVNode(_component_value_element, {
      id: "marketplace",
      value: $options.formattedValue
    }, null, 8 /* PROPS */, ["value"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["styles", "gap"]);
}