export default {
  name: "FlagValue",
  props: {
    flag: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 16
    },
    width: {
      type: Number,
      default: 16
    }
  },
  methods: {
    getWrapperComputedStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`
      };
    }
  }
};