import AppTd from "@/components/AppTd";
export default {
  name: 'SeoCheckBoxCell',
  props: {
    item: Object,
    pushModal: Boolean
  },
  components: {
    AppTd
  },
  emits: ['selectItem', 'isSelectedItem']
};