import { request } from "@/store";
import Filter from "@/store/filter";
import { makeArrayUnique } from "@/utils";
var _ = require("lodash");
const demand_forecasting = {
  state: {
    list: [],
    total_rows: null,
    sellers_filter: new Filter([], [], "id"),
    products_filter: new Filter([], [], "content_id"),
    keyword_list: [],
    demand_forecasting_settings: [],
    demand_forecasting_settings_initialized: false,
    df_manual_override_active: false
  },
  mutations: {
    demandForecastingSellersFilterSet(state, payload) {
      state.sellers_filter = payload;
    },
    demandForecastingProductsFilterSet(state, payload) {
      state.products_filter.initialize({
        list: payload,
        selected: [],
        name: "seo_content_suggestions_filter",
        key: "content_id"
      });
    },
    demandForecastingKeywordsListSet(state, payload) {
      state.keyword_list = payload;
    },
    demandForecastingListSet(state, payload) {
      state.list = payload;
    },
    demandForecastingSettingsUpdate(state, payload) {
      state.demand_forecasting_settings.push(payload);
      state.demand_forecasting_settings = makeArrayUnique(state.demand_forecasting_settings);
    },
    demandForecastingListProductDataSet(state, payload) {
      state.list = state.list.map(item => {
        let product_data_items = payload.find(product_data => product_data.sku === item.sku);
        this.commit("demandForecastingManualOverrideActiveSet", product_data_items);
        return {
          ..._.mergeWith({}, item, product_data_items, (o, s) => _.isNull(s) ? o : s),
          orderable: product_data_items?.orderable === false ? false : true
        };
      });
    },
    demandForecastingTotalRowsSet(state, payload) {
      state.total_rows = payload;
    },
    demandForecastingManualOverrideActiveSet(state, payload) {
      state.df_manual_override_active = Boolean(state.df_manual_override_active || payload?.manual_override);
    },
    demandForecastingManualOverrideActiveReset(state, payload) {
      state.df_manual_override_active = payload;
    },
    demandForecastingSettingsListSet(state, payload) {
      state.demand_forecasting_settings = payload;
      state.demand_forecasting_settings_initialized = true;
    }
  },
  getters: {
    dfSellersFilterValuesIdsGet(state) {
      return () => {
        return state.sellers_filter.filterValues;
      };
    },
    dfGetProductSkus(state) {
      return () => {
        return state.list.map(product => product.sku);
      };
    },
    dfGetProductAsins(state) {
      return () => {
        return state.list.map(product => product.asin);
      };
    },
    dfGetProductIds(state) {
      return () => {
        return state.list.map(product => [product.seller_id, product.asin, product.sku]);
      };
    },
    demandForecastingSettingsForAccountGet(state) {
      return seller_id => {
        if (!seller_id) {
          return state.demand_forecasting_settings.slice(-1).pop();
        }
        if (state.demand_forecasting_settings.length > 1) {
          return state.demand_forecasting_settings.find(settings => settings.seller_id === seller_id);
        }
        return state.demand_forecasting_settings.slice(-1).pop();
      };
    }
  },
  actions: {
    async demandForecastingList({
      commit
    }, payload) {
      let result = (await request("/inventory/demand_forecasting", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("demandForecastingListSet", result.data);
        commit("demandForecastingTotalRowsSet", result.total_rows);
      }
      return result;
    },
    async demandForecastingSuggestionList({
      commit
    }, payload) {
      let result = (await request("/inventory/demand_forecasting", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("demandForecastingProductsFilterSet", result.data);
      }
      return result;
    },
    async inventoryUpdateProductData({
      state
    }, payload) {
      let result = await request("/inventory/update_product_data", "POST", payload);
      return result;
    },
    async inventoryCreateOrderCsv({
      state
    }, payload) {
      let result = await request("/inventory/create_order_csv", "POST", payload);
      return result;
    },
    async inventoryCreateOrderGoogleSheet({
      state
    }, payload) {
      let result = await request("/inventory/generate_google_sheet", "POST", payload);
      if (result) {
        return result;
      }
    },
    async inventoryProductDataList({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_data", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("demandForecastingListProductDataSet", result.data);
      }
      return result;
    },
    async demandForecastingSettingsSave({
      state
    }, payload) {
      let result = await request("/inventory/update_demand_forecasting_settings", "POST", payload);
      return result;
    },
    async inventoryGetDemandForecastingSettings({
      commit
    }, payload) {
      let result = (await request("/inventory/get_demand_forecasting_settings", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("demandForecastingSettingsListSet", result.data);
      }
      return result;
    }
  }
};
export default demand_forecasting;