import AccountsMixin from "./AccountsMixin.vue";
import { EVENTS } from "@/utils";
import InitializeFiltersMixin from "./InitializeFiltersMixin.vue";
export default {
  name: "ProfitAndLossMixin",
  mixins: [AccountsMixin, InitializeFiltersMixin],
  mounted() {
    window.$bus.on(EVENTS.REFRESH_PROFIT_AND_LOSS_SELLERS_SUB_FILTERS, async () => {
      const sellers = this.$store.state.profitAndLoss.sellers.filterValues;
      const sales_channel = await this.initializeMarketplacesFilters({
        sellers
      }, "profitAndLoss", "marketplaces", "profit_and_loss");
      this.fetchSubFilters({
        sellers,
        sales_channel
      }, "profitAndLoss", {
        product: "products"
      }, "profit_and_loss");
    });
    window.$bus.on(EVENTS.REFRESH_PROFIT_AND_LOSS_MARKETPLACES_SUB_FILTERS, () => {
      const sellers = this.$store.state.profitAndLoss.sellers.filterValues;
      const sales_channel = this.$store.state.profitAndLoss.marketplaces.filterValues;
      this.fetchSubFilters({
        sellers,
        sales_channel
      }, "profitAndLoss", {
        product: "products"
      }, "profit_and_loss");
    });
  }
};