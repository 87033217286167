import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_seller_filter = _resolveComponent("seller-filter");
  const _component_marketplace_filter = _resolveComponent("marketplace-filter");
  const _component_product_filter = _resolveComponent("product-filter");
  const _component_filter_divider = _resolveComponent("filter-divider");
  const _component_filter_item_selector = _resolveComponent("filter-item-selector");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: 16
  }, {
    default: _withCtx(() => [_createVNode(_component_seller_filter, {
      sellers: _ctx.sellers,
      onApply: $options.applySellers,
      onCleared: $options.handleSellerCleared
    }, null, 8 /* PROPS */, ["sellers", "onApply", "onCleared"]), _createVNode(_component_marketplace_filter, {
      marketplaces: _ctx.marketplaces,
      onApply: $options.applyMarketplaces,
      onCleared: $options.handleMarketplaceCleared
    }, null, 8 /* PROPS */, ["marketplaces", "onApply", "onCleared"]), _createVNode(_component_product_filter, {
      products: _ctx.products,
      sellers: _ctx.sellers,
      marketplaces: _ctx.marketplaces,
      onApply: $options.applyProducts
    }, null, 8 /* PROPS */, ["products", "sellers", "marketplaces", "onApply"]), _createVNode(_component_filter_divider), $props.moreFilters && $props.hasAnySelection ? (_openBlock(), _createBlock(_component_wrap_with_popover, {
      key: 0,
      placement: "left",
      width: "397px"
    }, {
      title: _withCtx(() => [_createTextVNode(" The second level filters cannot be hidden unless they're cleared ")]),
      default: _withCtx(() => [_createVNode(_component_filter_item_selector, {
        title: "More filters",
        icon: "filter-add-new-ui",
        "no-caret": "",
        onClick: $options.handleClick,
        disabled: $props.moreFilters && $props.hasAnySelection
      }, null, 8 /* PROPS */, ["onClick", "disabled"])]),
      _: 1 /* STABLE */
    })) : (_openBlock(), _createBlock(_component_filter_item_selector, {
      key: 1,
      title: "More filters",
      icon: "filter-add-new-ui",
      "no-caret": "",
      onClick: $options.handleClick,
      disabled: $props.moreFilters && $props.hasAnySelection
    }, null, 8 /* PROPS */, ["onClick", "disabled"]))]),
    _: 1 /* STABLE */
  });
}