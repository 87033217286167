import { mapState, mapMutations } from "vuex";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import ContentMixin from "@/mixins/ContentMixin.vue";
import ContentDisplayRulesMixin from "@/mixins/ContentDisplayRulesMixin.vue";
import TasksMixin from "@/mixins/TasksMixin.vue";
import Popper from "vue3-popper";
import AppIcon from "@/components/AppIcon";
import AppModal from "@/components/AppModal";
import AppPopover from "@/components/AppPopover";
import ProductScore from "@/components/ProductScore";
import AppTableHeadSeo from "@/components/AppTableHeadSeo";
import AppTd from "@/components/AppTd";
import EditTextModal from "@/components/EditTextModal";
import ImageManagerModal from "@/components/ImageManagerModal";
import SeoEditItemNew from "@/components/SeoEditItemNew";
import ErrorOccured from "@/components/shared/ErrorOccured.vue";
import WrapWithCopy from "@/components/WrapWithCopy.vue";
import SeoRow from "@/components/seo/SeoRow.vue";
import { filterOut, EVENTS } from "@/utils";
export default {
  name: "ContentOptimisationTableNew",
  mixins: [ContentMixin, ValidateMixin, ContentDisplayRulesMixin, TasksMixin, AccountsMixin],
  emits: ["data-loading", "edit-single-item", "reset-paging-values"],
  components: {
    Popper,
    AppIcon,
    AppModal,
    AppPopover,
    ProductScore,
    AppTableHeadSeo,
    AppTd,
    EditTextModal,
    ImageManagerModal,
    SeoEditItemNew,
    ErrorOccured,
    WrapWithCopy,
    // SeoImageRow,
    SeoRow
  },
  data() {
    return {
      editSingleItem: false,
      errorOccured: false,
      sellerAccounts: [],
      countriesMapping: [],
      content_list: [],
      imgWidth: "28px",
      imgHeight: "28px",
      imgBorder: "none",
      sortBy: "country",
      sortDesc: false,
      editTextModal: false,
      seoEditModal: false,
      editAsin: null,
      editValue: "",
      editOriginal: "",
      editContentId: "",
      editContentLink: "",
      editImage: "",
      editProductName: "",
      editSku: "",
      type: "",
      title: "",
      singleContentToPush: {},
      modifiedAsins: [],
      sellerIds: [],
      selectAll: false,
      filter: {
        seller: "",
        country: "",
        sku: "",
        asin: ""
      },
      imageManagerModal: false,
      pushTaskIdentifier: "",
      pushModalActiveButton: true,
      pushModalSpinButton: false,
      confirmPushSingleContent: false,
      editSingleItem: false,
      showNavigationBtn: -1,
      isCopying: false,
      isCollapsedImageList: true,
      showAcceptAmazon: false
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      me: state => state.auth.me,
      products: state => state.content.list,
      modified_product_contents: state => state.content.modified_list,
      current_workspace: state => state.workspace.current_workspace,
      sellers_filter: state => state.content.sellers_filter,
      marketplaces_filter: state => state.content.marketplaces_filter,
      products_filter: state => state.content.products_filter,
      selected_contents: state => state.content.selected_contents,
      current_mode: state => state.content.current_mode,
      pushModal: state => state.content.pushModal,
      page: state => state.content.page,
      perPage: state => state.content.perPage
    }),
    workspaceCurrent() {
      return this.workspaceCurrentOld;
    },
    allowHtmlInputAndSpecialCharacters() {
      return ["product_description"].includes(this.type);
    },
    currentSellers() {
      return this.contentSellersSelectedGet();
    },
    sellersList() {
      return this.contentSellersListGet();
    },
    sellersListSet() {
      return this.contentSellersListSet;
    },
    sellersSelectedSet() {
      return this.contentSellersSelectedSet;
    },
    countriesList() {
      return this.contentCountriesListGet();
    },
    countriesListSet() {
      return this.contentCountriesListSet;
    },
    countriesSelectedSet() {
      return this.contentCountriesSelectedSet;
    },
    currentCountries() {
      return this.contentCountriesSelectedGet();
    },
    contentsList() {
      return this.contentSuggestionsListGet();
    },
    contentsSelectedSet() {
      return this.contentSuggestionsSelectedSet;
    },
    currentContents() {
      return this.contentSuggestionsSelectedGet();
    },
    currentTotal() {
      return this.totalCountGet();
    },
    totalCountSet() {
      return this.contentTotalCountSet;
    },
    filtersSelectedForSearch() {
      return this.contentFiltersSelectedGet;
    },
    filtersSelectedForSpecificSearch() {
      return this.modifiedContentsSelectedGet;
    },
    filterOptions() {
      const result = {
        seller: [],
        country: [],
        sku: [],
        asin: []
      };
      this.products.forEach(item => {
        if (!result.seller.includes(item.seller_id)) {
          result.seller.push(item.seller_id);
        }
        const country = this.getCountry(item.marketplace_id);
        if (!result.country.includes(country)) {
          result.country.push(country);
        }
        if (!result.sku.includes(item.sku)) {
          result.sku.push(item.sku);
        }
        if (!result.asin.includes(item.asin)) {
          result.asin.push(item.asin);
        }
      });
      return result;
    },
    tableFields() {
      const tableHeaders = [{
        key: "product_name",
        label: "Product name",
        sortable: true,
        stickyColumn: true,
        left: this.pushModal ? "20.4px" : "0px"
      }, {
        key: "images",
        label: "Images",
        align: "center"
      }, {
        key: "points",
        label: "Bullet Points",
        colspan: 5
      }, {
        key: "generic_keyword",
        label: "Search Terms",
        align: "center",
        sortable: true
      }, {
        key: "product_description",
        label: "Description",
        sortable: true
      }, {
        key: "image_list",
        label: "Images",
        align: "start",
        expandable: "true",
        isCollapsed: this.isCollapsedImageList
      }, {
        key: "amz_score",
        label: "AMZ",
        align: "center",
        stickyColumn: true,
        right: this.pushModal ? "111px" : "147px"
      }, {
        key: "draft_score",
        label: "Omny",
        align: "center",
        stickyColumn: true,
        right: this.pushModal ? "79px" : "115px"
      }, {
        key: "push_status",
        label: "Push Status",
        align: "center",
        sortable: true,
        stickyColumn: true,
        right: this.pushModal ? "0px" : "36px"
      }, {
        key: "edit",
        align: "center",
        stickyColumn: true,
        showHeader: !this.pushModal,
        right: "0px"
      }];
      let headersWithCheckbox = [{
        key: "checkbox",
        label: "",
        sortable: true,
        stickyColumn: true
      }, ...tableHeaders];
      headersWithCheckbox.pop();
      return this.pushModal ? headersWithCheckbox : tableHeaders;
    },
    filteredItems() {
      return this.products.map(item => {
        const rulesOmnyScore = this.statusValidate(item, "omny");
        const rulesAmzScore = this.statusValidate(item, "amazon");
        const country = this.getCountry(item.marketplace_id);
        const imageList = this.filterImageList(item.image_list);
        const productImage = this.getProductImage(item.image_list);
        return {
          listing_status: item.listing_status,
          image_small: productImage?.link,
          image_list: imageList,
          statusResultOmnyScore: rulesOmnyScore,
          statusOmnyScore: this.statusCalculate(rulesOmnyScore),
          statusResultAmzScore: rulesAmzScore,
          statusAmzScore: this.statusCalculate(rulesAmzScore),
          sku: item.sku,
          asin: item.asin,
          content_id: item.content_id,
          amazon_link: item.amazon_link,
          modified_attributes: item.modified_attributes,
          product_name: this.contentToDisplay(item, "product_name"),
          product_name_style: this.contentToDisplayTextStyle(item, "product_name"),
          product_name_icon: this.contentIconToDisplay(item, "product_name"),
          count_images: item.count_images,
          count_images_style: this.mValidateNumberOfImage(item.count_images),
          bullet_point_1: this.contentToDisplay(item, "bullet_point_1"),
          bullet_point_1_style: this.contentToDisplayTextStyle(item, "bullet_point_1"),
          bullet_point_1_icon: this.contentIconToDisplay(item, "bullet_point_1"),
          bullet_point_2: this.contentToDisplay(item, "bullet_point_2"),
          bullet_point_2_style: this.contentToDisplayTextStyle(item, "bullet_point_2"),
          bullet_point_2_icon: this.contentIconToDisplay(item, "bullet_point_2"),
          bullet_point_3: this.contentToDisplay(item, "bullet_point_3"),
          bullet_point_3_style: this.contentToDisplayTextStyle(item, "bullet_point_3"),
          bullet_point_3_icon: this.contentIconToDisplay(item, "bullet_point_3"),
          bullet_point_4: this.contentToDisplay(item, "bullet_point_4"),
          bullet_point_4_style: this.contentToDisplayTextStyle(item, "bullet_point_4"),
          bullet_point_4_icon: this.contentIconToDisplay(item, "bullet_point_4"),
          bullet_point_5: this.contentToDisplay(item, "bullet_point_5"),
          bullet_point_5_style: this.contentToDisplayTextStyle(item, "bullet_point_5"),
          bullet_point_5_icon: this.contentIconToDisplay(item, "bullet_point_5"),
          generic_keyword: this.contentToDisplay(item, "generic_keyword"),
          generic_keyword_style: this.contentToDisplayTextStyle(item, "generic_keyword"),
          generic_keyword_icon: this.contentIconToDisplay(item, "generic_keyword"),
          product_description: this.contentToDisplay(item, "product_description"),
          product_description_style: this.contentToDisplayTextStyle(item, "product_description"),
          product_description_icon: this.contentIconToDisplay(item, "product_description"),
          country,
          flag: this.getCountryFlag(this.getCountryCode(country)),
          isSelected: this.selected_contents.includes(item.asin),
          last_push_date: this.contentLastPushDate(item).text,
          show_omny_validation_status: this.contentShowOmnyValidationStatus(item),
          content_evaluation_status: this.contentEvaluateItem(item),
          statusPush: this.contentPushIconToDisplay(item)
        };
      });
    },
    tableItems() {
      //return [...this.sortedItems(this.filteredItems)];
      return [...this.filteredItems];
    },
    taskStatusMessages() {
      return {
        NOTASK: "Push to Amazon task is not running",
        PENDING: "Push to Amazon task is pending",
        STARTED: "Push to Amazon task is running",
        RETRY: "Push to Amazon task is being retried",
        SUCCESS: "Push to Amazon task is successful",
        FAILURE: "Push to Amazon task failed"
      };
    },
    pushButtonName() {
      return this.selected_contents.length > 0 ? "Push selected updates to Amazon" : "Push all updates to Amazon";
    }
  },
  async mounted() {
    //const data = await this.getCountries();
    //this.contentCountriesListSet(data);
    if (!this.pushModal) {
      this.$emit("data-loading", true);
      try {
        await this.refreshSellers();
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE, this.refreshSellers);
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE_COUNTRIES, this.refreshCountries);
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE_CONTENT_AND_SUGGESTIONS, this.refreshContentsAndSuggestions);
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE, this.refreshContents);
        window.$bus.on(EVENTS.REFRESH_SEO_PAGE_SUGGESTIONS, this.refreshSuggestions);
        window.$bus.on(EVENTS.SEO_PUSH_MODAL_CANCEL, this.onPushModalCancel);
        window.$bus.on(EVENTS.SEO_PUSH_MODAL_SUBMIT_MODIFIED_CONTENT, this.onPushModalSubmitModifiedContent);
        window.$bus.on(EVENTS.SEO_PUSH_MODAL_OPEN, this.onAmazonPush);
        this.pushTaskIdentifier = this.current_workspace.workspace_id + "_" + "content_push";
        this.getAsyncUpdate(this.pushTaskIdentifier);
        this.checkTaskStatusPeriodically(this.pushTaskIdentifier);
        this.$emit("data-loading", false);
        this.errorOccured = false;
      } catch (error) {
        console.log("Error", error);
        this.$emit("data-loading", false);
        this.errorOccured = true;
      }
    }
  },
  methods: {
    ...mapMutations(["contentCurrentModeSet", "contentSelectedContentsSet", "contentPushModalStatusSet"]),
    sortedItems(items) {
      return items.sort((a, b) => {
        const aaa = a[this.sortBy];
        const bbb = b[this.sortBy];
        if (typeof aaa != "undefined" && typeof bbb != "undefined") {
          return this.sortDesc ? bbb.toLowerCase().localeCompare(aaa.toLowerCase()) : aaa.toLowerCase().localeCompare(bbb.toLowerCase());
        }
        return 0;
      });
    },
    toggleSelectAll(checked) {
      this.contentSelectedContentsSet(checked ? this.tableItems.map(item => item) : []);
      this.selectAll = checked;
    },
    updateSelectedItems(item, isSelectedItem) {
      let item_suggestion = this.contentSuggestionsListGet().find(suggestion => item.asin == suggestion.asin);
      if (isSelectedItem) {
        this.handleContentSelect({
          item: item_suggestion,
          only: false
        });
      } else {
        this.handleContentDeselect(item_suggestion);
      }
    },
    selectItem($event, item) {
      if ($event.target.checked) {
        this.contentSelectedContentsSet(item);
      } else {
        this.contentSelectedContentsSet(this.selected_contents.filter(selected => item.content_id != selected.content_id));
      }
      //this.updateSelectedItems(item,$event.target.checked);
    },
    isSelectedItem(item) {
      return this.selected_contents.some(aValue => item.content_id == aValue.content_id);
    },
    onEdit(item, type, title) {
      console.log('item', item, type, title);
      if (!item.sku) {
        return;
      }
      const originalItem = this.products.find(el => el.content_id == item.content_id);
      if (originalItem && !this.pushModal && !this.confirmPushSingleContent) {
        this.editAsin = item.asin;
        //this.editValue = originalItem.omny_version[type]; //logic to show null
        // this.editValue = originalItem.omny_version[type]
        //   ? originalItem.omny_version[type]
        //   : originalItem.amazon_version[type];
        // this.editOriginal = originalItem.amazon_version[type]; //old logic for showing amazon version

        this.editValue = this.contentToDisplay(originalItem, type, true);
        this.editOriginal = originalItem.amazon_version[type];
        this.showAcceptAmazon = item[type + '_style'].isNotAccepted && originalItem?.amazon_version[type] != "";
        this.editTextModal = true;
        this.type = type;
        this.title = title;
        this.editProductName = item.product_name;
        this.editSku = item.sku;
        this.editImage = item.image_small;
        this.editDescription = item.product_description;
        this.editBulletPoints = [item.bullet_point_1 ?? "", item.bullet_point_2 ?? "", item.bullet_point_3 ?? "", item.bullet_point_4 ?? "", item.bullet_point_5 ?? ""];
        this.editContentId = item.content_id;
      }
    },
    async onPaste(item, type, title) {
      if (!item.sku) {
        return;
      }
      const originalItem = this.products.find(el => el.content_id == item.content_id);
      if (originalItem && !this.pushModal && !this.confirmPushSingleContent) {
        try {
          const copiedValue = await navigator.clipboard?.readText();
          this.setConfigForSubmit(item, originalItem, type, copiedValue);
          this.title = title;
          this.onSubmit();
          this.isCopying = false;
        } catch (err) {
          window.$bus.trigger("showDanger", {
            visible: true,
            message: "Clipboard permission is denied. Please activate clipboard permission on your browser and try again",
            delay: 59999 // Show alert less than 1 minute
          });
          this.isCopying = false;
          return;
        }
      }
    },
    onUpdate(val) {
      this.editValue = val;
    },
    handleIdle(data) {
      this.pushModalActiveButton = true;
      this.pushModalSpinButton = false;
      // if (data) {
      //   window.$bus.trigger("showWarning", {
      //     visible: true,
      //     message: this.taskStatusMessages[data.task_status],
      //   });
      // }
    },
    async handleFailed(data) {
      this.pushModalActiveButton = true;
      this.pushModalSpinButton = false;
      window.$bus.trigger("showDanger", {
        visible: true,
        message: this.taskStatusMessages[data.task_status],
        delay: 59999 // Show alert less than 1 minute
      });
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    async handleRunning(data) {
      this.pushModalActiveButton = false;
      this.pushModalSpinButton = true;
      data.task_result?.map(item => this.contentUpdateAfterPush(item));
      window.$bus.trigger("showInfo", {
        visible: true,
        message: this.taskStatusMessages[data.task_status],
        delay: 59999 // Show alert less than 1 minute
      });
      this.checkTaskStatusPeriodically(this.pushTaskIdentifier);
    },
    async handleFinished(data) {
      this.pushModalActiveButton = true;
      this.pushModalSpinButton = false;
      if (this.asyncUpdateInterval) {
        clearTimeout(this.asyncUpdateInterval);
        this.asyncUpdateInterval = null;
      }
      data.task_result?.map(item => this.contentUpdateAfterPush(item));
      window.$bus.trigger("showSuccess", {
        visible: true,
        message: this.taskStatusMessages[data.task_status],
        delay: 5999 // Show alert less than 1 minute
      });
      await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
    },
    modifiedContentToPush() {
      return this.selected_contents.length > 0 ? this.selected_contents : this.filteredItems;
    },
    statusCalculate(rules) {
      var score_sum = 0;
      var coefficient_sum = 0;
      rules.forEach(rule => {
        score_sum += rule.score * rule.coefficient;
        coefficient_sum += rule.coefficient;
      });
      const global_score = Math.floor(score_sum / coefficient_sum);
      if (global_score >= 7) {
        return {
          status: "success",
          score: global_score
        };
      } else if (global_score >= 4 && global_score < 7) {
        return {
          status: "warning",
          score: global_score
        };
      } else if (global_score < 4) {
        return {
          status: "error",
          score: global_score
        };
      }

      /* if (rules.some((item) => item.status == "error")) {
              return "error";
            }
      
            const warnings = rules.find((item) => item.status == "warning");
            if (warnings && warnings.length > 3) {
              return "warning";
            }
      
            return "success"; */
    },
    async onSubmit() {
      this.contentFieldSet({
        asin: this.editAsin,
        type: this.type,
        value: this.editValue,
        content_id: this.editContentId
      });
      const data = await this.contentUpdate({
        content_id: this.editContentId
      });
      if (data) {
        if (!this.modifiedAsins.includes(this.editAsin)) {
          this.modifiedAsins.push(this.editAsin);
        }
        this.editTextModal = false;
        this.editAsin = null;
        this.editValue = "";
        this.editOriginal = "";
        this.editContentId = "";
        this.type = "";
        this.title = "";
        await window.$bus.trigger(EVENTS.REFRESH_SEO_PAGE_CONTENT_TABLE);
      }
    },
    onCloseEdit() {
      this.editTextModal = false;
      this.editAsin = null;
      this.editValue = "";
      this.editOriginal = "";
      this.editContentId = "";
      this.type = "";
      this.title = "";
      this.editImage = "";
      this.editProductName = "";
    },
    onEditSingleItem(item) {
      if (!item.sku) {
        return;
      }
      this.$emit("edit-single-item", true);
      this.editSingleItem = true;
      this.editContentId = item.content_id;
      this.editContentLink = item.amazon_link;
    },
    onEditSingleItemBack() {
      this.$emit("edit-single-item", false);
      this.editSingleItem = false;
    },
    onImageChange(item) {
      //we comment it temporarily because we might work on the image upload or image processing
      // const originalItem = this.products.find((el) => el.asin == item.asin);

      // this.imageManagerModal = true;
      // this.editAsin = item.asin;
      // this.editImage =
      //   originalItem.image_large ||
      //   originalItem.image_medium ||
      //   originalItem.image_small;

      //temporary solution
      let link = item.amazon_link;
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "https://" + link;
      }
      window.open(link, "_blank");
    },
    onImageUpdate(val) {
      this.editImage = val;
    },
    onImageSubmit(val) {
      this.contentFieldSet({
        asin: this.editAsin,
        type: "image",
        value: val
      });
      if (!this.modifiedAsins.includes(this.editAsin)) {
        this.modifiedAsins.push(this.editAsin);
      }
      this.imageManagerModal = false;
      this.editAsin = null;
      this.editImage = "";
    },
    async onPushModalSubmitModifiedContent() {
      await this.onPushModalSubmit(this.modifiedContentToPush());
    },
    getPushStatusClass(item) {
      return `seo-popover ${item.statusPush.status}`;
    },
    showNavigation(index) {
      this.showNavigationBtn = index;
    },
    hideNavigation() {
      this.showNavigationBtn = -1;
    },
    isCopied(refName) {
      this.isCopying = true;
      let cell = this.$refs[refName];
      let copyClass = " is-copied";
      cell[0].className += copyClass;
      setTimeout(() => {
        cell[0].className = cell[0].className.replace(copyClass, "");
      }, 1000);
    },
    onCopyingHover(refName) {
      if (this.isCopying) {
        let cell = this.$refs[refName];
        let copyClass = " copying-hovered";
        cell[0].className = !cell[0].className.includes(copyClass) ? cell[0].className + copyClass : cell[0].className;
      }
    },
    onCopyingHoverLeave(refElement) {
      let cell = refElement;
      let copyClass = "copying-hovered";
      cell.className = cell.className.replace(copyClass, "");
    },
    keydownEscHandler() {
      this.isCopying = false;
    },
    keepFocus() {
      if (this.isCopying) {
        let divFocusable = this.$refs["seo-table"];
        divFocusable.focus();
      }
    },
    filterImageList(imageList) {
      let array = [];
      imageList.forEach(image => {
        if (!array.some(item => item.variant === image.variant)) {
          array.push(image);
        }
      });
      array.forEach(image => {
        const priorityOrder = ["image_large", "image_medium", "image_small"];
        let hoverImage = imageList.sort((a, b) => priorityOrder.indexOf(a.image_size) - priorityOrder.indexOf(b.image_size)).find(img => img.variant === image.variant);
        image["hover_img_link"] = hoverImage.link;
        image["hover_img_size"] = hoverImage.image_size;
      });
      if (array.length < 9) {
        for (let i = array.length; i < 9; i++) {
          let emptyImage = {
            variant: "PT0" + (i - 1),
            image_size: "",
            link: "",
            hover_img_link: ""
          };
          array.push(emptyImage);
        }
      }
      return array;
    },
    getProductImage(imageList) {
      const priorityOrder = ["image_small", "image_medium", "image_large"];
      return imageList.sort((a, b) => priorityOrder.indexOf(a.image_size) - priorityOrder.indexOf(b.image_size)).find(img => img.variant === "MAIN");
    },
    expandImageList() {
      this.isCollapsedImageList = false;
      this.scrollToRight();
    },
    collapseImageList() {
      this.isCollapsedImageList = true;
    },
    scrollToRight() {
      let table = this.$refs["seo-table-content"];
      if (!table) {
        return;
      }
      this.$nextTick(() => {
        table.scroll({
          behavior: "smooth",
          left: table.scrollWidth
        });
      });
    },
    setConfigForSubmit(item, originalItem, key, copiedValue) {
      this.editAsin = originalItem.asin;
      this.editValue = copiedValue;
      this.editOriginal = originalItem.amazon_version[key];
      this.type = key;
      this.editContentId = item.content_id;
    },
    handlePushItem(item) {
      const specialCases = ["content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_old_push_contribution_modified", "content_on_omny_difference_from_amazon_and_amazon_equal_sc_with_old_push_direct_modification", "content_on_omny_equal_sc_and_omny_difference_from_amazon_with_push", "content_on_omny_diffrence_from_sc_and_sc_difference_amazon_with_push"];
      const originalItem = this.products.find(element => element.content_id == item.content_id);
      let content_evaluation_status = item.content_evaluation_status;
      if (content_evaluation_status[0] == "no_content_on_omny_and_sc_difference_from_amazon") {
        Object.keys(originalItem.amazon_version).map(key => {
          if (this.noContentOnOmnyAndSCDifferenceFromAmazonStatus(originalItem, key)) {
            const copiedValue = originalItem.amazon_seller_central_version[key];
            this.setConfigForSubmit(item, originalItem, key, copiedValue);
            this.onSubmit();
          }
        });
      }
      if (specialCases.includes(content_evaluation_status[0])) {
        let all_attributes = Object.keys(originalItem.amazon_version);
        let attributes_to_modify = new Set(originalItem.modified_attributes || []);
        all_attributes.map(attribute => {
          if (!this.isOmnyVersionSameAsAmazon(originalItem, attribute)) {
            attributes_to_modify.add(attribute);
          }
        });
        this.contentUpdateAfterPush({
          modified_attributes: [...attributes_to_modify]
        });
      }
      this.onConfirmPushSingleContent(item);
    }
  }
};