import { mapState, mapActions } from 'vuex';
import AppUpload from '@/components/AppUpload';
export default {
  name: 'ImageManager',
  emits: ['update:modelValue'],
  components: {
    AppUpload
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      file: null,
      loading: false
    };
  },
  computed: {
    ...mapState({
      images: state => state.content.images
    })
  },
  async mounted() {
    //await this.imageList()
  },
  methods: {
    ...mapActions(['imageList', 'imageUpload']),
    onImageClick(val) {
      this.$emit('update:modelValue', val);
    },
    async onUpload(file) {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', file);
      const result = await this.imageUpload(formData);
      if (result) {
        await this.imageList();
      }
      this.loading = false;
      this.$refs.uploadRef.clear();
    }
  }
};