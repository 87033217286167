import AppIcon from "@/components/AppIcon.vue";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
export default {
  name: "AiCategorySectionTitle",
  components: {
    AppIcon,
    WrapWithPopover
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    details: {
      type: String,
      default: ""
    }
  }
};