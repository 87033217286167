import FlexRow from '@/components/FlexRow.vue';
import LayerBars from '@/components/tableElements/LayerBars.vue';
import VariableStyledBar from '@/components/tableElements/VariableStyledBar.vue';
export default {
  components: {
    FlexRow,
    LayerBars,
    VariableStyledBar
  },
  props: {
    value1: {
      type: Number
    },
    value2: {
      type: Number
    },
    min1: {
      type: Number
    },
    min2: {
      type: Number
    },
    max1: {
      type: Number
    },
    max2: {
      type: Number
    },
    color1: {
      type: String,
      default: "#DDE1E6"
    },
    color2: {
      type: String,
      default: "#8F9EAE"
    }
  },
  computed: {
    validValue() {
      return this.value1 !== null && this.value2 !== null;
    }
  }
};