import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4f325763"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "d-flex flex-column"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_textarea = _resolveComponent("app-textarea");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["d-flex flex-row justify-content-between", {
      'order-1 form-label': $props.bar == 'top',
      'order-2': $props.bar == 'bottom',
      'd-none': $props.bar == 'none'
    }])
  }, [_createElementVNode("div", null, [_createElementVNode("b", null, _toDisplayString($props.title), 1 /* TEXT */)]), _createCommentVNode(" <div>\n        {{ value.length }}\n      </div> "), _createElementVNode("div", {
    class: _normalizeClass({
      error: $options.value.length > $props.maximum_characters
    })
  }, _toDisplayString($options.characterCountInfo), 3 /* TEXT, CLASS */)], 2 /* CLASS */), _createVNode(_component_app_textarea, {
    modelValue: $options.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.value = $event),
    class: _normalizeClass(["error-box", {
      'order-2': $props.bar == 'top',
      'order-1': $props.bar == 'bottom',
      error: $options.value.length > $props.maximum_characters
    }])
  }, null, 8 /* PROPS */, ["modelValue", "class"])]);
}