import AppIcon from "@/components/AppIcon.vue";
export default {
  name: "CategoryItemNew",
  components: {
    AppIcon
  },
  emits: ["selectCategory", "edit-category"],
  props: {
    id: {
      type: Number,
      default: 0
    },
    category: {
      type: String,
      default: ""
    },
    score: {
      type: Number,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    manual: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "product_benefits"
    }
  },
  data() {
    return {
      category_before_edit: ""
    };
  },
  computed: {
    getIconColor() {
      return this.custom ? "#4C8EFF" : this.type == "product_benefits" ? "#8D85E5" : "#FF8933";
    },
    getClassPerType() {
      return {
        product_benefits: this.type == "product_benefits" ?? false,
        product_usecases: this.type == "product_usecases" ?? false
      };
    }
  },
  methods: {
    handleCategoryClick() {
      this.$emit("selectCategory", {
        id: this.id,
        category: this.category,
        score: this.score,
        custom: this.custom,
        edit: this.edit,
        manual: this.manual
      });
    },
    editCategory() {
      if (!this.$refs.category_name.innerText && this.manual) {
        this.handleCategoryClick();
        return;
      }
      this.$emit("edit-category", {
        id: this.id,
        content: {
          category: this.$refs.category_name.innerText,
          edit: false,
          custom: true
        }
      });
      this.category_before_edit = "";
    },
    setCategoryEdit() {
      this.category_before_edit = this.category;
      this.$emit("edit-category", {
        id: this.id,
        content: {
          category: this.category,
          edit: true,
          active: this.active,
          custom: this.custom
        }
      });
    },
    unsetCategoryEdit() {
      this.$refs.category_name.innerText = this.category_before_edit;
      if (!this.$refs.category_name.innerText && this.manual) {
        this.handleCategoryClick();
        return;
      }
      this.$emit("edit-category", {
        id: this.id,
        content: {
          category: this.category_before_edit,
          edit: false,
          active: this.active,
          custom: this.custom
        }
      });
      this.category_before_edit = "";
    }
  }
};