import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_search_element = _resolveComponent("search-element");
  const _component_marketplace_filter_item = _resolveComponent("marketplace-filter-item");
  const _component_selected_section = _resolveComponent("selected-section");
  const _component_list_section = _resolveComponent("list-section");
  const _component_WrapWithDropdownBody = _resolveComponent("WrapWithDropdownBody");
  return _openBlock(), _createBlock(_component_WrapWithDropdownBody, {
    onHidden: _cache[4] || (_cache[4] = $event => _ctx.$emit('hidden'))
  }, {
    searcher: _withCtx(() => [_createVNode(_component_search_element, {
      placeholder: "Search marketplaces...",
      onSearch: _cache[0] || (_cache[0] = val => _ctx.$emit('search', val))
    })]),
    selected: _withCtx(() => [_createVNode(_component_selected_section, {
      title: "Marketplaces",
      count: $props.selected.length,
      onClear: _cache[1] || (_cache[1] = $event => _ctx.$emit('clear')),
      onApply: _cache[2] || (_cache[2] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _cache[3] || (_cache[3] = $event => _ctx.$emit('closeDropdown')),
      isModified: $props.isModified
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selected, (item, index) => {
        return _openBlock(), _createBlock(_component_marketplace_filter_item, {
          key: index,
          name: item.name,
          icon: item.icon,
          item: item,
          selected: "",
          onDeselect: item => _ctx.$emit('deselect', item)
        }, null, 8 /* PROPS */, ["name", "icon", "item", "onDeselect"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count", "isModified"])]),
    list: _withCtx(() => [_createVNode(_component_list_section, {
      title: "Marketplaces",
      count: $props.list.length
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item, index) => {
        return _openBlock(), _createBlock(_component_marketplace_filter_item, {
          key: index,
          name: item.name,
          icon: item.icon,
          item: item,
          onSelect: item => _ctx.$emit('select', item),
          onSelectOnly: item => _ctx.$emit('selectOnly', item)
        }, null, 8 /* PROPS */, ["name", "icon", "item", "onSelect", "onSelectOnly"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count"])]),
    _: 1 /* STABLE */
  });
}