import { mapActions } from "vuex";
import { isObjectEmpty } from "@/utils";
export default {
  name: "DownloadMixin",
  computed: {
    taskStatusHandlers() {
      return {
        NOTASK: this.handleIdle,
        PENDING: this.handleRunning,
        STARTED: this.handleRunning,
        RETRY: this.handleRunning,
        SUCCESS: this.handleFinished,
        FAILURE: this.handleFailed
      };
    }
  },
  data() {
    return {
      asyncUpdateInterval: null,
      asyncUpdateFileUpload: null
    };
  },
  methods: {
    ...mapActions(["tasksGetAsyncUpdate", "tasksGetAsyncFileUploadUpdate"]),
    //csv_headers ["",""], csv_rows [{} {}]
    async generateCsvStringWithPagination(csv_headers, totalPages, downloadCsvRowsCallback, nullReplacer = '', rowLimit = 10_000) {
      let result = "";
      let currentPage = 1;
      let rowCount = 0;
      try {
        while (currentPage <= totalPages) {
          let csv_rows = await downloadCsvRowsCallback(currentPage);
          rowCount += csv_rows.length ?? 0;
          //Add CSV content from this page, keep header only in the first page
          let csv_string = await this.generateCsvString(csv_headers, csv_rows, currentPage, nullReplacer);
          // this.throwIfError(csv_string); // TODO: Fix this 
          result += csv_string;
          if (rowCount > rowLimit) break;
          currentPage++;
        }
        return result;
      } catch (error) {
        //console.log(error);
        window.$bus.trigger("showDanger", {
          message: "Error generating or downloading CSV" + error,
          visible: true,
          delay: 10
        });
        throw new Error("Error generating or downloading CSV");
      }
    },
    //csv_headers {header:fieldName} , csv_rows [{fieldName:value},{fieldName:value}]
    async generateCsvString(csv_headers, csv_rows, currentPage = 1, nullReplacer = '') {
      try {
        let replacer = (_, value) => value === null ? nullReplacer : value; // specify how you want to handle null values here
        let csv_string = currentPage === 1 ? Object.keys(csv_headers).join(";") : ""; // header row first
        csv_string = [csv_string, ...csv_rows.map(row => Object.keys(csv_headers).map(fieldName => JSON.stringify(row[csv_headers[fieldName]], replacer)).join(";") //updated from comma to semi-colon separated
        )].join("\r\n");
        return csv_string.replace(/\\"/g, '""');
      } catch (error) {
        window.$bus.trigger("showDanger", {
          message: "Error generating or downloading CSV" + error,
          visible: true,
          delay: 10
        });
        throw new Error("Error generating or downloading CSV");
      }
    },
    async downloadCsv(csv_string, fileName) {
      const bom = '\uFEFF'; //this has been added because tools like Excel and other they need to be notified that the document might containt utf-8
      var csv_blob = new Blob([bom + csv_string], {
        type: "text/csv;charset=utf-8;"
      });
      //var csv_blob = new Blob([csv_string], { type: "text/csv;charset=utf-8" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(csv_blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
  }
};