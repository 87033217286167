import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-677bf4ce"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  key: 0,
  class: "subtitle"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_DateValue = _resolveComponent("DateValue");
  const _component_flex_column = _resolveComponent("flex-column");
  const _component_b_container = _resolveComponent("b-container");
  return _openBlock(), _createBlock(_component_b_container, {
    class: "header"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: _normalizeClass(['top-level-aggregator', $props.fieldsClass])
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createVNode(_component_hug_value, {
          styles: {
            padding: '8px',
            height: '36px'
          }
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString($options.format($props.colGroup.label)), 1 /* TEXT */)]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"]), _createVNode(_component_b_row, {
      class: _normalizeClass(['fields', $props.fieldsClass])
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.colGroup.fields, (field, index) => {
        return _openBlock(), _createBlock(_component_b_col, {
          key: index
        }, {
          default: _withCtx(() => [_createVNode(_component_hug_value, {
            loading: $props.loading,
            align: field.justify,
            styles: {
              padding: '8px',
              alignItems: 'flex-start',
              height: 'unset'
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_flex_column, {
              gap: 4,
              styles: {
                height: 'unset',
                padding: 0
              }
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString($options.format(field.label)), 1 /* TEXT */), field.showSubtitle ? (_openBlock(), _createElementBlock("div", _hoisted_2, ['subtitle' in field ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, ['type' in field && field.type === 'currency' ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($options.format(`${field.subtitle}${_ctx.config.symbol}`)), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($options.format(field.subtitle)), 1 /* TEXT */))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_DateValue, {
                key: 1,
                value: field.current ? $props.period1 : $props.period2,
                format: "YYYY-MM",
                align: field.justify,
                styles: {
                  padding: 0
                }
              }, null, 8 /* PROPS */, ["value", "align"]))])) : _createCommentVNode("v-if", true)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "align"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"])]),
    _: 1 /* STABLE */
  });
}