import AppIcon from "@/components/AppIcon";
export default {
  name: "AppTableHead",
  emits: ["update:sortBy", "update:sortDesc", "selectAll"],
  components: {
    AppIcon
  },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectall: false
    };
  },
  methods: {
    onSort(field) {
      if (field.sortable) {
        if (this.sortBy == field.key) {
          this.$emit("update:sortDesc", !this.sortDesc);
        } else {
          this.$emit("update:sortBy", field.key);
          this.$emit("update:sortDesc", false);
        }
      }
    },
    onSelectAll(val) {
      this.$emit("selectAll", val);
    }
  }
};