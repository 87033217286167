import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4cb4e156"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  ref: "pdfWrapper",
  class: "pdf-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_table_graph = _resolveComponent("table-graph");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getPlotData, (plot, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      style: _normalizeStyle({
        ...$options.getPageDimension,
        background: '#fff',
        padding: '1rem',
        boxSizing: 'border-box',
        pageBreakAfter: 'always',
        pageBreakBefore: 'always'
      })
    }, [_createVNode(_component_table_graph, {
      graphId: plot.graphId,
      graphTitle: plot.graphTitle,
      graphHeaders: plot.graphHeaders,
      tableLoading: false,
      positions: plot.positions,
      totalPositions: plot.total,
      minMax: plot.minMax,
      noProductImage: false,
      productFullText: false,
      setStriped: false,
      displayTotal: plot.showTotal,
      breakProductName: true,
      truncateProductName: true,
      dataWidths: $options.getTotalWidths(index),
      currentViewPeriodOptions: plot.currentViewPeriodOptions
    }, null, 8 /* PROPS */, ["graphId", "graphTitle", "graphHeaders", "positions", "totalPositions", "minMax", "displayTotal", "dataWidths", "currentViewPeriodOptions"])], 4 /* STYLE */);
  }), 128 /* KEYED_FRAGMENT */))], 512 /* NEED_PATCH */);
}