export default {
  name: 'AlertsChart',
  emits: ['select'],
  props: {
    type: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    selectedRowId: {
      type: String,
      default: null
    },
    selectedLevel: {
      type: String,
      default: null
    }
  },
  computed: {
    title() {
      return (this.type == 'sellers' ? 'SELLERS' : '') || (this.type == 'categories' ? 'ALERT TYPE' : '');
    },
    percents() {
      return value => {
        const max = Math.ceil(Math.max(...this.items.map(item => item.alerts.total)) / 6) * 6;
        return max > 0 ? Math.floor(value * 100 / max) : 0;
      };
    },
    value() {
      return index => {
        const max = Math.ceil(Math.max(...this.items.map(item => item.alerts.total)) / 6) * 6;
        return index == 0 ? 0 : Math.round(max / 6 * index * 100) / 100;
      };
    }
  },
  methods: {
    onToggleSelect(id, level) {
      this.$emit('select', {
        id,
        level,
        type: this.type
      });
    }
  }
};