import AppIcon from '@/components/AppIcon';
import AppInput from '@/components/AppInput';
import AppPopover from '@/components/AppPopover';
import AppSwitch from '@/components/AppSwitch';
import DemandForecastingSettingsPopover from '@/components/DemandForecastingSettingsPopover';
import DemandForecastingMixins from "@/mixins/DemandForecastingMixins.vue";
export default {
  name: 'DemandForecastingSettingsCoverage',
  emits: ['update'],
  mixins: [DemandForecastingMixins],
  components: {
    AppIcon,
    AppPopover,
    AppInput,
    AppSwitch,
    DemandForecastingSettingsPopover
  },
  props: {
    settings: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      block: 1,
      popover: {}
    };
  },
  computed: {
    currentSettings() {
      return JSON.parse(JSON.stringify(this.settings));
    },
    totalCoverage() {
      return Math.round(this.totalCoverageFromSettings(this.currentSettings));
    },
    fbaBufferStock() {
      return Math.round(this.fbaBufferStockFromSettings(this.currentSettings));
    }
  },
  methods: {
    onBlock(number) {
      this.block = this.block == number ? 0 : number;
    },
    onUpdate() {
      this.$emit('update', this.currentSettings);
    },
    onBlur(key) {
      this.$refs[key].blur();
    },
    onFocus(key) {
      this.$refs[key].focus();
    },
    onUpdateFbaBufferCustom(event) {
      this.currentSettings.fba_buffer_stock_custom = event.target.value;
      this.onUpdate();
    },
    onUpdateFbaBuffer($event) {
      this.currentSettings.fba_buffer_stock_custom = Math.round(this.fbaBufferStockFromSettings(this.currentSettings));
      this.onUpdate();
      ;
    },
    onReset() {
      this.onUpdateFbaBuffer();
    },
    onPopover(prefix, number) {
      this.popover[prefix] = this.popover[prefix] == number ? 0 : number;
    }
  }
};