import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-69ddb950"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "big-wrapper",
  style: {
    "position": "relative"
  }
};
const _hoisted_2 = {
  key: 0,
  class: "edit-page-content-preview-option"
};
const _hoisted_3 = {
  key: 0,
  class: "edit-page-content-preview-header"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "preview-text"
};
const _hoisted_6 = {
  class: "copy-action-icon"
};
const _hoisted_7 = {
  class: "preview-actions-icon"
};
const _hoisted_8 = {
  class: "preview-actions-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.show_option ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Option " + _toDisplayString($props.option_number), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: _normalizeClass(['edit-page-content-preview', {
      withBorder: $props.withBorder,
      scroll: $props.scroll
    }])
  }, [$props.show_header ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_app_icon, {
    icon: $props.content_type,
    size: "16",
    class: "header-icon"
  }, null, 8 /* PROPS */, ["icon"]), _createElementVNode("div", _hoisted_4, _toDisplayString($props.header_title), 1 /* TEXT */), _createElementVNode("div", {
    class: "close",
    onClick: _cache[0] || (_cache[0] = () => {})
  }, "X")])) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, _toDisplayString($options.removeEndQuote($props.text)), 1 /* TEXT */), _renderSlot(_ctx.$slots, "default", {}, undefined, true), _createElementVNode("div", {
    class: _normalizeClass(['preview-actions', {
      aiContent: $props.content_type == 'ai'
    }])
  }, [_createElementVNode("div", _hoisted_6, [$props.show_add ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "add-icon",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.onAdd && $options.onAdd(...args))
  }, [_createVNode(_component_app_icon, {
    icon: "add-ai"
  })])) : _createCommentVNode("v-if", true), _createElementVNode("div", {
    class: "copy-icon",
    onClick: _cache[2] || (_cache[2] = (...args) => $options.onCopy && $options.onCopy(...args))
  }, [!$data.copied ? (_openBlock(), _createBlock(_component_app_icon, {
    key: 0,
    icon: "copy",
    size: "16",
    color: $props.content_type == 'ai' ? '#736BC9' : '#FF8933'
  }, null, 8 /* PROPS */, ["color"])) : (_openBlock(), _createBlock(_component_app_icon, {
    key: 1,
    icon: "check",
    size: 13,
    color: "#22863D"
  }))])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString($props.text.trim().length) + " character" + _toDisplayString($props.text.trim().length > 1 ? 's' : ''), 1 /* TEXT */)])], 2 /* CLASS */)], 2 /* CLASS */)]);
}