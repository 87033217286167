import AppIcon from "@/components/AppIcon";
import AppButton from "@/components/AppButton";
import AiAssistanceCard from "@/components/AiAssistanceCard.vue";
export default {
  name: "AiAssistance",
  emits: ["close", "open-next"],
  components: {
    AppButton,
    AppIcon,
    AiAssistanceCard
  },
  data() {
    return {
      selected_card: "ai_generation"
    };
  },
  methods: {
    onCardClick(selected) {
      this.selected_card = selected;
    },
    onNextClick() {
      this.$emit("open-next", this.selected_card);
    },
    onClose() {
      this.$emit("close");
    }
  }
};