export default {
  name: "IntercomScript",
  props: {
    appId: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    },
    userEmail: {
      type: String,
      required: true
    },
    showPopup: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.addIntercomScript();
  },
  beforeUnmount() {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  },
  methods: {
    addIntercomScript() {
      var APP_ID = this.appId;
      window.intercomSettings = {
        app_id: APP_ID,
        name: this.userName,
        email: this.userEmail,
        hide_default_launcher: !this.showPopup
      };
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + APP_ID;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }
  }
};