import { FeatureToggleComponent as feature } from "vue-feature-toggle";
//var store = await import("@/store");

function onlyVisibleToOmnyUsersRule() {
  if (window.auth_user) {
    //var auth_user = await store.default.getters.getAuthUser();
    //var auth_workspace = feature.$store.getters.getIsOmnyWorkspace();;
    return window.auth_user.isOmnyAdmin || window.auth_user.isOmnyUser;
  }
  return false;
}
function isInventoryVisibleRule() {
  if (window.auth_user) {
    return !feature.$store.getters.isInventoryDisabledGet();
  }
  return false;
}
function visibleToAllUsersRule() {
  return true;
}
feature.visibility("default", function () {
  return visibleToAllUsersRule();
});
feature.visibility("advertisingfeature", function () {
  return onlyVisibleToOmnyUsersRule();
});
feature.visibility("inventoryfeature", function () {
  return onlyVisibleToOmnyUsersRule() && isInventoryVisibleRule();
});
feature.visibility("productfeature", function () {
  return onlyVisibleToOmnyUsersRule();
});
feature.visibility("helpmenufeature", function () {
  return visibleToAllUsersRule();
});
feature.visibility("profitandlossfeature", function () {
  return visibleToAllUsersRule();
});
feature.visibility("demandforecastingfeature", function () {
  return visibleToAllUsersRule() && isInventoryVisibleRule();
});
feature.visibility("productdatafeature", function () {
  return visibleToAllUsersRule() && isInventoryVisibleRule();
});
feature.visibility("cogsdatafeature", function () {
  return onlyVisibleToOmnyUsersRule();
});
feature.visibility("inventoryfeature", function () {
  return visibleToAllUsersRule() && isInventoryVisibleRule();
});
feature.visibility("alertsfeature", function () {
  return onlyVisibleToOmnyUsersRule();
});
feature.visibility("intercommessagingfeature", function () {
  return true;
});
feature.visibility("dashboard_v2", function () {
  return onlyVisibleToOmnyUsersRule();
});
feature.visibility("keywordsmodulefeature", function () {
  return onlyVisibleToOmnyUsersRule();
});
feature.visibility("advertisingv2feature", function () {
  return visibleToAllUsersRule();
});
feature.visibility("budgetallocationfeature", function () {
  return onlyVisibleToOmnyUsersRule();
});
feature.visibility("ppcsettingsfeature", function () {
  return onlyVisibleToOmnyUsersRule();
});
feature.visibility("seo-v2", function () {
  return onlyVisibleToOmnyUsersRule();
});
export default feature;