export default {
  name: 'AppAlert',
  emits: ['update:modelValue', 'checked', 'unchecked', 'input'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      message: "",
      myvariant: "",
      htmlContent: "",
      dismissSecs: 1000,
      infoCountDown: 0,
      dangerCountDown: 0,
      successCountDown: 0,
      warningCountDown: 0,
      type: "",
      types: {
        INFO: "info",
        DANGER: "danger",
        WARNING: "warning",
        SUCCESS: "success"
      }
    };
  },
  mounted() {
    window.$bus.on('showInfo', this.showInfo);
    window.$bus.on('showDanger', this.showDanger);
    window.$bus.on('showWarning', this.showWarning);
    window.$bus.on('showSuccess', this.showSuccess);
  },
  destroyed() {
    window.$bus.off('showInfo', this.hideInfo);
    window.$bus.off('showDanger', this.hideDanger);
    window.$bus.off('showWarning', this.hideWarning);
    window.$bus.off('showSuccess', this.hideSuccess);
  },
  methods: {
    infoCountDownChanged(dismissCountDown) {
      this.infoCountDown = dismissCountDown;
    },
    dangerCountDownChanged(dismissCountDown) {
      this.dangerCountDown = dismissCountDown;
    },
    warningCountDownChanged(dismissCountDown) {
      this.warningCountDown = dismissCountDown;
    },
    successCountDownChanged(dismissCountDown) {
      this.successCountDown = dismissCountDown;
    },
    showInfo(value) {
      this.message = value.message;
      this.visible = value.visible;
      this.type = this.types.INFO;
      if (value.delay) {
        this.infoCountDown = value.delay;
      } else {
        this.infoCountDown = this.dismissSecs;
      }
    },
    showDanger(value) {
      this.message = value.message;
      this.visible = value.visible;
      this.type = this.types.DANGER;
      this.isHtmlMessage = value.isHtml || false;
      this.htmlContent = value.htmlContent || "";
      if (value.delay) {
        this.dangerCountDown = value.delay;
      } else {
        this.dangerCountDown = this.dismissSecs;
      }
    },
    showSuccess(value) {
      this.message = value.message;
      this.visible = value.visible;
      this.type = this.types.SUCCESS;
      if (value.delay) {
        this.successCountDown = value.delay;
      } else {
        this.successCountDown = this.dismissSecs;
      }
    },
    showWarning(value) {
      this.message = value.message;
      this.visible = value.visible;
      this.type = this.types.WARNING;
      if (value.delay) {
        this.warningCountDown = value.delay;
      } else {
        this.warningCountDown = this.dismissSecs;
      }
    },
    hideInfo() {
      this.message = "";
      this.visible = false;
      this.infoCountDown = 0;
    },
    hideDanger() {
      this.message = "";
      this.visible = false;
      this.dangerCountDown = 0;
    },
    hideWarning() {
      this.message = "";
      this.visible = false;
      this.warningCountDown = 0;
    },
    hideSuccess() {
      this.message = "";
      this.visible = false;
      this.successCountDown = 0;
    }
  }
};