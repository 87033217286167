import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-129d0e69"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "type-name"
};
const _hoisted_2 = {
  class: "type-name-text"
};
const _hoisted_3 = {
  class: "label"
};
const _hoisted_4 = {
  class: "input-group-text"
};
const _hoisted_5 = {
  key: 0,
  class: "input-group-text"
};
const _hoisted_6 = {
  class: "label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_form_radio = _resolveComponent("b-form-radio");
  const _component_WrapWithInfo = _resolveComponent("WrapWithInfo");
  const _component_AppChoiceMaker = _resolveComponent("AppChoiceMaker");
  const _component_b_input_group = _resolveComponent("b-input-group");
  const _component_FlexColumn = _resolveComponent("FlexColumn");
  return _openBlock(), _createElementBlock("div", {
    onClick: _cache[5] || (_cache[5] = $event => _ctx.$emit('select', $props.type)),
    class: _normalizeClass(['type-item', 'cursor-pointer', {
      active: $props.type === $props.method
    }])
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_AppChoiceMaker, null, {
    selector: _withCtx(() => [_createVNode(_component_b_form_radio, {
      modelValue: $options.checked,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.checked = $event)
    }, null, 8 /* PROPS */, ["modelValue"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_WrapWithInfo, {
      info: $props.info
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.title), 1 /* TEXT */)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["info"])])]),
    _: 1 /* STABLE */
  })]), $props.type === $props.method ? (_openBlock(), _createBlock(_component_FlexColumn, {
    key: 0,
    class: "input-wrapper",
    gap: 8
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, _toDisplayString($props.label), 1 /* TEXT */), _createVNode(_component_b_input_group, null, _createSlots({
      default: _withCtx(() => [_withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["form-control", {
          isValid: $data.isValid,
          isUnvalid: !$data.isValid
        }]),
        type: "number",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.v$.inputValue.$model = $event),
        onInput: _cache[2] || (_cache[2] = (...args) => $options.onInput && $options.onInput(...args)),
        min: 0,
        onBlur: _cache[3] || (_cache[3] = (...args) => $options.onBlur && $options.onBlur(...args)),
        onKeyup: _cache[4] || (_cache[4] = _withKeys((...args) => $options.onBlur && $options.onBlur(...args), ["enter"])),
        ref: "ppc_strategies_input"
      }, null, 34 /* CLASS, NEED_HYDRATION */), [[_vModelText, $setup.v$.inputValue.$model]])]),
      _: 2 /* DYNAMIC */
    }, [_ctx.$slots['prepend'] ? {
      name: "prepend",
      fn: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "prepend", {}, undefined, true)])]),
      key: "0"
    } : undefined, _ctx.$slots['append'] ? {
      name: "append",
      fn: _withCtx(() => [_ctx.$slots['append'] ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_renderSlot(_ctx.$slots, "append", {}, undefined, true)])) : _createCommentVNode("v-if", true)]),
      key: "1"
    } : undefined]), 1024 /* DYNAMIC_SLOTS */), _createElementVNode("div", _hoisted_6, _toDisplayString($props.afterLabel), 1 /* TEXT */)]),
    _: 3 /* FORWARDED */
  })) : _createCommentVNode("v-if", true)], 2 /* CLASS */);
}