import { getAiCategoryUseCases } from "@/components/aiCategory/service";
import CategoryWrapper from "./CategoryWrapper.vue";
export default {
  name: "AiCategoryUseCases",
  emits: ["selectedProductUseCases", "productdataLoaded"],
  components: {
    CategoryWrapper
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  async created() {
    try {
      const params = {
        category_id: this.product.category_id,
        marketplace_id: this.product.marketplace_id
      };
      const categories = await getAiCategoryUseCases({
        params
      });
      this.categories = categories.map(category => ({
        ...category,
        active: true
      }));
    } catch (error) {
      //console.log(error);
    }
    this.loading = false;
    this.$emit("selectedProductUseCases", this.categories);
  },
  data() {
    return {
      categories: [],
      loading: true
    };
  },
  methods: {
    getSelectedUseCases() {
      return this.$refs.categoryWrapper.getSelectedCategories();
    },
    onSelectedProductUseCases(val) {
      this.$emit("selectedProductUseCases", val);
    }
  }
};