import AppRating from '@/components/AppRating';
import AppIcon from '@/components/AppIcon';
import { currencies_symbols } from '@/utils/currencies.js';
export default {
  name: 'EditPagePreviewItem',
  components: {
    AppRating,
    AppIcon
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currency: currencies_symbols[this.product.currency],
      delivery_date: ""
    };
  },
  mounted() {
    const today = new Date();
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + 7);
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    };
    this.delivery_date = futureDate.toLocaleDateString('en-US', options);
  }
};