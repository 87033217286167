import { EVENTS } from '@/utils';
export default {
  name: 'App',
  data() {
    return {
      componentKey: 0
    };
  },
  mounted() {
    window.$bus.on(EVENTS.REFRESH_APPLICATION, () => {
      this.forceRerender();
      this.$router.go(0);
    });
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    }
  }
};