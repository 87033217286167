import AppIcon from '@/components/AppIcon.vue';
export default {
  name: 'AlertsPriority',
  components: {
    AppIcon
  },
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    level: {
      type: String,
      default: null
    }
  },
  computed: {
    valueFormated() {
      return (this.value * 1).toFixed(2);
    },
    levelClass() {
      switch (this.level) {
        case '1_URGENCY':
          return 'urgent-color';
        case '2_ALERT':
          return 'alert-color';
        case '3_WARNING':
          return 'warning-color';
        default:
          return '';
      }
    },
    percent() {
      const filteredItems = this.items.filter(item => item.alerting_category === this.level);
      const maxValue = Math.max(...filteredItems.map(item => item.alert_priority * 1));
      return Math.round(this.value * 100 / maxValue * 100) / 100;
    }
  }
};