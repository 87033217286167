import FlexRow from "@/components/FlexRow.vue";
import { COLORS } from "@/utils/constants";
import TableElementsMixin from "@/mixins/TableElementsMixin.vue";
export default {
  name: "EvolutionValue",
  components: {
    FlexRow
  },
  mixins: [TableElementsMixin],
  props: {
    value: {
      type: [Number, null],
      required: true
    },
    change: {
      type: String,
      required: false,
      default: 'positive'
      /**
      * Possible values:
          * - positive:
              *    - we consider positive values as `GREEN`
              *   - we consider negative values as `RED`
          * - negative
              *  - we consider positive values as `RED`
              * - we consider negative values as `GREEN`
       */
    },
    charCount: {
      type: Number
    }
  },
  computed: {
    evolutionColor() {
      const changes = {
        positive: () => {
          return this.value > 0 ? COLORS.EVOLUTION_GREEN : COLORS.EVOLUTION_RED;
        },
        negative: () => {
          return this.value < 0 ? COLORS.EVOLUTION_RED : COLORS.EVOLUTION_GREEN;
        }
      };
      return changes[this.change]();
    },
    getComputedStyle() {
      return {
        width: `${this.charCount}ch`,
        justifyContent: 'flex-end'
      };
    }
  }
};