import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import ValidateMixin from '@/mixins/ValidateMixin';
import AppCard from '@/components/AppCard';
import AppInput from '@/components/AppInput';
import AppButton from '@/components/AppButton';
import AppModal from '@/components/AppModal';
export default {
  name: 'ProfilePassword',
  mixins: [ValidateMixin],
  components: {
    AppCard,
    AppInput,
    AppButton,
    AppModal
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      form: {
        existing_password: '',
        new_password: ''
      },
      isEdit: false,
      loading: false,
      showConfirmModal: false
    };
  },
  methods: {
    ...mapActions(['authPassword']),
    async onSubmit() {
      if (this.isEdit) {
        const valid = await this.mValidate();
        if (valid) {
          this.loading = true;
          await this.authPassword(this.form);
          this.loading = false;
          this.showConfirmModal = true;
          this.onCancel();
        }
      }
    },
    onEdit() {
      this.isEdit = true;
    },
    onCancel() {
      this.isEdit = false;
      this.form.existing_password = '';
      this.form.new_password = '';
      this.v$.$reset();
    }
  }
};