import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    styles: $options.computedStyle,
    loading: $props.loading,
    tight: $props.tight
  }, {
    default: _withCtx(() => [$props.reverse ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [$options.validValue ? (_openBlock(), _createBlock(_component_flex_row, {
      key: 0,
      style: {
        width: '100%',
        justifyContent: 'flex-end'
      }
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "bar", {}, undefined, true)]),
      _: 3 /* FORWARDED */
    })) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true), $options.validValue ? (_openBlock(), _createBlock(_component_flex_row, {
      key: 0,
      style: {
        width: '100%',
        justifyContent: 'flex-start'
      }
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "bar", {}, undefined, true)]),
      _: 3 /* FORWARDED */
    })) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */))]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["styles", "loading", "tight"]);
}