import TableSkeleton from "./TableSkeleton.vue";
export default {
  name: "LoadingTable",
  components: {
    TableSkeleton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};