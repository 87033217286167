import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d367aa48"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  style: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ai_category_section_title = _resolveComponent("ai-category-section-title");
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_category_item = _resolveComponent("category-item");
  const _component_draggable = _resolveComponent("draggable");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ai_category_section_title, {
    title: $props.title
  }, {
    details: _withCtx(() => [_renderSlot(_ctx.$slots, "details", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["title"]), _createElementVNode("div", null, [!$options.noActive ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['category-wrapper-active', {
      'no-inactive': $options.noInactive
    }])
  }, [$props.loading ? (_openBlock(), _createBlock(_component_b_spinner, {
    key: 0
  })) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createElementVNode("div", {
    style: _normalizeStyle($options.getDragStickStyle)
  }, null, 4 /* STYLE */), _createVNode(_component_draggable, {
    ref: "dragContainer",
    modelValue: $options.updatedActiveCategories,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.updatedActiveCategories = $event),
    class: "draggable-wrapper",
    onStart: _cache[1] || (_cache[1] = $event => $data.dragging = true),
    onEnd: _cache[2] || (_cache[2] = $event => $data.dragging = false),
    onMove: $options.handleDragging
  }, {
    item: _withCtx(({
      element
    }) => [_createElementVNode("div", _hoisted_2, [_createCommentVNode(" \n                  The idea of wrapping the `CategoryItem` with another div is so that the dragging\n                  will cover the whole row, and not just the `CategoryItem` itself.\n\n                  This makes dragging easy.\n                  Key Point:\n                    - Wrap with div\n                    - Set the width to 100%\n                    - Set the display to flex\n                    - Set the justifyContent to flex-start (depending on how you justify the elements)\n                 "), (_openBlock(), _createBlock(_component_category_item, {
      key: element.category,
      category: element.category,
      categoryId: element.score,
      active: element.active,
      dragging: $data.dragging,
      onSelectCategory: $options.moveToInactiveCategories
    }, null, 8 /* PROPS */, ["category", "categoryId", "active", "dragging", "onSelectCategory"]))])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onMove"])], 64 /* STABLE_FRAGMENT */))], 2 /* CLASS */)) : _createCommentVNode("v-if", true), !$options.noInactive ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(['category-wrapper', {
      'no-active': $options.noActive
    }])
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.inactiveCategories, ({
    category,
    score
  }, index) => {
    return _openBlock(), _createBlock(_component_category_item, {
      key: index,
      category: category,
      categoryId: score,
      onSelectCategory: $options.moveToActiveCategories
    }, null, 8 /* PROPS */, ["category", "categoryId", "onSelectCategory"]);
  }), 128 /* KEYED_FRAGMENT */))], 2 /* CLASS */)) : _createCommentVNode("v-if", true)])]);
}