import { mapState, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import CustomSideBarAppModal from "@/components/CustomSideBarAppModal";
import AppForm from "@/components/AppForm";
import AppSelect from "@/components/AppSelect";
export default {
  name: "CreateAccountSideBar",
  emits: ["update:modelValue"],
  mixins: [ValidateMixin],
  components: {
    CustomSideBarAppModal,
    AppForm,
    AppSelect
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    provider: {
      type: String,
      default: ""
    },
    regions: {
      type: Object,
      default: () => ({})
    },
    countries: {
      type: Object,
      default: () => ({})
    },
    accounts: {
      type: Object,
      default: () => ({})
    },
    hasCountries: {
      type: Boolean,
      default: false
    },
    hasAccounts: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        region: "",
        country: "",
        account_provider: this.provider
      },
      loading: false
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      current_workspace: state => state.workspace.current_workspace
    }),
    modal: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      }
    },
    getCountries: function () {
      return this.countries[this.form.region];
    }
  },
  methods: {
    ...mapActions(["accountCreate", "accountAuthorize"]),
    async onSubmit() {
      const valid = await this.mValidate();
      if (valid) {
        this.form.workspace_id = this.current_workspace.workspace_id;
        this.loading = true;
        //await this.accountCreate(this.form)
        await this.accountAuthorize(this.form);
        this.loading = false;
        this.modal = false;
        // Redirect To Amazon
      }
    }
  }
};