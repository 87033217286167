import AppIcon from '@/components/AppIcon';
import AppButton from '@/components/AppButton';
export default {
  name: 'AppPageTitle',
  emits: ['action'],
  components: {
    AppIcon,
    AppButton
  },
  props: {
    title: {
      type: [String, Array],
      default: null
    },
    visibleButton: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: null
    },
    spinButton: {
      type: Boolean,
      default: false
    },
    activeButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClick() {
      this.$emit('action');
    }
  }
};