import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-72eea372"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "option-selector"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_flex_column = _resolveComponent("flex-column");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: 8,
    classes: "app-choice-maker",
    styles: {
      height: 'auto',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "selector", {}, undefined, true)]), _createVNode(_component_flex_column, {
      gap: 4,
      styles: {
        justifyContent: 'flex-start'
      }
    }, {
      default: _withCtx(() => [_createElementVNode("div", {
        class: _normalizeClass(['d-flex align-items-center text', {
          disabled: $props.disabled
        }])
      }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 2 /* CLASS */), _ctx.$slots['description'] ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['description', {
          disabled: $props.disabled
        }])
      }, [_renderSlot(_ctx.$slots, "description", {}, undefined, true)], 2 /* CLASS */)) : _createCommentVNode("v-if", true)]),
      _: 3 /* FORWARDED */
    })]),
    _: 3 /* FORWARDED */
  });
}