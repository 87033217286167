import { request } from '@/store';
export const sanitize = str => {
  // Remove only [`.`] from the string
  return str.replace(/[."]/ig, '').trim();
};
export const cleanUp = key => item => {
  return [null, undefined, '', 'null', 'none'].indexOf(item[key]?.toLowerCase()) === -1;
};
export const getAiCategoryBenefits = async ({
  params
} = {}) => {
  let res = [];
  //console.log('[getAiCategoryBenefits] services params');
  //console.log(params);
  try {
    res = await request('/content/getcontentbenefits', 'POST', params, false, false);
    res = res?.data.filter(cleanUp('benefit')).map(({
      benefit,
      count
    }) => ({
      category: sanitize(benefit) ?? 'No category',
      score: Math.round(count) ?? 1
    }));
  } catch (e) {
    //console.log(e)
  }
  return res;
};
export const getAiCategoryUseCases = async ({
  params
} = {}) => {
  let res = [];
  try {
    res = await request('/content/getcontentusecases', 'POST', params, false, false);
    if (typeof res?.data === 'string') res.data = JSON.parse(res?.data);
    res = res?.data.filter(cleanUp('use_cases')).map(({
      use_cases,
      use_cases_count
    }) => ({
      category: sanitize(use_cases) ?? 'No category',
      score: Math.round(use_cases_count) ?? 1
    })).slice(0, 10);
  } catch (e) {
    //console.log(e)
  }
  return res;
};
export const generateAiProposition = async ({
  params
} = {}) => {
  /*
   Here, we will be using these params:
      - asin
      - marketplace_id
      - seller_id
      - category_id
      - product_name
      - product_description
      - bullet_points
      - benefits_list
      - use_cases_list
  */
  let res = [];
  //console.log("[params to generate suggestions]");
  //console.log(params);

  res = await request('/content/generatecontentlisting', 'POST', params, false, false);
  //console.log("generateAiProposition", res)
  if (!res) throw new Error('No response from server');
  if (!res?.data) throw new Error('No data in response from server');
  res = res?.data;
  return res;
};
export const translateAiProposition = async ({
  params
} = {}) => {
  let res = {};
  //console.log("[params to generate translation]");
  //console.log(params);

  res = await request('/content/generatecontentlistingtranslation', 'POST', params, false, false);
  //console.log("generatedAiPropositionTranslation", res)
  if (!res) throw new Error('No response from server');
  // if (!res?.data) throw new Error('No data in response from server')
  // res = res?.data;

  return res;
};