import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_value_element = _resolveComponent("value-element");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    class: "date-value",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('click', $event)),
    id: $props.id,
    align: $props.align,
    styles: $props.styles,
    loading: $props.loading
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "prepend", {}, undefined, true), _createVNode(_component_value_element, {
      value: $options.formattedText
    }, null, 8 /* PROPS */, ["value"]), _renderSlot(_ctx.$slots, "append", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["id", "align", "styles", "loading"]);
}