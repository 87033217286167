export default {
  name: "FilterSectionTitle",
  props: {
    title: {
      type: String,
      default: ""
    },
    count: {
      type: Number,
      default: 0
    },
    searching: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedTitle() {
      if (this.searching) {
        return `${this.title} found`.toUpperCase();
      }
      if (!this.selected && !this.searching) {
        return `${this.title}`.toUpperCase();
      }
      return `${this.title} selected`.toUpperCase();
    }
  }
};