import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-afb7f0e6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "error-occurred"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_spinner = _resolveComponent("b-spinner");
  const _component_b_button = _resolveComponent("b-button");
  return _openBlock(), _createElementBlock("div", {
    class: "error-wrapper",
    style: _normalizeStyle({
      height: $props.wrapperHeight + 'px'
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
    style: _normalizeStyle({
      width: $props.size + 'px',
      height: $props.size + 'px'
    }),
    src: "/assets/images/error.svg",
    alt: "error-occurred"
  }, null, 4 /* STYLE */), _createElementVNode("div", null, _toDisplayString($props.text), 1 /* TEXT */), _createVNode(_component_b_button, {
    onClick: $options.handleRetry,
    class: "spinner-btn retry"
  }, {
    default: _withCtx(() => [this.loading ? (_openBlock(), _createBlock(_component_b_spinner, {
      key: 0,
      style: {
        width: '1rem',
        height: '1rem'
      },
      small: ""
    })) : (_openBlock(), _createElementBlock(_Fragment, {
      key: 1
    }, [_createTextVNode(" Retry ")], 64 /* STABLE_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"])])], 4 /* STYLE */);
}