import { CampaignFilter, MatchTypeFilter, TargetingFilter } from '@/components/new-filters';
import FlexRow from '../FlexRow.vue';
import { EVENTS } from '@/utils';
export default {
  name: 'PPCMoreFilters',
  components: {
    CampaignFilter,
    MatchTypeFilter,
    TargetingFilter,
    FlexRow
  },
  expose: ['hasAnySelection'],
  computed: {
    campaigns() {
      return this.$store.state.ppc.campaigns;
    },
    match_types() {
      return this.$store.state.ppc.match_types;
    },
    targetings() {
      return this.$store.state.ppc.targetings;
    },
    hasAnySelection() {
      return [this.campaigns, this.match_types, this.targetings].reduce((acc, curr) => acc || curr.selected.length > 0, false);
    },
    groupBy() {
      return this.$store.getters.ppcGetProductTableGroupBy()?.key;
    },
    sellers() {
      return this.$store.state.ppc.sellers;
    },
    marketplaces() {
      return this.$store.state.ppc.marketplaces;
    }
  },
  methods: {
    applyMatchTypes() {
      if (this.match_types.isModified) {
        this.match_types.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyCampaigns() {
      if (this.campaigns.isModified) {
        this.campaigns.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    },
    applyTargetings() {
      if (this.targetings.isModified) {
        this.targetings.apply();
        window.$bus.trigger(EVENTS.REFRESH_PPC_MANAGEMENT);
      }
    }
  }
};