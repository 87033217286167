import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createBlock(_component_FlexRow, _mergeProps(_ctx.$attrs, {
    styles: {
      width: `${$props.width} !important`,
      minWidth: '16px',
      height: '16px !important',
      padding: '4px 3.5px',
      borderRadius: '24px',
      background: $options.getBackground,
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '15.4px',
      textEdge: 'cap',
      leadingTrim: 'both',
      textAlign: 'center',
      color: $props.color
    },
    onMouseover: _cache[0] || (_cache[0] = $event => $data.hovered = true),
    onMouseleave: _cache[1] || (_cache[1] = $event => $data.hovered = false)
  }), {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["styles"]);
}