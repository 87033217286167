import CryptoJS from "crypto-js";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
export default {
  name: "AppInput",
  emits: ["update:modelValue", "submit", "change"],
  mixins: [ValidateMixin],
  components: {
    AppButton,
    AppIcon
  },
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    prepend: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    prependIcon: {
      type: String,
      default: null
    },
    prependSimple: {
      type: Boolean,
      default: false
    },
    append: {
      type: String,
      default: null
    },
    appendSimple: {
      type: Boolean,
      default: false
    },
    validateRule: {
      type: String,
      default: "none"
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    v2: {
      type: Boolean,
      default: false
    },
    version: {
      type: String,
      default: null
    },
    debounce: {
      type: String,
      default: null
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      id: "input-" + Math.floor(Math.random() * 900000) + 100000,
      isPasswordShown: false,
      secret: "some-secret-string",
      validate: [{
        variable: "value",
        rule: this.validateRule
      }]
    };
  },
  computed: {
    value: {
      get() {
        return this.decrypt(this.modelValue.toString());
      },
      set(val) {
        this.$emit("update:modelValue", this.encrypt(val));
        this.$emit("change", val);
      }
    },
    inputState() {
      return !this.mValidateMessage("value") ? null : false;
    },
    showErrorBorder() {
      return this.hasError || !!this.mValidateMessage("value");
    },
    computedType() {
      if (this.type == "password") {
        return this.isPasswordShown ? "text" : this.type;
      } else {
        return this.type;
      }
    }
  },
  methods: {
    encrypt(value) {
      if (value.length) {
        return this.type == "password" ? CryptoJS.AES.encrypt(value, CryptoJS.SHA256(this.secret), {
          mode: CryptoJS.mode.ECB
        }).toString() : value;
      }
      return "";
    },
    decrypt(value) {
      if (value.length) {
        return this.type == "password" ? CryptoJS.AES.decrypt(value, CryptoJS.SHA256(this.secret), {
          mode: CryptoJS.mode.ECB
        }).toString(CryptoJS.enc.Utf8) : value;
      }
      return "";
    },
    onPasswordShowToggle() {
      this.isPasswordShown = !this.isPasswordShown;
    },
    onSubmit() {
      this.$emit("submit");
    }
  }
};