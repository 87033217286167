import LoadingElement from "@/components/shared/LoadingElement.vue";
import BoxedValue from "@/components/tableElements/BoxedValue.vue";
import BarWithValue from "@/components/tableElements/BarWithValue.vue";
import FlexRow from "@/components/FlexRow.vue";
export default {
  name: "BoxAndBarWithValue",
  components: {
    LoadingElement,
    BoxedValue,
    BarWithValue,
    FlexRow
  },
  props: {
    value: {
      type: [Number, null]
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    loading: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String
    },
    barColor: {
      type: String
    }
  }
};