import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_rating = _resolveComponent("app-rating");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    align: "right",
    class: "rating-value",
    loading: $props.loading
  }, {
    default: _withCtx(() => [$props.value ? (_openBlock(), _createBlock(_component_app_rating, {
      key: 0,
      rating: $props.value,
      size: $props.size,
      color: $props.color
    }, null, 8 /* PROPS */, ["rating", "size", "color"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading"]);
}