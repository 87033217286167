import { truncateString } from '@/utils';
import { copyToClipboard } from '../shared/helpers';
import ProductSkuAsinItem from '../shared/ProductSkuAsinItem.vue';
import FilterSectionHeader from './FilterSectionHeader.vue';
import SellerFilterItem from './SellerFilterItem.vue';
import CoutryFilterItem from './CountryFilterItem.vue';
export default {
  name: 'SelectedItems',
  emits: ['deselect', 'clear', 'refresh'],
  props: {
    selected: {
      type: Array,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    filterTitle: {
      type: String,
      default: "",
      required: false
    },
    activateRefresh: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    truncateString,
    handleRefresh(e) {
      e.stopPropagation();
      this.$emit('refresh');
    },
    copyToClipboard(event, item) {
      event.stopPropagation();
      copyToClipboard(`${item.product_name} - ${item.sku} - ${item.asin}`);
    },
    handleDeselect(event, item) {
      event.stopPropagation();
      this.$emit('deselect', item);
      this.$emit('refresh');
    },
    handleClearFilter(event) {
      event.stopPropagation();
      this.$emit('clear');
      this.$emit('refresh');
    }
  },
  components: {
    ProductSkuAsinItem,
    FilterSectionHeader,
    SellerFilterItem,
    CoutryFilterItem
  }
};