import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cc411154"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "icon"
};
const _hoisted_2 = {
  class: "text"
};
const _hoisted_3 = {
  class: "icon"
};
const _hoisted_4 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_data_and_evolution_row_value = _resolveComponent("data-and-evolution-row-value");
  const _component_b_row = _resolveComponent("b-row");
  const _component_event_type_value = _resolveComponent("event-type-value");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  return _openBlock(), _createBlock(_component_b_row, {
    class: _normalizeClass(['aggregate', $props.type, {
      active: !$options.hideDetailsExpr
    }, {
      ['net-revenue']: $props.value.pl_aggregate === 'Net Revenue'
    }])
  }, {
    default: _withCtx(() => [_createVNode(_component_b_row, {
      class: "aggregate-row"
    }, {
      default: _withCtx(() => [_createVNode(_component_data_and_evolution_row_value, {
        onToggle: $options.toggleAggregate,
        value: $options.dataTransform($props.value),
        align: "left",
        fields: $props.fields,
        thStyles: $data.thStyles,
        loading: $props.loading,
        open: !$options.hideDetailsExpr,
        type: $props.type
      }, {
        default: _withCtx(() => [_createVNode(_component_flex_row, {
          gap: 4
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_app_icon, {
            icon: $options.getAggregateIcon,
            color: $options.getAggregateIconColor,
            size: 16
          }, null, 8 /* PROPS */, ["icon", "color"])]), _createElementVNode("div", _hoisted_2, _toDisplayString($options.renameValue($props.value.pl_aggregate)), 1 /* TEXT */)]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["onToggle", "value", "fields", "thStyles", "loading", "open", "type"])]),
      _: 1 /* STABLE */
    }), $props.value.details?.length > 0 && !$options.hideDetailsExpr ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList($props.value.details, (detail, index) => {
      return _openBlock(), _createBlock(_component_b_row, {
        class: _normalizeClass(['details', $props.type, {
          quantity: detail.pl_detail === 'Quantity'
        }, {
          active: !$options.hideEvents(index)
        }]),
        key: index
      }, {
        default: _withCtx(() => [_createVNode(_component_b_row, {
          class: "detail-row"
        }, {
          default: _withCtx(() => [_createVNode(_component_data_and_evolution_row_value, {
            onToggle: $event => $options.toggleDetails(index),
            value: $options.dataTransform(detail),
            name: detail.pl_detail,
            fields: $props.fields,
            loading: $props.loading,
            level: 2,
            thStyles: $data.thStyles,
            open: !$options.hideEvents(index)
          }, {
            default: _withCtx(() => [_createVNode(_component_flex_row, {
              gap: 4
            }, {
              default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [detail.pl_detail === 'Quantity' ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createTextVNode(_toDisplayString(null))], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createBlock(_component_app_icon, {
                key: 1,
                icon: $options.getDetailIcon(index, detail.pl_detail),
                color: "#757575",
                size: 16
              }, null, 8 /* PROPS */, ["icon"]))]), _createElementVNode("div", _hoisted_4, _toDisplayString(detail.pl_detail), 1 /* TEXT */)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onToggle", "value", "name", "fields", "loading", "thStyles", "open"])]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */), detail.events?.length > 0 && !$options.hideEvents(index) ? (_openBlock(true), _createElementBlock(_Fragment, {
          key: 0
        }, _renderList(detail.events, (event, e_index) => {
          return _openBlock(), _createBlock(_component_b_row, {
            key: e_index,
            class: "events"
          }, {
            default: _withCtx(() => [_createVNode(_component_data_and_evolution_row_value, {
              value: $options.dataTransform(event),
              fields: $props.fields,
              loading: $props.loading,
              level: 3,
              thStyles: $data.thStyles
            }, {
              default: _withCtx(() => [_createVNode(_component_flex_row, {
                gap: 4,
                styles: {
                  justifyContent: 'unset'
                }
              }, {
                default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
                  placement: "right"
                }, {
                  title: _withCtx(() => [_createTextVNode(_toDisplayString(event.event_type), 1 /* TEXT */)]),
                  content: _withCtx(() => [_createTextVNode(_toDisplayString(event.definition), 1 /* TEXT */)]),
                  default: _withCtx(() => [_createVNode(_component_event_type_value, {
                    value: event.event_type
                  }, null, 8 /* PROPS */, ["value"])]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "fields", "loading", "thStyles"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */);
        }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]);
    }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}