import FlexColumn from "../../FlexColumn.vue";
import FlexRow from "../../FlexRow.vue";
export default {
  name: "WrapWithSection",
  components: {
    FlexColumn,
    FlexRow
  },
  emits: ["clear", "apply"],
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    heightFromTop: {
      type: String,
      default: "0px"
    }
  }
};