import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PortfolioFilterBody = _resolveComponent("PortfolioFilterBody");
  const _component_FilterItem = _resolveComponent("FilterItem");
  return _openBlock(), _createBlock(_component_FilterItem, {
    id: "portfolios",
    ref: "portfolioFilterItem",
    icon: "CertificateIcon",
    title: $options.getPortfolioTitle,
    selectedCount: $props.portfolios.filterState.selectedCount,
    onClearAll: $options.handleClearAll
  }, {
    body: _withCtx(() => [_createVNode(_component_PortfolioFilterBody, {
      title: "Portfolio",
      selected: $props.portfolios.filterState.selected,
      isModified: $props.portfolios.isModified,
      searching: $props.portfolios.searching,
      list: $props.portfolios.filterState.list,
      onClear: $options.handleClear,
      onSelect: $options.handleSelect,
      onSelectOnly: $options.handleSelectOnly,
      onSelectAll: _cache[0] || (_cache[0] = $event => $props.portfolios.selectAll()),
      onDeselect: $options.handleDeselect,
      onApply: _cache[1] || (_cache[1] = $event => _ctx.$emit('apply')),
      onSearch: $options.handlePortfolioSearch,
      onHidden: _cache[2] || (_cache[2] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _ctx.$refs?.portfolioFilterItem?.closeDropdown
    }, null, 8 /* PROPS */, ["selected", "isModified", "searching", "list", "onClear", "onSelect", "onSelectOnly", "onDeselect", "onSearch", "onCloseDropdown"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "selectedCount", "onClearAll"]);
}