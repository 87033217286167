import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3feb8694"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pages"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.pages, (page, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "page",
      key: index,
      onClick: $event => _ctx.$emit('changeTab', page)
    }, [_createElementVNode("div", _hoisted_3, _toDisplayString(page.name), 1 /* TEXT */), _createElementVNode("div", {
      class: _normalizeClass([{
        active: page.name === $props.selectedTab
      }, "bottom"])
    }, null, 2 /* CLASS */)], 8 /* PROPS */, _hoisted_2);
  }), 128 /* KEYED_FRAGMENT */))]);
}