import FlexColumn from "@/components/FlexColumn.vue";
export default {
  name: "WrapWithDropdownBody",
  components: {
    FlexColumn
  },
  props: {
    showSearcher: {
      type: Boolean,
      default: true
    }
  },
  emits: ["hidden"],
  mounted() {
    window.$bus.on("DROPDOWN_HIDDEN", () => this.$emit("hidden"));
  },
  beforeUnmount() {
    window.$bus.off("DROPDOWN_HIDDEN", () => this.$emit("hidden"));
  }
};