import AnnotationAlertIcon from '@/components/icons/AnnotationAlertIcon.vue';
import FlexRow from "@/components/FlexRow.vue";
export default {
  name: 'WrapWithAlertIcon',
  components: {
    AnnotationAlertIcon,
    FlexRow
  },
  props: {
    color: String
  }
};