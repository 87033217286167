import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1a419904"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick", "onMouseover"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_date_value = _resolveComponent("date-value");
  const _component_date_picker_calendar_month_layout = _resolveComponent("date-picker-calendar-month-layout");
  return _openBlock(), _createBlock(_component_date_picker_calendar_month_layout, {
    monthList: $props.monthList
  }, {
    default: _withCtx(({
      year,
      month
    }) => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getDates(month, year), ({
      week,
      disabled
    }, index) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["week", {
          'disabled': $options.checkIfWeekIsBehindStack(disabled, week),
          'in-range': $options.checkIfWeekIsAheadStack(disabled, week),
          'selected': $options.checkIfWeekIsBetweenStartAndEnd(disabled, week)
        }]),
        onClick: $event => $options.handleWeekClick(week),
        key: `week-${year}-${month}-${index}`,
        ref_for: true,
        ref: week[0],
        onMouseover: $event => $options.handleCurrentlyHovered(week)
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(week, (date, index) => {
        return _openBlock(), _createBlock(_component_date_value, {
          key: date + month + year + index,
          value: date,
          format: "D",
          id: _ctx.getDatePickerDayId(date, 'week-date'),
          "data-date": date,
          class: _normalizeClass({
            'today': $options.dateIsToday(date)
          })
        }, null, 8 /* PROPS */, ["value", "id", "data-date", "class"]);
      }), 128 /* KEYED_FRAGMENT */))], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["monthList"]);
}