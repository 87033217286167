import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7223cfa0"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id", "data-month", "data-year"];
const _hoisted_2 = {
  class: "month"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.monthList, (current, index) => {
    return _openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, {
      key: `${current.month}y${current.year}-${index}`,
      ref_for: true,
      ref: `${current.month}y${current.year}`,
      id: `${current.month}y${current.year}`,
      class: "calendar-grid-month",
      "data-month": current.month,
      "data-year": current.year
    }), [_createElementVNode("p", _hoisted_2, _toDisplayString(current.monthName), 1 /* TEXT */), _renderSlot(_ctx.$slots, "default", {
      year: current.year,
      month: current.month
    }, undefined, true)], 16 /* FULL_PROPS */, _hoisted_1);
  }), 128 /* KEYED_FRAGMENT */);
}