import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-72067f54"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "custom-subtitle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_PercentageValue = _resolveComponent("PercentageValue");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_revenue_excl_v_a_t_subtitle_card = _resolveComponent("revenue-excl-v-a-t-subtitle-card");
  const _component_kpi_card = _resolveComponent("kpi-card");
  return _openBlock(), _createBlock(_component_kpi_card, {
    name: $props.name,
    subtitle: "units sold",
    loading: $props.loading
  }, {
    amount: _withCtx(() => [_createVNode(_component_currency_value, {
      value: $props.amount
    }, null, 8 /* PROPS */, ["value"])]),
    "change-rate": _withCtx(() => [_createVNode(_component_b_badge, {
      style: _normalizeStyle({
        background: `${$props.percentageBgColor} !important`
      })
    }, {
      default: _withCtx(() => [_createVNode(_component_PercentageValue, {
        value: $props.percentage,
        color: $props.percentageColor,
        styles: {
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: '16px'
        },
        showSign: ""
      }, null, 8 /* PROPS */, ["value", "color"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["style"])]),
    "info-popover-title": _withCtx(() => [_createTextVNode(_toDisplayString($data.title), 1 /* TEXT */)]),
    "info-popover-content": _withCtx(() => [_createTextVNode(_toDisplayString($data.detail), 1 /* TEXT */)]),
    "custom-subtitle": _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_revenue_excl_v_a_t_subtitle_card, {
      title: $props.splitSubtitle?.[0]?.name,
      loading: $props.loading
    }, {
      evolution: _withCtx(() => [_createVNode(_component_percentage_value, {
        style: {
          width: 'fit-content',
          height: 'fit-content'
        },
        value: $props.splitSubtitle?.[0]?.percentage,
        color: $props.splitSubtitle?.[0]?.percentageColor,
        "show-sign": ""
      }, null, 8 /* PROPS */, ["value", "color"])]),
      default: _withCtx(() => [_createVNode(_component_currency_value, {
        value: $props.splitSubtitle?.[0]?.amount,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "loading"]), _createVNode(_component_revenue_excl_v_a_t_subtitle_card, {
      title: $props.splitSubtitle?.[1]?.name,
      loading: $props.loading
    }, {
      evolution: _withCtx(() => [_createVNode(_component_percentage_value, {
        style: {
          width: 'fit-content',
          height: 'fit-content'
        },
        value: $props.splitSubtitle?.[1]?.percentage,
        color: $props.splitSubtitle?.[1]?.percentageColor,
        "show-sign": ""
      }, null, 8 /* PROPS */, ["value", "color"])]),
      default: _withCtx(() => [_createVNode(_component_currency_value, {
        value: $props.splitSubtitle?.[1]?.amount,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "loading"]), _createVNode(_component_revenue_excl_v_a_t_subtitle_card, {
      title: $props.splitSubtitle?.[2]?.name,
      loading: $props.loading
    }, {
      evolution: _withCtx(() => [_createVNode(_component_percentage_value, {
        style: {
          width: 'fit-content',
          height: 'fit-content'
        },
        value: $props.splitSubtitle?.[2]?.percentage,
        color: $props.splitSubtitle?.[2]?.percentageColor,
        "show-sign": "",
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "color", "loading"])]),
      default: _withCtx(() => [_createVNode(_component_currency_value, {
        value: $props.splitSubtitle?.[2]?.amount,
        loading: $props.loading
      }, null, 8 /* PROPS */, ["value", "loading"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "loading"])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["name", "loading"]);
}