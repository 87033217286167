import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6fde8138"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "d-flex flex-column gap-1"
};
const _hoisted_2 = {
  class: "d-flex flex-row justify-content-between bold-text"
};
const _hoisted_3 = {
  key: 0,
  class: "d-flex flex-row align-items-center gap-1"
};
const _hoisted_4 = {
  class: "hidden-text"
};
const _hoisted_5 = {
  key: 1,
  class: "d-flex flex-row align-items-center gap-1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, _toDisplayString($props.title), 1 /* TEXT */), _createElementVNode("div", {
    style: {
      "cursor": "pointer"
    },
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onToggle && $options.onToggle(...args))
  }, [$data.expand ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, [_createTextVNode("Less "), _createVNode(_component_app_icon, {
    icon: "arrow-up"
  })])])) : (_openBlock(), _createElementBlock("div", _hoisted_5, [_createTextVNode(" More "), _createVNode(_component_app_icon, {
    icon: "arrow-down"
  })]))])]), _createElementVNode("div", {
    style: _normalizeStyle(`max-height: ${$props.maxHeight}${$options.units}; overflow: ${$props.maxHeight == 'none' ? 'visible' : 'auto'};`)
  }, [_createElementVNode("div", {
    class: _normalizeClass(['justify', {
      overflow: !$data.expand
    }]),
    style: _normalizeStyle(`-webkit-line-clamp: ${$props.lines};`)
  }, _toDisplayString($props.value), 7 /* TEXT, CLASS, STYLE */)], 4 /* STYLE */)]);
}