import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "current-week"
};
const _hoisted_2 = {
  class: "kpi-card-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PPCTopSection = _resolveComponent("PPCTopSection");
  const _component_PPCMoreFilters = _resolveComponent("PPCMoreFilters");
  const _component_CurrentPeriod = _resolveComponent("CurrentPeriod");
  const _component_KpiCard = _resolveComponent("KpiCard");
  const _component_PercentageValue = _resolveComponent("PercentageValue");
  const _component_CurrencyValue = _resolveComponent("CurrencyValue");
  const _component_b_badge = _resolveComponent("b-badge");
  const _component_NumberValue = _resolveComponent("NumberValue");
  const _component_PeriodTable = _resolveComponent("PeriodTable");
  const _component_FlexColumn = _resolveComponent("FlexColumn");
  const _component_ProductTable = _resolveComponent("ProductTable");
  const _component_ppc_settings_filters = _resolveComponent("ppc-settings-filters");
  const _component_ppc_settings = _resolveComponent("ppc-settings");
  const _component_omny_ui_layout = _resolveComponent("omny-ui-layout");
  return _openBlock(), _createBlock(_component_omny_ui_layout, {
    contentPadding: "0",
    modelValue: $data.openModal,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.openModal = $event),
    activateSettings: $data.activateSettings,
    rightStripFeatureName: $data.rightStripFeatureName,
    hideSettingsFooterButtons: $data.hideSettingsFooterButtons,
    settingsInfo: "Advertising Settings can only be opened when you have a single seller and marketplace.",
    onModalShown: $options.handleSideOpened
  }, {
    "topbar-contents": _withCtx(() => [_createVNode(_component_PPCTopSection, {
      onMoreFilters: $options.toggleMoreFilters,
      "more-filters": $data.moreFilters,
      hasAnySelection: _ctx.$refs['moreFiltersRef']?.hasAnySelection
    }, null, 8 /* PROPS */, ["onMoreFilters", "more-filters", "hasAnySelection"])]),
    "topbar-sub": _withCtx(() => [_withDirectives(_createVNode(_component_PPCMoreFilters, {
      ref: "moreFiltersRef"
    }, null, 512 /* NEED_PATCH */), [[_vShow, $data.moreFilters]])]),
    "settings-header-right": _withCtx(() => [$data.openModal ? (_openBlock(), _createBlock(_component_ppc_settings_filters, {
      key: 0
    })) : _createCommentVNode("v-if", true)]),
    "right-strip-modal": _withCtx(() => [$data.openModal ? (_openBlock(), _createBlock(_component_ppc_settings, {
      key: 0,
      ref: "ppcSettingsPage",
      activateSettings: $data.activateSettings
    }, null, 8 /* PROPS */, ["activateSettings"])) : _createCommentVNode("v-if", true)]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["ppc-management", [{
        ['more-filters']: $data.moreFilters
      }, $options.productTableState]])
    }, [_createVNode(_component_FlexColumn, {
      gap: 24,
      classes: "all-wrapper"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_CurrentPeriod, {
        currentPeriod: $options.currentPeriod,
        loading: $data.kpisLoading
      }, null, 8 /* PROPS */, ["currentPeriod", "loading"]), _createElementVNode("div", _hoisted_2, [$data.kpisLoading ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList([0, 0, 0, 0], (_, index) => {
        return _createVNode(_component_KpiCard, {
          key: index,
          loading: ""
        }, {
          "kpi-subtitle-amount": _withCtx(() => [_createTextVNode("0")]),
          "subtitle-percentage": _withCtx(() => [_createTextVNode("0")]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 64 /* STABLE_FRAGMENT */)) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(_ctx.kpis.data, (kpi, index) => {
        return _openBlock(), _createBlock(_component_KpiCard, {
          key: index,
          name: kpi.name,
          amount: kpi.amount ?? 0,
          percentage: kpi.percentage,
          subtitleAmount: kpi.subtitleAmount,
          subtitle: kpi.subtitle,
          kpiId: `${kpi.name}-${index}`,
          trendDirection: kpi.trendDirection,
          percentageColor: kpi.percentageColor
        }, {
          "info-popover-title": _withCtx(() => [_createTextVNode(_toDisplayString(kpi.title), 1 /* TEXT */)]),
          "info-popover-content": _withCtx(() => [_createTextVNode(_toDisplayString(kpi.detail), 1 /* TEXT */)]),
          amount: _withCtx(() => [['TACOS', 'CVR'].includes(kpi.key) ? (_openBlock(), _createBlock(_component_PercentageValue, {
            key: 0,
            styles: {
              padding: '0'
            },
            value: kpi.amount ?? 0
          }, null, 8 /* PROPS */, ["value"])) : (_openBlock(), _createBlock(_component_CurrencyValue, {
            key: 1,
            value: kpi.amount ?? 0,
            styles: {
              padding: 0
            },
            limit: 10_000
          }, null, 8 /* PROPS */, ["value"]))]),
          "change-rate": _withCtx(() => [_createVNode(_component_b_badge, {
            style: _normalizeStyle({
              background: `${kpi.percentageBgColor} !important`
            })
          }, {
            default: _withCtx(() => [_createVNode(_component_PercentageValue, {
              value: kpi.percentage,
              pp: ['CVR', 'TACOS'].includes(kpi.key),
              color: kpi.percentageColor,
              styles: {
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '16px'
              },
              showSign: ""
            }, null, 8 /* PROPS */, ["value", "pp", "color"])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["style"])]),
          "kpi-subtitle-amount": _withCtx(() => [kpi.subtitle == 'CPC' ? (_openBlock(), _createBlock(_component_CurrencyValue, {
            key: 0,
            value: kpi.subtitleAmount,
            limit: 10_000,
            precision: 2
          }, null, 8 /* PROPS */, ["value"])) : (_openBlock(), _createBlock(_component_NumberValue, {
            key: 1,
            styles: {
              padding: 0
            },
            value: kpi.subtitleAmount,
            precision: kpi.subtitle === 'ROAS' ? 1 : 0
          }, null, 8 /* PROPS */, ["value", "precision"]))]),
          "subtitle-percentage": _withCtx(() => [_createVNode(_component_PercentageValue, {
            value: kpi.subtitlePercentage,
            showSign: "",
            color: kpi.subtitlePercentageColor
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name", "amount", "percentage", "subtitleAmount", "subtitle", "kpiId", "trendDirection", "percentageColor"]);
      }), 128 /* KEYED_FRAGMENT */))])]), _createVNode(_component_PeriodTable, {
        ref: "periodTable",
        periodTitle: $options.currentPeriod?.title,
        fields: $options.getPeriodTableFields,
        fields_heatmap: $options.getPeriodTableHeatmapThresholds,
        items: $options.getPeriodTableItems,
        minMax: $options.getPeriodTableMinMax,
        maxCharCount: _ctx.periodTable.maxCharCount,
        thresholds: $options.getPeriodTableThresholds,
        loading: $data.periodTableLoading,
        onRefresh: _cache[0] || (_cache[0] = async () => await $options.refreshMediator(['KPIS', 'PERIOD_TABLE', 'PRODUCT_TABLE'])),
        onSetDateRange: $options.handleSetDateRange,
        currencyConfig: _ctx.currencyConfig,
        "onRefresh:datePicker": $options.handleRefreshDatePicker
      }, null, 8 /* PROPS */, ["periodTitle", "fields", "fields_heatmap", "items", "minMax", "maxCharCount", "thresholds", "loading", "onSetDateRange", "currencyConfig", "onRefresh:datePicker"])]),
      _: 1 /* STABLE */
    }), _createVNode(_component_ProductTable, {
      ref: "productTable",
      thresholds: $options.getProductTableThresholds,
      fields_heatmap: $options.getProductTableHeatMapThresholds,
      datePickerDate: $options.getDatePickerDate,
      loading: $data.productTableLoading,
      onRefresh: _cache[1] || (_cache[1] = async () => await $options.refreshMediator(['KPIS', 'PERIOD_TABLE', 'PRODUCT_TABLE'])),
      onHandleSort: $options.handleProductTableSort,
      onChangeDate: $options.handleDateChange,
      currencyConfig: _ctx.currencyConfig
    }, null, 8 /* PROPS */, ["thresholds", "fields_heatmap", "datePickerDate", "loading", "onHandleSort", "onChangeDate", "currencyConfig"])], 2 /* CLASS */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "activateSettings", "rightStripFeatureName", "hideSettingsFooterButtons", "onModalShown"]);
}