import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-240be1e3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sidebar-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ai_generation = _resolveComponent("ai-generation");
  const _component_ai_assistance = _resolveComponent("ai-assistance");
  const _component_edit_page_translate = _resolveComponent("edit-page-translate");
  const _component_edit_page_preview = _resolveComponent("edit-page-preview");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createBlock(_component_b_modal, {
    scrollable: "",
    "no-fade": "",
    "no-close-on-backdrop": "",
    "no-close-on-esc": "",
    "hide-footer": "",
    "hide-header": "",
    class: _normalizeClass(['edit-page-sidebar', $options.editPageSideBarClass]),
    id: "seo-sidebar-modal"
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(['edit-page-sidebar-inner', $data.type])
    }, [_createElementVNode("div", _hoisted_1, [_withDirectives(_createVNode(_component_ai_generation, {
      ref: "aiGeneration",
      all_versions_list: $props.all_versions_list,
      product: $props.product,
      onClose: $options.onClose,
      onTypeChange: $options.onTypeChange,
      onAigenerate: $options.onAigenerate
    }, null, 8 /* PROPS */, ["all_versions_list", "product", "onClose", "onTypeChange", "onAigenerate"]), [[_vShow, $data.menu == 'ai_generation']]), _withDirectives(_createVNode(_component_ai_assistance, {
      onClose: $options.onClose,
      onOpenNext: $options.onAiAssistanceOpenNext
    }, null, 8 /* PROPS */, ["onClose", "onOpenNext"]), [[_vShow, $data.menu == 'ai_assistance']]), _withDirectives(_createVNode(_component_edit_page_translate, {
      product: $props.product,
      loading_translation_spinner: $props.loading_translation_spinner,
      is_translation_button_active: $props.is_translation_button_active,
      onClose: $options.onClose,
      onTranslate: $options.onTranslate,
      onAsyncUpdate: $options.getAsyncUpdate,
      "onUpdate:is_translation_button_active": $options.updateTranslationButtonActive
    }, null, 8 /* PROPS */, ["product", "loading_translation_spinner", "is_translation_button_active", "onClose", "onTranslate", "onAsyncUpdate", "onUpdate:is_translation_button_active"]), [[_vShow, $data.menu == 'translate']]), _createCommentVNode("edit-page-keywords\n          v-show=\"menu == 'keywords'\"\n          :product=\"product\"\n          @close=\"onClose\"\n          @no-keywords=\"onNoKeywords\"\n        /"), _withDirectives(_createVNode(_component_edit_page_preview, {
      ref: "preview",
      product: $props.product,
      onClose: $options.onClose,
      onTypeChange: $options.onTypeChange
    }, null, 8 /* PROPS */, ["product", "onClose", "onTypeChange"]), [[_vShow, $data.menu == 'preview']])]), _createCommentVNode("div\n        :class=\"['edit-page-sidebar-score wide', { [type]: menu == 'preview' }]\"\n        v-show=\"!!menu\"\n      >\n        <edit-page-score :product=\"product\" wide :validate=\"validate\" />\n      </div")], 2 /* CLASS */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}