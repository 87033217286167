import AppSwitch from "@/components/AppSwitch";
import AppSelect from "@/components/AppSelect";
import AppSlider from "@/components/AppSlider";
import DemandForecastingSettingsGrid from "@/components/DemandForecastingSettingsGrid";
import DemandForecastingSettingsSeasonal from "@/components/DemandForecastingSettingsSeasonal";
export default {
  name: "DemandForecastingSettingsForecasting",
  components: {
    AppSwitch,
    AppSelect,
    AppSlider,
    DemandForecastingSettingsGrid,
    DemandForecastingSettingsSeasonal
  },
  props: {
    settings: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      data: {
        //current: JSON.parse(JSON.stringify(this.settings)),
        x: {
          title: "Period",
          values: ["7D", "14D", "30D", "90D"]
        },
        y: {
          title: "% of weight",
          values: ["200%", "175%", "150%", "125%", "100%", "75%", "50%", "25%", "0%"]
        }
      },
      velocityHeight: 0,
      resizeBar: null,
      resizeY: null,
      deltaY: 0,
      boost_types: [{
        value: "manual",
        text: "Manual"
      }, {
        value: "seasonal",
        text: "Seasonal"
      }, {
        value: "trend",
        text: "Trend"
      }],
      percents: [{
        value: -75,
        label: "-75%",
        colors: ["#FECDCD", "#DB333B"]
      }, {
        value: -50,
        label: "-50%",
        colors: ["#FECDCD", "#DB333B"]
      }, {
        value: -25,
        label: "-25%",
        colors: ["#FECDCD", "#DB333B"]
      }, {
        value: -10,
        label: "-10%",
        colors: ["#FECDCD", "#DB333B"]
      }, {
        value: 0,
        label: "0%",
        colors: ["#595959", "#595959"],
        start: true
      }, {
        value: 25,
        label: "25%",
        colors: ["#CDE4BC", "#22863D"]
      }, {
        value: 50,
        label: "50%",
        colors: ["#CDE4BC", "#22863D"]
      }, {
        value: 75,
        label: "75%",
        colors: ["#CDE4BC", "#22863D"]
      }, {
        value: 100,
        label: "100%",
        colors: ["#CDE4BC", "#22863D"]
      }, {
        value: 150,
        label: "150%",
        colors: ["#CDE4BC", "#22863D"]
      }, {
        value: 200,
        label: "200%",
        colors: ["#CDE4BC", "#22863D"]
      }]
    };
  },
  computed: {
    calculateHeight() {
      return (percent, bar) => {
        if (this.velocityHeight > 0) {
          let height = this.velocityHeight * percent / 200;
          if (this.resizeBar == bar) {
            height += this.deltaY;
          }
          if (height <= 0) {
            return 0;
          }
          if (height >= this.velocityHeight) {
            return this.velocityHeight;
          }
          return height;
        }
        return 0;
      };
    },
    currentSettings() {
      return JSON.parse(JSON.stringify(this.settings));
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.velocityHeight = this.$refs.velocity.clientHeight;
    });
  },
  methods: {
    calculatePercent(bar) {
      if (bar) {
        const currentHeight = this.calculateHeight(this.currentSettings.forecasting_velocity[bar], bar);
        return currentHeight * 200 / this.velocityHeight;
      }
      return null;
    },
    onResizeStart(event, bar) {
      this.resizeBar = bar;
      this.resizeY = event.clientY;
    },
    onResize(event) {
      if (this.resizeBar) {
        this.deltaY = this.resizeY - event.clientY;
      }
    },
    onResizeStop() {
      const percent = this.calculatePercent(this.resizeBar);
      if (percent != null) {
        let current_settings = this.currentSettings;
        current_settings.forecasting_velocity[this.resizeBar] = this.calculatePercent(this.resizeBar);
        this.$emit("update", JSON.parse(JSON.stringify(current_settings)));
      }
      this.resizeBar = null;
      this.resizeY = null;
      this.deltaY = 0;

      //this.onUpdate()
    },
    onUpdate() {
      this.$emit("update", JSON.parse(JSON.stringify(this.currentSettings)));
    },
    onUpdateManualBoostValue($event) {
      let current_settings = this.currentSettings;
      current_settings["manual_boost_value"]["value"] = $event;
      this.$emit("update", JSON.parse(JSON.stringify(current_settings)));
    },
    onUpdateSeasonalBoostValue($event) {
      let current_settings = this.currentSettings;
      current_settings["seasonal_boost_value"] = $event;
      this.$emit("update", JSON.parse(JSON.stringify(current_settings)));
    }
  }
};