import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_date_value = _resolveComponent("date-value");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_evolution_value = _resolveComponent("evolution-value");
  const _component_slot_with_evolution_value = _resolveComponent("slot-with-evolution-value");
  const _component_overlap_bar_value = _resolveComponent("overlap-bar-value");
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  const _component_wrap_table_with_show_more = _resolveComponent("wrap-table-with-show-more");
  const _component_table_with_title = _resolveComponent("table-with-title");
  return _openBlock(), _createBlock(_component_table_with_title, {
    title: "Evolution",
    class: "advertising-period-table",
    onDownload: $options.downloadPerformancePeriodCSV,
    fields: $data.fields,
    loading: $props.loading
  }, {
    default: _withCtx(() => [_createVNode(_component_wrap_table_with_show_more, {
      modelValue: $options.period_pager,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.period_pager = $event),
      period: _ctx.period
    }, {
      default: _withCtx(() => [_createVNode(_component_b_table_lite, {
        class: "lite-table lite-table-hoverable period-table listing-table",
        fields: $data.fields,
        bordered: "",
        responsive: "",
        fixed: "",
        items: $options.items,
        "sticky-header": true,
        tbodyTrClass: item => _ctx.handleRowClass(item, $options.items),
        onRowClicked: _cache[0] || (_cache[0] = item => _ctx.handleRowClicked(item, $options.handleDateReset, $options.handleDateClick, 'period_agg'))
      }, {
        "head()": _withCtx(col => [_createVNode(_component_flex_row, {
          id: `advertising-period-table-${col?.label}`,
          styles: {
            justifyContent: col.field.justify,
            ...col.field.thStyle
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
            target: `advertising-period-table-${col?.label}`,
            style: {
              width: '100%'
            }
          }, {
            title: _withCtx(() => [_createElementVNode("div", {
              innerHTML: $options.getDescriptionBasedOnKpiKey(col?.column).title
            }, null, 8 /* PROPS */, _hoisted_1)]),
            content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
            default: _withCtx(() => [_createElementVNode("div", {
              innerHTML: col?.column === 'period_agg' ? $options.getPeriodColumnTitle : col?.label,
              class: "title"
            }, null, 8 /* PROPS */, _hoisted_2)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "styles"])]),
        "cell(selector)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_AppIcon, {
            icon: `zoom-${row.item.selected ? 'out' : 'in'}`,
            color: row.item.selected ? null : '#ADADAD',
            size: 16
          }, null, 8 /* PROPS */, ["icon", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(period_agg)": _withCtx(row => [_createVNode(_component_date_value, {
          value: row.item?.period_agg,
          format: $options.getDateValueFormat,
          styles: {
            justifyContent: 'flex-start',
            ..._ctx.activateDateValueStyles(row)
          },
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "format", "styles", "loading"])]),
        "cell(spend)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
            gap: 4
          }, {
            evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
              value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}spend_evol`]
            }, {
              default: _withCtx(({
                color
              }) => [_createVNode(_component_percentage_value, {
                value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}spend_evol`],
                color: color,
                "show-sign": "",
                charCount: $options.maxCharCount.percentage?.[`${_ctx.evolCategory[_ctx.periodCategory]}spend_evol`]
              }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
            default: _withCtx(() => [_createVNode(_component_bar_with_value, {
              tight: "",
              align: "left",
              value: row.item?.spend,
              color: "#5A52AE",
              min: $options.minMax?.spend?.min,
              max: $options.minMax?.spend?.max,
              loading: $props.loading,
              width: $options.maxCharCount?.currency?.spend
            }, {
              default: _withCtx(() => [_createVNode(_component_currency_value, {
                value: row.item?.spend,
                charCount: $options.maxCharCount?.currency?.spend
              }, null, 8 /* PROPS */, ["value", "charCount"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "width"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(sponsored_sales)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
            gap: 4
          }, {
            evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
              value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}sponsored_sales_evol`]
            }, {
              default: _withCtx(({
                color
              }) => [_createVNode(_component_percentage_value, {
                value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}sponsored_sales_evol`],
                color: color,
                "show-sign": "",
                charCount: $options.maxCharCount.percentage?.[`${_ctx.evolCategory[_ctx.periodCategory]}sponsored_sales_evol`]
              }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
            default: _withCtx(() => [_createVNode(_component_bar_with_value, {
              tight: "",
              align: "left",
              value: row.item?.sponsored_sales,
              color: "#8D85E5",
              min: $options.minMax?.sponsored_sales?.min,
              max: $options.minMax?.sponsored_sales?.max,
              loading: $props.loading,
              width: $options.maxCharCount?.currency?.sponsored_sales
            }, {
              default: _withCtx(() => [_createVNode(_component_currency_value, {
                value: row.item?.sponsored_sales,
                charCount: $options.maxCharCount?.currency?.sponsored_sales
              }, null, 8 /* PROPS */, ["value", "charCount"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading", "width"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(TACOS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
          value: row.item?.TACOS,
          background: "#EBD4F7",
          borderColor: "#CD93EB",
          min: $options.minMax?.TACOS?.min,
          max: $options.minMax?.TACOS?.max,
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.TACOS
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        "cell(CVR)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getColorsBasedOnRange(row.item?.CVR, $data.CVR_HEATMAP_COLORS),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.CVR,
            limit: 0.1
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(ROAS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
          value: row.item?.ROAS,
          background: "#E6E9FE",
          borderColor: "#D8DCFD",
          min: $options.minMax?.ROAS?.min,
          max: $options.minMax?.ROAS?.max,
          loading: $props.loading,
          align: "left"
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            styles: {
              padding: 0
            },
            value: row.item?.ROAS,
            precision: 1
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        "cell(units_sold_clicks_14_d)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
            gap: 16
          }, {
            default: _withCtx(() => [_createVNode(_component_number_value, {
              value: row.item?.units_sold_clicks_14_d,
              loading: $props.loading,
              tight: ""
            }, null, 8 /* PROPS */, ["value", "loading"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(clicks)": _withCtx(row => [_createVNode(_component_number_value, {
          value: row.item?.clicks,
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "loading"])]),
        "cell(CPC)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getColorForDecile(row.item?.CPC, $options.minMax?.CPC?.min, $options.minMax?.CPC?.max, $data.CPC_DECILE_COLORS),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.CPC,
            precision: 2,
            config: _ctx.currencyConfig
          }, null, 8 /* PROPS */, ["value", "config"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(sponsored_asin)": _withCtx(row => [_createVNode(_component_bar_with_value, {
          value: row.item?.sponsored_asin,
          color: "#4C8EFF",
          min: $options.minMax?.sponsored_asin?.min,
          max: $options.minMax?.sponsored_asin?.max,
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            value: row.item?.sponsored_asin,
            charCount: $options.maxCharCount.number?.sponsored_asin,
            style: {
              padding: 0
            }
          }, null, 8 /* PROPS */, ["value", "charCount"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["fields", "items", "tbodyTrClass"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "period"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDownload", "fields", "loading"]);
}