import FilterItemSelector from "./FilterItemSelector.vue";
import DatePickerSelector from "@/components/DatePickerSelector.vue";
import DropdownBody from "@/components/shared/DropdownBody.vue";
export default {
  name: 'DatePickerFilter',
  components: {
    FilterItemSelector,
    DatePickerSelector,
    DropdownBody
  },
  expose: ['toggle', 'close'],
  props: {
    date: {
      type: Object,
      required: true
    },
    pillStyle: {
      type: Object,
      default: () => ({
        backgroundColor: '#736BC9',
        color: '#FFFFFF'
      })
    },
    noCaret: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleHidden() {
      this.$emit('hidden');
    },
    toggle() {
      if (this.loading) {
        // We put this check here to prevent the dropdown from opening 
        // when the date picker is loading
        return;
      }
      this.handleHidden();
      this.$refs.dropdown.toggle();
    },
    close() {
      this.$refs.dropdown.close();
    }
  }
};