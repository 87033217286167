import DatePicker from './DatePicker.vue';
import { processDate } from '../utils';
import * as R from 'ramda';
import DropdownBody from './shared/DropdownBody.vue';
import DatePickerMixin from '../mixins/DatePickerMixin.vue';
import { EVENTS } from '../utils';
import DashboardMixin from '../mixins/DashboardMixin.vue';
import { mapState } from 'vuex';
export default {
  name: 'DatePickerWrapper',
  components: {
    DatePicker,
    DropdownBody
  },
  mixins: [DatePickerMixin, DashboardMixin],
  mounted() {
    this.handleSubmit(this.defaultDate());
  },
  data() {
    return {
      range: {
        start: "",
        end: ""
      },
      compare: {
        start: "",
        end: ""
      },
      startDate: "",
      endDate: "",
      title: "",
      compareTitle: {},
      shown: false,
      currentPeriod: {
        period: null,
        resetObject: null
      }
    };
  },
  computed: {
    ...mapState({
      datePickerPeriod: state => state.period.datePickerPeriod
    })
  },
  methods: {
    handleDatePickerCanel() {
      this.close();
    },
    close() {
      this.$refs.dropdown.close();
    },
    setupWrapper({
      title,
      compareTitle = {},
      range,
      compare
    }) {
      const format = 'YYYY-MM-DD';
      this.range = range;
      this.compare = compare;
      this.range.start = processDate(range.start, format);
      this.range.end = processDate(range.end, format);
      this.compare.start = processDate(compare.start, format);
      this.compare.end = processDate(compare.end, format);
      this.startDate = processDate(range.start, 'DD MMM');
      this.endDate = processDate(range.end, 'DD MMM YYYY');
      this.title = title;
      this.compareTitle = compareTitle;
    },
    handleSubmit({
      title,
      range,
      compare,
      compareTitle
    }) {
      //console.log("Current Period: ", this.currentPeriod)
      this.setupWrapper({
        title,
        range,
        compare,
        compareTitle
      });
      if (R.equals(this.currentPeriod.period, {
        range,
        compare,
        title
      })) {
        this.close();
        return;
      }
      this.$store.dispatch('datePickerSetPeriod', {
        range,
        compare,
        title
      });
      this.currentPeriod = {
        period: {
          range,
          compare,
          title
        },
        resetObject: {
          range: title,
          compare: compareTitle
        }
      };
      //console.log("Handle Submit: ", this.currentTab, this.currentView, this.currentPeriod)
      window.$bus.trigger(this.refreshTriggers[this.currentTab][this.currentView]);
      this.close();
    },
    handleDatePickerHidden() {
      // Reset the date picker to the current range
      //console.log("Current Period: ", this.currentPeriod)
      const {
        range,
        compare
      } = this.currentPeriod.resetObject;
      window.$bus.trigger(EVENTS.RESET_DATE_PICKER, {
        range,
        compare
      });
    },
    handleDatePickerShown() {
      window.$bus.trigger(EVENTS.CALENDAR_AUTO_SCROLL);
    }
  }
};