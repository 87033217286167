import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-20efb0e8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "evolution"
};
const _hoisted_4 = {
  class: "currency"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loading_element = _resolveComponent("loading-element");
  const _component_flex_column = _resolveComponent("flex-column");
  return _openBlock(), _createBlock(_component_flex_column, {
    class: "subtitle-card",
    gap: 5
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_loading_element, {
      loading: $props.loading,
      size: 30,
      height: 12
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.title), 1 /* TEXT */)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_loading_element, {
      loading: $props.loading,
      size: 20,
      height: 12
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "evolution", {}, undefined, true)]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["loading"])])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_loading_element, {
      loading: $props.loading,
      size: 60,
      height: 12
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["loading"])])]),
    _: 3 /* FORWARDED */
  });
}