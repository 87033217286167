export default {
  name: 'GraphController',
  emits: ['changeView'],
  props: {
    controllers: {
      type: Array,
      required: true
    },
    selectedView: {
      type: String,
      required: true
    }
  },
  methods: {
    handleClick(controller) {
      this.$emit('changeView', controller);
    },
    activate(item) {
      return this.selectedView === item;
    }
  }
};