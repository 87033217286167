import { mapState } from "vuex";
import AppIcon from "@/components/AppIcon";
import DemandForecastingSettings from "@/components/DemandForecastingSettings";
export default {
  name: "DemandForecastingSidebar",
  emits: ["translate", "is-sidebar-collapsed"],
  components: {
    AppIcon,
    DemandForecastingSettings
  },
  props: {
    account_id: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState({
      demand_forecasting_settings_initialized: state => state.demand_forecasting.demand_forecasting_settings
    })
  },
  data() {
    return {
      menu: null
    };
  },
  methods: {
    onToggleMenu(menu) {
      this.menu = this.menu == menu ? null : menu;
      this.$emit("is-sidebar-collapsed", this.menu == null);
    }
  }
};