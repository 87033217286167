import { mapState, mapMutations } from "vuex";
import { EVENTS } from "@/utils";
import AppDropdown from "@/components/AppDropdown";
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";
import FlexRow from "@/components/FlexRow.vue";
import SellerFilter from "@/components/new-filters/SellerFilter.vue";
import AccountsMixin from "@/mixins/AccountsMixin.vue";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin";
import ProductFilter from "@/components/new-filters/ProductFilter.vue";
import MarketplaceFilter from "@/components/new-filters/MarketplaceFilter.vue";
export default {
  name: "ProductDataTopSection",
  mixins: [AccountsMixin, InitializeFiltersMixin],
  components: {
    AppDropdown,
    AppButton,
    AppIcon,
    FlexRow,
    SellerFilter,
    ProductFilter,
    MarketplaceFilter
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      //item: "metric",
      options: [{
        value: "imperial",
        text: "Imperial"
      }, {
        value: "metric",
        text: "Metric"
      }],
      sellers: []
    };
  },
  async created() {
    ////console.log("[product data top section created.. changing dropdown]");
  },
  async mounted() {
    ////console.log("[product data top section mounted]");
  },
  computed: {
    ...mapState({
      sellers_filter: state => state.product_data.sellers_filter,
      marketplaces_filter: state => state.product_data.marketplaces_filter,
      products_filter: state => state.product_data.products_filter,
      current_workspace: state => state.workspace.current_workspace,
      item: state => state.workspace.scaleType
    })
  },
  methods: {
    ...mapMutations(["productDataSellersFilterSet", "productDataProductsFilterSet", "productDataKeywordsListSet"]),
    async applySellers() {
      if (this.sellers_filter.isModified) {
        //console.log("[calling apply filters to remove selected filter]");
        if (this.sellers_filter.selected.length !== 0 && this.sellers_filter.selected[0] !== undefined) {
          this.sellers_filter.selectOnly(this.sellers_filter.selected[0], "id");
        }
        this.sellers_filter.apply();
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_PRODUCT_DATA_PAGE);
      }
    },
    async clearSellers() {
      if (this.sellers_filter.isModified) {
        //console.log("[clear sellers called]");
        this.sellers_filter.clear();
        window.$bus.trigger("showSuccess", {
          message: "⏳Refreshing ...  ",
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_PRODUCT_DATA_PAGE);
      }
    },
    applyProducts() {
      if (this.products_filter.isModified) {
        this.products_filter.apply();
        let content_ids = this.products_filter.selected.map(item => item.content_id);
        this.productDataKeywordsListSet(content_ids);
        window.$bus.trigger(EVENTS.REFRESH_PRODUCT_DATA_PAGE);
      }
    },
    clearProducts() {
      if (this.products_filter.isModified) {
        this.products_filter.clear();
        this.productDataKeywordsListSet([]);
        window.$bus.trigger(EVENTS.REFRESH_PRODUCT_DATA_PAGE);
      }
    },
    async searchProducts($event) {
      this.products_filter.searching = true;
      if (Array.isArray($event) && $event.length === 0) {
        this.products_filter.searching = false;
      }
      this.productDataKeywordsListSet($event);
      await window.$bus.trigger(EVENTS.REFRESH_PRODUCT_DATA_SUGGESTION_PAGE);
    },
    async handleScaleTypeChange(newScaleType) {
      //console.log("[selected scale option]:", newScaleType);
      await window.$bus.trigger(EVENTS.CHANGE_PRODUCT_DATA_SCALE, newScaleType);
    }
  }
};