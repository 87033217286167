import moment from 'moment';
import { mapState } from 'vuex';
import AppButton from '@/components/AppButton';
import DatePickerCalendar from '@/components/DatePickerCalendar';
import DatePickerCalendarWeek from './DatePickerCalendarWeek.vue';
import FlexRow from '@/components/FlexRow.vue';
import { isObjectEmpty, generateMonthsBetweenYears, makeArrayUnique } from "@/utils";
import { getDatePickerDayId } from "../utils/datepicker";
export default {
  name: 'PpcStrategiesDatesModal',
  emits: ['weeks-selected', 'close', 'submit'],
  components: {
    AppButton,
    DatePickerCalendar,
    DatePickerCalendarWeek,
    FlexRow
  },
  expose: ["scrollTo", "setupCalenderDetails"],
  props: {
    start: {
      type: Object
    },
    end: {
      type: Object
    },
    noCancel: {
      type: Boolean,
      default: false
    },
    noOk: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.scrollTo(this.start);
  },
  data() {
    return {
      loading: false,
      monthDate: null,
      hoverDate: null,
      rangeCustom: {
        start: null,
        end: null
      },
      monthList: this.getMonthList(),
      today: moment()
    };
  },
  computed: {
    ...mapState({
      weeksRange: state => state.ppc_settings.weeksRange
    }),
    disableOkButton() {
      return !this.rangeCustom?.start || !this.rangeCustom?.end;
    },
    weekFilledRanges() {
      let result = this.weeksRange.filter(weekData => !!weekData?.strategy && !isObjectEmpty(weekData?.strategy)).map(obj => obj);
      return makeArrayUnique(result);
    },
    weekRange() {
      return {
        start: this.start,
        end: this.end
      };
    }
  },
  methods: {
    setupCalenderDetails() {
      this.$refs.calendarWeek.setupCalenderDetails(this.weekRange);
    },
    setState(range = {
      start,
      end
    }) {
      if (range.start) this.rangeCustom.start = moment(range.start);
      if (range.end) this.rangeCustom.end = moment(range.end);
    },
    getMonthList() {
      const today = moment();
      const months = generateMonthsBetweenYears(today.year(), today.year() + 1, today.month());
      return months;
    },
    scrollTo(date) {
      date = moment(date).format("YYYY-MM-DD");
      this.$refs.calendar.scrollTo(getDatePickerDayId(date, 'week-date'));
    },
    onChangeDate(date) {
      this.monthDate = date;
    },
    onSubmit() {
      this.$emit('submit', {
        startWeek: this.rangeCustom.start,
        endWeek: this.rangeCustom.end
      });
      this.$emit('close');
    },
    handleDateSubmit(val) {
      if (val.length > 0) {
        this.setState({
          start: moment(val[0]),
          end: moment(val[val.length - 1])
        });
      }
    }
  }
};