import { processDate } from "../utils";
import AppIcon from "./AppIcon.vue";
import FlexRow from "./FlexRow.vue";
import LoadingElement from "./shared/LoadingElement.vue";
export default {
  name: "DatePickerSelector",
  emits: ['click'],
  props: {
    title: {
      type: [String, null],
      required: true,
      default: null
    },
    start: {
      type: Object,
      required: true,
      default: () => ({
        date: null
      })
    },
    end: {
      type: Object,
      required: true,
      default: () => ({
        date: null
      })
    },
    pillStyle: {
      type: Object,
      required: false,
      default: () => ({})
    },
    dateStyle: {
      type: Object,
      required: false,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: Number,
      required: false,
      default: 200
    }
  },
  components: {
    FlexRow,
    AppIcon,
    LoadingElement
  },
  computed: {
    getPillStyle() {
      return {
        borderRadius: "24px",
        padding: "2px 8px",
        fontSize: "13px",
        fontWeight: "500",
        ...this.pillStyle
      };
    },
    getDateStyle() {
      return {
        color: "#757575",
        fontWeight: 500,
        lineHeight: "12px",
        fontSize: "12px",
        textAlign: "right",
        ...this.dateStyle
      };
    },
    getFormattedDates() {
      const start = processDate(this.start.date, this.start.format);
      const end = processDate(this.end.date, this.end.format);
      return `${start} - ${end}`;
    }
  }
};