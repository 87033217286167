import FlexRow from "@/components/FlexRow.vue";
import SponsoredSalesCard from "@/components/kpi-cards/ppc_v2/SponsoredSalesCard.vue";
import SpendCard from "@/components/kpi-cards/ppc_v2/SpendCard.vue";
import TacosCard from "@/components/kpi-cards/ppc_v2/TacosCard.vue";
import CvrCard from "@/components/kpi-cards/ppc_v2/CvrCard.vue";
import { mapState } from "vuex";
export default {
  name: "AdvertisingCards",
  components: {
    FlexRow,
    SponsoredSalesCard,
    SpendCard,
    TacosCard,
    CvrCard
  },
  computed: {
    ...mapState({
      kpis: state => state.dashboard_v2.kpis.data.advertising,
      periodCategory: state => state.dashboard_v2.periodCategory
    }),
    previousPeriodKpis() {
      return this.kpis[this.periodCategory];
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};