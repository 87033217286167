export default {
  name: 'DemandForecastingSettingsGrid',
  props: {
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 240
    },
    xData: {
      type: Object,
      default: () => {}
    },
    yData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      canvas: null,
      ctx: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.canvas = this.$refs.chart;
      this.ctx = this.canvas.getContext('2d');
      setTimeout(() => {
        this.update();
      }, 300);
    });
  },
  methods: {
    update() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.area();
    },
    area() {
      let xDelta = 0;
      let yDelta = 0;
      let xBottom = 0;
      if (this.xData.title) {
        yDelta += 20;
        xBottom += 20;
      }
      if (this.yData.title) {
        xDelta += 20;
      }
      if (this.xData.values && this.xData.values.length) {
        yDelta += 30;
      }
      if (this.yData.values && this.yData.values.length) {
        xDelta += 35;
      }
      if (this.xData.title) {
        const centerDelta = this.textWidth(this.xData.title) / 2;
        this.text(this.xData.title, xDelta + (this.width - xDelta) / 2 - centerDelta, this.height, '#757575', 'left');
      }
      if (this.yData.title) {
        this.text(this.yData.title, 20, 0 - this.width / 2 + 10, '#757575', 'center', -90);
      }
      if (this.xData.values && this.xData.values.length) {
        const step = (this.width - xDelta) / this.xData.values.length;
        for (let i = 0; i < this.xData.values.length; i++) {
          const textWidth = this.textWidth(this.xData.values[i]);
          const centerDelta = (step - textWidth) / 2;
          this.text(this.xData.values[i], xDelta + step * i + centerDelta, this.height - xBottom, '#999999', 'left');
        }
      }
      if (this.yData.values && this.yData.values.length) {
        for (let i = 0; i < this.yData.values.length; i++) {
          const top = 10 + (this.height - yDelta) / (this.yData.values.length - 1) * i;
          this.line(55, top, this.width, top, '#E3E3E3', 1, true);
          this.text(this.yData.values[i], 20, top + 3, '#999999', 'left');
        }
      }
    },
    line(startX, startY, endX, endY, color = '#E3E3E3', size = 1, isDashed = false) {
      if (isDashed) {
        this.ctx.setLineDash([4, 2]);
      }
      this.ctx.beginPath();
      this.ctx.moveTo(startX, startY);
      this.ctx.lineTo(endX, endY);
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = size;
      this.ctx.stroke();
      this.ctx.setLineDash([]);
    },
    rect(x, y, width, height) {
      this.ctx.fillStyle = 'red';
      this.ctx.fillRect(x, y, width, height);
    },
    text(text, x, y, color = '#757575', align = 'center', rotate) {
      if (rotate) {
        this.ctx.save();
        this.ctx.translate(this.width / 2, this.height / 2);
        this.ctx.rotate(rotate * Math.PI / 180);
      }
      this.ctx.font = '500 11px Figtree';
      this.ctx.fillStyle = color;
      this.ctx.textAlign = align;
      this.ctx.fillText(text, x, y);
      this.ctx.restore();
    },
    textWidth(text, align = 'center') {
      this.ctx.font = '500 11px Figtree';
      this.ctx.textAlign = align;
      const result = this.ctx.measureText(text).width;
      this.ctx.restore();
      return result;
    }
  }
};