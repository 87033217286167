import FlexRow from "@/components/FlexRow.vue";
export default {
  name: "SlotWithEvolutionValue",
  components: {
    FlexRow
  },
  props: {
    gap: {
      type: Number
    }
  }
};