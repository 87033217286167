import FlexColumn from '../FlexColumn.vue';
import { DateValue, HugValue } from '../tableElements';
import { mapState } from 'vuex';
export default {
  name: 'AggregateTableHeader',
  components: {
    HugValue,
    FlexColumn,
    DateValue
  },
  props: {
    colGroup: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    width: {
      type: String,
      default: '100%'
    },
    period1: {
      type: String
    },
    period2: {
      type: String
    },
    fieldsClass: {
      type: String
    }
  },
  computed: {
    ...mapState({
      config: state => state.currency_config.config
    })
  },
  methods: {
    format(value) {
      // Replace space with non-breaking space
      return value.replace(/ /g, '\u00A0');
    }
  }
};