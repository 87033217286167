import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3a9efdbc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "image-list"
};
const _hoisted_3 = {
  class: "images-content"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "images-counter"
};
const _hoisted_6 = {
  class: "images-number"
};
const _hoisted_7 = {
  class: "product-asin-sku icon-container"
};
const _hoisted_8 = {
  class: "icon-content"
};
const _hoisted_9 = {
  class: "status-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_img = _resolveComponent("b-img");
  const _component_Popper = _resolveComponent("Popper");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_modal = _resolveComponent("app-modal");
  const _component_app_td = _resolveComponent("app-td");
  return _openBlock(), _createBlock(_component_app_td, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.renderedImageList, image => {
      return _openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_Popper, {
        placement: "left",
        hover: "",
        arrow: ""
      }, _createSlots({
        default: _withCtx(() => [_createElementVNode("div", {
          class: "image-container",
          ref_for: true,
          ref: image.link,
          onClick: $event => $options.onClickImage(image.link)
        }, [image.link != '' ? (_openBlock(), _createBlock(_component_b_img, {
          key: 0,
          src: image.link,
          alt: "Image",
          style: _normalizeStyle(image.width > image.height ? 'width: 44px' : 'height:44px')
        }, null, 8 /* PROPS */, ["src", "style"])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_4)]),
        _: 2 /* DYNAMIC */
      }, [image.hover_img_link != '' ? {
        name: "content",
        fn: _withCtx(() => [_createVNode(_component_b_img, {
          src: image.hover_img_link,
          alt: "Image",
          style: _normalizeStyle(image.width > image.height ? 'width: 500px' : 'height:500px')
        }, null, 8 /* PROPS */, ["src", "style"])]),
        key: "0"
      } : undefined]), 1024 /* DYNAMIC_SLOTS */), $props.isCollapsed ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "expand-button",
        onClick: _cache[0] || (_cache[0] = (...args) => $options.expandColumn && $options.expandColumn(...args))
      }, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_app_icon, {
        icon: "seo-image-counter",
        size: "12"
      }), _createElementVNode("p", _hoisted_6, _toDisplayString($options.imageListSize), 1 /* TEXT */)]), _createVNode(_component_app_icon, {
        icon: "chevron-right",
        size: "12"
      })])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createVNode(_component_Popper, {
        placement: "left",
        hover: "",
        arrow: ""
      }, {
        content: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createVNode(_component_app_icon, {
          size: "16",
          icon: image.link === '' ? 'product-score-bad' : image.hover_img_size == 'image_large' ? 'check-v2' : 'product-score-warning',
          color: image.link === '' ? '#ffffff' : image.hover_img_size == 'image_large' ? '#22863d' : '#d7aa0f'
        }, null, 8 /* PROPS */, ["icon", "color"]), _createElementVNode("span", null, _toDisplayString($options.imagePopupText(image)), 1 /* TEXT */)])]),
        default: _withCtx(() => [_createVNode(_component_app_icon, {
          size: "16",
          icon: image.link === '' ? 'product-score-bad' : image.hover_img_size == 'image_large' ? 'check-v2' : 'product-score-warning',
          color: image.link === '' ? '#ffffff' : image.hover_img_size == 'image_large' ? '#22863d' : '#d7aa0f'
        }, null, 8 /* PROPS */, ["icon", "color"])]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)])]), !$props.pushModal ? (_openBlock(), _createBlock(_component_app_modal, {
        key: 0,
        "no-header": "",
        "no-footer": "",
        modelValue: $data.hoverImage[image.link],
        "onUpdate:modelValue": $event => $data.hoverImage[image.link] = $event,
        custom_class: "image-popup"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_img, {
          src: image.hover_img_link,
          alt: "Image",
          onMouseleave: $options.onLeaveImage,
          style: _normalizeStyle(image.width > image.height ? 'width: 500px' : 'height:500px')
        }, null, 8 /* PROPS */, ["src", "onMouseleave", "style"])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])) : _createCommentVNode("v-if", true)]);
    }), 256 /* UNKEYED_FRAGMENT */))])]),
    _: 1 /* STABLE */
  });
}