export default {
  name: 'AppCard',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    minHeight: {
      type: Number,
      default: null
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    hasBorder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    styles() {
      return {
        minHeight: this.minHeight ? this.minHeight + 'px' : null
      };
    }
  }
};