import { Bar } from 'vue-chartjs';
import TableGrapher from './shared/graphs/TableGrapher.vue';
import SkeletonItem from './shared/SkeletonItem.vue';
import TableSkeleton from './TableSkeleton.vue';
import EmptySpace from './shared/EmptySpace.vue';
import CenterWrapper from './shared/CenterWrapper.vue';
import PagerItem from './shared/PagerItem.vue';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, LineController } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, LineController);
export default {
  name: 'TableWithChart',
  emits: ['retry', 'page'],
  props: {
    graphTitle: {
      type: String,
      required: false
    },
    evolutionTitle: {
      type: String,
      required: false
    },
    graphHeaders: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    sortBy: {
      type: String,
      required: false,
      default: ''
    },
    sortDirection: {
      type: String,
      required: false,
      default: ''
    },
    tableLoading: {
      type: Boolean
    },
    totalLoading: {
      type: Boolean
    },
    errorOccurred: {
      type: Boolean
    },
    description: {
      type: Array,
      required: false
    },
    countries: {
      type: Array,
      required: false
    },
    graphPositions: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    chartPositions: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    minMax: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    totalCount: {
      type: Number,
      required: false,
      default: 0
    },
    pageSize: {
      type: Number,
      required: false,
      default: 10
    }
  },
  components: {
    TableGrapher,
    Bar,
    SkeletonItem,
    TableSkeleton,
    EmptySpace,
    PagerItem,
    CenterWrapper
  },
  data() {
    return {
      chart: {
        key: 'chart_1',
        name: "Chart 1"
      },
      chartLegend: ["Alert Inventory Level", "Unsellable", "Researching", "Inbound Working", "Inbound Shipped", "Inbound Receiving", "Fulfillable", "Reserved Processing", "Reserved Transfers"]
    };
  },
  computed: {
    noGraphData() {
      return this.graphPositions.length === 0;
    },
    noChartData() {
      return this.chartPositions.length === 0;
    },
    chartLabels() {
      return this.dashboardChartData.map(item => item.date_column);
    },
    dashboardChartData() {
      return this.chartPositions;
    },
    chartData() {
      /*
          Generate the data for each Legend with this structure
          {
              label: `date_value`
          }
           Mapping between Legend and `dashboardChartData`:
              Alert Inventory Level: alert_inventory_level_days
              Unsellable: afn_unsellable_quantity_days
              Researching: afn_researching_quantity_days
              Inbound Working: afn_inbound_working_quantity_days
              Inbound Shipped: afn_inbound_shipped_quantity_days
              Inbound Receiving: afn_inbound_receiving_quantity_days
              Fulfillable: afn_fulfillable_quantity_days
              Reserved: afn_reserved_quantity_days
          
       */

      const chartKeys = [{
        key: 'reserved_fc_processing_days',
        name: 'Reserved Processing',
        color: '#e0e0e0'
      }, {
        key: 'reserved_fc_transfers_days',
        name: 'Reserved Transfers',
        color: '#9e9e9e'
      }, {
        key: 'afn_fulfillable_quantity_days',
        name: 'Fulfillable',
        color: '#4caf50'
      }, {
        key: 'afn_inbound_receiving_quantity_days',
        name: 'Inbound Receiving',
        color: '#c8e6c9'
      }, {
        key: 'afn_inbound_shipped_quantity_days',
        name: 'Inbound Shipped',
        color: '#2196f3'
      }, {
        key: 'afn_inbound_working_quantity_days',
        name: 'Inbound Working',
        color: '#bbdefb'
      }, {
        key: 'afn_researching_quantity_days',
        name: 'Researching',
        color: '#f4c7c3'
      }, {
        key: 'afn_unsellable_quantity_days',
        name: 'Unsellable',
        color: '#f44336'
      }];
      const datasets = chartKeys.map(({
        key,
        name,
        color
      }) => {
        const data = {
          type: 'bar',
          label: name,
          data: this.dashboardChartData.map(item => item[key]),
          backgroundColor: color,
          borderColor: color + '44',
          borderWidth: 1,
          fill: false,
          tension: 0.1
        };
        return data;
      });

      // Extract `alert_inventory_level` from `dashboardPositionsChartReportGet`
      const alert_inventory_level_days = this.dashboardChartData.map(item => item.alert_inventory_level_days);

      // Make dataset for `alert_inventory_level`
      const lineData = {
        type: 'line',
        label: 'Alert Inventory Level',
        data: alert_inventory_level_days,
        backgroundColor: '#000000',
        borderColor: '#000000',
        borderWidth: 2,
        fill: false,
        tension: 0.1
      };
      return {
        // Generate labels and data from 01/01/2022 to 01/01/2023
        labels: this.chartLabels,
        datasets: [lineData, ...datasets]
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            }
          },
          y: {
            stacked: true,
            grid: {
              display: false
            }
          }
        },
        tooltips: {
          enabled: true
        },
        plugins: {
          legend: {
            display: true,
            position: 'right',
            align: 'center'
          },
          tooltip: {
            mode: 'index',
            intersect: false,
            backgroundColor: '#ffffff',
            titleColor: '#000000',
            bodyColor: '#000000',
            borderColor: '#000000',
            borderWidth: 0.5,
            titleFont: {
              size: 14,
              weight: 'bold'
            },
            size: 14
          }
        }
      };
    }
  },
  methods: {
    selectChart(item) {
      this.chart = item;
    }
  }
};