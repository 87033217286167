export default {
  name: 'DatePickerSidebar',
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: {
      type: String
    },
    options: {
      type: Array,
      default: () => [{
        id: 1,
        name: 'Default'
      }]
    }
  },
  methods: {
    handleChange(item) {
      this.$emit('update:modelValue', item.key);
      this.$emit('change', item);
    }
  }
};