export default {
  name: 'FilterCounter',
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    formattedCount() {
      return this.count > 9 ? '9+' : this.count;
    }
  }
};