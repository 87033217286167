import { mapActions, mapState, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin";
import AppCard from "@/components/AppCard";
import AppInput from "@/components/AppInput";
import AppSelect from "@/components/AppSelect";
import AppButton from "@/components/AppButton";
export default {
  name: "WorkspaceInformation",
  mixins: [ValidateMixin],
  components: {
    AppCard,
    AppInput,
    AppButton,
    AppSelect
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      form: {
        workspace_name: "",
        workspace_currency: "",
        workspace_week_type: ""
      },
      unit_types: [{
        text: "Units",
        value: ""
      }, {
        text: "Metric",
        value: "metric"
      }, {
        text: "Imperial",
        value: "imperial"
      }],
      currencies: [{
        text: "Currency",
        value: ""
      }, {
        text: "EUR €",
        value: "EUR"
      }, {
        text: "USD $",
        value: "USD"
      }, {
        text: "GBP £",
        value: "GBP"
      }, {
        text: "CAD $",
        value: "CAD"
      }, {
        text: "AED د.إ.",
        value: "AED"
      }, {
        text: "AUD $",
        value: "AUD"
      }, {
        text: "BRL R$",
        value: "BRL"
      }, {
        text: "EGP ج.م.",
        value: "EGP"
      }, {
        text: "INR Rs",
        value: "INR"
      }, {
        text: "JPY ¥",
        value: "JPY"
      }, {
        text: "MXN $",
        value: "MXN"
      }, {
        text: "PLN zł",
        value: "PLN"
      }, {
        text: "SAR ر.س.",
        value: "SAR"
      }, {
        text: "SEK kr",
        value: "SEK"
      }, {
        text: "SGD $",
        value: "SGD"
      }, {
        text: "TRY TL",
        value: "TRY"
      }],
      week_types: [{
        text: "Week Type",
        value: ""
      }, {
        text: "Sunday",
        value: "sunday"
      }, {
        text: "Monday",
        value: "monday"
      }],
      isEdit: false,
      isReadOnly: true,
      dataLoading: false,
      loading: false
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      token: state => state.auth.token,
      current_workspace: state => state.workspace.current_workspace
    })
  },
  async mounted() {
    var is_authenticated = await this.authStatus(false);
    if (is_authenticated) {
      this.dataLoading = true;
      await this.workspaceList();
      await this.workspaceCurrent();
      this.setWorkspaceData(this.current_workspace);
      this.dataLoading = false;
      window.$bus.on("refreshWorkspaceList", this.refreshWorkspaceList);
    }
  },
  methods: {
    ...mapActions(["authMe", "authStatus", "workspaceUpdate", "workspaceList", "workspaceCurrent"]),
    async onSubmit() {
      if (this.isEdit) {
        const valid = await this.mValidate();
        if (valid) {
          this.loading = true;
          await this.workspaceUpdate(this.form);
          this.loading = false;
          this.isEdit = false;
        }
      }
    },
    onEdit() {
      this.isEdit = true;
    },
    onCancel() {
      this.setWorkspaceData(this.current_workspace);
      this.isEdit = false;
    },
    setWorkspaceData(val) {
      this.form = {
        ...val
      };
    },
    async refreshWorkspaceList() {
      await this.workspaceList();
      await this.workspaceCurrent();
      this.setWorkspaceData(this.current_workspace);
    }
  }
};