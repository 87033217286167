import AppPopover from "@/components/AppPopover";
export default {
  name: "DemandForecastingRowInventory",
  components: {
    AppPopover
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      id: null
    };
  },
  created() {
    this.id = Math.floor(100000 + Math.random() * 900000).toString();
  }
};