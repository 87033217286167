import SearchElement from "../SearchElement.vue";
import WrapWithDropdownBody from "../WrapWithDropdownBody.vue";
import { SelectedSection } from "../sections";
import CampaignFilterItem from "../items/CampaignFilterItem.vue";
import ListSection from "../sections/ListSection.vue";
export default {
  name: "CampaignFilterBody",
  components: {
    WrapWithDropdownBody,
    SearchElement,
    SelectedSection,
    CampaignFilterItem,
    ListSection
  },
  emits: ["clear", "select", "deselect", "selectAll", "apply", "search", "hidden", "closeDropdown", "selectOnly"],
  data() {
    return {
      searchValue: ""
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    selected: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    isModified: {
      type: Boolean,
      default: false
    },
    searching: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getListTitle() {
      return this.searching ? `${this.list.length} CAMPAIGNS FOUND` : "CAMPAIGNS";
    }
  }
};