export default {
  name: 'PpcBudgetAllocationCircle',
  props: {
    percent: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 100
    },
    size: {
      type: Number,
      default: 16
    }
  },
  computed: {
    grayColor() {
      return '#D1D1D1';
    },
    progressColor() {
      if (this.percent >= 90) {
        return '#47D16C';
      } else if (this.percent >= 60) {
        return '#D7AA0F';
      } else {
        return '#DB333B';
      }
    },
    circumference() {
      return 2 * Math.PI * 14;
    },
    offset() {
      return this.circumference * (1 - this.percent / 100);
    }
  }
};