import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-96487752"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "evolution"
};
const _hoisted_2 = ["title"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  class: "direction"
};
const _hoisted_5 = ["title"];
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  return $props.isEvolution ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    title: $options.valueTitle,
    class: "amount"
  }, [_createElementVNode("div", {
    innerHTML: $options.formattedAmount
  }, null, 8 /* PROPS */, _hoisted_3)], 8 /* PROPS */, _hoisted_2), _withDirectives(_createElementVNode("div", _hoisted_4, [_createVNode(_component_font_awesome_icon, {
    class: "icon",
    icon: `fa-solid ${this.amount > 0 ? 'fa-up-long' : 'fa-down-long'}`,
    color: this.amount > 0 ? 'green' : 'red'
  }, null, 8 /* PROPS */, ["icon", "color"])], 512 /* NEED_PATCH */), [[_vShow, this.amount !== null]])])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    title: $options.valueTitle,
    class: "d-flex align-items-center"
  }, [$props.amount < 0 ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["custom-bar-elem-neg", {
      noBar: !$options.shouldNotShowBarWrapper
    }])
  }, [_withDirectives(_createElementVNode("div", {
    class: "bar-wrapper",
    style: _normalizeStyle({
      marginLeft: $options.negative ? -100 : 0
    })
  }, [_createElementVNode("div", {
    class: "bar",
    style: _normalizeStyle($options.barWidth)
  }, null, 4 /* STYLE */)], 4 /* STYLE */), [[_vShow, $options.shouldNotShowBarWrapper]]), _createElementVNode("div", {
    class: _normalizeClass(["amount", {
      alignLeft: $props.alignLeft,
      alignCenter: $props.alignCenter
    }])
  }, [_createElementVNode("div", {
    innerHTML: $options.formattedAmount
  }, null, 8 /* PROPS */, _hoisted_6)], 2 /* CLASS */)], 2 /* CLASS */)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["custom-bar-elem", {
      noBar: !$options.shouldNotShowBarWrapper
    }])
  }, [_createElementVNode("div", {
    class: _normalizeClass(["amount", {
      alignLeft: $props.alignLeft,
      alignCenter: $props.alignCenter
    }])
  }, [_createElementVNode("div", {
    innerHTML: $options.formattedAmount
  }, null, 8 /* PROPS */, _hoisted_7)], 2 /* CLASS */), _withDirectives(_createElementVNode("div", {
    class: "bar-wrapper",
    style: _normalizeStyle({
      marginLeft: $options.negative ? -100 : 0
    })
  }, [_createElementVNode("div", {
    class: "bar",
    style: _normalizeStyle($options.barWidth)
  }, null, 4 /* STYLE */)], 4 /* STYLE */), [[_vShow, $options.shouldNotShowBarWrapper]])], 2 /* CLASS */))], 8 /* PROPS */, _hoisted_5));
}