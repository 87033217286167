import DatePickerCalendarMonthLayout from './DatePickerCalendarMonthLayout.vue';
import { DateValue } from '@/components/tableElements';
import DatePickerCalendarMixin from '../mixins/DatePickerCalendarMixin.vue';
export default {
  name: 'DatePickerCalendarMonth',
  emits: ['custom-date', 'hover-date', 'switch-to-custom', 'enableCustomMode', 'setDatePickerState'],
  components: {
    DatePickerCalendarMonthLayout,
    DateValue
  },
  expose: ["firstMonth", "lastMonth"],
  props: {
    range: {
      type: Object,
      default: () => {}
    },
    compare: {
      type: Object,
      default: () => {}
    },
    showDays: {
      type: Boolean,
      default: true
    },
    highlightToday: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isRangeCustom: {
      type: Boolean,
      default: false
    },
    isCompareCustom: {
      type: Boolean,
      default: false
    },
    hoverDate: {
      type: Object,
      default: () => {}
    },
    monthList: {
      type: Array,
      default: () => []
    },
    state: {
      type: Object
    },
    weekType: {
      type: String,
      default: "monday"
    },
    datesMap: {
      type: Map
    }
  },
  computed: {
    dateClass() {
      return (date, month, year) => this.getDayDateClass(date, month, year);
    }
  },
  data() {
    return {
      firstMonth: null,
      lastMonth: null
    };
  },
  mixins: [DatePickerCalendarMixin]
};