import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = {
  class: "d-flex w-100 justify-content-between"
};
const _hoisted_5 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_th = _resolveComponent("b-th");
  const _component_b_tr = _resolveComponent("b-tr");
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_date_value = _resolveComponent("date-value");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_evolution_value = _resolveComponent("evolution-value");
  const _component_rate_change_value = _resolveComponent("rate-change-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_dotted_value = _resolveComponent("dotted-value");
  const _component_overlap_bar_value = _resolveComponent("overlap-bar-value");
  const _component_slot_with_evolution_value = _resolveComponent("slot-with-evolution-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  const _component_wrap_table_with_show_more = _resolveComponent("wrap-table-with-show-more");
  const _component_table_with_title = _resolveComponent("table-with-title");
  return _openBlock(), _createBlock(_component_table_with_title, {
    title: "Evolution",
    class: "performance-period-table",
    onDownload: $options.downloadPerformancePeriodCSV,
    fields: $data.fields,
    loading: $props.loading
  }, {
    default: _withCtx(() => [_createVNode(_component_wrap_table_with_show_more, {
      modelValue: $options.period_pager,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.period_pager = $event),
      period: _ctx.period
    }, {
      default: _withCtx(() => [_createVNode(_component_b_table_lite, {
        class: "lite-table lite-table-hoverable performance-table",
        fields: $data.fields,
        bordered: "",
        responsive: "",
        fixed: "",
        items: $options.items,
        "sticky-header": true,
        tbodyTrClass: item => _ctx.handleRowClass(item, $options.items),
        "no-border-collapse": "",
        onRowClicked: $options.handleRowClick
      }, {
        "thead-top": _withCtx(() => [_createVNode(_component_b_tr, {
          class: "headers"
        }, {
          default: _withCtx(() => [_createVNode(_component_b_th, {
            colspan: "2 b-table-sticky-column"
          }), _createVNode(_component_b_th, {
            colspan: "3",
            class: "performance",
            style: {
              color: '#423994',
              borderBottom: '1px solid #423994'
            }
          }, {
            default: _withCtx(() => [_createTextVNode("Performance")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            colspan: "3",
            class: "supply",
            style: {
              color: '#9447FF',
              borderBottom: '1px solid #9447FF'
            }
          }, {
            default: _withCtx(() => [_createTextVNode("Supply")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            colspan: "2",
            class: "listing",
            style: {
              color: '#4C8EFF',
              borderBottom: '1px solid #4C8EFF'
            }
          }, {
            default: _withCtx(() => [_createTextVNode("Listing")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            colspan: "2",
            class: "advertising",
            style: {
              color: '#068481',
              borderBottom: '1px solid #068481'
            }
          }, {
            default: _withCtx(() => [_createTextVNode("Advertising")]),
            _: 1 /* STABLE */
          })]),
          _: 1 /* STABLE */
        })]),
        "head()": _withCtx(col => [_createVNode(_component_flex_row, {
          id: `performance-period-table-${col?.label}`,
          styles: {
            justifyContent: col.field.justify,
            ...col.field.thStyle
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
            target: `performance-period-table-${col?.label}`
          }, {
            title: _withCtx(() => [_createElementVNode("div", {
              innerHTML: $options.getDescriptionBasedOnKpiKey(col?.column).title
            }, null, 8 /* PROPS */, _hoisted_1)]),
            content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
            default: _withCtx(() => [_createElementVNode("div", {
              innerHTML: col?.column === 'period_agg' ? $options.getPeriodColumnTitle : col?.label,
              class: "title"
            }, null, 8 /* PROPS */, _hoisted_2)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "styles"])]),
        "head(revenue_excl_VAT)": _withCtx(col => [_createVNode(_component_flex_row, {
          styles: {
            justifyContent: col.field.justify,
            ...col.field.thStyle
          },
          id: 'performance-period-table-revenue_excl_VAT'
        }, {
          default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
            triggers: "hover",
            target: 'performance-period-table-revenue_excl_VAT',
            style: {
              width: '100%'
            }
          }, {
            title: _withCtx(() => [_createElementVNode("div", {
              innerHTML: $options.getDescriptionBasedOnKpiKey(col?.column).title
            }, null, 8 /* PROPS */, _hoisted_3)]),
            content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
            default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
              class: "title",
              innerHTML: col?.label
            }, null, 8 /* PROPS */, _hoisted_5), _createElementVNode("div", null, [_ctx.evolutionType === 'percentage' ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = $event => _ctx.toggleEvolutionType($event, 'currency')),
              class: "evolution-type"
            }, "€")) : _createCommentVNode("v-if", true), _ctx.evolutionType === 'currency' ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = $event => _ctx.toggleEvolutionType($event, 'percentage')),
              class: "evolution-type"
            }, "%")) : _createCommentVNode("v-if", true)])])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles"])]),
        "cell(selector)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_AppIcon, {
            icon: `zoom-${row.item.selected ? 'out' : 'in'}`,
            color: row.item.selected ? null : '#ADADAD',
            size: 16
          }, null, 8 /* PROPS */, ["icon", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(period_agg)": _withCtx(row => [_createVNode(_component_date_value, {
          value: row.item?.period_agg,
          format: $options.getDateValueFormat,
          styles: {
            justifyContent: 'flex-start',
            ..._ctx.activateDateValueStyles(row)
          },
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "format", "styles", "loading"])]),
        "cell(revenue_excl_VAT)": _withCtx(row => [_createVNode(_component_rate_change_value, {
          value1: row.item?.revenue_and_lost_revenue_excl_VAT,
          onClick: $event => $options.handleRowClick(row.item),
          value2: row.item?.revenue_excl_VAT,
          min1: 0,
          min2: 0,
          max1: $options.minMax?.revenue_and_lost_revenue_excl_VAT?.max,
          max2: $options.minMax?.revenue_and_lost_revenue_excl_VAT?.max,
          loading: $props.loading,
          rateChange: _ctx.periodCategory === 'prev_period' ? row.item?.revenue_excl_VAT_evol : row.item?.last_year_revenue_excl_VAT_evol,
          color1: "#FFB3B8",
          color2: "#8D85E5",
          evolutionType: _ctx.evolutionType
        }, {
          lost: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.lost_revenue_excl_VAT,
            limit: 10_000,
            loading: $props.loading,
            styles: {
              justifyContent: 'flex-end',
              width: '100%'
            }
          }, null, 8 /* PROPS */, ["value", "loading"])]),
          gained: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.revenue_excl_VAT,
            limit: 10_000,
            loading: $props.loading
          }, null, 8 /* PROPS */, ["value", "loading"])]),
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[_ctx.evolutionType]}`]
          }, {
            default: _withCtx(({
              color
            }) => [_ctx.evolutionType === 'currency' ? (_openBlock(), _createBlock(_component_currency_value, {
              key: 0,
              value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[_ctx.evolutionType]}`],
              color: color,
              charCount: $options.maxCharCount.currency?.[`${_ctx.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[_ctx.evolutionType]}`],
              "show-sign": ""
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])) : _createCommentVNode("v-if", true), _ctx.evolutionType === 'percentage' ? (_openBlock(), _createBlock(_component_percentage_value, {
              key: 1,
              "show-sign": "",
              value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[_ctx.evolutionType]}`],
              color: color,
              charCount: $options.maxCharCount.percentage?.[`${_ctx.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[_ctx.evolutionType]}`]
            }, null, 8 /* PROPS */, ["value", "color", "charCount"])) : _createCommentVNode("v-if", true)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.revenue_excl_VAT,
            limit: 10_000,
            loading: $props.loading,
            charCount: $options.maxCharCount?.currency?.revenue_excl_VAT
          }, null, 8 /* PROPS */, ["value", "loading", "charCount"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value1", "onClick", "value2", "max1", "max2", "loading", "rateChange", "evolutionType"])]),
        "cell(days_inventory)": _withCtx(row => [_createVNode(_component_bar_with_value, {
          value: row.item?.days_inventory,
          min: $options.minMax?.days_inventory?.min,
          color: "#CC9F6C",
          max: $options.minMax?.days_inventory?.max,
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            value: row.item?.days_inventory,
            styles: {
              padding: 0
            }
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        "cell(share_asin_sold)": _withCtx(row => [_createVNode(_component_bar_with_value, {
          value: row.item?.share_asin_sold,
          min: $options.minMax?.share_asin_sold?.min,
          color: "#93999F",
          max: $options.minMax?.share_asin_sold?.max,
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.share_asin_sold,
            charCount: $options.maxCharCount?.percentage?.share_asin_sold
          }, null, 8 /* PROPS */, ["value", "charCount"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        "cell(organic_CVR)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getColorsBasedOnRange(row.item?.organic_CVR, $data.ORGANIC_CVR_HEATMAP_COLORS),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.organic_CVR,
            precision: 1
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(traffic_by_asin_buy_box_percentage)": _withCtx(row => [_createVNode(_component_dotted_value, {
          align: 'center',
          color: $options.getColorsBasedOnRange(row.item?.traffic_by_asin_buy_box_percentage, $data.BUYBOX_HEATMAP_COLORS),
          value: row.item?.traffic_by_asin_buy_box_percentage,
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.traffic_by_asin_buy_box_percentage
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["color", "value", "loading"])]),
        "cell(share_sponsored_sales)": _withCtx(row => [_createVNode(_component_bar_with_value, {
          value: row.item?.share_sponsored_sales,
          min: $options.minMax?.share_sponsored_sales?.min,
          color: "#4C8EFF",
          max: $options.minMax?.share_sponsored_sales?.max,
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.share_sponsored_sales,
            charCount: $options.maxCharCount.percentage?.share_sponsored_sales
          }, null, 8 /* PROPS */, ["value", "charCount"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        "cell(TACOS)": _withCtx(row => [_createVNode(_component_overlap_bar_value, {
          value: row.item?.TACOS,
          background: "#EBD4F7",
          borderColor: "#CD93EB",
          min: $options.minMax?.TACOS?.min,
          max: $options.minMax?.TACOS?.max,
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.TACOS
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "loading"])]),
        "cell(total_quantity)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
            gap: 16
          }, {
            evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
              value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}total_quantity_evol`]
            }, {
              default: _withCtx(({
                color
              }) => [_createVNode(_component_percentage_value, {
                value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}total_quantity_evol`],
                color: color,
                "show-sign": "",
                charCount: $options.maxCharCount.percentage?.[`${_ctx.evolCategory[_ctx.periodCategory]}total_quantity_evol`]
              }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
            default: _withCtx(() => [_createVNode(_component_number_value, {
              value: row.item?.total_quantity,
              loading: $props.loading,
              tight: ""
            }, null, 8 /* PROPS */, ["value", "loading"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(lost_revenue_excl_VAT)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading,
          align: "right"
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.lost_revenue_excl_VAT,
            color: $data.COLORS.RED700
          }, null, 8 /* PROPS */, ["value", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(avg_unit_price)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
            gap: 16
          }, {
            evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
              value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`]
            }, {
              default: _withCtx(({
                color
              }) => [_createVNode(_component_percentage_value, {
                "show-sign": "",
                value: row.item?.[`${_ctx.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`],
                color: color,
                charCount: $options.maxCharCount.percentage?.[`${_ctx.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`]
              }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
            default: _withCtx(() => [_createVNode(_component_currency_value, {
              value: row.item?.avg_unit_price,
              precision: 2,
              align: "right"
            }, null, 8 /* PROPS */, ["value"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["fields", "items", "tbodyTrClass", "onRowClicked"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "period"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDownload", "fields", "loading"]);
}