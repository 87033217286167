import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return $props.direction !== $data.TREND_DIRECTION_TYPE.OFF ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    style: _normalizeStyle($options.computedTrendDirection)
  }, [_createVNode(_component_app_icon, {
    icon: "trend",
    color: $props.color,
    size: $props.size
  }, null, 8 /* PROPS */, ["color", "size"])], 4 /* STYLE */)) : _createCommentVNode("v-if", true);
}