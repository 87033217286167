import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SearchElement = _resolveComponent("SearchElement");
  const _component_TargetingFilterItem = _resolveComponent("TargetingFilterItem");
  const _component_SelectedSection = _resolveComponent("SelectedSection");
  const _component_ListSection = _resolveComponent("ListSection");
  const _component_WrapWithDropdownBody = _resolveComponent("WrapWithDropdownBody");
  return _openBlock(), _createBlock(_component_WrapWithDropdownBody, {
    onHidden: _cache[5] || (_cache[5] = $event => _ctx.$emit('hidden'))
  }, {
    searcher: _withCtx(() => [_createVNode(_component_SearchElement, {
      placeholder: "Search keywords...",
      onSearch: _cache[0] || (_cache[0] = val => _ctx.$emit('search', val)),
      value: $data.searchValue
    }, null, 8 /* PROPS */, ["value"])]),
    selected: _withCtx(() => [_createVNode(_component_SelectedSection, {
      title: "Keywords",
      count: $props.selected.length,
      isModified: $props.isModified,
      heightFromTop: "118px",
      onClear: _cache[1] || (_cache[1] = $event => {
        _ctx.$emit('clear');
      }),
      onApply: _cache[2] || (_cache[2] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _cache[3] || (_cache[3] = $event => _ctx.$emit('closeDropdown'))
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selected, ({
        targeting,
        keyword_id_list,
        id
      }) => {
        return _openBlock(), _createBlock(_component_TargetingFilterItem, {
          key: id,
          targeting: targeting,
          id: id,
          selected: "",
          onDeselect: $event => _ctx.$emit('deselect', {
            keyword_id_list
          })
        }, null, 8 /* PROPS */, ["targeting", "id", "onDeselect"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count", "isModified"])]),
    list: _withCtx(() => [_createVNode(_component_ListSection, {
      title: "Keywords",
      count: $props.list.length,
      searching: $props.searching,
      onSelectAll: _cache[4] || (_cache[4] = $event => _ctx.$emit('selectAll')),
      heightFromTop: "118px"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, ({
        targeting,
        keyword_id_list,
        id
      }) => {
        return _openBlock(), _createBlock(_component_TargetingFilterItem, {
          key: id,
          targeting: targeting,
          id: id,
          onSelect: $event => _ctx.$emit('select', {
            keyword_id_list
          }),
          onSelectOnly: $event => _ctx.$emit('selectOnly', {
            keyword_id_list
          })
        }, null, 8 /* PROPS */, ["targeting", "id", "onSelect", "onSelectOnly"]);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["count", "searching"])]),
    _: 1 /* STABLE */
  });
}