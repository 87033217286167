import FilterItem from "./FilterItem.vue";
import MatchTypeFilterBody from "./bodies/MatchTypeFilterBody.vue";
import MatchTypeValue from "@/components/tableElements/MatchTypeValue.vue";
export default {
  name: "MatchTypeFilter",
  components: {
    FilterItem,
    MatchTypeFilterBody,
    MatchTypeValue
  },
  emits: ["apply"],
  expose: ["openDropdown"],
  props: {
    match_types: {
      type: Object,
      required: true,
      default: () => ({
        filterState: {
          selected: [],
          list: []
        },
        isModified: false,
        select: () => {},
        deselect: () => {},
        clear: () => {},
        apply: () => {},
        listSearch: () => {}
      })
    }
  },
  data() {
    return {
      icons: {
        DEFAULT: 'EqualsIcon',
        EXACT: 'TargetIcon',
        TARGETING_EXPRESSION: 'BracketsPlusIcon',
        TARGETING_EXPRESSION_PREDEFINED: 'BracketsCheckIcon',
        BROAD: 'ZoomOutIcon',
        PHRASE: 'MessageSquareIcon'
      }
    };
  },
  computed: {
    getMatchTypeTitle() {
      return this.getMatchType?.name ?? "Match type";
    },
    getMatchType() {
      return this.match_types.selected?.[0];
    },
    getMatchTypeIcon() {
      const topMatchType = this.getMatchType;
      if (!topMatchType) return this.icons.DEFAULT;
      //console.log('top', topMatchType);
      return this.icons[topMatchType.type];
    }
  },
  methods: {
    openDropdown() {
      this.$nextTick(() => {
        const innerFilterItem = this.$refs.matchTypeFilterItem;
        if (innerFilterItem) {
          innerFilterItem.openDropdown();
        }
      });
    }
  }
};