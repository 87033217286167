import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5d3184b4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1,
  class: "w-100"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_variable_styled_bar = _resolveComponent("variable-styled-bar");
  const _component_layer_bars = _resolveComponent("layer-bars");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    class: "compare-value",
    gap: 8,
    styles: {
      width: '100%'
    }
  }, {
    default: _withCtx(() => [$options.validValue ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_renderSlot(_ctx.$slots, "value1", {}, undefined, true), _createVNode(_component_layer_bars, null, {
      default: _withCtx(() => [_createVNode(_component_variable_styled_bar, {
        value: $props.value1,
        max: $props.max1,
        min: $props.min1,
        color: $props.color1
      }, null, 8 /* PROPS */, ["value", "max", "min", "color"]), _createVNode(_component_variable_styled_bar, {
        value: $props.value2,
        max: $props.max2,
        min: $props.min2,
        color: $props.color2
      }, null, 8 /* PROPS */, ["value", "max", "min", "color"])]),
      _: 1 /* STABLE */
    }), _renderSlot(_ctx.$slots, "value2", {}, undefined, true)], 64 /* STABLE_FRAGMENT */)) : (_openBlock(), _createElementBlock("span", _hoisted_1, "-"))]),
    _: 3 /* FORWARDED */
  });
}