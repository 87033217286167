import AppButtonRadioGroup from "@/components/AppButtonRadioGroup";
export default {
  name: 'DatePickerRange',
  emits: ['update:modelValue'],
  components: {
    AppButtonRadioGroup
  },
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    range: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Array
    },
    isCompare: {
      type: Boolean,
      default: false
    },
    hoverDate: {
      type: Object,
      default: () => {}
    },
    dateRange: {
      type: Object
    }
  },
  data() {
    return {
      startSelected: false,
      endSelected: false
    };
  },
  computed: {
    selected: {
      get() {
        //console.log("DatePickerRange", this.modelValue)
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    rangeOptions() {
      return [{
        text: this.formatted(this.range.start),
        value: "start"
      }, {
        text: this.formatted(this.range.end),
        value: "end"
      }];
    }
  },
  methods: {
    onSelectDate(type) {
      // if (this.startSelected && type === 'start')
      //   return
      // if (this.endSelected && type === 'end')
      //   return
      // if (this.dateRange[type]) {
      //   const newDate = moment(this.modelValue).isSame(this.dateRange[type], 'day') ? null : moment(this.dateRange[type]) //(type == 'start' ? moment(this.dateRange[type]).add(1, 'month') : moment(this.dateRange[type]))
      //   this.$emit('update:modelValue', newDate)

      //   // XOR relationship between startSelected and endSelected
      //   this.startSelected = type == 'start' ? !this.startSelected : false
      //   this.endSelected = type == 'end' ? !this.endSelected : false
      // } else {
      //   this[`${type}Selected`] = true
      //   // set the other to false
      //   this[`${type == 'start' ? 'end' : 'start'}Selected`] = false
      // }

      // this.$emit('deactivateOthers')
    }
  }
};