import TableGraph from '@/components/TableGraph.vue';
import DashboardMixin from '@/mixins/DashboardMixin';
import { computeMinMax, EVENTS } from '@/utils';
import { getGraphHeaderByTabView, getGraphTitles } from '@/components/shared/chartHelpers';
import { defineComputedPeriod, getGraphDefaultSortBy } from '@/components/shared/chartHelpers';
import { TABLE_HEADERS_CONFIG } from "@/components/shared/helpers";
import { mapState } from 'vuex';
import ErrorWrapper from '@/components/ErrorWrapper.vue';
export default {
  name: 'GlobalPerformancePage',
  mixins: [DashboardMixin],
  data() {
    return {
      positions: [],
      totalPositions: [],
      totalWidths: [],
      graphLoading: false,
      totalLoading: false,
      showTotal: false,
      minMax: {},
      graphDescription: [],
      sortBy: "",
      sortDirection: "desc",
      tab: "Global",
      view: "Performance",
      currentViewPeriodOptions: {},
      pageRenderedLoading: false,
      showError: false,
      graphId: 'global_performance'
    };
  },
  async mounted() {
    //console.log("Global Performance: ", this.$props)
    this.$store.dispatch('dashboardSetCurrentTab', 'Global');
    this.$store.dispatch('dashboardSetCurrentView', 'Performance');

    // Get graph Title
    this.graphDescription = this.headerDescription(this.tab, this.view);

    // Set the default sortBy
    this.sortBy = getGraphDefaultSortBy(this.tab, this.view);
    await this.updateComponent({
      period: this.period
    });
    window.$bus.on(EVENTS.GLOBAL_PERFORMANCE, () => {
      this.updateComponent({
        period: this.period
      }).catch(err => {
        //console.log("Failed to update component", err)
      });
    });

    //console.log("Global Performance: ", this.$store.state.dashboard)
  },
  computed: {
    ...mapState({
      graph: state => state.dashboard.pageReports['global_performance'].graph,
      period: state => state.period.globalPeriod
    }),
    graphTitle() {
      return `${getGraphTitles()[this.tab][this.view]} ${defineComputedPeriod(this.period.key)}` || "";
    },
    graphHeaders() {
      // Update Graph Headers
      let graphHeaders = getGraphHeaderByTabView(this.tab, this.view, this.period);

      // We update the name of the first item depending on the current period
      const {
        name
      } = this.getOptionsBasedOnPeriod({
        period: this.period.name
      });
      graphHeaders[0].name = name;
      return graphHeaders;
    },
    filters() {
      return {
        seller_id: this.$store.getters['dashboardGetSellers'](),
        sales_channel: this.$store.getters['dashboardGetSalesChannels'](),
        ...this.$store.getters['dashboardGetProducts']()
      };
    }
  },
  methods: {
    retry() {
      this.showError = false;
      this.updateComponent({
        period: this.period
      }).catch(err => {
        //console.log("Failed to update component", err)
      });
    },
    async innerFetch({
      period
    }) {
      return await this.fetchKpis({
        path: '/global/performance',
        filters: this.filters,
        period: period.key,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection
      });
    },
    async updateComponent({
      period
    }) {
      this.graphLoading = true;
      const {
        graph,
        error
      } = await this.setupComponent({
        period
      });
      if (error) {
        this.graphLoading = false;
        this.showError = true;
        return;
      }

      // Compute minMax
      this.minMax = computeMinMax(graph);
      this.setGraph({
        graph,
        tabView: 'global_performance'
      });

      // Update the name of the first item depending on the current period
      const {
        currentViewPeriodOptions
      } = this.getOptionsBasedOnPeriod({
        period: period.name
      });
      this.currentViewPeriodOptions = currentViewPeriodOptions;
      this.graphLoading = false;
    },
    async setupComponent({
      period
    }) {
      await this.getOmnyAccountSellers();
      return await this.innerFetch({
        period
      });
    },
    async sortTable(sortBy) {
      this.pageRenderedLoading = true;
      if (this.sortBy !== sortBy) this.sortDirection = 'desc';else {
        this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
      }
      this.sortBy = sortBy;
      const {
        graph
      } = await this.setupComponent({
        period: this.period
      });

      // Compute minMax
      this.minMax = computeMinMax(graph);
      this.setGraph({
        graph,
        tabView: 'global_performance'
      });
      this.pageRenderedLoading = false;
    },
    getOptionsBasedOnPeriod({
      period
    }) {
      const {
        name,
        currentViewPeriodOptions
      } = TABLE_HEADERS_CONFIG[period];
      return {
        name,
        currentViewPeriodOptions
      };
    }
  },
  components: {
    TableGraph,
    ErrorWrapper
  }
};