export const cogs_currencies = [{
  text: "EUR €",
  value: "EUR"
}, {
  text: "USD $",
  value: "USD"
}, {
  text: "GBP £",
  value: "GBP"
}, {
  text: "CAD $",
  value: "CAD"
}, {
  text: "AED د.إ.",
  value: "AED"
}, {
  text: "AUD $",
  value: "AUD"
}, {
  text: "BRL R$",
  value: "BRL"
}, {
  text: "EGP ج.م.",
  value: "EGP"
}, {
  text: "INR Rs",
  value: "INR"
}, {
  text: "JPY ¥",
  value: "JPY"
}, {
  text: "MXN $",
  value: "MXN"
}, {
  text: "PLN zł",
  value: "PLN"
}, {
  text: "SAR ر.س.",
  value: "SAR"
}, {
  text: "SEK kr",
  value: "SEK"
}, {
  text: "SGD $",
  value: "SGD"
}, {
  text: "TRY TL",
  value: "TRY"
}];