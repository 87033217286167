import { sortData } from "@/utils";
import { markRaw } from "vue";
export default class Filter {
  list;
  selected;
  defaultList;
  modified = false;
  searching = false;
  keyword = "";
  constructor(list = [], selected = [], key = "id", save = false, name = "filter", asyncSearcher = null, autoSelect = true) {
    // Edge case, for default selection when the list has only one item
    if (list.length === 1 && selected.length === 0 && autoSelect) {
      selected = list;
      list = [];
    }
    this.list = sortData(list, "__id");
    this.selected = sortData(selected ?? [], "__id");
    this.defaultList = sortData(list, "__id");
    this.key = key;
    this.save = save;
    this.name = name;
    this.asyncSearcher = asyncSearcher;
  }

  /**
   *
   * @param {*} list
   * @param {*} selected
   * @param {*} key
   *
   * Remove selected from list based on the key passed. (deduplication)
   * @returns {any[]}
   */
  dedupSelected(list, selected, key) {
    // Remove selected from list based on the key passed.
    return list.filter(item => !selected.find(i => i[key] === item[key]));
  }

  /**
   *
   * @param {list: any[], selected: any[], key: string} param0
   *
   * Initialize the filter.
   */
  initialize(params = {}) {
    let {
      list = [],
      selected = [],
      save = false,
      name = "filter",
      asyncSearcher = null,
      autoSelect = false
    } = params;
    // Edge case, for default selection when the list has only one item
    if (list.length === 1 && selected.length === 0 && autoSelect) {
      selected = list;
      list = [];
    }
    this.list = sortData(list, "__id");
    this.selected = sortData(selected, "__id");
    this.defaultList = sortData(list, "__id");
    if (params.key) {
      this.key = params.key;
    }
    this.save = save;
    this.name = name;
    this.asyncSearcher = asyncSearcher;
    this.apply();
  }

  /**
   *
   * @param {any} item
   * @param {string} key
   *
   * Select an item from the list and move it to the selected list.
   *
   * @returns {void}
   */
  select(item, key) {
    if (item[key] === null || item[key] === undefined) return;

    // Helper function to check equality of array or non-array values
    const isMatch = (a, b) => {
      if (Array.isArray(a) && Array.isArray(b)) {
        return a.length === b.length && a.every((val, index) => val === b[index]);
      }
      return String(a).toLowerCase() === String(b).toLowerCase();
    };

    // Find the item in the list based on the key
    const foundItem = this.list.find(i => isMatch(i[key], item[key]));
    if (!foundItem) return;

    // Remove the found item from the list and add to selected if not already present
    this.list = sortData(this.list.filter(i => !isMatch(i[key], item[key])), "__id");
    if (!this.selected.some(i => isMatch(i[key], item[key]))) {
      this.selected = sortData([...this.selected, foundItem], "__id");
    }
    this.modified = true;
    this.saveFilter();
  }
  selectMultiple(items, key) {
    for (const item of items) {
      this.select(item, key);
    }
  }

  /**
   * Select all items from the list and move it to the selected list.
   */
  selectAll() {
    this.selected = sortData([...this.selected, ...this.list], "__id");
    this.list = [];
    this.modified = true;
    this.saveFilter();
  }

  /**
   *
   * @param {any} item
   * @param {string} key
   */
  selectOnly(item, key) {
    // This is like doing a `clear` and after that `select`
    this.clear();
    this.select(item, key);
  }

  /**
   *
   * @param {any} item
   * @param {string} key
   */
  selectWhenNoSelected(item, key) {
    // This is like doing a `clear` and after that `select` if there is no selected
    if (this.selected.length == 0) {
      this.selectOnly(item, key);
    }
  }

  /**
   *
   * @param {any} item
   * @param {string} key
   *
   * Deselect an item from the selected list and move it to the list.
   */
  deselect(item, key) {
    // Find the item in the selected list based on the key passed.
    const foundItem = this.selected.find(i => i[key].toLowerCase() === item[key].toLowerCase());

    // Remove item from selected list based on the key
    this.selected = sortData(this.selected.filter(i => i[key].toLowerCase() !== item[key].toLowerCase()), "__id");

    // Add item to list
    this.list = sortData([...this.list, foundItem], "__id");
    this.modified = true;
    this.saveFilter();
  }

  /**
   * Clears the selected list and moves all elements from selected to list.
   */
  clear() {
    // Move all elements from selected to list
    this.list = sortData([...this.list, ...this.selected], "__id");

    // Reset selected
    this.selected = [];

    // Mark filter as modified
    this.modified = true;
    this.saveFilter();
  }

  /**
  * Performs and `empty` search to load `original` list from backend and
  * clears the selected list and moves all elements from selected to list.
  */
  async clearAfterAsyncSearch({
    callback,
    args
  }) {
    this.clear();
    this.asyncSearch({
      callback,
      keyword: [],
      args
    });
    this.apply();
  }

  /**
   * @param {any[]} data
   * Clears the selected list and moves all elements from selected to list.
   */
  set list(data) {
    // Move all elements from selected to list
    this.list = sortData(data, "__id");
  }

  /**
   *
   * @param {string} keyword
   * @param {string | string[] | undefined} key
   */
  listSearch(keyword, key) {
    // keyword can be a string or an array of strings
    this.searching = true;
    if (!Array.isArray(keyword)) {
      keyword = [keyword];
    }

    // Map all elements in keyword list into lowercase
    const keywords = keyword.map(item => item.toLowerCase()).map(item => {
      // Regex split based on [space, comma, semicolon]
      return item.split(/[\s,;]+/);
    }).flat();
    // Further break down the keyword into individual words

    // Filter list based on keyword
    key = key ?? this.key;
    if (key && !Array.isArray(key)) {
      key = [key];
    }
    if (keywords.length === 0) {
      this.searching = false;
      this.list = sortData(this.dedupSelected(this.defaultList, this.selected, key[0] ?? this.key), "__id");
      return;
    }
    const lowerCaseKeywords = keywords.map(k => k.toLowerCase());
    const results = this.defaultList.filter(item => {
      return key.some(k => {
        const searchSpace = item[k];
        if (searchSpace) {
          // If searchSpace is an array, convert all elements to lowercase and check for any match
          if (Array.isArray(searchSpace)) {
            return searchSpace.some(val => lowerCaseKeywords.some(keyword => val.toString().toLowerCase().includes(keyword)));
          }
          // If it's a string, convert to lowercase and check for a match
          return lowerCaseKeywords.some(keyword => searchSpace.toString().toLowerCase().includes(keyword));
        }
        return false;
      });
    });

    // Remove selected from list based on the key passed.
    this.list = Array.from(new Set(sortData(this.dedupSelected(results, this.selected, key[0] ?? this.key), "__id")));
  }
  /**
   * Cleans up the modified flag.
   */
  apply() {
    this.modified = false;
    this.saveFilter();
  }

  /**
   *
   * @returns {boolean}
   */
  get isModified() {
    return this.modified;
  }
  /**
   *
   * @param {string} keyword
   * @param {Function} callback
   */
  async asyncSearch({
    callback,
    keyword,
    args
  }) {
    if (!callback) {
      callback = this.asyncSearcher;
    }
    if (!callback) {
      throw new Error("No async searcher defined");
    }
    this.searching = true;
    if (keyword?.length === 0) {
      this.searching = false;
      this.list = sortData(this.dedupSelected(this.defaultList, this.selected, this.key), "__id");
      return;
    }
    // Filter list based on keyword

    const results = await callback({
      keyword,
      ...args
    });
    this.list = sortData(this.dedupSelected(results, this.selected, this.key), "__id");
  }

  /**
   * @returns {Object{list: any[], selected: any[]}}
   *  */
  get filterState() {
    return {
      list: this.list,
      selected: this.selected,
      selectedCount: this.selected.length
    };
  }

  /**
   * @returns {any[]}
   *
   */
  get filterValues() {
    // Return list when selected is empty otherwise return selected
    const res = markRaw(this.selected.length > 0 ? this.selected : this.list);
    return res.map(item => item[this.key]);
  }
  get filterItems() {
    // Return list when selected is empty otherwise return selected without dereferencing
    const res = markRaw(this.selected.length > 0 ? this.selected : this.list);
    return res;
  }
  get selectedItems() {
    // Return  the selected items in a list as is
    const res = markRaw(this.selected);
    return res;
  }
  get selectedValues() {
    return this.selected.map(item => item[this.key]);
  }

  /**
   * Returns a JSON string that can be saveable.
   * @returns {string}
   */
  serialize() {
    return JSON.stringify(this);
  }

  /**
   * Used to restore a filter from a JSON string.
   * @param {*} serialized
   * @returns
   */
  static deserialize(serialized) {
    let filter = new Filter([], []);
    serialized = JSON.parse(serialized);
    filter.list = sortData(serialized.list, "__id");
    filter.selected = sortData(serialized.selected, "__id");
    filter.key = serialized.key;
    filter.defaultList = sortData(serialized.defaultList, "__id");
    filter.modified = true;
    filter.searching = serialized.searching;
    filter.keyword = serialized.keyword;
    filter.save = serialized.save;
    filter.name = serialized.name;
    filter.asyncSearcher = serialized.asyncSearcher;
    return filter;
  }

  /**
   * Saves the filter to local storage.
   */
  saveFilter() {
    localStorage.setItem(this.name, this.serialize());
  }

  /**
   *
   * @param {callback, keyword, args} param0
   * @param {Object} item
   * @param {string} id
   * @returns boolean
   */
  async asyncSearchAndSelect({
    callback,
    keyword,
    args
  }) {
    await this.asyncSearch({
      callback,
      keyword,
      args
    });
    this.searching = false;
    if (this.list.length === 0) {
      return false;
    }
    this.selectAll(); // use select All to capture all elements returned by the async search
    return true;
  }

  /**
   * Clone the current filter.
   * @returns {Filter}
   */
  clone() {
    return Filter.deserialize(this.serialize());
  }

  /**
   * This is a deep comparison between two filters.
   * @param {Filter} filter
   */
  isEqual(filter) {
    if (!(filter instanceof Filter)) {
      return false;
    }

    // Compare each property individually [list, selected] te important ones
    return JSON.stringify(this.list) === JSON.stringify(filter.list) && JSON.stringify(this.selected) === JSON.stringify(filter.selected);
  }

  /**
   * Checks whether the `selected list` is empty or not.
   * Returns false if not empty and true when empty.
   * @returns {boolean}
   */
  get isEmpty() {
    return this.selected.length === 0;
  }
}