import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
  ref: "floatingTable",
  class: "floating-table"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_date_picker_popup = _resolveComponent("date-picker-popup");
  const _component_filter_divider = _resolveComponent("filter-divider");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_table_skeleton_v2 = _resolveComponent("table-skeleton-v2");
  const _component_b_collapse = _resolveComponent("b-collapse");
  const _component_app_pagination = _resolveComponent("app-pagination");
  const _component_compound_table = _resolveComponent("compound-table");
  return _openBlock(), _createBlock(_component_compound_table, {
    "has-toolbar": "",
    class: _normalizeClass([{
      ['intermediate']: !$data.fullSize && $data.visible
    }]),
    innerClass: "floating-compound-table",
    expanded: $data.visible,
    topBgColor: "#E0ECFF",
    fullSize: $data.fullSize,
    onMinimize: _cache[5] || (_cache[5] = $event => $options.handleMinimize($event)),
    onMaximize: _cache[6] || (_cache[6] = $event => $options.handleMaximize($event)),
    onNormalize: _cache[7] || (_cache[7] = $event => $options.handleNormalize($event)),
    onTopbarClick: $options.handleNormalizeOrNothing
  }, _createSlots({
    header: _withCtx(() => [_renderSlot(_ctx.$slots, "header")]),
    "header-sub": _withCtx(() => [_renderSlot(_ctx.$slots, "header-sub")]),
    "group-by-title": _withCtx(() => [_createTextVNode(_toDisplayString(null))]),
    "group-by": _withCtx(() => [_createVNode(_component_date_picker_popup, {
      datePickerDate: $props.datePickerDate,
      onSubmit: $options.handleDatePickerSubmit
    }, null, 8 /* PROPS */, ["datePickerDate", "onSubmit"])]),
    actions: _withCtx(() => [_createVNode(_component_filter_divider, {
      color: "#D0E2FF"
    }), _createVNode(_component_flex_row, {
      gap: 12
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "prepend-actions")]),
      _: 3 /* FORWARDED */
    })]),
    table: _withCtx(() => [_createVNode(_component_b_collapse, {
      modelValue: $data.visible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.visible = $event),
      ref: "floatingCompoundTable"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [$props.loading ? (_openBlock(), _createBlock(_component_table_skeleton_v2, {
        key: 0,
        fields: $props.fields,
        rows: 30,
        "show-footer": ""
      }, null, 8 /* PROPS */, ["fields"])) : _renderSlot(_ctx.$slots, "table", {
        key: 1,
        visible: $data.visible,
        showShadow: $data.showShadow,
        tableHeight: $data.tableHeight,
        fullSize: $data.fullSize,
        bottomRowHeight: $data.bottomRowHeight
      })], 512 /* NEED_PATCH */)]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue"])]),
    _: 2 /* DYNAMIC */
  }, [$data.fullSize ? {
    name: "pagination",
    fn: _withCtx(() => [_createVNode(_component_app_pagination, {
      page: $options._page,
      "onUpdate:page": [_cache[1] || (_cache[1] = $event => $options._page = $event), _cache[3] || (_cache[3] = $event => _ctx.$emit('refresh'))],
      perPage: $options._perPage,
      "onUpdate:perPage": [_cache[2] || (_cache[2] = $event => $options._perPage = $event), _cache[4] || (_cache[4] = $event => {
        this._totalPages = 0;
        _ctx.$emit('refresh');
      })],
      total: $options._totalPages
    }, null, 8 /* PROPS */, ["page", "perPage", "total"])]),
    key: "0"
  } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "expanded", "fullSize", "onTopbarClick"]);
}