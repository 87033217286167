import { mapState, mapGetters, mapActions } from "vuex";
import AlertsContent from "@/components/AlertsContent";
import AlertsSidebar from "@/components/AlertsSidebar";
import AlertsDetails from "@/components/AlertsDetails";
export default {
  name: "AlertsPage",
  components: {
    AlertsContent,
    AlertsSidebar,
    AlertsDetails
  },
  data() {
    return {
      dataLoading: true,
      selectedId: null,
      selectedRowId: null,
      selectedLevel: null,
      selectedType: null,
      sidebarOpen: false,
      detailsOpen: false,
      expandData: {
        rowId: null,
        tab: null
      }
    };
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      me: state => state.auth.me,
      alerts: state => state.alerts.list,
      current_workspace: state => state.workspace.current_workspace
    }),
    ...mapGetters(["workspaceCurrent"]),
    isLoggedIn() {
      return !!this.token;
    }
  },
  async mounted() {
    var is_authenticated = await this.authStatus();
    if (is_authenticated) {
      await this.workspaceList();
      sessionStorage.setItem("useFaker", true);
      await this.alertsList({
        workspace_id: this.current_workspace.workspace_id
      });
      await this.statusesList();
      await this.assigneesList();
      sessionStorage.setItem("useFaker", false);
      this.dataLoading = false;
    }
  },
  methods: {
    ...mapActions(["authStatus", "alertsList", "assigneesList", "statusesList", "workspaceList"]),
    onToggleSelect(data) {
      this.selectedRowId = this.selectedRowId === data.id && this.selectedLevel === data.level ? null : data.id;
      this.selectedLevel = data.level;
      this.selectedType = data.type;
      this.sidebarOpen = this.selectedRowId !== null;
    },
    onToggleId(id) {
      this.selectedId = this.selectedId === id ? null : id;
      this.detailsOpen = this.selectedId !== null;
    },
    onSidebarClose() {
      if (!this.detailsOpen) {
        this.selectedRowId = null;
      }
      this.sidebarOpen = false;
    },
    onSelectId(data) {
      this.selectedId = this.selectedId === data.id ? null : data.id;
      this.selectedRowId = data.rowId;
    },
    onDetailsClose() {
      this.selectedId = null;
      this.detailsOpen = false;
    },
    onExpand(data) {
      this.expandData = data;
    }
  }
};