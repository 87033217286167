import moment from 'moment';
import AppIcon from '@/components/AppIcon';
import AlertsStatus from '@/components/AlertsStatus';
import AlertsAssignee from '@/components/AlertsAssignee';
import AlertsPriority from '@/components/AlertsPriority';
export default {
  name: 'AlertsDetails',
  emits: ['update:modelValue', 'select', 'close'],
  components: {
    AppIcon,
    AlertsStatus,
    AlertsAssignee,
    AlertsPriority
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    selectedId: {
      type: [String, Number],
      default: null
    },
    selectedType: {
      type: String,
      default: null
    }
  },
  computed: {
    name() {
      const item = this.items.find(item => {
        const idField = this.selectedType == 'sellers' ? 'seller_id' : 'alerting_type';
        return item[idField] == this.current?.[idField];
      });
      return item ? this.selectedType == 'sellers' ? item.seller_name : item.alerting_type : 'Unknown';
    },
    typeNumber() {
      return this.items.filter(item => {
        const idField = this.selectedType == 'sellers' ? 'seller_id' : 'alerting_type';
        if (item[idField] == this.current?.[idField]) {
          return true;
        }
        return false;
      }).length;
    },
    contentItems() {
      const items = this.items.filter(item => {
        if (item.alerting_category == this.current?.alerting_category) {
          return true;
        }
        return false;
      });
      const byType = items.reduce((acc, value) => {
        const idField = this.selectedType == 'sellers' ? 'seller_id' : 'alerting_type';
        let el = acc.find(item => item.id === value[idField]);
        if (!el) {
          el = {
            id: value[idField],
            items: []
          };
        }
        el.items.push(value);
        return [...acc.filter(item => item.id !== el.id), el];
      }, []);
      byType.sort((a, b) => b.items.length - a.items.length);
      const groups = [];
      byType.forEach(item => {
        groups.push(item.items.reduce((acc, value) => {
          const idField = this.selectedType == 'categories' ? 'seller_id' : 'alerting_type';
          let el = acc.find(item => item.id === value[idField]);
          if (!el) {
            el = {
              id: value[idField],
              items: []
            };
          }
          el.items.push(value);
          return [...acc.filter(item => item.id !== el.id), el];
        }, []));
      });
      const result = [];
      groups.forEach(group => {
        group.forEach(byType => {
          byType.items.forEach(item => {
            result.push(item);
          });
        });
      });
      return result;
    },
    current() {
      return this.items.find(item => {
        if (item.id == this.selectedId) {
          return true;
        }
        return false;
      });
    },
    prev() {
      let isCurrent = false;
      const prev = {
        id: null,
        rowId: null
      };
      const idField = this.selectedType == 'sellers' ? 'seller_id' : 'alerting_type';
      this.contentItems.forEach(item => {
        if (item.id == this.selectedId) {
          isCurrent = true;
        } else {
          if (!isCurrent) {
            prev.id = item.id;
            prev.rowId = item[idField];
          }
        }
      });
      return prev;
    },
    next() {
      let isCurrent = false;
      const next = {
        id: null,
        rowId: null
      };
      const idField = this.selectedType == 'sellers' ? 'seller_id' : 'alerting_type';
      this.contentItems.forEach(item => {
        if (!next?.id && isCurrent) {
          next.id = item.id;
          next.rowId = item[idField];
        }
        if (item.id == this.selectedId) {
          isCurrent = true;
        }
      });
      return next;
    },
    createdAt() {
      return moment(this.current?.created_at).format('D MMM YYYY');
    }
  },
  methods: {
    onPrev() {
      if (this.prev?.id) {
        this.$emit('select', this.prev);
      }
    },
    onNext() {
      if (this.next?.id) {
        this.$emit('select', this.next);
      }
    },
    onClose() {
      this.$emit('close');
    }
  }
};