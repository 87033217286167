import AccountsMixin from "./AccountsMixin.vue";
import { EVENTS } from "@/utils";
import InitializeFiltersMixin from "@/mixins/InitializeFiltersMixin.vue";
export default {
  name: "DashboardV2Mixin",
  mixins: [AccountsMixin, InitializeFiltersMixin],
  mounted() {
    window.$bus.on(EVENTS.REFRESH_DASHBOARD_V2_SELLERS_SUB_FILTERS, async () => {
      const sellers = this.$store.state.dashboard_v2.sellers.filterValues;
      const sales_channel = await this.initializeMarketplacesFilters({
        sellers
      }, "dashboard_v2", "marketplaces", "dashboard/v2");
      this.fetchSubFilters({
        sellers,
        sales_channel
      }, "dashboard_v2", {
        product: "products"
      }, "dashboard/v2");
    });
    window.$bus.on(EVENTS.REFRESH_DASHBOARD_V2_MARKETPLACES_SUB_FILTERS, () => {
      const sellers = this.$store.state.dashboard_v2.sellers.filterValues;
      const sales_channel = this.$store.state.dashboard_v2.marketplaces.filterValues;
      this.fetchSubFilters({
        sellers,
        sales_channel
      }, "dashboard_v2", {
        product: "products"
      }, "dashboard/v2");
    });
  }
};