export default {
  name: 'AppTd',
  props: {
    align: {
      type: String,
      default: 'left'
    },
    valign: {
      type: String,
      default: 'middle'
    },
    stickyColumn: {
      type: Boolean,
      default: false
    }
  }
};