import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_match_type_value = _resolveComponent("match-type-value");
  const _component_MatchTypeFilterBody = _resolveComponent("MatchTypeFilterBody");
  const _component_FilterItem = _resolveComponent("FilterItem");
  return _openBlock(), _createBlock(_component_FilterItem, {
    id: "match-types",
    ref: "matchTypeFilterItem",
    selectedCount: $props.match_types.filterState.selectedCount,
    onClearAll: _cache[7] || (_cache[7] = $event => $props.match_types.clear())
  }, {
    title: _withCtx(() => [_createVNode(_component_match_type_value, {
      name: $options.getMatchTypeTitle,
      type: $options.getMatchType?.type
    }, null, 8 /* PROPS */, ["name", "type"])]),
    body: _withCtx(() => [_createVNode(_component_MatchTypeFilterBody, {
      title: "Match type",
      selected: $props.match_types.filterState.selected,
      isModified: $props.match_types.isModified,
      searching: $props.match_types.searching,
      list: $props.match_types.filterState.list,
      onClear: _cache[0] || (_cache[0] = $event => $props.match_types.clear()),
      onSelect: _cache[1] || (_cache[1] = ({
        id
      }) => $props.match_types.select({
        id
      }, 'id')),
      onSelectOnly: _cache[2] || (_cache[2] = ({
        id
      }) => $props.match_types.selectOnly({
        id
      }, 'id')),
      onDeselect: _cache[3] || (_cache[3] = ({
        id
      }) => {
        $props.match_types.deselect({
          id
        }, 'id');
      }),
      onApply: _cache[4] || (_cache[4] = $event => _ctx.$emit('apply')),
      onSearch: _cache[5] || (_cache[5] = ({
        value
      }) => $props.match_types.listSearch(value, ['name'])),
      onHidden: _cache[6] || (_cache[6] = $event => _ctx.$emit('apply')),
      onCloseDropdown: _ctx.$refs?.matchTypeFilterItem?.closeDropdown
    }, null, 8 /* PROPS */, ["selected", "isModified", "searching", "list", "onCloseDropdown"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["selectedCount"]);
}