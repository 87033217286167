import KpiCard from "@/components/KpiCard.vue";
import PercentageValue from "@/components/tableElements/PercentageValue.vue";
import CurrencyValue from "@/components/tableElements/CurrencyValue.vue";
import NumberValue from "@/components/tableElements/NumberValue.vue";
import { getDescriptionBasedOnKpiKey } from "@/utils/transformers";
import FlexColumn from "@/components/FlexColumn.vue";
import RevenueExclVATSubtitleCard from "@/components/kpi-cards/dashboard_v2/RevenueExclVATSubtitleCard.vue";
import { mapState } from "vuex";
import { APP_PERIOD_DROPDOWN_ELEMENTS } from "@/utils/constants";
const {
  title,
  detail
} = getDescriptionBasedOnKpiKey("revenue_excl_VAT");
export default {
  name: "RevenueExclVatCard",
  props: {
    name: {
      type: String
    },
    amount: {
      type: Number
    },
    subtitleAmount: {
      type: Number
    },
    percentage: {
      type: Number
    },
    percentageColor: {
      type: String
    },
    percentageBgColor: {
      type: String
    },
    subtitlePercentage: {
      type: [Number, null]
    },
    loading: {
      type: Boolean
    },
    splitSubtitle: {
      type: Array
    }
  },
  components: {
    KpiCard,
    PercentageValue,
    CurrencyValue,
    NumberValue,
    FlexColumn,
    RevenueExclVATSubtitleCard
  },
  data() {
    return {
      title,
      detail,
      APP_PERIOD_DROPDOWN_ELEMENTS
    };
  },
  computed: {
    ...mapState({
      period: state => state.dashboard_v2.period
    })
  }
};