import { mapGetters } from "vuex";
import { computeTableWidths } from "@/utils";
import TableGraph from "@/components/TableGraph";
export default {
  name: "PdfRenderer",
  data() {
    return {
      totalWidths: []
    };
  },
  watch: {
    getPlotData: {
      handler(oldValue, newValue) {
        //console.log("[PDFRenderer] Plot Data: ", oldValue, newValue);
        if (oldValue.length === newValue.length) {
          return;
        }

        // Use which ever is the latest
        const latest = newValue.length > oldValue.length ? newValue : oldValue;
        //console.log("[PDFRenderer] Latest: ", latest)
        let data = [];
        this.$nextTick(() => {
          latest.forEach(({
            graphId
          }) => {
            //console.log("[PDFRenderer] Graph ID: ", graphId)
            data.push(computeTableWidths(graphId));
          });
          this.totalWidths = data;
          //console.log("[PDFRenderer] Total Widths: ", this.totalWidths)
        });
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["dashboardPdfPlotDataGet", "pdfRendererDimensionGet"]),
    getPlotData() {
      // console.log(
      //     "[PDFRenderer] Plot Data: ",
      //     this.dashboardPdfPlotDataGet()
      // )
      return this.dashboardPdfPlotDataGet();
    },
    getPageDimension() {
      // Return the dimensions for a statandard A4 landscape
      return {
        width: "11.69in",
        height: "8.27in"
      };
    },
    innerTotalWidths() {
      return this.totalWidths;
    }
  },
  methods: {
    getTotalWidths(index) {
      if (this.innerTotalWidths.length === 0) {
        return [];
      }
      return this.innerTotalWidths[index];
    }
  },
  components: {
    TableGraph
  }
};