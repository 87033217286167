import KpiCard from "@/components/KpiCard.vue";
import PercentageValue from "@/components/tableElements/PercentageValue.vue";
import CurrencyValue from "@/components/tableElements/CurrencyValue.vue";
import NumberValue from "@/components/tableElements/NumberValue.vue";
import { COLORS } from "@/utils/constants";
import { getDescriptionBasedOnKpiKey } from "@/utils/transformers";
const {
  title,
  detail
} = getDescriptionBasedOnKpiKey("CVR");
export default {
  name: "ListingOrganicCvrCard",
  props: {
    amount: {
      type: Number
    },
    percentage: {
      type: Number
    },
    loading: {
      type: Boolean
    }
  },
  components: {
    KpiCard,
    PercentageValue,
    CurrencyValue,
    NumberValue
  },
  data() {
    return {
      COLORS,
      title,
      detail
    };
  }
};