import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a8991eb6"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_WrapWithPopover = _resolveComponent("WrapWithPopover");
  return _openBlock(), _createBlock(_component_WrapWithPopover, {
    target: $props.icon
  }, {
    content: _withCtx(() => [_createTextVNode(_toDisplayString($props.info), 1 /* TEXT */)]),
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(["right-strip-icon", [{
        disabled: $props.disabled
      }]]),
      id: $props.icon
    }, [_createVNode(_component_AppIcon, {
      icon: $props.icon,
      size: $props.size,
      color: $props.color
    }, null, 8 /* PROPS */, ["icon", "size", "color"])], 10 /* CLASS, PROPS */, _hoisted_1)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["target"]);
}