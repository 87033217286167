import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductFilterBody = _resolveComponent("ProductFilterBody");
  const _component_FilterItem = _resolveComponent("FilterItem");
  return _openBlock(), _createBlock(_component_FilterItem, {
    ref: "productFilterItem",
    class: "product-filter",
    id: "products",
    icon: "ProductsIcon",
    title: $options.getProductsTitle,
    selectedCount: $props.products.filterState.selectedCount,
    onClearAll: $options.onClear
  }, {
    body: _withCtx(() => [_createVNode(_component_ProductFilterBody, {
      title: "Products",
      selected: $props.products.filterState.selected,
      isModified: $props.products.isModified,
      searching: $props.products.searching,
      list: $props.products.filterState.list,
      onClear: $options.onClear,
      onSelect: $options.onSelect,
      onSelectOnly: $options.onSelectOnly,
      onSelectAll: $options.onSelectAll,
      onDeselect: $options.onDeSelect,
      onApply: $options.onApply,
      onSearch: $options.handleProductsSearch,
      onHidden: $options.handleHidden,
      onCloseDropdown: $options.onClose
    }, null, 8 /* PROPS */, ["selected", "isModified", "searching", "list", "onClear", "onSelect", "onSelectOnly", "onSelectAll", "onDeselect", "onApply", "onSearch", "onHidden", "onCloseDropdown"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "selectedCount", "onClearAll"]);
}