import AppIcon from '@/components/AppIcon';
export default {
  name: 'ExpandTextBox',
  components: {
    AppIcon
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    lines: {
      type: Number,
      default: 3
    },
    maxHeight: {
      type: [String, Number],
      default: 'none'
    }
  },
  data() {
    return {
      expand: false
    };
  },
  computed: {
    units() {
      return !isNaN(this.maxHeight) ? 'px' : '';
    }
  },
  methods: {
    onToggle() {
      this.expand = !this.expand;
    }
  }
};