import GroupBy from "@/components/GroupBy.vue";
import { EVENTS } from "@/utils/constants";
import { mapState } from "vuex";
export default {
  name: "ProductTableGroupBy",
  components: {
    GroupBy
  },
  expose: ["selectSellerGroupBy", "selectMarketplaceGroupBy", "selectProductGroupBy", "selectProductGroupGroupBy", "selectCampaignGroupBy", "selectTargetingGroupBy", "getFirstGroupBy"],
  emits: ["refresh", "clicked"],
  props: {
    tabBusy: {
      type: Boolean
    }
  },
  mounted() {
    window.$bus.on(EVENTS.ADD_SELLER_GROUPBY, this.resetTabsForSellers);
    window.$bus.on(EVENTS.ADD_MARKETPLACE_GROUPBY, this.resetTabsForMarketplace);
  },
  computed: {
    ...mapState({
      groupByElements: state => state.ppc.productTable.groupByElements
    }),
    groupByIndex: {
      get() {
        return this.$store.getters.ppcGetProductTableGroupBy();
      },
      set(val) {
        this.$store.dispatch("ppcSetProductTableGroupBy", val);
        this.$emit("refresh");
      }
    }
  },
  methods: {
    resetTabsForSellers() {
      let activeGroupByKey = '';
      if (this.$refs.groupBy && this.$refs.groupBy.activeGroupBy) {
        activeGroupByKey = this.$refs.groupBy.activeGroupBy.key;
        this.groupByElements = [{
          name: "Seller",
          key: "seller",
          active: activeGroupByKey === 'seller',
          index: 0
        }, {
          name: "Marketplace",
          key: "marketplace",
          active: activeGroupByKey === 'marketplace',
          index: 1
        }, ...this.groupByElements.filter(el => !['seller', 'marketplace'].includes(el.key))];
      }
    },
    resetTabsForMarketplace() {
      let activeGroupByKey = '';
      if (this.$refs.groupBy && this.$refs.groupBy.activeGroupBy) {
        activeGroupByKey = this.$refs.groupBy.activeGroupBy.key;
        this.groupByElements = [{
          name: "Marketplace",
          key: "marketplace",
          active: activeGroupByKey === 'marketplace',
          index: 0
        }, ...this.groupByElements.filter(el => !['seller', 'marketplace'].includes(el.key))];
      }
    }
  }
};