import AppIcon from "../AppIcon.vue";
import FlexRow from "../FlexRow.vue";
import { HugValue } from ".";
export default {
  name: "WrapWithDetails",
  components: {
    HugValue,
    FlexRow,
    AppIcon
  },
  props: {
    align: {
      type: String,
      default: "center"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showFull: false
    };
  }
};