import { capitalize } from 'vue';
const transformDataForCheckbox = data => {
  let output = {};
  data.forEach(({
    name,
    ...rest
  }, index) => {
    output[index] = {
      item: name,
      value: name,
      ...rest
    };
  });
  return output;
};
const periodsSwitches = {
  DEFAULT: [{
    name: 'Weekly',
    key: 'Weekly',
    options: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  }, {
    name: 'Monthly',
    key: 'Monthly',
    options: {
      month: 'long',
      year: 'numeric'
    }
  }, {
    name: 'Daily',
    key: 'Daily',
    options: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  }],
  DAY_INTERVAL: [{
    key: '7D',
    name: 'Last 7 days',
    options: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  }, {
    key: '28D',
    name: 'Last 28 days',
    options: {
      month: 'long',
      year: 'numeric'
    }
  }, {
    key: '1D',
    name: 'Yesterday',
    options: {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  }],
  INVENTORY_COVERAGE: {
    key: '28D',
    name: 'Last 28 days',
    options: {
      month: 'long',
      year: 'numeric'
    }
  }
};
const getOptionsBasedOnPeriod = ({
  selectedPeriod,
  period
}) => {
  // Find selectedPeriod in period passed
  const selectedPeriodIndex = period.findIndex(({
    key
  }) => key === selectedPeriod);
  return period[selectedPeriodIndex].options;
};
const getGraphHeaders = periodColName => {
  const defaultHeaders = {
    Sellers: {
      Performance: {
        sortBy: 'revenue_excl_VAT',
        headers: [{
          name: 'Seller',
          key: 'seller_name',
          isString: true,
          alignLeft: true,
          truncateLength: 60
        }, {
          name: 'Revenue excl. VAT',
          key: 'revenue_excl_VAT',
          isCurrency: true,
          color: '#0072F0',
          digitSeparator: '.'
        }, {
          name: 'Δ',
          key: 'evolution_vs_previous',
          isEvolution: true,
          isCurrency: true,
          digitSeparator: '.'
        }, {
          name: 'Units Sold',
          key: 'total_quantity',
          noBar: true,
          color: '#F5EE9E'
        }, {
          name: 'Days of Inventory',
          key: 'days_inventory',
          color: '#F10096',
          isDecimal: true,
          precision: 0
        }, {
          name: '% of sku sold',
          key: 'share_sku_sold',
          isPercentage: true,
          color: '#F66D00'
        }, {
          name: 'Lost Revenue',
          key: 'lost_revenue_excl_VAT',
          isCurrency: true,
          color: '#FFA800',
          digitSeparator: '.'
        }, {
          name: 'Share PPC Sales',
          key: 'share_sponsored_sales',
          isPercentage: true,
          color: '#7CB342'
        }, {
          name: 'TACOS',
          key: 'TACOS',
          color: '#5E35B1',
          isPercentage: true
        }, {
          name: '% of BuyBox',
          key: 'traffic_by_asin_buy_box_percentage',
          isPercentage: true,
          color: '#03A9F4'
        }]
      },
      Advertising: {
        sortBy: 'seller_name',
        headers: [{
          name: 'Seller',
          key: 'seller_name',
          isString: true,
          alignLeft: true,
          truncateLength: 60
        }, {
          name: 'Sponsored Sales',
          key: 'sponsored_sales',
          isCurrency: true,
          color: '#312783'
        }, {
          name: 'Units Sold',
          key: 'units_sold',
          color: '#312783a6'
        }, {
          name: 'Clicks',
          key: 'clicks',
          color: '#f9b968'
        }, {
          name: 'CVR',
          key: 'CVR',
          color: '#f66d00',
          isPercentage: true
        }, {
          name: 'Spend',
          key: 'spend',
          isCurrency: true,
          color: '#ffa800'
        }, {
          name: 'ACOS',
          key: 'ACOS',
          color: '#7cb342',
          isPercentage: true
        }, {
          name: 'Share PPC Sales',
          key: 'share_sponsored_sales',
          color: '#5e35b1',
          isPercentage: true
        }, {
          name: 'TACOS',
          key: 'TACOS',
          isPercentage: true,
          color: '#03a9f4'
        }, {
          name: 'Cannibalization',
          key: 'cannibalization_cost',
          isCurrency: true,
          color: '#ec407a'
        }, {
          name: 'Sponsored Net Margin',
          key: 'sponsored_net_margin_after_cannibalization_cost',
          isCurrency: true,
          color: '#ff7043'
        }, {
          name: 'Sponsored ASIN',
          key: 'sponsored_asin',
          color: '#737373'
        }]
      }
    },
    Global: {
      Performance: {
        sortBy: 'period_agg',
        headers: [{
          name: periodColName,
          key: 'period_agg'
        }, {
          name: 'Revenue excl. VAT',
          key: 'revenue_excl_VAT',
          isCurrency: true,
          color: '#0072F0',
          digitSeparator: '.'
        }, {
          name: 'Units Sold',
          key: 'total_quantity',
          noBar: true,
          color: '#F5EE9E'
        }, {
          name: 'Days of Inventory',
          key: 'days_inventory',
          color: '#F10096',
          isDecimal: true,
          precision: 0
        }, {
          name: '% of sku sold',
          key: 'share_sku_sold',
          isPercentage: true,
          color: '#F66D00'
        }, {
          name: 'Lost Revenue',
          key: 'lost_revenue_excl_VAT',
          isCurrency: true,
          color: '#FFA800',
          digitSeparator: '.'
        }, {
          name: 'Share PPC Sales',
          key: 'share_sponsored_sales',
          isPercentage: true,
          color: '#7CB342'
        }, {
          name: 'TACOS',
          key: 'TACOS',
          color: '#5E35B1',
          isPercentage: true
        }, {
          name: '% of BuyBox',
          key: 'traffic_by_asin_buy_box_percentage',
          isPercentage: true,
          color: '#03A9F4'
        }]
      },
      Advertising: {
        sortBy: 'period_agg',
        headers: [{
          name: periodColName,
          key: 'period_agg'
        }, {
          name: 'Sponsored Sales',
          key: 'sponsored_sales',
          isCurrency: true,
          color: '#312783'
        }, {
          name: 'Units Sold',
          key: 'units_sold',
          color: '#312783a6'
        }, {
          name: 'Clicks',
          key: 'clicks',
          color: '#f9b968'
        }, {
          name: 'CVR',
          key: 'CVR',
          color: '#f66d00',
          isPercentage: true
        }, {
          name: 'Spend',
          key: 'spend',
          isCurrency: true,
          color: '#ffa800'
        }, {
          name: 'ACOS',
          key: 'ACOS',
          color: '#7cb342',
          isPercentage: true
        }, {
          name: 'Share PPC Sales',
          key: 'share_sponsored_sales',
          color: '#5e35b1',
          isPercentage: true
        }, {
          name: 'TACOS',
          key: 'TACOS',
          isPercentage: true,
          color: '#03a9f4'
        }, {
          name: 'Cannibalization',
          key: 'cannibalization_cost',
          isCurrency: true,
          color: '#ec407a'
        }, {
          name: 'Sponsored Net Margin',
          key: 'sponsored_net_margin_after_cannibalization_cost',
          isCurrency: true,
          color: '#ff7043'
        }, {
          name: 'Sponsored ASIN',
          key: 'sponsored_asin',
          color: '#737373'
        }]
      }
    },
    Country: {
      Performance: {
        sortBy: 'revenue_excl_VAT',
        headers: [{
          name: 'Country',
          key: 'sales_channel',
          isString: true,
          truncateLength: 45,
          alignLeft: true
        }, {
          name: 'Revenue excl. VAT',
          key: 'revenue_excl_VAT',
          isCurrency: true,
          color: '#0072F0',
          digitSeparator: '.'
        }, {
          name: 'Δ',
          key: 'evolution_vs_previous',
          isEvolution: true,
          isCurrency: true,
          notSortable: true,
          digitSeparator: '.'
        }, {
          name: 'Units Sold',
          key: 'total_quantity',
          color: '#6b9080'
        }, {
          name: '% of sku sold',
          key: 'share_sku_sold',
          isPercentage: true,
          color: '#F66D00'
        }, {
          name: 'Lost Revenue',
          key: 'lost_revenue_excl_VAT',
          isCurrency: true,
          color: '#FFA800',
          digitSeparator: '.'
        }, {
          name: 'Share PPC Sales',
          key: 'share_sponsored_sales',
          isPercentage: true,
          color: '#7CB342'
        }, {
          name: 'TACOS',
          key: 'TACOS',
          isPercentage: true,
          color: '#619b8a'
        }, {
          name: '% of BuyBox',
          key: 'traffic_by_asin_buy_box_percentage',
          isPercentage: true,
          color: '#03A9F4'
        }]
      },
      Advertising: {
        sortBy: 'sales_channel',
        headers: [{
          name: 'Country',
          key: 'sales_channel',
          isString: true
        }, {
          name: 'Sponsored Sales',
          key: 'sponsored_sales',
          isCurrency: true,
          color: '#312783'
        }, {
          name: 'Units Sold',
          key: 'units_sold',
          color: '#312783a6'
        }, {
          name: 'Clicks',
          key: 'clicks',
          color: '#f9b968'
        }, {
          name: 'CVR',
          key: 'CVR',
          color: '#f66d00',
          isPercentage: true
        }, {
          name: 'Spend',
          key: 'spend',
          isCurrency: true,
          color: '#ffa800'
        }, {
          name: 'ACOS',
          key: 'ACOS',
          color: '#7cb342',
          isPercentage: true
        }, {
          name: 'Share PPC Sales',
          key: 'share_sponsored_sales',
          color: '#5e35b1',
          isPercentage: true
        }, {
          name: 'TACOS',
          key: 'TACOS',
          isPercentage: true,
          color: '#03a9f4'
        }, {
          name: 'Cannibalization',
          key: 'cannibalization_cost',
          isCurrency: true,
          color: '#ec407a'
        }, {
          name: 'Sponsored Net Margin',
          key: 'sponsored_net_margin_after_cannibalization_cost',
          isCurrency: true,
          color: '#ff7043'
        }, {
          name: 'Sponsored ASIN',
          key: 'sponsored_asin',
          color: '#737373'
        }]
      }
    },
    Product: {
      Performance: {
        sortBy: 'revenue_excl_VAT',
        headers: [{
          name: 'Product &bull; Asin &bull; Sku',
          key: 'product_name'
        }, {
          name: 'Revenue excl. VAT',
          key: 'revenue_excl_VAT',
          isCurrency: true,
          color: '#0072F0',
          digitSeparator: '.'
        }, {
          name: 'Δ',
          key: 'evolution_vs_previous',
          isEvolution: true,
          isCurrency: true,
          digitSeparator: '.'
        }, {
          name: '% of total rev.',
          key: 'share_total_revenue',
          isPercentage: true,
          color: '#F66D00',
          noBar: true
        }, {
          name: 'Units Sold',
          key: 'total_quantity',
          color: '#6b9080',
          noBar: true
        }, {
          name: 'Days of Inventory',
          key: 'days_inventory',
          color: '#F10096',
          isDecimal: true,
          precision: 0
        }, {
          name: 'Lost Revenue',
          key: 'lost_revenue_excl_VAT',
          isCurrency: true,
          color: '#FFA800',
          digitSeparator: '.'
        }, {
          name: 'Share PPC Sales',
          key: 'share_sponsored_sales',
          isPercentage: true,
          color: '#7CB342'
        }, {
          name: 'TACOS',
          key: 'TACOS',
          isPercentage: true,
          color: '#619b8a'
        }, {
          name: '% of BuyBox',
          key: 'traffic_by_asin_buy_box_percentage',
          isPercentage: true,
          color: '#03A9F4'
        }]
      },
      Content: {
        sortBy: 'sales_channel',
        headers: [{
          name: 'Country',
          key: 'sales_channel',
          isString: true
        }, {
          name: 'Sponsored Sales',
          key: 'sponsored_sales',
          isCurrency: true,
          color: '#312783'
        }, {
          name: 'Units Sold',
          key: 'units_sold',
          color: '#6b9080'
        }, {
          name: 'Clicks',
          key: 'clicks',
          color: '#00296b'
        }, {
          name: 'CVR',
          key: 'CVR',
          color: '#31572c',
          isPercentage: true
        }, {
          name: 'Spend',
          key: 'spend',
          isCurrency: true,
          color: '#b36a5e'
        }]
      },
      'Pricing and Margin': {
        sortBy: 'sales_channel',
        headers: [{
          name: 'Country',
          key: 'sales_channel',
          isString: true,
          alignLeft: true
        }, {
          name: 'Product',
          key: 'product_name'
        }, {
          name: 'Fulfillment',
          key: 'fulfilmment_program',
          isString: true,
          alignLeft: true
        }, {
          name: 'BuyBox Winner',
          key: 'buybox_winner',
          isBoolCase: true
        }, {
          name: 'SNL',
          key: 'small_and_light',
          isString: true,
          alignLeft: true
        }, {
          name: 'Price',
          key: 'price',
          noBar: true,
          isCurrency: true,
          precision: 2,
          digitSeparator: '.'
        }, {
          name: 'Tax',
          key: 'tax_est',
          noBar: true,
          isCurrency: true,
          precision: 2,
          digitSeparator: '.'
        }, {
          name: 'Referral Fee',
          key: 'referal_fee',
          noBar: true,
          isCurrency: true,
          precision: 2,
          digitSeparator: '.'
        }, {
          name: 'FBA Fee',
          key: 'fba_fees',
          noBar: true,
          isCurrency: true,
          precision: 2,
          digitSeparator: '.'
        }, {
          name: 'Prep Fee',
          key: 'prep_fees',
          noBar: true,
          isCurrency: true,
          precision: 2,
          digitSeparator: '.'
        }, {
          name: 'COGS',
          key: 'COGS',
          noBar: true,
          isCurrency: true,
          precision: 2,
          digitSeparator: '.'
        }, {
          name: 'Margin',
          key: 'margin_ccy',
          noBar: true,
          isCurrency: true,
          precision: 2,
          digitSeparator: '.'
        }, {
          name: 'Margin Rate',
          key: 'margin_rate',
          noBar: true,
          isPercentage: true,
          precision: 1,
          isConditionalCase: true,
          upperBound: 0.05,
          lowerBound: 0
        }]
      },
      Advertising: {
        sortBy: 'spend',
        headers: [{
          name: 'Product',
          key: 'product_name',
          isString: true,
          truncateLength: 45,
          alignLeft: true
        }, {
          name: 'Sponsored Sales',
          key: 'sponsored_sales',
          isCurrency: true,
          color: '#312783'
        }, {
          name: 'Units Sold',
          key: 'units_sold',
          color: '#312783a6'
        }, {
          name: 'Clicks',
          key: 'clicks',
          color: '#00296b'
        }, {
          name: 'Spend',
          key: 'spend',
          isCurrency: true,
          color: '#ffa800'
        }, {
          name: 'ACOS',
          key: 'ACOS',
          color: '#7cb342',
          isPercentage: true
        }, {
          name: 'Share PPC Sales',
          key: 'share_sponsored_sales',
          color: '#5e35b1',
          isPercentage: true
        }, {
          name: 'TACOS',
          key: 'TACOS',
          isPercentage: true,
          color: '#03a9f4'
        }, {
          name: 'Cannibalization',
          key: 'cannibalization_cost',
          isCurrency: true,
          color: '#ec407a'
        }, {
          name: 'Sponsored Net Margin',
          key: 'sponsored_net_margin_after_cannibalization_cost',
          isCurrency: true,
          color: '#ff7043'
        }]
      },
      'Inventory Coverage': {
        sortBy: 'daily_velocity',
        headers: [{
          name: 'Product',
          key: 'product_name',
          isString: true,
          truncateLength: 20,
          alignLeft: true
        }, {
          name: 'Total Inventory Days',
          key: 'afn_total_inventory_days',
          isDecimal: true,
          precision: 0,
          color: '#312783'
        }, {
          name: 'Alert Inventory Threshold Days',
          key: 'alert_inventory_level_days',
          isDecimal: true,
          precision: 0,
          noBar: true,
          alignCenter: true
        }, {
          name: 'Total Inventory vs Threshold Days',
          key: 'total_inventory_minus_threshold',
          isDecimal: true,
          precision: 0,
          color: '#f44336'
        }, {
          name: 'Total Inventory Units',
          key: 'afn_total_quantity',
          isDecimal: true,
          precision: 0,
          color: '#f66d00'
        }, {
          name: 'Daily Velocity',
          key: 'daily_velocity',
          isDecimal: true,
          precision: 1,
          color: '#ffa800'
        }]
      }
    },
    Advertising: {
      Targeting: {
        sortBy: 'spend',
        headers: [{
          name: 'Targeting',
          key: 'targeting',
          isString: true,
          truncateLength: 45,
          alignLeft: true
        }, {
          name: 'Campaign',
          key: 'campaign_name',
          isString: true,
          truncateLength: 45,
          alignLeft: true
        }, {
          name: 'Match Type',
          key: 'match_type',
          isString: true,
          alignLeft: true
        }, {
          name: 'Last Bid',
          key: 'keyword_bid',
          noBar: true,
          precision: 2,
          isDecimal: true
        }, {
          name: 'Spend',
          key: 'spend',
          color: '#b36a5e',
          isCurrency: true,
          showCurrencySign: false
        }, {
          name: 'Impressions',
          key: 'impressions',
          noBar: true,
          isDecimal: true,
          precision: 0
        }, {
          name: 'Clicks',
          key: 'clicks',
          noBar: true
        }, {
          name: 'CTR',
          key: 'CTR',
          isPercentage: true,
          precision: 1,
          isCaseColor: true,
          noBar: true
        }, {
          name: 'Units Sold',
          key: 'units_sold'
        }, {
          name: 'CVR',
          key: 'CVR',
          isPercentage: true,
          precision: 0,
          isCaseColor: true,
          noBar: true
        }, {
          name: 'Sponsored Sales',
          key: 'sponsored_sales',
          isCurrency: true,
          showCurrencySign: false
        }, {
          name: 'ACOS',
          key: 'ACOS',
          isPercentage: true,
          noBar: true
        }, {
          name: 'Position',
          key: 'position',
          noBar: true,
          isDecimal: true,
          precision: 0
        }]
      },
      Campaigns: {
        sortBy: 'spend',
        headers: [{
          name: 'Country',
          key: 'sales_channel',
          isString: true,
          alignLeft: true
        }, {
          name: 'Campaign',
          key: 'campaign_name',
          isString: true,
          truncateLength: 45,
          alignLeft: true
        }, {
          name: 'Spend',
          key: 'spend',
          color: '#b36a5e',
          isCurrency: true,
          showCurrencySign: false
        }, {
          name: 'Impressions',
          key: 'impressions',
          noBar: true,
          isDecimal: true,
          precision: 0
        }, {
          name: 'Clicks',
          key: 'clicks',
          noBar: true
        }, {
          name: 'CTR',
          key: 'CTR',
          isPercentage: true,
          precision: 1,
          isCaseColor: true,
          noBar: true
        }, {
          name: 'Units Sold',
          key: 'units_sold'
        }, {
          name: 'CVR',
          key: 'CVR',
          isPercentage: true,
          precision: 0,
          isCaseColor: true,
          noBar: true
        }, {
          name: 'Sponsored Sales',
          key: 'sponsored_sales',
          isCurrency: true,
          showCurrencySign: false
        }, {
          name: 'ACOS',
          key: 'ACOS',
          isPercentage: true,
          noBar: true
        }]
      }
    }
  };
  return defaultHeaders;
};
const reformatRoute = route => {
  route = route.replace(/_/g, ' ');
  // Capitalize first letter of each word
  route = capitalize(route);
  return route;
};
const getGraphHeaderByTabView = (tab, view, periodColName) => {
  const headers = getGraphHeaders(periodColName);
  return headers[tab][view].headers;
};
const getPeriodColumnName = period => {
  switch (period) {
    case 'Daily':
      return 'Day';
    case 'Weekly':
      return 'Week (starting date)';
    case 'Monthly':
      return 'Month';
    default:
      return '';
  }
};
const getGraphTitles = () => {
  const defaultTitles = {
    Global: {
      Performance: 'Global Performance over the last',
      Advertising: 'Advertising Performance over the last'
    },
    Country: {
      Performance: 'Performance per country over',
      Advertising: 'Advertising Performance over'
    },
    Product: {
      Performance: 'Product Performance over',
      Content: 'Content Performance over',
      'Pricing and Margin': 'Last Pricing & Margin per Product',
      Advertising: 'Advertising Performance over',
      'Inventory Coverage': 'Products Falling Short of Target Coverage over the last'
    },
    Advertising: {
      Targeting: 'Targeting over',
      Campaigns: 'Campaigns over'
    },
    Sellers: {
      Performance: 'Seller Performance over',
      Advertising: 'Advertising Performance over'
    }
  };
  return defaultTitles;
};
const PERIODS = {
  'Weekly': '12 weeks',
  'Monthly': '12 months',
  'Daily': '14 days',
  'Last 7 days': 'last 7 days',
  'Last 28 days': 'last 28 days',
  'Yesterday': 'yesterday',
  'Last week': 'last week',
  'Last month': 'last month',
  'Last 90 days': 'last 90 days',
  'Last 365 days': 'last 365 days',
  'Last 12 months': 'last 12 months',
  'Custom': 'the selected period'
};
const defineComputedPeriod = (period, view) => {
  if (view === 'Inventory Coverage') return '28 days';
  let periodKey = (() => {
    if (typeof period === 'string') return period;
    if (period && period.title) return period.title;
  })();
  return PERIODS[periodKey];
};
const getGraphTitle = (tab, view, computedPeriod) => {
  const titles = getGraphTitles();
  const tabTitles = titles[tab];
  if (!tabTitles) {
    return '';
  }
  const title = tabTitles[view];
  if (!title) {
    return '';
  }
  return `${title} ${computedPeriod}`;
};
const getGraphDefaultSortBy = (tab, view) => {
  const headers = getGraphHeaders();
  return headers[tab][view].sortBy;
};
const getGraphId = ({
  prefix,
  tab,
  view
}) => {
  return `${prefix ? prefix + "-" : ''}${tab}-${view}`;
};
export { getGraphHeaders, getGraphHeaderByTabView, getPeriodColumnName, getGraphTitles, defineComputedPeriod, getGraphTitle, reformatRoute, transformDataForCheckbox, periodsSwitches, getGraphId, getOptionsBasedOnPeriod, getGraphDefaultSortBy };