import TheSidebar from '@/components/TheSidebar';
import TheTopbar from '@/components/TheTopbar';
import AppAlert from '@/components/AppAlert';
import PdfRenderer from '@/components/PdfRenderer.vue';
import { EVENTS } from '@/utils/constants';
export default {
  name: 'TheWrapperPrivate',
  props: {
    innerScroll: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isCollapsed: false,
      isHovering: false,
      currentBreadcrumbs: [{
        text: 'Dashboard',
        to: '/'
      }]
    };
  },
  methods: {
    handleCollapse(value) {
      this.isCollapsed = value;
    },
    handleHovering(value) {
      this.isHovering = value;
    },
    updateBreadcrumbs(breadcrumbs) {
      this.currentBreadcrumbs = breadcrumbs;
    }
  },
  computed: {
    homepageStyle() {
      let style = {};
      if (this.isCollapsed && !this.isHovering) {
        style = {
          ['--wrapper-private-sidebar-width']: '4.85rem',
          justifyContent: 'center'
        };
      } else {
        style = {
          ['--wrapper-private-sidebar-width']: '17.14rem',
          justifyContent: 'start'
        };
      }
      window.$bus.trigger(EVENTS.SIDEBAR_CHANGED);
      return style;
    },
    padded() {
      return this.$store.state.padded ? {
        padding: '0 1rem 1rem 1rem'
      } : {};
    }
  },
  components: {
    TheSidebar,
    TheTopbar,
    AppAlert,
    PdfRenderer
  }
};