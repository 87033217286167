export default {
  name: 'FiscalCodeFilter',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Select a fiscal code'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: '',
      computedValue: {
        text: '',
        value: ''
      },
      isDropdownOpen: false
    };
  },
  computed: {
    filteredOptions() {
      if (!this.searchQuery) {
        return this.options;
      }
      const query = this.searchQuery.toLowerCase();
      return this.options.filter(option => option.text.toLowerCase().includes(query));
    }
  },
  watch: {
    value(newValue) {
      this.computedValue = newValue || {
        text: '',
        value: ''
      };
    },
    options: {
      immediate: true,
      handler() {
        if (this.value && !this.options.find(option => option.value === this.value.value)) {
          this.computedValue = {
            text: '',
            value: ''
          };
        }
      }
    }
  },
  methods: {
    selectOption(option) {
      this.computedValue = option;
      this.$emit('input', option);
      this.$emit('change', option);
      this.searchQuery = '';
      this.isDropdownOpen = false;
    },
    keepDropdownOpen() {
      this.isDropdownOpen = true;
    },
    handleInputBlur() {
      setTimeout(() => {
        this.isDropdownOpen = false;
      }, 200);
    }
  },
  mounted() {
    try {
      if (typeof this.value === 'string') {
        this.computedValue = JSON.parse(this.value) || {
          text: '',
          value: ''
        };
      } else {
        this.computedValue = this.value || {
          text: '',
          value: ''
        };
      }
    } catch (e) {
      this.computedValue = {
        text: '',
        value: ''
      };
    }
  }
};