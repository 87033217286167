import { mapState } from "vuex";
import ConnectionTabsRenderer from "@/components/shared/ConnectionTabsRenderer";
import WorkspaceDetailPage from "@/pages/WorkspaceDetailPage";
import MembersPage from "@/pages/MembersPage";
import AppPagination from "@/components/AppPagination";
export default {
  name: "WorkspacePage",
  components: {
    ConnectionTabsRenderer,
    WorkspaceDetailPage,
    MembersPage,
    AppPagination
  },
  data() {
    return {
      dataLoading: true,
      page: 1,
      perPage: 20,
      totalPages: 0,
      showPagination: false,
      currentTab: 'workspace_detail',
      tabs: [{
        value: "workspace_detail",
        text: "Account Settings",
        badge: 0,
        canSwitchTab: this.canSwitchTab
      }, {
        value: "workspace_members",
        text: "Members",
        badge: 0,
        canSwitchTab: this.canSwitchTab
      }]
    };
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      isOmnyAdmin: state => state.auth.isOmnyAdmin
    }),
    currentComponent() {
      const componentMap = {
        'workspace_detail': WorkspaceDetailPage,
        'workspace_members': MembersPage
      };
      return componentMap[this.currentTab] || null;
    }
  },
  async mounted() {
    // Set `padded` to false to remove padding in `TheWrapperPrivate.innerPage` padding
    this.$store.dispatch("setPadded", false);
  },
  beforeUnmount() {
    // Reset the `padded` to true to add padding in `TheWrapperPrivate.innerPage` padding
    this.$store.dispatch("setPadded", true);
  },
  methods: {
    canSwitchTab() {
      return true;
    },
    handleTotalUpdate(totalData) {
      this.totalPages = totalData.totalPages;
      this.showPagination = totalData.showPagination;
    },
    updatePerPage(perPage) {
      this.page = 1;
      this.perPage = perPage;
    }
  }
};