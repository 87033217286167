import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a7245fa4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tabs"
};
const _hoisted_2 = {
  class: "tab-number"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, tab => {
    return _openBlock(), _createElementBlock("div", {
      key: tab.value,
      class: _normalizeClass(['tab', {
        active: tab.value == $props.modelValue
      }]),
      ref_for: true,
      ref: `ref-${tab.value}`
    }, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.tabs.indexOf(tab) + 1), 1 /* TEXT */), _createTextVNode(" " + _toDisplayString(tab.text) + " ", 1 /* TEXT */), _createVNode(_component_app_icon, {
      icon: "tab-arrow",
      color: tab.value == $props.modelValue ? '#312783' : '#999999',
      style: {
        "position": "relative",
        "right": "-32px"
      }
    }, null, 8 /* PROPS */, ["color"])], 2 /* CLASS */);
  }), 128 /* KEYED_FRAGMENT */))]);
}