import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_signed_bar = _resolveComponent("signed-bar");
  const _component_bar_slot_with_value = _resolveComponent("bar-slot-with-value");
  return _openBlock(), _createBlock(_component_bar_slot_with_value, {
    loading: $props.loading,
    value: $props.value,
    color: $props.color,
    align: $props.align,
    gap: $props.gap,
    tight: $props.tight,
    reverse: $props.reverse
  }, {
    bar: _withCtx(() => [_createVNode(_component_signed_bar, {
      value: $props.value,
      positiveColor: $props.positiveColor,
      negativeColor: $props.negativeColor,
      min: $props.min,
      max: $props.max,
      negativeMin: $props.negativeMin
    }, null, 8 /* PROPS */, ["value", "positiveColor", "negativeColor", "min", "max", "negativeMin"])]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading", "value", "color", "align", "gap", "tight", "reverse"]);
}