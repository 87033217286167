import KpisControllersSection from '@/components/KpisControllersSection';
import KpisRenderer from '@/components/KpisRenderer';
import PdfExportPopup from '@/components/PdfExportPopup';
import { periodsSwitches } from '@/components/shared/chartHelpers';
import { GlobalPerformancePage, GlobalAdvertisingPage, CountryPerformancePage, CountryAdvertisingPage, ProductPerformancePage, ProductAdvertisingPage, ProductPricingMarginPage, ProductInventoryCoveragePage, AdvertisingCampaignsPage, AdvertisingTargetingPage, SellersPerformancePage, SellersAdvertisingPage } from '@/pages/subpages';
import DashboardMixin from '@/mixins/DashboardMixin';
import DatePickerMixin from '@/mixins/DatePickerMixin';
import LoadingTable from '@/components/LoadingTable';
import { mapState } from 'vuex';
export default {
  name: 'HomePage',
  async created() {
    this.$store.dispatch('dashboardSetCurrentTab', 'Global');
    this.$store.dispatch('dashboardSetCurrentView', 'Performance');
    this.$store.dispatch('datePickerSetPeriod', this.getDateFromRangeCompare());
    const sellers = await this.getOmnyAccountSellers();
    this.omnyAccountSellersCount = sellers.length;
    this.omnyAccountHasMoreThanOneSeller = sellers.length > 1;
    await this.initializeFilters({
      sellers
    });
    this.homepageLoading = false;
    this.showGlobalPerformancePage = true;
  },
  components: {
    KpisRenderer,
    KpisControllersSection,
    PdfExportPopup,
    GlobalPerformancePage,
    GlobalAdvertisingPage,
    CountryPerformancePage,
    CountryAdvertisingPage,
    ProductPerformancePage,
    ProductAdvertisingPage,
    ProductPricingMarginPage,
    ProductInventoryCoveragePage,
    AdvertisingCampaignsPage,
    AdvertisingTargetingPage,
    SellersPerformancePage,
    SellersAdvertisingPage,
    LoadingTable
  },
  mixins: [DashboardMixin, DatePickerMixin],
  data() {
    return {
      settingUp: false,
      countries: [],
      sellers: [],
      omnyAccountSellersCount: 0,
      omnyAccountHasMoreThanOneSeller: false,
      currentViewMinMax: {},
      tableLoading: true,
      period: 'Weekly',
      currentPeriod: periodsSwitches.DEFAULT[0],
      INVENTORY_COVERAGE_PERIOD: periodsSwitches.INVENTORY_COVERAGE,
      showGlobalPerformancePage: false,
      showGlobalAdvertisingPage: false,
      showCountryPerformancePage: false,
      showCountryAdvertisingPage: false,
      showProductPerformancePage: false,
      showProductInventoryCoveragePage: false,
      showProductPricingAndMarginPage: false,
      showProductAdvertisingPage: false,
      showAdvertisingTargetingPage: false,
      showAdvertisingCampaignsPage: false,
      showSellersPerformancePage: false,
      showSellersAdvertisingPage: false,
      tabViewEnabler: {
        Global: {
          Performance: () => {
            this.resetAllTabs();
            this.showGlobalPerformancePage = true;
          },
          Advertising: () => {
            this.resetAllTabs();
            this.showGlobalAdvertisingPage = true;
          }
        },
        Country: {
          Performance: () => {
            this.resetAllTabs();
            this.showCountryPerformancePage = true;
          },
          Advertising: () => {
            this.resetAllTabs();
            this.showCountryAdvertisingPage = true;
          }
        },
        Product: {
          Performance: () => {
            this.resetAllTabs();
            this.showProductPerformancePage = true;
          },
          Advertising: () => {
            this.resetAllTabs();
            this.showProductAdvertisingPage = true;
          },
          'Inventory Coverage': () => {
            this.resetAllTabs();
            this.showProductInventoryCoveragePage = true;
          },
          'Pricing and Margin': () => {
            this.resetAllTabs();
            this.showProductPricingAndMarginPage = true;
          }
        },
        Advertising: {
          Targeting: () => {
            this.resetAllTabs();
            this.showAdvertisingTargetingPage = true;
          },
          Campaigns: () => {
            this.resetAllTabs();
            this.showAdvertisingCampaignsPage = true;
          }
        },
        Sellers: {
          Performance: () => {
            this.resetAllTabs();
            this.showSellersPerformancePage = true;
          },
          Advertising: () => {
            this.resetAllTabs();
            this.showSellersAdvertisingPage = true;
          }
        }
      },
      homepageLoading: true
    };
  },
  computed: {
    ...mapState({
      globalPeriod: state => state.period.globalPeriod
    }),
    getUpdatedTabFilters() {
      return this.getTabFilters({
        omnyAccountHasMoreThanOneSeller: this.omnyAccountHasMoreThanOneSeller,
        refreshTrigger: this.refreshTriggers[this.currentTab][this.currentView]
      });
    },
    getUpdatedPages() {
      return this.pages({
        omnyAccountHasMoreThanOneSeller: this.omnyAccountHasMoreThanOneSeller
      });
    },
    showDatePicker() {
      return !(['Global'].includes(this.currentTab) || ['Inventory Coverage', 'Pricing and Margin'].includes(this.currentView));
    }
  },
  methods: {
    handleTabChange(tab) {
      if (!this.currentTab) return;
      if (tab.name === this.currentTab) return;
      this.getUpdatedPages.forEach(page => {
        if (page.name === tab.name) {
          page.active = true;
          const view = this.graphControllers[page.name].views[0];
          this.handleViewChange(view);
          this.tabViewEnabler[tab.name][view.name]();
        } else {
          page.active = false;
        }
      });
    },
    handleViewChange(view) {
      // If we are on the same tab, and the same view, we don't do anything
      if (view.name === this.currentView) return;

      // Switch all graphControllers to false
      this.getGraphControllers.forEach(controller => {
        if (controller.name === view.name) {
          controller.active = true;
          this.tabViewEnabler[this.currentTab][view.name]();
        } else {
          controller.active = false;
        }
      });
    },
    resetAllTabs() {
      this.showGlobalPerformancePage = false;
      this.showGlobalAdvertisingPage = false;
      this.showCountryPerformancePage = false;
      this.showCountryAdvertisingPage = false;
      this.showProductPerformancePage = false;
      this.showProductAdvertisingPage = false;
      this.showProductInventoryCoveragePage = false;
      this.showProductPricingAndMarginPage = false;
      this.showAdvertisingTargetingPage = false;
      this.showAdvertisingCampaignsPage = false;
      this.showSellersPerformancePage = false;
      this.showSellersAdvertisingPage = false;
    },
    changeGlobalTabPeriod(period) {
      this.$store.dispatch('globalPeriodSetPeriod', period);
      window.$bus.trigger(this.refreshTriggers['Global'][this.currentView]);
    }
  }
};