export default {
  name: 'ButtonController',
  emits: ['selectItem'],
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedItem: {
      type: [String, Number, Object],
      required: true
    },
    customStyle: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    activate(item) {
      return this.selectedItem === item;
    }
  }
};