import AppPageTitle from "@/components/AppPageTitle";
import AppIcon from "@/components/AppIcon";
import AppButton from '@/components/AppButton';
import { sortData } from "@/utils";
export default {
  name: "ConnectionFailed",
  components: {
    AppPageTitle,
    AppIcon,
    AppButton
  },
  data() {
    return {
      errorTitle: "No advertising profiles found for your Seller/Vendor account",
      errorDetail: "These are the all the advertising profiles that are available for your personal user but Omny couldn’t find a match. Please close this windows and restart the connection process with a user authorized on the Amazon Advertising Account corresponding to your Seller/Vendor Account.",
      supportText: "If you’re unable to find the issue and need help, contact us to resolve your queries"
    };
  },
  methods: {
    onClick() {
      window.location.href = "mailto:support@get-omny.com";
    }
  }
};