import CenterWrapper from '../shared/CenterWrapper.vue';
export default {
  name: 'StrokedArrowDownIcon',
  components: {
    CenterWrapper
  },
  props: {
    color: {
      type: String,
      default: '#5A52AE'
    },
    width: {
      type: String,
      default: '18'
    },
    height: {
      type: String,
      default: '18'
    }
  }
};