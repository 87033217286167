import CompoundTable from "@/components/CompoundTable.vue";
import { EVENTS, FLOATING_COMPOUND_TABLE_STATES } from "@/utils/constants";
import DatePickerPopup from "@/components/DatePickerPopup.vue";
import LiteTableMixin from "@/mixins/LiteTableMixin.vue";
import AppIconGroup from "@/components/AppIconGroup.vue";
import AppPagination from "@/components/AppPagination.vue";
import FlexRow from "@/components/FlexRow.vue";
import TableSkeletonV2 from '@/components/TableSkeletonV2.vue';
import FilterDivider from "@/components/new-filters/FilterDivider.vue";
export default {
  name: "FloatingCompoundTable",
  components: {
    CompoundTable,
    DatePickerPopup,
    AppIconGroup,
    AppPagination,
    FlexRow,
    TableSkeletonV2,
    FilterDivider
  },
  emits: ["refresh", "handleSort", "changeDate", "floatingCompoundTableState", "tableState"],
  expose: ["handleNormalize"],
  mixins: [LiteTableMixin],
  beforeUnmount() {
    window.$bus.off(EVENTS.FLOATING_COMPOUND_TABLE_CLOSE_COLLAPSE, this.handleMinimize);
    window.$bus.off(EVENTS.FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE, this.handleNormalizeOrNothing);
  },
  mounted() {
    window.$bus.on(EVENTS.FLOATING_COMPOUND_TABLE_CLOSE_COLLAPSE, this.handleMinimize);
    window.$bus.on(EVENTS.FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE, this.handleNormalizeOrNothing);
  },
  props: {
    datePickerDate: {
      type: [Object, null],
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    totalPages: {
      type: Number,
      required: false,
      default: 0
    },
    perPage: {
      type: Number,
      required: false,
      default: 0
    },
    page: {
      type: Number,
      required: false,
      default: 1
    },
    fields: {
      type: Array
    }
  },
  data() {
    return {
      fullSize: false,
      visible: false,
      showShadow: false,
      tableHeight: 0,
      scrollHeight: 0,
      showBottomRow: false,
      bottomRowHeight: "auto"
    };
  },
  computed: {
    _page: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      }
    },
    _perPage: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit("update:perPage", val);
      }
    },
    _totalPages: {
      get() {
        return this.totalPages;
      },
      set(val) {
        this.$emit("update:totalPages", val);
      }
    }
  },
  methods: {
    handleDatePickerSubmit(date) {
      this.$emit("changeDate", date);
      window.$bus.trigger(EVENTS.FLOATING_COMPOUND_TABLE_OPEN_COLLAPSE);
    },
    handleMinimize() {
      this.$refs?.floatingCompoundTable.close();
      this.fullSize = false;
      this.$emit("tableState", FLOATING_COMPOUND_TABLE_STATES.CLOSED);
    },
    handleMaximize() {
      this.visible = true;
      this.fullSize = true;
      this.$emit("tableState", FLOATING_COMPOUND_TABLE_STATES.FULL_SIZE);
    },
    handleNormalize() {
      this.visible = true;
      this.fullSize = false;
      this.$emit("tableState", FLOATING_COMPOUND_TABLE_STATES.INTERMEDIATE);
    },
    handleNormalizeOrNothing() {
      // When we are in `closed` state, move to normal
      // When we are in any other state, stay there

      if (this.fullSize || this.visible) {} else {
        this.handleNormalize();
      }
    }
  }
};