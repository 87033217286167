import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-298326bd"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tooltip-wrapper"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = {
  style: {
    marginLeft: '0.25rem'
  }
};
const _hoisted_6 = {
  key: 2,
  class: "d-flex justify-content-center"
};
const _hoisted_7 = {
  key: 3,
  class: "sales-channel-wrapper"
};
const _hoisted_8 = {
  class: "icon"
};
const _hoisted_9 = ["src", "alt"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_infinite_bar_loader = _resolveComponent("infinite-bar-loader");
  const _component_b_tooltip = _resolveComponent("b-tooltip");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_th = _resolveComponent("b-th");
  const _component_b_tr = _resolveComponent("b-tr");
  const _component_b_thead = _resolveComponent("b-thead");
  const _component_ProdcutSkuAsinItem = _resolveComponent("ProdcutSkuAsinItem");
  const _component_TableBarElement = _resolveComponent("TableBarElement");
  const _component_b_td = _resolveComponent("b-td");
  const _component_b_tbody = _resolveComponent("b-tbody");
  const _component_b_table_simple = _resolveComponent("b-table-simple");
  return _openBlock(), _createElementBlock(_Fragment, null, [$props.pageRenderedLoading ? (_openBlock(), _createBlock(_component_infinite_bar_loader, {
    key: 0
  })) : _createCommentVNode("v-if", true), _createVNode(_component_b_table_simple, {
    striped: $options.switchStriped,
    responsive: "",
    id: $props.graphId,
    class: _normalizeClass(["custom-table table-graph", {
      inactive: $props.pageRenderedLoading,
      [$props.customClass]: true
    }])
  }, {
    default: _withCtx(() => [_createVNode(_component_b_thead, null, {
      default: _withCtx(() => [_createVNode(_component_b_tr, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.graphHeaders, (header, index) => {
          return _openBlock(), _createBlock(_component_b_th, {
            key: index,
            class: _normalizeClass({
              sortable: this.getSortableProperty(header)
            }),
            id: $props.graphId + header.key + index
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_tooltip, {
              "custom-class": "header-desc",
              target: $props.graphId + header.key + index,
              placement: "top",
              delay: {
                show: 0,
                hide: 0
              },
              animation: false,
              onClick: () => {}
            }, {
              default: _withCtx(() => [_createElementVNode("div", {
                innerHTML: $options.getHeaderDescription(header.key)
              }, null, 8 /* PROPS */, _hoisted_2)]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"]), _createElementVNode("div", {
              onClick: () => {
                if (!this.getSortableProperty(header)) return;
                _ctx.$emit('sortTable', header.key);
              },
              class: "d-flex"
            }, [_createElementVNode("span", {
              innerHTML: $options.processHeader(header)
            }, null, 8 /* PROPS */, _hoisted_4), _withDirectives(_createElementVNode("span", _hoisted_5, [$props.sortDirection === 'desc' ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              class: "icon",
              icon: "fa-solid fa-chevron-down"
            })) : (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 1,
              class: "icon",
              icon: "fa-solid fa-chevron-up"
            }))], 512 /* NEED_PATCH */), [[_vShow, this.sortBy === header.key]])], 8 /* PROPS */, _hoisted_3)])]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "id"]);
        }), 128 /* KEYED_FRAGMENT */))]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    }), _createVNode(_component_b_tbody, {
      class: "custom-table-body"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.graphData, (detail, positionIndex) => {
        return _openBlock(), _createBlock(_component_b_tr, {
          key: positionIndex
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.graphHeaders, (header, index) => {
            return _openBlock(), _createBlock(_component_b_td, {
              key: index,
              class: _normalizeClass(header.isString ? 'align-left' : 'align-right'),
              style: _normalizeStyle((() => {
                if (header?.isCaseColor) {
                  return {
                    backgroundColor: $options.getCaseColor(header.key, positionIndex)
                  };
                }
                if (header?.isBoolCase) {
                  return {
                    backgroundColor: $options.getBoolCaseColor(header.key, positionIndex)
                  };
                }
                if (header?.isConditionalCase) {
                  return {
                    backgroundColor: $options.getConditionalCaseColor(header.key, positionIndex, header?.upperBound, header?.lowerBound)
                  };
                }
                return {};
              })())
            }, {
              default: _withCtx(() => [header.key === 'period_agg' ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [_createTextVNode(_toDisplayString(this.formatPeriod(detail.period_agg)), 1 /* TEXT */)], 64 /* STABLE_FRAGMENT */)) : header.key === 'product_name' ? (_openBlock(), _createBlock(_component_ProdcutSkuAsinItem, {
                key: 1,
                item: {
                  product_name: $options.getData('product_name', positionIndex, false),
                  asin: $options.getData('asin', positionIndex),
                  sku: $options.getData('sku', positionIndex),
                  main_image_link: $options.getData('main_image_link', positionIndex)
                },
                showTitle: true,
                truncate: !$props.productFullText,
                truncateLength: $options.getProductNameTruncateLength(header?.truncateLength),
                showImage: !$props.noProductImage,
                breakName: $props.breakProductName
              }, null, 8 /* PROPS */, ["item", "truncate", "truncateLength", "showImage", "breakName"])) : ['buybox_winner'].includes(header.key) ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($options.getBuyBoxPosition($options.getData('buybox_winner', positionIndex))), 1 /* TEXT */)) : ['sales_channel', 'country_code'].includes(header.key) ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
                src: $options.getData('flag', positionIndex),
                alt: $options.getData(header.key, positionIndex),
                "blank-color": "#ccc"
              }, null, 8 /* PROPS */, _hoisted_9)]), _createElementVNode("div", null, _toDisplayString($options.getData(header.key, positionIndex)), 1 /* TEXT */)])) : (_openBlock(), _createBlock(_component_TableBarElement, {
                key: 4,
                amount: $options.getData(header.key, positionIndex),
                alignLeft: header?.alignLeft || false,
                alignCenter: header?.alignCenter ?? false,
                isString: header?.isString || false,
                truncateLength: header?.truncateLength || 15,
                color: header?.color || '#0072F0',
                max: $options.getMax(header.key),
                min: $options.getMin(header.key),
                precision: header?.precision || 0,
                isCurrency: header?.isCurrency || false,
                isPercentage: header?.isPercentage || false,
                noBar: header?.noBar || false,
                isEvolution: header?.isEvolution || false,
                isCaseColor: header?.isCaseColor || false,
                isDecimal: header?.isDecimal || false,
                digitSeparator: header?.digitSeparator || '.',
                showCurrencySign: header?.showCurrencySign,
                decimalThreshold: header?.decimalThreshold || 0,
                isBoolCase: header?.isBoolCase || false,
                rowCurrency: $options.getData('currency', positionIndex) || 'EUR'
              }, null, 8 /* PROPS */, ["amount", "alignLeft", "alignCenter", "isString", "truncateLength", "color", "max", "min", "precision", "isCurrency", "isPercentage", "noBar", "isEvolution", "isCaseColor", "isDecimal", "digitSeparator", "showCurrencySign", "decimalThreshold", "isBoolCase", "rowCurrency"]))]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "style"]);
          }), 128 /* KEYED_FRAGMENT */))]),
          _: 2 /* DYNAMIC */
        }, 1024 /* DYNAMIC_SLOTS */);
      }), 128 /* KEYED_FRAGMENT */))]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["striped", "id", "class"])], 64 /* STABLE_FRAGMENT */);
}