import { mapState } from 'vuex';
export default {
  name: 'AlertsAssignee',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      assignees: state => state.alerts.assignees
    }),
    assignee() {
      return this.assignees.find(item => item.id == this.modelValue.id);
    }
  },
  methods: {
    onClick(val) {
      this.$emit('update:modelValue', val);
    }
  }
};