import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SellerFilterBody = _resolveComponent("SellerFilterBody");
  const _component_FilterItem = _resolveComponent("FilterItem");
  return _openBlock(), _createBlock(_component_FilterItem, {
    id: "sellers",
    ref: "sellerFilterItem",
    icon: "StrokedCartIcon",
    title: $options.getSellerTitle,
    selectedCount: $props.sellers.filterState.selectedCount,
    onClearAll: _cache[0] || (_cache[0] = $event => {
      $props.sellers.clear();
      _ctx.$emit('cleared');
    })
  }, {
    body: _withCtx(() => [_createVNode(_component_SellerFilterBody, {
      title: "Seller",
      selected: $props.sellers.filterState.selected,
      isModified: $props.sellers.isModified,
      searching: $props.sellers.searching,
      list: $props.sellers.filterState.list,
      onClear: $options.onClear,
      onSelect: $options.onSelect,
      onSelectOnly: $options.onSelectOnly,
      onDeselect: $options.onDeSelect,
      onApply: $options.onApply,
      onSearch: $options.onSearch,
      onHidden: $options.onApply,
      onCloseDropdown: $options.onClose
    }, null, 8 /* PROPS */, ["selected", "isModified", "searching", "list", "onClear", "onSelect", "onSelectOnly", "onDeselect", "onApply", "onSearch", "onHidden", "onCloseDropdown"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "selectedCount"]);
}