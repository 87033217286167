import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_variable_styled_bar = _resolveComponent("variable-styled-bar");
  return _openBlock(), _createBlock(_component_variable_styled_bar, {
    value: $props.value,
    min: $props.min,
    max: $props.max,
    color: $props.color,
    class: "cross-hatched-bar",
    style: _normalizeStyle($options.getComputedStyle)
  }, null, 8 /* PROPS */, ["value", "min", "max", "color", "style"]);
}