import DropdownBody from "@/components/shared/DropdownBody.vue";
import FilterItemSelector from "./FilterItemSelector.vue";
export default {
  name: "FilterItem",
  components: {
    DropdownBody,
    FilterItemSelector
  },
  emits: ['clearAll'],
  props: {
    icon: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "#4D4D4D"
    },
    iconSize: {
      type: Number,
      default: 14
    },
    title: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    selectedCount: {
      type: Number,
      default: 0
    },
    noCount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toggleDropdown: false
    };
  },
  expose: ["openDropdown"],
  computed: {
    getComputedTitle() {
      return this.title ?? "Selected";
    }
  },
  methods: {
    handleHidden() {
      window.$bus.trigger("DROPDOWN_HIDDEN", this.id);
    },
    closeDropdown() {
      this.$refs.filterDropdown.close();
    },
    openDropdown() {
      //console.log("Opening Dropdown")
      this.$refs.filterDropdown.open();
    }
  }
};