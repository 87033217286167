import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0d09e662"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "kpis-container"
};
const _hoisted_3 = {
  key: 1,
  class: "empty-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_error_occured = _resolveComponent("error-occured");
  const _component_empty_space = _resolveComponent("empty-space");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_renderSlot(_ctx.$slots, "controllerSection", {}, undefined, true)]), _createElementVNode("div", _hoisted_2, [$props.error ? (_openBlock(), _createBlock(_component_error_occured, {
    key: 0,
    onRetry: $options.handleRetry
  }, null, 8 /* PROPS */, ["onRetry"])) : _createCommentVNode("v-if", true), $props.noDataFound ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_empty_space)])) : _renderSlot(_ctx.$slots, "kpisSection", {
    key: 2
  }, undefined, true)])]);
}