import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createBlock(_component_FlexRow, {
    gap: 24,
    styles: {
      alignSelf: 'stretch',
      justifyContent: 'flex-start',
      width: '100%'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      class: "modal-title w-full",
      style: _normalizeStyle({
        color: $props.color
      })
    }, [_createVNode(_component_FlexRow, {
      gap: 8,
      styles: {
        justifyContent: 'flex-start'
      }
    }, {
      default: _withCtx(() => [$props.icon ? (_openBlock(), _createBlock(_component_AppIcon, {
        key: 0,
        class: "icon",
        icon: $props.icon,
        size: 20,
        color: $props.color
      }, null, 8 /* PROPS */, ["icon", "color"])) : _createCommentVNode("v-if", true), _createElementVNode("div", null, [_renderSlot(_ctx.$slots, "title", {}, undefined, true)])]),
      _: 3 /* FORWARDED */
    })], 4 /* STYLE */), _renderSlot(_ctx.$slots, "default", {}, undefined, true), _createVNode(_component_AppIcon, {
      icon: "close-table",
      onClick: _cache[0] || (_cache[0] = $event => $options.modal = false),
      color: "#999999",
      size: 12,
      class: "cursor-pointer",
      style: {
        padding: '8px 12px'
      }
    })]),
    _: 3 /* FORWARDED */
  });
}