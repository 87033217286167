export default {
  name: "ApplyKeywordsIcon",
  props: {
    color: {
      type: String,
      default: "#736BC9"
    },
    width: {
      type: String,
      default: "24"
    },
    height: {
      type: String,
      default: "24"
    }
  }
};