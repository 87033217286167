export default {
  name: 'SkeletonItem',
  emits: ['click'],
  props: {
    color: {
      type: String,
      default: '#DDDDDD'
    },
    customStyle: {
      type: Object,
      default: () => {}
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    getComputedStyle() {
      return {
        height: "20px",
        backgroundColor: this.color,
        borderRadius: "2px",
        justifySelf: 'center',
        ...this.customStyle
      };
    }
  }
};