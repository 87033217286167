import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TextValue = _resolveComponent("TextValue");
  const _component_FlexRow = _resolveComponent("FlexRow");
  const _component_WrapWithSectionItem = _resolveComponent("WrapWithSectionItem");
  return _openBlock(), _createBlock(_component_WrapWithSectionItem, {
    selected: $props.selected,
    onSelect: _cache[0] || (_cache[0] = $event => _ctx.$emit('select', {
      ad_format: $props.ad_format
    })),
    onDeselect: _cache[1] || (_cache[1] = $event => _ctx.$emit('deselect', {
      ad_format: $props.ad_format
    })),
    onSelectOnly: _cache[2] || (_cache[2] = $event => _ctx.$emit('selectOnly', {
      ad_format: $props.ad_format
    }))
  }, {
    default: _withCtx(() => [_createVNode(_component_FlexRow, {
      gap: 8,
      classes: "targeting"
    }, {
      default: _withCtx(() => [_createVNode(_component_TextValue, {
        class: "name",
        value: $props.name,
        main: ""
      }, null, 8 /* PROPS */, ["value"])]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["selected"]);
}