//events - a super-basic Javascript (publish subscribe) pattern
class Event {
  constructor() {
    this.events = {};
  }
  on(eventName, fn) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
  }
  off(eventName, fn) {
    if (this.events[eventName]) {
      for (var i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][i] === fn) {
          this.events[eventName].splice(i, 1);
          break;
        }
      }
    }
  }
  trigger(eventName, data) {
    if (this.events[eventName]) {
      const eventHandlers = this.events[eventName].map(fn => fn(data));
      return Promise.all(eventHandlers);
    } else {
      return Promise.resolve();
    }
  }
}
export default new Event();