import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  return _openBlock(), _createBlock(_component_b_dropdown, {
    offset: [-13, 10],
    class: _normalizeClass(['dropdown-status']),
    style: _normalizeStyle({
      color: $options.status?.color,
      backgroundColor: $options.status?.back
    })
  }, {
    "button-content": _withCtx(() => [_createTextVNode(_toDisplayString($options.text), 1 /* TEXT */)]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statuses, item => {
      return _openBlock(), _createBlock(_component_b_dropdown_item, {
        key: item.id,
        active: item.id == $props.modelValue,
        onClick: $event => $options.onClick(item.id)
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "status-badge",
          style: _normalizeStyle({
            color: item.color,
            backgroundColor: item.back
          })
        }, _toDisplayString(item.title), 5 /* TEXT, STYLE */)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["active", "onClick"]);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style"]);
}