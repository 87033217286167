import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0b065012"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "rate-change"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_layered_bar_with_value = _resolveComponent("layered-bar-with-value");
  const _component_slot_with_evolution_value = _resolveComponent("slot-with-evolution-value");
  const _component_rate_change_modal = _resolveComponent("rate-change-modal");
  const _component_b_dropdown = _resolveComponent("b-dropdown");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    class: "wrapper",
    loading: $props.loading,
    onMouseover: _cache[0] || (_cache[0] = $event => _ctx.$refs.rateChangeDropdown?.open()),
    onMouseout: _cache[1] || (_cache[1] = $event => _ctx.$refs.rateChangeDropdown?.close()),
    onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('click'))
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_dropdown, {
      "no-caret": "",
      toggleClass: "dropdown-selector",
      ref: "rateChangeDropdown"
    }, {
      "button-content": _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
        gap: 8
      }, {
        evolution: _withCtx(() => [_renderSlot(_ctx.$slots, "evolution", {}, undefined, true)]),
        default: _withCtx(() => [_createVNode(_component_layered_bar_with_value, {
          align: "left",
          value1: $props.value1,
          value2: $props.value2,
          min1: $props.min1,
          max1: $props.max1,
          min2: $props.min2,
          max2: $props.max2,
          color1: $props.color1,
          color2: $props.color2,
          style: {
            padding: 0
          }
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["value1", "value2", "min1", "max1", "min2", "max2", "color1", "color2"])]),
        _: 3 /* FORWARDED */
      })]),
      default: _withCtx(() => [_createVNode(_component_rate_change_modal, null, _createSlots({
        lost: _withCtx(() => [_renderSlot(_ctx.$slots, "lost", {}, undefined, true)]),
        gained: _withCtx(() => [_renderSlot(_ctx.$slots, "gained", {}, undefined, true)]),
        default: _withCtx(() => [_createVNode(_component_layered_bar_with_value, {
          value1: $props.value1 * 0.8,
          value2: $props.value2 * 0.8,
          min1: $props.min1,
          max1: $props.max1,
          min2: $props.min2,
          height: 8,
          max2: $props.max2,
          color1: $props.color1,
          color2: $props.color2,
          style: {
            padding: 0
          }
        }, null, 8 /* PROPS */, ["value1", "value2", "min1", "max1", "min2", "max2", "color1", "color2"])]),
        _: 2 /* DYNAMIC */
      }, [_ctx.$slots['date'] ? {
        name: "date",
        fn: _withCtx(() => [_renderSlot(_ctx.$slots, "date", {}, undefined, true)]),
        key: "0"
      } : undefined]), 1024 /* DYNAMIC_SLOTS */)]),
      _: 3 /* FORWARDED */
    }, 512 /* NEED_PATCH */)])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading"]);
}