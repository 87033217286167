import AppIcon from "@/components/AppIcon";
import AppPopover from "@/components/AppPopover";
const _ = require("lodash");
export default {
  name: "DemandForecastingRowManual",
  emits: ["update:modelValue", "clear_manual_override"],
  components: {
    AppIcon,
    AppPopover
  },
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => {}
    },
    weighted30DSalesForecast: {
      type: Number,
      default: 0
    },
    coverageDays: {
      type: Number,
      default: 0
    },
    totalCoverage: {
      type: Number,
      default: 0
    },
    slowMover: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this?.modelValue ? this.modelValue == 0 ? 0 : this.modelValue * 1 : 0,
      id: null,
      edit: false
    };
  },
  watch: {
    modelValue() {
      this.value = this?.modelValue ? this.modelValue == 0 ? 0 : this.modelValue * 1 : 0;
    }
  },
  computed: {
    days() {
      return this.weighted30DSalesForecast > 0 ? Math.round(30 * this.modelValue / this.weighted30DSalesForecast) : 0;
    },
    isOver() {
      return this.days + this.coverageDays > this.totalCoverage * 1.5;
    },
    isUnder() {
      return this.days + this.coverageDays < this.totalCoverage * 0.5;
    }
  },
  created() {
    this.id = Math.floor(100000 + Math.random() * 900000).toString();
  },
  methods: {
    onClick() {
      this.edit = true;
      this.$nextTick(() => {
        this.$refs.inputRef.focus();
      });
    },
    onBlur() {
      this.edit = false;
      const value = this.value.toString().trim();
      this.$emit("update:modelValue", value == "" ? 0 : value * 1);
    },
    onClear() {
      this.$emit("clear_manual_override");
    }
  }
};