import { mapState } from 'vuex';
export default {
  name: 'AlertsStatus',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState({
      statuses: state => state.alerts.statuses
    }),
    status() {
      return this.statuses.find(item => item.id == this.modelValue);
    },
    text() {
      if (this.status) {
        return this.status.title;
      }
      return this.modelValue;
    }
  },
  methods: {
    onClick(val) {
      this.$emit('update:modelValue', val);
    }
  }
};