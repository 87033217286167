import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2deb47ba"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "wrapper"
};
const _hoisted_2 = {
  class: "chart"
};
const _hoisted_3 = {
  class: "graph-title"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  class: "chart-plot"
};
const _hoisted_6 = {
  key: 0,
  style: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0'
  }
};
const _hoisted_7 = {
  key: 0,
  style: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
const _hoisted_8 = {
  class: "graph"
};
const _hoisted_9 = {
  class: "graph-title"
};
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  class: "table-grapher-wrapper"
};
const _hoisted_12 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_skeleton_item = _resolveComponent("skeleton-item");
  const _component_empty_space = _resolveComponent("empty-space");
  const _component_Bar = _resolveComponent("Bar");
  const _component_table_skeleton = _resolveComponent("table-skeleton");
  const _component_center_wrapper = _resolveComponent("center-wrapper");
  const _component_table_grapher = _resolveComponent("table-grapher");
  const _component_pager_item = _resolveComponent("pager-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$props.tableLoading ? (_openBlock(), _createBlock(_component_skeleton_item, {
    key: 0
  })) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [$props.graphPositions.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.evolutionTitle), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */))]), _createElementVNode("div", _hoisted_5, [$props.tableLoading ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_skeleton_item, {
    text: "Evolution Chart Loading...",
    customStyle: {
      width: '100%',
      height: '100%'
    }
  })])) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [$options.noChartData && $options.noGraphData ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_empty_space, {
    text: "Sorry, no chart data available"
  })])) : (_openBlock(), _createBlock(_component_Bar, {
    key: 1,
    data: $options.chartData,
    options: $options.chartOptions
  }, null, 8 /* PROPS */, ["data", "options"]))], 64 /* STABLE_FRAGMENT */))])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [$props.tableLoading ? (_openBlock(), _createBlock(_component_skeleton_item, {
    key: 0
  })) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [$props.graphPositions.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($props.graphTitle), 1 /* TEXT */)) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */))]), _createElementVNode("div", _hoisted_11, [$props.tableLoading ? (_openBlock(), _createBlock(_component_table_skeleton, {
    key: 0,
    headers: $props.graphHeaders,
    length: 8
  }, null, 8 /* PROPS */, ["headers"])) : !$props.tableLoading && $props.graphPositions.length === 0 ? (_openBlock(), _createBlock(_component_center_wrapper, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_empty_space, {
      message: `No data available for ${$props.graphTitle}`
    }, null, 8 /* PROPS */, ["message"])]),
    _: 1 /* STABLE */
  })) : (_openBlock(), _createBlock(_component_table_grapher, {
    key: 2,
    graphData: this.graphPositions,
    graphMinMax: this.minMax,
    graphHeaders: $props.graphHeaders,
    fetchingData: false,
    sortBy: $props.sortBy,
    sortDirection: $props.sortDirection,
    graphHeaderDescriptions: $props.description,
    setStriped: true
  }, null, 8 /* PROPS */, ["graphData", "graphMinMax", "graphHeaders", "sortBy", "sortDirection", "graphHeaderDescriptions"]))]), $props.totalCount > $props.pageSize ? (_openBlock(), _createElementBlock("div", _hoisted_12, [_createVNode(_component_pager_item, {
    currentPage: $props.currentPage,
    pageSize: $props.pageSize,
    totalCount: $props.totalCount,
    onPage: _cache[0] || (_cache[0] = data => _ctx.$emit('page', data))
  }, null, 8 /* PROPS */, ["currentPage", "pageSize", "totalCount"])])) : _createCommentVNode("v-if", true)])]);
}