import AppDropdown from '@/components/AppDropdown.vue';
import AppButton from '@/components/AppButton.vue';
import AppIcon from '@/components/AppIcon.vue';
import FlexRow from '@/components/FlexRow.vue';
import AppButtonRadioGroup from "@/components/AppButtonRadioGroup.vue";
export default {
  name: 'AppPagination',
  emits: ['update:page', 'update:perPage'],
  components: {
    AppDropdown,
    AppButton,
    AppIcon,
    FlexRow,
    AppButtonRadioGroup
  },
  props: {
    page: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 20
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      perPageOptions: [{
        text: '20',
        value: 20
      }, {
        text: '50',
        value: 50
      }, {
        text: '100',
        value: 100
      }],
      hardLimits: {
        20: 20 * 20,
        50: 20 * 50,
        100: 20 * 100
      }
    };
  },
  computed: {
    pagesCount() {
      const total = this.total > this.hardLimits[this.perPage] ? this.hardLimits[this.perPage] : this.total;
      return Math.ceil(total / this.perPage);
    },
    pagesRange() {
      return Array.from({
        length: this.pagesCount
      }, (_, index) => index + 1);
    },
    perPageValue: {
      get() {
        return this.perPage;
      },
      set(val) {
        this.$emit('update:perPage', val);
      }
    },
    pageLocationString() {
      const startRange = this.perPage * (this.page - 1) + 1;
      const endRange = Math.min(this.total, this.perPageValue * this.page);
      return `${startRange} - ${endRange} of ${this.total}`;
    }
  },
  watch: {
    pagesCount(val) {
      if (this.page > val) {
        this.$emit('update:page', 1);
      }
    }
  },
  methods: {
    onClick(val) {
      this.$emit('update:page', val);
    },
    onPrev() {
      if (this.page > 1) {
        this.$emit('update:page', this.page - 1);
      }
    },
    onNext() {
      if (this.page < this.pagesCount) {
        this.$emit('update:page', this.page + 1);
      }
    }
  }
};