import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_group_by = _resolveComponent("group-by");
  return _openBlock(), _createBlock(_component_group_by, {
    ref: "groupBy",
    modelValue: $options.groupByIndex,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.groupByIndex = $event),
    tabs: _ctx.groupByElements,
    background: '#F4F4F4',
    borderColor: '#757575',
    onClicked: _cache[1] || (_cache[1] = $event => _ctx.$emit('clicked'))
  }, null, 8 /* PROPS */, ["modelValue", "tabs"]);
}