import { HugValue } from "@/components/tableElements";
import AppIcon from "@/components/AppIcon.vue";
import StrokedLinkIcon from "@/components/icons/StrokedLinkIcon.vue";
export default {
  name: "TargetingValue",
  props: {
    gap: {
      type: Number,
      default: 8
    },
    value: {
      type: Object,
      required: true
    },
    width: {
      type: String,
      default: '100%'
    },
    loading: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: "left"
    }
  },
  components: {
    AppIcon,
    HugValue,
    StrokedLinkIcon
  }
};