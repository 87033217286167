import { useVuelidate } from '@vuelidate/core';
import ValidateMixin from '@/mixins/ValidateMixin.vue';
import AppInput from '@/components/AppInput';
import AppButton from '@/components/AppButton';
import AppIcon from '@/components/AppIcon';
export default {
  name: 'AppInputRangeItem',
  emits: ['update:modelValue', 'remove', 'add', 'input', 'submit'],
  mixins: [ValidateMixin],
  components: {
    AppInput,
    AppButton,
    AppIcon
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validateRule: {
      type: String,
      default: 'none'
    },
    noRemove: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rangeItem: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  methods: {
    onRemove() {
      this.$emit('remove');
    },
    onAdd() {
      this.$emit('add');
    },
    onSubmit() {
      this.$emit('submit');
    }
  }
};