import { CategoryItemNew } from "@/components/aiCategory";
import { mapActions } from "vuex";
import draggable from "vuedraggable";
import AppIcon from "@/components/AppIcon";
import { getAiCategoryBenefits, getAiCategoryUseCases } from "@/components/aiCategory/service";
import { amz_sp_api_marketplace_ids_country_domains } from "@/utils/countries";
export default {
  name: "AiGenerationCategoriesSelection",
  emits: ["changeTab", "selectedProductBenefits", "selectedProductUseCases", "competitors_benefits_and_usecases", "categories"],
  expose: ["getState"],
  components: {
    AppIcon,
    CategoryItemNew,
    draggable
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    selectedProductData: {
      type: Object,
      default: () => {}
    },
    userInput: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "product_benefits"
    },
    usecases_array: {
      type: Array,
      default: []
    },
    benefits_array: {
      type: Array,
      default: []
    },
    competitors_array: {
      type: Array,
      default: []
    }
  },
  async created() {
    if (this.type == "product_usecases") {
      this.competitors = this.competitors_array;
      this.competitors_loading = false;
      this.categories = this.usecases_array;
      this.categories_loading = false;
      this.inactiveCategories = this.categories.filter(item => item.active == false);
      this.activeCategories = this.categories.filter(item => item.active == true);
      return;
    }
    this.competitors = this.competitors_array.length ? this.competitors_array : await this.getCompetitors();
    this.competitors_loading = false;
    try {
      //this is checking whether user has selected any version such as amazon version.
      const useSelectedProductData = this.selectedProductData && this.selectedProductData.title !== null && this.selectedProductData.title !== "Local version";

      //based on the user selected version,return the value associated with the version.
      const getPropertyValue = property => {
        return useSelectedProductData ? this.selectedProductData[property] : this.product[property];
      };
      const params = {
        asin: this.selectedProductData?.asin ?? this.product.asin,
        marketplace_id: this.selectedProductData?.marketplace_id ?? this.product.marketplace_id,
        product_name: getPropertyValue("product_name"),
        product_description: getPropertyValue("product_description"),
        user_text: this.userInput,
        bullet_points: [getPropertyValue("bullet_point_1"), getPropertyValue("bullet_point_2"), getPropertyValue("bullet_point_3"), getPropertyValue("bullet_point_4"), getPropertyValue("bullet_point_5")].filter(Boolean),
        category_id: this.product.category_id
      };
      const benefits_categories = this.benefits_array.length ? this.benefits_array : await this.getAndFormatBenefits(params);
      this.categories = benefits_categories;
      this.categories_loading = false;
      this.inactiveCategories = this.categories.filter(item => item.active == false);
      this.activeCategories = this.categories.filter(item => item.active == true);
      this.getWrapperHeight();
      const usescases_categories = this.usecases_array.length ? this.usecases_array : await this.getAndFormatUsecases(params);
      this.$emit('competitors_benefits_and_usecases', {
        competitors: this.competitors,
        usecases_array: usescases_categories,
        benefits_array: benefits_categories
      });
    } catch (error) {
      //console.log(error);
    }
    ;
  },
  data() {
    return {
      categoryBenefitsLoading: true,
      categoryUseCasesLoading: true,
      categories: [],
      activeCategories: [],
      inactiveCategories: [],
      competitors: [],
      categories_loading: true,
      competitors_loading: true,
      categoryemitMap: {
        product_benefits: "selectedProductBenefits",
        product_usecases: "selectedProductUseCases"
      },
      moveToActiveMap: {
        product_benefits: this.moveToActiveCategoriesBenefits,
        product_usecases: this.moveToActiveCategoriesUseCases
      },
      draggableWrapperHeight: 200,
      addButtonText: {
        product_benefits: " + Add benefit or select AI suggestion",
        product_usecases: " + Add usecase or select AI suggestion"
      }
    };
  },
  updated() {
    this.getWrapperHeight();
  },
  computed: {
    updatedActiveCategories: {
      get() {
        return this.activeCategories;
      },
      set(newVal) {
        this.activeCategories = newVal;
        //console.log("newVal", newVal);
        this.$emit(this.categoryemitMap[this.type], this.getSelectedCategories());
      }
    },
    getClassPerType() {
      return {
        product_benefits: this.type == "product_benefits" ?? false,
        product_usecases: this.type == "product_usecases" ?? false
      };
    },
    frameStyle() {
      return {
        height: `${this.draggableWrapperHeight + 64}px`
      };
    },
    lineStyle() {
      return {
        height: `${this.draggableWrapperHeight + 26}px`
      };
    },
    canAddCategory() {
      if (this.type == "product_usecases") {
        return true;
      }
      return this.activeCategories.length < 5;
    }
  },
  methods: {
    ...mapActions(["categoryDefinition"]),
    onSelectedProductBenefits(val) {
      this.categoryBenefitsLoading = false;
      this.$emit("selectedProductBenefits", val);
    },
    onSelectedProductUseCases(val) {
      this.categoryUseCasesLoading = false, this.$emit("selectedProductUseCases", val);
    },
    async getCompetitors() {
      const payload = {
        category_id: this.product.category_id,
        marketplace_id: this.product.marketplace_id
      };
      const result = await this.categoryDefinition(payload);
      return result;
    },
    editCategory(val) {
      this.activeCategories = this.activeCategories.map(item => {
        if (item.id === val.id) {
          return {
            ...item,
            ...val.content
          };
        } else {
          return item;
        }
      });
    },
    moveToActiveCategoriesBenefits({
      id,
      category,
      score,
      custom,
      edit,
      manual
    }) {
      if (this.activeCategories.length < 5) {
        this.inactiveCategories = this.inactiveCategories.filter(item => item.id !== id);
        this.activeCategories = [...this.activeCategories, {
          category,
          id: id,
          active: true,
          score,
          custom,
          edit,
          manual
        }];
      }
      this.$emit(this.categoryemitMap[this.type], this.getSelectedCategories());
      this.$emit("categories", [...this.activeCategories, ...this.inactiveCategories]);
    },
    moveToActiveCategoriesUseCases({
      id,
      category,
      score,
      custom,
      edit,
      manual
    }) {
      this.inactiveCategories = this.inactiveCategories.filter(item => item.id !== id);
      this.activeCategories = [...this.activeCategories, {
        category,
        id: id,
        active: true,
        score,
        custom,
        edit,
        manual
      }];
      this.$emit(this.categoryemitMap[this.type], this.getSelectedCategories());
      this.$emit("categories", [...this.activeCategories, ...this.inactiveCategories]);
    },
    moveToActiveCategories({
      id,
      category,
      score,
      custom,
      edit,
      manual
    }) {
      // if (this.inactiveCategories.length > 0) {
      this.moveToActiveMap[this.type]({
        id,
        category,
        score,
        custom,
        edit,
        manual
      });
      // } 
    },
    moveToInactiveCategories({
      id,
      category,
      score,
      custom,
      edit,
      manual
    }) {
      this.activeCategories = this.activeCategories.filter(item => item.id !== id);
      this.$emit(this.categoryemitMap[this.type], this.getSelectedCategories());
      this.$emit("categories", [...this.activeCategories, ...this.inactiveCategories]);
      if (manual) {
        this.categories = this.categories.filter(item => item.id !== id);
        return;
      }
      this.inactiveCategories = [...this.inactiveCategories, {
        category,
        id: id,
        active: false,
        score,
        custom,
        edit,
        manual
      }].sort((a, b) => b.score - a.score);
    },
    moveAllToActive() {
      this.activeCategories.forEach(item => {
        this.moveToInactiveCategories({
          id: item.id,
          category: item.category,
          score: item.score,
          custom: item.custom,
          edit: item.edit,
          manual: item.manual
        });
      });
      this.inactiveCategories.forEach(item => {
        this.moveToActiveCategories({
          id: item.id,
          category: item.category,
          score: item.score,
          custom: item.custom,
          edit: item.edit,
          manual: item.manual
        });
      });
    },
    getSelectedCategories() {
      return this.activeCategories.map(item => item.category);
    },
    getWrapperHeight() {
      this.draggableWrapperHeight = this.$refs.draggableWrapper ? this.$refs.draggableWrapper.$el.clientHeight : this.$refs.noCategoriesWrapper.clientHeight;
    },
    categoryRedirect() {
      const domain = amz_sp_api_marketplace_ids_country_domains[this.product.marketplace_id];
      const link = "https://www." + domain + "/s?node=" + this.product.category_id + "&fs=true";
      window.open(link, "_blank");
    },
    generateScore() {
      const existingScores = this.categories.map(item => item.score);
      let score;
      do {
        score = -(Math.floor(Math.random() * 98) + 1);
      } while (existingScores.includes(score));
      return score;
    },
    generateId() {
      const existingIds = this.categories.map(item => item.id);
      let id = this.categories.length + 1;
      do {
        id++;
      } while (existingIds.includes(id));
      return id;
    },
    addCategory() {
      const newCategory = {
        id: this.generateId(),
        score: this.generateScore(),
        category: "",
        active: false,
        custom: true,
        edit: true,
        manual: true
      };
      this.categories.push(newCategory);
      this.moveToActiveCategories({
        id: newCategory.id,
        category: newCategory.category,
        score: newCategory.score,
        custom: newCategory.custom,
        edit: newCategory.edit,
        manual: newCategory.manual
      });
    },
    async getAndFormatBenefits(params) {
      const result = await getAiCategoryBenefits({
        params
      });
      const benefits = result.map(category => ({
        ...category,
        active: false,
        custom: false,
        edit: false,
        manual: false,
        id: result.findIndex(item => item.category === category.category) + 1
      }));
      return benefits;
    },
    async getAndFormatUsecases(params) {
      const result = await getAiCategoryUseCases({
        params
      });
      const usecases = result.map(category => ({
        ...category,
        active: false,
        custom: false,
        edit: false,
        manual: false,
        id: result.findIndex(item => item.category === category.category) + 1
      }));
      return usecases;
    }
  }
};