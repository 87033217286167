import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-68082e51"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "selected-items-wrapper"
};
const _hoisted_2 = {
  class: "wrapper"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  class: "icon-group"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["onClick"];
const _hoisted_7 = ["onClick", "title"];
const _hoisted_8 = {
  class: "selected-region"
};
const _hoisted_9 = {
  key: 2
};
const _hoisted_10 = {
  class: "icon",
  title: "Deselect"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_filter_section_header = _resolveComponent("filter-section-header");
  const _component_product_sku_asin_item = _resolveComponent("product-sku-asin-item");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_coutry_filter_item = _resolveComponent("coutry-filter-item");
  const _component_seller_filter_item = _resolveComponent("seller-filter-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_filter_section_header, {
    header: "Selected Items",
    onClick: _cache[1] || (_cache[1] = e => $options.handleClearFilter(e))
  }, {
    "extra-buttons": _withCtx(() => [_createVNode(_component_b_button, {
      class: "tiny-btn",
      variant: "secondary",
      onClick: _cache[0] || (_cache[0] = e => $options.handleRefresh(e)),
      disabled: $props.activateRefresh
    }, {
      default: _withCtx(() => [_createTextVNode(" Apply ")]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["disabled"])]),
    _: 1 /* STABLE */
  }), _createElementVNode("div", _hoisted_2, [$props.filterTitle === 'Product' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.selected, (item, p_index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "custom-product-wrapper",
      key: p_index
    }, [_createVNode(_component_product_sku_asin_item, {
      item: item,
      truncate: true,
      truncateLength: 60,
      handleSelect: e => {
        $options.handleDeselect(e, item);
      },
      showTitle: true,
      breakName: true
    }, null, 8 /* PROPS */, ["item", "handleSelect"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
      class: "icon",
      onClick: e => $options.copyToClipboard(e, item),
      title: "Copy to Clipboard"
    }, [_createVNode(_component_font_awesome_icon, {
      icon: "fa-solid fa-clipboard",
      style: {
        color: '#6c757d'
      }
    })], 8 /* PROPS */, _hoisted_5), _createElementVNode("div", {
      class: "icon",
      onClick: e => $options.handleDeselect(e, item),
      title: "Deselect"
    }, [_createVNode(_component_font_awesome_icon, {
      icon: "fa-solid fa-times",
      style: {
        color: '#6c757d'
      }
    })], 8 /* PROPS */, _hoisted_6)])]);
  }), 128 /* KEYED_FRAGMENT */))])) : (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($props.selected, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      onClick: e => $options.handleDeselect(e, item),
      class: "selected-wrapper",
      key: index,
      title: item.long_name ? item.long_name : item.name
    }, [_createElementVNode("div", _hoisted_8, [$props.filterTitle === 'Country' ? (_openBlock(), _createBlock(_component_coutry_filter_item, {
      key: 0,
      item: item
    }, null, 8 /* PROPS */, ["item"])) : $props.filterTitle === 'Sellers' ? (_openBlock(), _createBlock(_component_seller_filter_item, {
      key: 1,
      item: item
    }, null, 8 /* PROPS */, ["item"])) : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(item.name), 1 /* TEXT */))]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_font_awesome_icon, {
      icon: "fa-solid fa-times",
      style: {
        color: '#6c757d'
      }
    })])], 8 /* PROPS */, _hoisted_7);
  }), 128 /* KEYED_FRAGMENT */))])]);
}