import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f5f87658"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "kpi-card"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = ["id"];
const _hoisted_4 = {
  class: "amount-box"
};
const _hoisted_5 = {
  class: "amount"
};
const _hoisted_6 = {
  key: 0,
  class: "subtitle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoadingElement = _resolveComponent("LoadingElement");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_WrapWithPopover = _resolveComponent("WrapWithPopover");
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_LoadingElement, {
    size: 60,
    loading: $props.loading
  }, {
    default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString($options.updatedName), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"]), _createVNode(_component_WrapWithPopover, {
    target: $props.kpiId
  }, {
    title: _withCtx(() => [_renderSlot(_ctx.$slots, "info-popover-title", {}, undefined, true)]),
    content: _withCtx(() => [_renderSlot(_ctx.$slots, "info-popover-content", {}, undefined, true)]),
    default: _withCtx(() => [_createElementVNode("div", {
      id: $props.kpiId,
      class: "info"
    }, [_createVNode(_component_AppIcon, {
      icon: "info",
      size: 16,
      color: "#999999"
    })], 8 /* PROPS */, _hoisted_3)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["target"])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_LoadingElement, {
    size: 80,
    loading: $props.loading
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "amount", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading"])]), _createVNode(_component_LoadingElement, {
    size: 55,
    loading: $props.loading
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "change-rate", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading"])]), _ctx.$slots['custom-subtitle'] ? _renderSlot(_ctx.$slots, "custom-subtitle", {
    key: 0
  }, undefined, true) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, ['kpi-subtitle-amount' in _ctx.$slots && 'subtitle-percentage' in _ctx.$slots ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_FlexRow, {
    gap: 12,
    styles: {
      justifyContent: 'flex-start'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_FlexRow, {
      gap: 4,
      styles: {
        justifyContent: 'flex-start'
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_LoadingElement, {
        size: 55,
        loading: $props.loading
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "kpi-subtitle-amount", {}, undefined, true), _createTextVNode(" " + _toDisplayString($props.subtitle), 1 /* TEXT */)]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["loading"])]),
      _: 3 /* FORWARDED */
    }), _createVNode(_component_LoadingElement, {
      size: 55,
      loading: $props.loading
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "subtitle-percentage", {}, undefined, true)]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["loading"])]),
    _: 3 /* FORWARDED */
  })])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */))]);
}