import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "d-flex flex-column",
  style: {
    "border-top": "2px solid #2c2376",
    "padding-top": "8px",
    "padding-bottom": "0px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_the_sidebar_menu_item = _resolveComponent("the-sidebar-menu-item");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.menuItems, item => {
    return _openBlock(), _createBlock(_component_the_sidebar_menu_item, {
      key: item.text,
      item: item,
      onClick: $options.onClick,
      isCollapsed: $props.isCollapsed,
      isHovering: $props.isHovering,
      openItemText: $props.openItemText,
      disabled: item.text === 'Inventory' ? _ctx.inventoryDisabled : false
    }, null, 8 /* PROPS */, ["item", "onClick", "isCollapsed", "isHovering", "openItemText", "disabled"]);
  }), 128 /* KEYED_FRAGMENT */))]);
}