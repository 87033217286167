import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MarketplaceValue = _resolveComponent("MarketplaceValue");
  const _component_WrapWithSectionItem = _resolveComponent("WrapWithSectionItem");
  return _openBlock(), _createBlock(_component_WrapWithSectionItem, {
    selected: $props.selected,
    onSelect: _cache[0] || (_cache[0] = $event => _ctx.$emit('select', $props.item)),
    onDeselect: _cache[1] || (_cache[1] = $event => _ctx.$emit('deselect', $props.item)),
    onSelectOnly: _cache[2] || (_cache[2] = $event => _ctx.$emit('selectOnly', $props.item))
  }, {
    default: _withCtx(() => [_createVNode(_component_MarketplaceValue, {
      style: {
        justifyContent: 'flex-start'
      },
      marketplace: $props.name,
      flag: $props.icon
    }, null, 8 /* PROPS */, ["marketplace", "flag"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["selected"]);
}