export default {
  name: 'DialValue',
  props: {
    value: {
      type: [null, Number],
      required: true
    },
    max: {
      type: Number,
      required: true,
      default: 1
    },
    width: {
      type: String,
      default: "16"
    },
    height: {
      type: String,
      default: "16"
    },
    color: {
      type: String,
      default: "#E16098"
    }
  },
  data() {
    return {
      radius: 6,
      // Radius of the arc
      centerX: 8,
      // Center X coordinate
      centerY: 8 // Center Y coordinate
    };
  },
  computed: {
    arcPath() {
      // Normalize value to percentage
      const percentage = this.value / this.max * 100;

      // Calculate arc length and start angle
      const arcLength = percentage / 100 * 360;
      const startAngle = -Math.PI / 2; // Starting angle in radians (0 degrees)
      const endAngle = startAngle + arcLength * Math.PI / 180; // End angle in radians

      // Calculate start and end points of the arc
      const startX = this.centerX + this.radius * Math.cos(startAngle);
      const startY = this.centerY + this.radius * Math.sin(startAngle);
      const endX = this.centerX + this.radius * Math.cos(endAngle);
      const endY = this.centerY + this.radius * Math.sin(endAngle);

      // Determine if the arc should be large or small (large-arc-flag)
      const largeArcFlag = arcLength > 180 ? 1 : 0;

      // Create the arc path
      return `M ${startX} ${startY} A ${this.radius} ${this.radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`;
    }
  }
};