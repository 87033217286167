import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_revenue_excl_vat_card = _resolveComponent("revenue-excl-vat-card");
  const _component_days_of_inventory_card = _resolveComponent("days-of-inventory-card");
  const _component_organic_cvr_card = _resolveComponent("organic-cvr-card");
  const _component_tacos_card = _resolveComponent("tacos-card");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: 24,
    styles: {
      alignSelf: 'stretch'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_revenue_excl_vat_card, {
      loading: $props.loading,
      name: $options.getCardName,
      amount: $options.previousPeriodKpis[0]?.amount,
      percentage: $options.previousPeriodKpis[0]?.percentage,
      percentageColor: $options.previousPeriodKpis[0]?.percentageColor,
      percentageBgColor: $options.previousPeriodKpis[0]?.percentageBgColor,
      subtitleAmount: $options.previousPeriodKpis[0]?.subtitleAmount,
      subtitlePercentage: $options.previousPeriodKpis[0]?.subtitlePercentage,
      splitSubtitle: $options.getSplitSubtitle
    }, null, 8 /* PROPS */, ["loading", "name", "amount", "percentage", "percentageColor", "percentageBgColor", "subtitleAmount", "subtitlePercentage", "splitSubtitle"]), _createVNode(_component_days_of_inventory_card, {
      loading: $props.loading,
      amount: $options.previousPeriodKpis[1]?.amount,
      days: $options.previousPeriodKpis[1]?.percentage,
      subtitleAmount: $options.previousPeriodKpis[1]?.subtitleAmount,
      subtitlePercentage: $options.previousPeriodKpis[1]?.subtitlePercentage
    }, null, 8 /* PROPS */, ["loading", "amount", "days", "subtitleAmount", "subtitlePercentage"]), _createVNode(_component_organic_cvr_card, {
      loading: $props.loading,
      amount: $options.previousPeriodKpis[2]?.amount,
      percentage: $options.previousPeriodKpis[2]?.percentage,
      subtitleAmount: $options.previousPeriodKpis[2]?.subtitleAmount,
      subtitlePercentage: $options.previousPeriodKpis[2]?.subtitlePercentage
    }, null, 8 /* PROPS */, ["loading", "amount", "percentage", "subtitleAmount", "subtitlePercentage"]), _createVNode(_component_tacos_card, {
      loading: $props.loading,
      amount: $options.previousPeriodKpis[3]?.amount,
      percentage: $options.previousPeriodKpis[3]?.percentage,
      subtitleAmount: $options.previousPeriodKpis[3]?.subtitleAmount,
      subtitlePercentage: $options.previousPeriodKpis[3]?.subtitlePercentage
    }, null, 8 /* PROPS */, ["loading", "amount", "percentage", "subtitleAmount", "subtitlePercentage"])]),
    _: 1 /* STABLE */
  });
}