import AppIcon from '@/components/AppIcon';
import AppTextarea from '@/components/AppTextarea';
import ValidateMixin from '@/mixins/ValidateMixin.vue';
import ContentDisplayRulesMixin from "@/mixins/ContentDisplayRulesMixin.vue";
import EditPageContentPreview from '@/components/EditPageContentPreview.vue';
import Popper from "vue3-popper";
import { computeLength } from '@/utils';
export default {
  name: 'EditPageContentEdit',
  mixins: [ValidateMixin, ContentDisplayRulesMixin],
  emits: ['update:modelValue'],
  components: {
    AppIcon,
    AppTextarea,
    Popper,
    EditPageContentPreview
  },
  props: {
    modelValue: {
      type: [String, Array],
      default: ''
    },
    status: {
      type: String,
      default: 'draft' // 'synced', 'published'
    },
    index: {
      type: Number,
      default: 0
    },
    scroll: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    },
    minimum_characters: {
      type: Number,
      default: 0
    },
    maximum_characters: {
      type: Number,
      default: 500
    },
    type: {
      type: String,
      default: ""
    },
    show_amazon_version: {
      type: Boolean,
      default: false
    },
    show_dropdown: {
      type: Boolean,
      default: false
    },
    allowHtmlInputAndSpecialCharacters: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000,
      actionsMap: {
        product_name: 'updateTitleLimitExceeded',
        generic_keyword: 'updateKeywordsLimitExceeded'
      },
      validationMap: {
        product_name: this.mValidateTitle,
        generic_keyword: this.mValidateKeyword,
        product_description: this.mValidateDescription,
        bullet_points: this.mValidateBulletPoints
      },
      copied: false,
      amazon_icon_color: "#736BC9"
    };
  },
  computed: {
    editText: {
      get() {
        return typeof this.modelValue == 'string' ? this.modelValue : this.modelValue[this.index];
      },
      set(val) {
        let newValue = this.allowHtmlInputAndSpecialCharacters ? val : this.removeHtmlTagsAndSpecialCharacters(val);
        if (typeof this.modelValue != 'string') {
          newValue = [...this.modelValue];
          newValue[this.index] = val;
        }
        this.$emit('update:modelValue', newValue);
        this.checkCharacterLimit(newValue);
      }
    },
    validation() {
      return this.getValidation();
    },
    errorClass() {
      return this.editText.trim().length > this.maximum_characters;
    },
    characterCountInfo() {
      return this.getCharacterCountInfo();
    },
    amazonIconColor() {
      return this.amazon_icon_color;
    }
  },
  methods: {
    checkCharacterLimit(newValue) {
      const action = this.actionsMap[this.type];
      if (action) {
        this.$store.dispatch(action, newValue.trim().length > this.maximum_characters);
      }
    },
    getValidation() {
      const validateMethod = this.validationMap[this.type];
      if (validateMethod) {
        return typeof this.modelValue == 'string' ? validateMethod(this.modelValue) : validateMethod(this.modelValue[this.index]);
      }
      return {
        status: 'error',
        text: 'Invalid type'
      };
    },
    getCharacterCountInfo() {
      const valueLength = computeLength(this.type, this.editText);
      const isProductType = this.type === 'product_name' || this.type === 'generic_keyword';
      const maxCharacters = isProductType ? ` / ${this.maximum_characters}` : '';
      const plural = valueLength > 2 ? 's' : '';
      return `${valueLength}${maxCharacters} character${plural}`;
    },
    onCopy() {
      if (!this.copied) {
        navigator.clipboard.writeText(this.editText).then(this.setCopied);
      }
    },
    setCopied() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 5000);
    },
    updateAmazonIconColor(color) {
      this.amazon_icon_color = color;
    }
  }
};