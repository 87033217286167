import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button");
  const _component_FlexRow = _resolveComponent("FlexRow");
  return _openBlock(), _createBlock(_component_FlexRow, {
    styles: {
      justifyContent: 'flex-start'
    },
    class: "wrap-with-only-btn"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), _createVNode(_component_FlexRow, {
      onClick: _cache[0] || (_cache[0] = e => {
        e.stopPropagation();
        _ctx.$emit('click');
      }),
      class: _normalizeClass([{
        ['show-only-btn']: $props.showButton
      }, 'only'])
    }, {
      default: _withCtx(() => [_createVNode(_component_b_button, {
        variant: "outline-secondary",
        size: "sm",
        class: "only-btn"
      }, {
        default: _withCtx(() => [_createTextVNode(" ONLY ")]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"])]),
    _: 3 /* FORWARDED */
  });
}