import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SkeletonItem = _resolveComponent("SkeletonItem");
  return $props.loading ? (_openBlock(), _createBlock(_component_SkeletonItem, {
    key: 0,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('click')),
    customStyle: {
      width: $props.size ? `${$props.size}px` : '100%',
      height: $props.height ? `${$props.height}px` : '100%'
    },
    color: $props.color
  }, null, 8 /* PROPS */, ["customStyle", "color"])) : _renderSlot(_ctx.$slots, "default", {
    key: 1
  });
}