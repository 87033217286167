import { request } from "@/store";
import Filter from "@/store/filter";
var _ = require("lodash");
const cogs_data = {
  state: {
    list: [],
    download_list: [],
    total_rows: null,
    sellers_filter: new Filter([], [], "id")
  },
  mutations: {
    productCogsDataSellersFilterSet(state, payload) {
      state.sellers_filter = payload;
    },
    productCogsDataListSet(state, payload) {
      state.list = payload;
    },
    productCogsDataListSetDownload(state, payload) {
      state.download_list = payload;
    },
    filteredproductCogsDataListSet(state, payload) {
      state.list = state.list.map(item => {
        let product_data_items = payload.find(product_data => product_data.sku === item.sku);
        return {
          ..._.mergeWith({}, item, product_data_items, (o, s) => _.isNull(s) ? o : s)
        };
      });
    },
    filteredproductCogsDataListSetDownload(state, payload) {
      state.download_list = state.download_list.map(item => {
        let product_data_items = payload.find(product_data => product_data.sku === item.sku);
        return {
          ..._.mergeWith({}, item, product_data_items, (o, s) => _.isNull(s) ? o : s)
        };
      });
      //console.log()
    },
    productCogsDataTotalRowSet(state, payload) {
      state.total_rows = payload;
    }
  },
  getters: {
    productCogsDataSellersFilterValuesIdsGet(state) {
      return () => {
        return state.sellers_filter.filterValues;
      };
    },
    productCogsDataGetProductSkus(state) {
      return () => {
        return state.list.map(product => product.sku);
      };
    }
  },
  actions: {
    async getAllProductCogsDataList({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_cogs_data_by_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("filteredproductCogsDataListSet", result.data);
      }
      return result;
    },
    async getAllProductCogsDataListToDownload({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_cogs_data_by_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("filteredproductCogsDataListSetDownload", result.data);
      }
      return result;
    },
    async getProductCogsDataList({
      commit
    }, payload) {
      //console.log("[getProductCogsDataList]");

      let result = (await request("/inventory/get_product_cogs_data_from_gcp_by_seller_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("productCogsDataListSet", result.data);
        commit("productCogsDataTotalRowSet", result.total_rows);
      }
      return result;
    },
    async getProductCogsDataListToDownload({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_cogs_data_from_gcp_by_seller_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("productCogsDataListSetDownload", result.data);
        commit("productCogsDataTotalRowSet", result.total_rows);
      }
      return result;
    },
    async dfUpdateProductCogsData({
      state
    }, payload) {
      //console.log("[sending cogs data to the backend]");
      //console.log(payload);

      let result = await request("/inventory/update_product_cogs_data", "POST", payload, false);
      return result;
    },
    async dfproductDataList({
      commit
    }, payload) {
      let result = (await request("/inventory/get_product_data_by_id", "POST", payload)) ?? {
        data: []
      };
      if (result.data) {
        commit("filteredproductCogsDataListSet", result.data);
      }
      return result;
    },
    async uploadCogsDataCSV({
      state
    }, payload) {
      //console.log("[Inside uploadProductDataCSV]");
      //console.log("[chunk index]");

      //console.log(payload.chunkIndex);

      const formData = new FormData();
      const blob = new Blob([payload.chunk], {
        type: "text/plain"
      });
      formData.append("file", blob);
      formData.append("file_name", payload.fileName);
      formData.append("file_type", payload.fileType);
      formData.append("total_chunks", payload.totalChunks);
      formData.append("chunk_index", payload.chunkIndex);
      try {
        const response = await request("/inventory/uploadcogscsv", "POST", formData, false);
        //console.log("[instant response from server when clicked upload]");
        //const result = await response.json();
        //console.log(response.task_status);
        //console.log(response.task_id);

        if (!response.task_id) {
          throw new Error("Upload failed");
        }
        // console.log(
        //   `Chunk ${payload.chunkIndex + 1} of ${
        //     payload.totalChunks
        //   } uploaded successfully`,
        //   response
        // );
        return response;
      } catch (error) {
        console.error(`Error uploading chunk ${payload.chunkIndex + 1}`, error);
      }
      //return { status: response.status,task_id:response.task_id};
    }
  }
};
export default cogs_data;