export default {
  name: 'AppSlider',
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    variant: {
      type: String,
      default: 'default'
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    showSteps: {
      type: Boolean,
      default: true
    },
    paddingTop: {
      type: Number,
      default: 36
    },
    paddingBottom: {
      type: Number,
      default: 0
    },
    lineSize: {
      type: Number,
      default: 8
    },
    thumbSize: {
      type: Number,
      default: 24
    },
    offsetLeft: {
      type: Number,
      default: 0
    },
    offsetRight: {
      type: Number,
      default: 0
    },
    labelWidth: {
      type: Number,
      default: 30
    },
    mixed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      steps: [],
      render: 0.1
    };
  },
  computed: {
    initValues() {
      return {
        min: 0,
        max: this.options.length - 1,
        step: 1
      };
    },
    sliderValue: {
      get() {
        const index = this.options.findIndex(item => item.value == this.modelValue);
        if (index !== -1) {
          return index;
        }
        return 0;
      },
      set(val) {
        const nearestIndex = Math.round(val / this.initValues.step) * this.initValues.step;
        this.$emit('update:modelValue', this.options?.[nearestIndex]?.value);
      }
    },
    sliderTooltip() {
      return this.options?.[this.sliderValue]?.tooltip ?? this.options?.[this.sliderValue]?.label;
    },
    sliderStyle() {
      return [`padding-top: ${this.paddingTop}px`, `padding-bottom: ${this.paddingBottom}px`].join('; ');
    },
    lineStyle() {
      return [`height: ${this.lineSize}px`, `border-radius: ${this.lineSize}px`].join('; ');
    },
    progressStyle() {
      return [`width: ${this.progressWidth}`, `border-radius: ${this.lineSize}px`, this.mixedProgressStyle].join('; ');
    },
    progressWidth() {
      return this.sliderValue == this.initValues.max ? '100%' : `${this.getStepWidth() * this.sliderValue + this.offsetLeft + this.thumbSize / 2 + this.render}px`;
    },
    thumbStyle() {
      return [`left: ${this.thumbLeft}px`, `width: ${this.thumbSize}px`, `height: ${this.thumbSize}px`, `margin-top: -${this.thumbSize / 2}px`, this.mixedBgColorStyle].join('; ');
    },
    thumbLeft() {
      return this.getStepWidth() * this.sliderValue + this.offsetLeft + this.render;
    },
    tooltipStyle() {
      return [this.mixedBgColorStyle].join('; ');
    },
    inputStyle() {
      return [`top: ${this.paddingTop - this.lineSize}px`, `left: ${this.offsetLeft}px`, `right: ${this.offsetRight}px`, `height: ${this.thumbSize}px`].join('; ');
    },
    stepsStyle() {
      return [`top: ${(this.thumbSize - this.lineSize) / 2 + 8}px`, `left: ${this.offsetLeft + this.thumbSize / 2 - this.labelWidth / 2}px`, `right: ${this.offsetRight + this.thumbSize / 2 - this.labelWidth / 2}px`].join('; ');
    },
    stepStyle() {
      return label => {
        return [`width: ${this.labelWidth}px`, this.mixedColorStyle(label)].join('; ');
      };
    },
    mixedStartIndex() {
      return this.options.findIndex(item => item.start);
    },
    mixedSegments() {
      const stepWidth = this.getStepWidth() + this.render;
      return this.options.filter(item => !item.start).map((item, index) => {
        let width = stepWidth;
        if (index == 0 || index == this.options.length - 2) {
          width += this.thumbSize / 2;
        }
        return {
          index,
          width,
          color: item.colors[0]
        };
      });
    },
    mixedSegmentStyle() {
      return segment => {
        return [`width: ${segment.width}px`, `background-color: ${segment.color}`].join('; ');
      };
    },
    mixedBgColorStyle() {
      if (this.mixed) {
        const option = this.options.find(item => item.value == this.modelValue);
        if (option) {
          return [`background-color: ${option.colors[1]}`].join('; ');
        }
      }
      return '';
    },
    mixedColorStyle() {
      return label => {
        if (this.mixed) {
          const option = this.options.find(item => item.label == label);
          if (option) {
            return [`color: ${option.colors[1]}`].join('; ');
          }
        }
        return '';
      };
    },
    mixedProgressStyle() {
      if (this.mixed) {
        const currentIndex = this.options.findIndex(item => item.value == this.modelValue);
        const left = this.mixedCalculatePosition(currentIndex <= this.mixedStartIndex ? currentIndex : this.mixedStartIndex);
        const right = this.mixedCalculatePosition(currentIndex <= this.mixedStartIndex ? this.mixedStartIndex : currentIndex);
        return [`width: ${right - left}px`, `left: ${left}px`, this.mixedBgColorStyle].join('; ');
      }
      return '';
    },
    mixedCalculatePosition() {
      return maxIndex => {
        const stepWidth = this.getStepWidth() + this.render;
        return this.options.reduce((acc, _, index) => {
          if (index < maxIndex) {
            acc += stepWidth;
            if (index == 0 || index == this.options.length - 1) {
              acc += this.thumbSize / 2;
            }
          }
          return acc;
        }, 0);
      };
    }
  },
  mounted() {
    this.calculateSteps();
    this.$nextTick(() => {
      this.render = 0;
    });
  },
  methods: {
    getStepWidth() {
      const line = this.$refs.line;
      if (line) {
        return (line.offsetWidth - this.thumbSize) / this.initValues.max;
      }
      return 0;
    },
    calculateSteps() {
      this.steps = [];
      for (let i = this.initValues.min; i <= this.initValues.max; i += this.initValues.step) {
        this.steps.push(this.options?.[i]?.label);
      }
    },
    onChange() {
      this.$emit('change', this.modelValue);
    }
  }
};