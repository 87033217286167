import { SellerFilter, MarketplaceFilter, ProductFilter, DatePickerFilter } from '@/components/new-filters';
import FlexRow from '@/components/FlexRow.vue';
import { EVENTS } from '@/utils/constants';
import MultiModeDatePicker from "@/components/MultiModeDatePicker.vue";
import MultiModeDatePickerUIText from '@/components/MultiModeDatePickerUIText.vue';
import { processDate } from '../utils';
export default {
  name: 'ProfitAndLossFilters',
  components: {
    SellerFilter,
    MarketplaceFilter,
    ProductFilter,
    FlexRow,
    DatePickerFilter,
    MultiModeDatePicker,
    MultiModeDatePickerUIText
  },
  expose: ['datePicker'],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sellers() {
      return this.$store.state.profitAndLoss.sellers;
    },
    marketplaces() {
      return this.$store.state.profitAndLoss.marketplaces;
    },
    products() {
      return this.$store.state.profitAndLoss.products;
    },
    profitAndLossFilters() {
      return {
        seller_id: this.sellers.filterValues[0],
        sales_channel: this.marketplaces.filterValues[0]
      };
    },
    datePicker() {
      return this.$refs.datePicker;
    },
    datePickerDate() {
      return this.$store.getters.profitAndLossGetAggregatePeriod();
    },
    datePickerDisplayDate() {
      return type => {
        const {
          date,
          format
        } = this.datePickerDate[type];
        if (this.datePickerDate.title === 'Quarterly') {
          // Return `Q{1-4} {year}` format
          return `Q${processDate(date, format)}`;
        }
        return processDate(date, format);
      };
    }
  },
  methods: {
    async applySellers() {
      if (this.sellers.isModified) {
        this.sellers.apply();

        // This await is very important, because we need to wait for the new marketplace to be set
        // Note: Whenever, wherever we apply the sellers, we need to trigger the sub filters to be refreshed (in this async await manner.)
        window.$bus.trigger('showSuccess', {
          message: '⏳Fetching sub filters for sellers...  ',
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_PROFIT_AND_LOSS_SELLERS_SUB_FILTERS);
        window.$bus.trigger('showSuccess', {
          visible: false,
          message: ""
        });
        window.$bus.trigger(EVENTS.REFRESH_PROFIT_AND_LOSS);
      }
    },
    async applyMarketplaces() {
      if (this.marketplaces.isModified) {
        this.marketplaces.apply();
        window.$bus.trigger('showSuccess', {
          message: '⏳Fetching sub filters for marketplace...  ',
          visible: true,
          delay: 10
        });
        await window.$bus.trigger(EVENTS.REFRESH_PROFIT_AND_LOSS_MARKETPLACES_SUB_FILTERS);
        window.$bus.trigger('showSuccess', {
          visible: false,
          message: ""
        });
        window.$bus.trigger(EVENTS.REFRESH_PROFIT_AND_LOSS);
      }
    },
    applyProducts() {
      if (this.products.isModified) {
        this.products.apply();
        window.$bus.trigger(EVENTS.REFRESH_PROFIT_AND_LOSS);
      }
    },
    async handleDatePickerSubmit(state) {
      this.$refs.datePickerFilter.close();
      await window.$bus.trigger(EVENTS.PROFIT_AND_LOSS_DATE_PICKER_SUBMIT, state);
    },
    handleDatePickerCancel() {
      this.$nextTick().then(() => {
        this.$refs.datePicker.reset(this.datePickerDate);
        this.$refs.datePickerFilter.close();
      });
    }
  }
};