import WrapWithCopy from "@/components/WrapWithCopy.vue";
import AppPopover from "@/components/AppPopover";
import AppIcon from "@/components/AppIcon";
import Popper from "vue3-popper";
import AppTd from "@/components/AppTd";
import WrapWithStatusIcon from "@/components/seo/WrapWithStatusIcon.vue";
export default {
  name: 'SeoProductCell',
  components: {
    AppIcon,
    AppPopover,
    WrapWithCopy,
    Popper,
    AppTd,
    WrapWithStatusIcon
  },
  emits: ["onEdit"],
  props: {
    item: Object,
    index: Number,
    imgWidth: String,
    imgHeight: String,
    imgBorder: String,
    showNavigationBtn: Number,
    pushModal: Boolean
  },
  data() {
    return {
      imgWidth: "28px",
      imgHeight: "28px",
      imgBorder: "none"
    };
  },
  methods: {
    onImageChange(item) {
      //we comment it temporarily because we might work on the image upload or image processing
      // const originalItem = this.products.find((el) => el.asin == item.asin);

      // this.imageManagerModal = true;
      // this.editAsin = item.asin;
      // this.editImage =
      //   originalItem.image_large ||
      //   originalItem.image_medium ||
      //   originalItem.image_small;

      //temporary solution
      let link = item.amazon_link;
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "https://" + link;
      }
      window.open(link, "_blank");
    },
    onEdit(item, field, title) {
      // Logic for handling edit
    }
  }
};