import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ca5eadd"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "total-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_evolution_value = _resolveComponent("evolution-value");
  const _component_slot_with_evolution_value = _resolveComponent("slot-with-evolution-value");
  const _component_number_value = _resolveComponent("number-value");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_b_td = _resolveComponent("b-td");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fields, field => {
    return _openBlock(), _createBlock(_component_b_td, {
      key: field.key,
      class: _normalizeClass([{
        ['b-table-sticky-column']: $options.isTotalColumn(field)
      }])
    }, {
      default: _withCtx(() => [_createVNode(_component_hug_value, {
        class: "total-element",
        styles: {
          justifyContent: field.justify,
          padding: '8px'
        },
        loading: $props.loading
      }, {
        default: _withCtx(() => [$options.isTotalColumn(field) ? (_openBlock(), _createElementBlock("span", _hoisted_1, "TOTAL")) : _createCommentVNode("v-if", true), field.key === 'sponsored_sales' ? (_openBlock(), _createBlock(_component_slot_with_evolution_value, {
          key: 1,
          gap: 16
        }, {
          evolution: _withCtx(() => [$props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}sponsored_sales_evol`] !== null ? (_openBlock(), _createBlock(_component_evolution_value, {
            key: 0,
            value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}sponsored_sales_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}sponsored_sales_evol`],
              color: color
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)]),
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $props.total.sponsored_sales
          }, null, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'spend' ? (_openBlock(), _createBlock(_component_slot_with_evolution_value, {
          key: 2,
          gap: 16
        }, {
          evolution: _withCtx(() => [$props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}spend_evol`] !== null ? (_openBlock(), _createBlock(_component_evolution_value, {
            key: 0,
            value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}spend_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}spend_evol`],
              color: color
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)]),
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $props.total.spend
          }, null, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'clicks' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 3,
          value: $props.total.clicks,
          tight: ""
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'ACOS' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 4,
          value: $props.total.ACOS
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'TACOS' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 5,
          value: $props.total.TACOS
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'units_sold_clicks_14_d' ? (_openBlock(), _createBlock(_component_slot_with_evolution_value, {
          key: 6,
          gap: 16
        }, {
          default: _withCtx(() => [_createVNode(_component_number_value, {
            value: $props.total.units_sold_clicks_14_d,
            tight: ""
          }, null, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'ROAS' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 7,
          value: $props.total.ROAS,
          precision: 1,
          tight: ""
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'CPC' ? (_openBlock(), _createBlock(_component_currency_value, {
          key: 8,
          value: $props.total.CPC,
          precision: 2,
          config: $props.currencyConfig,
          tight: ""
        }, null, 8 /* PROPS */, ["value", "config"])) : _createCommentVNode("v-if", true), field.key === 'CVR' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 9,
          value: $props.total.CVR,
          limit: 0.1
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'organic_CVR' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 10,
          value: $props.total.organic_CVR,
          precision: 1
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'share_asin_sold' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 11,
          value: $props.total.share_asin_sold
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'days_inventory' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 12,
          value: $props.total.days_inventory,
          tight: ""
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'revenue_excl_VAT' ? (_openBlock(), _createBlock(_component_slot_with_evolution_value, {
          key: 13,
          gap: 16
        }, {
          evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
            value: $props.total[`${_ctx.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[$props.evolutionType]}`]
          }, {
            default: _withCtx(({
              color
            }) => [$props.evolutionType === 'currency' ? (_openBlock(), _createBlock(_component_currency_value, {
              key: 0,
              "show-sign": "",
              value: $props.total[`${_ctx.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[$props.evolutionType]}`],
              color: color
            }, null, 8 /* PROPS */, ["value", "color"])) : (_openBlock(), _createBlock(_component_percentage_value, {
              key: 1,
              value: $props.total[`${_ctx.evolCategory[_ctx.periodCategory]}revenue_excl_VAT_evol${_ctx.evolutionTypeKpi[$props.evolutionType]}`],
              color: color
            }, null, 8 /* PROPS */, ["value", "color"]))]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value"])]),
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $props.total.revenue_excl_VAT
          }, null, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'share_sponsored_sales' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 14,
          value: $props.total.share_sponsored_sales
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'traffic_by_asin_buy_box_percentage' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 15,
          value: $props.total.traffic_by_asin_buy_box_percentage
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'rating' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 16,
          value: $props.total.rating,
          tight: "",
          precision: 1
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'page_views' ? (_openBlock(), _createBlock(_component_slot_with_evolution_value, {
          key: 17,
          gap: 16
        }, {
          evolution: _withCtx(() => [$props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}page_views`] !== null ? (_openBlock(), _createBlock(_component_evolution_value, {
            key: 0,
            value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}page_views_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}page_views_evol`],
              color: color
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)]),
          default: _withCtx(() => [_createVNode(_component_number_value, {
            value: $props.total.page_views,
            tight: "",
            align: "left"
          }, null, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'avg_unit_price' ? (_openBlock(), _createBlock(_component_slot_with_evolution_value, {
          key: 18,
          gap: 16
        }, {
          evolution: _withCtx(() => [$props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`] !== null ? (_openBlock(), _createBlock(_component_evolution_value, {
            key: 0,
            value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}avg_unit_price_evol`],
              color: color
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)]),
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $props.total.avg_unit_price,
            precision: 2,
            align: "right"
          }, null, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'share_total_revenue' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 19,
          value: $props.total.share_total_revenue
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'total_quantity' ? (_openBlock(), _createBlock(_component_slot_with_evolution_value, {
          key: 20,
          gap: 16
        }, {
          evolution: _withCtx(() => [$props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}total_quantity_evol`] !== null ? (_openBlock(), _createBlock(_component_evolution_value, {
            key: 0,
            value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}total_quantity_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}total_quantity_evol`],
              color: color
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true)]),
          default: _withCtx(() => [_createVNode(_component_number_value, {
            value: $props.total.total_quantity,
            tight: ""
          }, null, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'ratings_total' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 21,
          value: $props.total.ratings_total,
          tight: ""
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'return_rate' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 22,
          value: $props.total.return_rate,
          precision: 1
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'lost_revenue_excl_VAT' ? (_openBlock(), _createBlock(_component_currency_value, {
          key: 23,
          value: $props.total.lost_revenue_excl_VAT
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'sponsored_asin' ? (_openBlock(), _createBlock(_component_number_value, {
          key: 24,
          value: $props.total.sponsored_asin,
          tight: ""
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), _createCommentVNode(" Profit "), field.key === 'pl_net_revenue' ? (_openBlock(), _createBlock(_component_flex_row, {
          key: 25,
          styles: {
            justifyContent: 'space-between',
            width: '100%'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $props.total?.pl_net_revenue
          }, null, 8 /* PROPS */, ["value"]), _createVNode(_component_evolution_value, {
            value: $props.total?.pl_net_revenue_evol
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: $props.total?.pl_net_revenue_evol,
              color: color,
              "show-sign": ""
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'pl_contributive_margin' ? (_openBlock(), _createBlock(_component_flex_row, {
          key: 26,
          styles: {
            justifyContent: 'space-between',
            width: '100%'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_currency_value, {
            value: $props.total?.pl_contributive_margin
          }, null, 8 /* PROPS */, ["value"]), _createVNode(_component_evolution_value, {
            value: $props.total?.pl_contributive_margin_evol
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: $props.total?.pl_contributive_margin_evol,
              color: color,
              "show-sign": ""
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'pl_amazon_commission_pc' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 27,
          value: $props.total?.pl_amazon_commission_pc
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'pl_fba_direct_pc' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 28,
          value: $props.total?.pl_fba_direct_pc
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'pl_fba_indirect_pc' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 29,
          value: $props.total?.pl_fba_indirect_pc
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'pl_refund_pc' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 30,
          value: $props.total?.pl_refund_pc
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'pl_coop_pc' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 31,
          value: $props.total?.pl_coop_pc
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'pl_cogs_pc' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 32,
          value: $props.total?.pl_cogs_pc
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'pl_other_costs_pc' ? (_openBlock(), _createBlock(_component_percentage_value, {
          key: 33,
          value: $props.total?.pl_other_costs_pc
        }, null, 8 /* PROPS */, ["value"])) : _createCommentVNode("v-if", true), field.key === 'pl_net_percentage_revenue' ? (_openBlock(), _createBlock(_component_flex_row, {
          key: 34,
          styles: {
            justifyContent: 'space-between',
            width: '100%'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: $props.total?.pl_net_percentage_revenue,
            styles: {
              justifyContent: 'flex-start'
            }
          }, null, 8 /* PROPS */, ["value"]), _createVNode(_component_evolution_value, {
            value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}pl_net_percentage_revenue_evol`]
          }, {
            default: _withCtx(({
              color
            }) => [_createVNode(_component_percentage_value, {
              value: $props.total?.[`${_ctx.evolCategory[_ctx.periodCategory]}pl_net_percentage_revenue_evol`],
              color: color,
              "show-sign": "",
              pp: ""
            }, null, 8 /* PROPS */, ["value", "color"])]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true), field.key === 'pl_advertising_costs' ? (_openBlock(), _createBlock(_component_flex_row, {
          key: 35,
          styles: {
            width: '100%',
            justifyContent: 'space-between'
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: $props.total?.pl_advertising_costs_pc,
            style: {
              width: '55px',
              textAlign: 'right'
            }
          }, null, 8 /* PROPS */, ["value"]), _createVNode(_component_currency_value, {
            value: $props.total?.pl_advertising_costs
          }, null, 8 /* PROPS */, ["value"])]),
          _: 1 /* STABLE */
        })) : _createCommentVNode("v-if", true)]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["styles", "loading"])]),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]);
  }), 128 /* KEYED_FRAGMENT */);
}