import { mapGetters, mapActions } from "vuex";
import AppTableHead from "@/components/AppTableHead";
import AppTd from "@/components/AppTd";
import AppButton from "@/components/AppButton";
import AppPageTitle from "@/components/AppPageTitle";
import ConnectionSuccess from "@/components/ConnectionSuccess";
import ConnectionFailed from "@/components/ConnectionFailed";
import { sortData } from "@/utils";
export default {
  name: "AmazonAdsAccountSelectionPage",
  emits: ["close-ads-window", "back", "cancel", "select"],
  components: {
    AppTableHead,
    AppTd,
    AppButton,
    AppPageTitle,
    ConnectionSuccess,
    ConnectionFailed
  },
  data() {
    return {
      selectLimit: 10,
      loadingData: false,
      showSuccessMessage: false,
      showFailedMessage: false,
      authorizationFail: false,
      accounts: []
    };
  },
  async mounted() {
    var is_authenticated = await this.authStatus();
    this.loadingData = true;
    this.showSuccessMessage = false;
    this.showFailedMessage = false;
    if (is_authenticated) {
      var result = await this.accountAuthorizeResultList();
      var accounts = result.filtered_profiles.length <= 0 ? result.unfiltered_profiles : result.filtered_profiles;
      this.accounts = this.createaAccountList(accounts);
      this.showFailedMessage = result.filtered_profiles.length <= 0;
      this.authorizationFail = result.unfiltered_profiles.length <= 0;
      this.loadingData = false;
      this.selectAll(false);
    }
  },
  computed: {
    ...mapGetters(["getCountry", "getCountryCode", "getCountryFlag"]),
    selectedAccounts() {
      return this.accounts.filter(item => item.isSelected);
    },
    tableFields() {
      return [{
        key: "select",
        custom: "select-checkbox",
        isSelectAll: !this.showFailedMessage
      }, {
        key: "title",
        label: "Name",
        custom: "product-wrapper"
      }, {
        key: "seller_id",
        label: "Seller Id",
        custom: "product-wrapper"
      }, {
        key: "account_id",
        label: "Account Id",
        custom: "product-wrapper"
      }, {
        key: "marketplace_id",
        label: "MarketPlace",
        custom: "product-wrapper"
      }, {
        key: "country",
        label: "Country",
        custom: "product-wrapper"
      }];
    }
  },
  methods: {
    ...mapActions(["accountAuthorizeResultSelectedList", "accountAuthorizeResultList", "authStatus"]),
    onRowSelect(item) {
      if (this.selectedAccounts.length < this.selectLimit) {
        item.isSelected ??= false;
        item.isSelected = !item.isSelected;
        this.$emit("select", this.selectedAccounts);
      }
    },
    getFlagForMarketPlace(marketplace_id) {
      const country = this.getCountry(marketplace_id);
      let country_code = this.getCountryCode(country);
      return this.getCountryFlag(country_code);
    },
    createaAccountList(data) {
      let accountListFilter = [];
      data.forEach(account => {
        let singleAccount = {
          name: account.accountInfo.name,
          seller_id: account.accountInfo.id,
          account_id: account.profileId,
          marketplace_id: account.accountInfo.marketplaceStringId
        };
        accountListFilter.push(singleAccount);
      });
      return sortData(accountListFilter, "name");
    },
    // onBack() {
    //   this.$emit("back");
    // },

    // onCancel() {
    //   this.$emit("cancel");
    // },

    async onValidate() {
      if (this.selectedAccounts.length) {
        //this.$emit("select", this.selectedAccounts);
        var result_update = await this.accountAuthorizeResultSelectedList({
          accounts: this.selectedAccounts.map(account => account.account_id)
        });
        if (result_update) {
          this.showSuccessMessage = true;
          return;
        }
        this.showFailedMessage = true;
        return;
      }
    },
    selectAll(val) {
      this.accounts.forEach(item => {
        item.isSelected ??= false;
        item.isSelected = val;
      });
      this.$emit("select", this.selectedAccounts);
    }
  }
};