import DataAndEvolutionRowValue from '@/components/profit-and-loss/DataAndEvolutionRowValue.vue';
import WrapWithPopover from '@/components/shared/WrapWithPopover.vue';
import AppIcon from '@/components/AppIcon.vue';
import FlexRow from '@/components/FlexRow.vue';
import EventTypeValue from '@/components/profit-and-loss/EventTypeValue.vue';
export default {
  name: "DataAndEvolutionValue",
  props: {
    value: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    align: {
      type: String,
      default: 'left'
    },
    fields: {
      type: Array
    },
    type: {
      type: String,
      // 'cumlative', 'normal'
      default: 'normal'
    }
  },
  components: {
    DataAndEvolutionRowValue,
    WrapWithPopover,
    AppIcon,
    FlexRow,
    EventTypeValue
  },
  emits: ['togglePercentAggregate', 'togglePercentDetails'],
  data() {
    return {
      thStyles: [],
      hideDetails: true,
      hideDetailEvents: [],
      iconMap: {
        cumlative: 'equal',
        normal: 'simple-arrow-down'
      }
    };
  },
  watch: {
    fields: {
      handler: function (val) {
        this.thStyles = val.map(field => field.thStyle);
      },
      immediate: true
    },
    value: {
      handler: function (val) {
        this.hideDetailEvents = new Array(val?.details?.length).fill(true) || [];
      },
      immediate: true
    }
  },
  computed: {
    hideEvents() {
      return index => this.hideDetailEvents[index];
    },
    hideDetailsExpr() {
      // Only `Net Revenue` has a default open details
      return this.value.pl_aggregate !== 'Net Revenue' && this.hideDetails;
    },
    getAggregateIcon() {
      if (this.type === 'cumlative') {
        return 'equal';
      } else if (!this.hideDetailsExpr) {
        return 'simple-arrow-down';
      } else {
        return 'chevron-right-small';
      }
    },
    getAggregateIconColor() {
      // When active return #423994 and not net revenue
      if (!this.hideDetailsExpr && this.value.pl_aggregate !== 'Net Revenue') {
        return '#423994';
      }
      return '#757575';
    },
    getDetailIcon() {
      return index => {
        if (this.hideDetailEvents[index]) {
          return 'chevron-right-small';
        } else {
          return 'simple-arrow-down';
        }
      };
    }
  },
  methods: {
    toggleAggregate() {
      this.hideDetails = !this.hideDetails;
      this.$emit('togglePercentAggregate');
    },
    toggleDetails(index) {
      this.hideDetailEvents[index] = !this.hideDetailEvents[index];
      this.$emit('togglePercentDetails', index);
    },
    dataTransform(value) {
      return {
        amount: {
          period1: value.currency_amount_euro,
          period2: value.currency_amount_euro_previous
        },
        evolution: value.currency_amount_evolution,
        evolution_percentage: value.currency_amount_evolution_pc
      };
    },
    renameValue(text) {
      const renameMap = {
        "Net Revenue": "Gross Merchandise Value",
        "Gross Margin": "Revenue net of Amazon commissions"
      };
      return renameMap[text] || text;
    }
  }
};