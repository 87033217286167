export class Node {
  constructor(range, value) {
    this.range = range;
    this.value = value;
    this.left = null;
    this.right = null;
  }
  contains(value) {
    return this.range[0] <= value && value < this.range[1];
  }
}
export class RangeBST {
  root;
  constructor() {
    this.root = null;
  }
  static from(input) {
    const bst = new RangeBST();
    input.forEach(([range, value]) => {
      bst.insert(range, value);
    });
    return bst;
  }
  insert(range, value) {
    this.root = this._insert(this.root, range, value);
  }
  _insert(node, range, value) {
    if (node === null) {
      return new Node(range, value);
    }
    if (range[1] < node.range[0]) {
      node.left = this._insert(node.left, range, value);
    } else {
      node.right = this._insert(node.right, range, value);
    }
    return node;
  }
  searchRange(value) {
    const results = [];
    this._searchRange(this.root, value, results);
    return results;
  }
  _searchRange(node, value, results) {
    if (node === null) return;
    let temp = node;
    while (temp) {
      if (temp.contains(value)) {
        results.push(temp.value);
      }
      if (temp.left && temp.left.range[1] >= value) {
        temp = temp.left;
      } else {
        temp = temp.right;
      }
    }
  }
}