import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-630361d6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "popover-box"
};
const _hoisted_2 = {
  class: "popover-top"
};
const _hoisted_3 = {
  class: "popover-title"
};
const _hoisted_4 = {
  class: "popover-content"
};
const _hoisted_5 = {
  class: "popover-bottom"
};
const _hoisted_6 = {
  class: "popover-numbers"
};
const _hoisted_7 = {
  class: "popover-buttons"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_popover = _resolveComponent("app-popover");
  return _openBlock(), _createBlock(_component_app_popover, {
    trigger: "manual",
    placement: "top",
    "auto-width": "",
    target: `${$props.prefix}-${$props.number}`,
    show: $props.show
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($props.title), 1 /* TEXT */), _createElementVNode("div", {
      class: "popover-close",
      onClick: _cache[0] || (_cache[0] = (...args) => $options.onClose && $options.onClose(...args))
    }, [_createVNode(_component_app_icon, {
      icon: "cross",
      size: 16,
      color: "#fff"
    })])]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString($props.number + ' of ' + $props.max), 1 /* TEXT */), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
      class: _normalizeClass(['popover-button', {
        disabled: $props.number == 1
      }]),
      onClick: _cache[1] || (_cache[1] = (...args) => $options.onPrev && $options.onPrev(...args))
    }, [_createVNode(_component_app_icon, {
      icon: "chevron-left2"
    })], 2 /* CLASS */), _createElementVNode("div", {
      class: _normalizeClass(['popover-button', {
        disabled: $props.number == $props.max
      }]),
      onClick: _cache[2] || (_cache[2] = (...args) => $options.onNext && $options.onNext(...args))
    }, [_createVNode(_component_app_icon, {
      icon: "chevron-right2"
    })], 2 /* CLASS */)])])])]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["target", "show"]);
}