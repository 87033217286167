import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-41589742"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "more-filters"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_campaign_filter = _resolveComponent("campaign-filter");
  const _component_match_type_filter = _resolveComponent("match-type-filter");
  const _component_targeting_filter = _resolveComponent("targeting-filter");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_flex_row, {
    gap: 16
  }, {
    default: _withCtx(() => [_createVNode(_component_campaign_filter, {
      campaigns: $options.campaigns,
      storeRoot: "ppc",
      onApply: $options.applyCampaigns
    }, null, 8 /* PROPS */, ["campaigns", "onApply"]), _createVNode(_component_match_type_filter, {
      match_types: $options.match_types,
      storeRoot: "ppc",
      onApply: $options.applyMatchTypes
    }, null, 8 /* PROPS */, ["match_types", "onApply"]), _createVNode(_component_targeting_filter, {
      targetings: $options.targetings,
      storeRoot: "ppc",
      onApply: $options.applyTargetings
    }, null, 8 /* PROPS */, ["targetings", "onApply"])]),
    _: 1 /* STABLE */
  })]);
}