import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d36dd92a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "accounts-table-error"
};
const _hoisted_2 = {
  class: "icon-and-error-title"
};
const _hoisted_3 = {
  class: "error-title"
};
const _hoisted_4 = {
  class: "error-detail"
};
const _hoisted_5 = {
  class: "support"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_page_title = _resolveComponent("app-page-title");
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_button = _resolveComponent("app-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_app_page_title, {
    title: 'Choose Amazon Ads Accounts',
    action: "Validate",
    onAction: _ctx.onValidate,
    visibleButton: false
  }, null, 8 /* PROPS */, ["onAction"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_app_icon, {
    icon: "warning2",
    color: "#D1D1D1"
  }), _createElementVNode("p", _hoisted_3, _toDisplayString($data.errorTitle), 1 /* TEXT */)]), _createElementVNode("p", _hoisted_4, _toDisplayString($data.errorDetail), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("p", null, _toDisplayString($data.supportText), 1 /* TEXT */), _createVNode(_component_app_button, {
    class: "support-button",
    variant: "outline-primary",
    onClick: $options.onClick,
    disabled: false
  }, {
    default: _withCtx(() => [_createVNode(_component_app_icon, {
      icon: "cube"
    }), _createTextVNode(" Contact Support ")]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"])])]);
}