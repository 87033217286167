import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    width: '100%'
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: $props.gap,
    styles: {
      width: '100%',
      flex: '1 0 0'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default")]), _renderSlot(_ctx.$slots, "evolution")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["gap"]);
}