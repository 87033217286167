import AppIcon from "@/components/AppIcon.vue";
import { TREND_DIRECTION_TYPE } from "@/utils/constants";
export default {
  name: "TrendElement",
  components: {
    AppIcon
  },
  props: {
    direction: {
      type: String,
      // TREND_DIRECTION_TYPE { UP, DOWN, OFF}
      required: true
    },
    color: {
      type: String,
      required: false
    },
    size: {
      type: Number,
      required: false,
      default: 20
    }
  },
  data() {
    return {
      TREND_DIRECTION_TYPE
    };
  },
  computed: {
    computedTrendDirection() {
      const direction = this.direction === TREND_DIRECTION_TYPE.DOWN ? {
        transform: "rotateX(180deg)"
      } : {};
      return {
        ...direction,
        width: `${this.size}px`,
        height: `${this.size}px`,
        display: "flex"
      };
    }
  }
};