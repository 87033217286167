import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "parent-div-account-info"
};
const _hoisted_2 = {
  class: "d-flex flex-row align-items-center justify-content-end gap-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_input = _resolveComponent("app-input");
  const _component_b_col = _resolveComponent("b-col");
  const _component_app_select = _resolveComponent("app-select");
  const _component_b_row = _resolveComponent("b-row");
  const _component_app_button = _resolveComponent("app-button");
  const _component_app_card = _resolveComponent("app-card");
  return _openBlock(), _createBlock(_component_app_card, {
    loading: $data.dataLoading,
    title: "",
    scrollable: false
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_b_row, {
      class: "input-row"
    }, {
      default: _withCtx(() => [_createCommentVNode(" First row with three columns for account name, currency and unit "), _createVNode(_component_b_col, {
        cols: "4"
      }, {
        default: _withCtx(() => [_createVNode(_component_app_input, {
          modelValue: $data.form.workspace_name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.form.workspace_name = $event),
          readonly: !$data.isEdit,
          validateRule: "array",
          type: "text",
          label: "Account Name",
          placeholder: "Please enter new workspace name",
          required: "",
          onSubmit: $options.onSubmit
        }, null, 8 /* PROPS */, ["modelValue", "readonly", "onSubmit"])]),
        _: 1 /* STABLE */
      }), _createVNode(_component_b_col, {
        cols: "4"
      }, {
        default: _withCtx(() => [_createVNode(_component_app_select, {
          modelValue: $data.form.workspace_currency,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.form.workspace_currency = $event),
          readonly: !$data.isEdit,
          validateRule: "array",
          type: "text",
          label: "Currency",
          options: $data.currencies,
          required: "",
          onSubmit: $options.onSubmit,
          class: "my-select"
        }, null, 8 /* PROPS */, ["modelValue", "readonly", "options", "onSubmit"])]),
        _: 1 /* STABLE */
      }), _createVNode(_component_b_col, {
        cols: "4"
      }, {
        default: _withCtx(() => [_createVNode(_component_app_select, {
          modelValue: $data.form.workspace_unit_type,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.form.workspace_unit_type = $event),
          readonly: !$data.isEdit,
          validateRule: "array",
          label: "Units",
          options: $data.unit_types,
          required: "",
          onSubmit: $options.onSubmit,
          class: "my-select"
        }, null, 8 /* PROPS */, ["modelValue", "readonly", "options", "onSubmit"])]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    }), _createVNode(_component_b_row, {
      class: "input-row"
    }, {
      default: _withCtx(() => [_createVNode(_component_b_col, {
        cols: "4"
      }, {
        default: _withCtx(() => [_createVNode(_component_app_select, {
          modelValue: $data.form.workspace_week_type,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.form.workspace_week_type = $event),
          readonly: !$data.isEdit,
          validateRule: "array",
          label: "Week starts from",
          options: $data.week_types,
          required: "",
          onSubmit: $options.onSubmit,
          class: "my-select"
        }, null, 8 /* PROPS */, ["modelValue", "readonly", "options", "onSubmit"])]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    }), _createVNode(_component_b_row, {
      class: "button-row"
    }, {
      default: _withCtx(() => [_createCommentVNode(" Second row for buttons "), _createVNode(_component_b_col, null, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [!$data.isEdit ? (_openBlock(), _createBlock(_component_app_button, {
          key: 0,
          variant: "outline-primary",
          onClick: $options.onEdit
        }, {
          default: _withCtx(() => [_createTextVNode(" Edit ")]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true), $data.isEdit ? (_openBlock(), _createBlock(_component_app_button, {
          key: 1,
          variant: "outline-primary",
          onClick: $options.onCancel
        }, {
          default: _withCtx(() => [_createTextVNode(" Cancel ")]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])) : _createCommentVNode("v-if", true), $data.isEdit ? (_openBlock(), _createBlock(_component_app_button, {
          key: 2,
          loading: $data.loading,
          onClick: $options.onSubmit
        }, {
          default: _withCtx(() => [_createTextVNode(" Save ")]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["loading", "onClick"])) : _createCommentVNode("v-if", true)])]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    })])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["loading"]);
}