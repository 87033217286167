import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "padding": "20px 24px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_input = _resolveComponent("app-input");
  const _component_app_select = _resolveComponent("app-select");
  const _component_b_col = _resolveComponent("b-col");
  const _component_b_row = _resolveComponent("b-row");
  const _component_app_input_range = _resolveComponent("app-input-range");
  const _component_app_form = _resolveComponent("app-form");
  const _component_custom_side_bar_app_modal = _resolveComponent("custom-side-bar-app-modal");
  return _openBlock(), _createBlock(_component_custom_side_bar_app_modal, {
    modelValue: $options.modal,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $options.modal = $event),
    "ok-loading": $data.loading,
    title: "Add Account",
    "ok-title": "Save",
    onOk: $options.onSubmit
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_app_form, {
      novalidate: "",
      onSubmit: $options.onSubmit
    }, {
      default: _withCtx(() => [_createVNode(_component_app_input, {
        modelValue: $data.form.workspace_name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.form.workspace_name = $event),
        validateRule: "name",
        type: "text",
        label: "Account Name",
        placeholder: "Please enter account name",
        required: "",
        onSubmit: $options.onSubmit
      }, null, 8 /* PROPS */, ["modelValue", "onSubmit"]), _createVNode(_component_b_row, {
        class: "settings-row"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "5"
        }, {
          default: _withCtx(() => [_createVNode(_component_app_select, {
            modelValue: $data.form.currency,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.form.currency = $event),
            validateRule: "array",
            type: "text",
            label: "Currency",
            options: $data.currencies,
            required: "",
            onSubmit: $options.onSubmit,
            class: "select"
          }, null, 8 /* PROPS */, ["modelValue", "options", "onSubmit"])]),
          _: 1 /* STABLE */
        }), _createVNode(_component_b_col, {
          cols: "5"
        }, {
          default: _withCtx(() => [_createVNode(_component_app_select, {
            modelValue: $data.form.unit_type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.form.unit_type = $event),
            validateRule: "array",
            label: "Units",
            options: $data.unit_types,
            required: "",
            onSubmit: $options.onSubmit,
            class: "select"
          }, null, 8 /* PROPS */, ["modelValue", "options", "onSubmit"])]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      }), _createVNode(_component_b_row, {
        class: "settings-row"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_col, {
          cols: "5"
        }, {
          default: _withCtx(() => [_createVNode(_component_app_select, {
            modelValue: $data.form.week_type,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.form.week_type = $event),
            validateRule: "array",
            type: "text",
            label: "Week starts from",
            options: $data.week_types,
            required: "",
            onSubmit: $options.onSubmit,
            class: "select"
          }, null, 8 /* PROPS */, ["modelValue", "options", "onSubmit"])]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      }), _createVNode(_component_app_input_range, {
        modelValue: $data.form.members,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.form.members = $event),
        validateRule: "email",
        type: "email",
        label: "Members",
        placeholder: "Please enter an email",
        onSubmit: $options.onSubmit
      }, null, 8 /* PROPS */, ["modelValue", "onSubmit"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onSubmit"])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "ok-loading", "onOk"]);
}