import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_th = _resolveComponent("b-th");
  const _component_b_tr = _resolveComponent("b-tr");
  const _component_b_thead = _resolveComponent("b-thead");
  const _component_b_td = _resolveComponent("b-td");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_b_tbody = _resolveComponent("b-tbody");
  const _component_b_table_simple = _resolveComponent("b-table-simple");
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    class: "bid-simulator"
  }, {
    default: _withCtx(() => [_createVNode(_component_b_table_simple, null, {
      default: _withCtx(() => [_createVNode(_component_b_thead, {
        class: "table-header"
      }, {
        default: _withCtx(() => [_createVNode(_component_b_tr, {
          class: "header",
          style: {
            "margin-top": "0"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_b_th, {
            class: "title",
            style: _normalizeStyle(['border-right:1px solid #eee'])
          }, {
            default: _withCtx(() => [_createTextVNode("ILLUSTRATIVE TARGETING")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            class: "title",
            style: _normalizeStyle(['border-right:1px solid #eee'])
          }, {
            default: _withCtx(() => [_createTextVNode("PRODUCT MARGIN")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            class: "title",
            style: _normalizeStyle(['border-right:1px solid #eee'])
          }, {
            default: _withCtx(() => [_createTextVNode("ADS CVR")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            class: "title",
            style: _normalizeStyle(['border-right:1px solid #eee'])
          }, {
            default: _withCtx(() => [_createTextVNode("AVG. MARGIN PER CLICK")]),
            _: 1 /* STABLE */
          }), _createVNode(_component_b_th, {
            class: "title"
          }, {
            default: _withCtx(() => [_createTextVNode("COMPUTED MAX BID")]),
            _: 1 /* STABLE */
          })]),
          _: 1 /* STABLE */
        })]),
        _: 1 /* STABLE */
      }), _createVNode(_component_b_tbody, {
        class: "table-body"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.targeting_types, key => {
          return _openBlock(), _createBlock(_component_b_tr, {
            class: _normalizeClass(["targeting-field", {
              [$options.getRowStyle(key).colorClass]: true,
              [$options.getRowStyle(key).focusClass]: $props.focusTargeting == $data.key_mapping[key]
            }]),
            style: _normalizeStyle($options.getRowStyle(key))
          }, {
            default: _withCtx(() => [_createVNode(_component_b_td, {
              class: "target-info has-border"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString($options.getTargeting(key)), 1 /* TEXT */)]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, {
              class: "target-info has-border"
            }, {
              default: _withCtx(() => [_createVNode(_component_currency_value, {
                value: $options.getProductMargin(key),
                currency: $props.currency,
                precision: $data.precision,
                allowPrecisionModification: false
              }, null, 8 /* PROPS */, ["value", "currency", "precision"])]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, {
              class: "target-info has-border"
            }, {
              default: _withCtx(() => [_createVNode(_component_percentage_value, {
                value: $options.getCvr(key)
              }, null, 8 /* PROPS */, ["value"])]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, {
              class: "target-info has-border"
            }, {
              default: _withCtx(() => [_createVNode(_component_currency_value, {
                value: $options.avgMargin(key),
                currency: $props.currency,
                precision: $data.precision,
                allowPrecisionModification: false
              }, null, 8 /* PROPS */, ["value", "currency", "precision"])]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */), _createVNode(_component_b_td, {
              class: "target-info"
            }, {
              default: _withCtx(() => [_createVNode(_component_currency_value, {
                value: $options.computedMaxBid(key),
                currency: $props.currency,
                precision: $data.precision,
                allowPrecisionModification: false
              }, null, 8 /* PROPS */, ["value", "currency", "precision"])]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["style", "class"]);
        }), 256 /* UNKEYED_FRAGMENT */))]),
        _: 1 /* STABLE */
      })]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  });
}