import AnnoncementIcon from "@/components/icons/AnnoncementIcon.vue";
import KeySettingsIcon from "@/components/icons/KeySettingsIcon.vue";
export default {
  name: 'CampaignIdCount',
  components: {
    AnnoncementIcon,
    KeySettingsIcon
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'auto'
    }
  }
};