import AppTabs from '@/components/AppTabs';
import AppCard from '@/components/AppCard';
import AlertsChart from '@/components/AlertsChart';
import AlertsList from '@/components/AlertsList';
export default {
  name: 'AlertsContenet',
  emits: ['select', 'selectId'],
  components: {
    AppTabs,
    AppCard,
    AlertsChart,
    AlertsList
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedRowId: {
      type: String,
      default: null
    },
    selectedLevel: {
      type: String,
      default: null
    },
    selectedId: {
      type: [String, Number],
      default: null
    },
    isColumn: {
      type: Boolean,
      default: false
    },
    expandData: {
      type: Object,
      default: () => {
        return {
          rowId: null,
          tab: null
        };
      }
    }
  },
  data() {
    return {
      tab: 'overview',
      tabs: [{
        value: 'overview',
        text: 'Overview',
        canSwitchTab: this.canSwitchTab
      }, {
        value: '1_URGENCY',
        text: 'Urgent',
        canSwitchTab: this.canSwitchTab,
        prepend: {
          value: 24,
          color: '#DB333B',
          back: '#FFD7D9'
        }
      }, {
        value: '2_ALERT',
        text: 'Alert',
        canSwitchTab: this.canSwitchTab,
        prepend: {
          value: 0,
          color: '#B75B15',
          back: '#FFDBC2'
        }
      }, {
        value: '3_WARNING',
        text: 'Warning',
        canSwitchTab: this.canSwitchTab,
        prepend: {
          value: 0,
          color: '#8F710A',
          back: '#FAEBB7'
        }
      }],
      expandedIds: []
    };
  },
  computed: {
    readyTabs() {
      const result = this.tabs.map(tab => {
        if (tab.value == 'overview') {
          return tab;
        }
        return {
          ...tab,
          prepend: {
            ...tab.prepend,
            value: this.items.filter(item => item.alerting_category === tab.value).length
          }
        };
      });
      return result;
    },
    alertsSellers() {
      const result = this.items.reduce((acc, value) => {
        let seller = acc.find(item => item.id === value.seller_id);
        if (!seller) {
          seller = {
            id: value.seller_id,
            title: value.seller_name,
            alerts: {
              '1_URGENCY': 0,
              '2_ALERT': 0,
              '3_WARNING': 0,
              total: 0
            }
          };
        }
        seller.alerts[value.alerting_category]++;
        seller.alerts.total++;
        return [...acc.filter(item => item.id !== value.seller_id), seller];
      }, []);
      result.sort((a, b) => b.alerts.total - a.alerts.total);
      return result;
    },
    alertsCategories() {
      const result = this.items.reduce((acc, value) => {
        let category = acc.find(item => item.id === value.alerting_type);
        if (!category) {
          category = {
            id: value.alerting_type,
            title: value.alerting_type,
            alerts: {
              '1_URGENCY': 0,
              '2_ALERT': 0,
              '3_WARNING': 0,
              total: 0
            }
          };
        }
        category.alerts[value.alerting_category]++;
        category.alerts.total++;
        return [...acc.filter(item => item.id !== value.alerting_type), category];
      }, []);
      result.sort((a, b) => b.alerts.total - a.alerts.total);
      return result;
    }
  },
  watch: {
    tab(val) {
      if (val != 'overview') {
        this.onToggleSelect({
          id: null,
          level: null,
          type: null
        });
      }
    },
    expandData(val) {
      if (val.tab) {
        this.tab = val.tab;
        if (val.rowId) {
          this.expandedIds = [val.rowId];
        }
      }
    }
  },
  methods: {
    onToggleSelect(data) {
      this.$emit('select', data);
    },
    onToggleId(id) {
      this.$emit('selectId', id);
    },
    canSwitchTab() {
      return true;
    }
  }
};