export default {
  name: 'AppCircleProgress',
  props: {
    size: {
      type: Number,
      default: 48
    },
    stroke: {
      type: Number,
      default: 5
    },
    value: {
      type: Number,
      default: 0
    },
    maxValue: {
      type: Number,
      default: 6
    },
    maxScore: {
      type: Number,
      default: 10
    },
    font: {
      type: Number,
      default: 19
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      colors: ['#F24C3D', '#FFC436', '#5D9C59', '#C9CFD4']
    };
  },
  computed: {
    valuePercent() {
      return this.maxValue > 0 ? Math.floor(this.value * 100 / this.maxValue) / 100 : 0;
    },
    offsets() {
      return [0, 0.33, 0.66, this.valuePercent];
    },
    lengths() {
      const result = [];
      for (let i = 0; i < this.offsets.length - 1; i++) {
        result.push(this.valuePercent <= 0.33 + this.offsets[i] ? this.valuePercent - this.offsets[i] <= 0 ? 0 : this.valuePercent - this.offsets[i] : i == 2 ? 0.34 : 0.33);
      }
      result.push(1 - this.valuePercent);
      return result;
    },
    score() {
      return Math.floor(this.maxScore * this.valuePercent);
    }
  },
  mounted() {
    this.generate();
  },
  watch: {
    value() {
      this.generate();
    }
  },
  methods: {
    generate() {
      const canvas = this.$refs.progress;
      const ctx = canvas.getContext('2d');
      const x = canvas.width / 2;
      const y = canvas.height / 2;
      const strokeWidth = this.stroke;
      const radius = (this.size - this.stroke) / 2;
      for (let i = 0; i < this.colors.length; i++) {
        const color = this.getColor(i);
        const offset = this.offsets[i];
        const length = this.lengths[i];
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.lineWidth = strokeWidth;
        ctx.arc(x, y, radius, -0.5 * Math.PI + 2 * offset * Math.PI, (-0.5 + 2 * offset + 2 * length) * Math.PI);
        ctx.stroke();
      }
    },
    getColor(index) {
      if (this.simple && index != 3) {
        if (this.valuePercent <= 0.33) {
          return this.colors[0];
        }
        if (this.valuePercent > 0.33 && this.valuePercent <= 0.66) {
          return this.colors[1];
        }
        if (this.valuePercent > 0.66) {
          return this.colors[2];
        }
      }
      return this.colors[index];
    }
  }
};