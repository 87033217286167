import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "campaign-id-count wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_annoncement_icon = _resolveComponent("annoncement-icon");
  const _component_key_settings_icon = _resolveComponent("key-settings-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['count', {
      type: $props.type
    }])
  }, _toDisplayString($props.count), 3 /* TEXT, CLASS */), _createVNode(_component_annoncement_icon, {
    color: "#2172E1"
  }), _createVNode(_component_key_settings_icon, {
    class: "settings",
    color: "#2172E1"
  })]);
}