import moment from 'moment';
import DatePickerMonthes from '@/components/DatePickerMonthes';
import DatePickerDays from '@/components/DatePickerDays';
export default {
  name: 'DatePickerCalendar',
  emits: ['custom-date', 'hover-date', 'change-date', 'switch-to-custom', 'calendar-is-visible'],
  expose: ['autoScrollToDate', 'scrollTo'],
  components: {
    DatePickerMonthes,
    DatePickerDays
  },
  props: {
    date: {
      type: Object,
      default: () => {}
    },
    range: {
      type: Object,
      default: () => {}
    },
    compare: {
      type: Object,
      default: () => {}
    },
    isRangeCustom: {
      type: Boolean,
      default: false
    },
    isCompareCustom: {
      type: Boolean,
      default: false
    },
    hoverDate: {
      type: Object,
      default: () => {}
    },
    sundayFirst: {
      type: Boolean,
      default: true
    },
    monthList: {
      type: Array,
      default: () => [{
        date: "2024-01-01"
      }]
    },
    state: {
      type: Object
    }
  },
  data() {
    return {
      scrollDate: null,
      isCalendarScrollVisible: false
    };
  },
  computed: {
    monthDate() {
      if (this.scrollDate) {
        return this.scrollDate;
      }
      return this.date;
    }
  },
  methods: {
    scrollTo(id, behavior = "smooth") {
      if (!id) return;
      this.$nextTick(() => {
        const el = document.getElementById(id);
        if (el) {
          // Get the element's parent;
          const elementOffset = el.offsetTop - this.$refs.scroll.offsetTop;
          this.$refs.scroll.scroll({
            behavior,
            top: elementOffset
          });
        }
      });
    },
    onCustomDate(result) {
      this.$emit('custom-date', result);
      this.scrollDate = null;
    },
    onHoverDate(date) {
      this.$emit('hover-date', date);
    },
    onMonthChange(type) {
      const date = type == 'next' ? moment(this.monthDate).add(1, 'month') : moment(this.monthDate).subtract(1, 'month');
      this.$emit('change-date', date);
      this.scrollDate = null;
    },
    onSwitchToCustom(date) {
      this.$emit('switch-to-custom', date);
      this.scrollDate = null;
    },
    onWheel() {
      const scrollTop = this.$refs.scroll.scrollTop;
      const clientHeight = this.$refs.scroll.clientHeight;
      const monthes = this.$refs.scroll.querySelectorAll('.calendar-grid-month');
      for (const month of monthes) {
        const top = month.offsetTop;
        if (top >= scrollTop && top <= scrollTop + clientHeight) {
          const m = month.dataset.month;
          const y = month.dataset.year;
          const date = moment([y, m, 1]).add(1, 'month');
          this.scrollDate = date;
          return;
        }
      }
    },
    autoScrollToDate() {
      // this.$nextTick(() => {
      //   if (this.$refs.scroll) {
      //     // Auto scroll to range's start date (monthyyear)
      //     const { start } = this.range;

      //     // this.scrollTo(getElementById(start))
      //   }
      // })
    }
  }
};