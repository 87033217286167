import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_value_element = _resolveComponent("value-element");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    align: $props.align,
    styles: $options.computedStyle,
    loading: $props.loading,
    tight: $props.tight
  }, {
    default: _withCtx(() => [_createVNode(_component_value_element, {
      value: $options.formattedValue,
      style: _normalizeStyle($options.computedStyle)
    }, null, 8 /* PROPS */, ["value", "style"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["align", "styles", "loading", "tight"]);
}