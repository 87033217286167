import { truncateString, hasNoDecimals, getFormattedCurrency, getFormattedDecimal, getFormattedPercentage } from '@/utils';
export default {
  mounted() {
    if (this.isCaseColor) this.getCaseIntensity();
  },
  data() {
    return {
      currency: 'EUR'
    };
  },
  props: {
    amount: {
      type: [Number, String],
      default: 0
    },
    color: String,
    isPercentage: {
      type: Boolean,
      default: false
    },
    max: Number,
    min: Number,
    noBar: {
      type: Boolean,
      default: false
    },
    isCurrency: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: 0
    },
    isEvolution: {
      type: Boolean,
      default: false
    },
    isString: {
      type: Boolean,
      default: false
    },
    truncateLength: {
      type: Number,
      default: 15
    },
    alignLeft: {
      type: Boolean,
      default: false
    },
    isDecimal: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    digitSeparator: {
      type: String,
      default: "."
    },
    decimalThreshold: {
      type: Number,
      default: 0
    },
    isBoolCase: {
      type: Boolean,
      default: false
    },
    showCurrencySign: {
      type: Boolean,
      default: true
    },
    rowCurrency: {
      type: String,
      default: "EUR"
    },
    alignCenter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    negative() {
      return this.amount < 0;
    },
    barWidth() {
      if (this.min >= 0 && this.max >= 0) {
        const widthPercentage = this.positiveNormalized();
        return {
          width: `${widthPercentage}%`,
          backgroundColor: this.color
        };
      } else {
        const widthPercentage = this.negativeNormalized();
        return {
          width: `${widthPercentage}%`,
          backgroundColor: this.color
        };
      }
    },
    formattedAmount() {
      if (this.isString) {
        return this.truncateString(this.amount, this.truncateLength);
      }
      if (this.amount === null) {
        return '-';
      }
      if (this.isPercentage) {
        return this.formatPercentage(this.amount);
      }
      if (this.isDecimal) {
        return this.formatDecimal(this.amount);
      }
      if (this.isCurrency) {
        return this.formatCurrency(this.amount);
      }
      return this.amount.toLocaleString('fr-FR');
    },
    udpateColumns() {
      return this.noBar ? "1fr" : "1fr 1fr";
    },
    valueTitle() {
      if (!this.showTitle) {
        return null;
      }
      if (this.isString) return this.amount;
      if (this.amount === null) return '';
      return this.isCurrency ? this.formatCurrency(this.amount, true) : this.amount.toLocaleString('fr-FR');
    },
    shouldNotShowBarWrapper() {
      return !(this.isString || this.noBar || this.isString && this.noBar);
    }
  },
  methods: {
    truncateString,
    hasNoDecimals,
    getFormattedCurrency,
    getFormattedDecimal,
    getFormattedPercentage,
    positiveNormalized() {
      const absAmount = Math.abs(parseFloat(this.amount));
      const normalizedAmount = this.max !== 0 ? absAmount / Math.abs(this.max) : 0;
      return (normalizedAmount * 100).toFixed(2);
    },
    negativeNormalized() {
      const absAmount = Math.abs(parseFloat(this.amount));
      const normalizedAmount = this.max !== 0 ? absAmount / Math.abs(Math.abs(this.min)) : 0;
      return (normalizedAmount * 100).toFixed(2);
    },
    formatCurrency(amount, full) {
      return getFormattedCurrency(amount, full, this.rowCurrency, this.precision, this.showCurrencySign, false);
    },
    formatDecimal(amount) {
      return getFormattedDecimal(amount, this.precision);
    },
    formatPercentage(amount) {
      return getFormattedPercentage(amount, this.precision);
    },
    getCaseIntensity() {
      const intensity = this.toHex(this.positiveNormalized());
      return this.setCaseColorIntensity(intensity);
    }
  }
};