import CustomInput from "../shared/CustomInput.vue";
import FilterItemSubSection from "@/components/filters/FilterItemSubSection.vue";
import SelectedItems from "@/components/filters/SelectedItems.vue";
import FilterCounter from "@/components/filters/FilterCounter.vue";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import DropdownBody from "@/components/shared/DropdownBody.vue";
export default {
  name: "FilterItem",
  mixins: [ValidateMixin],
  emits: ["select", "deselect", "search", "clear", "triggerRefresh"],
  props: {
    filter: {
      type: Object,
      default: () => ({
        name: "",
        titleName: "",
        values: [],
        showIcon: false,
        showSearch: false,
        searchPlaceholder: "",
        selected: []
      }),
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: true
    },
    selected: {
      type: Array,
      default: () => [],
      required: false
    },
    filterTitle: {
      type: String,
      default: "",
      required: false
    },
    validateRule: {
      type: String,
      default: "none"
    },
    validateErrorMessage: {
      type: String,
      default: "Required: select at least one"
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      id: "input-" + Math.floor(Math.random() * 900000) + 100000,
      filterClicked: false,
      showCounter: false,
      validate: [{
        variable: "selected",
        rule: this.validateRule
      }],
      selectedSeller: "",
      searchValue: ""
    };
  },
  methods: {
    handleRefresh() {
      this.$emit("triggerRefresh");
      this.filterClicked = true;
      this.showCounter = true;
    },
    handleDeselect(item) {
      this.$emit("deselect", item);
      this.filterClicked = false;
    },
    handleClear() {
      this.$emit("clear");
      this.clear();
      this.filterClicked = false;
      this.showCounter = false;
    },
    handleSelect({
      item,
      only
    }) {
      this.$emit("select", {
        item,
        only
      });
      this.filterClicked = false;
    },
    innerHandleSearch({
      value,
      refresh
    }) {
      this.searchValue = value;
      value = value.split(",").filter(element => element !== false);
      this.$emit("search", {
        value,
        refresh
      });
    },
    clear() {
      this.searchValue = "";
      this.$emit("search", {
        value: [],
        refresh: true
      });
    },
    handleHidden() {
      if (this.filter.selected.length && !this.filterClicked) {
        this.handleRefresh();
      }
    }
  },
  components: {
    FilterItemSubSection,
    SelectedItems,
    CustomInput,
    FilterCounter,
    DropdownBody
  },
  computed: {
    selectedState() {
      return !this.mValidateMessage("selected") ? null : false;
    },
    selectedCount() {
      return this.filter.selected.length;
    }
  }
};