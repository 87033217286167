import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-44ea428b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "count"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['section-title', {
      selected: $props.selected
    }])
  }, [$props.searching || $props.selected ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.count), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString($options.formattedTitle), 1 /* TEXT */)], 2 /* CLASS */);
}