import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TextValue = _resolveComponent("TextValue");
  const _component_HugValue = _resolveComponent("HugValue");
  return _openBlock(), _createBlock(_component_HugValue, {
    styles: {
      padding: '4px 8px',
      borderRadius: '4px',
      background: '#DDE1E6'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_TextValue, {
      value: $options.formattedValue,
      size: 12,
      color: "#71777F",
      styles: {
        lineHeight: '12px',
        fontWeight: 500
      }
    }, null, 8 /* PROPS */, ["value"])]),
    _: 1 /* STABLE */
  });
}