import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3a24f970"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "label-text",
  style: {
    "flex": "1",
    "white-space": "nowrap"
  }
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1,
  class: "expand-img-header"
};
const _hoisted_5 = {
  class: "img-list-lable"
};
const _hoisted_6 = {
  class: "img-variant-container"
};
const _hoisted_7 = {
  class: "img-variant"
};
const _hoisted_8 = {
  key: 0,
  class: "sort"
};
const _hoisted_9 = {
  key: 3,
  class: "icon-placeholder"
};
const _hoisted_10 = {
  key: 1,
  class: "expandable"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_b_th = _resolveComponent("b-th");
  const _component_b_tr = _resolveComponent("b-tr");
  const _component_b_thead = _resolveComponent("b-thead");
  return $props.fields.length ? (_openBlock(), _createBlock(_component_b_thead, {
    key: 0
  }, {
    default: _withCtx(() => [_createVNode(_component_b_tr, null, {
      default: _withCtx(() => [_ctx.field?.showHeader ?? true ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList($props.fields, (field, index) => {
        return _openBlock(), _createBlock(_component_b_th, {
          id: "content-table-header",
          key: index,
          colspan: field.colspan ? field.colspan : 1,
          "sticky-column": field.stickyColumn ? field.stickyColumn : false,
          style: _normalizeStyle({
            right: field.right,
            left: field.left
          })
        }, {
          default: _withCtx(() => [_createElementVNode("div", {
            class: _normalizeClass(['th', field.align, field.valign, {
              sortable: field.sortable
            }, {
              active: field.sortable && $props.sortBy == field.key
            }]),
            onClick: $event => $options.onSort(field),
            style: _normalizeStyle(field.key === 'amz_score' || field.key === 'draft_score' ? 'justify-content:center; display:flex; align-items: center' : field.key === 'push_status' ? 'display: flex; align-items: center; width: 63px' : 'display: flex; align-items: center')
          }, [_createElementVNode("div", _hoisted_2, [field.key === 'amz_score' ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 0,
            icon: "amazon-logo"
          })) : _createCommentVNode("v-if", true), field.key === 'draft_score' ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 1,
            icon: "omny-score"
          })) : _createCommentVNode("v-if", true), field.key === 'push_status' ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 2,
            icon: "push-status"
          })) : _createCommentVNode("v-if", true), field.key == 'image_list' ? _renderSlot(_ctx.$slots, field.key, {
            key: 3
          }, () => [field.isCollapsed ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(field.label), 1 /* TEXT */)) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(field.label), 1 /* TEXT */), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.variantList, lable => {
            return _openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(lable), 1 /* TEXT */);
          }), 256 /* UNKEYED_FRAGMENT */))])]))], true) : _createCommentVNode("v-if", true), field.key !== 'amz_score' && field.key !== 'draft_score' && field.key !== 'push_status' && field.key !== 'image_list' ? _renderSlot(_ctx.$slots, field.key, {
            key: 4
          }, () => [_createTextVNode(_toDisplayString(field.label), 1 /* TEXT */)], true) : _createCommentVNode("v-if", true)]), field.sortable ? (_openBlock(), _createElementBlock("div", _hoisted_8, [field.sortable && field.key !== 'status' && $props.sortBy == field.key && $props.sortDesc ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            class: "icon active-icon",
            icon: "fa-solid fa-chevron-down"
          })) : field.sortable && field.key !== 'status' && $props.sortBy == field.key ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 1,
            class: "icon inactive-icon",
            icon: "fa-solid fa-chevron-up"
          })) : field.sortable && field.key !== 'status' ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 2,
            class: "icon inactive-icon",
            icon: "fa-solid fa-chevron-up"
          })) : (_openBlock(), _createElementBlock("div", _hoisted_9))])) : _createCommentVNode("v-if", true), field.expandable ? (_openBlock(), _createElementBlock("div", _hoisted_10, [field.isCollapsed ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 0,
            icon: "chevron-expand-horizontal",
            onClick: $options.expandImageList
          }, null, 8 /* PROPS */, ["onClick"])) : (_openBlock(), _createBlock(_component_app_icon, {
            key: 1,
            icon: "chevron-collapse-horizontal",
            onClick: $options.collapseImageList
          }, null, 8 /* PROPS */, ["onClick"]))])) : _createCommentVNode("v-if", true)], 14 /* CLASS, STYLE, PROPS */, _hoisted_1)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["colspan", "sticky-column", "style"]);
      }), 128 /* KEYED_FRAGMENT */)) : _createCommentVNode("v-if", true)]),
      _: 3 /* FORWARDED */
    })]),
    _: 3 /* FORWARDED */
  })) : _createCommentVNode("v-if", true);
}