export default {
  name: "WrapWithPopover",
  props: {
    target: {
      type: String
    },
    triggers: {
      type: String,
      default: "hover focus"
    },
    placement: {
      type: String,
      default: "top"
    },
    container: {
      type: String,
      default: "body"
    },
    strategy: {
      type: String,
      default: "absolute"
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: String
    },
    padding: {
      type: String
    },
    customClass: {
      type: String
    },
    boundary: {
      type: String
    }
  }
};