import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_flex_row = _resolveComponent("flex-row");
  return _openBlock(), _createBlock(_component_flex_row, {
    gap: $props.gap,
    align: $props.align
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "value"), ![null, undefined].includes($props.value) ? _renderSlot(_ctx.$slots, "indicator", {
      key: 0
    }) : _createCommentVNode("v-if", true)]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["gap", "align"]);
}