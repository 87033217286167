import AppCircleProgress from '@/components/AppCircleProgress';
import AppIcon from '@/components/AppIcon';
export default {
  name: 'EditPageScore',
  components: {
    AppCircleProgress,
    AppIcon
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    validate: {
      type: Array,
      default: () => []
    },
    wide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModalOpen: false
    };
  },
  computed: {
    successRules() {
      var success_rules = 0;
      this.validate.forEach(rule => {
        success_rules += rule.score * rule.coefficient;
      });
      return success_rules;
    },
    allRules() {
      var all_rules = 0;
      this.validate.forEach(rule => {
        all_rules += 10 * rule.coefficient;
      });
      return all_rules;
    }
  },
  methods: {
    onModalToggle() {
      this.isModalOpen = !this.isModalOpen;
    }
  }
};