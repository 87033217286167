export default {
  name: "DotElement",
  props: {
    color: {
      type: String,
      required: true,
      default: "#312783"
    },
    size: {
      type: [String, Number],
      required: false,
      default: 8
    }
  },
  computed: {
    getComputedStyle() {
      return {
        backgroundColor: this.color,
        width: `${this.size}px`,
        height: `${this.size}px`
      };
    }
  }
};