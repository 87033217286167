import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  class: "w-100"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_wrap_with_popover = _resolveComponent("wrap-with-popover");
  const _component_flex_row = _resolveComponent("flex-row");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_hug_value = _resolveComponent("hug-value");
  const _component_date_value = _resolveComponent("date-value");
  const _component_currency_value = _resolveComponent("currency-value");
  const _component_bar_with_value = _resolveComponent("bar-with-value");
  const _component_percentage_value = _resolveComponent("percentage-value");
  const _component_evolution_value = _resolveComponent("evolution-value");
  const _component_slot_with_evolution_value = _resolveComponent("slot-with-evolution-value");
  const _component_signed_bar_with_value = _resolveComponent("signed-bar-with-value");
  const _component_wrap_with_alert_icon = _resolveComponent("wrap-with-alert-icon");
  const _component_block_bar_with_value = _resolveComponent("block-bar-with-value");
  const _component_boxed_value = _resolveComponent("boxed-value");
  const _component_box_and_bar_with_value = _resolveComponent("box-and-bar-with-value");
  const _component_b_table_lite = _resolveComponent("b-table-lite");
  const _component_wrap_table_with_show_more = _resolveComponent("wrap-table-with-show-more");
  const _component_table_with_title = _resolveComponent("table-with-title");
  return _openBlock(), _createBlock(_component_table_with_title, {
    title: "Evolution",
    class: "profit-period-table",
    onDownload: $options.downloadPerformancePeriodCSV,
    fields: $data.fields,
    loading: $props.loading
  }, {
    default: _withCtx(() => [_createVNode(_component_wrap_table_with_show_more, {
      modelValue: $options.period_pager,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.period_pager = $event),
      period: _ctx.period
    }, {
      default: _withCtx(() => [_createVNode(_component_b_table_lite, {
        class: "lite-table lite-table-hoverable period-table listing-table",
        fields: $data.fields,
        bordered: "",
        responsive: "",
        fixed: "",
        items: $options.items,
        "sticky-header": true,
        tbodyTrClass: item => _ctx.handleRowClass(item, $options.items),
        onRowClicked: _cache[0] || (_cache[0] = item => _ctx.handleRowClicked(item, $options.handleDateReset, $options.handleDateClick, 'period_agg'))
      }, {
        "head()": _withCtx(col => [_createVNode(_component_flex_row, {
          id: `profit-period-table-${col?.label}`,
          styles: {
            justifyContent: col.field.justify,
            ...col.field.thStyle
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_wrap_with_popover, {
            target: `profit-period-table-${col?.label}`,
            style: {
              width: '100%'
            }
          }, {
            title: _withCtx(() => [_createElementVNode("div", {
              innerHTML: $options.getDescriptionBasedOnKpiKey(col?.column).title
            }, null, 8 /* PROPS */, _hoisted_1)]),
            content: _withCtx(() => [_createTextVNode(_toDisplayString($options.getDescriptionBasedOnKpiKey(col?.column).detail), 1 /* TEXT */)]),
            default: _withCtx(() => [_createElementVNode("div", {
              innerHTML: col?.column === 'period_agg' ? $options.getPeriodColumnTitle : col?.label,
              class: "title"
            }, null, 8 /* PROPS */, _hoisted_2)]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["target"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "styles"])]),
        "cell(selector)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_AppIcon, {
            icon: `zoom-${row.item.selected ? 'out' : 'in'}`,
            color: row.item.selected ? null : '#ADADAD',
            size: 16
          }, null, 8 /* PROPS */, ["icon", "color"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(period_agg)": _withCtx(row => [_createVNode(_component_date_value, {
          value: row.item?.period_agg,
          format: $options.getDateValueFormat,
          styles: {
            justifyContent: 'flex-start',
            ..._ctx.activateDateValueStyles(row)
          },
          loading: $props.loading
        }, null, 8 /* PROPS */, ["value", "format", "styles", "loading"])]),
        "cell(pl_net_revenue)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading,
          value: row.item?.pl_net_revenue
        }, {
          default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
            gap: 8
          }, {
            evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
              value: row.item?.[`${$data.evolCategory[this.periodCategory]}pl_net_revenue_evol`]
            }, {
              default: _withCtx(({
                color
              }) => [_createVNode(_component_percentage_value, {
                value: row.item?.[`${$data.evolCategory[this.periodCategory]}pl_net_revenue_evol`],
                color: color,
                "show-sign": ""
              }, null, 8 /* PROPS */, ["value", "color"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
            default: _withCtx(() => [_createVNode(_component_bar_with_value, {
              tight: "",
              align: "left",
              value: row.item?.pl_net_revenue,
              min: $options.minMax?.pl_net_revenue?.min,
              max: $options.minMax?.pl_net_revenue?.max,
              color: "#22863D"
            }, {
              default: _withCtx(() => [_createVNode(_component_currency_value, {
                value: row.item?.pl_net_revenue,
                charCount: $options.maxCharCount?.currency?.pl_net_revenue
              }, null, 8 /* PROPS */, ["value", "charCount"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "value"])]),
        "cell(pl_contributive_margin)": _withCtx(row => [_createVNode(_component_hug_value, {
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_slot_with_evolution_value, {
            gap: 8
          }, {
            evolution: _withCtx(() => [_createVNode(_component_evolution_value, {
              value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}pl_contributive_margin_evol`]
            }, {
              default: _withCtx(({
                color
              }) => [_createVNode(_component_percentage_value, {
                value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}pl_contributive_margin_evol`],
                color: color,
                "show-sign": "",
                charCount: $options.maxCharCount?.percentage?.[`${$data.evolCategory[_ctx.periodCategory]}pl_contributive_margin_evol`]
              }, null, 8 /* PROPS */, ["value", "color", "charCount"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
            default: _withCtx(() => [_createVNode(_component_signed_bar_with_value, {
              tight: "",
              align: "left",
              value: row.item?.pl_contributive_margin,
              min: $options.minMax?.pl_contributive_margin?.min,
              max: $options.minMax?.pl_contributive_margin?.max,
              positiveColor: "#5A52AE",
              negativeMin: $options.minMax?.pl_contributive_margin?.negativeMin
            }, {
              default: _withCtx(() => [_createVNode(_component_currency_value, {
                value: row.item?.pl_contributive_margin,
                charCount: $options.maxCharCount?.currency?.pl_contributive_margin
              }, null, 8 /* PROPS */, ["value", "charCount"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "negativeMin"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading"])]),
        "cell(pl_net_percentage_revenue)": _withCtx(row => [_createVNode(_component_block_bar_with_value, {
          value: row.item?.pl_net_percentage_revenue * 0.7,
          min: $options.minMax?.pl_net_percentage_revenue?.min,
          max: $options.minMax?.pl_net_percentage_revenue?.max,
          color: $options.getColorForDecile(row.item?.pl_net_percentage_revenue, this.minMax?.pl_net_percentage_revenue?.min, this.minMax?.pl_net_percentage_revenue?.max, $data.PROFIT_REVENUE_PERCENTAGE_DECILE_COLORS, 5, 4),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_hug_value, {
            styles: {
              padding: '0 8px',
              alignSelf: 'stretch',
              justifyContent: 'flex-end',
              gap: '4px',
              position: 'relative'
            }
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [row.item?.pl_net_percentage_revenue < 0 ? (_openBlock(), _createBlock(_component_wrap_with_alert_icon, {
              key: 0
            }, {
              default: _withCtx(() => [_createVNode(_component_percentage_value, {
                value: row.item?.pl_net_percentage_revenue,
                styles: {
                  justifyContent: 'flex-start'
                },
                charCount: $options.maxCharCount.percentage?.pl_net_percentage_revenue,
                color: $data.COLORS.RED700
              }, null, 8 /* PROPS */, ["value", "charCount", "color"])]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)) : (_openBlock(), _createBlock(_component_percentage_value, {
              key: 1,
              value: row.item?.pl_net_percentage_revenue,
              styles: {
                justifyContent: 'flex-start'
              },
              charCount: $options.maxCharCount.percentage?.pl_net_percentage_revenue
            }, null, 8 /* PROPS */, ["value", "charCount"]))]), _createVNode(_component_evolution_value, {
              value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}pl_net_percentage_revenue_evol`]
            }, {
              default: _withCtx(({
                color
              }) => [_createVNode(_component_percentage_value, {
                value: row.item?.[`${$data.evolCategory[_ctx.periodCategory]}pl_net_percentage_revenue_evol`],
                color: color,
                "show-sign": "",
                pp: ""
              }, null, 8 /* PROPS */, ["value", "color"])]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"])]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "min", "max", "color", "loading"])]),
        "cell(pl_amazon_commission_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getProfitPercentageDecileColor(row, 'pl_amazon_commission_pc'),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.pl_amazon_commission_pc
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(pl_fba_direct_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getProfitPercentageDecileColor(row, 'pl_fba_direct_pc'),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.pl_fba_direct_pc
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(pl_fba_indirect_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getProfitPercentageDecileColor(row, 'pl_fba_indirect_pc'),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.pl_fba_indirect_pc
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(pl_refund_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getProfitPercentageDecileColor(row, 'pl_refund_pc'),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.pl_refund_pc
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(pl_coop_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getProfitPercentageDecileColor(row, 'pl_coop_pc'),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.pl_coop_pc
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(pl_cogs_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getProfitPercentageDecileColor(row, 'pl_cogs_pc'),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.pl_cogs_pc
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(pl_other_costs_pc)": _withCtx(row => [_createVNode(_component_boxed_value, {
          bgColor: $options.getProfitPercentageDecileColor(row, 'pl_other_costs_pc'),
          loading: $props.loading
        }, {
          default: _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.pl_other_costs_pc
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["bgColor", "loading"])]),
        "cell(pl_advertising_costs)": _withCtx(row => [_createVNode(_component_box_and_bar_with_value, {
          loading: $props.loading,
          bgColor: $options.getProfitPercentageDecileColor(row, 'pl_advertising_costs_pc'),
          barColor: "#F1959C",
          value: row.item?.pl_advertising_costs,
          min: $options.minMax?.pl_advertising_costs?.min,
          max: $options.minMax?.pl_advertising_costs?.max
        }, {
          "box-value": _withCtx(() => [_createVNode(_component_percentage_value, {
            value: row.item?.pl_advertising_costs_pc
          }, null, 8 /* PROPS */, ["value"])]),
          "bar-value": _withCtx(() => [_createVNode(_component_currency_value, {
            value: row.item?.pl_advertising_costs,
            color: "#F2616D"
          }, null, 8 /* PROPS */, ["value"])]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "bgColor", "value", "min", "max"])]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["fields", "items", "tbodyTrClass"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "period"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onDownload", "fields", "loading"]);
}