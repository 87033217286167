import ErrorOccured from '@/components/shared/ErrorOccured.vue';
import CenterWrapper from '@/components/shared/CenterWrapper.vue';
export default {
  name: 'ErrorWrapper',
  emits: ['retry'],
  components: {
    ErrorOccured,
    CenterWrapper
  },
  props: {
    showError: {
      type: Boolean,
      default: false
    }
  }
};