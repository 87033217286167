import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f660aa6a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "period-preview"
};
const _hoisted_2 = {
  class: "body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['month-icon', {
      ['enabled']: $props.previousEnabled
    }]),
    onClick: _cache[0] || (_cache[0] = () => {
      if ($props.previousEnabled) _ctx.$emit('previous');
    })
  }, [_createVNode(_component_app_icon, {
    icon: "chevron-left-small",
    size: 20,
    color: "#757575"
  })], 2 /* CLASS */), _createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]), _createElementVNode("div", {
    class: _normalizeClass(['month-icon', {
      ['enabled']: $props.nextEnabled
    }]),
    onClick: _cache[1] || (_cache[1] = () => {
      if ($props.nextEnabled) _ctx.$emit('next');
    })
  }, [_createVNode(_component_app_icon, {
    icon: "chevron-right-small",
    size: 20,
    color: "#757575"
  })], 2 /* CLASS */)]);
}