import FlexColumn from "@/components/FlexColumn.vue";
import FlexRow from "@/components/FlexRow.vue";
import { HugValue } from "@/components/tableElements";
import AnnoncementIcon from "@/components/icons/AnnoncementIcon.vue";
import { truncate } from "lodash";
import WrapWithPopover from "@/components/shared/WrapWithPopover.vue";
import StrokedRoundedLinkIcon from "@/components/icons/StrokedRoundedLinkIcon.vue";
import WrapWithCopy from "@/components/WrapWithCopy.vue";
export default {
  name: "ProductValue",
  components: {
    FlexColumn,
    FlexRow,
    HugValue,
    AnnoncementIcon,
    WrapWithPopover,
    WrapWithCopy,
    StrokedRoundedLinkIcon
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 100
    },
    loading: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: "left"
    },
    sponsored: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    isChild: {
      type: Object,
      default: false
    },
    isToggled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLargeScreen() {
      return window.innerWidth > 1488;
    },
    productName() {
      if (!this.value?.name) return `-`;
      return this.value.name;
    },
    sku() {
      return this.isLargeScreen ? this.value.sku : truncate(this.value.sku, 26);
    },
    asin() {
      if (!this.value?.asin) return `-`;
      return this.isLargeScreen ? this.value.asin : truncate(this.value.asin, 26);
    }
  }
};