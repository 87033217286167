import FlexRow from '@/components/FlexRow.vue';
export default {
  name: 'SlotWithValue',
  props: {
    value: {
      type: Number
    },
    gap: {
      type: Number
    },
    align: {
      type: String
    }
  },
  components: {
    FlexRow
  }
};