import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "title-icon"
};
const _hoisted_2 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_tab = _resolveComponent("b-tab");
  const _component_b_tabs = _resolveComponent("b-tabs");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode(" \n\n        To use NavigationTabs, all you need to do is to pass\n        <navigation-tabs :tabs=\"[{text, value, ?icon, activeColor, ?disabled}]\" v-model=\"tab:Number\"/>\n        to your template.\n\n        The icons are icons used from `@/components/icons` directory.\n\n        If you want to add a new Icon, use an example from there to organize your icon.\n     "), _createVNode(_component_b_tabs, {
    style: _normalizeStyle($options.cssProps),
    class: "navigation-tabs",
    modelValue: $options.currentTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.currentTab = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, ({
      text,
      disabled,
      icon,
      activeColor
    }) => {
      return _openBlock(), _createBlock(_component_b_tab, {
        disabled: disabled,
        key: text
      }, {
        title: _withCtx(() => [_createElementVNode("div", _hoisted_1, [icon ? (_openBlock(), _createBlock(_resolveDynamicComponent(icon), {
          key: 0,
          class: "icon",
          width: "16",
          height: "16"
        })) : _createCommentVNode("v-if", true), _createElementVNode("span", _hoisted_2, _toDisplayString(text), 1 /* TEXT */)])]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["disabled"]);
    }), 128 /* KEYED_FRAGMENT */))]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style", "modelValue"])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */);
}