import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b54027d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "d-flex flex-column mb-3"
};
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-row align-items-center justify-content-between"
};
const _hoisted_3 = {
  key: 0,
  class: "title"
};
const _hoisted_4 = {
  key: 1,
  class: "title-box"
};
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  const _component_app_button = _resolveComponent("app-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.title ? (_openBlock(), _createElementBlock("div", _hoisted_2, [typeof $props.title == 'string' ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.title), 1 /* TEXT */)) : typeof $props.title == 'array' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.title, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: item.text,
      class: "title",
      onClick: $event => $options.onClick(item)
    }, [index > 0 ? (_openBlock(), _createBlock(_component_app_icon, {
      key: 0,
      icon: "chevron-right"
    })) : _createCommentVNode("v-if", true), _createTextVNode(" " + _toDisplayString(item.text), 1 /* TEXT */)], 8 /* PROPS */, _hoisted_5);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true), $props.visibleButton ? (_openBlock(), _createBlock(_component_app_button, {
    key: 2,
    variant: "outline-primary",
    onClick: $options.onClick,
    disabled: !$props.activeButton,
    loading: $props.spinButton
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.action), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick", "disabled", "loading"])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]);
}