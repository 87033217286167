import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0d08d69c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "monthes"
};
const _hoisted_2 = {
  class: "month-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_icon = _resolveComponent("app-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(['month-icon', {
      disabled: $options.isFirstMonth
    }]),
    onClick: _cache[0] || (_cache[0] = $event => $options.onClick('previous'))
  }, [_createVNode(_component_app_icon, {
    icon: "chevron-left-small",
    size: 20,
    color: "#757575"
  })], 2 /* CLASS */), _createElementVNode("div", _hoisted_2, _toDisplayString($options.previous) + " ‐ " + _toDisplayString($options.current), 1 /* TEXT */), _createElementVNode("div", {
    class: _normalizeClass(['month-icon', {
      disabled: $options.isLastMonth
    }]),
    onClick: _cache[1] || (_cache[1] = $event => $options.onClick('next'))
  }, [_createVNode(_component_app_icon, {
    icon: "chevron-right-small",
    size: 20,
    color: "#757575"
  })], 2 /* CLASS */)]);
}