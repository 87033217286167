import AppIcon from './AppIcon.vue';
import WrapWithPopover from './shared/WrapWithPopover.vue';
export default {
  name: 'RightStripIcon',
  components: {
    AppIcon,
    WrapWithPopover
  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: '#757575'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    info: {
      type: String,
      default: ''
    }
  }
};