import AppPopover from "@/components/AppPopover";
import AppIcon from "@/components/AppIcon";
export default {
  name: "AppCard",
  components: {
    AppPopover,
    AppIcon
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    info: {
      type: String,
      default: null
    },
    minHeight: {
      type: Number,
      default: null
    },
    noBody: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    hasBorder: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      id: null
    };
  },
  computed: {
    styles() {
      const result = ["width: 100%", "height: 100%"];
      if (this.minHeight) {
        result.push(`min-height: ${this.minHeight}px`);
      }
      return result.join("; ");
    }
  },
  created() {
    this.id = Math.floor(100000 + Math.random() * 900000).toString();
  }
};