export default {
  name: 'FilterSectionHeader',
  emits: ['click'],
  props: {
    header: {
      type: String,
      default: "",
      required: true
    },
    showButton: {
      type: Boolean,
      default: true,
      required: false
    },
    buttonText: {
      type: String,
      default: "Clear",
      required: false
    },
    disable: {
      type: Boolean,
      default: false,
      required: false
    },
    headerStyle: {
      type: Object,
      default: () => ({}),
      required: false
    },
    noRuler: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};