import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "alert-wrapper"
};
const _hoisted_2 = {
  class: "error-message-html"
};
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_alert = _resolveComponent("b-alert");
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [$data.type === $data.types.INFO ? (_openBlock(), _createBlock(_component_b_alert, {
    key: 0,
    modelValue: $data.infoCountDown,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.infoCountDown = $event),
    dismissible: "",
    variant: "primary",
    onClosed: _cache[1] || (_cache[1] = $event => $data.infoCountDown = 0),
    onCloseCountdown: $options.infoCountDownChanged
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.message), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onCloseCountdown"])) : _createCommentVNode("v-if", true), $data.type === $data.types.SUCCESS ? (_openBlock(), _createBlock(_component_b_alert, {
    key: 1,
    modelValue: $data.successCountDown,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.successCountDown = $event),
    dismissible: "",
    variant: "success",
    onClosed: _cache[3] || (_cache[3] = $event => $data.successCountDown = 0),
    onCloseCountdown: $options.successCountDownChanged
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.message), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onCloseCountdown"])) : _createCommentVNode("v-if", true), $data.type === $data.types.DANGER && !_ctx.isHtmlMessage ? (_openBlock(), _createBlock(_component_b_alert, {
    key: 2,
    modelValue: $data.dangerCountDown,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.dangerCountDown = $event),
    dismissible: "",
    variant: "danger",
    onClosed: _cache[5] || (_cache[5] = $event => $data.dangerCountDown = 0),
    onCloseCountdown: $options.dangerCountDownChanged
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.message), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onCloseCountdown"])) : _createCommentVNode("v-if", true), $data.type === $data.types.DANGER && _ctx.isHtmlMessage ? (_openBlock(), _createBlock(_component_b_alert, {
    key: 3,
    modelValue: $data.dangerCountDown,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.dangerCountDown = $event),
    dismissible: "",
    variant: "danger",
    onClosed: _cache[7] || (_cache[7] = $event => $data.dangerCountDown = 0),
    onCloseCountdown: $options.dangerCountDownChanged
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", {
      class: "mb-2",
      innerHTML: $data.message
    }, null, 8 /* PROPS */, _hoisted_3), _createElementVNode("p", {
      class: "mb-2",
      innerHTML: $data.htmlContent
    }, null, 8 /* PROPS */, _hoisted_4)])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onCloseCountdown"])) : _createCommentVNode("v-if", true), $data.type === $data.types.WARNING ? (_openBlock(), _createBlock(_component_b_alert, {
    key: 4,
    modelValue: $data.warningCountDown,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => $data.warningCountDown = $event),
    dismissible: "",
    variant: "warning",
    onClosed: _cache[9] || (_cache[9] = $event => $data.warningCountDown = 0),
    onCloseCountdown: $options.warningCountDownChanged
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.message), 1 /* TEXT */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onCloseCountdown"])) : _createCommentVNode("v-if", true)], 512 /* NEED_PATCH */)), [[_vShow, $data.message !== '' && $data.visible]]);
}