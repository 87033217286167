import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_omny_modal_header = _resolveComponent("omny-modal-header");
  const _component_AppButton = _resolveComponent("AppButton");
  const _component_AppIcon = _resolveComponent("AppIcon");
  const _component_b_modal = _resolveComponent("b-modal");
  return _openBlock(), _createBlock(_component_b_modal, {
    modalClass: "right-pane-layout",
    noFade: "",
    modelValue: $options.modal,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $options.modal = $event)
  }, {
    header: _withCtx(() => [_createVNode(_component_omny_modal_header, {
      modelValue: $options.modal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.modal = $event)
    }, {
      title: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue"])]),
    cancel: _withCtx(() => [_createVNode(_component_AppButton, {
      v2: "",
      variant: "outline-secondary",
      version: "btn-modal",
      onClick: _cache[1] || (_cache[1] = () => {
        $options.modal = false;
        _ctx.$emit('cancel');
      })
    }, {
      default: _withCtx(() => [_ctx.$slots['cancel'] ? _renderSlot(_ctx.$slots, "cancel", {
        key: 0
      }) : (_openBlock(), _createElementBlock("div", _hoisted_1, "Cancel"))]),
      _: 3 /* FORWARDED */
    })]),
    ok: _withCtx(() => [_createVNode(_component_AppButton, {
      v2: "",
      variant: "primary",
      version: "btn-modal",
      onClick: _cache[2] || (_cache[2] = $event => {
        _ctx.$emit('submit');
      }),
      disabled: !$props.isCreatable
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "ok")]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["disabled"])]),
    "header-close": _withCtx(() => [_createVNode(_component_AppIcon, {
      icon: "close-table",
      onClick: _cache[3] || (_cache[3] = $event => $options.modal = false),
      color: "#999999",
      size: 12,
      class: "cursor-pointer",
      style: {
        padding: '8px 12px'
      }
    })]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue"]);
}