import { truncateString } from "../../utils";
import FilterItem from "./FilterItem.vue";
import ProductFilterBody from "./bodies/ProductFilterBody.vue";
export default {
  name: "ProductFilter",
  components: {
    FilterItem,
    ProductFilterBody
  },
  emits: ["apply", "cleared", "closeDropdown", "search", "select", "selectAll", "selectOnly", "deselect", "clear", "hidden"],
  props: {
    products: {
      type: Object,
      required: true,
      default: () => ({
        selected: [],
        list: [],
        isModified: false,
        select: () => {},
        deselect: () => {},
        clear: () => {},
        apply: () => {},
        listSearch: () => {}
      })
    },
    sellers: {
      type: Object
    },
    marketplaces: {
      type: Object
    },
    storeRoot: {
      type: String,
      required: true
    },
    select_key: {
      type: String,
      default: "id"
    }
  },
  computed: {
    getProductsTitle() {
      let joinedContents = this.products.selected.map(el => el?.asin).join(", ");
      let joinedSku = this.products.selected.map(el => el?.sku).join(", ");

      // Now, I only want to use sku when joinedContents is empty
      joinedContents = joinedContents.length > 0 ? joinedContents : joinedSku;
      joinedContents = joinedContents.length > 0 ? joinedContents : "All Products";
      return truncateString(joinedContents, 16);
    }
  },
  methods: {
    handleProductsSearch({
      value
    }) {
      this.$emit("search", value);
      if (this.products.asyncSearcher !== null) {
        this.products.asyncSearch({
          keyword: value,
          args: {
            sellers: this.sellers.filterValues,
            sales_channel: this.marketplaces.filterValues
          }
        });
      }
    },
    handleHidden() {
      this.$emit("apply");
      this.$emit("hidden");
    },
    onSelect($event) {
      this.products.select($event, this.select_key);
    },
    onSelectOnly($event) {
      this.products.selectOnly($event, this.select_key);
    },
    onDeSelect($event) {
      this.products.deselect($event, this.select_key);
    },
    onClear() {
      this.products.clear();
      this.$emit("cleared");
    },
    onApply() {
      this.$emit("apply");
    },
    onClose() {
      this.$refs?.productFilterItem?.closeDropdown;
    },
    onSelectAll() {
      this.products.selectAll();
    }
  }
};