export default {
  name: 'AppLoader',
  props: {
    size: {
      type: Number,
      default: 16
    }
  },
  computed: {
    style() {
      const result = [];
      if (this.size) {
        result.push(`width: ${this.size}px`);
        result.push(`height: ${this.size}px`);
      }
      return result.join('; ');
    }
  }
};