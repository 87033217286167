import AppSlider from "@/components/AppSlider";
export default {
  name: "DemandForecastingSettingsSeasonal",
  emits: ["update:modelValue", "change"],
  components: {
    AppSlider
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    percents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: JSON.parse(JSON.stringify(this.modelValue)),
      monthlyKeys: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    };
  },
  methods: {
    onChange() {
      this.$emit("change", this.current);
    }
  }
};