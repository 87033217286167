import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_pdf_export_popup = _resolveComponent("pdf-export-popup");
  const _component_kpis_controllers_section = _resolveComponent("kpis-controllers-section");
  const _component_global_performance_page = _resolveComponent("global-performance-page");
  const _component_global_advertising_page = _resolveComponent("global-advertising-page");
  const _component_country_performance_page = _resolveComponent("country-performance-page");
  const _component_country_advertising_page = _resolveComponent("country-advertising-page");
  const _component_product_performance_page = _resolveComponent("product-performance-page");
  const _component_product_advertising_page = _resolveComponent("product-advertising-page");
  const _component_product_inventory_coverage_page = _resolveComponent("product-inventory-coverage-page");
  const _component_product_pricing_margin_page = _resolveComponent("product-pricing-margin-page");
  const _component_advertising_targeting_page = _resolveComponent("advertising-targeting-page");
  const _component_advertising_campaigns_page = _resolveComponent("advertising-campaigns-page");
  const _component_sellers_performance_page = _resolveComponent("sellers-performance-page");
  const _component_sellers_advertising_page = _resolveComponent("sellers-advertising-page");
  const _component_LoadingTable = _resolveComponent("LoadingTable");
  const _component_kpis_renderer = _resolveComponent("kpis-renderer");
  const _component_omny_ui_layout = _resolveComponent("omny-ui-layout");
  return _openBlock(), _createBlock(_component_omny_ui_layout, {
    contentPadding: "0 14px"
  }, {
    default: _withCtx(() => [_createVNode(_component_kpis_renderer, null, {
      controllerSection: _withCtx(() => [_createVNode(_component_kpis_controllers_section, {
        filters: $options.getUpdatedTabFilters,
        showFilterSectionLine: false,
        onChangeTab: _cache[0] || (_cache[0] = value => $options.handleTabChange(value)),
        onChangeView: _cache[1] || (_cache[1] = value => $options.handleViewChange(value)),
        onChangePeriod: _cache[2] || (_cache[2] = value => $options.changeGlobalTabPeriod(value)),
        pages: $options.getUpdatedPages,
        pdfPages: _ctx.getPdfExportPages,
        pdfPeriods: _ctx.getPdfExportPeriods,
        tabPeriods: _ctx.getTabPeriods,
        graphControllers: _ctx.getGraphControllers,
        currentTab: _ctx.currentTab,
        currentView: _ctx.currentView,
        currentPeriod: _ctx.globalPeriod,
        showDatePicker: $options.showDatePicker
      }, {
        filterSectionRightItem: _withCtx(() => [_createVNode(_component_pdf_export_popup, {
          pdfPages: _ctx.getPdfExportPages,
          periods: _ctx.getPdfExportPeriods
        }, null, 8 /* PROPS */, ["pdfPages", "periods"])]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["filters", "pages", "pdfPages", "pdfPeriods", "tabPeriods", "graphControllers", "currentTab", "currentView", "currentPeriod", "showDatePicker"])]),
      kpisSection: _withCtx(() => [_createVNode(_component_LoadingTable, {
        loading: $data.homepageLoading
      }, {
        default: _withCtx(() => [$data.showGlobalPerformancePage ? (_openBlock(), _createBlock(_component_global_performance_page, {
          key: 0,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showGlobalAdvertisingPage ? (_openBlock(), _createBlock(_component_global_advertising_page, {
          key: 1,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showCountryPerformancePage ? (_openBlock(), _createBlock(_component_country_performance_page, {
          key: 2,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showCountryAdvertisingPage ? (_openBlock(), _createBlock(_component_country_advertising_page, {
          key: 3,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showProductPerformancePage ? (_openBlock(), _createBlock(_component_product_performance_page, {
          key: 4,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showProductAdvertisingPage ? (_openBlock(), _createBlock(_component_product_advertising_page, {
          key: 5,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showProductInventoryCoveragePage ? (_openBlock(), _createBlock(_component_product_inventory_coverage_page, {
          key: 6,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showProductPricingAndMarginPage ? (_openBlock(), _createBlock(_component_product_pricing_margin_page, {
          key: 7,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showAdvertisingTargetingPage ? (_openBlock(), _createBlock(_component_advertising_targeting_page, {
          key: 8,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showAdvertisingCampaignsPage ? (_openBlock(), _createBlock(_component_advertising_campaigns_page, {
          key: 9,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showSellersPerformancePage ? (_openBlock(), _createBlock(_component_sellers_performance_page, {
          key: 10,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true), $data.showSellersAdvertisingPage ? (_openBlock(), _createBlock(_component_sellers_advertising_page, {
          key: 11,
          period: this.currentPeriod
        }, null, 8 /* PROPS */, ["period"])) : _createCommentVNode("v-if", true)]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading"])]),
      _: 1 /* STABLE */
    })]),
    _: 1 /* STABLE */
  });
}