import { mapActions } from "vuex";
import TheWrapperPublic from "@/components/TheWrapperPublic.vue";
import { useVuelidate } from "@vuelidate/core";
import ValidateMixin from "@/mixins/ValidateMixin.vue";
import AppForm from "@/components/AppForm.vue";
import AppInput from "@/components/AppInput.vue";
import AppButton from "@/components/AppButton.vue";
export default {
  name: "ResetPassword",
  mixins: [ValidateMixin],
  components: {
    TheWrapperPublic,
    AppForm,
    AppInput,
    AppButton
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      form: {
        confirm_password: "",
        password: ""
      },
      loading: false,
      successReset: false
    };
  },
  methods: {
    ...mapActions(["resetPassword"]),
    async onSubmit() {
      // Check if password and confirm password are the same
      if (this.form.password !== this.form.confirm_password) {
        window.$bus.trigger("showDanger", {
          visible: true,
          message: "Password and confirm password are not the same."
        });
        return;
      }
      const isValid = await this.mValidate();
      if (!isValid) return;
      this.loading = true;
      await this.resetPassword(this.form);
      this.loading = false;
      this.successReset = true;
    },
    loginPage() {
      this.$router.push("/login");
    }
  }
};