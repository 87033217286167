export default {
  name: 'AppCardConnections',
  emits: ["back"],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    minHeight: {
      type: Number,
      default: null
    },
    scrollable: {
      type: Boolean,
      default: true
    },
    hasBorder: {
      type: Boolean,
      default: true
    },
    pushModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styles() {
      return {
        minHeight: this.minHeight ? this.minHeight + 'px' : null
      };
    },
    modal: {
      get() {
        return this.pushModal;
      },
      set(val) {
        this.$emit("back", val);
      }
    }
  }
};