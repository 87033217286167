import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1b79f7c1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-page"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_alert = _resolveComponent("app-alert");
  const _component_pdf_renderer = _resolveComponent("pdf-renderer");
  const _component_the_sidebar = _resolveComponent("the-sidebar");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_app_alert), _createVNode(_component_pdf_renderer), _createElementVNode("div", {
    style: _normalizeStyle($options.homepageStyle),
    class: "homepage-wrapper"
  }, [_createVNode(_component_the_sidebar, {
    isCollapsed: $data.isCollapsed,
    onHandleCollapse: $options.handleCollapse,
    onHandleHovering: $options.handleHovering,
    isHovering: $data.isHovering,
    style: _normalizeStyle({
      width: 'var(--wrapper-private-sidebar-width)'
    })
  }, null, 8 /* PROPS */, ["isCollapsed", "onHandleCollapse", "onHandleHovering", "isHovering", "style"]), _createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])], 4 /* STYLE */)], 64 /* STABLE_FRAGMENT */);
}