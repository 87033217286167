import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["width", "height", "viewBox"];
const _hoisted_2 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_center_wrapper = _resolveComponent("center-wrapper");
  return _openBlock(), _createBlock(_component_center_wrapper, null, {
    default: _withCtx(() => [(_openBlock(), _createElementBlock("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      width: $props.width,
      height: $props.height,
      viewBox: `0 0 ${$props.width} ${$props.height}`,
      fill: "none"
    }, [_createElementVNode("path", {
      d: "M1.5 4L7.5 4M7.5 4C7.5 4.82843 8.17157 5.5 9 5.5C9.82843 5.5 10.5 4.82843 10.5 4C10.5 3.17157 9.82843 2.5 9 2.5C8.17157 2.5 7.5 3.17157 7.5 \n                4ZM4.5 8L10.5 8M4.5 8C4.5 8.82843 3.82843 9.5 3 9.5C2.17157 9.5 1.5 8.82843 1.5 8C1.5 7.17157 2.17157 6.5 3 6.5C3.82843 6.5 4.5 7.17157 4.5 8Z",
      stroke: $props.color,
      "stroke-width": "1.33333",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8 /* PROPS */, _hoisted_2)], 8 /* PROPS */, _hoisted_1))]),
    _: 1 /* STABLE */
  });
}