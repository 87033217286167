import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TrendElement = _resolveComponent("TrendElement");
  const _component_hug_value = _resolveComponent("hug-value");
  return _openBlock(), _createBlock(_component_hug_value, {
    id: $props.id,
    styles: $options.computedStyle,
    loading: $props.loading
  }, {
    default: _withCtx(() => [$props.value ? (_openBlock(), _createBlock(_component_TrendElement, {
      key: 0,
      direction: $props.direction,
      color: $props.color
    }, null, 8 /* PROPS */, ["direction", "color"])) : _createCommentVNode("v-if", true), _renderSlot(_ctx.$slots, "default")]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["id", "styles", "loading"]);
}