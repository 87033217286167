import FilterItem from "./FilterItem.vue";
import SellerFilterBody from "./bodies/SellerFilterBody.vue";
import { truncateString } from "@/utils";
export default {
  name: "SellerFilter",
  components: {
    FilterItem,
    SellerFilterBody
  },
  emits: ["apply", "cleared"],
  props: {
    sellers: {
      type: Object,
      required: true,
      default: () => ({
        filterState: {
          selected: [],
          list: []
        },
        isModified: false,
        select: () => {},
        deselect: () => {},
        clear: () => {},
        apply: () => {},
        listSearch: () => {}
      })
    },
    onlyMode: {
      type: Boolean,
      default: false
    },
    select_key: {
      type: String,
      default: "id"
    },
    search_key: {
      type: Array,
      default: ["name", "id", "region"]
    }
  },
  computed: {
    getSellerTitle() {
      let joinedSellers = this.sellers.selected?.map(seller => seller.name !== "" ? seller.name : seller.id).join(", ");
      joinedSellers = joinedSellers ? truncateString(joinedSellers, 20) : "All Sellers";
      return joinedSellers;
    }
  },
  methods: {
    onSelect($event) {
      if (this.onlyMode) {
        this.sellers.selectOnly($event, this.select_key);
      } else {
        this.sellers.select($event, this.select_key);
      }
      this.sellers.select($event, this.select_key);
    },
    onSelectOnly($event) {
      this.sellers.selectOnly($event, this.select_key);
    },
    onDeSelect($event) {
      this.sellers.deselect($event, this.select_key);
      if (this.sellers.filterState.selected.length === 0) {
        this.$emit("cleared");
      }
    },
    onClear() {
      this.sellers.clear();
      this.$emit("cleared");
    },
    onApply() {
      this.$emit("apply");
    },
    onClose() {
      this.$refs?.sellerFilterItem?.closeDropdown;
    },
    onSearch($event) {
      this.sellers.listSearch($event.value, [...this.search_key, "id"]);
    }
  }
};