import AppIcon from './AppIcon.vue';
import FlexRow from './FlexRow.vue';
import WrapWithPopover from './shared/WrapWithPopover.vue';
import InfoIcon from './icons/InfoIcon.vue';
export default {
  name: 'WrapWithInfo',
  props: {
    info: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: 'left'
    },
    gap: {
      type: Number,
      default: 4
    },
    popoverClass: {
      type: String
    },
    width: {
      type: String
    }
  },
  components: {
    FlexRow,
    AppIcon,
    WrapWithPopover,
    InfoIcon
  }
};