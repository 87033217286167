import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_date_picker_sidebar_section = _resolveComponent("date-picker-sidebar-section");
  const _component_date_picker_range = _resolveComponent("date-picker-range");
  const _component_date_picker_calendar_month = _resolveComponent("date-picker-calendar-month");
  const _component_date_picker_calendar = _resolveComponent("date-picker-calendar");
  const _component_date_picker_layout = _resolveComponent("date-picker-layout");
  return _openBlock(), _createBlock(_component_date_picker_layout, {
    onSubmit: $options.onOk,
    onCancel: $options.onCancel,
    disableOK: $options.disableOK
  }, {
    sidebar: _withCtx(() => [_createVNode(_component_date_picker_sidebar_section, {
      modelValue: $options.range,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.range = $event),
      options: $data.periodOptions
    }, {
      default: _withCtx(() => [_createTextVNode(" Range ")]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "options"]), _createVNode(_component_date_picker_sidebar_section, {
      class: "compare",
      modelValue: $options.compare,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $options.compare = $event),
      options: $data.compareOptions
    }, {
      default: _withCtx(() => [_createTextVNode(" Compare ")]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "options"])]),
    body: _withCtx(() => [_createVNode(_component_date_picker_range, {
      ref: "range",
      modelValue: $options.rangeSelector,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $options.rangeSelector = $event),
      range: $options.state.range,
      options: $options.rangeOptions
    }, {
      title: _withCtx(() => [_createTextVNode("Selected range")]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "range", "options"]), _createVNode(_component_date_picker_range, {
      ref: "compare",
      modelValue: $options.compareRangeSelector,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $options.compareRangeSelector = $event),
      range: $options.state.compare,
      "is-compare": "",
      options: $options.compareRangeOptions
    }, {
      title: _withCtx(() => [_createTextVNode(" Compare: ")]),
      detail: _withCtx(() => [_createTextVNode(_toDisplayString($options.compare), 1 /* TEXT */)]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "range", "options"]), _createVNode(_component_date_picker_calendar, {
      ref: "calendar",
      date: $options.state.range.start,
      range: $options.state.range,
      compare: $options.state.compare,
      monthList: $setup.filteredMonthList,
      hoverDate: _ctx.hoverDate,
      onHoverDate: _ctx.onHoverDate
    }, {
      default: _withCtx(({
        range,
        compare,
        monthList
      }) => [_createVNode(_component_date_picker_calendar_month, {
        ref: "calendarMonth",
        range: range,
        compare: compare,
        hoverDate: _ctx.hoverDate,
        monthList: monthList,
        onEnableCustomMode: $options.enableCustomMode,
        onSetDatePickerState: $options.setDatePickerState,
        state: $options.state,
        weekType: $props.weekType,
        datesMap: $setup.filteredDatesMap
      }, null, 8 /* PROPS */, ["range", "compare", "hoverDate", "monthList", "onEnableCustomMode", "onSetDatePickerState", "state", "weekType", "datesMap"])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["date", "range", "compare", "monthList", "hoverDate", "onHoverDate"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onSubmit", "onCancel", "disableOK"]);
}