import VariableStyledBar from '@/components/tableElements/VariableStyledBar.vue';
import FlexRow from '@/components/FlexRow.vue';
import { BAR_CHART_MINIMUM_PERCENTAGE, COLORS } from '@/utils/constants';
import { normalizeToRange } from "@/utils";
export default {
  name: 'SignedBar',
  components: {
    FlexRow,
    VariableStyledBar
  },
  props: {
    value: {
      type: [Number, null],
      required: true,
      default: 0
    },
    negativeColor: {
      type: String,
      required: true,
      default: COLORS.RED400
    },
    positiveColor: {
      type: String,
      required: true,
      default: "#312783"
    },
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 0
    },
    height: {
      type: Number
    },
    negativeMin: {
      type: [Number, null]
    }
  },
  data() {
    return {
      COLORS
    };
  },
  computed: {
    leftShift() {
      return normalizeToRange(Math.abs(this.negativeMin), BAR_CHART_MINIMUM_PERCENTAGE * this.min, this.max);
    },
    valueWidth() {
      return normalizeToRange(Math.abs(this.value), BAR_CHART_MINIMUM_PERCENTAGE * this.min, this.max);
    },
    signedStyle() {
      if (this.value !== this.negativeMin) {
        let styleToBeReturned = {
          transform: `translateX(${this.calculatedLeftShift}%)`
        };
        if (this.value > 0) {
          styleToBeReturned['paddingRight'] = `${this.calculatedLeftShift}%`;
        }
        return styleToBeReturned;
      }
      return {};
    },
    calculatedLeftShift() {
      const normValue = normalizeToRange(Math.abs(this.value), BAR_CHART_MINIMUM_PERCENTAGE * this.min, this.max);
      return this.value < 0 ? this.leftShift - normValue : this.leftShift;
    }
  },
  methods: {}
};