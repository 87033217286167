import FlexRow from "@/components/FlexRow.vue";
import { TextValue } from "@/components/tableElements";
import EqualsIcon from "@/components/icons/EqualsIcon.vue";
import TargetIcon from "@/components/icons/TargetIcon.vue";
import BracketsPlusIcon from "@/components/icons/BracketsPlusIcon.vue";
import BracketsCheckIcon from "@/components/icons/BracketsCheckIcon.vue";
import ZoomOutIcon from "@/components/icons/ZoomOutIcon.vue";
import MessageSquareIcon from "@/components/icons/MessageSquareIcon.vue";
export default {
  name: "MatchTypeValue",
  components: {
    EqualsIcon,
    TargetIcon,
    BracketsPlusIcon,
    BracketsCheckIcon,
    ZoomOutIcon,
    MessageSquareIcon,
    TextValue,
    FlexRow
  },
  props: {
    type: String,
    name: String
  }
};