import { createWebHistory, createRouter } from "vue-router";
import NotFoundPage from "@/pages/NotFoundPage";
import AuthPage from "@/pages/AuthPage";
import LoginPage from "@/pages/LoginPage";
import RecoveryPage from "@/pages/RecoveryPage";
import ResetPassword from "@/pages/ResetPassword";
import RegisterPage from "@/pages/RegisterPage";
import OnboardPage from "@/pages/OnboardPage";
import HomePage from "@/pages/HomePage";
import ProfilePage from "@/pages/ProfilePage";
import WorkspacePage from "@/pages/WorkspacePage";
import AllWorkspacesPage from "@/pages/AllWorkspacesPage";
import AmazonSellerCentralPage from "@/pages/AmazonSellerCentralPage";
import AmazonAdvertisingPage from "@/pages/AmazonAdvertisingPage";
import ConnectionSuccessPage from "@/pages/ConnectionSuccessPage";
import ConnectionFailedPage from "@/pages/ConnectionFailedPage";
import AmazonAdsAccountSelectionPage from "@/pages/AmazonAdsAccountSelectionPage";
import SEOPage from "@/pages/SEOPage";
import SEOPageNew from "@/pages/SEOPageNew";
import ProductAnalyticsPage from "@/pages/ProductAnalyticsPage";
import DemandForecastingPage from "@/pages/DemandForecastingPage";
import ProductDataPage from "@/pages/ProductDataPage";
import CogsDataPage from "@/pages/CogsDataPage";
import PPCManagementPage from "@/pages/PPCManagementPage";
import PPCManagementV2Page from "@/pages/PPCManagementV2Page";
import ConnectionsPage from '@/pages/ConnectionsPage';
import ProfitAndLossPage from "@/pages/ProfitAndLossPage";
import AlertsPage from '@/pages/AlertsPage';
import DashboardV2Page from "@/pages/DashboardV2Page";
import PricingAndMarginPage from "@/pages/PricingAndMarginPage";
import InventoryCoveragePage from "@/pages/InventoryCoveragePage.vue";

/*eslint no-unused-vars: ["error", { "args": "none" }]*/

function preventDirectNavigationToThisRoute(to, from, next) {
  if (sessionStorage.getItem(to.path) !== "true") {
    // reject the navigation
    next({
      name: "Login"
    });
  } else {
    sessionStorage.setItem(to.path, false);
    next();
  }
}
function resetLocalStorageIfNeeded(to, from, next) {
  if (from.meta?.menuName != 'Inventory' && to.meta?.menuName == 'Inventory') {
    localStorage.removeItem("sellers_filter");
  }
}
function preventNavigationInactiveFeatures(to, from, next) {
  if (!router.$feature.isVisible(to.meta.featureName)) {
    next({
      name: "Login"
    }); //cancel the navigation as this feature is not visible to user
  } else {
    next();
  }
}
const routes = [{
  path: "/",
  meta: {
    requiresAuth: true,
    featureName: "default"
  },
  component: AuthPage,
  children: [{
    path: "",
    name: "Dashboard",
    component: DashboardV2Page,
    meta: {
      requiresAuth: true,
      breadcrumb: "Dashboard",
      featureName: "default"
    }
  }, {
    path: "amz-seller-central",
    meta: {
      requiresAuth: true,
      featureName: "default"
    },
    children: [{
      path: "",
      name: "AmazonSellerCentral",
      component: AmazonSellerCentralPage,
      meta: {
        requiresAuth: true,
        breadcrumb: "AmazonSellerCentral",
        featureName: "default"
      }
    }]
  }, {
    path: "amz-advertising",
    meta: {
      requiresAuth: true,
      featureName: "default"
    },
    children: [{
      path: "",
      name: "AmazonAdvertising",
      component: AmazonAdvertisingPage,
      meta: {
        breadcrumb: "AmazonAdvertising",
        featureName: "default"
      }
    }]
  }, {
    path: "amz-vendor-central",
    meta: {
      requiresAuth: true,
      featureName: "default"
    },
    children: [{
      path: "",
      name: "AmazonVendorCentral",
      component: AmazonSellerCentralPage,
      meta: {
        breadcrumb: "AmazonVendorCentral",
        featureName: "default"
      }
    }]
  }, {
    path: "seo",
    meta: {
      requiresAuth: true,
      featureName: "default"
    },
    children: [{
      path: "",
      name: "SEO",
      meta: {
        requiresAuth: true,
        breadcrumb: "Content",
        featureName: "default",
        menuName: "Products"
      },
      component: SEOPage
    }, {
      path: "/seo-v2",
      name: "SEONew",
      meta: {
        requiresAuth: true,
        breadcrumb: "Content",
        featureName: "seo-v2",
        menuName: "Products"
      },
      component: SEOPageNew
    }, {
      path: "/pricing-and-margin",
      name: "Pricing and Margin",
      meta: {
        requiresAuth: true,
        breadcrumb: "Pricing and Margin",
        featureName: "default",
        menuName: "Products"
      },
      component: PricingAndMarginPage
    }, {
      path: "/cogs-data",
      name: "CogsData",
      meta: {
        requiresAuth: true,
        breadcrumb: "COGS",
        featureName: "cogsdatafeature",
        menuName: "Products"
      },
      component: CogsDataPage,
      beforeEnter: [preventNavigationInactiveFeatures]
    }]
  }, {
    path: "inventory",
    meta: {
      requiresAuth: true,
      featureName: "inventoryfeature"
    },
    children: [{
      path: "/demand-forecasting",
      name: "DemandForecasting",
      meta: {
        requiresAuth: true,
        breadcrumb: "DemandForecasting",
        featureName: "demandforecastingfeature",
        menuName: "Inventory"
      },
      component: DemandForecastingPage
    }, {
      path: "/product-data",
      name: "ProductData",
      meta: {
        requiresAuth: true,
        breadcrumb: "Product Data",
        featureName: "productdatafeature",
        menuName: "Inventory"
      },
      component: ProductDataPage
    }, {
      path: "/inventory-coverage",
      name: "Inventory Coverage",
      meta: {
        requiresAuth: true,
        breadcrumb: "Inventory Coverage",
        featureName: "productdatafeature",
        menuName: "Inventory"
      },
      component: InventoryCoveragePage,
      beforeEnter: [preventNavigationInactiveFeatures]
    }]
  }, {
    path: "product-analytics",
    name: "ProductAnalytics",
    meta: {
      requiresAuth: true,
      featureName: "productfeature"
    },
    component: ProductAnalyticsPage,
    beforeEnter: [preventNavigationInactiveFeatures]
  }, {
    path: "advertising",
    name: "Advertising",
    meta: {
      requiresAuth: true,
      breadcrumb: "PPC Management",
      featureName: "advertisingv2feature",
      menuName: "Advertising"
    },
    component: PPCManagementV2Page
  }, {
    path: "profit-and-loss",
    name: "ProfitAndLoss",
    meta: {
      requiresAuth: true,
      breadcrumb: "Profit and Loss",
      featureName: "profitandlossfeature",
      menuName: "Profit and Loss"
    },
    component: ProfitAndLossPage,
    beforeEnter: [preventNavigationInactiveFeatures]
  }, {
    path: 'alerts',
    name: 'Alerts',
    meta: {
      requiresAuth: true,
      breadcrumb: "Alerts",
      featureName: "alertsfeature"
    },
    component: AlertsPage,
    beforeEnter: [preventNavigationInactiveFeatures]
  }, {
    path: "profile",
    name: "Profile",
    meta: {
      requiresAuth: true,
      breadcrumb: "User profile",
      featureName: "default",
      menuName: "Settings"
    },
    component: ProfilePage
  }, {
    path: "connections",
    name: "Connections",
    meta: {
      requiresAuth: true,
      breadcrumb: "Connections",
      featureName: "default",
      menuName: "Settings"
    },
    children: [{
      path: "",
      name: "ConnectionsPage",
      component: ConnectionsPage,
      meta: {
        requiresAuth: true,
        breadcrumb: "AmazonSellerCentral",
        featureName: "default"
      }
    }]
  }, {
    path: "account",
    name: "Accounts",
    meta: {
      requiresAuth: true,
      breadcrumb: "Account",
      featureName: "default",
      menuName: "Settings"
    },
    children: [{
      path: "",
      name: "WorkspacePage",
      component: WorkspacePage,
      meta: {
        requiresAuth: true,
        featureName: "default"
      }
    }]
  }, {
    path: "manage-accounts",
    name: "Manage Accounts",
    meta: {
      requiresAuth: true,
      breadcrumb: "Manage Accounts",
      featureName: "default",
      menuName: "Settings"
    },
    component: AllWorkspacesPage
  }]
}, {
  path: "/login",
  name: "Login",
  meta: {
    requiresAuth: false,
    featureName: "default"
  },
  component: LoginPage
}, {
  path: "/register",
  name: "Register",
  meta: {
    requiresAuth: false,
    featureName: "default"
  },
  component: RegisterPage
}, {
  path: "/recovery",
  name: "Recovery",
  meta: {
    requiresAuth: false,
    featureName: "default"
  },
  component: RecoveryPage
}, {
  path: "/user/password-reset",
  name: "ResetPassword",
  meta: {
    requiresAuth: false,
    featureName: "default"
  },
  component: ResetPassword
}, {
  path: "/onboard",
  name: "Onboard",
  meta: {
    requiresAuth: false,
    featureName: "default"
  },
  component: OnboardPage
}, {
  path: "/amz-advertising/select-profiles",
  name: "AmazonAdsAccountSelectionPage",
  meta: {
    requiresAuth: false,
    featureName: "default"
  },
  component: AmazonAdsAccountSelectionPage
}, {
  path: "/connections/success",
  name: "ConnectionsSuccessPage",
  meta: {
    requiresAuth: true,
    featureName: "default"
  },
  component: ConnectionSuccessPage,
  beforeEnter: []
}, {
  path: "/connections/failed",
  name: "ConnectionsFailedPage",
  meta: {
    requiresAuth: false,
    featureName: "default"
  },
  component: ConnectionFailedPage,
  beforeEnter: []
}, {
  path: "/:pathMatch(.*)",
  name: "NotFound",
  meta: {
    requiresAuth: false,
    featureName: "default"
  },
  component: NotFoundPage
}];

// {
//     path: '/onboard/:email',
//     name: 'Onboard',
//     component: OnboardPage,
// }

let router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// router.beforeEach(async(to, from, next) => {
//     const store = await
//     import ('@/store');
//     var is_authenticated = store.default.getters.getAuthState()
//     if ((to.path !== '/login' && to.path !== 'login') && !is_authenticated) {
//         next({ path: '/login' })
//     } else
//     if ((to.path === '/login' || to.path === 'login') && is_authenticated) {
//         next({ path: '/' })
//     } else {
//         next()
//     }
// })

const redirectToLogin = route => {
  const LOGIN = "/login";
  if (route.name != LOGIN) {
    return {
      path: LOGIN,
      replace: true,
      query: {
        redirectFrom: route.fullPath
      }
    };
  }
};

// const redirectToHome = route => {
//     const HOME = '/';
//     return { path: HOME, replace: true };
// };

router.beforeEach(async (to, from, next) => {
  //const store = await import('@/store');
  //var is_authenticated = await store.default.getters.getAuthState();
  window.auth_user = await router.$store.getters.getAuthUser(false);
  var is_authenticated = await router.$store.getters.getAuthState(false);
  //console.log("Is Authenticated: ", is_authenticated);
  const requiresAuth = to.matched.some(route => route.meta && route.meta.requiresAuth);
  if (!is_authenticated && to.fullPath === "/") {
    router.push(redirectToLogin(to));
  }
  if (!is_authenticated && requiresAuth) {
    // Push to login
    router.replace(redirectToLogin(to));
  }
  resetLocalStorageIfNeeded(to, from, next);
  // Jump to the route `to` if the user is authenticated
  next();
});
export default router;