import HugValue from '@/components/tableElements/HugValue.vue';
import VariableStyledBar from '@/components/tableElements/VariableStyledBar.vue';
import CrossHatchedBar from '@/components/tableElements/CrossHatchedBar.vue';
import LayerBars from '@/components/tableElements/LayerBars.vue';
export default {
  name: "LayeredBarWithValue",
  props: {
    value1: {
      type: [Number, null],
      required: false,
      default: 3301
    },
    min1: {
      type: Number,
      required: false,
      default: 0
    },
    max1: {
      type: Number,
      required: false,
      default: 0
    },
    value2: {
      type: [Number, null],
      required: false,
      default: 3301
    },
    min2: {
      type: Number,
      required: false,
      default: 0
    },
    max2: {
      type: Number,
      required: false,
      default: 0
    },
    color1: {
      type: String,
      required: true,
      default: "#312783"
    },
    color2: {
      type: String,
      required: true,
      default: "#312783"
    },
    align: {
      type: String,
      required: false,
      default: "right"
    },
    gap: {
      type: Number,
      required: false,
      default: 8
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      required: false,
      default: 4
    }
  },
  components: {
    VariableStyledBar,
    HugValue,
    CrossHatchedBar,
    LayerBars
  },
  computed: {
    validValue() {
      return this.value1 !== null && this.value1 !== undefined && this.value2 !== null && this.value2 !== undefined;
    },
    computedStyle() {
      return {
        justifyContent: this.align === "left" ? "flex-start" : "flex-end",
        gap: `${this.gap}px`,
        [`--layer-height`]: this.height + 1 + "px"
      };
    }
  }
};