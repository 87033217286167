import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DateValue = _resolveComponent("DateValue");
  const _component_date_picker_calendar_month_layout = _resolveComponent("date-picker-calendar-month-layout");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_date_picker_calendar_month_layout, {
    monthList: $props.monthList,
    firstMonth: $data.firstMonth,
    lastMonth: $data.lastMonth
  }, {
    default: _withCtx(({
      year,
      month
    }) => [_createElementVNode("div", {
      class: "date-list",
      onMouseout: _cache[0] || (_cache[0] = $event => _ctx.onDayDateHover(null))
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.datesMap.get(`${month}_${year}`), date => {
      return _openBlock(), _createBlock(_component_DateValue, {
        key: date + month + year,
        class: _normalizeClass($options.dateClass(date, month, year)),
        onMouseover: $event => _ctx.onDayDateHover(date),
        onClick: $event => _ctx.onDayDateClick(date),
        value: date,
        format: "D",
        id: _ctx.getDatePickerDayId(date, 'month-date')
      }, null, 8 /* PROPS */, ["class", "onMouseover", "onClick", "value", "id"]);
    }), 128 /* KEYED_FRAGMENT */))], 32 /* NEED_HYDRATION */)]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["monthList", "firstMonth", "lastMonth"])]);
}