import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_GroupBy = _resolveComponent("GroupBy");
  return _openBlock(), _createBlock(_component_GroupBy, {
    class: "product-groupby",
    modelValue: $options.groupByIndex,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.groupByIndex = $event),
    tabs: _ctx.groupByElements,
    onClicked: _cache[1] || (_cache[1] = $event => _ctx.$emit('clicked')),
    tabBusy: $props.tabBusy
  }, null, 8 /* PROPS */, ["modelValue", "tabs", "tabBusy"]);
}