export default {
  name: "ProductScore",
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ""
    },
    score: {
      type: Number,
      default: 0
    }
  },
  computed: {
    style() {
      return "width: " + this.size + "px ; height: " + this.size + "px ; border-radius: " + this.size + "px ; background: " + this.color + "; font-size: 13px ;";
    }
  }
};