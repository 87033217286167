import DatePickerMixin from '../mixins/DatePickerMixin.vue';
import AppButton from '@/components/AppButton';
export default {
  name: 'DatePicker',
  mixins: [DatePickerMixin],
  components: {
    AppButton
  },
  emits: ['submit', 'cancel'],
  props: {
    disableOK: {
      type: Boolean,
      default: false
    }
  }
};