import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-514a7cfc"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["title"];
const _hoisted_2 = {
  class: "custom-img-wrapper"
};
const _hoisted_3 = {
  style: {
    width: '100%'
  }
};
const _hoisted_4 = {
  class: "product-sku-asin"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_img = _resolveComponent("b-img");
  return _openBlock(), _createElementBlock("div", {
    class: "d-flex flex-direction-row product-wrapper",
    onClick: _cache[0] || (_cache[0] = e => $options.handleClick(e)),
    title: $props.showTitle ? this.generateTitle() : ''
  }, [_withDirectives(_createElementVNode("div", _hoisted_2, [_createVNode(_component_b_img, {
    src: $props.item?.main_image_link || '/assets/images/amazon-logo-transparent.svg',
    class: "custom-img",
    height: "40",
    width: "30"
  }, null, 8 /* PROPS */, ["src"])], 512 /* NEED_PATCH */), [[_vShow, $props.showImage]]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    class: "product-name",
    style: _normalizeStyle(!$props.breakName ? {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%'
    } : {})
  }, _toDisplayString($options.updatedItem), 5 /* TEXT, STYLE */), _createElementVNode("div", _hoisted_4, _toDisplayString($props.item.asin) + " • " + _toDisplayString($props.item.sku), 1 /* TEXT */)])], 8 /* PROPS */, _hoisted_1);
}