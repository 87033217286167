export default {
  name: 'TabSelector',
  emits: ['changeTab'],
  props: {
    pages: {
      type: Array,
      required: true
    },
    selectedTab: {
      type: String,
      required: true
    }
  }
};