import { request } from './index';
const alerts = {
  state: {
    list: [],
    statuses: [],
    assignees: []
  },
  mutations: {
    alertsListSet(state, payload) {
      state.list = payload.map((item, index) => {
        item.id = index + 1;
        item.status = 'in_progress';
        item.created_at = new Date();
        item.assignee = {
          id: 1,
          name: 'Franklin Okoli',
          color: '#22863D',
          back: '#DEFBE6'
        };
        return item;
      });
    },
    statusesListSet(state, payload) {
      state.statuses = payload;
    },
    assigneesListSet(state, payload) {
      state.assignees = payload;
    }
  },
  actions: {
    async alertsList({
      commit
    }, payload) {
      const data = await request(`/alerts/list`, 'POST', payload);
      if (data) {
        commit('alertsListSet', data);
      }
      return data;
    },
    async statusesList({
      commit
    }) {
      const data = await request(`/alerts/statuses`);
      if (data) {
        commit('statusesListSet', data);
      }
      return data;
    },
    async assigneesList({
      commit
    }) {
      const data = await request(`/alerts/assignees`);
      if (data) {
        commit('assigneesListSet', data);
      }
      return data;
    }
  }
};
export default alerts;