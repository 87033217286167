import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "modal-paragraph"
};
const _hoisted_2 = {
  style: {
    "padding": "20px 24px"
  }
};
const _hoisted_3 = {
  key: 0,
  class: "next-app-select"
};
const _hoisted_4 = {
  key: 1,
  class: "next-app-select"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_select = _resolveComponent("app-select");
  const _component_app_form = _resolveComponent("app-form");
  const _component_custom_side_bar_app_modal = _resolveComponent("custom-side-bar-app-modal");
  return _openBlock(), _createBlock(_component_custom_side_bar_app_modal, {
    modelValue: $options.modal,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $options.modal = $event),
    title: `Add an ${$props.provider} Account`,
    "ok-loading": $data.loading,
    "ok-title": "Authorize",
    onOk: $options.onSubmit
  }, {
    default: _withCtx(() => [_createVNode(_component_app_form, {
      novalidate: "",
      onSubmit: $options.onSubmit
    }, {
      default: _withCtx(() => [_createElementVNode("p", _hoisted_1, " Choose a region and country or seller/vendor account to connect then click on the button to authorize Omny to connect to your " + _toDisplayString($props.provider) + " Account ", 1 /* TEXT */), _createElementVNode("div", _hoisted_2, [_createVNode(_component_app_select, {
        modelValue: $data.form.region,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.form.region = $event),
        options: $props.regions,
        validateRule: "region",
        label: "Region",
        required: "",
        onSubmit: $options.onSubmit
      }, null, 8 /* PROPS */, ["modelValue", "options", "onSubmit"]), $props.hasAccounts ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_app_select, {
        modelValue: $data.form.amz_sp_api_seller_id,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.form.amz_sp_api_seller_id = $event),
        options: $props.accounts,
        validateRule: "array",
        label: "Seller/Vendor Account",
        required: "",
        onSubmit: $options.onSubmit
      }, null, 8 /* PROPS */, ["modelValue", "options", "onSubmit"])])) : _createCommentVNode("v-if", true), $props.hasCountries ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_app_select, {
        modelValue: $data.form.country,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.form.country = $event),
        options: this.getCountries,
        validateRule: "country",
        label: "Country",
        required: "",
        onSubmit: $options.onSubmit
      }, null, 8 /* PROPS */, ["modelValue", "options", "onSubmit"])])) : _createCommentVNode("v-if", true)])]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onSubmit"])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "ok-loading", "onOk"]);
}