export default {
  name: "ValueElement",
  props: {
    value: {
      type: [Number, String, null]
    }
  },
  computed: {
    formatedValue() {
      if (!this.value) return '-';
      // replace space with non-breaking space
      return this.value.toString().replace(/ /g, '\u00a0');
    }
  }
};